import React from 'react';
// import * as Cookies from "js-cookie";

import logo from '../../../assets/images/ag-logo.png';
import './meeting.css';
import AgoraVideoCall from '../../AgoraVideoCall';
import { AGORA_APP_ID } from '../agora.config';
import { withRouter } from 'react-router';


//../../../assets/images/ag-logo.png
class Index extends React.Component {
  constructor(props) {
    super(props);
    // this.videoProfile = Cookies.get("videoProfile").split(",")[0] || "480p_4";
    // this.channel = Cookies.get("channel") || "test";
    // this.transcode = Cookies.get("transcode") || "interop";
    // this.attendeeMode = Cookies.get("attendeeMode") || "video";
    // this.baseMode = Cookies.get("baseMode") || "avc";

    this.videoProfile = localStorage.getItem('videoProfile').split(',')[0] || '480p_4';
    this.channel = localStorage.getItem('channel') || 'test';
    this.transcode = localStorage.getItem('transcode') || 'interop';
    this.attendeeMode = localStorage.getItem('attendeeMode') || 'video';
    this.baseMode = localStorage.getItem('baseMode') || 'avc';
    // localStorage.getItem('token');
    this.appId = AGORA_APP_ID;
    if (!this.appId) {
      return console.log('Get App ID first!');
    }
    this.uid = undefined;
  }

  render() {
    return (
      <div className="wrapper meeting" style={{width:'99%'}}>
        <div className="ag-header">
          <div className="ag-header-lead">
            <img className="header-logo" src={logo} alt="nothing" />
            <font color="white">
              <span>AgoraWeb v2.1</span>
            </font>
          </div>
          <div className="ag-header-msg">
            <font color="white"> Room:&nbsp;</font>
            <span id="room-name">
              <font color="white">{this.channel}</font>
            </span>
          </div>
        </div>
        <div className="ag-main">
          <div className="ag-container">
            <AgoraVideoCall
              videoProfile={this.videoProfile}
              channel={this.channel}
              transcode={this.transcode}
              attendeeMode={this.attendeeMode}
              baseMode={this.baseMode}
              appId={this.appId}
              uid={this.uid}
              setCallOn={this.props.setCallOn}
              setRespondantActive={this.props.setRespondantActive}
              respondantInfo={this.props.respondantInfo}
              selectedLanguage={this.props.sourceLanguage}
              micinit={this.props.mic}
              videoinit={this.props.videocam}
            />
          </div>
        </div>
        <div className="ag-footer">
          <a className="ag-href" href="https://www.agora.io">
            <span>Powered By Agora</span>
          </a>
          <font color="white">
            <span>Talk to Support: 400 632 6626</span>
          </font>
        </div>
      </div>
    );
  }
}

export default Index;
