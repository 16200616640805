import { PDFDownloadLink, Document, Page, View, Image, Text, Link, Svg, Path, G } from '@react-pdf/renderer';
import { FETCH_REPORT, REQUEST_ANALYSIS_DATA, REQUEST_FILTER_DATA } from '../../constants/analysis/analysisConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react'
import barChart from '../../images/bar_chart_2.svg';
import table from '../../images/table.svg'
import audio from '../../images/audio2.svg';
import logo from '../../images/logo.jpg';

const PlayIcon = () => {
    return (
        <Svg viewBox="0 0 60 60" width={60} height={60}>
            <G>
                <Path d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30 
       c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15 
       C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"
                    stroke="rgb(128, 128, 128)"
                    strokeWidth={1}
                />
                <Path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30 
       S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
                    stroke="rgb(128, 128, 128)"
                    strokeWidth={1}
                />
            </G>
        </Svg>
    )
}
const MyDoc = (props, projectTitle) => {


    const [getTotPage, setgetTotPage] = useState(0)
    useEffect(() => {
        Object.keys(props.report).map((k, i) => {
          
            setgetTotPage(i + 1)
        })
    })
    // console.log('projectTitle', props.projectTitle)
    // const projectTitle = useSelector(state => state.ProjectReducer.projectIdentity);


    return (
        <Document>
            {Object.keys(props.report).map((k, i) => {

                return (
                    <Page size={'A5'} key={'qtype_' + props.report[k].qtype} style={{ padding: '32', paddingTop: 85 }}>
                        <Text style={{ position: 'absolute', top: 20, left: 20, right: 10, fontSize: 12, fontWeight: '900' }}>{props?.projectTitle}</Text>
                        {props.report[k].qtype == 2 &&
                            <View>
                                <Text style={{ marginBottom: '20px', fontSize: 10, fontWeight: 'bold' }}>{props.report[k].question}</Text>
                                <Image src={{ uri: props.report[k].report }} />
                            </View>
                        }
                        {props.report[k].qtype == 5 &&
                            <View>
                                <Text style={{ marginBottom: '20px', fontSize: 10, fontWeight: 'bold' }}>{props.report[k].question}</Text>
                                <Image src={{ uri: props.report[k].report }} />
                                {props.report[k].hasOwnProperty('extra') &&
                                    <>
                                        <View style={{ marginTop: 16 }}>
                                            <Text style={{ fontSize: 11, fontweight: 'bold' }}>{props.report[k].extra.word} ({props.report[k].extra.answers.length})</Text>
                                        </View>
                                        <View style={{ marginTop: 8, display: 'flex', flexDirection: 'row' }}>
                                            {props.report[k].extra.answers.map((d, i) => {
                                                return (
                                                    <View style={{ flexBasis: '24%', padding: 4, border: 1, borderColor: '#798e8f', marginRight: 5 }}>
                                                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>{d.name}</Text>
                                                        <Text style={{ fontSize: 10, marginTop: 4 }}>{d.text}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    </>
                                }
                            </View>
                        }
                        {props.report[k].qtype == 8 &&
                            <View>
                                <Text style={{ marginBottom: '20px', fontSize: 10, fontWeight: 'bold' }}>{props.report[k].question}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <View
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                            justifyContent: 'center', flexWrap: 'wrap', padding: 8
                                        }}>
                                        {Object.keys(props.report[k].report).map((a, i) => {

                                            return (

                                                <View style={{ flexBasis: '25%', padding: 8 }}>
                                                    <Image src={props.report[k].report[a].path}
                                                        style={{ width: 85, height: 85, objectFit: 'cover' }} />
                                                    <Text style={{ marginTop: 4, fontSize: 10 }}>{props.report[k].report[a].name}</Text>
                                                </View>


                                            )
                                        })}
                                    </View>
                                </View>
                            </View>
                        }
                        {props.report[k].qtype == 10 &&
                            <View>
                                <Text style={{ marginBottom: '20px', fontSize: 10, fontWeight: 'bold' }}>{props.report[k].question}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <View
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                            justifyContent: 'center', flexWrap: 'wrap', padding: 8
                                        }}>
                                        {Object.keys(props.report[k].report).map((a, i) => {

                                            return (
                                                <Link src={props.report[k].report[a].path} style={{ textDecoration: 'none' }}>
                                                    <View style={{ flexBasis: '50%', padding: 8, position: 'relative' }}>
                                                        <Image src={props.report[k].report[a].thumb}
                                                            style={{ width: 300, height: 300, objectFit: 'cover' }} />
                                                        <View style={{ position: 'absolute', top: 130, left: 140 }}>
                                                            <PlayIcon />
                                                        </View>
                                                    </View>
                                                </Link>


                                            )
                                        })}
                                    </View>
                                </View>
                            </View>
                        }

												{props.report[k].qtype == 12 &&
                            <View>
                                <Text style={{ marginBottom: '20px', fontSize: 10, fontWeight: 'bold' }}>{props.report[k].question}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <View
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                            justifyContent: 'center', flexWrap: 'wrap', padding: 8
                                        }}>
                                        {Object.keys(props.report[k].report).map((a, i) => {

                                            return (

                                            <Link src={props.report[k].report[a].path} style={{ textDecoration: 'none' }}>
                                                <Image src={audio} style={{ width: 300, height: 300, objectFit: 'cover' }} />
                                                        <View style={{ position: 'absolute', top: 130, left: 140 }}>
                                                            <PlayIcon />
                                                </View>
                                            </Link>
                                            )
                                        })}

                                    </View>
                                </View>
                            </View>
                        }

                        
												{props.report[k].qtype == 13 &&
                            <View>
                                <Text style={{ marginBottom: '20px', fontSize: 10, fontWeight: 'bold' }}>{props.report[k].question}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <View
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                            justifyContent: 'center', flexWrap: 'wrap', padding: 8
                                        }}>
                                        {Object.keys(props.report[k].report).map((a, i) => {

                                            return (

                                            <Link src={props.report[k].report[a].path} style={{ textDecoration: 'none' }}>
                                                <Image src={audio} style={{ width: 300, height: 300, objectFit: 'cover' }} />
                                                        <View style={{ position: 'absolute', top: 130, left: 140 }}>
                                                            <PlayIcon />
                                                </View>
                                            </Link>
                                            )
                                        })}

                                    </View>
                                </View>
                            </View>
                        }					
					

                        {props.report[k].qtype == 4 &&
                            <View>
                                
                                <Text style={{ marginBottom: '20px', fontSize: 10, fontWeight: 'bold' }}>{props.report[k].question}</Text>
                                {props.report[k].chartType == barChart &&
                                    <>
                                        {props.report[k].report.map((c, i) => {
                                            return (
                                                <View style={{ marginTop: 8 }}
                                                >
                                                    <Text style={{ fontSize: 11, marginBottom: 4 }}>{c.choice}&nbsp;mean score {c.mean}</Text>
                                                    <Image src={{ uri: c.report }} />
                                                </View>
                                            )

                                        })}
                                    </>
                                }
                                {props.report[k].report.chartType == table &&
                                    <>
                                      
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <View style={{ flexBasis: '20%', backgroundColor: '#798E8F', borderRight: 2, borderColor: '#ffffff' }}>
                                                <Text style={{ color: '#798E8F' }}> hello  </Text>
                                            </View>
                                            {props.report[k].report.data.table.header.map((h, i) => {
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#798E8F',
                                                            borderRight: 2, borderColor: '#ffffff', fontSize: 11, color: '#ffffff', textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        <Text>{h}</Text>
                                                    </View>
                                                )
                                            })}


                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <View style={{ flexBasis: '20%', backgroundColor: '#F2F4F4', }}>
                                                <Text style={{ color: '#38454F', fontSize: 10, fontWeight: 'bold', textAlign: 'center' }}> High  </Text>
                                            </View>
                                            {props.report[k].report.data.table.high.map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, color: '#38454F', textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? h : ((h / sum[i]) * 100).toFixed(1)}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>
																						
                                            {props.report[k].report.data.table.highList[0].map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? (h) : (i == 0 ? h : ((parseInt(h) / sum[i-1]) * 100).toFixed(1))}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>

                                            {props.report[k].report.data.table.highList[1].map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? (h) : (i == 0 ? h : ((parseInt(h) / sum[i-1]) * 100).toFixed(1))}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>

                                            {props.report[k].report.data.table.highList[2].map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? (h) : (i == 0 ? h : ((parseInt(h) / sum[i-1]) * 100).toFixed(1))}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>
                                            <View style={{ flexBasis: '20%', backgroundColor: '#F2F4F4', }}>
                                                <Text style={{ color: '#38454F', fontSize: 10, fontWeight: 'bold', textAlign: 'center' }}> Neutral  </Text>
                                            </View>
                                            {props.report[k].report.data.table.neutral.map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, color: '#38454F', textAlign: 'center',
                                                            padding: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? h : ((h / sum[i]) * 100).toFixed(1)}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>
                                            <View style={{ flexBasis: '20%', backgroundColor: '#F2F4F4', }}>
                                                <Text style={{ color: '#38454F', fontSize: 10, fontWeight: 'bold', textAlign: 'center' }}> Low  </Text>
                                            </View>
                                            {props.report[k].report.data.table.low.map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, color: '#38454F', textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? h : ((h / sum[i]) * 100).toFixed(1)}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>

                                            {props.report[k].report.data.table.lowList[0].map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? (h) : (i == 0 ? h : ((parseInt(h) / sum[i-1]) * 100).toFixed(1))}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>

                                            {props.report[k].report.data.table.lowList[1].map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? (h) : (i == 0 ? h : ((parseInt(h) / sum[i-1]) * 100).toFixed(1))}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>

                                            {props.report[k].report.data.table.lowList[2].map((h, i) => {
																								let sum = props.report[k].report.data.table.sum ;
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        {/* <Text>{h}</Text> */}
																												<Text>{sum.length == 0 ? (h) : (i == 0 ? h : ((parseInt(h) / sum[i-1]) * 100).toFixed(1))}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', borderTop: 1, borderColor: '#ffffff' }}>
                                            <View style={{ flexBasis: '20%', backgroundColor: '#F2F4F4', }}>
                                                <Text style={{ color: '#38454F', fontSize: 10, fontWeight: 'bold', textAlign: 'center' }}> Mean  </Text>
                                            </View>
                                            {props.report[k].report.data.table.mean.map((h, i) => {
                                                return (
                                                    <View
                                                        style={{
                                                            flexBasis: '20%', backgroundColor: '#F2F4F4',
                                                            fontSize: 10, color: '#38454F', textAlign: 'center',
                                                            paddingTop: 4
                                                        }}>
                                                        <Text>{h}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    </>
                                }

                            </View>
                        }

                        <Image style={{ position: 'absolute', bottom: 15, left: 10, right: 10, width: '50px' }} src={logo} />

                        <Text style={{ position: 'absolute', bottom: 20, left: 350, right: 10, fontSize: 8, fontWeight: 'bold', color: 'grey' }}>{'Page ' + (i + 1) + ' of ' + getTotPage}</Text>

                    </Page>
                )
            })}
        </Document>
    )
}


export default function DownloadReport() {
    const dispatch = useDispatch()
    const report = useSelector(state => state.analysisReducer.reportdata)
    const projectTitle = useSelector(state => state.ProjectReducer.projectIdentity);

    return (
        <div>
            <PDFDownloadLink document={<MyDoc projectTitle={projectTitle} report={report} />} fileName="report.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download now!'
                }
            </PDFDownloadLink>
        </div>
    )
}