import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as DeleteProjectMethods from '../../utils/api/clientAdmin/DeleteProjectData';

function* DeleteProjectData(payload) {
  const promise = DeleteProjectMethods.DeleteProjectData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.DeleteProjectAction.DELETEPROJECT_RECEIVED, data: res.Response });
}

export { DeleteProjectData };
