import styled from '@emotion/styled';
import { Icon } from '../../common';
import ErrorText from './ErrorText';

const InputContainer = styled.div`
	position: relative;
	width: ${(props) => (props.width ? props.width : '100%')};
	display: ${(props) => (props.display ? props.display : 'block')};

	.characters {
		position: absolute;
		right: 0;
		bottom: ${(props) => props.characterBottom || '0'};
		z-index: 1;
		font-size: 12px;
		font-weight: 400;
		color: ${(props) => props.characterColor || 'var(--inputColor)'};
	}
`;

const StyledInputText = styled.input`
	border: 0;
	border-radius: 0;
	border-bottom: ${(props) =>
		props.color
			? `1px solid ${props.color}`
			: '1px solid var(--darkBorderColor)'};
	color: ${(props) => props.color || 'var(--inputColor)'};
	padding-left: ${(props) => (props.positionIcon ? '2rem' : '0')};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '0.875rem')};
	background: transparent;

	:hover,
	:focus {
		box-shadow: none;
		outline: 0;
		border-color: var(--inputColor);
		color: ${(props) => props.color || 'var(--inputColor)'};
		background: transparent;
	}
    ::placeholder{
		color: ${(props) => props.color || 'var(--mediumGrey)'};
		font-weight: 500;
		font-size: ${(props) =>
			props.placeholderSize ? props.placeholderSize : '0.75rem'};
	}
	::-webkit-input-placeholder{
		color: ${(props) => props.color || 'var(--mediumGrey)'};
		font-weight: 500;
		font-size: ${(props) =>
			props.placeholderSize ? props.placeholderSize : '0.75rem'};
	}
	::-moz-placeholder{
		color: ${(props) => props.color || 'var(--mediumGrey)'};
		font-weight: 500;
		font-size: ${(props) =>
			props.placeholderSize ? props.placeholderSize : '0.75rem'};
	}
	:-ms-input-placeholder {
		color: ${(props) => props.color || 'var(--mediumGrey)'};
		font-weight: 500;
		font-size: ${(props) =>
			props.placeholderSize ? props.placeholderSize : '0.75rem'};
	}
	&.is-invalid:focus {
		box-shadow: none;
		outline: 0;
	}
	&:disabled {
    background: transparent;
  }
	
`;

export default function InputText({
	children,
	className = "",
	placeholder = null,
	type = "text",
	value = "",
	onChange = null,
	onBlur= null,
	showCharacters = true,
	characterLimit = 30,
	width,
	display,
	showIcon = false,
	disabled = false,
	iconName,
	iconStyle,
	error = false,
	characterColor,
	characterBottom,
	setIsFlag,
	IsFlag,
	...rest
}) {
	const charactersTyped = value?.length || 0;

	if (showCharacters && value?.length >= characterLimit) {
		value = value.substr(0, (characterLimit - 1));
	}

	return (
		<InputContainer
			width={width}
			display={display}
			characterColor={characterColor}
			characterBottom={characterBottom}
			{...rest}
		>
			{IsFlag != undefined && IsFlag ? <StyledInputText
				value={value}
				type={type}
				className={`form-control ${className} ${
					error ? 'is-invalid': ''
				}`}
				placeholder={placeholder}
				defaultValue={value}
				onChange={onChange}
				disabled={disabled}
				onBlur={onBlur}
				{...rest}
			/> : 
			<StyledInputText
				// value={value}
				type={type}
				className={`form-control ${className} ${
					error ? 'is-invalid': ''
				}`}
				placeholder={placeholder}
				defaultValue={value}
				onChange={onChange}
				disabled={disabled}
				onBlur={onBlur}
				{...rest}
			/>}
			{showCharacters && (
				<span className="characters">
					{charactersTyped}/{characterLimit}
				</span>
			)}
			{showIcon && (
				<Icon className={iconStyle} color="var(--inputColor)">
					{iconName}
				</Icon>
			)}

			{error && <ErrorText>{error}</ErrorText>}
		</InputContainer>
	);
}