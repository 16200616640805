import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as DeleteVideoClipMethods from '../../utils/api/field/DeleteVideoClipData';

function* DeleteVideoClipData(payload) {
  const promise = DeleteVideoClipMethods.DeleteVideoClipData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.DeleteVideoClipAction.DELETEVIDEOCLIP_RECEIVED, data: res });
}

export  { DeleteVideoClipData }