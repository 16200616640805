import React, { Component } from 'react';
import Select, { components } from 'react-select';
import ErrorText from './ErrorText';
import DropdownIcon from '../../images/dropdown.svg';

import { color } from 'd3';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius: 0
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    ...state.selectProps.valueContainerStyle,
    padding: '0px'
  }),

  control: (provided, state) => ({
    ...provided,
    outline: 0,
    borderRadius: 0,
    border: 0,
    background: 'transparent',
    color: state.isSelected ? 'green' : '',
    borderBottom: state.selectProps.error
      ? '1px solid var(--errorColor)'
      : state.selectProps.selectedBorderBottom
      ? state.selectProps.selectedBorderBottom
      : '1px solid var(--darkBorderColor)',
    borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)',
    boxShadow: 'none',
    // minHeight: '36px',
    '&:hover': {
      borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)'
    },
    ...state.selectProps.controlStyle
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #EFEFEF',
    borderRadius: '5px',
    width: 'auto',
    padding: '0 10px',
    // position: 'absolute',
    background: '#FFFFFF',
    // width: '220px',
    zIndex: 5,
    boxShadow: 'none',
    color: 'red'
  }),

  option: (provided, state) => ({
    fontSize: '0.75rem',
    fontWeight: '500',
    color: 'var(--inputColor)',
    padding: '12px 7px',
    borderBottom: '1px solid #E2E2E2',
    cursor: 'pointer',
    whiteSpace: 'nowrap',

    '&:last-child': {
      borderBottom: 0
    },
    ...state.selectProps.optionStyle
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '1px'
  }),

  singleValue: (provided, state) => ({
    fontSize: state.selectProps.selectedFontSize ? state.selectProps.selectedFontSize : '1rem',
    fontWeight: state.selectProps.selectedFontWeight ? state.selectProps.selectedFontWeight : '800',
    color: state.selectProps.selectedColor ? state.selectProps.selectedColor : 'var(--inputColor)',

    ...state.selectProps.singleValueStyle
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  placeholder: (provided, state) => ({
    fontSize: '0.75rem',
    color: 'var(--mediumGrey)',
    ...state.selectProps.placeholderStyle
  }),

  IndicatorsContainer: (provided, state) => ({
    ...provided,
    padding: '0px',
    ...state.selectProps.indicatorContainer
  }),

  DropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0
  })
  // menu: (provided, state) => ({
  //   ...provided,
  //   width: state.selectProps.width,
  //   borderBottom: '1px dotted pink',
  //   color: state.selectProps.menuColor,
  //   padding: 20,
  // }),
};

const DownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DropdownIcon} alt="Dropdown Icon" />
    </components.DropdownIndicator>
  );
};
const options = [{ lable: 'MP3', value: 'MP3' }];
export default function Dropdown({
  onChange,
  options = [],
  width,
  controlWidth,
  defaultValue,
  placeholder = 'Select one',
  className = '',
  Menu,
  SingleValue,
  isClearable = false,
  isSearchable = false,
  name,
  error = false,
  selectedFontSize,
  selectedFontWeight,
  selectedColor,
  DropdownIndicator = DownIndicator,
  selectedBorderBottom,
  singleValueStyle = {},
  valueContainerStyle = {},
  controlStyle = {},
  indicatorContainer = {},
  optionStyle,
  value,
  placeholderStyle,
  isDisabled,
  key
}) {
  let Components = {
    DropdownIndicator
  };

  if (Menu) {
    Components = { ...Components, Menu };
  }
  if (SingleValue) {
    Components = { ...Components, SingleValue };
  }
  return (
    <>
      <Select
        key={defaultValue}
        value={value}
        styles={customStyles}
        components={Components}
        options={options}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        width={width}
        controlWidth={controlWidth}
        placeholder={placeholder}
        onChange={onChange}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        error={error}
        className={`${error ? 'is-invalid' : { className }}`}
        selectedFontSize={selectedFontSize}
        selectedFontWeight={selectedFontWeight}
        selectedColor={selectedColor}
        selectedBorderBottom={selectedBorderBottom}
        singleValueStyle={singleValueStyle}
        valueContainerStyle={valueContainerStyle}
        controlStyle={controlStyle}
        indicatorContainer={indicatorContainer}
        optionStyle={optionStyle}
        placeholderStyle={placeholderStyle}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
}