import styled from '@emotion/styled';

export const Circle = styled.div`
	border: ${(props) => (props.status ? `` : `1px solid var(--greyBlue)`)};
	background: ${(props) =>
		props.status == 1
			? `var(--primaryColor)`
			: props.status == 2
			? `var(--mediumYellow)`
			: props.status == 3
			? `var(--red)`
			: `var(--white)`};

	width: 12px;
	height: 12px;
	border-radius: 6px;
	margin-right: 7px;
`;
