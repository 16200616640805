import styled from '@emotion/styled'
import Button from './Button';

const StyledButton = styled(Button)`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 0.3125rem;
    background: ${props => props.inverted ? 'var(--primaryColor)' : 'var(--white)'};
    color: ${props => props.inverted ? 'var(--white)' : 'var(--labelColor)'};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.65rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    border: 0;
    transition: background-color 250ms, color 250ms;

    img {
        margin-right: 8px;
    }

    .plus-white {
        display: ${props => props.inverted ? 'inline-block' : 'none'};
    }

    .plus-green {
        display: ${props => props.inverted ? 'none' : 'inline-block'};
    }

    span {
        background: transparent;
        display: inline-block;
        margin-right: 5px;
        font-size: 2.2rem;
        color: ${props => props.inverted ? 'var(--white)' : 'var(--primaryColor)'};
        transition: color 250ms;
    }

    :hover,
    :focus {
        background: ${props => props.inverted ? 'var(--white)' : 'var(--primaryColor)'};
        color: ${props => props.inverted ? 'var(--primaryColor)' : 'var(--white)'};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
        transition: background-color 250ms, color 250ms;

        span {
            color: ${props => props.inverted ? 'var(--primaryColor)' : 'var(--white)'};
            transition: color 250ms;
        }

        .plus-white {
            display: ${props => props.inverted ? 'none' : 'inline-block'};
        }

        .plus-green {
            display: ${props => props.inverted ? 'inline-block' : 'none'};
        }
    }
`;

export default function CardButton({children, className="", style={}, inverted=false}) {
    return <StyledButton className={className} style={style} inverted={inverted}>
        {children}
    </StyledButton>
}