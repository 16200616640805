import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Button, Card, Divider, Heading, Icon } from '.';

import question from '../images/question.svg';
import { Switch } from '../components/form';
const HelpContainer = styled.div`
	position: fixed;
	bottom: 13px;
	right: 13px;
	z-index: 1300;
`;
const HelpButton = styled.div`
	background: var(--middleGreen);
	width: 40px;
	height: 40px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
	cursor: pointer;
`;
const HelpCard = styled(Card)`
	// width: 40px;
	background: var(--white);
	z-index: 200;

	padding: 1.8rem 1.8rem;
	box-sizing: border-box;
	/* Selected Box in Box */

	box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
	border-radius: 10px;

	.close {
		position: absolute;
		right: 15px;
		top: 15px;
		color: var(--greyBlue);
		cursor: pointer;
	}
`;

const CustomDivider = () => {
	return (
		<Divider
			margin='0'
			height='1.5px'
			width='100%'
			background='var(--deepBlue)'
		/>
	);
};

const CustomSwitch = styled(Switch)`
	.inner {
		background: ${(props) => props.activeColor};
	}

	input {
		display: none;

		:checked ~ .outer .inner {
			background: var(--white);
		}
		:checked ~ .outer {
			background: ${(props) => props.activeColor};
		}
	}
`;
const TextToggle = ({ name }) => {
	const [check, setCheck] = useState(false);
	return (
		<div
			className='d-flex justify-content-between '
			style={{ marginTop: '4px' }}
		>
			<Heading
				fontSize='12px'
				fontWeight='500'
				breakHeading={false}
				color='var(--deepBlue)'
			>
				{name}
			</Heading>
			<CustomSwitch
				inactiveColor='var(--snapGreen)'
				activeColor='var(--snapGreen)'
				className=' ml-1'
				checked={check}
				onChange={() => setCheck((prev) => !prev)}
			/>
		</div>
	);
};

const TextLink = ({ name }) => {
	return (
		<div
			className='d-flex justify-content-between align-items-center '
			style={{ marginTop: '8px' }}
		>
			<Heading
				fontSize='12px'
				fontWeight='500'
				breakHeading={false}
				color='var(--deepBlue)'
			>
				{name}
			</Heading>
			<div
				className='d-flex justify-content-between align-items-center'
				onClick={() => {}}
				style={{
					borderBottom: '1px solid var(--sapGreen)',
				}}
			>
				<Heading
					fontSize='12px'
					fontWeight='700'
					breakHeading={false}
					color='var(--sapGreen)'
					className='mb-0 pb-0'
				>
					Watch Now >
				</Heading>
			</div>
		</div>
	);
};
function Help() {
	const [open, setOpen] = useState(false);
	return (
		<HelpContainer>
			{open ? (
				<HelpCard>
					<div
						onClick={() => {
							setOpen(false);
						}}
					>
						<Icon
							style={{ fontSize: '20px' }}
							color='var(--greyBlue)'
							className='close'
						>
							close
						</Icon>
					</div>

					<Heading
						fontSize='16px'
						fontWeight='500'
						breakHeading={false}
						color='var(--deepBlue)'
					>
						Hello{' '}
						<span style={{ fontWeight: '700' }}>Riya</span>
					</Heading>
					<Heading
						fontSize='16px'
						fontWeight='700'
						breakHeading={false}
						color='var(--deepBlue)'
						className='mb-0 pb-0'
					>
						What can I help you with?
					</Heading>

					<div style={{ padding: '1.7rem 0' }}>
						<div className='d-flex justify-content-between align-items-center pb-2 '>
							<Heading
								fontSize='14px'
								fontWeight='700'
								breakHeading={false}
								color='var(--deepBlue)'
								className='p-0 m-0 '
							>
								Intoduction To Explorastory
							</Heading>
							<Button
								fontSize='12px'
								fontWeight='700'
								className='p-0 m-0 px-2 '
								color='var(--sapGreen)'
								buttonColor='var(--sapGreen)'
							>
								Watch the demo
							</Button>
						</div>

						<CustomDivider />
					</div>

					<div>
						<Heading
							fontSize='14px'
							fontWeight='700'
							breakHeading={false}
							color='var(--deepBlue)'
						>
							Assistant Tool Tips{' '}
							<span
								style={{
									fontSize: '12px',
									fontWeight: '500',
									marginLeft: '10px',
								}}
							>
								{'Highlights areas to help guide you'}
							</span>
						</Heading>
						<CustomDivider />
					</div>
					<div className='mr-4 my-3'>
						<TextToggle name='Setup' />
						<TextToggle name='Recruit' />
						<TextToggle name='Design' />
						<TextToggle name='Field' />
						<TextToggle name='Analysis' />
					</div>
					<div>
						<Heading
							fontSize='14px'
							fontWeight='700'
							breakHeading={false}
							color='var(--deepBlue)'
						>
							Video Explainers
							<span
								style={{
									fontSize: '12px',
									fontWeight: '500',
									marginLeft: '10px',
								}}
							>
								{
									'Detailed step by step videos to help you learn '
								}
							</span>
						</Heading>
						<CustomDivider />
					</div>
					<div className=' mt-3'>
						<TextLink name='Setup' />
						<TextLink name='Recruit' />
						<TextLink name='Design' />
						<TextLink name='Field' />
						<TextLink name='Analysis' />
					</div>
				</HelpCard>
			) : (
				<HelpButton
					onClick={() => {
						setOpen(true);
					}}
				>
					<img src={question} />
				</HelpButton>
			)}
		</HelpContainer>
	);
}

export default Help;
