import styled from '@emotion/styled';

const StyledLabel = styled.label`
    color: var(--labelColor);
    font-weight: 700;
`

export default function Label({ children }){
    return (
        <StyledLabel>{children}</StyledLabel>
    )
}