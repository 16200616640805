import { useState, useRef, useEffect } from 'react'
import {
    select,
    scaleBand,
    axisBottom,
    axisLeft,
    scaleLinear,
    stack,
    max,
    event,
    map,
    InternSet,
    range,
    d3Cloud,
    scaleOrdinal,
    schemeCategory10,
} from "d3";

import WordCloud from 'react-d3-cloud';


export default function ReportWordCloud(props) 
{
    const [data, setData] = useState([]);
    const wrapperRef = useRef();
    const canvasRef = useRef(null)

    useEffect(() => {
        const { width, height } = wrapperRef.current.getBoundingClientRect();

        let d = props.dataset != undefined ? props.dataset.reduce((f,d)=>{
            
            let obj = {
                text: d['word'],
                value: d['count']
            }
           
            f.push(obj)
            
            return f
        },[]) : [];


        setData(d)

        setTimeout(() => {
        var doctype = '<?xml version="1.0" standalone="no"?>'
        + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
      
        // serialize our SVG XML to a string.
        var source = (new XMLSerializer()).serializeToString(window.document.getElementById("svg_"+props.qid).getElementsByTagName('svg')[0]);

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        let img = new Image({width: width, height: height})
        img.src = 'data:image/svg+xml;base64,'+window.btoa(doctype + source)
        canvas.width = width
        canvas.height = height
        context.drawImage(img,0,0,width,height)
        
        img.onload = function(){
            // Now that the image has loaded, put the image into a canvas element.
            context.drawImage(img, 0, 0,width,height);
        
            if(props.onReady != undefined)
            {
                props.onReady(props.index, 5, canvas.toDataURL("image/png"), props.question)
            }
        }
    }, 3000);
    }, [])
    
    return (
        <>
            <div
                ref={wrapperRef}
                style={{ height: "200px", margin: "2rem" }}
                id={'svg_'+props.qid}
            >
                <WordCloud
                    data={data}
                    width={500}
                    height={80}
                    font="Noto Sans"
                    fontStyle="normal"
                    fontWeight="bold"
                    fontSize={(word) => Math.max(Math.log2(word.value) * 5,5)}
                    spiral="rectangular"
                    rotate={(word) => word.value % 360}
                    padding={5}
                    random={Math.random}
                    fill={'#664178'}
                    onWordClick={(event, d) => {
                        props.onWordClick(d.text)
                    }}
                />
                <canvas ref={canvasRef} style={{display:'none'}}/>

            </div>
        </>
    );
}