// reducer.js
const initialState = {
  transcriptData: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_TRANSCRIPT_DATA':
      return {
        ...state,
        transcriptData: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
