import styled from '@emotion/styled';
import React, { useState } from 'react';

import './ReactTooltip.css';
import CreateClipTooltipBox from './CreateClipTooltipBox';

const Circle = styled.div`
	position: absolute;
	left: ${(props) => props.left};
	top: ${(props) => props.top};
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};
	z-index: 100;
	cursor: pointer;

	width: 32px;
	height: 32px;
	border-radius: 16px;
	background: #38454f70;

	/* Colour/White */

	border: ${(props) => (props.active ? '2px solid #ffffff' : 'none')};
	/* Selected Box in Box */

	box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);

	display: flex;
	align-items: center;
	justify-content: center;

	.innerCircle {
		background: white;
		width: 16px;
		height: 16px;
		border-radius: 8px;
		opacity: ${(props) => (props.active ? 1 : 0.7)};
	}
`;

const CustomCard = styled.div`
	z-index: 10;
	background: #ffffff;
	border: 1px solid #efefef;
	box-sizing: border-box;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
	
	margin-left: -20px;
`;
const ReactTooltip = ({
	information,
	delay,
	direction,
	width,
	children,
	description,
	disabled,
	left,
	top,
	right,
	bottom,
}) => {
	let timeout;
	const [active, setActive] = useState(false);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, delay || 400);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	return (
		<div
			className='Tooltip-Wrapper'
				onMouseOver={showTip}
				onMouseLeave={hideTip}
				left={left}
				top={top}
				right={right}
				bottom={bottom}
			>
			{children}
			{active && disabled && (
				<CustomCard
				className={` Tool-Tip   direction-${ direction || 'top' }`} style={{transform: 'translateX(-95%) translateY(60%)'}}>
					{/* Content */}
					<CreateClipTooltipBox
						width={width}
						description={description}
						information={information}
						handleClose={hideTip}
					/>
				</CustomCard>
			)}
		</div>
	);
};

export default ReactTooltip;
