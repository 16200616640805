import {useState, useRef,useEffect} from 'react'
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  event,
  map,
  InternSet,
  range
} from "d3";

export default function VerticalBarChartReport(props){

  //const [data, setData] = useState(props.datasets);
  const svgRef = useRef();
  const wrapperRef = useRef();
  const canvasRef = useRef(null)

  let {title, yFormat, yLabel} = props

  useEffect(()=>{
    const svg = select(svgRef.current);
    let x = d => d.x // given d in data, returns the (ordinal) x-value
    let y = d => d.y // given d in data, returns the (quantitative) y-value

    let data = props.datasets

    const X = map(data, x);
    const Y = map(data, y);
    let marginTop = 20 // the top margin, in pixels
    let marginRight = 0 // the right margin, in pixels
    let marginBottom = 30 // the bottom margin, in pixels
    let marginLeft = 40 // the left margin, in pixels
    const { width, height } = wrapperRef.current.getBoundingClientRect();
    let xDomain = X // an array of (ordinal) x-values
    let xRange = [marginLeft, width - marginRight] // [left, right]
    let yType = scaleLinear // y-scale type
    let yDomain  = [0, max(Y)] // [ymin, ymax]
    let yRange = [height - marginBottom, marginTop] // [bottom, top]
    let xPadding = 0.1 // amount of x-range to reserve to separate bars
  
    let color = "rgb(35,97,160)" 

    xDomain = new InternSet(xDomain);

    // Omit any data not present in the x-domain.
    const I = range(X.length).filter(i => xDomain.has(X[i]));

    // Construct scales, axes, and formats.
    const xScale = scaleBand(xDomain, xRange).padding(xPadding);
    const yScale = yType(yDomain, yRange);
    const xAxis = axisBottom(xScale).tickSizeOuter(0);
    const yAxis = axisLeft(yScale).ticks(height / 40, yFormat);

    // Compute titles.
    if (title === undefined) {
        const formatValue = yScale.tickFormat(100, yFormat);
        title = i => `${X[i]}\n${formatValue(Y[i])}`;
    } else {
        const O = map(data, d => d);
        const T = title;
        title = i => T(O[i], i, data);
    }

    svg.selectAll('.x-axis > *').remove()
    svg.selectAll('.y-axis > *').remove()
    svg.selectAll('.content > *').remove()

    svg.selectAll('.y-axis').append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .attr("font-family","Noto Sans")
          .text(yLabel));

    const bar = svg.selectAll('.content').append("g")
      .attr("fill", color)
      .selectAll("rect")
      .data(I)
      .join("rect")
      .attr("x", i => (xScale(X[i])+(xScale.bandwidth()/2)-50))
      .attr("y", i => yScale(Y[i]))
      .attr("height", i => yScale(0) - yScale(Y[i]))
      .attr("width", Math.min(xScale.bandwidth(),100));
    

    if (title) bar.append("title")
      .text(title);

    svg.selectAll('.x-axis').append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis)

    let doctype = '<?xml version="1.0" standalone="no"?>'
        + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';

        // serialize our SVG XML to a string.
    let source = (new XMLSerializer()).serializeToString(window.document.getElementById('svg_'+props.qid));

    // create a file blob of our SVG.
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let img = new Image({width: width, height: height})
    img.src = 'data:image/svg+xml;base64,'+window.btoa(doctype + source)
    canvas.width = width
    canvas.height = height
    context.drawImage(img,0,0,width,height)
  
    img.onload = function(){
        // Now that the image has loaded, put the image into a canvas element.
        context.drawImage(img, 0, 0,width,height);
      
        if(props.onReady != undefined){
            props.onReady(props.index, 2, canvas.toDataURL("image/png"), props.question)
          }
      }
    },[])

    return (
        <>
          <div
            ref={wrapperRef}
            style={{ width: "100%", height: "200px", marginBottom: "2rem" }}
          >
            <svg ref={svgRef} style={{ width: "100%", height: "110%" }} id={'svg_'+props.qid}>
              <g className="x-axis" />
              <g className="y-axis" />
              <g className="content" />
            </svg>
            <canvas ref={canvasRef} style={{display:'none'}}/>
           
          </div>
        </>
      );
}