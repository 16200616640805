import styled from '@emotion/styled';

const StyledIcon = styled.span`
	color: ${(props) => props.color || 'var(--labelColor)'};
`;

export default function Icon({ children, onClick, className = '', style = {}, color, outlined = true }) {
	return ( <StyledIcon onClick={onClick} className={`material-icons ${ outlined && 'material-icons-outlined' } ${className}`} color={color} style={style}>{children}</StyledIcon>
	);
}
