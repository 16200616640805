import styled from '@emotion/styled';

const StyledTabs = styled.ul`
    border-bottom: 1px solid var(--darkBorderColor);
    padding: 0 1rem;
    display: flex;
    align-items: flex-end;
`

export default function Tabs({ children, className }) {
    return <StyledTabs className={`nav ${className}`}>{children}</StyledTabs>
};