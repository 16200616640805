import React, { useRef } from 'react';
import video_play from '../../images/video_play.svg';
import analysis_video from '../../images/analysis_video.png';
import styled from '@emotion/styled';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import useWindowDimensions from '../../common/useWindowDimensions';
const Container = styled.div`
  background: var(--midWhite);
  // margin: 0.5rem;
  margin-left: 0;
  // margin: 0.5rem 0;
  // margin-bottom: 1rem;
  // margin-right: 0.5rem;
  .image {
    // width: 14rem;
  }

  .video-react.video-react-fluid {
    padding-top: 70% !important;
  }

  .video-react .video-react-video {
    height: 100%;
  }
  label {
    margin-bottom: 0 !important;
  }
  .img {
    position: relative;
  }
  .video_play {
    position: absolute;
    left: 40%;
    top: 38%;
  }
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .imgContainer:hover {
      .popup {
        display: block;
      }
    }
    .imgContainer {
      position: relative;
      background: var(--darkWhite);
      margin-left: 5px;
      padding: 10px;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      .popup {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18);
        border-radius: 5px;
        background: var(--white);
        font-size: 10px;
        font-weight: 500;
        color: var(--deepBlue);
        padding: 0.5rem;
        position: absolute;
        width: 160px;
        bottom: -40px;
        right: 0;
        z-index: 1080;
        display: none;
      }
    }
  }
`;

function ViewVideo({ imgLink, proportion = 5 , thumbLink, onPlay}) {
  const { height, width } = useWindowDimensions();
  const playerRef = useRef(null);


  const handlePlay = () => {
    if (playerRef.current) {
      onPlay(playerRef.current.video.video); // Send video DOM element reference to parent
    }
  };
  return (
    <Container style={{ marginRight: width / proportion }}>
      <div className="video">
        <Player
          src={imgLink}
          poster={thumbLink}
          ref={playerRef}
          onPlay={handlePlay}
          preload="none"
          fluid="false"
        >
          <BigPlayButton position="center" />
        </Player>
      </div>
    </Container>
  );
}

export default ViewVideo;
