import React, { useState, useEffect } from 'react';
import { Text, Button, Avatar, Icon } from '../../common';
import { Dropdown, Checkbox, Radio } from '../../components/form';
import styled from '@emotion/styled';
import { UsageReview } from '../../views/Project/data';
// import { ImageDisplay, PreviewImage } from '../ImageDisplay';
import Thumbnail from '../../images/thumbnail.png';
import { Link } from 'react-router-dom';

const ButtonBase = styled(Button)`
   width:${(props) => props.width || 'auto'};
   height:${(props) => props.height || 'auto'};
   color:${(props) => props.color};
   font-weight:600;
`;

const DivContainer = styled.div`padding: 1rem 2.75rem 0rem 2.75rem;`;

const ProfileContainer = styled.div`
	max-height: 420.17px;
	transition: 3s;
	.scrollable {
		overflow-y: auto;
		max-height: 415px;
		scrollbar-width: thin;
	}
`;

const CollapseContainer = styled.div`
	max-height: 396.29px;
	transition: 3s;

	.card {
		border: 1px solid #e8e8e8;
		box-sizing: border-box;
		box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
	}

	.scrollable {
		overflow-y: auto;
		max-height: 390px;
		scrollbar-width: thin;
	}
`;

const SeeLess = ({ width, className }) => (
	<React.Fragment>
		<Icon color="var(--middleGreen)">expand_less</Icon>
	</React.Fragment>
);

const SeeMore = ({ width, className }) => (
	<React.Fragment>
		<Icon color="var(--middleGreen)">expand_more</Icon>
	</React.Fragment>
);

export function FieldList({ stage, appliedStage }) {
	const [ seeMore, setSeeMore ] = useState(false);
	const [ imagePreview, setImagePreview ] = useState(false);

	useEffect(
		() => {
			setSeeMore(false);
			setImagePreview(false);
		},
		[ stage ]
	);

	const handleSeeMore = () => {
		setSeeMore(!seeMore);
	};

	const handleImagePreview = (open) => {
		setImagePreview(open);
	};
	return (
		<React.Fragment>
			<DivContainer>
				<div className="container pr-0">
					<div class="row ">
						<div className="col-md-5 p-0 offset-1">
							<div className="cursor d-flex align-items-center">
								<Text color="var(--greyBlue)" fontSize="12px" fontWeight="500">
									Name
								</Text>
								<Icon
									style={{
										fontSize: '15px'
									}}
									className="ml-1"
								>
									unfold_more
								</Icon>
							</div>
						</div>
						<div className="col-md-3 p-0">
							<div className="cursor d-flex align-items-center">
								<Text color="var(--greyBlue)" fontSize="12px" fontWeight="500">
									Group
								</Text>
								<Icon
									style={{
										fontSize: '15px'
									}}
									className="ml-1"
								>
									unfold_more
								</Icon>
							</div>
						</div>
						<div className=" d-flex align-items-center col-md-3 p-0">
							<div className="cursor d-flex align-items-center">
								<Text color="var(--greyBlue)" fontSize="12px" fontWeight="500">
									Completion Status
								</Text>
								<Icon
									style={{
										fontSize: '15px'
									}}
									className="ml-1"
								>
									unfold_more
								</Icon>
							</div>
						</div>
					</div>
				</div>
			</DivContainer>
			<hr />
			<div className="container pb-3 pr-0">
				<ProfileContainer>
					<div className="scrollable">
						<div className="container">
							<div className="row d-flex align-items-center">
								<div className="col-md-2 col-md-10percent p-0 ">
									<SelectBox checked={true} />
								</div>
								<div className={`col-md-4 p-0 flex-fill`}>
									<div className="d-flex">
										<div className="d-flex  align-items-center">
											<Avatar name="Sonia P" width="35" height="35" />
											<div className="f-flex flex-column ml-2">
												<Text type="5" fontSize="14px" className="d-flex">
													R1-
													<Text span type="4" fontSize="14px">
														Sonia P.
													</Text>
												</Text>
												<Text
													fontSize="10px"
													fontWeight="300"
													className="underline-text cursor"
												>
													View profile
												</Text>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 p-0 d-flex align-items-center justify-content-center">
									<div className="group-code">
										<Text type="1" fontSize="14px">
											G1
										</Text>
									</div>
									<div className="group ml-2 w-70">
										<Text
											type="5"
											fontSize="12px"
											color="var(--seaBlue)"
											className="lineheight-105"
										>
											Intending buyer Affluent Class
										</Text>
									</div>
								</div>
								{stage == 2 && (
									<div className="col-md-3 p-0 d-flex justify-content-center">
										<ButtonBase
											size="mid"
											fontSize="8px"
											fontWeight="600"
											width="92px"
											height="27px"
											padding="0"
											fill={false}
											borderRadius="100px"
										>
											Clip Video
										</ButtonBase>
									</div>
								)}
								{stage == 1 && (
									<div className="col-md-3 p-0 d-flex justify-content-center">
										<ButtonBase
											size="mid"
											fontSize="12px"
											fontWeight="700"
											width="92px"
											height="27px"
											padding="0"
											fill={false}
											borderRadius="100px"
											simple
											className="d-flex align-items-center"
											onClick={handleSeeMore}
										>
											Review
											{seeMore ? <SeeLess /> : <SeeMore />}
										</ButtonBase>
									</div>
								)}
								{seeMore && (
									<CollapseContainer className=" col-md-11 m-auto pt-3">
										<div className={(seeMore ? 'show' : '') + ' collapse scrollable'}>
											{UsageReview.map((question, index) => (
												<div className="card card-body mb-2">
													<div className="">
														<Text type="4" fontSize="12px" fontWeight="700">
															{index + 1}. {question.title}
														</Text>
														<div className="d-flex flex-wrap mt-2 ml-2">
															{question.options.map((option, index) => (
																<div
																	className="mr-auto"
																	key={`${option.value}-${index}`}
																>
																	{question.type === 'checkbox' ? (
																		<Checkbox
																			fontSize="14px"
																			checked={option.selected}
																		>
																			{option.value}
																		</Checkbox>
																	) : (
																		<Radio
																			fontSize="14px"
																			checked={option.selected}
																		>
																			{option.value}
																		</Radio>
																	)}
																</div>
															))}
														</div>
													</div>
												</div>
											))}
											<div className="card card-body mb-2">
												<div className="">
													<Text type="4" fontSize="12px" fontWeight="700">
														3. Can you please upload photos of the device you watch on?
													</Text>
													<div className="d-flex flex-wrap mt-2">
														<div className="ml-2" onClick={() => handleImagePreview(true)}>
															<ImageDisplay
																src={Thumbnail}
																className="rounded d-block cursor"
																alt="thumbnail"
																width="112px"
																height="62px"
															/>
														</div>
														<div className="ml-2" onClick={() => handleImagePreview(true)}>
															<ImageDisplay
																src={Thumbnail}
																className="rounded d-block cursor"
																alt="thumbnail"
																width="112px"
																height="62px"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</CollapseContainer>
								)}
							</div>
							<hr />
							<div className="row d-flex align-items-center">
								<div className="col-md-2 col-md-10percent p-0 ">
									<SelectBox checked={false} />
								</div>
								<div className={`col-md-4 p-0 flex-fill`}>
									<div className="d-flex">
										<div className="d-flex  align-items-center">
											<Avatar name="Sonia P" width="35" height="35" />
											<div className="f-flex flex-column ml-2">
												<Text type="5" fontSize="14px" className="d-flex">
													R2-
													<Text span type="4" fontSize="14px">
														Aaron G.
													</Text>
												</Text>
												<Text
													fontSize="10px"
													fontWeight="300"
													className="underline-text cursor"
												>
													View profile
												</Text>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 p-0 d-flex align-items-center justify-content-center">
									<div className="group-code">
										<Text type="1" fontSize="14px">
											G2
										</Text>
									</div>
									<div className="group ml-2 w-70">
										<Text
											type="5"
											fontSize="12px"
											color="var(--seaBlue)"
											className="lineheight-105"
										>
											Intending buyer Affluent Class
										</Text>
									</div>
								</div>
								{stage == 2 && (
									<div className="col-md-3 p-0 d-flex justify-content-center">
										<Link to="/project/1/pre-video-call">
											<ButtonBase
												size="mid"
												fontSize="8px"
												fontWeight="700"
												width="92px"
												height="31px"
												padding="0"
											>
												<Text type="1" fontSize="10px" color="#FFFFFF">
													Join Now
												</Text>
												<Text type="4" fontWeight="400" fontSize="8px" color="#FFFFFF">
													Starts in 15mins
												</Text>
											</ButtonBase>
										</Link>
									</div>
								)}
								{stage == 1 && (
									<div className="col-md-3 p-0 d-flex justify-content-center">
										<Text fontSize="12px" fontWeight="700" color="var(--middleGreen)">
											In Progress
										</Text>
									</div>
								)}
							</div>
							<hr />
							<div className="row d-flex align-items-center">
								<div className="col-md-2 col-md-10percent p-0 ">
									<SelectBox checked={false} />
								</div>
								<div className={`col-md-4 p-0 flex-fill`}>
									<div className="d-flex">
										<div className="d-flex  align-items-center">
											<Avatar name="Sonia P" width="35" height="35" />
											<div className="f-flex flex-column ml-2">
												<Text type="5" fontSize="14px" className="d-flex">
													R2-
													<Text span type="4" fontSize="14px">
														Aaron G.
													</Text>
												</Text>
												<Text
													fontSize="10px"
													fontWeight="300"
													className="underline-text cursor"
												>
													View profile
												</Text>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 p-0 d-flex align-items-center justify-content-center">
									<div className="group-code">
										<Text type="1" fontSize="14px">
											G3
										</Text>
									</div>
									<div className="group ml-2 w-70">
										<Text
											type="5"
											fontSize="12px"
											color="var(--seaBlue)"
											className="lineheight-105"
										>
											Intending buyer Affluent Class
										</Text>
									</div>
								</div>
								{stage == 2 && (
									<div className="col-md-3 p-0 d-flex justify-content-center align-items-center">
										<div className="d-flex">
											<Icon
												style={{
													fontSize: '18px'
												}}
											>
												watch_later
											</Icon>
											<Text type="4" fontSize="12px" className="ml-1">
												2 pm today
											</Text>
										</div>
									</div>
								)}
								{stage == 1 && (
									<div className="col-md-3 p-0 d-flex justify-content-center align-items-center">
										<div className="col-md-3 p-0 d-flex justify-content-center">
											<Text fontSize="12px" fontWeight="700" color="var(--middleGreen)">
												Pending
											</Text>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</ProfileContainer>
			</div>
			{imagePreview && (
				<PreviewImage
					open={imagePreview}
					handleClose={handleImagePreview}
					className="image-modal"
					height="288px"
				/>
			)}
		</React.Fragment>
	);
}

export function SelectBox({ checked }) {
	return (
		<label class="CheckContainer">
			<input type="checkbox" checked={checked} />
			<span class="checkmark" />
		</label>
	);
}
