import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as viewProjectSuperAdminMethods from '../../utils/api/superAdmin/ViewProjectDataSuperAdminDashboardData';

function* viewProjectSuperAdminData(payload) {
  const promise = viewProjectSuperAdminMethods.ViewProjectSuperAdminDashboardData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ViewProjectSuperAdminDashboardAction.VIEWPROJECTSUPERADMINDASHBOARD_DATA_RECEIVED, data: res.Response });
}

export  { viewProjectSuperAdminData }