import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import { css } from '@emotion/react'


const ButtonStyle = (props) => css`
    background: ${props.fill ? (props.buttonColor ? props.buttonColor : 'var(--primaryColor)') : 'transparent'};
    border:  ${props.buttonColor ? `1px solid ${props.buttonColor}` : '1px solid var(--primaryColor)'};
    border-radius: ${props.borderRadius ? props.borderRadius : '0.3125rem' };
    font-weight:  ${props.fontWeight ? props.fontWeight : '700'};
    color: ${props.fill ? 'var(--white)' : props.disabled ? 'var(--lightestGrey)' : props.buttonColor ? props.buttonColor  : 'var(--primaryColor)'};
`
const ButtonStyleOutlined = (props) => css`
    border:  ${props.buttonColor ? `1px solid ${props.buttonColor}` : '1px solid var(--primaryColor)'};
    border-radius: ${props.borderRadius ? props.borderRadius : '0.3125rem' };
    font-weight:  ${props.fontWeight ? props.fontWeight : '700'};
	color: ${props.buttonColor ? props.buttonColor : 'var(--middleGreen)'};
`

const ButtonHover = (props) => css`
    transition: box-shadow 250ms ease;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
    background: ${props.disabled ? 'transparent' : props.buttonColor || 'var(--primaryColor)'};
    color: ${props.disabled ? 'var(--lightestGrey)' : 'var(--white)'};
`

const StyledButton = styled.button`
    ${props => props.variant == "outlined" ? ButtonStyleOutlined :  ButtonStyle};
    padding: ${props => props.padding ? props.padding : props.size === 'big' ? '1rem 1.56rem' : props.size === 'mid' ? '0.531rem 1.8125rem' : '0.375rem 1.125rem'} ;
    font-size: ${props => props.fontSize ? props.fontSize : props.size === 'big' ? '0.8125rem' : props.size === 'mid' ? '0.75rem' : '0.625rem'};
    transition: box-shadow 250ms ease;

    :hover,
    :focus {
        ${ButtonHover}
    }

    :disabled,
    [disabled] {
        background: #C2C2C2;
        border-color: #C2C2C2;
        

        :hover {
            box-shadow: none;
            background: #C2C2C2;
            color: #FFFFFF;
            cursor: not-allowed;
        }
    }
`

const JustLink = styled.button`
    background: transparent;
    border: 0;
    font-weight: ${props => props.fontWeight ? props.fontWeight : '700'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.625rem'};
    padding: 0.375rem 1.125rem;
    color: ${props => props.color ? props.color : 'var(--primaryColor)'};
    text-decoration: ${props => props.decoration ? props.decoration : 'none'};


    :hover {
        color: ${props => props.color ? props.color : 'var(--primaryColor)'};
        text-decoration: ${props => props.decoration ? props.decoration : 'none'};
    }
`


export default function Button({
    children,
    className="",
    type="button",
    borderRadius,
    loading=false,
    disabled=false,
    size='big',
    fontSize,
    fontWeight,
    color,
    decoration,
    fill=true,
    simple,
    onClick,
    padding,
    buttonColor,
    variant="fill",

    ...rest
}) {

    if(simple) {
        return <JustLink
            className={className}
            type={type}
            borderRadius={borderRadius}
            loading={loading}
            disabled={disabled ? 'disabled': ''}
            size={size}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            decoration={decoration}
            fill={fill}
            padding={padding}
            onClick={onClick}
            buttonColor={buttonColor}
            {...rest}
        >{children}</JustLink>
    }

    return <StyledButton
        className={`btn ${className} ${loading || disabled ? 'disabled': ''}`}
        type={type}
        borderRadius={borderRadius}
        loading={loading}
        disabled={disabled ? 'disabled': ''}
        size={size}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        decoration={decoration}
        fill={fill}
        padding={padding}
        onClick={onClick}
        buttonColor={buttonColor}
        variant={variant}
        {...rest}
    >{children}</StyledButton>

}