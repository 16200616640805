import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as cohortMethods from '../../utils/api/analysis/cohortData';

function* cohortData(payload) {
  const promise = cohortMethods.cohortData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.analysisAction.ADD_COHORT_RESPONSE, data: res.Response });
}

export  { cohortData }