import styled from '@emotion/styled';
import { Divider } from '.';
import emptydashboard from '../images/empty-dashboard.png';

const StyledContainer = styled.div`
    /* background: var(--white); */
    padding: ${props => props.padding || `100px 0`};
    position: relative;

    .divider{
        margin: 0 auto;
    }
`

export default function EmptyState({ children, className='', style={}, padding, imgSrc=emptydashboard }) {
    return <StyledContainer className={className} style={style} padding={padding}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 d-flex justify-content-center flex-column">
                    {children}
                </div>
                <div className="col-md-2">
                    <Divider height="100%" width="1px" />
                </div>
                <div className="col-md-5 text-right">
                    
                    <img src={imgSrc} alt="Empty State" className="empty-image" />
                </div>
            </div>
        </div>
    </StyledContainer>
}