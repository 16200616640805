import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddNewClientAdminMethods from '../../utils/api/superAdmin/AddnewClientAdminData';

function* AddNewClientAdminData(payload) {
  const promise = AddNewClientAdminMethods.AddNewClientAdminData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddNewClientAdminAction.ADDNEWCLIENT_RECEIVED, data: res.Response });
}

export  { AddNewClientAdminData }