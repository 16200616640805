import React from 'react';
import { Tab, Tabs, TabsContainer } from '../../common';
import styled from '@emotion/styled';

const TabContainerWrapper = styled(TabsContainer)`
  ul {
    display: flex;
    justify-content: space-between;
  }
`;

export function RecruitWrapper({ stage, children, handleClick }) {
  return (
    <React.Fragment>
      <TabContainerWrapper>
        <div className="row">
          <div className="col-md-8">
            <Tabs>
              <Tab onClick={() => handleClick(1)} active={stage == 1 ? true : false} fontSize="14px">
                Invite
              </Tab>
              <Tab
                onClick={() => handleClick(2)}
                active={stage == 2 ? true : false}
                fontSize="14px"
                activeColor="var(--middleGreen)"
              >
                Applied
              </Tab>
              <Tab onClick={() => handleClick(3)} active={stage == 3 ? true : false} fontSize="14px">
                Confirmed
              </Tab>
              <Tab onClick={() => handleClick(4)} active={stage == 4 ? true : false} fontSize="14px">
                Rejected
              </Tab>
            </Tabs>
          </div>
        </div>
        {children}
      </TabContainerWrapper>
    </React.Fragment>
  );
}
