import React from 'react';
import { useState,useEffect } from 'react';
import { Button, Text, Avatar, Heading } from '..';
import styled from '@emotion/styled';
import {
    InputText,
    TextArea,
    DropFile,
    Dropdown,
    ButtonRadio,
    TaskTypeOption,
    TaskMenu,
} from '../../components/form';
import { Divider } from '..';
import Icon from '../Icon'
import cross from '../../assets/images/cross.svg';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import profile_pic from '../../assets/images/profile_pic.png';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_CLIENTDASHBOARD_DATA } from '../../constants/clientAdmin/clientDashboardConstants';
import { REQUEST_EDITUSERDATA } from '../../constants/clientAdmin/EditUserdataConstants';
import avatar from '../../images/researcher.png';


const field = {
    marginLeft: "0px"
}

const InputTextContainer = styled.div`
	.iconContainer {
		position: absolute;
		top: 30%;
		right: 15px;
	}
	span {
		font-size: 18px;
		color: var(--greyBlue);
	}
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;
const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	float: right;
    margin-right: 5px;
`;

const Pill = styled.span`
	font-size: 9px;
	font-family: "Readex Pro", sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
`;

const gender_list = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' },
];

const city_list = [
    { value: 1, label: 'Ahmedabad' },
    { value: 2, label: 'Bangalore' },
    { value: 3, label: 'Mumbai' },
    { value: 4, label: 'Kolkata' },
    { value: 5, label: 'Delhi' },
];

const qualification_list = [
    { value: 1, label: 'Illiterate' },
    { value: 2, label: 'Literate but no formal schooling' },
    { value: 3, label: 'School (5-9 yrs)' },
    { value: 4, label: 'SSC/HSC' },
    { value: 5, label: 'Some College(Including a diploma but not graduate)' },
    { value: 6, label: 'Graduate/Post-Graduate: General' },
    { value: 7, label: 'Graduate/Post-Graduate: Professional' },
];

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function EditObserverModal({userModaldata, setIsFlag, IsFlag}) {
    const [seeMoreProfile, setSeeMoreProfile] = useState(true);
    const [seeMorePrimary, setSeeMorePrimary] = useState(false);
    const [userType, setUserType] = useState('Respondant');

    const [GetObserverData,setGetObserverData] = useState([]);
    const userId = useSelector(state => state.LoginReducer.userId);
    const dispatch = useDispatch(); 
    useEffect(() => {
		setGetObserverData(userModaldata);
	},[userModaldata])

    const handleChnage = (evt) => {
		const value = evt.target.value;
		setGetObserverData({
			...GetObserverData,
			[evt.target.name]: value,
		});

	};

    const applyuserChanges = () =>{

        const formData ={
            userRole:"Observer",
            name:GetObserverData.name,
            email:GetObserverData.email,
            mobile:GetObserverData.mobile}
            dispatch({
                type: REQUEST_EDITUSERDATA,
                payload: formData
            });
            setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
                () => dispatch({
                    type: REQUEST_CLIENTDASHBOARD_DATA,
                    payload: {
                      user_ID: userId
                    }
                  }), 
                500
              );
            //   setGetTeamData(teamModaldata);
              setIsFlag(1);
        }

    return (
        <div>
            <>

                {/* <Button
                    className='ml-4'
                    size='small'
                    variant='outlined'
                    width='50px'
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                >
                    edit
                </Button> */}

                <div className="modal fade" id="editObserverModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" >
                                <Text><Pill className='mt-2 ml-2'>Observer
                                    <img
                                        src={cross}
                                        className='ml-1 mb-1'
                                        style={{ cursor: 'pointer', borderRadius: '50%' }}
                                    /></Pill></Text>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <Avatar
                                                src={GetObserverData?.presignedUrl != null ? GetObserverData?.presignedUrl : avatar}
                                                className='team-profile-pic'
                                                width='60'
                                                height='60'
                                            />
                                        </div>

                                        <div className='container w-75'>
                                            <div className='row'>
                                                {/* PRofile Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3 mb-3'
                                                    >
                                                        Profile
                                                    </Heading>

                                                    <>
                                                        <InputTextContainer>
                                                            <div className='row' style={field}>
                                                                <div className='col-8'>
                                                                    <InputText
                                                                        name='name'
                                                                        placeholder='Full Name'
                                                                        showCharacters={false}
                                                                        defaultValue={GetObserverData?.name}
                                                                        value={GetObserverData?.name}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <InputText
                                                                        name='mobile'
                                                                        placeholder='Phone No.'
                                                                        showCharacters={false}
                                                                        defaultValue={GetObserverData?.mobile}
                                                                        value={GetObserverData?.mobile}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                            </div>
                                                            <div className='row' style={field}>
                                                                <div className='col-8'>
                                                                    <InputText
                                                                        name='email'
                                                                        placeholder='Email'
                                                                        showCharacters={false}
                                                                        defaultValue={GetObserverData?.email}
                                                                        value={GetObserverData?.email}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <InputText
                                                                        name='designation'
                                                                        placeholder='Designation'
                                                                        showCharacters={false}
                                                                        defaultValue={GetObserverData?.designation}
                                                                        value={GetObserverData?.designation}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                            </div>
                                                        </InputTextContainer>
                                                        {/* ///// reset password */}
                                                        <div className='row' id='ResetPassword'>
                                                            <div className='col col-5 mt-2 mb-2 ml-1'>

                                                                <CustomButton
                                                                    className='ml-0'
                                                                    size='small'
                                                                    variant='outlined'
                                                                    fontSize='8px'
                                                                    data-toggle="modal" data-target="#ResetPasswordObserver"
                                                                    style={{ paddingLeft: '20px', marginRight: '95px', borderRadius: "7px", height: "20px", width: '85px', marginTop: '10px' }}                                                                >
                                                                    Reset Password
                                                                </CustomButton>
                                                                {/* New Modal for Reset Password  */}
                                                                <div className="modal fade" id="ResetPasswordObserver" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                    <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
                                                                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                                                                            <div className="modal-header border-0">
                                                                                <Heading fontSize='18px'>Reset Your Password</Heading>
                                                                                {/* <button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
                                                                                        <span aria-hidden="true">&times;</span>
                                                                                    </button> */}
                                                                            </div>
                                                                            <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                                                                <div className='row mb-2'>
                                                                                    <div className='col col-12'>
                                                                                        <Text type='3' color="var(--ternaryColor)" className="mb-0">We sent a reset password email to Lisam@xyz.com. Please click the reset password link to set your new password.</Text>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='row' style={{ paddingTop: '20px' }}>
                                                                                    <div id='delete' className='col col-3'>
                                                                                        <CustomButton
                                                                                            className='ml-1 mt-1'
                                                                                            size='small'
                                                                                            // variant='outlined'
                                                                                            width='60px'
                                                                                            data-dismiss="modal"
                                                                                        // style={{ color: "#798E8F", background: "none" }}
                                                                                        >
                                                                                            Done
                                                                                        </CustomButton>
                                                                                    </div>
                                                                                    <div id='resendEmail' className='col col-8 col-offset-6' style={{ display: 'flex', justifyContent: 'end' }}>
                                                                                        <Text type="3" color="var(--ternaryColor)" fontSize='10px' className="mt-2"><u>Resend Email</u></Text>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* END */}

                                                            </div>
                                                        </div>
                                                        {/* ///////////////////// */}
                                                    </>
                                                </div>
                                                {/* END */}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer border-0">
                            </div>
                            {/* <div className='container-fluid'> */}
                            <div className='row'>
                                <div className='col-3 modal-footer border-0 ml-4' style={{ justifyContent: "flex-start" }}>
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        // variant='outlined'
                                        width='60px'
                                        onClick={applyuserChanges}
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Save
                                    </CustomButton>
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Cancel
                                    </CustomButton>
                                </div>
                                <div className='col-8'>
                                    <CustomButton
                                        className='ml-2 mt-3'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-toggle="modal" data-target="#DeleteModalObserver" data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Delete
                                    </CustomButton>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                {/* New Modal for Delete User  */}
                <div className="modal fade" id="DeleteModalObserver" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" style={{paddingBottom: "0px"}}>
                                <Heading fontSize='18px'>Confirm Delete</Heading>
                                <button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                <div className='row'>
                                    <div className='col col-12'>
                                        <Text type='2' color="var(--ternaryColor)" className="mb-0">Are you sure you want to delete this Observer?</Text>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingTop: '20px' }}>
                                    <div className='col'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            variant='outlined'
                                            width='60px'
                                            // width='5px'
                                            // height='4px'
                                            fontSize='10px'
                                            style={{ float: "left" }}
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </CustomButton>
                                    </div>
                                    <div id='delete' className='col col-4 col-offset-6'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            // variant='outlined'
                                            width='60px'
                                            data-toggle="modal"
                                            data-target="#UserDeletedModal" data-dismiss="modal"
                                        // style={{ color: "#798E8F", background: "none" }}
                                        >
                                            Delete
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer border-0">
                                <div className='row'>
                                    <div className='col col-4 col-offset-6'>
                                        <Button
                                            className='ml-4'
                                            size='small'
                                            variant='outlined'
                                            width='20px'

                                        >
                                            save changes
                                        </Button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* END */}
            </>
        </div >
    )
}
