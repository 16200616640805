import styled from '@emotion/styled';
import React from 'react';
import { Button, Divider, Heading, Icon } from '.';

const CustomButton = styled(Button)`
	padding: 2px 1rem;
`;
const CustomButtonLink = styled(Heading)`
	padding: 0px;
	padding-bottom: 0px !important;
	margin-bottom: 0px !important;
	color: var(--deepBlue);
	line-height: 10px;
	margin-left: 1rem;
	border-bottom: 1px solid var(--deepBlue);
	cursor: pointer;
	&:hover {
		color: var(--deepBlue);
	}
`;
function ToolTipBox({
	width = '250px',
	number,
	information,
	handleNext,
	handleClose,
	handleDisable,
	handleSkip,
}) {
	return (
		<div className='py-1 px-3' style={{ width: width }}>
			<div
				onClick={handleClose}
				style={{
					position: 'absolute',
					right: '5px',
					top: '5px',
					cursor: 'pointer',
				}}
			>
				<Icon style={{ fontSize: '14px' }} color='var(--deepBlue)'>
					close
				</Icon>
			</div>

			<div className='d-flex align-items-center'>
				<div className='d-flex '>
					<Heading fontSize='14px' fontWeight='400'>
						{number}
					</Heading>
					<Divider
						width='0.5px'
						height='18px'
						background='var(--deepBlue)'
						margin='0'
						className='mx-3'
					/>
				</div>
				<div>
					<Heading
						fontSize='14px'
						fontWeight='700'
						breakHeading={false}
						className='mb-0 pb-0'
						style={{
							whiteSpace: 'pre-line',
							lineHeight: '19px',
						}}
					>
						{information}
					</Heading>
				</div>
			</div>
			<div className='d-flex  align-items-center mt-1'>
				<CustomButton
					fontSize='10px'
					fontWeight='600'
					size='small'
					buttonColor='var(--sapGreen)'
					onClick={handleNext}
				>
					Next
				</CustomButton>
				<div onClick={handleSkip}>
					<CustomButtonLink
						fontSize='10px'
						fontWeight='400'
						size='small'
						simple
					>
						Skip for now
					</CustomButtonLink>
				</div>
				<div onClick={handleDisable}>
					<CustomButtonLink
						fontSize='10px'
						fontWeight='400'
						size='small'
						simple
					>
						Disable
					</CustomButtonLink>
				</div>
			</div>
		</div>
	);
}

export default ToolTipBox;
