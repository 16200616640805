import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Icon from '../common/Icon';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
// import { createBrowserHistory } from "history";
// const history = createBrowserHistory({forceRefresh:true});

const StyledSideNav = styled.div`
	position: fixed;
	left: 0;
	top: 71px;
	background: var(--secondaryColor);
	height: 100%;
	width: 160px;
	padding-top: 1rem;
`;

const SideNavLink = styled(Link)`
	color: var(--white);
	font-weight: 800;
	width: 100%;
	padding: 1rem;
	display: block;
	// text-transform: uppercase;
	display: flex;
	align-items: center;
	margin-top: 0.2rem;
	font-size: 0.75rem;
	border-left: 5px solid transparent;
	:hover,
	&.active {
		background: var(--white);
		color: var(--secondaryColor);
		text-decoration: none;
		border-left: 5px solid var(--snapGreen);
		span {
			color: var(--secondaryColor);
		}
	}

	span {
		display: inline-block;
		margin-right: 0.5rem;
		color: var(--white);
	}
`;

export default function SideNav_SA({ children }) {
    const history = useHistory() 
    const [selected, setSelected] = useState('');
    
    useEffect(() => {
        if(history.location && history.location.pathname.includes('project')){
            setSelected('project');
        }
        else if(history.location && history.location.pathname.includes('empty-dashboard')){
            setSelected('tasks');
        } else {
            setSelected('dashboard')
        }
        
        history.listen((location) => { 
        
           if(location.pathname.includes('calendar'))
           {
               setSelected('calendar');
           }
           else if(location.pathname.includes('empty-dashboard')) {
               setSelected('tasks');
           }
           else if(location.pathname.includes('project')) {
               setSelected('project');
           }
           else if(location.pathname.includes('notifications')) {
                setSelected('notifications');
           }
           else if(location.pathname.includes('data')) {
               setSelected('data');
           }
           else{
               setSelected('dashboard');
           }
        });        
        
     },[history]) 

	return (
		<StyledSideNav>
			<SideNavLink to='/dashboard-super-admin' onClick={() => setSelected('dashboard')}  className={`${selected === 'dashboard' ? 'active' : ''}`}><Icon>grid_view</Icon> Dashboard</SideNavLink>
			<SideNavLink to='/dashboard-super-admin' onClick={() => setSelected('project')} className={`${selected === 'project' ? 'active' : ''}`  }><Icon>business_center</Icon> Subscription</SideNavLink>
			<SideNavLink to='/dashboard-super-admin' onClick={() => setSelected('tasks')} className={`${selected === 'tasks' ? 'active' : ''}`}><Icon>insert_drive_file</Icon> Platform</SideNavLink> 
			<SideNavLink to='/dashboard-super-admin' onClick={() => setSelected('calendar')}  className={`${selected === 'calendar' ? 'active' : ''}`}><Icon>calendar_today</Icon> Projects</SideNavLink>
			<SideNavLink to='/dashboard-super-admin' onClick={() => setSelected('calendar')}  className={`${selected === 'calendar' ? 'active' : ''}`}><Icon>calendar_today</Icon> Users</SideNavLink>
			<SideNavLink to='/dashboard-super-admin' onClick={() => setSelected('data')} className={`${selected === 'data' ? 'active' : ''}`}><Icon>inventory_2</Icon> Respondents</SideNavLink>
			<SideNavLink to='/dashboard-super-admin' onClick={() => setSelected('notifications')} className={`${selected === 'notifications' ? 'active' : ''}`}><Icon>notifications</Icon> Notifications</SideNavLink>
		</StyledSideNav>
	);
}
