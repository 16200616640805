import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './chart.css';
const colors = d3.scaleOrdinal(d3.schemeCategory10);
const format = d3.format('.2f');

const XAxis = ({ top, bottom, left, right, height, scale }) => {
	const axis = useRef(null);

	useEffect(() => {
		d3.select(axis.current).call(
			d3.axisBottom(scale).scale(scale).tickSize(0)
		);
	});
	const reactWidth = scale.bandwidth() / 5.5;
	return (
		<g
			className='axis x  py-2  '
			ref={axis}
			text-anchor='middle'
			transform={`translate(${left - reactWidth}, ${
				height - bottom + 10
			})`}
			strokeWidth='0'
			color='var(--deepBlue)'
			style={{
				fontFamily: 'Noto Sans',
				fontSize: '14px',
				fontWeight: '700',
				color: 'var(--deepBlue)',
			}}
		/>
	);
};

const YAxis = ({ top, bottom, left, right, scale, width }) => {
	const axis = useRef(null);

	useEffect(() => {
		d3.select(axis.current).call(
			d3.axisLeft(scale).scale(scale).tickSize(-width, 0, 0).ticks(4)
		);
	});

	return (
		<g
			className='axis y grid yaxis'
			ref={axis}
			transform={`translate(${left}, ${top})`}
			color='var(--grey20)'
			strokeWidth='0.5'
			fontSize='10px'
			fontWeight='700'
			style={{
				fontFamily: 'Noto Sans',
				fontSize: '12px',
				fontWeight: '400',
				color: 'var(--deepBlue)',
			}}
		></g>
	);
};

const Rect = ({ data, x, y, height, top, bottom }) => {
	const reactWidth = x.bandwidth() / 1.5;
	const reactHeight = height - bottom - top - y(data.y);
	return (
		<g transform={`translate(${x(data.x)}, ${y(data.y)})`}>
			<rect
				width={reactWidth}
				height={reactHeight}
				fill={data.color || colors(data.index)}
			/>
			<text
				textAnchor='middle'
				alignmentBaseline='middle'
				fill={data.y == 0 ? 'black' : 'white'}
				fontSize='12'
				x={reactWidth / 2}
				y={data.y > 0 ? reactHeight / 2 : -10}
			>
				{data.y}
			</text>
		</g>
	);
};

const BarChart = (props) => {
	const [sort, setSort] = useState(false);

	const { addData = true } = props;
	const data = [...props.data];

	const x = d3
		.scaleBand()
		.range([0, props.width - props.left - props.right])
		.domain(data.map((d) => d.x))
		.padding(0.1);

	const y = d3
		.scaleLinear()
		.range([props.height - props.top - props.bottom, 0])
		.domain([
			0,
			addData
				? d3.max(data, (d) => d.y) + 5
				: d3.max(data, (d) => d.y),
		]);

	return (
		<>
			<svg width={props.width} height={props.height}>
				<XAxis
					scale={x}
					top={props.top}
					bottom={props.bottom}
					left={props.left}
					right={props.right}
					height={props.height}
				/>
				<YAxis
					scale={y}
					top={props.top}
					bottom={props.bottom}
					left={props.left}
					right={props.right}
					width={props.width}
				/>
				<g transform={`translate(${props.left}, ${props.top})`}>
					{data.map((d, i) => (
						<Rect
							data={d}
							x={x}
							y={y}
							top={props.top}
							bottom={props.bottom}
							height={props.height}
						/>
					))}
				</g>
			</svg>
		</>
	);
};

export default BarChart;
