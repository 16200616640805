import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { Icon } from '../../common';
import DropdownIcon from '../../images/dropdown.svg';

const tempOptions = [
	{ value: 'Form', label: 'Form', icon: 'description' },
	{ value: 'Video', label: 'Video', icon: 'videocam' },
];

const customStyles = {
	container: (provided, state) => ({
		...provided,
		border: 0,
		borderRadius: 0,
		width: state.selectProps.width || 'auto',
	}),

	control: (provided, state) => ({
		...provided,
		outline: 0,
		borderRadius: 0,
		border: 0,
		borderColor: state.isFocused
			? 'var(--inputColor)'
			: 'var(--borderColor)',
		boxShadow: 'none',
		minHeight: '36px',
		'&:hover': {
			borderColor: state.isFocused
				? 'var(--inputColor)'
				: 'var(--borderColor)',
		},

		...state.selectProps.controlStyle,
	}),

	menu: (provided, state) => ({
		...provided,
		border: '1px solid #EFEFEF',
		borderRadius: '5px',
		width: 'auto',
		padding: '0 10px',
		// position: 'absolute',
		background: '#FFFFFF',
		// width: '220px',
		zIndex: 1,
		boxShadow: 'none',
		left: '50%',
		transform: 'translateX(-50%)',
	}),

	option: (provided, state) => ({
		fontSize: '0.75rem',
		fontWeight: '500',
		color: 'var(--inputColor)',
		padding: '7px',
		borderBottom: '1px solid #E2E2E2',
		display: 'flex',
		justifyCotent: 'center',
		alignItems: 'center',
		cursor: 'pointer',

		'&.material-icons': {
			display: 'inline-block',
			marginRight: '10px',
		},

		'&:last-child': {
			borderBottom: 0,
		},
		...state.selectProps.optionStyle,
	}),

	input: (provided, state) => ({
		...provided,
		margin: '1px',
	}),

	valueContainer: (provided, state) => ({
		...provided,
		justifyContent: 'center',
	}),

	singleValue: (provided, state) => ({
		fontSize: '1rem',
		fontWeight: '800',
		color: 'var(--inputColor)',
		display: 'flex',
		alignItems: 'flex-end',
		...state.selectProps.singleValueStyle,
	}),

	indicatorSeparator: () => ({
		display: 'none',
	}),

	placeholder: () => ({
		fontSize: '0.75rem',
		color: 'var(--mediumGrey)',
	}),

	// menu: (provided, state) => ({
	//   ...provided,
	//   width: state.selectProps.width,
	//   borderBottom: '1px dotted pink',
	//   color: state.selectProps.menuColor,
	//   padding: 20,
	// }),
};

const IconOption = (props) => (
	<components.Option {...props}>
		<Icon className='mr-2'>{props.data.icon}</Icon>
		{props.data.label}
	</components.Option>
);

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<img src={DropdownIcon} alt='Dropdown Icon' />
		</components.DropdownIndicator>
	);
};

const SingleValue = ({ children, ...props }) => (
	<components.SingleValue {...props}>
		<Icon className='mr-2'>{props.data.icon}</Icon>
		{props.data.label}
	</components.SingleValue>
);

export default function Dropdown({
	onChange,
	width,
	controlWidth,
	defaultValue,
	placeholder = 'Select one',
	className = '',
	controlStyle,
	options = tempOptions,
	singleValueStyle,
	optionStyle,
}) {
	return (
		<Select
			controlStyle={controlStyle}
			optionStyle={optionStyle}
			singleValueStyle={singleValueStyle}
			styles={customStyles}
			components={{
				Option: IconOption,
				DropdownIndicator,
				SingleValue,
			}}
			defaultValue={defaultValue}
			options={options}
			width={width}
			controlWidth={controlWidth}
			className={className}
			placeholder={placeholder}
			onChange={onChange}
		/>
	);
}
