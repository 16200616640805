import styled from '@emotion/styled';

const Container = styled.div`
    padding: 0.5rem 1.5rem;
    height: ${props => props.height || '375px'};
    overflow-y: ${props => props.height === 'auto' ? 'unset' : 'auto'};
`

export default function TabsContentContainer({ children, className="", height }) {
    return <Container className={className} height={height}>{children}</Container>
}