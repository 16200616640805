import React from 'react';
import { components } from 'react-select';
import { Icon } from '.';

function DownIndicator({
	color = 'var(--deepBlue)',
	iconStyle,
	iconName = 'keyboard_arrow_down',
	...props
}) {
	return (
		<components.DropdownIndicator {...props}>
			<Icon color={color} style={iconStyle}>
				{iconName}
			</Icon>
		</components.DropdownIndicator>
	);
}

export default DownIndicator;
