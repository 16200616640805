import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as webDashboardMethods from '../../utils/api/dashboard/webDashboardData';

function* webDashboardData(payload) {
  const promise = webDashboardMethods.webDashboardData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.webDashboardAction.WEBDASHBOARD_DATA_RECEIVED, data: res.Response });
}

export  { webDashboardData }