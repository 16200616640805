import maverickPortalhttp from '../../webService/webService';
import maverickPortalApiEndPoint from '../../../config/apiEndPoint';
import { createBrowserHistory } from "history";
import { Store } from '../../../config/sagaConfig';

const history = createBrowserHistory({forceRefresh:true});

const addTag = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(maverickPortalApiEndPoint.addTag.url, maverickPortalApiEndPoint.addTag.methodType+'/18')
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if(response.data?.Status && response.data?.Status.StatusCode === -2){
					localStorage.removeItem('token')
          history.push('/#/sign-in');
					console.log(response.data?.Status.StatusDesc);
					resolve({ res });
        }
				else {
					console.log(response.data?.Status.StatusDesc);
				}
        const res = response.data;
        resolve({ res });
      });
  });
};
const getTags = (data) => {
	// ge thte selected project and recruote id from reducer
	/** const  */
// 	const data = {
//     "user_ID": 18
// }
// console.log({data})
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getTags.url}/${data.user_ID}/${data.project_ID}`,
        maverickPortalApiEndPoint.getTags.methodType
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } 
				else {
					console.log(response.data?.Status && response.data?.Status.StatusCode === -2);
				}
        const res = response.data;
        resolve({ res });
      });
  });
};

export { addTag, getTags };
