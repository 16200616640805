import React from 'react';
import styled from '@emotion/styled';
import EditButtom from '../assets/images/edit.png';

const IMG = styled.img`
  line-height: 12px;
  width: ${props => props.width || '18px'};
  font-size: 8pt;
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export default function EditButton({ width = null }) {
  return (
    <React.Fragment>
      <IMG src={EditButtom} alt="Edit" width={width} />
    </React.Fragment>
  );
}
