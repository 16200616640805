import styled from '@emotion/styled';
import { Tab, Tabs, TabsContainer } from '.';

export const NavTabs = styled(Tabs)`
	padding: 0;
	border: none;
	position: relative;
`;

export const NavTabsContainer = styled(TabsContainer)`
	padding: 0;
	border: none;
	position: relative;
`;

export const NavTab = styled(Tab)`
	margin-right: 0rem;

	&.semi-active button {
		color: #c2c2c2;
	}
	background: rgba(121, 142, 143, 0.1);

	&:last-child {
		button {
			border-top-right-radius: 5px;
		}
	}
	
	&:first-of-type {
		button {
			border-top-left-radius: 5px;
			
		}
	}

	button {
		padding: 0.5rem 1.4rem;
		overflow: hidden;
		border: 1px solid #e2e2e2;
		// border-bottom: 1px solid var(--secondaryColor);
		color: var(--deepBlue);
		font-size: ${(props) =>
		props.big ? '1rem' : props.fontSize || '0.625rem'};
		font-weight: 500;
		// cursor:${(props)=>props.Cursor?props.Cursor:'pointer'}
	 cursor: ${(props) => (props.disabled ? 'not-allowed' : props.Cursor ? props.Cursor : 'pointer')};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')}; 
	}

	&.active button,
	button:hover {
		transform: translateY(1px);
		color: ${(props) =>
		props.activeColor ? props.activeColor : 'var(--secondaryColor)'};
		font-weight: 600;
        
		font-size: ${(props) =>
		props.big ? '1rem' : props.fontSize || '0.625rem'};
		// padding: 0.625rem 2.25rem;
		padding: 0.625rem 1.56rem;
		background: var(--white);
		border: 1px solid var(--secondaryColor);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		// border-top:3px solid var(--primaryColor);
		border-bottom: none;

		&:after {
			content: '';
			width: 100%;
			height: 3px;
			border-radius: 30px;
			background: var(--snapGreen);
			top: -0.3px;
			left: 0;
			position: absolute;
		}
	}
`;
