import React, { useState, useEffect, useRef } from "react";
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  event,
  schemeTableau10,
  scaleOrdinal
} from "d3";

export const StackedBarGraph = (props) => {
  //const [data, setData] = useState(datasets);
  const svgRef = useRef();
  const wrapperRef = useRef();
  const colors = schemeTableau10
  const [dkeys,setDkeys] = useState([])
    const [dcolors,setDcolors] = useState({})

  useEffect(() => {
    const svg = select(svgRef.current);
    const { width, height } = wrapperRef.current.getBoundingClientRect();
    let tdata ={}
    for(let i =0; i < props.data.length; i++){
        if(!tdata.hasOwnProperty(props.data[i].cn1)){
            tdata[props.data[i].cn1] = {}
        }
        if(!tdata[props.data[i].cn1].hasOwnProperty(props.data[i].cn3)){
            tdata[props.data[i].cn1][props.data[i].cn3] = {}
        }

        tdata[props.data[i].cn1][props.data[i].cn3][props.data[i][props.secondary]] = props.data[i].anscnt
        //tdata[props.data[i].cn1]['type'] = props.data[i][props.tertiary]
        
    }
    let tkeys = Object.keys(tdata)
    let fdata = []
    for(let i=0;i < tkeys.length;i++){
        let ttkeys = Object.keys(tdata[tkeys[i]])
        for(let j=0;j < ttkeys.length;j++){
            let hold = tdata[tkeys[i]][ttkeys[j]]
            
                let ttt = {
                    name: tkeys[i],
                    type: ttkeys[j]
                }
                ttt = {...ttt,...hold}
                fdata.push(ttt)
            
            
        }
        
    }
    let keys = props.data.reduce((f,d)=>{
        if(f.indexOf(d[props.secondary]) == -1){
            f.push(d[props.secondary])
        }
        return f
    },[])

    setDkeys(keys)

    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(fdata);

    
    const extent = [
      0,
      max(layers, (layer) => max(layer, (sequence) => sequence[1]))
    ];
    
    const yScale = scaleLinear().domain(extent).range([height, 20]);

    const x0Scale = scaleBand()
      .domain(fdata.map((d) => d.name))
      .range([0, width])
      .padding(0.1);
    const x1Scale = scaleBand()
      .domain(fdata.map((d) => d.type))
      .rangeRound([0, x0Scale.bandwidth()])
      .padding(0.15);

     

   
    // const tooltipDiv = tooltip
    //   .append("xhtml:div")
    //   .style("visibility", "visible")
    //   .style("background-color", "black")
    //   .style("color", "white")
    //   .style("border", "solid")
    //   .style("border-width", "1px")
    //   .style("border-radius", "5px")
    //   .style("padding", "10px")
    //   .html("<p>I'm a tooltip written in HTML</p>");

    const xAix = axisBottom(x0Scale);
    const yAix = axisLeft(yScale);

    const color = scaleOrdinal(keys, colors);

    let cd = {}
        for(let i=0; i < keys.length;i++){
          cd[keys[i]] = color(i)
        }

        setDcolors(cd)

    svg
      .select(".x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(xAix);
    svg
      .select(".y-axis")
      .attr("transform", `translate(${0 + 25}, 0 )`)
      .call(yAix);


    svg.selectAll('.content > *').remove()

    svg
    .selectAll('.content')
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .attr("fill", (layer,i) =>{
        return color(i)
    })
      .selectAll("rect")
      .data((layer) => layer)
      .join("rect")
      .attr("class", "barreact")
      
      .attr(
        "x",
        (sequence) => x0Scale(sequence.data.name) + x1Scale(sequence.data.type)
      )
      .attr("width", x1Scale.bandwidth())
      .attr("y", (sequence) => yScale(sequence[1]))
      .attr("height", (sequence) => yScale(sequence[0]) - yScale(sequence[1]))

      let gr = svg
      .selectAll(".layer")
      .filter(function(d, i,list) {
        return i === list.length - 1;
    })
    .selectAll('rect')

    let ngroup = svg.selectAll('.content').append("g")
    gr.each(function (p, j) {
       
        ngroup
        .append("text")
           .text(function(d) {   
               if(p.data.type != undefined && p.data.type != 'undefined'){
                return p.data.type;
               }

               return ''
                         
                   
           })
           .attr("x",function(d){
               
               return x0Scale(p.data.name)+x1Scale(p.data.type)+(x1Scale.bandwidth()/2)
           })
           .attr("y", function(d){
               
               return yScale(p[1])-5
           })
           .attr("text-anchor", "middle")
           .attr("font-family","Noto Sans")
           .attr("font-size", "11px")
           .attr("fill", "black");
    });

    let ar = svg
    .selectAll('.content')
    .selectAll('rect')

    let agroup = svg.selectAll('.content').append("g")
    ar.each(function (p, j) {
      
      agroup
      .append("text")
         .text(function(d) {   
               
             if( (p[1] - p[0])  != 0){
              return p[1] - p[0];
             }else{
               return ''
             }
                  
         })
         .attr("x",function(d){
            
             return x0Scale(p.data.name)+x1Scale(p.data.type)+(x1Scale.bandwidth()/2)
         })
         .attr("y", function(d){
             
             return yScale(p[1]) + ((yScale(p[0]) - yScale(p[1]))/2)
             
         })
         .attr("text-anchor", "middle")
         .attr("font-family","Noto Sans")
         .attr("font-size", "11px")
         .attr("fill", "white");
  });
    
    
      
        //    .attr(
        //     "x",
        //     (sequence) => {
        //         console.log("text x");
        //         console.log(x0Scale(sequence.data.name) + x1Scale(sequence.data.type));
        //         return x0Scale(sequence.data.name) + x1Scale(sequence.data.type)
        //     }
        //   )
        // .attr("y", function(d){
        //     return yScale(d) - 5;
        // })
        //    .attr("x", function(d, i) {
        //         return i * (w / d.data.length) + (w / d.data.length ) / 2;
        //    })
        //    .attr("y", function(d) {
        //         return h - (d * 4) + 14;
        //    })
        //    .attr("font-family", "sans-serif")
        //    .attr("font-size", "11px")
        //    .attr("fill", "white");
    //   .on("mouseover", function () {
    //     return tooltipDiv.style("visibility", "visible");
    //   })
    //   .on("mousemove", function () {
    //     console.log(tooltipDiv);
    //     const htmlContentHeight = tooltipDiv.node().getBoundingClientRect()
    //       .height;
    //     tooltip.attr("height", htmlContentHeight);
    //     tooltip.raise();
    //     return tooltip
    //       .attr("x", event.pageX)
    //       .attr("y", event.pageY - htmlContentHeight);
    //   })
    //   .on("mouseout", function () {
    //     return tooltipDiv.style("visibility", "hidden");
    //   });
    svg.select(".barreact");

    svg
      .select(".x-axis")
      .selectAll(".tick")
      .on("click", (e) => {
        const filteredD = data.map((d, index) => {
          if (d.name === e && !d.facebook) {
            return datasets[index];
          }
          return {
            name: d.name,
            type: d.type,
            facebook: d.name === e ? null : d.facebook,
            google: d.name === e ? null : d.google,
            linkedin: d.name === e ? null : d.linkedin
          };
        });

        setData(filteredD);
      });
  }, [props]);

  return (
    <>
      <div
        ref={wrapperRef}
        style={{ width: "100%", height: "200px", marginBottom: "2rem" }}
      >
        <svg ref={svgRef} style={{ width: "100%", height: "110%" }}>
          <g className="x-axis" />
          <g className="y-axis" />
          <g className="content" />
        </svg>
        <div>
        <div style={{fontSize:'12px', marginBottom:'4px'}}>Contribution</div>
        <div style={{ width: "100%", height: "50px", marginTop:'20px', display:'flex' }}>
            
              {dkeys.map((d,i)=>{
                return (
                  <div style={{marginRight: '8px', display:'flex'}} key={'color_'+d} >
                    <div style={{width:'16px',height:'16px',backgroundColor:dcolors[d]}}>&nbsp;</div>
                    <div style={{marginLeft:'4px', fontSize:'12px'}}>{d}</div>
                  </div>
                )
              })

              }
              
        </div>
        </div>
      </div>
    </>
  );
};

export default StackedBarGraph
