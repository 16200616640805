import React, { useState, useEffect, useRef } from 'react';
import ProjectWrapper from './ProjectWrapper';
import { createBrowserHistory } from "history";
import {
	Heading,
	HelperText,
	Card,
	Text,
	ProgressBar,
	Button,
	Divider,
	Tabs,
	Tab,
	TabsContainer
} from '../../common';
import {
	OverviewCardSmall,
	OverviewCardBig,
	RecruitWrapper,
	RecruitStatus,
	Notification,
	ProfileBuilder
} from '../../containers';
import styled from '@emotion/styled';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import constants from "../../constants";
import { REQUEST_INVITEE_DATA } from '../../constants/invite-recruit/GetInviteeConstants';

const history = createBrowserHistory({ forceRefresh: true });

const TemplateCard = styled(Card)`
    padding: 0;
    height: 100%;
    position: relative;
    box-shadow:${(props) => (props.boxShadow ? props.boxShadow : `0px 2px 8px 1px rgba(0, 0, 0, 0.2)`)};
    width:${(props) => (props.width ? props.width : 'auto')};
    height:${(props) => (props.height ? props.height : '191px')};
    position:relative;
    
    img{
        width: 90px;
        height:90px;
        margin-bottom:1rem;
    }

    .hovered {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;
        
    }

    &.active {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
    }
`;

const ProgressStatus = styled(ProgressBar)`
   height:8px;
`;
const ButtonInvite = styled(Button)`
    width: 65px;
    height: 24px;
    font-size: 10px;
    padding:0;
`;

const CardContainer = styled(Card)`
  height: 204.73px;
`;

function InviteRecruit(props) {
	const dispatch = useDispatch();
	// const [projectTitle, setProjectTitle] = useState('');
	const [isShown, setIsShown] = useState(false);
	const [isActiveOverview, setIsActiveOverview] = useState(false);
	const [isActiveTask, setIsActiveTask] = useState(false);
	const [stage, setStage] = useState(1);
	const [appliedStage, setAppliedStage] = useState(1);
	const { REQUEST_RECRUITSGROUPDATA } = constants.RecruitsGroupConstant;
	const [groupId, setGroupId] = useState(3);
	const [showTables, setShowTables] = useState(false);
	const [selectedTaskId, setSelectedTaskId] =  useState('');
	const userId = useSelector(state => state.LoginReducer.userId);
	// const projectId = useSelector(state => state.ProjectReducer.project_ID);
	const { projectId } = useParams();
	const projectTitle = props.projectTitle != undefined ? props.projectTitle : "";
	const data = { user_ID: userId, project_ID: projectId }
	const SendInviteData = props.SendInviteData != undefined ? props.SendInviteData : "";
	const ConfirmAssignData = props.ConfirmAssignData != undefined ? props.ConfirmAssignData : "";
	const RejectedData = props.RejectedData != undefined ? props.RejectedData : "";
	const ReassignprojectgroupData = props.ReassignprojectgroupData != undefined ? props.ReassignprojectgroupData : "";

	useEffect(() => {
		dispatch({
			type: REQUEST_INVITEE_DATA,
			payload: {
				"user_ID": userId,
				"projectGroup_ID": "1",
				"project_ID": projectId,
				"type": "invite",
				"isOriginal": 1
			}
		});
		dispatch({ type: 'REQUEST_PROJECTSETUPDATA', payload: data });
		const Payload = {
			user_ID: userId,
			project_ID: projectId
		};
		dispatch({ type: 'RESUME_DATA', payload: Payload });
	}, []);

	const Projectset = useSelector(state => state.ProjectsetReducer);
	const projectgroup = Projectset.projectGroup;

	useEffect(() => {
		let changestage;
		if (appliedStage == 1) {
			changestage = "applied_all";
		} else if (appliedStage == 2) {
			changestage = "applied_invited";
		} else if (appliedStage == 3) {
			changestage = "applied_directly";
		}

		if (stage == 2 && appliedStage) {
			dispatch({
				type: REQUEST_RECRUITSGROUPDATA,
				payload: {
					"user_ID": userId,
					"projectGroup_ID": "1",
					"project_ID": projectId,
					"type": changestage
				}
			});
		}
	}, [appliedStage]);

	const handleClickStage = (stage) => {
		if (stage == 1) {
			setAppliedStage(0)
		} else if (stage == 2) {
			setAppliedStage(1)
		}
		setStage(stage);
		if (stage == 1) {
			stage = "invite";
		} else if (stage == 2) {
			stage = "applied_all";
		} else if (stage == 3) {
			stage = "confirmed";
		} else if (stage == 4) {
			stage = "rejected";
		} else {
			stage = "invite";
		}
		if (stage == "invite") {

			dispatch({
				type: REQUEST_INVITEE_DATA,
				payload: {
					"user_ID": userId,
					"projectGroup_ID": "1",
					"project_ID": projectId,
					"type": "invite",
					"isOriginal": 1
				}
			});

		} else {
			dispatch({
				type: REQUEST_RECRUITSGROUPDATA,
				payload: {
					"user_ID": userId,
					"projectGroup_ID": "1",
					"project_ID": projectId,
					"type": stage
				}
			});
		}

	}
	const InviteeData = useSelector(state => state.GetInviteeReducer.InviteeData);
	const InviteeCount = useSelector(state => state.GetInviteeReducer.InviteeCount);
	const ProjectDetails = useSelector(state => state.GetInviteeReducer.ProjectDetails);
	const containerRef = useRef(null);

	const getInviteeData = () => {
		const projectGroupId = `G${groupId}`;
		if (InviteeData.hasOwnProperty(projectGroupId)) {
			return InviteeData[projectGroupId]
		}
	}
	
	const RecruitsGroupwiseData = useSelector(state => state.RecruitsGroupReducer.FilterRecruitsGroupwise);
	const RecruitsGroupwiseCount = useSelector(state => state.RecruitsGroupReducer.RecruitGroupCount);
	const taskDetails = useSelector(state => state.GetInviteeReducer.taskDetails);
	const getRecruitsGroupwiseData = () => {
		const projectGroupId = `G${groupId}`;
			if (RecruitsGroupwiseData.hasOwnProperty(projectGroupId)) {
				return RecruitsGroupwiseData[projectGroupId]
			}else{
				return RecruitsGroupwiseData
			}
		
	}

	useEffect(() => {
	}, [isActiveOverview]);

	useEffect (() =>{
	},[RecruitsGroupwiseData,ProjectDetails]);

	const updateTaskStatus = () => {
		const data = {
			user_ID: userId,
			projectTask_ID: taskDetails[0].projectTask_ID
		}
		dispatch({ type: 'REQUEST_UPDATE_TASK_STATUS', payload: data });
		setTimeout(() => {
			history.push(`/project/${projectId}/design`);
		}, 500);
	}

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };

	const projectStatus = props.projectStatus != undefined ? props.projectStatus : "";

	return projectgroup && (
		<ProjectWrapper projectTitle={ProjectDetails?.title} step={2} projectStatus={projectStatus} projectId={projectId}>
			<Heading type="2" className="mt-4" fontWeight="600" color="var(--seaBlue)">
				In this section...
			</Heading>
			<HelperText fontSize="0.99rem">
				First select a task to choose and invite respondents to participate in
			</HelperText>
			<div style={{ position: 'relative' }}>
				<CardContainer padding="1rem 3rem" style={{ height: showTables ? 'auto' : '12.5rem' }}>
					<div className={`row flex-nowrap ${showTables ? 'mb-3' : ''} pb-5`} ref={containerRef} style={{ display: 'flex', gap: '10px', scrollBehavior: 'smooth',position: 'relative', overflowX: 'hidden'}}>
						{taskDetails?.map ((item) =>
							!isActiveOverview ? (
							<div className="col-md-3 col-md-22 p-0" id={item.projectTask_ID} key={item.projectTask_ID} style={{ minWidth: "250px" }}>
								<div className="d-flex justify-content-around">
									<TemplateCard
										className="card-border  pt-4 pb-4 "
										onMouseEnter={() => setIsShown(item.projectTask_ID)}
										onMouseLeave={() => setIsShown(false)}
										width="172px"
										height="auto"
									>
										{isShown !== item.projectTask_ID ? (
											<OverviewCardSmall
												title={item.taskName}
												width="90px"
												height="90px"
												icon={item.taskType == "Form" ? "description" : "videocam"}
												stats={item.taskCompletionCount + "/" + item.totalCount}
												percentage={item.taskCompletionCount * 100 / item.totalCount}
											/>

										) : (
											<React.Fragment>
												<div>
													<Text type="4" fontSize="10px" className="textalign">
														{item.taskName}
													</Text>
												</div>
												<div>

													{item.projectGroup.map((projectGroupData, pgdIndex) => {
															const samplesizeVar = projectgroup[pgdIndex]?.sampleSize ;
															return (
																<div className="p-2" key={pgdIndex}>
																	<Text type="5" fontSize="10px" className="lineheight-18 textalign">
																		{projectGroupData.title} - {projectGroupData.CompletionCountwrtGrp}/{samplesizeVar}
																	</Text>
																	<ProgressStatus width={(projectGroupData.CompletionCountwrtGrp >0 && projectGroupData.countRespondentwrtGrp > 0) ? projectGroupData.CompletionCountwrtGrp * 100 / samplesizeVar : 0} className="m-1" />
																</div>
															)
														})
													}

													<div className="p-2 d-flex justify-content-around">
														<ButtonInvite
															primary
															className="pt-1 pb-1"
															fontSize="0.75rem"
															fill={false}
															onClick={() => {
																setShowTables(true);
																handleClickStage(1);
																setIsActiveOverview(true);
																setIsActiveTask(false);
																setSelectedTaskId(item.projectTask_ID)
															}}
														>
															Invite
														</ButtonInvite>
														<ButtonInvite
															primary
															className="pt-1 pb-1"
															fontSize="0.75rem"
															onClick={() => {
																setShowTables(true);
																handleClickStage(2);
																setIsActiveOverview(true);
																setIsActiveTask(false);
																setSelectedTaskId(item.projectTask_ID)
															}}
														>
															Review
														</ButtonInvite>
													</div>
												</div>
											</React.Fragment>
										)}
									</TemplateCard>
									{/* <Divider margin="0" orientation="vertical" height="auto" width="1px" /> */}
								</div>
							</div>
						): (
							!isActiveTask && (selectedTaskId != item.projectTask_ID) ? (
								<div className="col-md-3 col-md-22 p-0" id={item.projectTask_ID}>
								<div className="d-flex justify-content-around">
									<TemplateCard
										className="card-border  pt-4 pb-4 "
										onMouseEnter={() => setIsShown(item.projectTask_ID)}
										onMouseLeave={() => setIsShown(false)}
										width="172px"
										height="auto"
									>
										{isShown !== item.projectTask_ID ? (
											<OverviewCardSmall
												title={item.taskName}
												width="90px"
												height="90px"
												icon={item.taskType == "Form" ? "description" : "videocam"}
												stats={item.taskCompletionCount + "/" + item.totalCount}
												percentage={item.taskCompletionCount * 100 / item.totalCount}
											/>

										) : (
											<React.Fragment>
												<div>
													<Text type="4" fontSize="10px" className="textalign">
														{item.taskName}
													</Text>
												</div>
												<div>

													{item.projectGroup.map((projectGroupData,pgdIndex) => {
																	const samplesizeVar = projectgroup[pgdIndex]?.sampleSize ;
																	return (
																		<div className="p-2">
																			<Text type="5" fontSize="10px" className="lineheight-18 textalign">
																				{projectGroupData.title} - {projectGroupData.CompletionCountwrtGrp}/{samplesizeVar}
																			</Text>
																			<ProgressStatus width={(projectGroupData.CompletionCountwrtGrp >0 && projectGroupData.countRespondentwrtGrp > 0) ? projectGroupData.CompletionCountwrtGrp * 100 / samplesizeVar : 0} className="m-1" />
																		</div>
																	)
																})
													}

													<div className="p-2 d-flex justify-content-around">
														<ButtonInvite
															primary
															className="pt-1 pb-1"
															fontSize="0.75rem"
															fill={false}
															onClick={() => {
																setShowTables(true);
																handleClickStage(1);
																setIsActiveOverview(true);
																setIsActiveTask(false);
																setSelectedTaskId(item.projectTask_ID)
															}}
														>
															Invite
														</ButtonInvite>
														<ButtonInvite
															primary
															className="pt-1 pb-1"
															fontSize="0.75rem"
															onClick={() => {
																setShowTables(true);
																handleClickStage(2);
																setIsActiveOverview(true);
																setIsActiveTask(false);
																setSelectedTaskId(item.projectTask_ID)
															}}
														>
															Review
														</ButtonInvite>
													</div>
												</div>
											</React.Fragment>
										)}
									</TemplateCard>
									{/* <Divider margin="0" orientation="vertical" height="auto" width="1px" /> */}
								</div>
							</div>
							) : (
								<div className="col-md-9 ml-4 mr-4" id={item.projectTask_ID}>
									<TemplateCard
										className="card-border  pt-4 pb-4 "
										boxShadow="0px 12px 24px rgba(0, 0, 0, 0.3)"
										height="auto"

									>
										<OverviewCardBig
											title={item.taskName}
											width="90px"
											height="90px"
											icon={item.taskType == "Form" ? "description" : "videocam"}
											invites={item.InviteeCount}
											application={item.ApplicationsCount}
											percentage={item.taskCompletionCount * 100 / item.totalCount}
											data={item.projectGroup.map((projectGroupData,pgdIndex) => {
															const samplesizeVar = projectgroup[pgdIndex]?.sampleSize ;
															return (
																<><div className="my-2">
																	<Text type="4" fontSize="12px" className="lineheight-18 ">
																		{projectGroupData.groupTag} - {projectGroupData.title} - {projectGroupData.CompletionCountwrtGrp} / {samplesizeVar}
																	</Text>
																	<ProgressStatus width={(projectGroupData.CompletionCountwrtGrp >0 && projectGroupData.countRespondentwrtGrp > 0) ? projectGroupData.CompletionCountwrtGrp * 100 / samplesizeVar : 0} className="m-1" />
																</div></>
															)
														})
											}
										>
										</OverviewCardBig>
									</TemplateCard>
									{/* <Divider margin="0" orientation="vertical" height="auto" width="1px" /> */}
								</div>
							)
						))} 
					</div>
				</CardContainer>

				{/* Left Arrow Button */}
				<button 
					onClick={scrollLeft} 
					className="btn"
					style={{
						position: 'absolute',
						top: '50%',
						left: '10px',
						transform: 'translateY(-50%)',
						zIndex: 1,
						background: 'transparent',
						border: 'none',
						fontSize: '1.5rem', // Make the arrow larger
						color: 'gray',
						outline: 'none',
						boxShadow: 'none',
						cursor: 'pointer'
					}}
				>
					&lt;
				</button>

				{/* Right Arrow Button */}
				<button 
					onClick={scrollRight} 
					className="btn"
					style={{
						position: 'absolute',
						top: '50%',
						right: '10px',
						transform: 'translateY(-50%)',
						zIndex: 1,
						background: 'transparent',
						border: 'none',
						fontSize: '1.5rem', // Make the arrow larger
						color: 'gray',
						outline: 'none',
						boxShadow: 'none',
						cursor: 'pointer'
					}}
				>
					&gt;
				</button>
			</div>
			
			{showTables && <>
				<Heading type="5" breakHeading={false} color="var(--headingColor)" className="mt-5">
					Pick the right{' '}
					<Text fontWeight="800" color="var(--headingColor)" span>
						Respondents
					</Text>{' '}
				</Heading>
				<Text type="3" className="mb-4">
					Select and invite potential respondents to participate in the Usage Overview task for {projectTitle}.
				</Text>
				{/* Action Component */}
				<div className="row mt-4">
					<div className="col-md-12 p-0">
						<RecruitWrapper stage={stage} handleClick={handleClickStage}>
							{stage == 1 && (
								<div>
									<RecruitStatus data={InviteeCount} />
									<div className="row mt-4">
										<div className="col-md-8 col-md-70 pl-0 ">
											<Card padding="0">
												<ProfileBuilder stage={stage} appliedStage={appliedStage} RecruitsGroupwiseData={InviteeData ? getInviteeData() : []} groupIdChange={setGroupId} projectgroup={projectgroup} SendInviteData={SendInviteData}/>
											</Card>
											<div className="mt-4 d-flex justify-content-center">
												<Button primary className="mt-3" size="mid" fontSize="13px" onClick={() => updateTaskStatus()}>
													Start Designing Tasks
												</Button>
											</div>
										</div>
										<div className="col-md-4 p-0">
											<Notification />
										</div>
									</div>
								</div>
							)}
							{stage == 2 && (
								<div>
									<div className="row">
										<div className="col-md-8 offset-1">
											<TabsContainer>
												<div className="row">
													<div className="col-md-8">
														<Tabs>
															<Tab
																active={appliedStage == 1 && true}
																fontSize="14px"
																onClick={() => setAppliedStage(1)}
															>
																All
															</Tab>
															<Tab
																active={appliedStage == 2 && true}
																fontSize="14px"
																onClick={() => setAppliedStage(2)}
															>
																Invited
															</Tab>
															<Tab
																active={appliedStage == 3 && true}
																fontSize="14px"
																onClick={() => setAppliedStage(3)}
															>
																Directly
															</Tab>
														</Tabs>
													</div>
												</div>
											</TabsContainer>
										</div>
									</div>
									<RecruitStatus data={RecruitsGroupwiseCount} />
									<div className="row mt-4">
										<div className="col-md-8 col-md-70 pl-0 pr-0">
											<Card padding="0">
												<ProfileBuilder stage={stage} appliedStage={appliedStage} RecruitsGroupwiseData={RecruitsGroupwiseData ? getRecruitsGroupwiseData() : []} groupIdChange={setGroupId} projectgroup={projectgroup} ConfirmAssignData={ConfirmAssignData} />
											</Card>
											<div className="mt-4 d-flex justify-content-center">
												<Button primary className="mt-3" size="mid" fontSize="13px" onClick={() => updateTaskStatus()}>
													Start Designing Tasks
												</Button>
											</div>
										</div>
										<div className="col-md-4 pr-0">
											<Notification />
										</div>
									</div>
								</div>
							)}
							{stage == 3 && (
								<div>
									<RecruitStatus data={RecruitsGroupwiseCount} />
									<div className="row mt-4">
										<div className="col-md-8 col-md-70 pl-0 pr-0">
											<Card padding="0">
												<ProfileBuilder stage={stage} appliedStage={appliedStage} RecruitsGroupwiseData={RecruitsGroupwiseData ? getRecruitsGroupwiseData() : []} groupIdChange={setGroupId} projectgroup={projectgroup} ReassignprojectgroupData={ReassignprojectgroupData}/>
											</Card>
											<div className="mt-4 d-flex justify-content-center">
												<Button primary className="mt-3" size="mid" fontSize="13px" onClick={() => updateTaskStatus()}>
													Start Designing Tasks
												</Button>
											</div>
										</div>
										<div className="col-md-4 pr-0">
											<Notification />
										</div>
									</div>
								</div>
							)}
							{stage == 4 && (
								<div>
									<RecruitStatus data={RecruitsGroupwiseCount}/>
									<div className="row mt-4">
										<div className="col-md-8 col-md-70 pl-0 pr-0">
											<Card padding="0">
												<ProfileBuilder stage={stage} appliedStage={appliedStage} RecruitsGroupwiseData={RecruitsGroupwiseData ? getRecruitsGroupwiseData() : []} groupIdChange={setGroupId} projectgroup={projectgroup} RejectedData={RejectedData} />
											</Card>
											<div className="mt-4 d-flex justify-content-center">
												<Button primary className="mt-3" size="mid" fontSize="13px" onClick={() => updateTaskStatus()}>
													Start Designing Tasks
												</Button>
											</div>
										</div>
										<div className="col-md-4 pr-0">
											<Notification />
										</div>
									</div>
								</div>
							)}
						</RecruitWrapper>
					</div>
				</div></>}
		</ProjectWrapper>
	);
}

const mapStateToProps = (state) => {
	return {
		// will be available as props.captureData
		SendInviteData: state.SendInviteReducer.SendInviteData,
		ConfirmAssignData: state.ConfirmAndAssignReducer.ConfirmAssignData,
		RejectedData: state.SendRejectReducer.RejectedData,
		ReassignprojectgroupData: state.ReassignProjectGroupsReducer.ReassignprojectgroupData,
		//projectTitle: state.ProjectReducer.projectIdentity,
		projectStatus: state.ProjectReducer.resumeData.projectStatus != undefined ? state.ProjectReducer.resumeData.projectStatus[0]?.steps_array != undefined ? state.ProjectReducer.resumeData.projectStatus[0]?.steps_array : "" : "",
		projectTitle: state.ProjectReducer.resumeData.project_data != undefined ? state.ProjectReducer.resumeData.project_data[0].identity : "",
	}
  }
  
  // export default EditTab;
  export default connect(mapStateToProps)(InviteRecruit);