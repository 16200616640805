import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddVideoTagMethods from '../../utils/api/field/AddVideoTagData';

function* AddVideoTagData(payload) {
  const promise = AddVideoTagMethods.AddVideoTagData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddVideoTagAction.ADDVIDEOTAG_RECEIVED, data: res.Response });
}

export  { AddVideoTagData }