import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Section = styled.div`
	padding: 6.5rem 1rem;
	overflow: hidden;

	&.landing-section {
		padding-top: 13rem;
	}

	.container-fluid {
		height: 100%;
	}

	.content {
		height: 100%;
		display: flex;
		align-items: center;
	}

	img {
		max-width: 100%;
	}

	.icon {
		/* width: 27px; */
	}

	.dark-content,
	.dark-content a {
		font-size: 14px;
		font-weight: 700;
		color: var(--seaBlue);
	}
	.smallScreen {
		display: none;
	}
	@media (max-width: 720px) {
		padding: 3.25rem 1rem;
		&.landing-section {
			padding-top: 7rem;
		}
		.bigScreen {
			display: none;
		}
		.smallScreen {
			display: block;
		}
		.checkbox-container {
			flex-direction: column;

			label {
				width: 100%;
			}
		}
	}
`;

export const GreenButton = styled.button`
	font-size: 14px;
	background: var(--sapGreen);
	border-radius: 5px;
	border: 0;
	padding: 0.5rem 1rem;
	text-transform: uppercase;
	color: var(--white);
	font-weight: 700;
	padding: 0.5rem 1.5rem;
`;

export const GreyButton = styled.button`
	font-size: 14px;
	background: transparent;
	border-radius: 5px;
	border: 1px solid var(--seaBlue);
	color: var(--seaBlue);
	font-weight: 700;
	text-transform: uppercase;
	padding: 0.5rem 1.5rem;
`;

export const HeadingStyling = (props) => css`
	font-size: 48px;
	font-weight: 700;
	color: var(--deepBlue);

	.light {
		font-weight: 400;
	}

	.break {
		display: block;
	}
`;

export const H1 = styled.h1`
	${HeadingStyling}
`;

export const H2 = styled.h2`
	${HeadingStyling}
`;

export const H3 = styled.h3`
	font-size: 36px;
	font-weight: 700;
	color: var(--deepBlue);
`;

export const Step = styled.div`
	h2 {
		font-size: 48px;
		font-weight: 700;
		color: #dddddd;

		:hover {
			color: var(--sapGreen);
			font-weight: 800;
			cursor: pointer;
		}
	}

	.sub-heading,
	.sub-content {
		display: none;
	}

	&.active h2 {
		color: var(--sapGreen);
		font-weight: 800;
	}

	&.active .sub-heading,
	&.active .sub-content {
		display: block;
	}
`;

export const SubHeading = styled.p`
	font-size: 20px;
	font-weight: 700;
	color: ${(props) => props.color || "var(--deepBlue)"};
`;

export const SubContent = styled.p`
	font-size: 18px;
	font-weight: 400;
	color: var(--seaBlue);
`;

export const StyledLabel = styled.label`
	font-size: 0.875rem;
	font-weight: 500;
	color: var(--seaBlue);
	margin-bottom: 0;
`;
