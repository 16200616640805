import styled from '@emotion/styled';
import React, { useState, useEffect, Children } from 'react';
import { Button, Divider, Heading, Icon } from '../../common';
import './ParticipantsPopup.css';
import crossImg from '../../images/cross.svg';
import avatar from '../../images/researcherlist.png'
import { color } from 'd3';
const CustomButton = styled(Button)`
  padding: 2px 1rem;
  color: var(--deepBlue);
  border: 1px solid var(--deepBlue);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
  :hover {
    color: var(--deepBlue);
  }
`;
const styles = {
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '3px',
    paddingBottom: '3px',
    borderBottom: '0.25px solid #798E8F',
    width: '125px'
  },



  avatar: {
    width: '16px',
    height: '16px',
    zindex: 1,
    transform: 'translateY(-5px)'
  },

  displayDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
  displayAvatar: {
    width: '16px',
    height: '16px',
    borderRadius: '1px',
    marginRight: '10px'
  },
  displayName: {
    color: '#3A3A3A',
    font : 'Noto Sans',
    fontSize: '10px',
    fontWeight: '500',
    textAlign: 'left',
    lineHeight: '15.32px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  seeMore: {
    color: '#2361A0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  }
};
function ParticipantsPopup({
  width = '50%',
  height = '50%',
  number,
  heading,
  showed,
  children,
  secondheading,
  secondheading2,
  handleNext,
  onClose,
  handleDisable,
  handleSkip,
  showCancel = true,
  showCross = true,
  secondHeadingFontSize = '14px',
  showAllParticipants,
  handleToggleParticipants,
  ParticipantsData

}) {
  const [show, setShow] = useState(false);
  const closeHandler = e => {
    setShow(false);
    onClose(false);
  };
  useEffect(() => {
    setShow(showed);
  }, [showed]);

   const participantsToShow = showAllParticipants ? ParticipantsData : ParticipantsData?.slice(0, 2);
    const remainingCount = ParticipantsData?.length - participantsToShow?.length;

  return (
    <div
      className="d-flex  popup1  overlay1"
      style={{ width: width,
        visibility: show ? 'visible' : 'hidden', opacity: show ? '1' : '0' }}
      
    >
       <div className="popup1"    >
      
      <div className="flex popup_inner1 text-start"   onMouseLeave={closeHandler}>
        {heading && (
          <Heading
          color={'#3A3A3A'}
            fontSize="15px"
            fontWeight="700"
            breakHeading={false}
            className="mb-1"
            style={{
              textAlign: 'left',
              paddingTop: '0.5rem',
              display: 'flex',
              justifyContent: 'space-between', 
              alignItems: 'center',
              borderBottom: '0.25px solid #798E8F',
              marginBottom: '3px',
              paddingBottom: '3px',
            }}
          >
            {heading}

            {showCross && (
              <div style={{ float: 'right' ,marginLeft: '1rem'}}>
                <button
                  style={{ backgroundColor: 'white', border: 'none', color: 'black', padding: '0.1rem', cursor:'pointer' }}
                  onClick={closeHandler}
                >
                  <img src={crossImg} style={{ width: '12px', height: '12px'}}></img>{' '}
                </button>
              </div>
            )}
          </Heading>
        )} 

        {secondheading && (
          <Heading
            // fontSize="14px"
            fontSize={secondHeadingFontSize}
            fontWeight="400"
            breakHeading={false}
            className="mb-3 pb-0 justify-content-start"
            style={{
              whiteSpace: 'pre-line',
              lineHeight: '19px',
              textAlign: 'left',
             
            }}
          >
            {secondheading}
          </Heading>
        )}

        {secondheading2 && (
          <Heading
            fontSize="14px"
            fontWeight="400"
            breakHeading={false}
            className="mb-3 pb-0"
            style={{
              whiteSpace: 'pre-line',
              lineHeight: '19px',
              marginTop: '-18px',
              textAlign: 'left'
            }}
          >
            {secondheading2}
          </Heading>
        )}

        <div className="grid row " style={{ justifyContent: 'space-between', marginLeft:'0px' }}>
          {showCancel && (
            <div onClick={closeHandler}>
              <CustomButton
                fontSize="15px"
                fontWeight="500"
                color="black"
                size="large"
                buttonColor="var(--white)"
                // onClick={handleClose}
              >
                Cancel
              </CustomButton>
            </div>
          )}

          {/* {showCross && } */}
          {/* <div className="content">{children}</div> */}
          <div style={styles.displayDataContainer}>
            {participantsToShow?.map((item, index) => (
              <div key={index} style={styles.userInfo}>
             <img src={avatar} className="mr-2" style={styles.displayAvatar} alt={`Avatar for user`} />
                <div style={styles.displayName}>{item.researcherName}</div>
              </div>
            ))}
            {ParticipantsData?.length > 2 && !showAllParticipants && (
              <div style={styles.seeMore} onClick={handleToggleParticipants}>
                +{remainingCount} more participants
              </div>
            )}
            {ParticipantsData?.length > 2 && showAllParticipants &&  (
              <div style={styles.seeMore} onClick={handleToggleParticipants}>
                Show less
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
export default ParticipantsPopup;