import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetProfileMethods from '../../utils/api/profile/GetProfileData';

function* GetProfileData(payload) {
  const promise = GetProfileMethods.GetProfileData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetProfileAction.GETPROFILEDATA_RECEIVED, data: res.Response });
}

export { GetProfileData };
