import styled from '@emotion/styled';

const P = styled.p`
    font-size: ${props => props.fontSize || '0.875rem'};
    font-weight: 300;
    color: var(--greyBlue);
`

export default function HelperText({ children, className="", fontSize=null }){
    return <P className={className} fontSize={fontSize}>{children}</P>
}