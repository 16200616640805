import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
//   height: 100vh;
`;

const Dot = styled(animated.div)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color || '#ff4500'};
  position:relative;
  top:7px;
`;

const PulsatingDot = ({color}) => {
  const [styles, api] = useSpring(() => ({
    from: { transform: 'scale(1)' },
    to: async (next) => {
      while (1) {
        await next({ transform: 'scale(1.5)' });
        await next({ transform: 'scale(1)' });
      }
    },
    config: { duration: 1000 },
  }));

  useEffect(() => {
    api.start();
  }, [api]);

  return (
    <Container>
      <Dot style={styles} color={color} />
    </Container>
  );
};

export default PulsatingDot;