import React, { useEffect } from 'react';
import video_play from '../../images/video_play.svg';
import analysis_video from '../../images/analysis_video.png';
import note_blue from '../../images/note_blue.svg';
import tag_grey from '../../images/tag_grey.svg';

import styled from '@emotion/styled';
import { Heading } from '../../common';
import { Checkbox } from '../../components/form';
import {   useDispatch, useSelector} from 'react-redux';

const Container = styled.div`
	background: var(--midWhite);

	// margin: 0.5rem;
	margin-left: 0;
	// margin: 0.5rem 0;
	margin-bottom: 1rem;
	// margin-right: 0.5rem;
	.image {
		// width: 14rem;
	}
	label {
		margin-bottom: 0 !important;
	}
	.img {
		position: relative;
	}
	.video_play {
		position: absolute;
		left: 40%;
		top: 38%;
	}
	.iconContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		.imgContainer:hover {
			.popup {
				display: block;
			}
		}
		.imgContainer {
			position: relative;
			background: var(--darkWhite);
			margin-left: 5px;
			padding: 10px;
			width: 28px;
			height: 28px;
			border-radius: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
			.popup {
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18);
				border-radius: 5px;
				background: var(--white);
				font-size: 10px;
				font-weight: 500;
				color: var(--deepBlue);
				padding: 0.5rem;
				position: absolute;
				width: 160px;
				bottom: -40px;
				left: 10px;
				z-index: 1080;
				display: none;
			}
		}
	}
`;
function AnalysisAudio({ checkBoxValue, imgLink,onPlay, selectCheckBox, name, imgId,qid, selection }) {

	// useEffect(()=>{
	// 	console.log('selection '+imgId.toString(),selection);
	// 	console.log('selection val'+imgId.toString(),Object.keys(selection).indexOf(imgId.toString()))
	// },[selection])

	return (
		<Container>
			<div className='audio'>
                <audio controls style={{width : '100%',maxHeight: '250px'}} onPlay={onPlay}>
                    <source src={imgLink} type='audio/mpeg'/>
                </audio>
			</div>

			<div className='d-flex p-2 justify-content-between'>
				<div className='d-flex align-items-center'>
					<Checkbox
						color='var(--deepBlue)'
						fontSize='10px'
						iconSize='10px'
						inactiveColor='var(--white)'
						value={imgId}
						checked={Object.keys(selection).indexOf(imgId.toString()) != -1}
						onChange={(ev)=>{
							
							selectCheckBox({
								path:imgLink,
								name: name,
								imgId:imgId
							})
						}}
					/>
					<div className='pl-1 d-flex'>
						

						<Heading
							fontSize='10px'
							fontWeight='700'
							color='var(--deepBlue)'
							className='mb-0 pb-0 pl-1'
							breakHeading={false}
						>
							{name}
						</Heading>
					</div>
				</div>

				{/* <div className='iconContainer'>
					<div className='imgContainer'>
						<img src={tag_grey} />
						<div className='popup'>
							Hits, Usability, Ergonomic, Compact
						</div>
					</div>
					<div className='imgContainer'>
						<img src={note_blue} />
						<div className='popup'>
							The handle is too wide to comfortably hold
						</div>
					</div>
				</div> */}
			</div>
		</Container>
	);
}

export default AnalysisAudio;
