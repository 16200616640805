import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { Heading, Icon, Text } from '../../common';
import {
	NavTab,
	NavTabs,
	NavTabsContainer,
} from '../../common/NewTab';
import { InputText } from '../../components/form';

const Container = styled.div`
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-item: center;
	border-bottom: 1px solid var(--deepBlue);
`;

function ProfileSettingWrapper({ step = 1, children }) {
	return (
		<div>
			<div className='d-flex '>
				<Icon style={{ fontSize: '36px' }} color='var(--sapGreen)'>
					arrow_back_ios
				</Icon>
				<div>
					<Heading
						fontSize='28px'
						fontWeight='700'
						// className='mb-0 pb-0'
						color='var(--seaBlue)'
					>
						Profile & Settings
					</Heading>
					<Text
						fontSize='14px'
						fontWeight='300'
						className='mb-0 pb-0'
						color='var(--seaBlue)'
					>
						Update your profile info and change notification
						settings to your preference
					</Text>
				</div>
			</div>

			<Container className='mt-4'>
				<NavTabsContainer className='px-3 '>
					<NavTabs>
						<Link to='/profile'>
							<NavTab active={step === 1} fontSize='12px'>
								Profile
							</NavTab>
						</Link>
						<Link to='/security'>
							<NavTab active={step === 2} fontSize='12px'>
								Security
							</NavTab>
						</Link>
					</NavTabs>
				</NavTabsContainer>
			</Container>

			<div className='py-5 px-5'>{children}</div>
		</div>
	);
}

const CustomInputText = styled(InputText)`
	border-bottom: 1.5px solid var(--deepBlue);
`;

export const Input = ({ labelText, style, ...props }) => {
	return (
		<div style={style}>
			<Heading
				fontSize='16px'
				fontWeight='500'
				color='var(--deepBlue)'
			>
				{labelText}
			</Heading>

			<CustomInputText
				characterColor='var(--mediumGrey)'
				characterBottom='7px'
				fontSize='16px'
				fontWeight='800'
				color='var(--deepBlue)'
				{...props}
			/>
		</div>
	);
};

export default ProfileSettingWrapper;
