import React from 'react';
import { Card, Avatar, Text, Button, Icon } from '../../common';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const ButtonBase = styled(Button)`
   width:${(props) => props.width || 'auto'};
   height:${(props) => props.height || 'auto'};
   color:${(props) => props.color};
`;
export function CallCompleted() {
	return (
		<Card className="pb-5 pt-5">
			<div className="d-flex flex-column justify-content-center align-items-center">
				<Avatar src="https://i.pravatar.cc/150?img=60" width="159px" height="159px" />
				<div className="d-flex flex-column align-items-center">
					<div className="d-flex mt-4">
						<Text type="5" fontSize="14px">
							Interview with{' '}
						</Text>
						<Text type="4" fontSize="14px" className="ml-1">
							R3 - Sahil R.
						</Text>
						<Text type="1" fontSize="14px" color="var(--middleGreen)" className="ml-1">
							- Completed
						</Text>
					</div>
					<Text type="3" fontSize="12px" className="mt-1" fontWeight="300">
						G1 - Intending Buyer Affluent Class
					</Text>

					<div className="d-flex justify-content-center align-items-center mt-4">
						<Link to="/project/1/field">
							<ButtonBase
								simple
								className="d-flex align-items-center mr-2"
								fontWeight="700"
								fontSize="11px"
							>
								<Icon color="var(--middleGreen)">keyboard_arrow_left</Icon>
								Back to Field
							</ButtonBase>
						</Link>

						<Link to="/project/1/clipping">
							<ButtonBase className="p-0" width="128px" height="30px" fontWeight="700" fontSize="11px">
								Edit Clips
							</ButtonBase>
						</Link>
					</div>
				</div>
			</div>
		</Card>
	);
}
