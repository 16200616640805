import RESET_PASSWORD from '../../constants/reset-password/ResetPasswordConstants';


// const { RESET_PASSWORD } = constants.ResetPasswordConstants;

export default {
    RESET_PASSWORD,

  
    resetPassword: payload => ({
      type: RESET_PASSWORD,
      payload
    })
  };