import styled from '@emotion/styled';
import { css } from '@emotion/react';

const headingStyles = (props) => css`
	color: ${props.color || 'var(--headingColor)'};

	span {
		display: ${props.breakHeading ? 'block' : 'inline-block'};
	}
`;

const H1 = styled.h1`
	${headingStyles};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1.75rem')};
`;

const H2 = styled.h2`
	${headingStyles};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1.625rem')};
`;

const H3 = styled.h3`
	${headingStyles};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1.375rem')};
`;

const H4 = styled.h4`
	${headingStyles};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '800')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1.125rem')};
`;

const H5 = styled.h5`
	${headingStyles};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1.125rem')};
`;

const H6 = styled.h6`
	${headingStyles};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '800')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
`;

const SmallHeading = styled.p`
	font-size: 0.72rem;
	font-weight: 400;
	color: var(--textColor);
`;

export default function Heading({
	children,
	type = null,
	className = '',
	index = null,
	breakHeading = true,
	color = null,
	fontSize = null,
	small = null,
	fontWeight = null,
	...props
}) {
	if (type === '6') {
		return (
			<H6
				breakHeading={breakHeading}
				color={color}
				fontSize={fontSize}
				fontWeight={fontWeight}
				className={`m-heading ${className}`}
				{...props}
			>
				{children}
			</H6>
		);
	}

	if (type === '5') {
		return (
			<H5
				breakHeading={breakHeading}
				color={color}
				fontSize={fontSize}
				fontWeight={fontWeight}
				className={`m-heading ${className}`}
				{...props}
			>
				{children}
			</H5>
		);
	}

	if (type === '4') {
		return (
			<H4
				breakHeading={breakHeading}
				color={color}
				fontSize={fontSize}
				fontWeight={fontWeight}
				className={`m-heading ${className}`}
				{...props}
			>
				{children}
			</H4>
		);
	}

	if (type === '3') {
		return (
			<H3
				breakHeading={breakHeading}
				color={color}
				fontSize={fontSize}
				fontWeight={fontWeight}
				className={`m-heading ${className} time-index${index}`}
				{...props}
			>
				{children}
			</H3>
		);
	}

	if (type === '2') {
		return (
			<H2
				breakHeading={breakHeading}
				color={color}
				fontSize={fontSize}
				fontWeight={fontWeight}
				className={`m-heading ${className}`}
				{...props}
			>
				{children}
			</H2>
		);
	}

	if (small) {
		return (
			<SmallHeading className={className} {...props}>
				{children}
			</SmallHeading>
		);
	}

	return (
		<H1
			breakHeading={breakHeading}
			color={color}
			fontSize={fontSize}
			fontWeight={fontWeight}
			className={`m-heading ${className}`}
			{...props}
		>
			{children}
		</H1>
	);
}
