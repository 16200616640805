import constants from '../../constants';

const {
  REQUEST_ADD_TAG,
  ADD_TAG_REQUEST_RECEIVED,
  REQUEST_GET_TAGS_LIST,
  TAGS_LIST_RECEIVED
} = constants.TagsConstants;
export default {
  REQUEST_ADD_TAG,
  ADD_TAG_REQUEST_RECEIVED,
  REQUEST_GET_TAGS_LIST,
  TAGS_LIST_RECEIVED,
  addTag: payload => ({
    type: REQUEST_ADD_TAG,
    payload
  }),
  addTagReceived: () => ({
    type: ADD_TAG_REQUEST_RECEIVED
  }),
  getTagList: payload => ({
    type: REQUEST_GET_TAGS_LIST,
    payload
  }),
  tagListReceived: () => ({
    type: TAGS_LIST_RECEIVED
  })
};
