import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as analysisClipsDataMethods from '../../utils/api/analysis/analysisClipsData';

function* analysisClipsData(payload) {
  const promise = analysisClipsDataMethods.analysisClipsData(payload.payload);
  const { res } = yield promise;
  
  yield putResolve({ type: actions.analysisAction.ANALYSIS_CLIPS_DATA_RECEIVED, data: res.Response });
}

export  { analysisClipsData }