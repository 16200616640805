import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as RecruitsGroupMethods from '../../utils/api/invite-recruit/RecruitsGroupData';

function* RecruitsGroupData(payload) {
  const promise = RecruitsGroupMethods.RecruitsGroupData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.RecruitsGroupAction.RECRUITSGROUPDATA_RECEIVED, data: res.Response });
}

export  { RecruitsGroupData }