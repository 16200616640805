import styled from '@emotion/styled';
import Text from './Text';

const Notification = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;

    .notification-text {
        flex: 1;
        padding-right: 1.5rem;
    }
`

export default function NotificationInfo({children, className="",}) {
    return <Notification className={`${className}`}>
        {children}
    </Notification>
}