import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditProfileMethods from '../../utils/api/profile/EditProfileData';

function* EditProfileData(payload) {
  const promise = EditProfileMethods.EditProfileData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditProfileAction.EDITPROFILEDATA_RECEIVED, data: res });
}

export { EditProfileData };
