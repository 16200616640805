import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';

import * as otpVerificationMethods from '../../utils/api/otpVerification/otpVerificationData';
function* OtpVerificationData(payload) {
  const promise = otpVerificationMethods.ValidateOtpWeb(payload.payload);
  const { res } = yield promise;
  

  yield putResolve({ type: actions.otpVerifyAction.VERIFY_OTP_RECIEVED, data: res.Response });
}

export { OtpVerificationData };
