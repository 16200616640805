import actions from '../../actions';

// reducer with initial state
const initialState = {
  userName: null,
  emailAddress: null,
  userId: null,
  isloggedIn: false,
  roleId: null,
  status:null
};
const getUsername = data => {
  return data.user_name;
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.LoginActions.REQUEST_USER_LOGIN:
      return { ...state, isloggedIn: false };
    case actions.LoginActions.USER_LOGIN_RECEIVED:
      return {
        ...state,
        isloggedIn: true,
        userName: getUsername(action.data.Response),
        emailAddress: action.data.Response.user_email,
        userId: action.data.Response.user_id,
        roleId: action.data.Response.user_role_ID,
        status:action.data.Status.StatusDesc
      };
    case actions.LoginActions.REQUEST_USER_LOGOUT:
      return { ...state, isloggedIn: false };
    case actions.LoginActions.USER_LOGOUT_RECEIVED:
      return {
        ...state,
        isloggedIn: false,
        userName: null,
        emailAddress: null,
        userId: null,
        roleId: null
      };

    default:
      return state;
  }
};

export default reducer;