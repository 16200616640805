import styled from '@emotion/styled';
import React from 'react';

const ScaleItem = styled.div`
	width: 32px;
	height: 3px;
	margin-right: 2px;
	background: ${(props) => props.color};
	color: ${(props) => props.color};
	position: relative;
	span {
		font-weight: 500;
		font-size: 10px;
		position: absolute;
		top: 5px;
	}
`;

const ScaleContainer = styled.div`
	display: flex;
	position: absolute;
	right: 0;
	top: -30px;
	p {
		font-weight: normal;
		font-size: 10px;
		line-height: 14px;
		/* identical to box height */

		letter-spacing: 0.15em;
		text-transform: uppercase;
		padding: 0;
		position: absolute;
		top: -18px;
	}
	.high {
		left: 0;
		color: #335679;
	}
	.neutral {
		right: 36%;
		color: #a6a6a6;
	}
	.low {
		right: 0;
		color: #c90404;
	}
`;
const scaleList = [
	{
		label: 7,
		color: '#335679',
	},
	{
		label: 6,
		color: '#2361A1',
	},
	{
		label: 5,
		color: '#5083CC',
	},
	{
		label: 4,
		color: '#A6A6A6',
	},
	{
		label: 3,
		color: '#ED7D7D',
	},
	{
		label: 2,
		color: '#E55555',
	},
	{
		label: 1,
		color: '#C90404',
	},
];
function Scale({ style }) {
	return (
		<ScaleContainer style={style}>
			<p className='low'>Lowest</p>
			<p className='neutral'>Neutral</p>
			<p className='high'>Highest</p>
			{scaleList.map((item) => (
				<ScaleItem color={item.color}>
					<span>{item.label}</span>
				</ScaleItem>
			))}
		</ScaleContainer>
	);
}

export default Scale;
