import React, { useState } from 'react';
import Thumbnail from '../../images/image_large.png';
import { Icon, Button, Text, ProgressMultipleBar } from '../../common';
import { InputText, Dropdown } from '../../components/form';

export function ImageDisplay({
	img = Thumbnail,
	width = '112px',
	height = '62px',
	alt = 'thumbnail',
	className = 'rounded'
}) {
	return (
		<React.Fragment>
			<img src={img} className={className} alt={alt} width={width} height={height} />
		</React.Fragment>
	);
}

const userList = [
	{ img: Thumbnail, text: '1. Device with plug + wire place on table.', tag: '', slected: true },
	{ img: Thumbnail, text: '2. Device with plug + wire place on table.', tag: '', slected: false }
];

export function PreviewImage({ open, handleClose, className, height }) {
	const [state, setState] = useState(userList);

	const [selected, setSelected] = useState(0);

	const handleNext = (index) => {
		// + 1
		// setSelected(userList[index])
	};
	const handlePrev = (index) => {
		// -1
		// setSelected(userList[index])
	};

	return (
		<div
			className={`"modal fade absolute-position ${open && 'show'} ${className}`}
			id="exampleModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog relative-position" role="document">
				<div className="modal-content">
					<div className={`modal-body`}>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => handleClose()}
						>
							<span aria-hidden="true">&times;</span>
						</button>

						<div className="p-4">
							<ImageDisplay img={userList[selected].img} width="100%" height="200px" />

							<div className="mt-3">
								<Text type="4" fontSize="12px">
									1. Device with plug + wire place on table.
								</Text>
								<div className="row">
									<div className="col-md-6 pt-1">
										<InputText
											type="text"
											showCharacters={false}
											className="form-control"
											id="note"
											placeholder="Enter a note"
											showCharacters={false}
										/>
									</div>
									<div className="col-md-6">
										<Dropdown placeholder="Choose Tag" />
									</div>
								</div>
							</div>
						</div>

						<Button simple className="absolute-position image-left-arrow">
							<Icon>keyboard_arrow_left</Icon>
						</Button>
						<Button simple className="absolute-position image-right-arrow">
							<Icon>keyboard_arrow_right</Icon>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
