import React, { useState, useEffect } from 'react';
import ProfileSettingWrapper, { Input } from './ProfileSettingWrapper';
import profile_pic from '../../images/profile_pic.png';
import styled from '@emotion/styled';
import { Button, Heading, HelperText, Text } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_CHANGEPASSWORDWEB } from '../../constants/Security/ChangePasswordWeb';

const Image = styled.img`
	height: 200px;
	width: 200px;
	border-radius: 100px;
`;
const CustomButton = styled(Button)`
	color: var(--sapGreen);
	padding: 0;

	border-bottom: 1px solid var(--sapGreen);
	&:hover {
		color: var(--sapGreen);
	}
`;
const initialState = {
	password: '',
	new_password: '',
	new_password_text: 'Please use 8 - 32 characters, at least 1 number, and 1 letter.',
	confirm_password_text: '',
	confirm_password: '',
};
function SecurityScreen() {
	const [values, setValues] = useState(initialState);
	const userId = useSelector(state => state.LoginReducer.userId);
	const dispatch = useDispatch();
	const [IsFlag,setIsFlag] = useState(0);

	const handleChnage = (evt) => {
		const value = evt.target.value;
		if (evt.target.name === 'new_password') {
			if ((evt.target.value.length < 8 || evt.target.value.length > 32) && !(/\d/.test(evt.target.value)) && !(/[a-z]/i.test(evt.target.value))) {
				values.new_password_text = 'Please use 8 - 32 characters, at least 1 number, and 1 letter.'
			} else if ((evt.target.value.length < 8 || evt.target.value.length > 32) && !(/[a-z]/i.test(evt.target.value))) {
				values.new_password_text = 'Please use 8 - 32 characters, at least 1 letter.'
			} else if ((evt.target.value.length < 8 || evt.target.value.length > 32) && !(/\d/.test(evt.target.value))) {
				values.new_password_text = 'Please use 8 - 32 characters, at least 1 number.'
			} else if ((evt.target.value.length < 8 || evt.target.value.length > 32)) {
				values.new_password_text = 'Please use 8 - 32 characters'
			}  else if (!(/[a-z]/i.test(evt.target.value)) && !((/\d/.test(evt.target.value)))) {
				values.new_password_text = 'Please use at least 1 number, and 1 letter.'
			} else if (!(/[a-z]/i.test(evt.target.value))) {
				values.new_password_text = 'Please use at least 1 letter.'
			} else if (!(/\d/.test(evt.target.value))) {
				values.new_password_text = 'Please use at least 1 number.'
			} else {
				values.new_password_text = ''
			}
		}
		if (evt.target.name === 'confirm_password') {
			if (evt.target.value === values.new_password) {
				values.confirm_password_text = ''
			} else {
				values.confirm_password_text = 'New Password and Confirm Password is not matching'
			}
		}
		setValues({
			...values,
			[evt.target.name]: value,
		});
	};

	const applyPasswordChanges = () =>{
		

		 const Payload = {
			user_ID: userId,
			currentPassword :values.password,
			newPassword:values.new_password,
			confirmPassword:values.confirm_password
		  };
		  
		  dispatch({ type: 'REQUEST_CHANGEPASSWORDWEB', payload: Payload });
		
	}

	const discardPasswordChanges = () =>{

		setValues(initialState);
		setIsFlag(1);
   }

  	 useEffect(() => {
	 },[values])


	return (
		<ProfileSettingWrapper step={2}>
			<Heading
				fontWeight='500'
				fontSize='18px'
				color='var(--deepBlue)'
			>
				Change Password
			</Heading>
			<div className='row   mt-3'>
				<div className='col-md-4'>
					<Input
						type='password'
						labelText='Current Password'
						name='password'
						value={values.password}
						showCharacters={false}
						style={{ paddingRight: '3rem' }}
						onChange={handleChnage}
						setIsFlag={setIsFlag}
						IsFlag={IsFlag}
					/>
				</div>
				<div className='col-md-4'>
					<div>
						<Input
							type='password'
							showCharacters={false}
							labelText='New Password'
							name='new_password'
							value={values.new_password}
							style={{ paddingRight: '3rem' }}
							onChange={handleChnage}
							setIsFlag={setIsFlag}
							IsFlag={IsFlag}
						/>
						<Text
							fontSize='10px'
							fontWeight='400'
							color='var(--deepBlue)'
							className='mt-2'
						>
							{ values.new_password_text }
						</Text>
					</div>
				</div>
				<div className='col-md-4'>
					<Input
						type='password'
						showCharacters={false}
						labelText='Confirm Password'
						name='confirm_password'
						value={values.confirm_password}
						style={{ paddingRight: '3rem' }}
						onChange={handleChnage}
						setIsFlag={setIsFlag}
						IsFlag={IsFlag}
					/>
					<Text
						fontSize='10px'
						fontWeight='400'
						color='var(--deepBlue)'
						className='mt-2'
					>
						{ values.confirm_password_text }
					</Text>
				</div>
			</div>
			<div className='d-flex mt-5'>
				<Button
					fontSize='13px'
					fontWeight='700'
					size='small'
					buttonColor='var(--sapGreen)'
					className='mr-4'
					onClick={applyPasswordChanges}
				>
					Apply Changes
				</Button>
				<Button
					fontSize='13px'
					fontWeight='700'
					size='small'
					buttonColor='var(--sapGreen)'
					variant='outlined'
					onClick={discardPasswordChanges}
				>
					Discard Changes
				</Button>
			</div>
		</ProfileSettingWrapper>
	);
}

export default SecurityScreen;
