import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditVideoNoteMethods from '../../utils/api/field/EditVideoNoteData';

function* EditVideoNoteData(payload) {
  const promise = EditVideoNoteMethods.EditVideoNoteData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditVideoNoteAction.EDITVIDEONOTE_RECEIVED, data: res.Response });
}

export  { EditVideoNoteData }