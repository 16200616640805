import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopup from '../../common/CustomPopup';
import { Button, Divider, Heading, Icon, ActionIcon, Text } from '../../common';
import { InputText, TextArea } from '../../components/form';
import { CustomButton } from '../../common/commonStyled';
import DesignQuestion from './DesignQuestion';
import maverickPortalhttp from '../../utils/webService/webService';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faL } from '@fortawesome/free-solid-svg-icons';
const CustomButtont = styled(Button)`
  padding: 2px 1rem;
  color: var(--white);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
`;
const CustomDivider = () => <Divider width="100%" height="1px" margin="1.5rem 0" background="var(--greyBlue)" />;

export default function DesignFormTab({
  task,
  nextTask,
  taskList,
  questionTypeList,
  handleTaskTab,
  sectionIndex,
  ValueTypeChoices,
  addQuestionDisable,
  setAddQuestionDisable,
  questionIndex
}) {
  const loggedUser = useSelector(state => state.LoginReducer);
  const projectTask_ID = task.projectTask_ID;
  const [index, setIndex] = useState(null);
  const { projectId } = useParams();
  const [popup, setPopup] = useState(false);
  const [formTask, setFormTask] = useState(task);
  const [formTaskSections, setFormTaskSections] = useState(taskList);
  const dispatch = useDispatch();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  const [popupColor, setPopupColor] = useState('');
  const [message, setMessage] = useState(false);
  const addSec = useSelector(state => state.ProjectReducer?.addSection);
  const editSec = useSelector(state => state.ProjectReducer?.editSection);
  const editQues = useSelector(state => state.TaskReducer?.editQuestion);
  const [editSectionIndex, seteditSectionIndex] = useState(null);
  const addQues = useSelector(state => state.TaskReducer?.addQuestion);
  const deleteSec = useSelector(state => state.ProjectReducer?.deleteSec);
  const deleteQues = useSelector(state => state.TaskReducer?.deleteQues);

  const [sectionmessage, setsectionMessage] = useState(null);
  const [newQuestionIndex, setNewQuestionIndex] = useState(null);
  const [editQuestionIndex, setEditQuestionIndex] = useState(null);
  const [questionmessage, setquestionMessage] = useState(null);
  const [sectionNameError, setSectionNameError] = useState(false);
  const [sectionSavedStatus, setSectionSavedStatus] = useState([]);
  const [switchvalue, setSwitchValue] = useState(0);
  const [QuestionIndex, setQuestionIndex] = useState(null);
  const [isNewSectionForm, setisNewSectionForm] = useState(false);
  const [addSectionDisable, setAddSectionDisbale] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [deletesectionmessage, setdeletesectionMessage] = useState(null);
  const [deleteSectionIndex, setDeleteSectionIndex] = useState(null);
  const [deleteQuestionIndex, setDeleteQuestionIndex] = useState(null);

  //Rutuja's Message for AddSection  Message /Error
  useEffect(() => {

    if (addSec?.StatusCode == 1) {
      setPopupColor('var(--sapGreen)');
      setsectionMessage('Section Added Successfully!');
    }
    if (addSec?.StatusCode == -1) {
      setPopupColor('red');
      setsectionMessage('Error While Adding Section');
    }

    const timeout = setTimeout(() => {
      setsectionMessage(null);
      seteditSectionIndex(null);
      dispatch({ type: 'CLEAR_ADD_SECTION' });
      dispatch({ type: 'CLEAR_EDIT_SECTION' });
    }, 3000);
  }, [addSec, editSec]);

  //Rutujas code for add question message
  useEffect(() => {
    if (addQues && addQues !== undefined) {
      if (addQues?.StatusCode == 1) {
        setIsPopup(true);
        setPopupColor('var(--sapGreen)');
        setquestionMessage('Question Added Successfully!');
      }
      if (addQues?.StatusCode == -1) {
        setIsPopup(true);
        setPopupColor('red');
        setquestionMessage('Error While Adding Question');
      }

      const timeout = setTimeout(() => {
        setIsPopup(false);
        setquestionMessage(null);
        seteditSectionIndex(null);
        dispatch({ type: 'CLEAR_ADD_QUESTION' });
      }, 3000);
    }
  }, [addQues]);

  useEffect(() => {
    if (editSec?.StatusCode == 1) {
      setPopupColor('var(--sapGreen)');
      setsectionMessage('Section Edited Successfully!');
    }
    if (editSec?.StatusCode == -1) {
      setPopupColor('red');
      setsectionMessage('Error While Editing The Section');
    }

    const timeout = setTimeout(() => {
      setsectionMessage(null);
      dispatch({ type: 'CLEAR_EDIT_SECTION' });
    }, 3000);
  }, [editSec]);

  useEffect(() => {
    if (editQues && editQues !== undefined) {
      if (editQues?.StatusCode == 1) {
        setIsPopup(true);
        setPopupColor('var(--sapGreen)');
        // setquestionMessage('Question Edited Successfully!');
        setquestionMessage(editQues?.StatusDesc);
      }
      if (editQues?.StatusCode == -1) {
        setIsPopup(true);
        setPopupColor('red');
        // setquestionMessage('Question response is already saved. You can not Edit question.');
        setquestionMessage(editQues?.StatusDesc);
      }

      const timeout = setTimeout(() => {
        setIsPopup(false);
        setquestionMessage(null);
        dispatch({ type: 'CLEAR_EDIT_QUESTION' });
      }, 3000);
    }
  }, [editQues]);

  useEffect(() => {
    if (deleteSec?.StatusCode == 1) {
      setIsPopupVisible(true);
      setPopupColor('var(--sapGreen)');
      setdeletesectionMessage(deleteSec?.StatusDesc);
    }
    if (deleteSec?.StatusCode == -1) {
      setIsPopupVisible(true);
      setPopupColor('red');
      setdeletesectionMessage(deleteSec?.StatusDesc);
    }

    // getCriteriaBudgetQuestion();
    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
        maverickPortalApiEndPoint.getProjectTask.methodType
      )
      .then(response => {
        let taskData = response.data.Response;

        setFormTaskSections(taskData?.GetProjectTaskQuestions);
      });
    const timeout = setTimeout(() => {
      setIsPopupVisible(false);
      setdeletesectionMessage(null);
      dispatch({ type: 'CLEAR_DELETE_SECTION' });
    }, 3000);
  }, [deleteSec]);

  useEffect(() => {
    if (deleteQues && deleteQues !== undefined) {
      if (deleteQues?.StatusCode == 1) {
        setIsPopup(true);
        setPopupColor('var(--sapGreen)');
        setquestionMessage(deleteQues?.StatusDesc);
      }
      if (deleteQues?.StatusCode == -1) {
        setIsPopup(true);
        setPopupColor('red');
        setquestionMessage(deleteQues?.StatusDesc);
      }

      maverickPortalhttp
          .makeRequest(
            `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
            maverickPortalApiEndPoint.getProjectTask.methodType
          )
          .then(response => {
            let taskData = response.data.Response;

            setFormTaskSections(taskData?.GetProjectTaskQuestions);
          });

      const timeout = setTimeout(() => {
        setIsPopup(false);
        setquestionMessage(null);
        dispatch({ type: 'CLEAR_DELETE_QUESTION' });
        // getCriteriaBudgetQuestion();
        
      }, 3000);
    }
  }, [deleteQues]);

  let questionTypes = questionTypeList?.map(questionType => {
    return questionType.questionType_ID != 9 && questionType.questionType_ID != 10
      ? { ...questionType, label: questionType.questionTypeName, value: questionType.questionType_ID }
      : null;
  });

  questionTypes = questionTypes?.filter(function(questionType) {
    return questionType != null;
  });

  useEffect(() => {
    setFormTaskSections(taskList);
  }, [taskList]);

  useEffect(() => {
    setTimeout(() => {
      setisNewSectionForm(false);
    }, 3000);
  }, [isNewSectionForm]);

  const [showSaveButton, setShowSaveButton] = useState(false);
  // const [editedSectionIndex, setEditedSectionIndex] = useState(null);
  const createSection = () => {
    // let tempFormTaskSections = formTaskSections;
    // tempFormTaskSections = [
    //   ...tempFormTaskSections,
    //   { sectionName: '', sectionDescription: '', questionName_array: [], isNew: true }
    // ];

    // setFormTaskSections(tempFormTaskSections);
    // setShowSaveButton(true);
    // setSectionNameError(false);

    setFormTaskSections(prevList => [
      ...prevList,
      { sectionName: '', sectionDescription: '', questionName_array: [], isNew: true }
    ]);
    setShowSaveButton(true);
    setAddSectionDisbale(true);
    setSectionNameError(false);
    setSectionSavedStatus(prevStatus => [...prevStatus, false]);
  };
  const closeHandler = e => {
    setPopup(e);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    console.log("source",source);
    console.log("destination",destination);
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const sourceIndex = parseInt(source.droppableId.split('-')[1], 10);
      const updatedSections = [...formTaskSections];
      const [removed] = updatedSections[sourceIndex].questionName_array.splice(source.index, 1);
      updatedSections[sourceIndex].questionName_array.splice(destination.index, 0, removed);
      setFormTaskSections(updatedSections);

      const data = {
        user_ID: loggedUser.userId,
        questions: updatedSections[sourceIndex].questionName_array?.map((questionObj, index) => ({
          question_ID: questionObj?.question_ID,
          questionOrder: index + 1
        }))
      };

      dispatch({ type: 'DRAG_QUESTION', payload: data });
    } else {
      const sourceIndex = parseInt(source.droppableId.split('-')[1], 10);
      const destinationIndex = parseInt(destination.droppableId.split('-')[1], 10);
      const updatedSections = [...formTaskSections];
      const [removed] = updatedSections[sourceIndex].questionName_array.splice(source.index, 1);
      updatedSections[destinationIndex].questionName_array.splice(destination.index, 0, removed);
      console.log("updatedSections",updatedSections);
      setFormTaskSections(updatedSections);

      const data = {
        user_ID: loggedUser.userId,
        questionsPaperSections: [
          updatedSections[sourceIndex].questionName_array?.map((questionObj, index) => ({
            question_ID: questionObj?.question_ID,
            questionOrder: index + 1,
            questionPaperSection_ID: updatedSections[sourceIndex].questionPaperSection_ID
          })),
          updatedSections[destinationIndex].questionName_array?.map((questionObj, index) => ({
            question_ID: questionObj?.question_ID,
            questionOrder: index + 1,
            questionPaperSection_ID: updatedSections[destinationIndex].questionPaperSection_ID
          })),
        ]
      };

      dispatch({ type: 'DRAG_QUESTION_ACROSS_SECTIONS', payload: data});
    }
    
  };

  const storeSection = (sectionIndex, _field, _value) => {
    // let tempFormTaskSections = formTaskSections;
    // tempFormTaskSections[sectionIndex] = {
    //   ...tempFormTaskSections[sectionIndex],
    //   _field: _value,
    //   isNew: _field != 'sectionDescription'
    // };

    // setFormTaskSections(tempFormTaskSections);

    // const data = {
    //   user_ID: loggedUser.userId,
    //   projectsTask_ID: formTask.projectTask_ID,
    //   project_ID: projectId,
    //   projectScreener_ID: null,
    //   parentType: 'task',
    //   sectionName: _field == 'sectionName' ? _value : tempFormTaskSections[sectionIndex].sectionName,
    //   sectionDescription:
    //     _field == 'sectionDescription' ? _value : tempFormTaskSections[sectionIndex].sectionDescription
    // };
    // dispatch({ type: 'ADD_SECTION', payload: data });

    setFormTaskSections(prevList => {
    
      return prevList.map((section, i) => (i === sectionIndex ? { ...sectionIndex, [_field]: _value } : section));
    });

    const section = formTaskSections[sectionIndex];
    if (section?.sectionName || section?.sectionDescription) {
      const data = {
        user_ID: loggedUser.userId,
        projectsTask_ID: formTask.projectTask_ID,
        project_ID: projectId,
        projectScreener_ID: null,
        parentType: 'task',
        sectionName: section.sectionName,
        sectionDescription: section.sectionDescription
        // sectionName: field == 'sectionName' ? value : tempCBScreenerList[index].sectionName,
        // sectionDescription: field == 'sectionDescription' ? value : tempCBScreenerList[index].sectionDescription
      };

      dispatch({ type: 'ADD_SECTION', payload: data });
      // setShowSaveButton(false);
      //  setSectionSavedStatus(prevStatus => [...prevStatus, false]);
      const updatedStatus = [...sectionSavedStatus];
      updatedStatus[sectionIndex] = true; // Mark the section at index as saved
      setSectionSavedStatus(updatedStatus);
    } else {
      console.log('error');
    }

    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
        maverickPortalApiEndPoint.getProjectTask.methodType
      )
      .then(response => {
        let taskData = response.data.Response;

        setFormTaskSections(taskData?.GetProjectTaskQuestions);
      });
    setShowSaveButton(false);
    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
        maverickPortalApiEndPoint.getProjectTask.methodType
      )
      .then(response => {
        let taskData = response.data.Response;

        setFormTaskSections(taskData?.GetProjectTaskQuestions);
      });
    setShowSaveButton(false);
  };

  const updateSection = (sectionIndex, _field, _value) => {
    let tempFormTaskSections = formTaskSections;
    tempFormTaskSections[sectionIndex] = { ...tempFormTaskSections[sectionIndex], _field: _value };
    if (_field == 'sectionName') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }
    if (_field == 'sectionDescription') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }

    setFormTaskSections(tempFormTaskSections);

    const data = {
      user_ID: loggedUser.userId,
      QuestionPaperSection_ID: tempFormTaskSections[sectionIndex].questionPaperSection_ID,
      sectionName: _field == 'sectionName' ? _value : tempFormTaskSections[sectionIndex].sectionName,
      sectionDescription:
        _field == 'sectionDescription' ? _value : tempFormTaskSections[sectionIndex].sectionDescription
    };
    if (data.sectionName != '') {
      dispatch({ type: 'EDIT_SECTION', payload: data });
    }

    setShowSaveButton(false);
    // setEditedSectionIndex(null);
  };

  const createQuestion = sectionIndex => {
    let tempFormTaskSections = formTaskSections.slice();

    !tempFormTaskSections[sectionIndex].hasOwnProperty('questionName_array') &&
      (tempFormTaskSections[sectionIndex] = { ...tempFormTaskSections[sectionIndex], questionName_array: [] });

    let tempQuestionArray = tempFormTaskSections[sectionIndex].questionName_array;
    // console.log('switchValue', switchvalue);
    tempQuestionArray.push({
      question_ID: null,
      questionName: null,
      questionDescription: null,
      isRequired: switchvalue,
      isEdit: true,
      questionType_ID: 1,
      oldQuestionType_ID: 1,
      characterLimit: 500,
      maxFileSizeInMB: 10,
      numberOfFiles: 1,
      savedOption: null,
      choiceName_array: [],
      ValueTypeChoices: [],
      likertScaleName_array: [
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Dislike Very Much'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Dislike'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Moderately Dislike'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Neutral'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Moderately Like'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Like'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Like Very Much'
        }
      ]
    });
    tempFormTaskSections[sectionIndex].questionName_array = tempQuestionArray;
    setNewQuestionIndex(sectionIndex);
    setEditQuestionIndex(null);
    setDeleteQuestionIndex(null);
    setAddQuestionDisable(true);
    setFormTaskSections(tempFormTaskSections);
  
  };

  const handleChangeQuestionType = (sectionIndex, questionIndex, questionTypeId, question) => {
    let tempFormTaskSections = formTaskSections.slice();

    question = {
      ...question,
      questionType_ID: questionTypeId,
      oldQuestionType_ID: question.oldQuestionType_ID,
      isEdit: true
    };
    tempFormTaskSections[sectionIndex].questionName_array[questionIndex] = question;

    setFormTaskSections(tempFormTaskSections);
  };

  const handleDuplicateQuestion = (sectionIndex, questionIndex) => {
    let tempFormTaskSections = formTaskSections.slice();
    let question = tempFormTaskSections[sectionIndex]?.questionName_array[questionIndex];
    question = { ...question, isEdit: true, question_ID: null };

    // tempFormTaskSections[sectionIndex].questionName_array.push(question);
    // Insert the new question at the specified questionIndex
    tempFormTaskSections[sectionIndex].questionName_array.splice(questionIndex + 1, 0, question);
    setFormTaskSections(tempFormTaskSections);
  };

  const handleDeleteQuestion = (sectionIndex, questionIndex) => {
    setDeleteQuestionIndex(sectionIndex);
    setQuestionIndex(questionIndex);
    setNewQuestionIndex(null);
    let tempFormTaskSections = formTaskSections.slice();
    let question = tempFormTaskSections[sectionIndex]?.questionName_array[questionIndex];
    let section = tempFormTaskSections[sectionIndex]?.questionPaperSection_ID;
    console.log('Section ID', section);
    console.log('Delete question', question);
    let payload = {
      user_ID: loggedUser.userId,
      questions_ID: question.question_ID
    };

    tempFormTaskSections[sectionIndex].questionName_array.splice(questionIndex, 1);
    setAddQuestionDisable(false);
    setFormTaskSections(tempFormTaskSections);

    dispatch({
      type: 'REQUEST_DELETE_QUESITON',
      formData: payload
    });
  };
  const handleDeleteSection = sectionIndex => {
    setDeleteSectionIndex(sectionIndex);
    let tempFormTaskSections = formTaskSections.slice();
    let section = tempFormTaskSections[sectionIndex];
    let payload = {
      user_ID: loggedUser.userId,
      QuestionPaperSection_ID: section.questionPaperSection_ID
    };
    // // let question = tempFormTaskSections[sectionIndex]
    // // console.log("Delete section", question)
    // let section = tempFormTaskSections[sectionIndex]?.questionPaperSection_ID[sectionIndex];
    // console.log("Section", section)
    // let payload = {
    // 	user_ID: loggedUser.userId,
    // 	//questions_ID: question.question_ID
    // 	//QuestionPaperSection_ID: section.questionPaperSection_ID
    // };
    tempFormTaskSections.splice(sectionIndex, 1);
    setFormTaskSections(tempFormTaskSections);
    dispatch({
      type: 'REQUEST_DELETE_SECTION',
      formData: payload
    });
    //console.log(sectionIndex)
    // tempFormTaskSections.splice(sectionIndex,1);
    // setFormTaskSections(tempFormTaskSections);
    setAddSectionDisbale(false);
    setPopup(false);
  };
  const deleteSection = () => {
    console.log('Section Index', formTaskSections);
  };

  const handleProjectTaskTab = () => {
    handleTaskTab(task.taskType, task.projectTask_ID, task.IsVisited);
  };
  const handleProjectTaskNext = () => {
    handleTaskTab(nextTask.taskType, nextTask.projectTask_ID, nextTask.IsVisited);
  };
  return (
    <>
      <section className="form-notification-section ">
        <div className="row py-2">
          <div className="col-md-6">
            <Heading color={'var(--deepBlue)'} fontSize="1.125rem" fontWeight="800">
              {formTask.taskName}
            </Heading>

            <TextArea
              showCharacters={false}
              fontSize="0.75rem"
              fontWeight="500"
              placeholder="Add section description"
              value={formTask.taskDescription}
              className="mt-1"
            />
          </div>
        </div>
        <DragDropContext onDragEnd={result => handleDragEnd(result)}>
          {formTaskSections?.map((section, sIndex) => {
            return (
              <React.Fragment key={section.questionPaperSection_ID}>
                <CustomDivider />
                <div className="row">
                  <div className="col-md-8">
                    {isPopupVisible && deletesectionmessage && sIndex === 0 && (
                      <center>
                        <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                          <div>{deletesectionmessage}</div>
                        </div>
                      </center>
                    )}
                    <CustomButton
                      variant="outlined"
                      buttonColor="var(--greyBlue)"
                      className=""
                      //onClick={(e) => handleDeleteSection(sIndex)}

                      onClick={e => {
                        setPopup(!popup), setIndex(sIndex);
                      }}
                      style={{ float: 'right', color: 'var(--greyBlue)', backgroundColor: 'white', fontSize: '15px' }}
                    >
                      <Icon className="material-icons-round" style={{ color: 'var(--greyBlue)', marginTop: '1.5px' }}>
                        delete
                      </Icon>
                      Delete Section
                      <CustomPopup
                        onClose={closeHandler}
                        showed={popup}
                        heading="Confirm delete"
                        secondheading="Are you sure you want to delete this Section?"
                        secondheading2="Once deleted, it cant be retrieved."
                      >
                        <CustomButtont
                          fontSize="14px"
                          fontWeight="300"
                          color="black"
                          size="medium"
                          style={{ justifyContent: 'space-between', marginRight: '20px' }}
                          onClick={() => {
                            handleDeleteSection(index);
                          }}
                        >
                          Delete
                        </CustomButtont>
                      </CustomPopup>
                    </CustomButton>

                    {/* <span
                      variant='outlined'
                      className=''
                      onClick={(e) => handleDeleteSection(sIndex)}
                      style={{float: 'right',color: 'var(--greyBlue)',display: 'inline-block',verticalAlign: 'middle'}}
                    >
                      <ActionIcon className='mt-3'>delete</ActionIcon>Delete Section
                    </span> */}
                    <div className="section-wrapper ">
                      <div className="row">
                        <div className="col-md-3">
                          <Heading type="5" className="mt-2" color="var(--seaBlue)">
                            Section {+sIndex + +1}
                            {/* <CustomButton
                      variant='outlined'
                      buttonColor='var(--sapGreen)'
                      className='mt-4'
                      onClick={(e) => handleDeleteSection(sIndex)}
                    >
              
                      Delete Section
                    </CustomButton> */}
                          </Heading>
                        </div>
                        {formTaskSections[sIndex].sectionName == '' &&
                        formTaskSections[sIndex].sectionDescription == '' ? (
                          <div className="col-md-9">
                            <TextArea
                              showCharacters={false}
                              fontSize="1.125rem"
                              value={section.sectionName}
                              placeholder="Add a section name"
                              width="250px"
                              style={{
                                color: 'var(--deepBlue)'
                              }}
                              onChange={e => {
                                let tempFormTaskSections = formTaskSections;
                                tempFormTaskSections[sIndex].sectionName = e.target.value;
                                setFormTaskSections(tempFormTaskSections);
                              }}
                              // onBlur={e =>
                              //   section.isNew
                              //     ? storeSection(sIndex, 'sectionName', e.target.value)
                              //     : updateSection(sIndex, 'sectionName', e.target.value)
                              // }
                            />
                            {sectionNameError && (
                              <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                            )}
                            <TextArea
                              fontSize="0.75rem"
                              showCharacters={false}
                              value={section.sectionDescription}
                              placeholder="Add a section description"
                              className="mt-1"
                              style={{
                                color: 'var(--deepBlue)',
                                fontWeight: '500'
                              }}
                              onChange={e => {
                                let tempFormTaskSections = formTaskSections;
                                tempFormTaskSections[sIndex].sectionDescription = e.target.value;
                                setFormTaskSections(tempFormTaskSections);
                              }}
                              // onBlur={(e) => !section.isNew ? updateSection(sIndex, "sectionName", e.target.value): ''}

                              // onBlur={e =>
                              //   section.isNew
                              //     ? storeSection(sIndex, 'sectionDescription', e.target.value)
                              //     : updateSection(sIndex, 'sectionDescription', e.target.value)
                              // }
                            />
                            {/* <Text type="2">
                              {message ? (
                                <p className="mt-2" style={{ color: 'red', fontSize: '11px' }}>
                                  Fill in all details
                                </p>
                                storeSection(sIndex, "sectionDescription", e.target.value)
                              ) : null}
                            </Text> */}
                            {/* Sageer's code section button and popup */}
                            {showSaveButton && (
                              <>
                                <CustomButton
                                  variant="outlined"
                                  buttonColor="var(--sapGreen)"
                                  className="mt-3"
                                  onClick={e => {
                                    e.stopPropagation();
                                    if (!section.sectionName.trim()) {
                                      // alert('Please fill in the section name.')
                                      setSectionNameError(true);
                                    } else {
                                      setSectionNameError(false);
                                      storeSection(sIndex, 'sectionDescription', e.target.value);
                                      // storeSection(index, 'sectionName', e.target.value)
                                      setisNewSectionForm(true);
                                      setAddSectionDisbale(false);
                                      setShowSaveButton(false);
                                    }
                                  }}
                                >
                                  <Icon>save</Icon>
                                  Save Section
                                </CustomButton>
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="col-md-9">
                            <TextArea
                              showCharacters={false}
                              fontSize="1.125rem"
                              value={section.sectionName}
                              placeholder="Add a section name"
                              width="250px"
                              style={{
                                color: 'var(--deepBlue)'
                              }}
                              onChange={e => {
                                let tempFormTaskSections = formTaskSections;
                                tempFormTaskSections[sIndex].sectionName = e.target.value;
                                setFormTaskSections(tempFormTaskSections);
                                seteditSectionIndex(section.questionPaperSection_ID);
                              }}
                              // onBlur={e =>
                              //   section.isNew
                              //     ? storeSection(sIndex, 'sectionName', e.target.value)
                              //     : updateSection(sIndex, 'sectionName', e.target.value)
                              // }
                            />
                            {editSectionIndex === section.questionPaperSection_ID && sectionNameError && (
                              <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                            )}
                            <TextArea
                              fontSize="0.75rem"
                              showCharacters={false}
                              value={section.sectionDescription}
                              className="mt-1"
                              style={{
                                color: 'var(--deepBlue)',
                                fontWeight: '500'
                              }}
                              onChange={e => {
                                let tempFormTaskSections = formTaskSections;
                                tempFormTaskSections[sIndex].sectionDescription = e.target.value;

                                setFormTaskSections(tempFormTaskSections);
                                seteditSectionIndex(section.questionPaperSection_ID);
                              }}
                              // onBlur={e =>
                              //   section.isNew
                              //     ? storeSection(sIndex, 'sectionDescription', e.target.value)
                              //     : updateSection(sIndex, 'sectionDescription', e.target.value)

                              // }
                            />
                            {editSectionIndex === section.questionPaperSection_ID && (
                              <>
                                {/* {sectionNameError && <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>} */}
                                <CustomButton
                                  variant="outlined"
                                  buttonColor="var(--sapGreen)"
                                  className="mt-3"
                                  onClick={e => {
                                    e.stopPropagation();
                                    // storeSection(index, 'sectionDescription', e.target.value)
                                    if (!section.sectionName.trim()) {
                                      setSectionNameError(true);
                                    } else {
                                      setSectionNameError(false);
                                      updateSection(sIndex, 'sectionName', section.sectionName);
                                      updateSection(sIndex, 'sectionDescription', section.sectionDescription);
                                      setShowSaveButton(false);
                                    }
                                  }}
                                >
                                  <Icon>save</Icon>
                                  Save Section
                                </CustomButton>
                              </>
                            )}
                            {editSectionIndex === section.questionPaperSection_ID && sectionmessage && (
                              <center>
                                <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                                  <div>{sectionmessage}</div>
                                </div>
                              </center>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className="questions-list mt-3"
                        // style={{ borderLeft: '3px solid var(--greyBlue) !important' }}
                      >
                        <React.Fragment key={section.questionPaperSection_ID}>
                          <Droppable droppableId={`droppable-${sIndex}`}>
                            {provided => (
                              <div className="questions-list mt-3" {...provided.droppableProps} ref={provided.innerRef}>
                                {section?.questionName_array?.map((question, qIndex) => (
                                  <Draggable
                                    key={question.question_ID}
                                    draggableId={question.question_ID ? question.question_ID.toString() : ''}
                                    index={qIndex}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <DesignQuestion
                                          key={qIndex}
                                          sectionIndex={sIndex}
                                          setSwitchValue={setSwitchValue}
                                          switchvalue={switchvalue}
                                          questionIndex={qIndex}
                                          sectionId={section.questionPaperSection_ID}
                                          questionArrayLength={section?.questionName_array?.length}
                                          question={question}
                                          questionTypes={questionTypes}
                                          ValueTypeChoices={ValueTypeChoices}
                                          handleChangeQuestionType={handleChangeQuestionType}
                                          handleDuplicateQuestion={handleDuplicateQuestion}
                                          handleDeleteQuestion={handleDeleteQuestion}
                                          handleProjectTaskTab={handleProjectTaskTab}
                                          isInterview={task.taskType !== 'Form'}
                                          setEditQuestionIndex={setEditQuestionIndex}
                                          setNewQuestionIndex={setNewQuestionIndex}
                                          setDeleteQuestionIndex={setDeleteQuestionIndex}
                                          enableEdit={editQuestionId == question?.question_ID}
                                          setEditQuestionId={setEditQuestionId}
                                          setAddQuestionDisable={setAddQuestionDisable}
                                          setQuestionIndex={setQuestionIndex}
                                          questionmessage={questionmessage}
                                          popupColor={popupColor}
                                          showCheckbox={true} 
                                        />

                                        {/* {editQuestionIndex === sIndex && questionIndex === qIndex && isPopup && (
                                        <center>
                                          <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                                            <div>{questionmessage}</div>
                                          </div>
                                        </center>
                                      )} */}

                                        {deleteQuestionIndex === sIndex && QuestionIndex === qIndex && questionmessage && (
                                          <center>
                                            <div
                                              style={{
                                                color: popupColor,
                                                fontWeight: 'bold',
                                                textAlign: 'left'
                                              }}
                                            >
                                              <div>{questionmessage}</div>
                                            </div>
                                          </center>
                                        )}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </React.Fragment>
                      </div>

                      {newQuestionIndex === sIndex && questionmessage && (
                        <center>
                          <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                            <div>{questionmessage}</div>
                          </div>
                        </center>
                      )}

                      <CustomButton
                        variant="outlined"
                        buttonColor="var(--sapGreen)"
                        color="var(--sapGreen)"
                        className="mt-5"
                        onClick={() => createQuestion(sIndex)}
                        disabled={section.isNew || addQuestionDisable}
                      >
                        <Icon>add</Icon>
                        Add Question
                      </CustomButton>

                      {isPopupVisible && deletesectionmessage && deleteSectionIndex == index + 1 && (
                        <center>
                          <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                            <div>{deletesectionmessage}</div>
                          </div>
                        </center>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </DragDropContext>
        

        <CustomDivider />

        <CustomButton
          variant="outlined"
          buttonColor="var(--sapGreen)"
          className="mt-3"
          onClick={e => createSection()}
          disabled={addSectionDisable}
        >
          <Icon>add</Icon>
          Add New Section
        </CustomButton>
        {isNewSectionForm && sectionmessage && (
          <center>
            <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
              <div>{sectionmessage}</div>
            </div>
          </center>
        )}

        <div className="d-flex flex-column align-items-center justify-content-center mt-5">
          <Heading type="4">Well done!</Heading>
          <Heading type="5">Your progress so far has been saved</Heading>
          <div className="d-flex ">
            <Link to={`/project/${projectId}/invite-recruit`}>
              <Button size="mid" className="mt-4 mr-2" buttonColor="var(--sapGreen)">
                Recruit Respondents
              </Button>
            </Link>

            <Button size="mid" className="mt-4 ml-2" buttonColor="var(--sapGreen)" onClick={handleProjectTaskNext}>
              Design {nextTask.taskType != 'Video' ? nextTask.taskType : 'Interview'}
            </Button>
            <Link to={{ pathname: '/project/' + projectId + '/field' }} style={{ marginLeft: '15px' }}>
              <Button size="mid" className="mt-4 mr-2" buttonColor="var(--sapGreen)">
                Go To Field
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
