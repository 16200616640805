import styled from '@emotion/styled';
import React from 'react';
import { Heading } from '../../../common';

const Stack = styled.div`
	background: ${(props) => props.color};
	height: 35px;
	width: ${(props) => `${(props.width / props.max) * 100}%`};
	font-weight: bold;
	font-size: 14px;
	text-align: center;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const HorizontalChart = ({ data }) => {
	return (
		<div style={{ width: '100%', background: 'green' }}>
			{data.map((row) => (
				<div>
					<p>{row.name}</p>
					<div className='d-flex'>
						{row.data.map((item) => (
							<Stack
								width={item.value}
								color={item.color}
							/>
						))}
					</div>
				</div>
			))}
			{/* <div className='d-flex'>
				<Stack width={15} color='#163E66' />
			</div> */}
		</div>
	);
};
function HorizontalBarChart({ data }) {
	const sums = data.map((row) =>
		row.data
			.map((item) => item.value)
			.reduce((a, b) => parseInt(a) + parseInt(b), 0)
	);

	return (
		<div style={{ width: '100%' }}>
			{data.map((row, index) => (
				<div className='my-3'>
					<div className='d-flex align-items-center mb-2'>
						<Heading
							fontSize='12px'
							fontWeight='700'
							className='mb-0 pb-0'
							color='var(--deepBlue)'
						>
							{row.name}
						</Heading>
						<Heading
							fontSize='12px'
							fontWeight='400'
							className='mb-0 pb-0 ml-2'
							color='var(--deepBlue)'
						>
							{row.information}
						</Heading>
					</div>

					<div className='d-flex'>
						{row.data.map((item) => {
							return (
								<Stack
									max={sums[index]}
									width={item.value}
									color={item.color}
								>
									{item.value}
								</Stack>
							);
						})}
					</div>
				</div>
			))}
			{/* <div className='d-flex'>
				<Stack width={15} color='#163E66' />
			</div> */}
		</div>
	);
}

export default HorizontalBarChart;
