import { combineReducers } from 'redux';
import LoginReducer from './login/LoginReducer';
import TagReducer from './tag/TagReducer';
import QuestionReducer from './question/QuestionReducer';
import ProjectReducer from './project/index';
import TaskReducer from './design/TaskReducer';
import ProjectsetReducer from './invite-recruit/ProjectsetReducer';
import RecruitsGroupReducer from './invite-recruit/RecruitsGroupReducer';
import SendInviteReducer from './invite-recruit/SendInviteReducer';
import AddVideoTagReducer from './field/AddVideoTagReducer';
import AddVideoNoteReducer from './field/AddVideoNoteReducer';
import AddVideoTrimReducer from './field/AddVideoTrimReducer';
import GetAllTrimClipsReducer from './field/GetAllTrimClipsReducer';
import GetVideoTagReducer from './field/GetVideoTagReducer';
import GetVideoNoteReducer from './field/GetVideoNoteReducer';
import GetVideoReducer from './field/GetVideoReducer';
import SendRejectReducer from './invite-recruit/SendRejectReducer';
import RecruitScreener from './invite-recruit/RecruitScreener';
import GetInviteeReducer from './invite-recruit/GetInviteeReducer';
import ConfirmAndAssignReducer from './invite-recruit/ConfirmAndAssignReducer';
import ReassignProjectGroupsReducer from './invite-recruit/ReassignProjectGroupsReducer';
import ConfirmRespondentReducer from './invite-recruit/ConfirmRespondentReducer';
import GetFieldTaskReducer from './field/GetFieldTaskReducer';
import GetTaskReducer from './field/GetTaskReducer';
import GetTaskStatusReducer from './field/GetTaskStatusReducer';
import GetVideoTaskStatusReducer from './field/GetVideoTaskStatusReducer';
import projectReducer from './project/projectReducer';
import PublishReducer from './publish/PublishReducer';
import webDashboardReducer from './dashboard/webDashboardReducer';
import GetFieldOverviewReducer from './field/GetFieldOverviewReducer';
import GetCaptureGuideReducer from './field/GetCaptureGuideReducer';
import AddNewNoteReducer from './field/AddNewNoteReducer';
import GetRecordTaskVideoReducer from './field/GetRecordTaskVideoReducer';
import AddTimeVideoQuestionsReducer from './field/AddTimeVideoQuestionsReducer';
import FieldPreEditorReducer from './field/FieldPreEditorReducer';
import StartInterviewReducer from './field/StartInterviewReducer';
import EndInterviewReducer from './field/EndInterviewReducer';
import DeleteVideoNoteReducer from './field/DeleteVideoNoteReducer';
import DeleteVideoTagReducer from './field/DeleteVideoTagReducer';
import EditVideoNoteReducer from './field/EditVideoNoteReducer';
import EditButtonNameReducer from './field/EditButtonNameReducer';
import GetProfileReducer from './profile/GetProfileReducer';
import EditProfileReducer from './profile/EditProfileReducer';
import analysisReducer from './analysis/analysisReducer';
import PublishProjectReducer from './publish/PublishProjectReducer';
import graphDataReducer from './analysis/graphDataReducer';
import DeleteVideoClipReducer from './field/DeleteVideoClipReducer';
import ChangePasswordWebReducer from './Security/ChangePasswordWebReducer';
import EditClipReducer from './field/EditClipReducer';
import AddRescheduleSlotReducer from './field/AddRescheduleSlotReducer';
import endProjectReducer from './dashboard/endProjectReducer';
import AddRespondentAnswerReducer from './field/AddRespondentAnswerReducer';
import clientDashboardReducer from './clientAdmin/clientDashboardReducer';
import EditTeamDataReducer from './clientAdmin/EditTeamDataReducer';
import AddNewUserReducer from './clientAdmin/AddNewUserReducer';
import EditUserdataReducer from './clientAdmin/EditUserdataReducer';
import AddNewClientAdminReducer from './superAdmin/AddNewClientAdminReducer';
import SuperAdminDashboardReducer from './superAdmin/SuperAdminDashboardReducer';
import EditClientAdminReducer from './superAdmin/EditClientAdminReducer';
import ViewUserInGeneralPoolReducer from './superAdmin/ViewUserInGeneralPoolReducer';
import AddNewUserGenPoolReducer from './superAdmin/AddNewUserGenPoolReducer';
import EditResearcherDataGenPoolReducer from './superAdmin/EditResearcherDataGenPoolReducer';
import EditRespondantDataGenPoolReducer from './superAdmin/EditRespondantDataGenPoolReducer';
import ViewProjectSuperAdminDashboardReducer from './superAdmin/ViewProjectSuperAdminDashboardReducer';
import DeleteProjectReducer from './clientAdmin/DeleteProjectReducer';
import OTPReducer from './otp/OTPReducer';
import OtpVerificationReducer from './otpVerification/otpVerificationReducer';
import ResetPasswordEmailNotificationReducer from './superAdmin/ResetPasswordEmailNotificationReducer';
import calenderDataReducer from './dashboard/calenderDataReducer';
import generateSummaryReducer from './analysis/generateSummaryReducer';
import checkSummaryReducer from './analysis/checkSummaryReducer';
import SendTranscriptReducer from './transcript/SendTranscriptReducer';
import getSummaryReducer from './analysis/getSummaryReducer';

export default combineReducers({
  LoginReducer,
  TagReducer,
  QuestionReducer,
  ProjectReducer,
  TaskReducer,
  ProjectsetReducer,
  RecruitsGroupReducer,
  SendInviteReducer,
  AddVideoTagReducer,
  AddVideoNoteReducer,
  AddVideoTrimReducer,
  GetAllTrimClipsReducer,
  GetVideoTagReducer,
  GetVideoNoteReducer,
  GetVideoReducer,
  RecruitScreener,
  SendRejectReducer,
  GetInviteeReducer,
  ConfirmAndAssignReducer,
  ReassignProjectGroupsReducer,
  ConfirmRespondentReducer,
  GetFieldTaskReducer,
  GetTaskReducer,
  GetTaskStatusReducer,
  GetVideoTaskStatusReducer,
  projectReducer,
  PublishReducer,
  webDashboardReducer,
  calenderDataReducer,
  GetFieldOverviewReducer,
  GetCaptureGuideReducer,
  AddNewNoteReducer,
  GetRecordTaskVideoReducer,
  AddTimeVideoQuestionsReducer,
  FieldPreEditorReducer,
  StartInterviewReducer,
  EndInterviewReducer,
  DeleteVideoNoteReducer,
  DeleteVideoTagReducer,
  EditVideoNoteReducer,
  EditButtonNameReducer,
  GetProfileReducer,
  EditProfileReducer,
  analysisReducer,
  PublishProjectReducer,
  analysisReducer,
  graphDataReducer,
  DeleteVideoClipReducer,
  ChangePasswordWebReducer,
  EditClipReducer,
  AddRescheduleSlotReducer,
  endProjectReducer,
  AddRespondentAnswerReducer,
  clientDashboardReducer,
  EditTeamDataReducer,
  AddNewUserReducer,
  EditUserdataReducer,
  AddNewClientAdminReducer,
  SuperAdminDashboardReducer,
  EditClientAdminReducer,
  ViewUserInGeneralPoolReducer,
  AddNewUserGenPoolReducer,
  EditResearcherDataGenPoolReducer,
  EditRespondantDataGenPoolReducer,
  ViewProjectSuperAdminDashboardReducer,
  DeleteProjectReducer,
  OTPReducer,
  OtpVerificationReducer,
  ResetPasswordEmailNotificationReducer,
  SendTranscriptReducer,
  generateSummaryReducer,
  checkSummaryReducer,
  getSummaryReducer
});
