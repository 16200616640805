import styled from '@emotion/styled';

const StyledTab = styled.li`
	margin-right: ${(props) => (props.big ? '4rem' : '1.2rem')};
	// transform: translateY(1px);

	&.active button {
		color: ${(props) =>
			props.activeColor ? props.activeColor : 'var(--ternaryColor)'};
		font-weight: 700;
		font-size: ${(props) =>
			props.big ? '1.375rem' : props.fontSize || '0.625rem'};
		transform: translateY(1px);
		:after {
			content: '';
			width: 100%;
			height: 3px;
			border-radius: 15px;
			background: var(--primaryColor);
			bottom: -1px;
			left: 0;
			position: absolute;
		}

		:hover {
			font-weight: 700;
		}
	}

	&.semi-active button {
		color: var(--ternaryColor);
	}

	button {
		color: #c2c2c2;
		font-size: ${(props) =>
			props.big ? '1.125rem' : props.fontSize || '0.72rem'};
		padding: 0.5rem;
		font-weight: 500;
		border: 0;
		background: transparent;
		border-radius: 0;
		position: relative;

		:hover {
			color: ${(props) =>
				props.activeColor
					? props.activeColor
					: 'var(--ternaryColor)'};
			font-weight: 500;
		}

		:hover:after {
			content: '';
			width: 100%;
			height: 3px;
			border-radius: 15px;
			background: var(--primaryColor);
			bottom: -1px;
			left: 0;
			position: absolute;
		}
	}
`;

export default function Tab({
	children,
	active,
	className = '',
	fontSize = null,
	big,
	onClick,
	activeColor,
}) {
	return (
		<StyledTab
			className={`nav-item ${className} ${active ? 'active' : ''}`}
			fontSize={fontSize}
			big={big}
			onClick={onClick}
			activeColor={activeColor}
		>
			<button className=''>{children}</button>
		</StyledTab>
	);
}
