import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as ResetPasswordEmailNotificationTokenValidateMethod from '../../utils/api/superAdmin/ResetPasswordEmailNotificationTokenValidateData';

function* PasswordEmailTokenValidate(payload) {
  const promise = ResetPasswordEmailNotificationTokenValidateMethod.ResetPasswordEmailNotificationTokenValidate(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ResetPasswordEmailNotificationAction.REQUEST_TOKEN_VALIDATE_RECEIVED, data: res.Response });

}

export { PasswordEmailTokenValidate };
