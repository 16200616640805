import constants from "../../constants";

const {REQUEST_SUPERADMINDASHBOARD_DATA, SUPERADMINDASHBOARD_DATA_RECEIVED} = constants.SuperAdminDashboardConstants;


export default {
    REQUEST_SUPERADMINDASHBOARD_DATA,
    SUPERADMINDASHBOARD_DATA_RECEIVED,

    requestSuperAdminDashboardData: () => ({
        type: REQUEST_SUPERADMINDASHBOARD_DATA
    }),

    superAdminDashboardDataReceived : () => ({
        type: SUPERADMINDASHBOARD_DATA_RECEIVED
    })
}