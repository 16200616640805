import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as UpdatepasswordMethods from '../../utils/api/superAdmin/UpdatePasswordData';

function* UpdatepasswordData(payload) {
  const promise = UpdatepasswordMethods.UpdatepasswordData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.UpdatePasswordAction.REQUEST_UPDATE_PASSWORD_RECEIVED, data: res.Response });
}
export { UpdatepasswordData };
