import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Avatar, Button, Heading, Icon, Text } from '../../common';

import user from '../../images/todo.png';
import { ScrollContainer } from './common';
import { Item, Thumbnail, CustomButton } from './common';
import delete_greyBlue from '../../images/delete_greyBlue.svg';
function EventList() {
	const [open, setOpen] = useState(true);
	const handleClearAll = () => {};
	const handleLeftClick = () => {};
	const handleRightClick = () => {};
	return (
		<div>
			<div
				className='d-flex justify-content-between'
				style={{ marginRight: '1rem', paddingRight: '0.7rem' }}
			>
				<div className='d-flex '>
					<Heading
						className='mb-0 pb-0 mr-3'
						fontSize='18px'
						fontWeight='700'
						color='var(--deepBlue)'
					>
						Events and Updates
					</Heading>
					<div
						onClick={handleClearAll}
						className='d-flex align-items-center'
						style={{ marginBottom: '2px' }}
					>
						<img src={delete_greyBlue} />
						<Text
							fontSize='11px'
							fontWeight='400'
							color='var(--greyBlue)'
							className='mb-0 pb-0 ml-1'
						>
							Clear All
						</Text>
					</div>
				</div>
				<div className='d-flex'>
					<div
						className='d-flex align-items-center'
						style={{ marginRight: '9rem' }}
					>
						<div className='d-flex' onClick={handleLeftClick}>
							<Icon
								color='var(--greyBlue)'
								style={{ fontSize: '20px' }}
							>
								chevron_left
							</Icon>
						</div>

						<Text
							fontSize='14px'
							fontWeight='500'
							color='var(--greyBlue)'
							className='mb-0 pb-0 mx-1'
						>
							1-30 of 34
						</Text>
						<div
							className='d-flex'
							onClick={handleRightClick}
						>
							<Icon
								color='var(--greyBlue)'
								style={{ fontSize: '20px' }}
							>
								chevron_right
							</Icon>
						</div>
					</div>
					<div
						style={{ cursor: 'pointer' }}
						className='d-flex align-items-center'
						onClick={() => setOpen((prev) => !prev)}
					>
						<Heading
							fontSize='14px'
							fontWeight='800'
							color='var(--deepBlue)'
							breakHeading={false}
							className='mb-0 pb-0'
						>
							{open ? 'Hide' : 'Open'}
						</Heading>
						<Icon
							color='#38454F'
							style={{ fontSize: '28px' }}
						>
							{' '}
							{open ? 'expand_less' : 'expand_more'}{' '}
						</Icon>
					</div>
				</div>
			</div>
			{open && (
				<ScrollContainer>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								<span className='bold'>Task 1</span>
								completed {' | '}
								<span className='tag '>
									{' '}
									R3 + 7 more
								</span>
							</p>
							<p className='subtitle'>Field</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='minute'>10 mins</p>
							<CustomButton>Review Request</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								<span className='bold'>
									Recruitment
								</span>{' '}
								started
							</p>
							<p className='subtitle'>Recruit</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='minute'>45 mins</p>
							<CustomButton>View Recruit</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								<span className='bold'>
									Added as researcher
								</span>
								{' | '}
								<span className='tag '>
									{' '}
									By Khushbu
								</span>
							</p>
							<p className='subtitle'>Setup</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='minute'>2 hours</p>
							<CustomButton>View Project</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								<span className='bold'>
									Screener recieved
								</span>
								{' | '}
								<span className='tag '>
									{' '}
									R2 + 5 more
								</span>
							</p>
							<p className='subtitle'>Recruit</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='minute'>3 days</p>
							<CustomButton>Review Screener</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								<span className='bold'>
									New Applicants
								</span>
								{' | '}
								<span className='tag '>
									{' '}
									Karan Sharma + 10 more
								</span>
							</p>
							<p className='subtitle'>Recruit</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='minute'>5 days</p>
							<CustomButton>View Recruit</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								<span className='bold'>
									New Applicants
								</span>
								{' | '}
								<span className='tag '>
									{' '}
									Karan Sharma + 10 more
								</span>
							</p>
							<p className='subtitle'>Recruit</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='minute'>5 days</p>
							<CustomButton>View Recruit</CustomButton>
						</div>
					</Item>
				</ScrollContainer>
			)}
		</div>
	);
}

export default EventList;
