import React, { useState, useRef, useEffect } from 'react';
import Chevron from '../images/downIcon.svg';
import './Accordion.css';

function Accordion2({ title, isActive, onClick, children, index }) {
    const [height, setHeight] = useState(isActive ? 'auto' : '0px');
    const [rotate, setRotate] = useState('');

    const contentRef = useRef(null);
    useEffect(() => {
        if (isActive) {
            setHeight(`${contentRef.current.scrollHeight}px`);
            setRotate('rotate');
        } else {
            setHeight('0px');
            setRotate('');
        }
    }, [isActive]);

    function toggleAccordion() {
        onClick();
    }

    return (
        <div className='accordion__section'>
            <button className={`accordion ${isActive ? 'active' : ''}`} onClick={() => {toggleAccordion(); }}>
                <p className='accordion__title'>{title}</p>
                <img src={Chevron} className={`accordion__icon ${rotate}`} width={10} alt='Chevron' />
            </button>
            <div
                ref={contentRef}
                style={{ maxHeight: height, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out' }}
                className='accordion__content'
            >
                <div className='accordion__text'>{children}</div>
            </div>
        </div>
    );
}

export default Accordion2;