import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as ConfirmRespondentMethods from '../../utils/api/invite-recruit/ConfirmRespondentData';

function* ConfirmRespondentData(payload) {
  const promise = ConfirmRespondentMethods.ConfirmRespondentData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ConfirmRespondentAction.CONFIRM_RESPONDENT_RESPONSE, data: res.Response });
}

export  { ConfirmRespondentData }