import ProjectWrapper from './ProjectWrapper';
import styled from '@emotion/styled';
import { Button, Card, Heading } from '../../common';
import { Link, useParams } from 'react-router-dom';
import { Dropdown } from '../../components/form';
import { useEffect, useState } from 'react';
import Breadcurb from '../../common/Breadcurb';
import { Circle } from '../../common/Circle';
import { NavTab, NavTabs, NavTabsContainer } from '../../common/NewTab';

import expand from '../../images/expand.svg';
import minlScreen from '../../images/minlScreen.svg';

import rightArrowLight from '../../images/RightArrowLight.svg';

import InterviewTab from './InterviewTab';
import DownIndicator from '../../common/DownIndicator';
import EditTab from './EditTab';
import { useLocation } from 'react-router-dom';
import { edit_status, fieldsData, form_status, interview_status } from './data';
import { useDispatch, useSelector, connect } from 'react-redux';
import { REQUEST_CAPTURE_GUIDE } from '../../constants/field/GetCaptureGuideConstants';

const RightArrow = () => {
  return <img src={rightArrowLight} />;
};

const HeaderCard = styled(Card)`
  border: 1px solid var(--greyBlue30);
  box-shadow: none;
  margin-left: 0px;
  padding: 0;
  border-radius: 10px;
  height: auto;

  opacity: ${props => (props.callOn ? 0.5 : 1)};
`;

const CustomButton = styled(Button)`
  :disabled,
  [disabled] {
    background: transparent;
    border-color: var(--middleGrey);
    color: var(--middleGrey);

    :hover {
      box-shadow: none;
      background: #c2c2c2;
      color: #ffffff;
      cursor: not-allowed;
    }
  }
`;
const Progress = styled.div`
  & > div {
    width: 30%;
    padding-left: 1.2rem;
  }
  & > div:first-of-type {
    padding-left: 0rem;
  }
  & > div:last-child {
  }
`;
const Container = styled.div`
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--secondaryColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ResizeContainer = styled.div`
  transition: all 0.3s linear;
  &.fullScreen {
    transition: all 0.3s linear;
    background: white;
    position: absolute;
    top: -10px;
    z-index: 1050;
    width: 100%;
    padding: 0;
    margin: 0;
    padding: 0 1rem;
    left: 0;
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .ag-container {
      // width: 750px;
    }
    .ag-btn-group {
      z-index: 20;
    }
    // height: 100%;
  }
`;

const Mapper = {
  2: InterviewTab,
  3: EditTab
};
let editTab = false;
let TabScreen = Mapper[2];
function PreCallScreenResp(props) {
  const [step, setStep] = useState(2);
  const [usersList, setUsersList] = useState([]);
  const [callOn, setCallOn] = useState(false);
  const [respondantActive, setRespondantActive] = useState(false);
  TabScreen = Mapper[step];
  const [fullScreen, setFullScreen] = useState(false);
  let location = useLocation();
  const startInfo = location.state != undefined ? location.state.data : '';
  const editInfo = location.state?.editData;
  editTab = location.state?.editStatus;
  
  const [respondantInfo, setRespondentInfo] = useState(startInfo ? startInfo : editInfo);
  const projectRespondent_ID = respondantInfo?.projectRespondent_ID;
  const InterviewSchedule_ID = respondantInfo?.InterviewSchedule_ID != null ? respondantInfo?.InterviewSchedule_ID : '';
  const [selectedRespondantVideoId, setSelectedRespondantVideoId] = useState(respondantInfo?.projectRepondentVideo_ID);
  const dispatch = useDispatch();
  const userId = useSelector(state => state.LoginReducer.userId);
  const projectId = useSelector(state => state.ProjectReducer.project_ID);
  let { id } = useParams();
  const projectTitle = props.projectTitle != undefined ? props.projectTitle : '';
  useEffect(() => {
    if (editTab) {
      setStep(3);
    }
    if (location.state?.usersList?.length) {
      let users = location.state.usersList.map(user => ({
        ...user,
        value: user.RespondentName,
        label: user.RespondentName
      }));
      setUsersList(users);
    }
  }, []);
  useEffect(() => { }, [respondantActive]);
  useEffect(() => {
    if (id != undefined) {
      dispatch({
        type: REQUEST_CAPTURE_GUIDE,
        payload: {
          user_ID: userId,
          project_ID: id,
          projectRespondent_ID: projectRespondent_ID
        }
      });
    }
  }, [id]);

  const captureguideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);

  const changeScreenSize = () => {
    if (fullScreen) {
      setFullScreen(false);
      localStorage.setItem('resFullScreen', false);
    } else {
      setFullScreen(true);
      localStorage.setItem('resFullScreen', true);
    }
  };
  useEffect(() => {
    localStorage.setItem('projectRepondentVideo_ID', captureguideData?.projectRepondentVideo_ID);
    localStorage.setItem('projectRespondent_ID', projectRespondent_ID);
    localStorage.setItem('userId', userId);
  });

  useEffect(() => {
    const Payload = {
      user_ID: userId,
      project_ID: id
    };
    dispatch({ type: 'RESUME_DATA', payload: Payload });
  }, []);

  const projectStatus = props.projectStatus != undefined ? props.projectStatus : '';

  return (
    <>
      {/* <ProjectWrapper projectTitle={projectTitle} step={4} projectStatus={projectStatus} projectId={id}> */}
      {/* <Breadcurb>
        <li className="breadcrumb-item ">
          <Link to={`/dashboard-respondent`}>Field Overview</Link>
          Field Overview
          <a href="#">Field Overview</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Respondent Details
        </li>
      </Breadcurb> */}

      <Heading type={6} fontSize="1rem" fontWeight="600" color="var(--deepBlue)">
      {projectTitle}
      </Heading>

      {/* <HeaderCard callOn={callOn} className="row mt-2">
        <div
          className="col-md-3 py-2 px-4"
          style={{
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            background: 'var(--greyBlueLight)'
          }}
        >
          <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
            Respondent:{' '}
          </Heading>
          <Dropdown
            options={usersList}
            isDisabled={respondantActive}
            selectedFontSize="0.875rem"
            selectedColor="var(--deepBlue)"
            selectedFontWeight="700"
            controlStyle={{ minHeight: '0px' }}
            valueContainerStyle={{
              padding: 0
            }}
            onChange={(value) => {
              setRespondentInfo(value);
              setSelectedRespondantVideoId(value.projectRepondentVideo_ID)
              if(value.editStatus) {
                setStep(3);
              }else {
                setStep(2);
              }
              dispatch({
                type: REQUEST_CAPTURE_GUIDE,
                payload: {
                  user_ID: userId,
                  project_ID: id,
                  projectRespondent_ID: value.projectRespondent_ID
                }
              });
            }}
            DropdownIndicator={DownIndicator}
            selectedBorderBottom="2px solid var(--deepBlue)"
            defaultValue={{
              value: respondantInfo?.Respondent_ID,
              label: respondantInfo?.RespondentName != undefined ? respondantInfo?.RespondentName : respondantInfo?.name
            }}
          />
        </div>
        <div className="col-md-2 py-2 px-3" style={{ borderRight: '1px solid var(--greyBlue30)' }}>
          <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
            Group:{' '}
          </Heading>
          <div className="d-flex">
            <div className="">
              <Heading type={3} fontSize="0.875rem" fontWeight="600" color="var(--deepBlue)">
                {respondantInfo?.ProjectGroup}
              </Heading>
            </div>
            <div className="pl-2">
              <Heading type={3} fontSize="0.875rem" fontWeight="600" color="var(--deepBlue)">
                {respondantInfo?.groupName}
              </Heading>
            </div>
          </div>
        </div>
        <div
          className="col-md-7 py-2 px-4"
          style={{
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10
          }}
        >
          <Progress className="d-flex  align-items-center">
            <div>
              <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
                Form:{' '}
              </Heading>
              <div className="d-flex align-items-center">
                <Circle status={respondantInfo?.formStatus} />

                <Heading
                  type={3}
                  className="pb-0 mb-0"
                  fontSize="0.875rem"
                  fontWeight="500"
                  color="var(--deepBlue)"
                  breakHeading={false}
                >
                  {form_status[respondantInfo?.formStatus]}
                </Heading>
              </div>
            </div>
            <RightArrow />
            <div>
              <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
                Interview:{' '}
              </Heading>
              <div className="d-flex align-items-center">
                <Circle status={respondantInfo?.interviewStatus && respondantInfo?.interviewStatus == 2 ? 1 : respondantInfo?.interviewStatus} />
                <Circle status={respondantInfo?.interviewStatus && respondantInfo?.interviewStatus == 2 ? 0 : respondantInfo?.interviewStatus == 3 ? 0 : respondantInfo?.interviewStatus} />
                <Heading
                  type={3}
                  className="pb-0 mb-0"
                  fontSize="0.875rem"
                  fontWeight="700"
                  color="var(--deepBlue)"
                  breakHeading={false}
                >
                  {interview_status[respondantInfo?.interviewStatus]}
                </Heading>
              </div>
            </div>
            <RightArrow />

            <div>
              <Heading type={3} fontSize="0.75rem" fontWeight="400" color="var(--deepBlue)">
                Edit:{' '}
              </Heading>
              <div className="d-flex align-items-center">
                <Circle status={0} />

                <Heading
                  type={3}
                  className="pb-0 mb-0"
                  fontSize="0.875rem"
                  fontWeight="500"
                  color="var(--deepBlue)"
                  breakHeading={false}
                >
                  {edit_status[respondantInfo?.editStatus]}
                </Heading>
              </div>
            </div>
          </Progress>
        </div>
      </HeaderCard> */}
      <ResizeContainer className={`${fullScreen && 'fullScreen'}`}>
        <Container className="mt-4">
          <NavTabsContainer>
            <NavTabs>
              {/* <NavTab className="mx-3" fontSize="0.75rem" active={step === 1}>
                Form
              </NavTab> */}
              <NavTab onClick={() => setStep(2)} fontSize="0.75rem" active={step === 2}>
                Interview
              </NavTab>
              {/* <NavTab onClick={() => setStep(3)} fontSize="0.75rem" active={step === 3}>
                Edit
              </NavTab> */}
            </NavTabs>
          </NavTabsContainer>
          {fullScreen ? (
            <img src={minlScreen} onClick={() => changeScreenSize()} style={{ cursor: 'pointer' }} />
          ) : (
            <img src={expand} onClick={() => changeScreenSize()} style={{ cursor: 'pointer' }} />
          )}
        </Container>
        <TabScreen
          selectedRespondantVideoId={selectedRespondantVideoId}
          setRespondantActive={setRespondantActive}
          callOn={callOn}
          setStep={setStep}
          setCallOn={setCallOn}
          respondantInfo={respondantInfo}
          projectRespondent_ID={projectRespondent_ID}
          id={id}
          InterviewSchedule_ID={InterviewSchedule_ID}
          fullScreen={fullScreen}
        />
      </ResizeContainer>
      {/* </ProjectWrapper> */}
    </>
  );
}

const mapStateToProps = state => {
  return {
    // will be available as props.captureData
    projectTitle:
      state.ProjectReducer.resumeData.project_data != undefined
        ? state.ProjectReducer.resumeData.project_data[0].identity
        : '',
    projectStatus:
      state.ProjectReducer.resumeData.projectStatus != undefined
        ? state.ProjectReducer.resumeData?.projectStatus[0]?.steps_array != undefined
          ? state.ProjectReducer.resumeData?.projectStatus[0]?.steps_array
          : ''
        : ''
  };
};

// export default EditTab;
export default connect(mapStateToProps)(PreCallScreenResp);
