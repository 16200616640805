import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function CustomContentProgressbar(props) {
	const { children, percentage, ...otherProps } = props;

	return (
		<div
			style={{
				position: 'relative',
				width: '100%',
				height: '100%'
			}}
		>
			<div style={{ position: 'absolute' }}>
				<CircularProgressbar
					value={percentage}
					styles={buildStyles({
						pathColor: `#664078`,
						textColor: '#f88',
						trailColor: '#d6d6d6',
						backgroundColor: '#E2E2E2'
					})}
				/>
			</div>
			<div
				style={{
					position: 'absolute',
					height: '100%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{props.children}
			</div>
		</div>
	);
}
