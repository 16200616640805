import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetInviteeMethods from '../../utils/api/invite-recruit/GetInviteeData';

function* GetInviteeData(payload) {
  const promise = GetInviteeMethods.GetInviteeData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetInviteeAction.INVITEE_DATA_RECEIVED, data: res.Response });
}

export  { GetInviteeData }