import React from 'react';
import { Heading,  Button, Text, CardButton, EmptyState  } from "../../common";
import { Link } from 'react-router-dom';
import PlusWhite from '../../assets/images/plus-white.svg';
import PlusGreen from '../../assets/images/plus-green.svg';
import EmptyDashboard from '../../assets/images/empty-dashboard.png';


export default function DashboardScreen() {


    return (
        <>

            {/* Heading */}
            <div className="row">
                
                <div className="col-sm-8">
                    <Heading >Your Dashboard</Heading>
                    <Text type="3" color="var(--ternaryColor)" className="mb-0">View your live projects, calendar and recent notifications in one place</Text>
                </div>
                <div className="col-sm-4 ">
                    <div className="d-flex justify-content-end">
                        <Link to="/project/create">
                            <CardButton><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white" /> Create a project</CardButton>
                        </Link>
                        
                    </div>
                    
                </div>
                
            </div>

            <EmptyState imgSrc={EmptyDashboard} className="mt-5">
                <Heading type="2" fontWeight="700" fontSize="22px">Hello! Create your new project here.</Heading>
                <Text type="3">Begin your research by first creating a project</Text>

                <div className="d-flex justify-content-start align-items-center mt-5">
                    <Link to="/project/create" className="mr-4">
                        <CardButton inverted={true}><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white" /> Create a project</CardButton>
                    </Link>

                    <Link to="/project/create">
                        <Button fill={false} color="var(--primaryColor)">Get Help ?</Button>
                    </Link>
                </div>
                
            </EmptyState>

        </>
    )
}