import actions from "../../actions";


const initialState = {
    superAdminprojectData: null,
    // clientData: null,
    // usersData: null,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actions.ViewProjectSuperAdminDashboardAction.REQUEST_VIEWPROJECTSUPERADMINDASHBOARD_DATA:
            return {
                ...state,
            }
        case actions.ViewProjectSuperAdminDashboardAction.VIEWPROJECTSUPERADMINDASHBOARD_DATA_RECEIVED:
            // console.warn(action);
            return {
                ...state,
                superAdminprojectData: action?.data?.projectStatus,
                // superAdminProjectData: action?.data?.superAdminProjectStatus,
                // clientData: action?.data?.clientData,
                // usersData: action?.data?.usersData,
            }

        default:
            return state;
    }
};

export default reducer;