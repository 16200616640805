import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetPreEditorDataMethods from '../../utils/api/field/FieldPreEditorData';

function* GetPreEditorData(payload) {
  const promise = GetPreEditorDataMethods.GetPreEditorData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.FieldPreEditorAction.PRE_EDITOR_DATA_RECEIVED, data: res });
}

function* EditEditorTranscriptData({payload}) {
  const promise = GetPreEditorDataMethods.EditEditorTranscriptData(payload);
  const { res } = yield promise;
}

export { GetPreEditorData, EditEditorTranscriptData };
