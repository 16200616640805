import React from 'react';
import { useState, useEffect } from 'react';
import { Button, Text, Avatar, Heading } from '../../';
import styled from '@emotion/styled';
import {
    InputText,
    TextArea,
    DropFile,
    Dropdown,
    ButtonRadio,
    TaskTypeOption,
    TaskMenu,
} from '../../../components/form';
import { Divider } from '../../';
import Icon from '../../Icon'
import cross from '../../../assets/images/cross.svg';
import GreenDropdown from '../../../assets/images/green-dropdown.svg';
import profile_pic from '../../../assets/images/profile_pic.png';
import { REQUEST_EDITRESPONDANTDATAGENPOOL } from '../../../constants/SuperAdmin/EditRespondantDataGenPoolConstants';
import { useDispatch, useSelector } from 'react-redux';

const field = {
    marginLeft: "0px"
}

const InputTextContainer = styled.div`
	.iconContainer {
		position: absolute;
		top: 30%;
		right: 15px;
	}
	span {
		font-size: 18px;
		color: var(--greyBlue);
	}
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;
const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	float: right;
    margin-right: 5px;
`;

const Pill = styled.span`
	font-size: 9px;
	font-family: "Readex Pro", sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
`;

const gender_list = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' },
];

const city_list = [
    { value: 1, label: 'Ahmedabad' },
    { value: 2, label: 'Bangalore' },
    { value: 3, label: 'Mumbai' },
    { value: 4, label: 'Kolkata' },
    { value: 5, label: 'Delhi' },
];

const qualification_list = [
    { value: 1, label: 'Illiterate' },
    { value: 2, label: 'Literate but no formal schooling' },
    { value: 3, label: 'School (5-9 yrs)' },
    { value: 4, label: 'SSC/HSC' },
    { value: 5, label: 'Some College(Including a diploma but not graduate)' },
    { value: 6, label: 'Graduate/Post-Graduate: General' },
    { value: 7, label: 'Graduate/Post-Graduate: Professional' },
];

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function EditRespondantGenPoolModal({ userModaldata, setIsFlag, IsFlag }) {

    const [seeMoreProfile, setSeeMoreProfile] = useState(true);
    const [seeMorePrimary, setSeeMorePrimary] = useState(false);
    const [userType, setUserType] = useState('Respondant');
    const [GetResearcherGenPoolData, setGetResearcherGenPoolData] = useState([]);
    const [productUsed, setProductUsed] = useState({languages: []});
    const dispatch = useDispatch();

    useEffect(() => {
        setGetResearcherGenPoolData(userModaldata);
    }, [userModaldata])

    const handleChange = (evt) => {
        const value = evt.target.value;

        setGetResearcherGenPoolData({
            ...GetResearcherGenPoolData,
            [evt.target.name]: value,
        });

    };

    const handleChangeCheckbox = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { languages } = productUsed;
          
         
        // Case 1 : The user checks the box
        if (checked) {
          setProductUsed({
            languages: [...languages, value]
          });
        }
      
        // Case 2  : The user unchecks the box
        else {
          setProductUsed({
            languages: languages.filter((e) => e !== value),
            
          });
        }
        };

        useEffect(()=>{
            setGetResearcherGenPoolData({
                ...GetResearcherGenPoolData,
                'product_used': productUsed.languages,
            });
        },[productUsed])

    // console.warn('Product Used',productUsed.languages);

    // console.warn(isMarried);

    // console.warn('User Data',GetUserData);

    const editRespondant = () => {

        const formData = {
            userRole: "Respondant",
            role_ID: 4,
            full_name: GetResearcherGenPoolData.name,
            email: 'q@gmail.com',
            phone_no: GetResearcherGenPoolData.phone_no,
            gender_ID: GetResearcherGenPoolData.gender_ID,
            profileImage: "",
            dateOfBirth: GetResearcherGenPoolData.dateOfBirth,
            isMarried: isMarried,
            noOfKids: GetResearcherGenPoolData.noOfKids,
            address: GetResearcherGenPoolData.address,
            center_ID: GetResearcherGenPoolData.city_ID,
            noOfRooms: GetResearcherGenPoolData.noOfRooms,
            HomeType: [GetResearcherGenPoolData.house_type],
            ownProfession: GetResearcherGenPoolData.occupation,
            ownannualIncome: GetResearcherGenPoolData.annualIncome,
            ownEducation_ID: GetResearcherGenPoolData.qualification,
            parentProfession: GetResearcherGenPoolData.pOccupation,
            parentannualIncome: GetResearcherGenPoolData.pAnnualIncome,
            parentEducation_ID: GetResearcherGenPoolData.pQualification
        }
        dispatch({
            type: REQUEST_EDITRESPONDANTDATAGENPOOL,
            payload: formData
        });
        setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
            () => dispatch({
                type: REQUEST_SUPERADMINDASHBOARD_DATA,
                // payload: {
                //   user_ID: userId
                // }
            }),
            500
        );
        //   setGetTeamData(teamModaldata);
        //   setIsFlag(1);
    }


    return (
        <div>
            <>

                {/* <Button
                    className='ml-4'
                    size='small'
                    variant='outlined'
                    width='50px'
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                >
                    edit
                </Button> */}

                <div className="modal fade" id="editRespondantGenPoolModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" >
                                <Text><Pill className='mt-2 ml-2'>Respondant
                                    <img
                                        src={cross}
                                        className='ml-1 mb-1'
                                        style={{ cursor: 'pointer', borderRadius: '50%' }}
                                    /></Pill></Text>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <Avatar
                                                src={GetResearcherGenPoolData?.presignedUrl}
                                                className='team-profile-pic'
                                                width='60'
                                                height='60'
                                            />
                                        </div>

                                        {/* <div className='col ml-4' id='heading'>
                                            <Heading
                                                fontSize='14px'
                                                className='ml-2'
                                            >
                                                User Role
                                            </Heading>
                                            <div className='col mt-2' style={{ paddingLeft: "5px" }}>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <Text><input type='radio' name='user_type' value="Reseacher" style={{ accentColor: "#728F01" }} onClick={() => setUserType('Researcher')} />    Researcher</Text>
                                                    </div>
                                                    <div className='col'>
                                                        <Text><input type='radio' className='ml-3' name='user_type' value="Respondant" defaultChecked style={{ accentColor: "#728F01" }} onClick={() => setUserType('Respondant')} />    Respondant</Text>
                                                    </div>
                                                    <div className='col'>
                                                        <Text><input type='radio' className='ml-3' name='user_type' value='Observer' style={{ accentColor: "#728F01" }} onClick={() => setUserType('Observer')} />    Observer</Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}



                                        <div className='container w-75'>
                                            <div className='row'>
                                                {/* PRofile Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Profile
                                                    </Heading>
                                                    {/* {seeMoreProfile == true ? ( */}
                                                        <>
                                                            <InputTextContainer>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='full_name'
                                                                            placeholder='Full name'
                                                                            showCharacters={false}
                                                                            defaultValue={GetResearcherGenPoolData?.name}
                                                                            value={GetResearcherGenPoolData?.name}
                                                                            onChange={handleChange}
                                                                            setIsFlag={setIsFlag}
                                                                            IsFlag={IsFlag}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='phone_no'
                                                                            placeholder='Phone No.'
                                                                            showCharacters={false}
                                                                            defaultValue={GetResearcherGenPoolData?.name}
                                                                            value={GetResearcherGenPoolData?.name}
                                                                            onChange={handleChange}
                                                                            setIsFlag={setIsFlag}
                                                                            IsFlag={IsFlag}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='dateOfBirth'
                                                                            placeholder='Date of birth'
                                                                            showCharacters={false}
                                                                            defaultValue={GetResearcherGenPoolData?.name}
                                                                            value={GetResearcherGenPoolData?.name}
                                                                            onChange={handleChange}
                                                                            setIsFlag={setIsFlag}
                                                                            IsFlag={IsFlag}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <Dropdown
                                                                            placeholder='Gender'
                                                                            showCharacters={false}
                                                                            options={gender_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setGetResearcherGenPoolData({
                                                                                    ...GetResearcherGenPoolData,
                                                                                    'gender': evt.value
                                                                                })
                                                                            }
                                                                        // value='Male'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        {/* <Icon className='iconContainer'>create</Icon> */}
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='marital_status'
                                                                            placeholder='Marital Status'
                                                                            showCharacters={false}
                                                                            defaultValue={GetResearcherGenPoolData?.name}
                                                                            value={GetResearcherGenPoolData?.name}
                                                                            onChange={handleChange}
                                                                            setIsFlag={setIsFlag}
                                                                            IsFlag={IsFlag}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    {/* <div className='col-4'>
                                                                            <InputText
                                                                            name='no_of_kids'
                                                                                placeholder='No. Of Kids'
                                                                                showCharacters={false}
                                                                                defaultValue={GetResearcherGenPoolData?.name}
                                                                                value={GetResearcherGenPoolData?.name}
                                                                                onChange={handleChange}
                                                                                setIsFlag={setIsFlag}
                                                                                IsFlag={IsFlag}
                                                                            // showIcon={true}
                                                                            // iconName="create"
                                                                            // error='Hey, looks like you’ve missed this'
                                                                            />
                                                                            <Icon className='iconContainer'>create</Icon>
                                                                        </div> */}
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-12'>
                                                                        <InputText
                                                                            name='address'
                                                                            placeholder='Address'
                                                                            showCharacters={false}
                                                                            defaultValue={GetResearcherGenPoolData?.name}
                                                                            value={GetResearcherGenPoolData?.name}
                                                                            onChange={handleChange}
                                                                            setIsFlag={setIsFlag}
                                                                            IsFlag={IsFlag}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <Dropdown
                                                                            name='city'
                                                                            placeholder='City'
                                                                            showCharacters={false}
                                                                            options={city_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setGetResearcherGenPoolData({
                                                                                    ...GetResearcherGenPoolData,
                                                                                    'city': evt.label
                                                                                })
                                                                            }
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        {/* <Icon className='iconContainer'>create</Icon> */}
                                                                    </div>
                                                                    {/* <div className='col-4'>
                                                                            <Text><input type='radio' className='ml-3 mt-3' name='house_type' value='1' onChange={handleChange} style={{ accentColor: "#728F01" }}  defaultChecked/>    Flat</Text>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <Text><input type='radio' className='ml-3 mt-3' name='house_type' value='2' onChange={handleChange} style={{ accentColor: "#728F01" }}  />    Bunglow</Text>
                                                                        </div> */}
                                                                </div>
                                                                {/* <div className='row' style={field}>
                                                                        <div className='col-4'>
                                                                            <InputText
                                                                            name='noOfRooms'
                                                                                placeholder='No. of Rooms'
                                                                                showCharacters={false}
                                                                                defaultValue={GetResearcherGenPoolData?.name}
                                                                                value={GetResearcherGenPoolData?.name}
                                                                                onChange={handleChange}
                                                                                setIsFlag={setIsFlag}
                                                                                IsFlag={IsFlag}
                                                                            // showIcon={true}
                                                                            // iconName="create"
                                                                            // error='Hey, looks like you’ve missed this'
                                                                            />
                                                                            <Icon className='iconContainer'>create</Icon>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row' style={field}>
                                                                        <div className='col-8'>
                                                                            <InputText
                                                                            name='occupation'
                                                                                placeholder='Occupation'
                                                                                showCharacters={false}
                                                                                defaultValue={GetResearcherGenPoolData?.name}
                                                                                value={GetResearcherGenPoolData?.name}
                                                                                onChange={handleChange}
                                                                                setIsFlag={setIsFlag}
                                                                                IsFlag={IsFlag}
                                                                            // showIcon={true}
                                                                            // iconName="create"
                                                                            // error='Hey, looks like you’ve missed this'
                                                                            />
                                                                            <Icon className='iconContainer'>create</Icon>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <InputText
                                                                             name='annualIncome'
                                                                                placeholder='Annual income'
                                                                                showCharacters={false}
                                                                                defaultValue={GetResearcherGenPoolData?.name}
                                                                                value={GetResearcherGenPoolData?.name}
                                                                                onChange={handleChange}
                                                                                setIsFlag={setIsFlag}
                                                                                IsFlag={IsFlag}
                                                                            // showIcon={true}
                                                                            // iconName="create"
                                                                            // error='Hey, looks like you’ve missed this'
                                                                            />
                                                                            <Icon className='iconContainer'>create</Icon>
                                                                        </div>
                                                                    </div> */}
                                                                {/* <div className='row' style={field}>
                                                                        <div className='col-4'>
                                                                            <Dropdown
                                                                             name='qualification'
                                                                             placeholder='Qualifications'
                                                                             showCharacters={false}
                                                                             options={qualification_list}
                                                                             onChange={(evt) =>
                                                                                 //  console.warn(evt.label)
                                                                                 setGetResearcherGenPoolData({
                                                                                     ...GetResearcherGenPoolData,
                                                                                     'qualification': evt.value
                                                                                 })
                                                                             }
                                                                            />
                                                                        </div>
                                                                    </div> */}
                                                            </InputTextContainer>
                                                        </>
                                                    {/* ) : null
                                                    } */}
                                                </div>
                                                {/* END */}
                                            </div>
                                            {/* See more Button */}
                                            {/* <SeeButton className='mt-3' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreProfile(!seeMoreProfile)}>{seeMoreProfile ? SeeLess() : SeeMore()}</SeeButton> */}
                                            {/* END */}

                                            {/* <Divider
                                                    margin='0'
                                                    height='2px'
                                                    width='100%'
                                                    color='#38454F'
                                                    className='mt-2'
                                                /> */}

                                            <div className='row mt-3'>
                                                {/* Products Used at home */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Products Used at home -
                                                    </Heading>
                                                    {/* {seeMorePrimary == true ? ( */}
                                                    <>
                                                        <InputTextContainer>
                                                            <div className='row' style={{ width: "133%" }}>
                                                                {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='electricity_connection' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Electricity connection</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='LPG_stove' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    LPG Stove</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='refrigerator' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Refrigerator</Text>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <div className='row' style={{ width: "133%" }}>
                                                                {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='colour_television' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Colour Television</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='ceiling_fan' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Ceiling Fan</Text>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='washing_machine' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Washing Machine</Text>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <div className='row' style={{ width: "133%" }}>
                                                                {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='computer/laptop' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Computer/Laptop</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='four_wheeler' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Four Wheeler</Text>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='air_conditioner' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Air Conditioner</Text>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <div className='row' style={{ width: "133%" }}>
                                                                {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='two_wheeler' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Two-Wheeler</Text>
                                                                </div>
                                                                {/* <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='2' onChange={handleChange} style={{ accentColor: "#728F01" }}  />    Bunglow</Text>
                                                                    </div> */}
                                                                {/* <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='3' onChange={handleChange} style={{ accentColor: "#728F01" }}  />    Text</Text>
                                                                    </div> */}
                                                                {/* </div> */}
                                                            </div>
                                                        </InputTextContainer>
                                                    </>

                                                    {/* ) : null
                                                    } */}
                                                </div>
                                                {/* END */}
                                            </div>

                                            <div className='row mt-3'>
                                                {/* Primary Earning Member Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Primary Earning Member Details
                                                    </Heading>
                                                    {/* {seeMorePrimary == true ? ( */}
                                                        <>
                                                            <InputTextContainer>
                                                                {/* <div className='row' style={field}>
                                                                        <div className='col-8'>
                                                                            <InputText
                                                                             name='pOccupation'
                                                                                placeholder='Occupation'
                                                                                showCharacters={false}
                                                                                defaultValue={GetResearcherGenPoolData?.name}
                                                                                value={GetResearcherGenPoolData?.name}
                                                                                onChange={handleChange}
                                                                                setIsFlag={setIsFlag}
                                                                                IsFlag={IsFlag}
                                                                            // showIcon={true}
                                                                            // iconName="create"
                                                                            // error='Hey, looks like you’ve missed this'
                                                                            />
                                                                            <Icon className='iconContainer'>create</Icon>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <InputText
                                                                            name='pAnnualIncome'
                                                                                placeholder='Annual Income'
                                                                                showCharacters={false}
                                                                                defaultValue={GetResearcherGenPoolData?.name}
                                                                                value={GetResearcherGenPoolData?.name}
                                                                                onChange={handleChange}
                                                                                setIsFlag={setIsFlag}
                                                                                IsFlag={IsFlag}
                                                                            // showIcon={true}
                                                                            // iconName="create"
                                                                            // error='Hey, looks like you’ve missed this'
                                                                            />
                                                                            <Icon className='iconContainer'>create</Icon>
                                                                        </div>
                                                                    </div> */}
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <Dropdown
                                                                            name='pQualification'
                                                                            placeholder='Qualifications'
                                                                            showCharacters={false}
                                                                            options={qualification_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setGetResearcherGenPoolData({
                                                                                    ...GetResearcherGenPoolData,
                                                                                    'pQualification': evt.value
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </InputTextContainer>
                                                        </>

                                                    {/* ) : null
                                                    } */}
                                                </div>
                                                {/* END */}
                                            </div>
                                            {/* See more Button */}
                                            {/* <SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMorePrimary(!seeMorePrimary)}>{seeMorePrimary ? SeeLess() : SeeMore()}</SeeButton> */}
                                            {/* END */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer border-0">
                            </div>
                            {/* <div className='container-fluid'> */}
                            <div className='row'>
                                <div className='col-3 modal-footer border-0 ml-4' style={{ justifyContent: "flex-start" }}>
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        // variant='outlined'
                                        width='60px'
                                        onClick={editRespondant}
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Save
                                    </CustomButton>
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Cancel
                                    </CustomButton>
                                </div>
                                <div className='col-8'>
                                    <CustomButton
                                        className='ml-2 mt-3'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-toggle="modal" data-target="#DeleteModalRespondant" data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Delete
                                    </CustomButton>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                {/* New Modal for Delete User  */}
                <div className="modal fade" id="DeleteModalRespondant" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" style={{ paddingBottom: "0px" }}>
                                <Heading fontSize='18px'>Confirm Delete</Heading>
                                <button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                <div className='row'>
                                    <div className='col col-12'>
                                        <Text type='7' color="var(--ternaryColor)" className="mb-0">Are you sure you want to delete this Respondent?</Text>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingTop: '20px' }}>
                                    <div className='col'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            variant='outlined'
                                            width='60px'
                                            // width='5px'
                                            // height='4px'
                                            fontSize='10px'
                                            style={{ float: "left" }}
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </CustomButton>
                                    </div>
                                    <div id='delete' className='col col-4 col-offset-6'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            // variant='outlined'
                                            width='60px'
                                            data-toggle="modal"
                                            data-target="#UserDeletedModal" data-dismiss="modal"
                                        // style={{ color: "#798E8F", background: "none" }}
                                        >
                                            Delete
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer border-0">
                                <div className='row'>
                                    <div className='col col-4 col-offset-6'>
                                        <Button
                                            className='ml-4'
                                            size='small'
                                            variant='outlined'
                                            width='20px'

                                        >
                                            save changes
                                        </Button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* END */}
            </>
        </div >
    )
}
