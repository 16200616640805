import styled from '@emotion/styled';
import React, {useEffect, useRef} from 'react';
import { Heading, Icon } from '../../common';
import { useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { useState } from 'react';
import SearchableDropdown from '../../components/form/SearchableDropdown';

const IconCustom = styled(Icon)`
	font-size: 0.75rem;
	// margin-top: 7px;
`;

const FormContainer = styled.div`
	display: flex;
	width: auto;
	padding: 0 8px;
	margin: 0;
	border: ${(props) =>
		props.color
			? `1px solid ${props.color}`
			: `1px solid var(--greyBorder)`};
	border-radius: 20px;
	.css-tlfecz-indicatorContainer {
		display: none;
	}
	input {
		font-size: 11px;
		font-weight: 500;
		color: ${(props) =>
			props.color ? `${props.color} ` : `var(--white)`};

		color: red;
	}
	span {
		align-self: flex-end;
		margin-bottom: 0.5rem;
	}
`;

const customStylesDesign = {
	container: (provided, state) => ({
		...provided,
		border: 0,
		borderRadius: 0,
	}),

	valueContainer: (provided, state) => ({
		...provided,
		...state.selectProps.valueContainerStyle,
	}),

	control: (provided, state) => ({
		...provided,
		outline: 0,
		borderRadius: 0,
		border: 0,
		background: 'transparent',
		// border: '1px solid var(--white)',
		// borderRadius: '20px',
		borderColor: state.isFocused
			? 'var(--inputColor)'
			: 'var(--borderColor)',
		boxShadow: 'none',
		minHeight: '30px',
		minWidth: '18px',
		'&:hover': {
			borderColor: state.isFocused
				? 'var(--inputColor)'
				: 'var(--borderColor)',
		},
		...state.selectProps.controlStyle,
	}),

	menuList: (provided, state) => ({
		...provided,

		borderRadius: '5px',
		width: 'auto',
		padding: '0 10px',
		border: '1px solid #EFEFEF',
		position: 'absolute',
		// background: '#8A8A8A',
		background: '#FFFFFF',
		width: '180px',
		zIndex: 5,
		boxShadow: 'none',
		textAlign: 'left',
		'&::-webkit-scrollbar':{
			width:'4px',
			borderRadius:'100px',
		},
		'&::-webkit-scrollbar-track':{
			background:'#f0f0f0'
		},
		'&::-webkit-scrollbar-thumb':{
			background:'var(--scrollbarColor)'
		},
		'&::-webkit-scrollbar-thumb:hover':{
			background:'var(--scrollbarColor)'
		},

	}),

	option: (provided, state) => ({
		fontSize: '0.75rem',
		fontWeight: '500',
		// color: 'var(--white)',
		color: 'var(--inputColor)',
		padding: '0px 3px',
		paddingTop: '3px',
		textAlign: 'left',
		cursor: 'pointer',
		// borderBottom: '1px solid var(--white)',
		borderBottom: '1px solid #E2E2E2',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		// '&:last-child': {
		// 	borderBottom: 0,
		// },
	}),

	input: (provided, state) => ({
		...provided,
		margin: '1px',
		fontSize: '11px',
		fontWeight: '500',
		color: 'var(--white)',
		...state.selectProps.inputStyle,
	}),

	singleValue: (provided, state) => ({
		fontSize: state.selectProps.selectedFontSize
			? state.selectProps.selectedFontSize
			: '11px',
		fontWeight: state.selectProps.selectedFontWeight
			? state.selectProps.selectedFontWeight
			: '500',
		color: state.selectProps.selectedColor
			? state.selectProps.selectedColor
			: 'var(--white)',

		...state.selectProps.singleValueStyle,
	}),

	indicatorSeparator: () => ({
		display: 'none',
	}),
	IndicatorsContainer: () => ({
		display: 'none',
	}),
	placeholder: () => ({
		fontSize: '0.75rem',
		color: 'var(--mediumGrey)',
	}),

	// menu: (provided, state) => ({
	//   ...provided,
	//   width: state.selectProps.width,
	//   borderBottom: '1px dotted pink',
	//   color: state.selectProps.menuColor,
	//   padding: 20,
	// }),
};

function Input({ InputIndex, className, options, value = '', color, singleValueStyle, onSelectTag, onChangeTag, onDeleteTag }) {
	const [text, setText] = useState(value.tag);
	const [edit, setEdit] = useState(false);
	const [masterTagList, setMasterTagList] = useState(options);
	// const [mlist, setmlist] = useState();
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const clickedonDeleteForEdittag = useRef();
	const mlist = useRef();

	const userId = useSelector(state => state.LoginReducer.userId);
	const {projectId} = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		setText(text) ;
	},[text])

	// console.log("text",text);

	const handleDeleteDispatch = (id) => {
		let payload = {
			"user_ID" : userId,
			"id" : id
		}
		dispatch({ type: 'REQUEST_DELETEFORMTAG', payload: payload });
		// setTimeout(() => {
		// 	dispatch({ 
		// 		type: 'REQUEST_GET_VIDEO_TASK' , 
		// 		payload: {
		// 			user_ID: userId,
		// 			project_ID: projectId,
		// 			projectTask_ID: projectTask_ID
		// 		}
		// 	});
		// }, 800);
	}

	const handleClickonDelete = (dclick) => {
		clickedonDeleteForEdittag.current = dclick ;
	}

	const handleMtaglist = (list) => {
		// console.log("list",list);
		mlist.current = list ;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setEdit(false);
		setMenuIsOpen(false);

		let index = options.findIndex((o) => o.label == text);
	
		let newTag = index < 0  ? {tag_ID: "", tag: text, value:"", label: text} : {tag_ID: options[index].value, tag: text, value: options[index].value, label: text};

		if(value.hasOwnProperty('isNew') && value?.isNew == true)
		{
			onSelectTag(newTag);
		}
		else
		{
			onChangeTag(InputIndex, newTag);
		}
	};
	return (
		<FormContainer color={color} className={className} disabled={!edit}>
			<form onSubmit={handleSubmit}>
				<SearchableDropdown
					isSearchable
					singleValueStyle={singleValueStyle}
					valueContainerStyle={{
						padding: edit ? '0 20px 0 0px' : '0',
					}}
					controlStyle={{ minHeight: 'auto' }}
					inputStyle={{
						color: color,
					}}
					defaultValue={value ? {label:text, value:value.tag_ID} : ''}
					value={{label: text}}
					selectedColor={color}
					options={options}
					onChange={(opt) => {
						if(clickedonDeleteForEdittag.current){
							// console.log("mlist",mlist);
							const ind = mlist.current?.findIndex(mtag => mtag.label == text) ;
							if(ind >= 0){
								let txt = mlist.current[ind]?.label ;
								// console.log("txt",txt);
								setText(txt);
							} 
							else setText('')
							// let txt = mlist.current.length > 0 ? mlist.current[0].label : '';
							// setText(txt);
						}
						else{
							// console.log("opt.label",opt.label);
							setText(opt.label);
						}
						!edit && setEdit(true);
					}}
					masterTagList={masterTagList}
					setMasterTagList={setMasterTagList}
					menuIsOpen={menuIsOpen}
					setMenuIsOpen={setMenuIsOpen}
					passHandleDeleteDispatch={handleDeleteDispatch}
					passClickedonDelete={handleClickonDelete}
					passMtaglist={handleMtaglist}
					crossOption={true}
					customStylesForDropdown={customStylesDesign}
				/>
			</form>

			<div style={{ height: '12px' }}>
				{!edit ?
					<IconCustom color={color} style={{cursor:"pointer"}} onClick={() => {
						onDeleteTag(text)
					}}>close</IconCustom>
					:
					<IconCustom color={color} style={{cursor:"pointer"}} onClick={(e) => handleSubmit(e)}>subdirectory_arrow_left</IconCustom>
				}
				
			</div>
		</FormContainer>
	);
}

function EditingTag({
	tagColor,
	addTagButtonColor = 'var(--greyBorder)',
	AddTagButton,
	singleValueStyle,
	tagList,
	options,
	onSelectTag,
	onChangeTag,
	onDeleteTag
}) {
	const [tagsList, setTagsList] = useState([]);

	useEffect(() => {
		setTagsList(tagList);
	},[tagList])

	// useEffect(() => {
	// 	setTagsList(tagsList);
	// },[tagsList])

	
	
	
	const handleAddtag = () => {
		setTagsList((prev) => [...prev, {label: '', value: '', isNew: true}]);
		// tagList = [...tagList, {label: '', value: '', isNew: true}] ;
	};
	return (
		<div className='d-flex align-items-center flex-wrap'>
			{tagsList &&
				tagsList.map((item, index) => (
					<Input
						key={item.tag_ID}
						InputIndex={index}
						className='mr-2 mb-2'
						value={item}
						color={tagColor}
						singleValueStyle={singleValueStyle}
						options={options}
						onSelectTag={onSelectTag}
						onChangeTag={onChangeTag}
						onDeleteTag={onDeleteTag}
					/>
				))}
			{/* {tagList &&
				tagList.map((item, index) => (
					<Input
						key={item.tag_ID}
						InputIndex={index}
						className='mr-2 mb-2'
						value={item}
						color={tagColor}
						singleValueStyle={singleValueStyle}
						options={options}
						onSelectTag={onSelectTag}
						onChangeTag={onChangeTag}
						onDeleteTag={onDeleteTag}
					/>
				))} */}
			
			<div
				className='d-flex align-items-center mb-2 '
				onClick={handleAddtag}
			>
				{AddTagButton ? (
					<AddTagButton />
				) : (
					<>
						<Icon
							style={{ fontSize: '16px' }}
							color={addTagButtonColor}
						>
							add
						</Icon>{' '}
						<Heading
							fontSize='10px'
							fontWeight='500'
							color={addTagButtonColor}
							className='mb-0 pb-0 ml-1'
						>
							Add Tag{' '}
						</Heading>
					</>
				)}
			</div>
		</div>
	);
}

export default EditingTag;