export default class AuthService {
  
  static login(data) {
    localStorage.setItem('userData', JSON.stringify(data));
    localStorage.setItem('userId', data.user_id);
    localStorage.setItem('token', data.token_data.access_token);
  }

  static logout() {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
  }

  static getToken() {
    const token = localStorage.getItem('token');
    return `Bearer ${token}`;
  }

  static getUserData() {
    const userData = localStorage.getItem('userData');
    return JSON.parse(userData);
  }

  static getUserId(){
    const userId = localStorage.getItem('userId');
    return userId;
  }
}
