import React, { useEffect } from 'react';
import { useState } from 'react';

import { Button, Text, Avatar, Heading } from '..';
import styled from '@emotion/styled';
import {
    InputText,
    TextArea,
    DropFile,
    Dropdown,
    ButtonRadio,
    TaskTypeOption,
    TaskMenu,
} from '../../components/form';
import { Divider } from '..';
import Icon from '../Icon'
import cross from '../../assets/images/cross.svg';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import profile_pic from '../../assets/images/profile_pic.png';
import { REQUEST_ADDNEWUSER } from '../../constants/clientAdmin/AddNewUserConstants';
import { useDispatch, useSelector } from 'react-redux';

const field = {
    marginLeft: "0px"
}

const InputTextContainer = styled.div`
	.iconContainer {
		position: absolute;
		top: 30%;
		right: 15px;
	}
	span {
		font-size: 18px;
		color: var(--greyBlue);
	}
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;
const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	float: right;
    margin-right: 5px;
`;

const gender_list = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' },
];

const city_list = [
    { value: 1, label: 'Ahmedabad' },
    { value: 2, label: 'Bangalore' },
    { value: 3, label: 'Mumbai' },
    { value: 4, label: 'Kolkata' },
    { value: 5, label: 'Delhi' },
];

const qualification_list = [
    { value: 1, label: 'Not Educated' },
    { value: 2, label: 'Literate but no formal schooling' },
    { value: 3, label: 'School (5-9 yrs)' },
    { value: 4, label: 'SSC/HSC' },
    { value: 5, label: 'Some College(Including a diploma but not graduate)' },
    { value: 6, label: 'Graduate/Post-Graduate: General' },
    { value: 7, label: 'Graduate/Post-Graduate: Professional' },
];

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function AddUserModal({setIsFlag, IsFlag, teamData, city, gender, qualification}) {
    const [seeMoreProfile, setSeeMoreProfile] = useState(true);
    const [seeMorePrimary, setSeeMorePrimary] = useState(false);
    const [userType, setUserType] = useState('Respondant');
    const [GetUserData,setGetUserData] = useState([]); 
    const [productUsed, setProductUsed] = useState({languages: []}); 
    const [clientData, setclientData] = useState();
    const [gender_ID, setgender_ID] = useState();
    const [isMarried, setisMarried] = useState();
    const [city_ID, setcity_ID] = useState();
    const [qulification, setqulification] = useState();
    const [pQulification, setpQulification] = useState();
    const [isModalOpen, setModalStatus] = useState(false);
    const addnewUserData = useSelector(state => state.AddNewUserReducer.addnewUserData);

const [updatedMessage, setupdatedMessage] = useState('');

    useEffect(() =>{ 
    },[updatedMessage])
    const dispatch = useDispatch(); 
    
    const handleChnage = (evt) => {    
		const value = evt.target.value;
        if(evt.target.name == "teamname")
        {
            setclientData(teamData.filter(x => x.teamName === evt.target.value));
        }

        if(evt.target.name == "married"){
            if(evt.target.value == "married" || evt.target.value == "Married"){
                setisMarried(1);
            }else{
                setisMarried(0);
            }
        }

		setGetUserData({
			...GetUserData,
			[evt.target.name]: value,
		});
        setIsFlag(0);

	};

    const handleChangeCheckbox = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { languages } = productUsed;
          
     
         
        // Case 1 : The user checks the box
        if (checked) {
          setProductUsed({
            languages: [...languages, value]
          });
        }
      
        // Case 2  : The user unchecks the box
        else {
          setProductUsed({
            languages: languages.filter((e) => e !== value),
            
          });
        }

        };

        useEffect(()=>{
            setGetUserData({
                ...GetUserData,
                'product_used': productUsed.languages,
            });
        },[productUsed])

    // console.warn('Product Used',productUsed.languages);

    // console.warn('User Data',GetUserData);

    const handleChnageGender = (evt) => {
        setgender_ID(evt.value);
	};

    const handleChnagecity = (evt) => {
        setcity_ID(evt.value);
	};
    const handleChnagequlification = (evt) => {
        setqulification(evt.value);
	};
    const handleChnagepQulification = (evt) => {
        setpQulification(evt.value);
	};

    const addMember = () =>{


        if(userType == "Researcher"){
            const formData ={
                userRole:"Researcher",
                name:GetUserData.name,
                email:GetUserData.email,
                mobile:GetUserData.mobile,
                clientTeam_ID:clientData != undefined ? clientData[0]?.clientTeam_ID :"" 
                }
                    dispatch({
                        type: REQUEST_ADDNEWUSER,
                        payload: formData
                    });
        }else if(userType == 'Observer'){
            const formData ={
                userRole:"Observer",
                name:GetUserData.name,
                email:GetUserData.email,
                mobile:GetUserData.mobile,
            }

            dispatch({
                type: REQUEST_ADDNEWUSER,
                payload: formData
            });
        }else if(userType == "Respondant"){
            const formData ={
                userRole:"Respondent",
                name:GetUserData.name,
                mobile:GetUserData.mobile,
                gender_ID:gender_ID,
                dateOfBirth:GetUserData.dateOfBirth,
                isMarried:isMarried,
                noOfKids:GetUserData.noOfKids,
                address:GetUserData.address,
                city_ID:city_ID,
                noOfRooms:GetUserData.noOfRooms,
                occupation:GetUserData.occupation,
                annualIncome:GetUserData.annualIncome,
                qulification:qulification,
                pOccupation:GetUserData.pOccupation,
                pAnnualIncome:GetUserData.pAnnualIncome,
                pQulification:pQulification
            }
            dispatch({
                type: REQUEST_ADDNEWUSER,
                payload: formData
            });
        }
        
            setGetUserData([]);
            setIsFlag(1);

            setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
            () => addnewUserData == "Researcher added successfully." ? $("#addUserModal").modal('hide') 
                : addnewUserData == "Respondent added successfully." ? $("#addUserModal").modal('hide')
                : addnewUserData == "Observer added successfully." ? $("#addUserModal").modal('hide')
                : setupdatedMessage("Please fill all the fields"),
            400);
            
        }

    const cancelChanges = () => {
        setGetUserData([]);
        setIsFlag(1);
    }

    
    return (
        <div>
            <>

                {/* <Button
                    className='ml-4'
                    size='small'
                    variant='outlined'
                    width='50px'
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                >
                    edit
                </Button> */}

                <div className="modal fade" id="addUserModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" show={isModalOpen}
          onHide={() => setModalStatus(false)}>
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{borderRadius: "10px"}}>
                            <div className="modal-header border-0" >
                                <Text className='mt-2 ml-2'>New User</Text>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <Avatar
                                                src={profile_pic}
                                                className='team-profile-pic'
                                                width='60'
                                                height='60'
                                            />
                                        </div>

                                        <div className='col ml-4' id='heading'>
                                            <Heading
                                                fontSize='14px'
                                                className='ml-2'
                                            >
                                                User Role
                                            </Heading>
                                            <div className='col mt-2' style={{ paddingLeft: "5px" }}>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <Text><input type='radio' name='user_type' value="Reseacher" style={{ accentColor: "#728F01" }} onClick={() => {setUserType('Researcher');setGetUserData([]);setIsFlag(1);}} />    Researcher</Text>
                                                    </div>
                                                    <div className='col'>
                                                        <Text><input type='radio' className='ml-3' name='user_type' value="Respondant" defaultChecked style={{ accentColor: "#728F01" }} onClick={() => {setUserType('Respondant');setGetUserData([]);setIsFlag(1);}} />    Respondant</Text>
                                                    </div>
                                                    <div className='col'>
                                                        <Text><input type='radio' className='ml-3' name='user_type' value='Observer' style={{ accentColor: "#728F01" }} onClick={() => {setUserType('Observer');setGetUserData([]);setIsFlag(1);}} />    Observer</Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {(userType == 'Researcher') ? (
                                    <div className='container w-75'>
                                        <div className='row'>
                                            {/* PRofile Details */}
                                            <div>
                                                <Heading
                                                    fontSize='14px'
                                                    className='mt-3 ml-3'
                                                >
                                                    Profile
                                                </Heading>

                                                <>
                                                    <InputTextContainer>
                                                    {/* <div>{updatedMessage}</div> */}
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='name'
                                                                    placeholder='Full Name'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.name}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='mobile'
                                                                    placeholder='Phone No.'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.mobile}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='email'
                                                                    placeholder='Email ID'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.email}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='teamname'
                                                                    placeholder='Team Name'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.teamName}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='designation'
                                                                    placeholder='Designation'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.designation}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                    </InputTextContainer>
                                                </>
                                            </div>
                                            {/* END */}
                                        </div>
                                    </div>
                                ) : (userType == 'Observer') ? (
                                    <div className='container w-75'>
                                        <div className='row'>
                                            {/* PRofile Details */}
                                            <div>
                                                <Heading
                                                    fontSize='14px'
                                                    className='mt-3 ml-3'
                                                >
                                                    Profile
                                                </Heading>

                                                <>
                                                    <InputTextContainer>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='name'
                                                                    placeholder='Full Name'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.name}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='mobile'
                                                                    placeholder='Phone No.'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.mobile}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='email'
                                                                    placeholder='Email ID'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.email}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='designation'
                                                                    placeholder='Designation'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
						                                            IsFlag={IsFlag}
                                                                    value={GetUserData?.designation}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                    </InputTextContainer>
                                                </>
                                            </div>
                                            {/* END */}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='container w-75'>
                                    <div className='row'>
                                        {/* PRofile Details */}
                                        <div>
                                            <Heading
                                                fontSize='14px'
                                                className='mt-3 ml-3'
                                            >
                                                Profile
                                            </Heading>
                                            {/* {seeMoreProfile == true ? ( */}
                                                <>
                                                    <InputTextContainer>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name="name"
                                                                    placeholder='Full Name'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.name}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='mobile'
                                                                    placeholder='Phone No.'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.mobile}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name="dateOfBirth"
                                                                    placeholder='Date-of-birth (YYYY-MM-DD) '
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.dateOfBirth}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <Dropdown
                                                                    name="gender"
                                                                    placeholder='Gender'
                                                                    showCharacters={false}
                                                                    options={gender}
                                                                    onChange={handleChnageGender}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    selectedFontWeight="100"
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                {/* <Icon className='iconContainer'>create</Icon> */}
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name="married"
                                                                    placeholder='Marital Status'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.married}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            {/* <div className='col-4'>
                                                                <InputText
                                                                    name="noOfKids"
                                                                    placeholder='No. of Kids'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.noOfKids}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div> */}
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-12'>
                                                                <InputText
                                                                    name="address"
                                                                    placeholder='Address'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.address}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-4'>
                                                                <Dropdown
                                                                    name="city"
                                                                    placeholder='City'
                                                                    showCharacters={false}
                                                                    options={city}
                                                                    onChange={handleChnagecity}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    selectedFontWeight="100"
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                {/* <Icon className='iconContainer'>create</Icon> */}
                                                            </div>
                                                            {/* <div className='col-4'>
                                                                <Text><input type='radio' className='ml-3 mt-3' name='house_type' style={{ accentColor: "#728F01" }} />    Flat</Text>
                                                            </div>
                                                            <div className='col-4'>
                                                                <Text><input type='radio' className='ml-3 mt-3' name='house_type' style={{ accentColor: "#728F01" }} defaultChecked />    Bunglow</Text>
                                                            </div> */}
                                                        </div>
                                                        {/* <div className='row' style={field}>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name="noOfRooms"
                                                                    placeholder='No. of rooms'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.noOfRooms}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name="occupation"
                                                                    placeholder='Occupation'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.occupation}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name="annualIncome"
                                                                    placeholder='Annual Income'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.annualIncome}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-12'>
                                                                <Dropdown
                                                                    name="qulification"
                                                                    placeholder='Qualifications'
                                                                    showCharacters={false}
                                                                    options={qualification}
                                                                    onChange={handleChnagequlification}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    selectedFontWeight="60"
                                                                    selectedFontSize= 'small !important'
                                                                    selectedColor= '#757c7cd9 !important'
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </InputTextContainer>
                                                </>
                                            {/* ) : null
                                            } */}
                                        </div>
                                        {/* END */}
                                    </div>
                                    {/* See more Button */}
                                    {/* <SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreProfile(!seeMoreProfile)}>{seeMoreProfile ? SeeLess() : SeeMore()}</SeeButton> */}
                                    {/* END */}

                                    {/* <Divider
                                        margin='0'
                                        height='2px'
                                        width='100%'
                                        color='#38454F'
                                        className='mt-2'
                                    /> */}

                                        <div className='row mt-3'>
                                            {/* Products Used at home */}
                                            <div>
                                                <Heading
                                                    fontSize='14px'
                                                    className='mt-3 ml-3'
                                                >
                                                    Products Used at home -
                                                </Heading>
                                                {/* {seeMorePrimary == true ? ( */}
                                                <>
                                                    <InputTextContainer>
                                                        <div className='row' style={{width: "133%"}}>
                                                            {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='electricity_connection' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Electricity connection</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='LPG_stove' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    LPG Stove</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='refrigerator' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Refrigerator</Text>
                                                                </div>
                                                            {/* </div> */}
                                                        </div>
                                                        <div className='row' style={{width: "133%"}}>
                                                            {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='colour_television' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Colour Television</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='ceiling_fan' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Ceiling Fan</Text>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='washing_machine' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Washing Machine</Text>
                                                                </div>
                                                            {/* </div> */}
                                                        </div>
                                                        <div className='row' style={{width: "133%"}}>
                                                            {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='computer/laptop' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Computer/Laptop</Text>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='four_wheeler' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Four Wheeler</Text>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='air_conditioner' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Air Conditioner</Text>
                                                                </div>
                                                            {/* </div> */}
                                                        </div>
                                                        <div className='row' style={{width: "133%"}}>
                                                            {/* <div className='col-12'> */}
                                                                <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='two_wheeler' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Two-Wheeler</Text>
                                                                </div>
                                                                {/* <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='2' onChange={handleChange} style={{ accentColor: "#728F01" }}  />    Bunglow</Text>
                                                                </div> */}
                                                                {/* <div className='col-4'>
                                                                    <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='3' onChange={handleChange} style={{ accentColor: "#728F01" }}  />    Text</Text>
                                                                </div> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </InputTextContainer>
                                                </>

                                                {/* ) : null
                                                } */}
                                            </div>
                                            {/* END */}
                                        </div>

                                    <div className='row mt-3'>
                                        {/* Primary Earning Member Details */}
                                        <div>
                                            <Heading
                                                fontSize='14px'
                                                className='mt-3 ml-3'
                                            >
                                                Primary Earning Member Details
                                            </Heading>
                                            {/* {seeMorePrimary == true ? ( */}
                                                <>
                                                    <InputTextContainer>
                                                        {/* <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name="pOccupation"
                                                                    placeholder='Occupation'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.pOccupation}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name="pAnnualIncome"
                                                                    placeholder='Annual Income'
                                                                    showCharacters={false}
                                                                    onChange={handleChnage}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    value={GetUserData?.pAnnualIncome}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div> */}
                                                        <div className='row' style={field}>
                                                            <div className='col-12'>
                                                                <Dropdown
                                                                    name="pQulification"
                                                                    placeholder='Qualifications'
                                                                    showCharacters={false}
                                                                    options={qualification_list}
                                                                    onChange={handleChnagepQulification}
                                                                    setIsFlag={setIsFlag}
                                                                    IsFlag={IsFlag}
                                                                    selectedFontWeight="60"
                                                                    selectedFontSize= 'small !important'
                                                                    selectedColor= '#757c7cd9 !important'                                                                    />
                                                            </div>
                                                        </div>
                                                    </InputTextContainer>
                                                </>

                                            {/* ) : null
                                            } */}
                                        </div>
                                        {/* END */}
                                    </div>
                                    {/* See more Button */}
                                    {/* <SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMorePrimary(!seeMorePrimary)}>{seeMorePrimary ? SeeLess() : SeeMore()}</SeeButton> */}
                                    {/* END */}
                                </div>
                                )}


                            </div>
                            <div className="modal-footer border-0 mt-2" style={{ justifyContent: "flex-start" }}>
                                <CustomButton
                                    className='ml-2 mt-1'
                                    size='small'
                                    // variant='outlined'
                                    width='60px'
                                    onClick={addMember}
                                // style={{ color: "#798E8F", background: "none" }}
                                >
                                    Save
                                </CustomButton>
                                <CustomButton
                                    className='ml-2 mt-1'
                                    size='small'
                                    variant='outlined'
                                    width='60px'
                                    data-dismiss="modal"
                                    onClick={cancelChanges}
                                    // style={{ color: "#798E8F", background: "none" }}
                                >
                                    Cancel
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}
