import styled from '@emotion/styled';
import Icon from './Icon';

const StyledIcon = styled(Icon)`
    padding: 0.45rem;
    cursor: pointer;
    font-size: 1.125rem;
    margin-left: 5px;

    :hover {
        background: var(--lightestGrey);
        border-radius: 50%;
    }
`

export default function ActionIcon({ children, onClick, className=""}) {
    return <StyledIcon onClick={onClick} className={className}>{children}</StyledIcon>
}