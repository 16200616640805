import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as ReassignProjectGroupsMethod from '../../utils/api/invite-recruit/ReassignProjectGroupsData';

function* ReassignProjectGroupsData(payload) {
  const promise = ReassignProjectGroupsMethod.ReassignProjectGroupsData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ReassignProjectGroupsAction.REASSIGN_PROJECT_GROUP_RESPONSE, data: res.Status });
}

export  { ReassignProjectGroupsData }