import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as calendarDataMethods from '../../utils/api/dashboard/calendarData';

function* calendarData(payload) {
  const promise = calendarDataMethods.calendarData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.calenderDataAction.CALENDER_DATA_RECEIVED, data: res.Response });
}

export { calendarData };
