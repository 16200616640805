import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as UpdateLayoutMethods from '../../utils/api/field/UpdateLayoutData';

function* UpdateLayoutData(payload) {
  const promise = UpdateLayoutMethods.UpdateLayoutData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.StartInterviewAction.UPDATELAYOUT_RECIEVED, data: res.Response });
}

export { UpdateLayoutData };
