import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
	Heading,
	Tabs,
	Tab,
	TabsContainer,
	TabsContentContainer,
	Button,
	Icon,
	Card,
	Divider,
	Text,
} from '../../common';
import { Link, useParams } from 'react-router-dom';
import {
	NavTab,
	NavTabs,
	NavTabsContainer,
} from '../../common/NewTab';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { extendWith } from 'lodash';

const Stage = styled(Text)`
	font-size: 0.875rem;
	font-weight: 500;
	align-items: center;
	padding-right: 0;
	justify-content: center;
	display: inline-flex;

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 32px;
		width: 32px;
		border-radius: 50%;
		background: #c2c2c2;
		color: var(--white);
		margin-right: 8px;
	}

	&.active {
		font-weight: 700;
		color: var(--navActive);
	}

	&.active span {
		background: var(--navActive);
	}
`;

const Container = styled.div`
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-item: center;
	border-bottom: 1px solid var(--secondaryColor);
	margin-bottom: 1rem;
`;

// const history = createBrowserHistory({ forceRefresh: true });

// const projectId = useSelector(state => state.ProjectReducer.project_ID);

// const handleOpenTab = (e) => {
//     history.push({ pathname: `/project/${projectId}/schedule` });
// 	// alert("hh")
// }

export default function ProjectWrapper({

	children,
	stage = 1,
	step = 1,
	projectTitle = 'Untitled Project',
	tabContainerClass,
	projectStatus,
	projectId
}) {
	const history = createBrowserHistory({ forceRefresh: true });

	//const projectId = useSelector(state => state.ProjectReducer.project_ID);
	// const projectId = projectId;

	const handleOpenTab = (e) => {
		if (projectStatus != undefined && (projectStatus[projectStatus?.length - 1]?.Step == e || projectStatus[projectStatus?.length - 1]?.Step > e || e == 5)) {
			if (e <= step && e == 1) {
				history.push({ pathname: `/project/${projectId}/publish` });
			}
			else if ((e <= step && e == 2) || (e >= step && e == 2)) {
				history.push({ pathname: `/project/${projectId}/invite-recruit` });
			}
			else if ((e <= step && e == 3) || (e >= step && e == 3)) {
				history.push({ pathname: `/project/${projectId}/design` });
			}
			else if (e <= step && e == 4 || (e >= step && e == 4)) {
				history.push({ pathname: `/project/${projectId}/field` });
			}
			else if (e > step && e == 5 || (e >= step && e == 5)) {
				history.push({ pathname: `/project/${projectId}/analysis` });
			}
		}

	}

	return (
		<>
			<Container>
				<Heading
					fontSize={'1.5rem'}
					color={'var(--secondaryColor)'}
					type='2'
					fontWeight='700'
					className='d-flex align-items-center'
				>
					{projectTitle}
				</Heading>
				<NavTabsContainer className={tabContainerClass}>
					<NavTabs>
						<NavTab active={step === 1} onClick={(e) => handleOpenTab(e = 1)} big>
							Setup
						</NavTab>
						<NavTab active={step === 2} onClick={(e) => handleOpenTab(e = 2)} big>
							Recruit
						</NavTab>
						<NavTab active={step === 3} onClick={(e) => handleOpenTab(e = 3)} big>
							Design
						</NavTab>
						<NavTab active={step === 4} onClick={(e) => handleOpenTab(e = 4)} big>
							Field
						</NavTab>
						<NavTab active={step === 5} onClick={(e) => handleOpenTab(e = 5)} big>
							Analysis
						</NavTab>
					</NavTabs>
				</NavTabsContainer>
			</Container>
			{step === 1 && (
				<TabsContentContainer className='pt-2' height='auto'>
					{step === 1 && (
						<Card>
							<div className='d-flex justify-content-between align-items-center'>
								<Stage
									className={
										stage >= 1 ? 'active' : ''
									}
								>
									<span>1</span>Template & Team
								</Stage>
								<Divider margin='0' />
								<Stage
									className={
										stage >= 2 ? 'active' : ''
									}
								>
									<span>2</span>Criteria & Budget
								</Stage>
								<Divider />
								<Stage
									className={
										stage >= 3 ? 'active' : ''
									}
								>
									<span>3</span>Schedule Dates
								</Stage>
								<Divider />
								<Stage
									className={
										stage >= 4 ? 'active' : ''
									}
								>
									<span>4</span>Publish
								</Stage>
							</div>
						</Card>
					)}
				</TabsContentContainer>
			)}
			{children}
		</>
	);
}
