import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as checkSummaryMethods from '../../utils/api/analysis/checkSummary';

function* checkSummary(payload) {
  const promise = checkSummaryMethods.checkSummary(payload.payload);
  const { res } = yield promise;

  yield putResolve({ type: actions.checkSummaryActions.CHECK_SUMMARY_RECEIVED, data: res.Response });
}

export  { checkSummary }