import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetSummaryDataMethods from "../../utils/api/field/GetSummaryData";

function* GetSummaryData(payload) {
  const promise = GetSummaryDataMethods.GetSummaryData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetSummaryDataAction.SUMMARYDATA_RECEIVED, data: res.Response });
}

function* EditSummaryData(payload) {
  const promise = GetSummaryDataMethods.EditSummaryData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetSummaryDataAction.SUMMARYDATA_RECEIVED, data: res.Response });
}

export  { GetSummaryData, EditSummaryData }
// export  { GetSummaryData }