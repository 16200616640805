import styled from '@emotion/styled';
import React from 'react';
import { Button, Divider, Heading, Icon } from '.';

const CustomButton = styled(Button)`
	padding: 2px 1rem;
`;
const CustomButtonLink = styled(Heading)`
	padding: 0.375rem 1.125rem;
	padding-bottom:0.325rem !important;
	margin-bottom: 0px !important;
	color: var(--deepBlue);
	line-height: 10px;
	margin-left: 1rem;
	border-bottom: 1px solid var(--deepBlue);
	cursor: pointer;
	&:hover {
		color: var(--deepBlue);
	}
`;
function ReactToolTipBox({
	width = '150px',
	number,
	information,
    description,
	handleNext,
	handleClose,
	handleDisable,
	handleSkip,
}) {
	return (
		<div className='py-1' style={{ width: width}}>
			<div style={{marginTop: '15px',position: 'absolute', left: '72px', top: '-26px',padding: '10px',border: '2px solid transparent',borderTopColor:'whitesmoke',borderLeftColor: 'whitesmoke',backgroundColor: 'white', height: '0',width:' 0', boxShadow: 'inset -3px -20px 20px 3px',transform:' rotate(45deg)'}}></div>
			<div className='d-flex align-items-center'>
				
				<div>
					<Heading
						fontSize='11px'
						// fontWeight='700'
						breakHeading={false}
						className='mb-0 pb-0'
						style={{
							whiteSpace: 'pre-line',
							paddingLeft: '8px',
							position: 'relative',
							zIndex: '3'
							// lineHeight: '19px',
                            // color:'green',
                            // marginBottom:'1px'
						}}
					>
						{information}
					</Heading>
				</div>
			</div>
			{/* <div className='d-flex align-items-center'>
				
				<div>
					<Heading
						fontSize='12px'
						fontWeight='700'
						breakHeading={false}
						className='mb-0 pb-0'
						style={{
							whiteSpace: 'pre-line',
							lineHeight: '19px',
              marginTop:'10px'
						}}
					>
						{description}
					</Heading>
				</div>
			</div> */}
		</div>
	);
}

export default ReactToolTipBox;
