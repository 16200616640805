import constants from '../../constants';


const {
   REQUEST_CREATE_PROJECT, REQUEST_EDIT_PROJECT, CREATE_PROJECT_RECEIVED, EDIT_PROJECT_RECEIVED, REQUEST_DELETE_REFERRAL, DELETE_REFERRAL_RECEIVED, CLEAR_EDIT_PROJECT,
} = constants.ProjectConstants;
export default {
  REQUEST_CREATE_PROJECT,
  CREATE_PROJECT_RECEIVED,
  REQUEST_EDIT_PROJECT,
  EDIT_PROJECT_RECEIVED,
  REQUEST_DELETE_REFERRAL,
  DELETE_REFERRAL_RECEIVED,
  CLEAR_EDIT_PROJECT,

  getQuestions: payload => ({
    type: REQUEST_CREATE_PROJECT,
    payload
  }),
  questionsReceived: () => ({
    type: CREATE_PROJECT_RECEIVED
  }),
  getEditQuestions: payload => ({
    type:REQUEST_EDIT_PROJECT,
    payload
  }),
  editQuestionsReceived: () => ({
    type: EDIT_PROJECT_RECEIVED
  }),

  getDeleteReferral: payload => ({
    type:REQUEST_DELETE_REFERRAL,
    payload
  }),

  deleteReferralReceived: () => ({
    type: DELETE_REFERRAL_RECEIVED
  }),
};

// export const cancelchange = () =>({
//   type: 'CANCEL_UPDATE',
// });