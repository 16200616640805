import React from 'react';
import { useState } from 'react';
import { Button, Text, Avatar, Heading } from '..';
import styled from '@emotion/styled';
import {
    InputText,
    TextArea,
    DropFile,
    Dropdown,
    ButtonRadio,
    TaskTypeOption,
    TaskMenu,
} from '../../components/form';
import { Divider } from '..';
import Icon from '../Icon'
import cross from '../../assets/images/cross.svg';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import profile_pic from '../../assets/images/profile_pic.png';
import confirm from '../../assets/images/confirm.svg';

const field = {
    marginLeft: "0px"
}

const InputTextContainer = styled.div`
	.iconContainer {
		position: absolute;
		top: 30%;
		right: 15px;
	}
	span {
		font-size: 18px;
		color: var(--greyBlue);
	}
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;
const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	float: right;
    margin-right: 5px;
`;

const Pill = styled.span`
	font-size: 9px;
	font-family: "Readex Pro", sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
`;

const gender_list = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' },
];

const city_list = [
    { value: 1, label: 'Ahmedabad' },
    { value: 2, label: 'Bangalore' },
    { value: 3, label: 'Mumbai' },
    { value: 4, label: 'Kolkata' },
    { value: 5, label: 'Delhi' },
];

const qualification_list = [
    { value: 1, label: 'Illiterate' },
    { value: 2, label: 'Literate but no formal schooling' },
    { value: 3, label: 'School (5-9 yrs)' },
    { value: 4, label: 'SSC/HSC' },
    { value: 5, label: 'Some College(Including a diploma but not graduate)' },
    { value: 6, label: 'Graduate/Post-Graduate: General' },
    { value: 7, label: 'Graduate/Post-Graduate: Professional' },
];

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function UserDeletedModal() {

    const [seeMoreProfile, setSeeMoreProfile] = useState(true);
    const [seeMorePrimary, setSeeMorePrimary] = useState(false);
    const [userType, setUserType] = useState('Respondant');

    return (
        <div>
            <>

                {/* <Button
                    className='ml-4'
                    size='small'
                    variant='outlined'
                    width='50px'
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                >
                    edit
                </Button> */}

                {/* New Modal for Confirm Delete User  */}
                <div className="modal fade" id="UserDeletedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0">
                            </div>
                            <div className='container'>
                                <div style={{ marginLeft: "42%", paddingTop: "5%" }}>
                                    <img
                                        src={confirm}
                                        className=''
                                        width='50'
                                        height='50'
                                    />
                                </div>
                                <Heading fontSize='18px' className='mt-4 text-center' >User Deleted</Heading>
                                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}

                                <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                    <Text  type='7' color="#38454F" className="mb-0 text-center">User profile successfully deleted</Text>
                                    <div className='row' style={{ paddingTop: '20px' }}>
                                        <div className='' style={{  marginLeft: "40%" }}>
                                            <CustomButton
                                                className='ml-2 mt-1'
                                                size='small'
                                                //  variant='outlined'
                                                width='60px'
                                                // width='5px'
                                                // height='4px'
                                                fontSize='10px'
                                                
                                                data-dismiss="modal"
                                            >
                                                Okay
                                            </CustomButton>
                                        </div>
                                        {/* <div id='delete' className='col col-4 col-offset-6'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            // variant='outlined'
                                            width='60px'
                                        // style={{ color: "#798E8F", background: "none" }}
                                        >
                                            Delete
                                        </CustomButton>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* END */}
            </>
        </div >
    )
}
