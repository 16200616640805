import React from 'react';
import DownIndicator from '../../common/DownIndicator';
import { Dropdown } from '../../components/form';
const DownIndicatorCustom = ({ ...props }) => {
	return (
		<DownIndicator
			color={'var(--mediumGrey)'}
			iconStyle={{ fontSize: 18 }}
			{...props}
		/>
	);
};

const selectedBorderBottom = '1px solid var(--darkGreen)';

const controlStyle = {
	minHeight: 'auto',
	width: '150px',
	'&:hover': {
		borderColor: 'var(--deepBlue)',
	},
};

const optionStyle = {
	fontSize: '12px',
	fontWeight: '300',
	color: 'var(--mediumGrey)',
};
function DesignDropdown({value, options, isDisabled, onChange}) {

	return (
		<Dropdown
			selectedFontSize='12px'
			options={options}
			defaultValue={value}
			selectedColor='var(--mediumGrey)'
			selectedFontWeight='400'
			controlStyle={controlStyle}
			valueContainerStyle={{
				padding: 0,
			}}
			DropdownIndicator={(props) => <DownIndicatorCustom {...props} />}
			selectedBorderBottom={selectedBorderBottom}
			optionStyle={optionStyle}
			onChange={onChange}
			isDisabled={isDisabled}
		/>
	);
}

export default DesignDropdown;
