import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetVideoTagMethods from '../../utils/api/field/GetVideoTagData';

function* GetVideoTagData(payload) {
  const promise = GetVideoTagMethods.GetVideoTagData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetVideoTagAction.GETVIDEOTAG_RECEIVED, data: res.Response });
}

export  { GetVideoTagData }