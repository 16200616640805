import styled from '@emotion/styled';

const ButtonRadio = styled.label`

 
    

    button{
        
       
        background: var(--white);
        border-radius: ${props => props.borderRadius ? props.borderRadius : '0.32rem'};
        padding: ${props => props.padding ? props.padding : '.438rem 1.2rem'};
        border: 1px solid var(--navActive);
        color: var(--navActive);
        margin: ${props => props.margin ? props.margin : '0'};
        transition: all 0.25s ease-in-out;
        font-weight: ${props => props.fontWeight ? props.fontWeight : '700'};
        font-size: ${props => props.fontSize ? props.fontSize : '1rem'};
    }
   

   button:hover {
        background: var(--navActive);
        color: var(--white);
        transition: all 0.25s ease;
    }
    button:focus {
        background: var(--navActive);
        color: var(--white);
        transition: all 0.25s ease;
    }
   
    
    input:checked +button{
        background-color: var(--navActive);
        color: var(--white);
        transition: all 0.25s ease-in-out;
        
    }
    
    
    input{
        
        display: none;
    
        
    }
    

`

export default function Radio({ text="", name="", value="", checked=null, ref="",onClick, borderRadius, padding, fontSize, fontWeight, activeColor, activeBackgroundColor, className=""}){

    return (
        <ButtonRadio  className={className} borderRadius={borderRadius} padding={padding}  fontSize={fontSize} fontWeight={fontWeight} activeColor={activeColor} activeBackgroundColor={activeBackgroundColor} onClick={onClick}><input type="radio" name={name} value={value}  checked={checked} /> <button type="button">{text}</button></ButtonRadio>
    )

}