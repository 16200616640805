import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetCaptureGuideMethods from '../../utils/api/field/GetCaptureGuideData';
import {STORE_RTM_TRANSCRIPT_LOGS} from '../../constants/projects/ProjectConstants'

// function* GetCaptureGuideData(payload) {
//   const promise = GetCaptureGuideMethods.GetCaptureGuideData(payload.payload);
//   const { res } = yield promise;
//   yield putResolve({ type: actions.GetCaptureGuideAction.CAPTURE_GUIDE_RECEIVED, data: res.Response });
// }

function* GetCaptureGuideData(payload) {

  try {
    const promise = GetCaptureGuideMethods.GetCaptureGuideData(payload.payload);
    const { res } = yield promise;
    if (res.Response && res.Response?.captureGuide) {
      yield putResolve({ type: actions.GetCaptureGuideAction.CAPTURE_GUIDE_RECEIVED, data: res.Response });
    } else {
      console.warn("Capture guide data is missing or not satisfied.");
      // Optionally, you can dispatch an action to handle this scenario
      // yield put({ type: actions.CAPTURE_GUIDE_NOT_SATISFIED });
      const currentTimestamp = new Date().getTime();
      const responseObj = {
        statement: {
          timeStamp: currentTimestamp,
          Platform: 'web',
          role: 'researcher',
          // channelId: this.props.channel,
          // name: this.state.userName,
          event: 'Capture Guide',
          // userId: this.state.userid,
          // RTMUserId: this.USER_ID,
          errorCode: 501,
          reson: 'Capture guide data is missing or not satisfied from else condition.'
        }
      };
      yield putResolve({
        type: STORE_RTM_TRANSCRIPT_LOGS,
        payload: responseObj
      });
    }
  } catch (error) {
    // Handle the rejected promise here
    console.error("Error getting capture guide data:", error);
    // You can dispatch an action to handle the error in your Redux state if needed
    // yield put({ type: actions.ERROR_ACTION_TYPE, payload: error });
    const currentTimestamp = new Date().getTime();
      const responseObj = {
        statement: {
          timeStamp: currentTimestamp,
          Platform: 'web',
          role: 'researcher',
          // channelId: this.props.channel,
          // name: this.state.userName,
          event: 'Capture Guide',
          // userId: this.state.userid,
          // RTMUserId: this.USER_ID,
          errorCode: 502,
          reson: 'Capture guide data is missing or not satisfied from catch block condition'  + error
        }
      };
      // console.log(responseObj);
      yield putResolve({
        type: STORE_RTM_TRANSCRIPT_LOGS,
        payload: responseObj
      });
  }
}


export { GetCaptureGuideData };
