import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddVideoNoteMethods from '../../utils/api/field/AddVideoNoteData';

function* AddVideoNoteData(payload) {
  const promise = AddVideoNoteMethods.AddVideoNoteData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddVideoNoteAction.ADDVIDEONOTE_RECEIVED, data: res.Response });
}

export  { AddVideoNoteData }