import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Icon } from '../../common';

const data = [{}];
const Th = styled.th`
	background: var(--greyBlue);
	font-weight: 600;
	font-size: 12px;

	text-align: center;

	/* Colour/White */
	color: #ffffff;
	border-left: 2px solid #fff;
	border-top: none !important;
	border-bottom: none !important;
	padding: 8px !important;
	&:first-of-type {
		border-left: 0px solid #fff;
	}
`;

const Td = styled.td`
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	/* identical to box height */

	/* Colour/DeepBlue */

	color: var(--deepBlue);
	padding: 8px !important;
	&.titleValue {
		font-weight: 600;
		font-size: 14px;
		text-align: left;
		display: flex;
		padding-left: 1.5rem !important;
		cursor: pointer;
	}
`;

const Tr = styled.tr`
	border-bottom: ${(props) =>
		!props.border ? 'none' : '1px solid var(--greyBlue30)'};

	transition: height 0.6s ease;

	&.main {
		background: var(--midWhite);
	}

	&.low {
		:last-child {
			border-bottom: none;
		}
	}
	&.footer {
		background: var(--darkWhite);
		border-bottom: none;
		td {
			font-weight: bold !important;
			border-left: 2px solid #fff;
			:first-of-type {
				border-left: 0px solid #fff;
			}
		}
	}
`;
const Table = styled.table`
	width: 100%;
	.simple {
		td {
			padding: 6px 5px !important;
			font-size: 12px;
			font-weight: 400;
			color: var(--deepBlue);
		}
		tr:nth-child(even) {
			background: var(--greyBlueLight);
		}
		tr:nth-child(odd) {
			background: #fff;
		}
		td:first-of-type {
			text-align: left;
			padding-left: 2rem !important;
			font-size: 10px;
			font-weight: 600;
			color: var(--deepBlue);
		}
	}
	.simple_footer {
		tr {
			background: #e1e5e5;
		}
		td {
			padding: 6px 5px !important;
			font-size: 12px;
			font-weight: 700;
			color: var(--deepBlue);
		}
		td:first-of-type {
			text-align: left;
			padding-left: 1rem !important;
			font-size: 10px;
			font-weight: 700;
			color: var(--deepBlue);
			text-transform: uppercase;
		}
	}
`;

const TableAccordion = ({
	title,
	titleList,
	dataList,
	disable = false,
	className = '',
	dataListClassName = '',
	sum = []
}) => {
	const [open, setOpen] = useState(true);
	return (
		<>
			<Tr
				className={`main ${className}`}
				border={true}
				onClick={() => setOpen((prev) => !prev)}
			>
				<Td className='titleValue'>
					<span>{title}</span>{' '}
					{!disable && (
						<Icon color='var(--deepBlue)'>
							{open ? 'arrow_drop_up' : 'arrow_drop_down'}
						</Icon>
					)}
				</Td>
				{titleList.map((item, index) => (
					<Td>{sum.length == 0 ? item : ((item / sum[index]) * 100).toFixed(1)}</Td>
				))}
			</Tr>

			{!disable && open && (
				<tbody>
					{dataList.map((itemList, iIndex) => {
						return (
							<Tr
								className={dataListClassName}
								border={true}
							>
								{itemList.map((item, index) => (
									<Td
										className={
											index == 0 &&
											'titleValue'
										}
									>
										{sum.length == 0 ? (item) : (index == 0 ? item : ((parseInt(item) / sum[index-1]) * 100).toFixed(1))}
									</Td>
								))}
							</Tr>
						);
					})}
				</tbody>
			)}
		</>
	);
};
function AnalysisLikerTable({
	High,
	HighList,
	Low,
	LowList,
	Neutral,
	MeanScore,
	accordion = true,
	data,
	footerData,
	Header,
	Sum = []
}) {
	return (
		<Table>
			<thead>
				<Tr border={false}>
					<Th width='20%' />
					{Header != undefined &&
					<>
					{Header.map((d,i)=>{
						return (
							<Th width='20%'>{d}</Th>
						)
					})
					}
					</>
					}
					
				</Tr>
			</thead>
			{accordion ? (
				<>
					<TableAccordion
						title='High'
						titleList={High}
						dataList={HighList}
						sum={Sum}
					/>
					<TableAccordion
						title='Neutral'
						titleList={Neutral}
						sum={Sum}
						disable
					/>

					<TableAccordion
						dataListClassName='low'
						title='Low'
						titleList={Low}
						dataList={LowList}
						sum={Sum}
					/>
					<TableAccordion
						className='footer'
						title='MEAN SCORE'
						titleList={MeanScore}
						disable
					/>
				</>
			) : (
				<>
					<tbody className='simple'>
						{data.map((row) => (
							<Tr className={``} border={true}>
								{row.map((item) => (
									<Td>{item}</Td>
								))}
							</Tr>
						))}
					</tbody>
					<tbody className='simple_footer'>
						<Tr className={``} border={true}>
							{footerData.map((item) => (
								<Td>{item}</Td>
							))}
						</Tr>
					</tbody>
				</>
			)}
		</Table>
	);
}

export default AnalysisLikerTable;
