import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as ChangePasswordWebMethods from '../../utils/api/Security/ChangePasswordWebData';

function* ChangePasswordWebData(payload) {
  const promise = ChangePasswordWebMethods.ChangePasswordWebData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ChangePasswordWebAction.CHANGEPASSWORDWEB_RECEIVED, data: res.Response });
}

export { ChangePasswordWebData };
