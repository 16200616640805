import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Avatar, Button, Heading, Icon } from '../../common';
import avatar from '../../images/researcher.png';
import user from '../../images/todo.png';
import { ScrollContainer } from './common';
import { Item, Thumbnail, CustomButton } from './common';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const addQuote = (text) => {
	if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(text) == true) {
		return text.indexOf(' ') >= 0 ? "'" + text + "'" : text;
	} else {
		return avatar;
	}

}

function TodoList(notificationContent) {
	const [open, setOpen] = useState(true);
	const userId = useSelector(state => state.LoginReducer.userId);
	const [isFocused, setFocus] = useState(false);
	// let setFocus = false;

	return (
		<div>
			<div
				className='d-flex justify-content-between'
				style={{ marginRight: '1rem', paddingRight: '0.7rem' }}
			>
				<Heading
					className='mb-0 pb-0'
					fontSize='18px'
					fontWeight='700'
					color='var(--deepBlue)'
				>
					Your To-Do List
				</Heading>
				<div
					style={{ cursor: 'pointer' }}
					className='d-flex align-items-center'
					onClick={() => setOpen((prev) => !prev)}
				>
					<Heading
						fontSize='14px'
						fontWeight='800'
						color='var(--deepBlue)'
						breakHeading={false}
						className='mb-0 pb-0'
					>
						{open ? 'Hide' : 'Open'}
					</Heading>
					<Icon color='#38454F' style={{ fontSize: '28px' }}>
						{' '}
						{open ? 'expand_less' : 'expand_more'}{' '}
					</Icon>
				</div>
			</div>
			{open && (
				<ScrollContainer>
					{notificationContent.notificationContent != undefined && notificationContent.notificationContent?.map((obj, oIndex) => {

						return <Item>
							<div className='flex align-items-center'>
								<Thumbnail src={obj?.presignedUrl != null ? obj?.presignedUrl : avatar}></Thumbnail>
								<p className='title'>
									{obj.notificationContent}

									
									{/*<span className='tag red'>
										{' '}
										Overdue
					</span> */}
								</p>
                              
								{//<p className='subtitle'>{obj.notificationContent.includes('has applied to project') ? '' : obj.steps_array != undefined ? obj.steps_array[obj.steps_array.length - 1].ModuleDescription : ""}</p>
								
								}

							</div>
							<div>
								{obj.buttonText == "Go to project" ?
									<Link
										to={`/project/${obj.project_ID}/${(obj.steps_array != undefined ? obj.steps_array[obj.steps_array.length - 1].ModuleDescription : "").toLowerCase()}`}
										onMouseEnter={() => setFocus(true)}
										onMouseLeave={() => setFocus(false)}
										className="hoverColor"
										style={{
											color: isFocused ? "white" : "#728F01"
										}}

									>
										<CustomButton>
											{obj.buttonText}
										</CustomButton>
									</Link>
									:
									obj.buttonText == "Go To Field" || obj.buttonText == "Start" ?
										<Link
											to={`/project/${obj.project_ID}/field`}
											className="hoverColor"
											style={{
												color: setFocus ? "white" : "#728F01"
											}}
											onMouseEnter={() => setFocus(true)}
											onMouseLeave={() => setFocus(false)}
										>
											<CustomButton>
												{obj.buttonText}
											</CustomButton>
										</Link>
										:
										obj.buttonText == "Review Budget" || obj.buttonText == "Revise Budget" ?
											<Link
												to={`/project/${obj.project_ID}/criteria-and-budget`}
												className="hoverColor"
												style={{
													color: setFocus ? "white" : "#728F01"
												}}
												onMouseEnter={() => setFocus(true)}
												onMouseLeave={() => setFocus(false)}
											>
												<CustomButton>
													{obj.buttonText}
												</CustomButton>
											</Link>
											:
											obj.buttonText == "View Project" ?
												<Link
													to={`/project/${obj.project_ID}/edit`}
													className="hoverColor"
													style={{
														color: setFocus ? "white" : "#728F01"
													}}
													onMouseEnter={() => setFocus(true)}
													onMouseLeave={() => setFocus(false)}
												>
													<CustomButton>
														{obj.buttonText}
													</CustomButton>
												</Link>
												: obj.buttonText == "Go to Recruit" ?
													<Link
														to={`/project/${obj.project_ID}/invite-recruit`}
														onMouseEnter={() => setFocus(true)}
														onMouseLeave={() => setFocus(false)}
														className="hoverColor"
														style={{
															color: isFocused ? "white" : "#728F01"
														}}

													>
														<CustomButton>
															{obj.buttonText}
														</CustomButton>
													</Link>
													:
													<Link
														to="/"
														className="hoverColor"
														style={{
															color: setFocus ? "white" : "#728F01"
														}}
														onMouseEnter={() => setFocus(true)}
														onMouseLeave={() => setFocus(false)}
													>
														<CustomButton>
															{obj.buttonText}
														</CustomButton>
													</Link>
								}
							</div>
						</Item>
					})}
					{/* <Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Schedule Request{' | '}
								<span className='tag red'>
									{' '}
									Overdue
								</span>
							</p>
							<p className='subtitle'>Field</p>
						</div>
						<div>
							<CustomButton>Review Request</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Review Project Screeners{' | '}
								<span className='tag orange'>
									In 2 hrs
								</span>
							</p>
							<p className='subtitle'>Recruit</p>
						</div>
						<div>
							<CustomButton>Review Screeners</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Review Budget Request{' | '}
								<span className='tag orange'>
									In 4 days
								</span>
							</p>
							<p className='subtitle'>Setup</p>
						</div>
						<div>
							<CustomButton>Review Budget </CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Review Task 1 - R1 + 3 more{' | '}
								<span className='tag orange'>
									{' '}
									In 6 days
								</span>
							</p>
							<p className='subtitle'>Field</p>
						</div>
						<div>
							<CustomButton>Review Task</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Reminder for Budget Approval{' | '}
								<span className='tag orange'>
									{' '}
									In 7 days
								</span>
							</p>
							<p className='subtitle'>Setup</p>
						</div>
						<div>
							<CustomButton>Send Reminder</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Schedule Request{' | '}
								<span className='tag red'>
									{' '}
									Overdue
								</span>
							</p>
							<p className='subtitle'>Field</p>
						</div>
						<div>
							<CustomButton>Review Request</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Review Project Screeners{' | '}
								<span className='tag orange'>
									In 2 hrs
								</span>
							</p>
							<p className='subtitle'>Recruit</p>
						</div>
						<div>
							<CustomButton>Review Screeners</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Review Budget Request{' | '}
								<span className='tag orange'>
									In 4 days
								</span>
							</p>
							<p className='subtitle'>Setup</p>
						</div>
						<div>
							<CustomButton>Review Budget </CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Review Task 1 - R1 + 3 more{' | '}
								<span className='tag orange'>
									{' '}
									In 6 days
								</span>
							</p>
							<p className='subtitle'>Field</p>
						</div>
						<div>
							<CustomButton>Review Task</CustomButton>
						</div>
					</Item>
					<Item>
						<div className='flex align-items-center'>
							<Thumbnail src={user}></Thumbnail>
							<p className='title'>
								Reminder for Budget Approval{' | '}
								<span className='tag orange'>
									{' '}
									In 7 days
								</span>
							</p>
							<p className='subtitle'>Setup</p>
						</div>
						<div>
							<CustomButton>Send Reminder</CustomButton>
						</div>
					</Item> */}
				</ScrollContainer>
			)}
		</div>
	);
}

export default TodoList;