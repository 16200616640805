import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as superAdminDashboardMethods from '../../utils/api/superAdmin/SuperAdminDashboardData';

function* superAdminDashboardData(payload) {
  const promise = superAdminDashboardMethods.superAdminDashboardData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.SuperAdminDashboardAction.SUPERADMINDASHBOARD_DATA_RECEIVED, data: res.Response });
}

export  { superAdminDashboardData }