import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Avatar, ActionButton, Heading, HelperText, ProjectCard, Button, NotificationCard, WeekCalenderCard, TabsContainer, Tabs, Tab, TabsContentContainer, NotificationInfo, Text, Time, Icon  } from "../../common";
import { Link } from 'react-router-dom';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import PlusWhite from '../../assets/images/plus-white.svg';
import PlusGreen from '../../assets/images/plus-green.svg';
import {  useDispatch ,useSelector} from 'react-redux';
import { REQUEST_WEBDASHBOARD_DATA } from '../../constants/dashboard/webDashboardConstants';
import { ScrollContainer } from '../Notificaions/common';
import {call,put,takeEvery,takeLatest} from 'redux-saga/effects'



const getCalenderData = async()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization","Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkuZGV2Lmh1bWFuaWZ5dGVjaG5vbG9naWVzLmNvbVwvYXBpXC93ZWJMb2dpbiIsImlhdCI6MTY4OTg0NjMxOCwibmJmIjoxNjg5ODQ2MzE4LCJqdGkiOiIwdG5TY1BQeXhhTzRNckVrIiwic3ViIjoxNjc1LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.qrggUGD6YP5fdKWfXBbgL6iLL6hYrWpxubvVkURhg0w");
    myHeaders.append("Content-Type","application/json");

    var raw = JSON.stringify({
        "user_ID":"1675",
        "selected_date":"2023-08-01"
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        // mode: 'no-cors'
    };
    return await fetch (`https://${process.env.REACT_APP_URL}/getCalendarData`,requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
}

const CardButton = styled(Button)`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    background: var(--white);
    color: var(--labelColor);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.65rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    border: 0;
    transition: background-color 250ms, color 250ms;

    img {
        margin-right: 8px;
    }

    .plus-white {
        display: none;
    }

    span {
        background: transparent;
        display: inline-block;
        margin-right: 5px;
        font-size: 2.2rem;
        color: var(--primaryColor);
        transition: color 250ms;
    }

    .active {
        background: var(--grey5);
        color: var(--ternaryColor);
        text-decoration: none;

        span {
            color: var(--ternaryColor);
        }
    }

    :hover,
    :focus {
        background: var(--primaryColor);
        color: var(--white);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
        transition: background-color 250ms, color 250ms;

        span {
            color: var(--white);
            transition: color 250ms;
        }

        .plus-white {
            display: inline-block;
        }

        .plus-green {
            display: none;
        }
    }
`

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`


const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function DashboardScreen() {

    const [seeMore, setSeeMore] = useState(false);
    const dispatch = useDispatch(); 
    const userId = useSelector(state=>state.LoginReducer.userId);
	useEffect(() => {
		dispatch({
			type: REQUEST_WEBDASHBOARD_DATA,
			payload: {
				"user_ID":userId,
			}
		  });
	  },[]);
    const projectStatus = useSelector(state=>state.webDashboardReducer.projectStatus);
    const notificationContent = useSelector(state=>state.webDashboardReducer.notificationContent);
    return (
        <>

            {/* Heading */}
            <div className="row">
                
                <div className="col-sm-8">
                    <Heading >Your Dashboard</Heading>
                    <Text type="3" color="var(--ternaryColor)" className="mb-0">View your live projects, calendar and recent notifications in one place</Text>
                </div>
                <div className="col-sm-4 ">
                    <div className="d-flex justify-content-end">
                        <Link to="/project/create">
                            <CardButton><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white"/>  Create a project</CardButton>
                        </Link>
                        
                    </div>
                    
                </div>
                
            </div>


            {/* Projects List */}
            <section className="projects-list mt-5">
                {projectStatus?.filter((project,index) =>  seeMore ? true : index <= 1).map((project,index) => <ProjectCard key={index} className="mb-4" project={project} />)}


                <SeeButton simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMore(!seeMore)}>{seeMore ? SeeLess() : SeeMore() }</SeeButton>
                
            </section>

            
            <section className="calendar-notification-section mt-5">

                <div className="row">
                    <div className="col-md-8">
                        <WeekCalenderCard onDateSelection={(date) => console.log(date)}>
                            
                            {/* <TabsContentContainer height="341px">
                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
                                    <Text className="notification-text" type="2"><Text fontWeight="800" span>Sonia S.</Text> has scheduled her interview - <Text fontWeight="800" span>11:45 - 12:45</Text> on <Text fontWeight="800" span>2.11.21</Text> has applied.</Text>
                                    <Button primary className="pt-1 pb-1" fontSize="0.75rem">Join</Button>
                                </NotificationInfo>
                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Sam J." className="mr-2" />
                                    <Text className="notification-text" type="2"><Text fontWeight="800" span>Sam J.</Text> has accepted the invitation</Text>
                                    <Text type="4" bold width="auto">2pm - 3pm</Text>
                                </NotificationInfo>

                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Sally H." className="mr-2" />
                                    <Text className="notification-text" type="2"><Text fontWeight="800" span>R6 - Sally H.</Text> has submitted Task - 1 Form - <Link to="/"><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">Review Form</Button></Link></Text>
                                    <Text type="4" bold width="auto">3:30pm - 4:30pm</Text>
                                </NotificationInfo>
                                
                            </TabsContentContainer> */}
                            
                        </WeekCalenderCard>
                    </div>
                    <div className="col-md-4">
                        <NotificationCard>
                            <TabsContainer>
                                <Tabs>
                                    <Tab active={true}>All</Tab>
                                    {/* <Tab>Todo</Tab>
                                    <Tab>Updates</Tab>
                                    <Tab>Reminders</Tab> */}
                                </Tabs>
                            </TabsContainer>
                            
                            <TabsContentContainer>
                                <ScrollContainer>
                                <Heading small={true}>Today</Heading>
                                
                                {notificationContent != undefined && notificationContent?.map((obj, oIndex) =>
                                    <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name={obj.name} className="mr-2" />
                                    <Text className="notification-text" type="5">{obj.notificationContent}- 
                                        {obj.buttonText == "Go to project" ? <Link to={`/project/${obj.project_ID}/${(obj.steps_array != undefined ? obj.steps_array[obj.steps_array.length - 1].ModuleDescription : "").toLowerCase()}`}><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">{obj.buttonText}</Button></Link>
                                        : obj.buttonText == "Go To Field"  || obj.buttonText == "Start" ? <Link to={`/project/${obj.project_ID}/field`}><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">{obj.buttonText}</Button></Link>
                                        : obj.buttonText == "View Project"  ?  <Link to={`/project/${obj.project_ID}/edit`}><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">{obj.buttonText}</Button></Link>
                                        : obj.buttonText == "Review Budget" || obj.buttonText == "Revise Budget" ? <Link to={`/project/${obj.project_ID}/criteria-and-budget`}><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">{obj.buttonText}</Button></Link> 
                                        : <Link to="/"><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">{obj.buttonText}</Button></Link>}
                                    </Text>
                                    {/* <Text type="6">1m</Text> */}

                                </NotificationInfo>
                                )}
                                {/* <NotificationInfo>
                                    
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Aaron G." className="mr-2" />
                                    <Text className="notification-text" type="5">Interview with R4 - <Text fontWeight="800" span>Aaron G.</Text> - <Link to="/"><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">Join Now</Button></Link></Text>
                                    <Text type="6">1m</Text>

                                </NotificationInfo>

                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Sam J." className="mr-2" />
                                    <Text className="notification-text" type="5"><Text fontWeight="800" span>Sam J.</Text> has accepted the invitation</Text>
                                    <Text type="6">4m</Text>
                                </NotificationInfo>

                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Sally H." className="mr-2" />
                                    <Text className="notification-text" type="5"><Text fontWeight="800" span>R6 - Sally H.</Text> has submitted Task - 1 Form - <Link to="/"><Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">Review Form</Button></Link></Text>
                                    <Text type="6">21m</Text>
                                </NotificationInfo>

                                <Heading small={true} className="mt-4">Yesterday</Heading>
                                
                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
                                    <Text className="notification-text" type="5"><Text fontWeight="800" span>Sonia S.</Text> has scheduled her interview - <Text fontWeight="800" span>11:45 - 12:45</Text> on <Text fontWeight="800" span>2.11.21</Text> has applied.</Text>
                                    <Text type="6">25hr</Text>
                                </NotificationInfo>

                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
                                    <Text className="notification-text" type="5"><Text fontWeight="800" span>Sonia S.</Text> has scheduled her interview - <Text fontWeight="800" span>11:45 - 12:45</Text> on <Text fontWeight="800" span>2.11.21</Text> has applied.</Text>
                                    <Text type="6">25hr</Text>
                                </NotificationInfo>

                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
                                    <Text className="notification-text" type="5"><Text fontWeight="800" span>Sonia S.</Text> has scheduled her interview - <Text fontWeight="800" span>11:45 - 12:45</Text> on <Text fontWeight="800" span>2.11.21</Text> has applied.</Text>
                                    <Text type="6">25hr</Text>
                                </NotificationInfo>

                                <NotificationInfo>
                                    <Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
                                    <Text className="notification-text" type="5"><Text fontWeight="800" span>Sonia S.</Text> has scheduled her interview - <Text fontWeight="800" span>11:45 - 12:45</Text> on <Text fontWeight="800" span>2.11.21</Text> has applied.</Text>
                                    <Text type="6">25hr</Text>
                                </NotificationInfo> */}
                            </ScrollContainer>
                            </TabsContentContainer>
                            
                        </NotificationCard>
                    </div>
                </div>

            </section>

        </>
    )
}