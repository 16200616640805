import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as ConfirmAndAssignMethods from '../../utils/api/invite-recruit/ConfirmAndAssignData';

function* ConfirmAndAssignData(payload) {
  const promise = ConfirmAndAssignMethods.ConfirmAndAssignData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ConfirmAndAssignAction.CONFIRM_AND_ASSIGN_RESPONSE, data: res.Status });
}

export  { ConfirmAndAssignData }

