import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { TextArea } from '../../components/form';
import { Card, Divider, Heading, Icon, Button, Text } from '../../common';
import { InputText } from '../../components/form';

import CustomPopup from '../../common/CustomPopup';
import drag from '../../images/drag.svg';
import deleteImg from '../../images/delete.svg';
import VideoEdit from './VideoEdit';
import Breadcurb from '../../common/Breadcurb';
import EditableInput from '../../common/EditableInput';
import useWindowDimensions from '../../common/useWindowDimensions';
import { REQUEST_PRE_EDITOR_DATA } from '../../constants/field/FieldPreEditorConstant';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { postRequest } from '../../utils/helpers/httpHelper';
import { GET_RECORD_TASK_VIDEO } from '../../constants/field/GetRecordTaskVideoConstants';
export let global_newglobal = null;
const moment = extendMoment(Moment);
import ProjectWrapper from './ProjectWrapper';
import { REQUEST_FIELD_DATA } from '../../constants/field/GetFieldOverviewConstants';
import { UPDATE_RTM_TRANSCRIPT_DATA } from '../../constants/projects/ProjectConstants';
import { Dropdown } from '../../components/form';
import successIcon from '../../images/successIcon.svg';
import { color } from 'd3';
const CustomButton = styled(Button)`
  width: ${props => props.width};
  padding: 12px 24px;
  font-size: 16px;
  padding: 3px 3px !important;
`;
const LeftSideContainer = styled(Card)`
  background: var(--midWhite);
  padding: 0;
  border-radius: 5px;
`;
const ScrollContainer = styled.div`
  height: 30.5rem;
  overflow-y: scroll;
  overflow-x: hidden;

  width ::-webkit-scrollbar {
    width: 4px;
    margin-right: 10px;
  }

  track ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  Handle ::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }

  Handle on hover ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbarColor);
  }
`;
const RightSideHeaderContainer = styled(Card)`
  background: var(--midWhite);
  padding: 0.7rem 1.4rem;

  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: none;

  li {
    margin-right: 0.75rem;
    font-weight: 500;
    font-size: 12px;
    color: var(--greyBlue);
  }
`;

const CustomTextArea = styled(TextArea)`
  ::placeholder,
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    color: var(--grey20);
    font-weight: 400;
    font-size: 10px;
  }
`;

function TranscriptEditor(props) {
  const loggedUser = useSelector(state => state.LoginReducer);
  const addClipResponse = useSelector(state => state.AddVideoTrimReducer.addClipResponse);
  const editClipResponse = useSelector(state => state.EditClipReducer.editClipResponse);
  const [seekTime, setSeekTime] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [enableNoteEdit, setEnableNoteEdit] = useState(true);
  const [selectedGuideNotes, setSelectedGuideNotes] = useState('');
  const [seekEndTime, setSeekEndTime] = useState(null);
  const [selectedField, setSelectedField] = useState({});
  const dispatch = useDispatch();
  const [selectedSideTab, setSelectedSideTab] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [isCreateClip, setIsCreateClip] = useState(false);
  const [clipName, setClipName] = useState('');

  const { id } = useParams();
  const projectRespondent_ID =
    localStorage.getItem('projectRespondent_ID') != null ? localStorage.getItem('projectRespondent_ID') : '';
  const projectRepondentVideo_ID =
    localStorage.getItem('projectRepondentVideo_ID') != null ? localStorage.getItem('projectRepondentVideo_ID') : '';

  const location = useLocation();

  const { respondantInfo } = props;

  const InterviewSchedule_ID = props.InterviewSchedule_ID;
  const channel_name = respondantInfo.agoraChannelName;

  const batchStatus = true;
  const userId = useSelector(state => state.LoginReducer.userId);
  const transcriptData = useSelector(state => state.ProjectReducer?.rtmTranscriptData);
  const [parsedJsonData, setParsedJsonData] = useState([]);

  const [popup, setPopup] = useState(false);
  const [allDonePopup, setAllDonePopup] = useState(false);
  const [findValue, setFindValue] = useState('');
  const [allDoneMessage, setAllDoneMessage] = useState(true);
  const [replaceValue, setReplaceValue] = useState('');
  const [clipTimeError, setClipTimeError] = useState(null);
  const numberOfSpeakers = 3;
  const [edittabClipTimeErrorForEdit, setEdittabClipTimeErrorForEdit] = useState();
  const [edittabSectionMessage, setEdittabSectionMessage] = useState();
  const [edittabSaveButtonClicked, setEdittabSaveButtonClicked] = useState();
  props.passClipTimeErrorForEdittoPreCallScreen(edittabClipTimeErrorForEdit);
  props.passClipTimeErrortoPrecallScreen(clipTimeError);
  props.passSectionMessagetoPreCallScreen(edittabSectionMessage);
  props.passSaveButtonClickedtoPreCallScreen(edittabSaveButtonClicked);

  const handleClipTimeErrorForEdit = cterror => {
    setEdittabClipTimeErrorForEdit(cterror);
  };

  const handleEditTabSectionMessage = smessage => {
    setEdittabSectionMessage(smessage);
  };

  const handleEditTabSaveButtonClicked = savebtn => {
    setEdittabSaveButtonClicked(savebtn);
  };

  useEffect(() => {
    setTimeout(() => {
      setClipTimeError(null);
    }, 3000);
  }, [clipTimeError]);

  const handleReplace = () => {
    if (numberOfSpeakers !== null && batchStatus !== null) {
      setEditedTexts(parsedJsonData.map(item => item.text));
      setPopup(true);
    }
  };

  const handleFindReplace = () => {
    const newParsedJsonData = parsedJsonData.map(item => {
      let newName = item.name;
      const replacedNames = {}; // Object to store replaced names

      speaker.forEach((originalSpeaker, index) => {
        const regex = new RegExp(originalSpeaker, 'g');

        if (replaceValue[index]) {
          newName = newName.replace(regex, replaceValue[index]);
        } else {
          newName = newName.replace(regex, originalSpeaker);
        }

        if (newName !== item.name) {
          replacedNames[originalSpeaker] = replaceValue[index];
        }
      });

      return { ...item, name: newName, replacedNames };
    });
    setParsedJsonData(newParsedJsonData);

    const transcriptData = editedTexts.map((text, index) => {
      const replacedName = newParsedJsonData[index]?.name || parsedJsonData[index]?.name; // Get replaced name for this text
      return {
        text,
        end: parsedJsonData[index]?.end || '',
        name: replacedName
      };
    });

    dispatch({
      type: UPDATE_RTM_TRANSCRIPT_DATA,
      payload: {
        user_ID: localStorage.getItem('userId'),
        interviewSchedule_ID: InterviewSchedule_ID,
        channel_name: channel_name,
        transcript_data: transcriptData,
        uid: null
      }
    });

    setTimeout(() => {
      const updatedTranscriptPayload = {
        user_ID: localStorage.getItem('userId'),
        InterviewSchedule_ID: InterviewSchedule_ID,
        channel_name: channel_name,
        transcript_data: {}
      };
    }, 500);

    setAllDonePopup(true);
  };

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;
    const newReplaceValue = [...replaceValue];
    newReplaceValue[index] = newValue;
    if (newReplaceValue.length > 0) {
      setReplaceValue(newReplaceValue);
    }

    if (newReplaceValue.length > 0) {
      setAllDoneMessage(false);
    }
    if (newReplaceValue == speaker) {
      setAllDoneMessage(true);
    }

    if (newValue.length < 1) {
      setAllDoneMessage(true);
     
    }
  };
  useEffect(() => {
    const transcriptPayload = {
      user_ID: localStorage.getItem('userId'),
      InterviewSchedule_ID: InterviewSchedule_ID,
      channel_name: channel_name,
      transcript_data: {}
    };
  }, [InterviewSchedule_ID, channel_name]);

  var speaker = [];
  parsedJsonData.map(speakersname => {
    if (!speaker.includes(speakersname.name)) {
      speaker.push(speakersname.name);
    }
  });

  useEffect(() => {
    if (localStorage.getItem('LocationKey') !== location.key) {
      localStorage.removeItem('ButtonClicked');
    }
    dispatch({
      type: REQUEST_FIELD_DATA,
      payload: {
        user_ID: userId,
        project_ID: id
      }
    });
  }, []);
  const closeHandler = () => {
    // Close the popup
    setPopup(false);
    setAllDonePopup(false);
    setAllDoneMessage(false);
  };

  const closeAllDonePopup = () => {
    setAllDonePopup(false);
  };

  const handleCancel = () => {
    setPopup(false);

    setFindValue('');
    setReplaceValue('');
  };

  const checkName = name => {
    return name.toLowerCase().includes('Guest');
  };

  const [editedTexts, setEditedTexts] = useState(Array(parsedJsonData.length).fill(''));
  useEffect(() => {
    const transcriptPayload = {
      user_ID: localStorage.getItem('userId'),
      InterviewSchedule_ID: InterviewSchedule_ID,
      channel_name: channel_name,

      transcript_data: {}
    };
    dispatch({ type: 'GET_RTM_TRANSCRIPT_DATA', payload: transcriptPayload });
  }, []);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (transcriptData?.Response?.json_data !== null) {
      if (transcriptData?.Status?.StatusCode == 1) {
        const parsedData = JSON.parse(transcriptData?.Response.json_data);

        if (parsedData) {
          setParsedJsonData(parsedData);
          setEditedTexts(parsedData.map(item => item.text));
        }
      }
    }
  }, [transcriptData]);

  const handleEditAllClick = () => {
    setEditMode(true);
    setEditedTexts(parsedJsonData.map(item => item.text));
  };

  const handleSaveLine = (index, newText, end) => {
    const newEditedTexts = [...editedTexts];
    newEditedTexts[index] = newText;

    setEditedTexts(newEditedTexts);
    const [date, time] = end.split(' ');

    const transcriptPayload = {
      user_ID: localStorage.getItem('userId'),
      interviewSchedule_ID: InterviewSchedule_ID,
      channel_name: channel_name,
      uid: null,
      transcript_data: { text: newText, end }
    };
    dispatch({ type: UPDATE_RTM_TRANSCRIPT_DATA, payload: transcriptPayload });
    const updatedTranscriptPayload = {
      user_ID: localStorage.getItem('userId'),
      InterviewSchedule_ID: InterviewSchedule_ID,
      channel_name: channel_name,
      transcript_data: {}
    };
    dispatch({ type: 'GET_RTM_TRANSCRIPT_DATA', payload: updatedTranscriptPayload });
  };

  const handleSaveAllEdit = () => {
    const allLines = editedTexts.map((text, index) => ({
      text,
      end: parsedJsonData[index]?.end || '',
      name: parsedJsonData[index]?.name
    }));

    const editedTextsOnly = allLines.map(item => item.text);
    setEditedTexts(editedTextsOnly);
    dispatch({
      type: UPDATE_RTM_TRANSCRIPT_DATA,
      payload: {
        user_ID: localStorage.getItem('userId'),
        interviewSchedule_ID: InterviewSchedule_ID,
        channel_name: channel_name,
        transcript_data: allLines,
        uid: null
      }
    });

    setTimeout(() => {
      const updatedTranscriptPayload = {
        user_ID: localStorage.getItem('userId'),
        InterviewSchedule_ID: InterviewSchedule_ID,
        channel_name: channel_name,
        transcript_data: {}
      };
      dispatch({ type: 'GET_RTM_TRANSCRIPT_DATA', payload: updatedTranscriptPayload });
    }, 500);

    setEditMode(false);
  };

  const handleCancelAllEdit = () => {
    setEditMode(false);
  };
  const handleTextChange = (index, newText) => {
    const newEditedTexts = [...editedTexts];
    newEditedTexts[index] = newText;
    setEditedTexts(newEditedTexts);
  };

  const clickOnSide = (startTime, endTime, video, index, sectionData, clipIndex, clip_ID) => {
  
    global_newglobal = clip_ID;
    setSeekTime(startTime?.split(' ')[1]);
    setSeekEndTime(endTime?.split(' ')[1]);
    video.edit = false;
    video.index = index;
    setSelectedGuideNotes(video);
    setSelectedField({ sectionName: video.buttonName, index: index, clipIndex: clipIndex, clip_ID: video.clip_ID });
    setEnableNoteEdit(true);
    setIsCreateClip(false);
  };
  const updateCaptureGuideText = (buttonName, index, clipIndex, clip_ID) => {
    let updatedPreEditorData = preEditorData;
    updatedPreEditorData.CaptureGuide[index].clip_array[clipIndex].buttonName = buttonName;
    setPreEditorData(updatedPreEditorData);
    dispatch({
      type: 'REQUEST_EDITBUTTONNAME',
      payload: {
        user_ID: loggedUser.userId,
        question_ID: updatedPreEditorData.CaptureGuide[index].clip_array[clipIndex].question_ID,
        buttonName: buttonName,
        clip_ID: clip_ID
      }
    });
    setTimeout(() => getUpdatedPreEditorData(), 500);
  };

  const getUpdatedPreEditorData = () => {
    dispatch({
      type: 'REQUEST_PRE_EDITOR_DATA',
      payload: {
        user_ID: loggedUser.userId,
        project_ID: id,
        projectRespondent_ID: props.selectedRespondantVideoId
      }
    });
  };

  const noteUpdated = (notesObj, videoNote, type) => {
    if (type && type == 'delete') {
      notesObj.videoNote = null;
      setSelectedGuideNotes(notesObj);
    }
    let updatedNoteIndex = preEditorData.CaptureGuide[notesObj.index].clip_array.findIndex(
      x => x.clip_ID == notesObj.clip_ID
    );
    preEditorData.CaptureGuide[notesObj.index].clip_array[updatedNoteIndex].videoNote = videoNote;
  };

  const enableDisableNoteEdit = video => {
    setEnableNoteEdit(!enableNoteEdit);
  };

  const handleDropdownChange = option => {
    setSelectedOption(option);

    if (option.label == 'Edit Text') {
      handleEditAllClick();
    } else {
      handleReplace();
    }
  };

  useEffect(() => {}, [seekTime, clickOnSide]);

  useEffect(() => {
    if (addClipResponse?.projectRespondentTrimVideo_ID != null) {
      getUpdatedPreEditorData();
    }
  }, [addClipResponse]);

  useEffect(() => {
    if (editClipResponse?.projectRespondentTrimVideo_ID != null) {
      getUpdatedPreEditorData();
    }
  }, [editClipResponse]);

  const [preEditorData, setPreEditorData] = useState({});
  useEffect(() => {
    setPreEditorData(props.captureData);
  }, [props.captureData]);
  useEffect(() => {
    setPreEditorData({});
    dispatch({
      type: 'REQUEST_PRE_EDITOR_DATA',
      payload: {
        user_ID: loggedUser.userId,
        project_ID: id,
        projectRespondent_ID: props.selectedRespondantVideoId
      }
    });
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () =>
        dispatch({
          type: GET_RECORD_TASK_VIDEO,
          payload: {
            user_ID: loggedUser.userId,
            project_ID: props.selectedRespondantVideoId
          }
        }),
      500
    );
  }, [props.selectedRespondantVideoId]);
  const handleCreateClip = create => {
    setIsCreateClip(create);
  };

  const handleClipNameChange = e => {
    setClipName(e.target.value);
  };

  const handleClipCreated = (startTime, endTime, sectionId, questionId) => {
    let error = compareTime(startTime, endTime);
    if (!error) {
      let payload = {
        question_ID: questionId,
        user_ID: loggedUser.userId,
        startTime: startTime,
        endTime: endTime,
        questionPaperSection_ID: sectionId,
        projectRepondentVideo_ID: projectRepondentVideo_ID,
        clipName: clipName,
        projectRespondent_ID: projectRespondent_ID
      };

      dispatch({
        type: 'REQUEST_ADDVIDEOTRIM',
        payload: payload
      });

      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => getUpdatedPreEditorData(),
        4000
      );
    }
  };

  const compareTime = (startTime, endTime) => {
    if (preEditorData && preEditorData?.CaptureGuide) {
      var captureGuide = preEditorData?.CaptureGuide;
      for (var key1 in captureGuide) {
        var clip_array = captureGuide[key1].clip_array;
        for (var key2 in clip_array) {
          var clipStartDate = moment(clip_array[key2].startTime, 'HH:mm:ss', 'Asia/Kolkata');
          var clipEndDate = moment(clip_array[key2].endTime, 'HH:mm:ss', 'Asia/Kolkata');
          var selectedStartDate = moment('0000-00-00 ' + startTime, 'HH:mm:ss', 'Asia/Kolkata');
          var selectedEndDate = moment('0000-00-00 ' + endTime, 'HH:mm:ss', 'Asia/Kolkata');

          var clipDateRange = moment.range(clipStartDate, clipEndDate);
          var selectedDateRange = moment.range(selectedStartDate, selectedEndDate);

          // has overlapping
          if (
            selectedStartDate.within(clipDateRange) ||
            selectedEndDate.within(clipDateRange) ||
            clipStartDate.within(selectedDateRange) ||
            clipEndDate.within(selectedDateRange)
          ) {
            
            setClipTimeError('Time range error');
            return true;
          }
        }
      }
    }
    setClipTimeError(null);
    return false;
  };

  return (
    <div className="row px-3 py-2">
      <div className="col-md-3">
        <LeftSideContainer>
          <Divider background="var(--lightGrey)" height="2px" width="100%" margin="0" />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="py-2" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div>
                {editMode ? (
                  <CustomButton
                    className="ml-2"
                    marginTop="10px"
                    size="small"
                    variant="outlined"
                    buttonColor="var(--sapGreen)"
                    onClick={handleSaveAllEdit}
                  >
                    Save
                  </CustomButton>
                ) : (
                  <div>
                    <div style={{ marginLeft: '20px' }}>
                      <Dropdown
                        options={[
                          { value: 'edit', label: 'Edit Text' },
                          { value: 'editSpeakers', label: 'Replace Name' }
                        ]}
                        onChange={handleDropdownChange}
                        placeholder={'Edit'}
                        defaultValue={'Edit'}
                        selectedFontWeight="60"
                        selectedFontSize="small !important"
                      />
                    </div>

                    <CustomPopup
                      onClose={closeHandler}
                      showed={popup}
                      heading="Replace Name"
                      showCancel={false}
                      style={{ fontSize: '12px' }}
                    >
                      {speaker.map((originalSpeaker, index) => (
                        <div key={index} className="container">
                          <div className="row">
                            <div className="col-3 px-0 py-3 align-middle">
                              <Text type="5">{originalSpeaker}</Text>
                            </div>

                            <div className="col-9" style={{ paddingLeft: 0 }}>
                              <InputText
                                value={replaceValue[index] || originalSpeaker}
                                showCharacters={false}
                                placeholder="Replace as"
                                onChange={e => handleInputChange(e, index)}
                              ></InputText>
                            </div>
                          </div>
                        </div>
                      ))}
                      {allDonePopup === false ? (
                        <Button
                          size="medium"
                          style={{ marginTop: '1rem', paddingBlock: '0.5rem' }}
                          onClick={handleFindReplace}
                          disabled={allDoneMessage}
                        >
                          Replace All
                        </Button>
                      ) : (
                        <Text color="var(--primaryColor)" style={{ marginTop: '1rem' }}>
                          All Done! Text Replaced.
                        </Text>
                      )}
                    </CustomPopup>
                  </div>
                )}
              </div>
              <div>
                {editMode && (
                  <button
                    onClick={handleCancelAllEdit}
                    style={{
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer',
                      textDecoration: 'none'
                    }}
                  >
                    <Icon>close</Icon>
                  </button>
                )}
              </div>
            </div>
          </div>
          <ScrollContainer>
            <div style={{ position: 'relative' }}>
              {parsedJsonData &&
                parsedJsonData.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex mt-2 w-100 border-bottom"
                    style={{ fontSize: '14px', marginLeft: '10px' }}
                  >
                    {editMode ? (
                      <>
                        <div className="p-2 w-30" style={{ color: '#38454F', fontWeight: '600' }}>
                          {item.name}
                        </div>
                        <div className="p-2">:</div>
                        <CustomTextArea
                          value={editedTexts[index]}
                          showCharacters={false}
                          className="mt-1"
                          width={'800px'}
                          onChange={e => handleTextChange(index, e.target.value)}
                        />
                      </>
                    ) : (
                      <>
                        <div className="p-2" style={{ color: '#38454F', fontWeight: '600', width: '8rem' }}>
                          {item.name}
                        </div>
                        <div className="p-2">:</div>
                        <div className="p-2 w-100" style={{ color: '#38454F' }}>
                          {item.text}
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
            {!parsedJsonData && <div>No Transcript Found!</div>}
          </ScrollContainer>
        </LeftSideContainer>
      </div>
      <div className="col-md-9">
        <RightSideHeaderContainer>
          <Breadcurb>
            <li>Field Overview</li>
            <li>
              <Icon style={{ fontSize: 10 }}>arrow_forward_ios</Icon>
            </li>
            <li className="" aria-current="page">
              Respondent Details
            </li>
            <li>
              <Icon style={{ fontSize: 10 }}>arrow_forward_ios</Icon>
            </li>
            <li>
              {isCreateClip ? (
                <InputText showCharacters={false} labelText="Clip Name" onChange={e => handleClipNameChange(e)} />
              ) : (
                <EditableInput
                  value={selectedField.sectionName}
                  submit={text =>
                    updateCaptureGuideText(text, selectedField.index, selectedField.clipIndex, selectedField.clip_ID)
                  }
                />
              )}
            </li>
          </Breadcurb>
        </RightSideHeaderContainer>
        <VideoEdit
          selectedSideTab={selectedSideTab}
          moveSeekTo={seekTime}
          endTime={seekEndTime}
          playing={playing}
          setPlaying={setPlaying}
          selectedGuideNotes={selectedGuideNotes}
          captureGuideTimes={
            preEditorData?.CaptureGuide ? preEditorData.CaptureGuide.map(guide => guide.clip_array.map(obj => obj)) : []
          }
          tagList={preEditorData && preEditorData.MasterTags ? preEditorData.MasterTags : []}
          enableNoteEdit={enableNoteEdit}
          createClip={handleCreateClip}
          isCreateClip={isCreateClip}
          clipCreated={handleClipCreated}
          enableDisableNoteEdit={enableDisableNoteEdit}
          noteUpdated={noteUpdated}
          projectRepondentVideo_ID={projectRepondentVideo_ID}
          selectedRespondantVideoId={props.selectedRespondantVideoId}
          getUpdatedPreEditorData={getUpdatedPreEditorData}
          projectId={id}
          respondantInfo={props.respondantInfo}
          passClipTimeErrorForEdit={handleClipTimeErrorForEdit}
          passSectionMessage={handleEditTabSectionMessage}
          passSaveButtonClicked={handleEditTabSaveButtonClicked}
          // clip_ID = {global_Clip_id2}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    // will be available as props.captureData

    captureData: state.FieldPreEditorReducer.preEditorData
  };
};

// export default EditTab;
export default connect(mapStateToProps)(TranscriptEditor);
