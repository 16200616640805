import constants from "../../constants";


const {REQUEST_VIEWPROJECTSUPERADMINDASHBOARD_DATA, VIEWPROJECTSUPERADMINDASHBOARD_DATA_RECEIVED} = constants.ViewProjectSuperAdminDashboardConstants;


export default{
    REQUEST_VIEWPROJECTSUPERADMINDASHBOARD_DATA,
    VIEWPROJECTSUPERADMINDASHBOARD_DATA_RECEIVED,

    requestViewProjectSuperAdminDashboardData: payload => ({
        type: REQUEST_VIEWPROJECTSUPERADMINDASHBOARD_DATA,
        payload
    }),
    ViewProjectSuperAdminDashboardDataReceived: () => ({
        type: VIEWPROJECTSUPERADMINDASHBOARD_DATA_RECEIVED
    })
}