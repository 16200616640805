import {useState, useRef,useEffect} from 'react'
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  event,
  map,
  InternSet,
  range
} from "d3";
import { retry } from 'redux-saga/effects';
import { wordData } from '../../../../sagas/analysis/wordDataSaga';
import * as d3 from 'd3'

export default function BarChart(props){

    //const [data, setData] = useState(props.datasets);
    const svgRef = useRef();
    const wrapperRef = useRef();
    

    let {title, yFormat, yLabel} = props

    // const truncateLabel = (label, wordLimit) => {
    //   if(typeof label !== 'string'){
    //     console.error('Label is not a string:',label);
    //     return label;
    //   }

    //   const words = label.split(' ');
    //   // let truncatedLabel = label;
    //   // if(words.length > wordLimit){
    //   //   truncatedLabel = words.slice(0,wordLimit).join(' ');
    //   //   const remainingLabel = words.slice(wordLimit).join(' ');
    //   //   return `${truncatedLabel}\n${remainingLabel}`;
    //   // }
    //   return  words.slice(0,wordLimit).join(' ');;
    // }
    
    useEffect(()=>{
      const svg = select(svgRef.current);
    let x = d => d.x // given d in data, returns the (ordinal) x-value
    let y = d => d.y // given d in data, returns the (quantitative) y-value
    let data = props.datasets

    const X = map(data, x);
    const Y = map(data, y);
    let marginTop = 20 // the top margin, in pixels
    let marginRight = 0 // the right margin, in pixels  it was at 0 before
    let marginBottom = 30 // the bottom margin, in pixels
    let marginLeft = 40 // the left margin, in pixels
    const { width, height } = wrapperRef.current.getBoundingClientRect();
    let xDomain = X // an array of (ordinal) x-values
    let xRange = [marginLeft, width - marginRight] // [left, right]
    let yType = scaleLinear // y-scale type
    let yDomain  = [0, max(Y)] // [ymin, ymax]
    let yRange = [height - marginBottom, marginTop] // [bottom, top]
    let xPadding = 0.1 // amount of x-range to reserve to separate bars
    
    let color = "rgb(35,97,160)" 

    xDomain = new Set(xDomain);

    
    // const calculateWordLimit = (label,maxWidth) =>{
    //   const words = label.split(' ');
    //   let currentWidth = 0;
    //   let wordLimit =0;

    //   for(const word of words){
    //     currentWidth +=getTextWidth(word);

    //     if(currentWidth <= maxWidth){
    //       wordLimit++;
    //     }else{
    //       break;
    //     }
    //   }
    //   return wordLimit;
    // }
    // const getTextWidth = (text) =>{
    //   const canvas = document.createElement('canvas');
    //   const context = canvas.getContext('2d');
    //   context.font  ='10px sans-serif';
    //   const width = context.measureText(text).width;
    //   return width;
    // };
    // const truncatedXDomain = Array.from(xDomain).map((label) =>
    //   truncateLabel(label, 2)
    // );

    // Omit any data not present in the x-domain.
    const I = range(X.length).filter(i => xDomain.has(X[i]));

    // Construct scales, axes, and formats.
    const xScale = scaleBand(xDomain, xRange).padding(xPadding);
    const yScale = yType(yDomain, yRange);
    const xAxis = axisBottom(xScale).tickSizeOuter(0);
    const yAxis = axisLeft(yScale).ticks(height / 40, yFormat);
    // const maxLabelWidth = xScale.bandwidth();
    
   

    // Compute titles.
    if (title === undefined) {
        const formatValue = yScale.tickFormat(100, yFormat);
        title = i => `${X[i]}\n${formatValue(Y[i])}`;
    } else {
        const O = map(data, d => d);
        const T = title;
        title = i => T(O[i], i, data);
        // const T = title;
        // title = (i) => T(data[i], i,data);
    }

    // Sageer's code
    var insertLineBreaks = function(d){
      var el = d3.select(this);
      var words = d3.select(this).text().split(' ');
      el.text(' ');

      for(var i=0;i<words.length;i++){
        // el.append('tspan').text(words[i]);
        var tspan =el.append('tspan').text(words[i]);
        if(i<words.length-1){
          el.append('tspan').text(' ');
        }
        if(i>0 && i%2 ==0){
          tspan.attr('x',0).attr("dy","1em");
        }
      }
    };

    svg.selectAll('.x-axis > *').remove()
    svg.selectAll('.y-axis > *').remove()

    svg.selectAll('.content > *').remove()
    
    
  svg.selectAll('.y-axis').append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .attr("font-family","Noto Sans")
          .text(yLabel));

  const bar = svg.selectAll('.content').append("g")
      .attr("fill", color)
    .selectAll("rect")
    .data(I)
    .join("rect")
      .attr("x", i => (xScale(X[i])+(xScale.bandwidth()/2)-50))
      .attr("y", i => yScale(Y[i]))
      .attr("height", i => yScale(0) - yScale(Y[i]))
      .attr("width", Math.min(xScale.bandwidth(),100));

  //  const bar = svg.selectAll('.content').append("g")
  //     .attr("fill", color);
  //  bar
  //   .selectAll("rect")
  //   .data(X)
  //   .join("rect")
  //     // .attr("x", (d) => (truncateLabel(d,2))+(xScale.bandwidth()/4))
  //     .attr('x',(d,i) => xScale(truncateLabel(X[i],2)))
  //     .attr("y", (d,i) => yScale(Y[i]))
  //     .attr("height", (d,i) => yScale(0) - yScale(Y[i]))
  //     .attr("width", xScale.bandwidth());
      

  if (title) 
      bar
      // .selectAll('rect') //new one
      .append("title")
      .text(title);
      // .text((d,i) =>title(i));
  // svg.selectAll('.x-axis > tick > text')
      
  
  // svg.selectAll('.x-axis').append("g")
  //     .attr("transform", `translate(0,${height - marginBottom})`)
  //     .call(xAxis)
      // .selectAll('.tick text')
      // .attr('dy', '0.5em')
      // .style('text-anchor','middle')
      // .style('white-space','nowrap')
      // .style('overflow','hidden')
      // .style('text-overflow','ellipsis');

      svg.selectAll('.x-axis').append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis)
      .selectAll(".tick text").each(insertLineBreaks)
  

    },[props]);

   
    
    return (
        <>
          <div
            ref={wrapperRef}
            style={{ width: "100%", height: "200px", marginBottom: "2rem" ,margin: '0 auto'}}
            
          >
            <svg ref={svgRef} style={{ width: "100%", height: "110%"}}>
              
              <g className='x-axis'/>
              <g className="y-axis" />
              <g className="content" />
            </svg>
          </div>
        </>
      );
}