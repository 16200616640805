import styled from '@emotion/styled';
import { Avatar, Button } from '../../common';

export const Thumbnail = (props) => {
	return <Avatar width={40} height={40} className='avatar' {...props} />;
};
export const Item = styled.div`
	display: flex;
	// background: red;
	justify-content: space-between;
	align-items: center;
	padding: 9px 0;
	margin: 2px 0;
	padding-right: 1.5rem;
	border: 1px solid transparent;

	.avatar {
		margin: 2px 1.5rem;
		margin-left: 1rem;
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	p {
		margin: 0;
		padding: 0;
	}
	.title {
		font-weight: normal;
		font-size: 16px;

		letter-spacing: 0.2px;

		/* Colour/DeepBlue */
		.bold {
			font-weight: 700;
		}
		color: var(--deepBlue);
		.tag {
		}
		.red {
			color: var(--red);
		}
		.orange {
			color: #cb9f30;
		}
	}
	.subtitle {
		margin-left: 10px;
		font-weight: normal;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.2px;

		/* Colour/GreyBlue */

		color: #798e8f;
	}
	.minute {
		font-size: 12px;
		// line-height: 20px;
		/* identical to box height, or 167% */

		text-align: right;
		letter-spacing: 0.2px;

		/* Colour/GreyBlue */

		color: #798e8f;
		margin-right: 2rem;
	}

	&:hover {
		border: 1px solid var(--greyBlue30);
		button {
			background: var(--sapGreen);
			color: white;
		}
	}
`;

const StyledButton = styled(Button)`
	width: 140px;
`;
export const CustomButton = ({ children }) => (
	<StyledButton
		padding='3px 2px'
		fontSize='13px'
		fontWeight='700'
		size='small'
		variant='outlined'
		buttonColor='var(--sapGreen)'
	>
		{children}
	</StyledButton>
);

export const ScrollContainer = styled.div`
	padding: 1rem 0;
	// background: red;
	height: 350px;
	overflow-y: scroll;
	padding-right: 5px;
	/* width */
	::-webkit-scrollbar {
		width: 4px;
		border-radius: 100px;
		// margin-right: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f0f0f0;
		border-radius: 100px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
		border-radius: 100px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #a6a6a6;
	}
`;
