import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';
import ErrorText from './ErrorText';
import { ScrollContainer } from '../../views/Notificaions/common';

const InputContainer = styled.div`
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};
  display: ${props => (props.display ? props.display : 'block')};

  .characters {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    font-size: 12px;
    font-weight: 400;
    color: var(--inputColor);
  }
`;

const StyledTextArea = styled(TextareaAutosize)`
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--darkBorderColor);
  color: ${props => props.color || 'var(--inputColor)'};
  padding-left: 0;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '700')};
  font-size: ${props => (props.fontSize ? props.fontSize : '0.875rem')};
  background: transparent;
  resize: none;

  :hover,
  :focus {
    box-shadow: none;
    outline: 0;
    border-color: var(--inputColor);
    color: ${props => props.color || 'var(--inputColor)'};
    background: transparent;
  }

  &.is-invalid:focus {
    box-shadow: none;
    outline: 0;
  }

  &:disabled {
    background: transparent;
  }

  ::placeholder {
    color: var(--mediumGrey);
    font-weight: 500;
    font-size: 0.75rem;
  }
  ::-webkit-input-placeholder {
    color: var(--mediumGrey);
    font-weight: 500;
    font-size: 0.75rem;
  }
  ::-moz-placeholder {
    color: var(--mediumGrey);
    font-weight: 500;
    font-size: 0.75rem;
  }
  :-ms-input-placeholder {
    color: var(--mediumGrey);
    font-weight: 500;
    font-size: 0.75rem;
  }

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 100px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 100px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 3px;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a6a6a6;
  }
`;

export default function TextArea({
  children,
  className = '',
  placeholder = null,
  minRows = '1',
  maxRows = '3',
  value = '',
  onChange = null,
  onBlur = null,
  characterLimit = 300,
  showCharacters = true,
  disabled = false,
  width,
  display,
  error = false,
  ...rest
}) {
  const charactersTyped = value?.length || 0;

  if (showCharacters && value?.length >= characterLimit) {
    value = value.substr(0, characterLimit - 1);
  }

  return (
    <InputContainer width={width} display={display}>
      <StyledTextArea
        minRows={minRows}
        maxRows={maxRows}
        className={`form-control ${className} ${error ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        defaultValue={value}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
        {...rest}
      />
      {showCharacters && (
        <span className="characters">
          {charactersTyped}/{characterLimit}
        </span>
      )}
      {error && <ErrorText>{error}</ErrorText>}
    </InputContainer>
  );
}
