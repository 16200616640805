import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditResearcherGenPoolMethods from '../../utils/api/superAdmin/EditResearcherGenPoolData';

function* EditResearcherGenPoolData(payload) {
  const promise = EditResearcherGenPoolMethods.EditResearcherGenPoolData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditResearcherDataGenPoolAction.EDITRESEARCHERDATAGENPOOL_RECEIVED, data: res.Response });
}

export { EditResearcherGenPoolData };
