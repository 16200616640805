import React from 'react';

export function Handle({ domain: [ min, max ], handle: { id, value, percent }, getHandleProps, children }) {
	return (
		<div
			role="slider"
			aria-valuemin={min}
			aria-valuemax={max}
			aria-valuenow={value}
			style={{
				left: `${percent - 0.7}%`,
				position: 'absolute',
				zIndex: 2,
				cursor: 'pointer',
				color: '#B6DC25',
				fontSize: '19px',
				fontWeight: 700
			}}
			{...getHandleProps(id)}
		>
			{children}
		</div>
	);
}

export function Track({ source, target, getTrackProps }) {
	return (
		<div
			style={{
				position: 'absolute',
				height: 14,
				zIndex: 1,
				backgroundColor: '#798E8F',
				borderRadius: '3px',
				opacity: 0.6,
				cursor: 'pointer',
				left: `${source.percent}%`,
				width: `${target.percent - source.percent}%`,
				top:'5px'
			}}
			{...getTrackProps()}
		/>
	);
}
