import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddTimeVideoQuestionsMethods from '../../utils/api/field/AddTimeVideoQuestionsData';

function* AddTimeVideoQuestionsData(payload) {
  const promise = AddTimeVideoQuestionsMethods.AddTimeVideoQuestionsData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddTimeVideoQuestionsAction.ADDTIMEVIDEOQUESTION_RECEIVED, data: res.Response });
}

export  { AddTimeVideoQuestionsData }