import constants from '../../constants';
const { REQUEST_TOKEN_VALIDATE,REQUEST_TOKEN_VALIDATE_RECEIVED } = constants.TokenValidateForEmailPassword;
export default {
  REQUEST_TOKEN_VALIDATE,
  REQUEST_TOKEN_VALIDATE_RECEIVED,
  
    RequestresetPasswordEmailTOkenValidation: payload => ({
      type: REQUEST_TOKEN_VALIDATE,
      payload
    }),
    GetresetPasswordEmailTOkenValidationRecieved: () => ({
      type: REQUEST_TOKEN_VALIDATE_RECEIVED
   
      
    })

  };



  