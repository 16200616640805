import React from 'react';
import { Tab, Tabs, TabsContainer } from '../../common';
import styled from '@emotion/styled';

const TabContainerWrapper = styled(TabsContainer)`
   
   ul{
       display:flex;
       justify-content: ${(props) => (props.justify ? props.justify : 'space-between')};

	   li:not(:first-of-type){
		   	margin-left:2rem;
	   }
   }

`;

export function FieldWrapper({ stage, children, handleClick, ...props }) {
	return (
		<React.Fragment>
			<TabContainerWrapper {...props}>
				<div className="row">
					<div className="col-md-12 p-0">
						<Tabs>
							<Tab
								onClick={() => handleClick(1)}
								active={stage == 1 ? true : false}
								fontSize="14px"
								activeColor="var(--middleGreen)"
							>
								Usage Overview
							</Tab>
							<Tab
								onClick={() => handleClick(2)}
								active={stage == 2 ? true : false}
								fontSize="14px"
								activeColor="var(--middleGreen)"
							>
								Task Analysis
							</Tab>
						</Tabs>
					</div>
				</div>
				{children}
			</TabContainerWrapper>
		</React.Fragment>
	);
}

export function VideoClipWrapper({ stage, children, handleClick, ...props }) {
	return (
		<React.Fragment>
			<TabContainerWrapper {...props}>
				<div className="row">
					<div className="col-md-12 p-0">
						<Tabs>
							<Tab onClick={() => handleClick(1)} active={stage == 1 ? true : false} fontSize="14px">
								View Clips
							</Tab>
							<Tab
								onClick={() => handleClick(2)}
								active={stage == 2 ? true : false}
								fontSize="14px"
							>
								View Raw Video
							</Tab>
						</Tabs>
					</div>
				</div>
				{children}
			</TabContainerWrapper>
		</React.Fragment>
	);
}
