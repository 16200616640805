import styled from '@emotion/styled';
import React, { useState, useEffect, Children } from 'react';
import { Button, Divider, Heading, Icon } from '.';
import './CustomPopup.css';
import crossImg from '../images/cross.svg';
const CustomButton = styled(Button)`
  padding: 2px 1rem;
  color: var(--deepBlue);
  border: 1px solid var(--deepBlue);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
  :hover {
    color: var(--deepBlue);
  }
    
`;
function CustomPopup({
  width = '100%',
  // height = '100%',
  number,
  heading,
  showed,
  children,
  secondheading,
  secondheading2,
  handleNext,
  onClose,
  handleDisable,
  handleSkip,
  showCancel = true,
  showCross = true,
  secondHeadingFontSize = '14px',
}) {
  const [show, setShow] = useState(false);
  const closeHandler = e => {
    setShow(false);
    onClose(false);
  };
  useEffect(() => {
    setShow(showed);
  }, [showed]);
 
  return (
    <div
      className="d-flex  popup  overlay"
      style={{ width: width,
         visibility: show ? 'visible' : 'hidden', opacity: show ? '1' : '0' }}
    >
      <div className="flex popup_inner text-start">
        {heading && (
          <Heading
            fontSize="20px"
            fontWeight="700"
            breakHeading={false}
            className="mb-3"
            style={{
              lineHeight: '19px',
              textAlign: 'left',
              paddingTop: '1.5rem'
            }}
          >
            {heading}

            {showCross && (
              <div style={{ float: 'right' }}>
                <button
                  style={{ backgroundColor: 'white', border: 'none', color: 'black', padding: '0.1rem' }}
                  onClick={closeHandler}
                >
                  <img src={crossImg} style={{ width: '100%', height:'15px' }}></img>{' '}
                </button>
              </div>
            )}
          </Heading>
        )}

        {secondheading && (
          <Heading
            // fontSize="14px"
            fontSize={secondHeadingFontSize}
            fontWeight="400"
            breakHeading={false}
            className="mb-3 pb-0 justify-content-start"
            style={{
              whiteSpace: 'pre-line',
              lineHeight: '19px',
              textAlign: 'left',
            }}
          >
            {secondheading}
          </Heading>
        )}

        {secondheading2 && (
          <Heading
            fontSize="14px"
            fontWeight="400"
            breakHeading={false}
            className="mb-3 pb-0"
            style={{
              whiteSpace: 'pre-line',
              lineHeight: '19px',
              marginTop: '-18px',
              textAlign: 'left'
            }}
          >
            {secondheading2}
          </Heading>
        )}

        <div className="grid row " style={{ justifyContent: 'space-between', marginLeft: '0px' }}>
          {showCancel && (
            <div onClick={closeHandler}>
              <CustomButton
                fontSize="15px"
                fontWeight="500"
                color="black"
                size="large"
                buttonColor="var(--white)"
                // onClick={handleClose}
              >
                Cancel
              </CustomButton>
            </div>
          )}

          {/* {showCross && } */}
          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
}
export default CustomPopup;