import React, { useEffect } from 'react';
import video_play from '../../images/video_play.svg';
import analysis_video from '../../images/analysis_video.png';
import note_blue from '../../images/note_blue.svg';
import tag_grey from '../../images/tag_grey.svg';

import styled from '@emotion/styled';
import { Heading } from '../../common';
import { Checkbox } from '../../components/form';
import {   useDispatch, useSelector} from 'react-redux';

const Container = styled.div`
	background: var(--White);

	// margin: 0.5rem;
	margin-left: 0;
	// margin: 0.5rem 0;
	margin-bottom: 1rem;
	// margin-right: 0.5rem;
	.image {
		// width: 14rem;
	}
	label {
		margin-bottom: 0 !important;
	}
	.img {
		position: relative;
	}
	.video_play {
		position: absolute;
		left: 40%;
		top: 38%;
	}
	.iconContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		.imgContainer:hover {
			.popup {
				display: block;
			}
		}
		.imgContainer {
			position: relative;
			background: var(--darkWhite);
			margin-left: 5px;
			padding: 10px;
			width: 28px;
			height: 28px;
			border-radius: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
			.popup {
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18);
				border-radius: 5px;
				background: var(--white);
				font-size: 10px;
				font-weight: 500;
				color: var(--deepBlue);
				padding: 0.5rem;
				position: absolute;
				width: 160px;
				bottom: -40px;
				left: 10px;
				z-index: 1080;
				display: none;
			}
		}
	}
`;
function ViewAudio({  imgLink, onPlay}) {

	return (
		<Container>
			<div className='audio'>
                <audio controls style={{width : '70%',maxHeight: '250px', marginTop: '5px'}} onPlay={onPlay}>
                    <source src={imgLink} type='audio/mpeg'/>
                </audio>
			</div>

		</Container>
	);
}

export default ViewAudio;