import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as wordMethods from '../../utils/api/analysis/wordData';

function* wordData(payload) {
  const promise = wordMethods.wordData(payload.payload);
  const { res } = yield promise;
  
  yield putResolve({ type: actions.analysisAction.WORD_ANSWER_RECEIVED, data: res.Response });
}

export  { wordData }