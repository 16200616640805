import styled from '@emotion/styled';
import React from 'react';
import { Divider, Heading, Icon, Text } from '../../common';
import DownIndicator from '../../common/DownIndicator';
import {
	NavTab,
	NavTabs,
	NavTabsContainer,
} from '../../common/NewTab';
import { Dropdown } from '../../components/form';
import EventList from './EventList';
import TodoList from './TodoList';
import {  useDispatch ,useSelector} from 'react-redux';

const Container = styled.div`
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-item: center;
	border-bottom: 1px solid var(--deepBlue);
`;

const ListContainer = styled.div``;
const product_types = [
	{ value: 1, label: 'My Movie Binge' },
	{ value: 2, label: 'My Movie Binge 2' },
];

const controlStyle = {
	height: '39px',
	width: '200px',
	'&:hover': {
		borderColor: 'var(--deepBlue)',
	},
	borderBottom: '1.5px solid var(--deepBlue)',
};

const optionStyle = {
	fontSize: '14px',
	fontWeight: '300',
	color: 'var(--deepBlue)',
};
const DownIndicatorCustom = ({ ...props }) => {
	return (
		<DownIndicator
			iconName='arrow_drop_down'
			color={'var(--deepBlue)'}
			iconStyle={{ fontSize: 30 }}
			{...props}
		/>
	);
};
const singleValueStyle = {
	fontSize: '14px',
	fontWeight: '300',
	color: 'var(--mediumGrey)',
};

function NotificationScreen({ step = 1, children }) {
	const notificationContent = useSelector(state=>state.webDashboardReducer.notificationContent);
	
	//console.log(notificationContent);
	return (
		<div>
			<div className='d-flex '>
				<Icon style={{ fontSize: '36px' }} color='var(--sapGreen)'>
					arrow_back_ios
				</Icon>
				<div>
					<Heading
						fontSize='28px'
						fontWeight='700'
						className='mb-0 pb-0'
						color='var(--seaBlue)'
					>
						Notifications
					</Heading>
					<Text
						fontSize='14px'
						fontWeight='300'
						className='mb-0 pb-0'
						color='var(--seaBlue)'
					>
						Find your updates and reminders all in one place
					</Text>
				</div>
			</div>

			<div style={{ marginLeft: '2.25rem', marginTop: '1rem' }}>
				{/* <div className='mb-4 mt-4'>
					<Dropdown
						selectedFontSize='14px'
						selectedColor='var(--deepBlue)'
						selectedFontWeight='700'
						options={product_types}
						defaultValue={{
							value: 1,
							label: 'My Movie Binge',
						}}
						DropdownIndicator={(props) => (
							<DownIndicatorCustom {...props} />
						)}
						controlStyle={controlStyle}
						optionStyle={optionStyle}
						valueContainerStyle={{
							padding: 0,
						}}
					/>
				</div> */}

				<TodoList notificationContent={notificationContent}/>
				{/* <Divider
					width='99%'
					background='#E2E2E2'
					margin='0.5rem 1.5rem 2rem 0.5rem'
				/>
				<EventList /> */}
			</div>
		</div>
	);
}

export default NotificationScreen;
