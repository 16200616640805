import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddRescheduleSlotMethods from '../../utils/api/field/AddRescheduleSlotData';

function* AddRescheduleSlotData(payload) {
  const promise = AddRescheduleSlotMethods.AddRescheduleSlotData(payload.payload);
  const { res } = yield promise;

  yield putResolve({ type: actions.AddReschduleSlotAction.RESCHEDULESLOT_RECEIVED, data: res.Status });
}

export { AddRescheduleSlotData };
