/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { useState } from 'react';
import PlusGreen from '../assets/images/plus-green.svg';
import Text from './Text';
import { Button } from '.';

const StyledButton = styled.button`
    background: transparent;
    border: 0;
    padding: 0.5rem;

    .task-menu {
        display: none;
        position: absolute;
        z-index: 1;
        transition-delay: 1s !important;
    }

    /* &:hover .task-menu, */
    &.isOpen .task-menu{
        display: block;
        
    }
`

const NormalBtn = styled.div`
    background: transparent;
    border: 0;

    .task-menu {
        display: none;
        position: absolute;
        z-index: 1;
        transition-delay: 1s !important;
        left: ${props => props.left || 'auto'};
        top: ${props => props.top || 'auto'};
    }

    /* &:hover .task-menu, */
    &.isOpen .task-menu{
        display: block;
        
    }
`

export default function ActionButton({ children, link, onClick, normalBtn, left, top, ...rest }) {

    const[isOpen, setIsOpen] = useState(false);

    if(normalBtn) {
        return <NormalBtn className={isOpen ? 'isOpen' : ''} left={left} top={top}><Button {...rest} onBlur={() => setTimeout(() => {setIsOpen(false)}, 350)} 
        onClick={() => {
                onClick && onClick(); 
                setIsOpen(isOpen => !isOpen);
            }
        } >
            {children}
        </Button></NormalBtn>
    }

    return <StyledButton 
                onBlur={() => setTimeout(() => {setIsOpen(false)}, 350)} 
                onClick={() => {
                        onClick && onClick(); 
                        setIsOpen(isOpen => !isOpen);
                    }
                } className={isOpen ? 'isOpen' : ''}>
        <img src={PlusGreen} alt="Add Circle" style={{height: '55px'}} />
        <Text type="2">{children || `Add new`}</Text>
    </StyledButton>
}