import actions from "../../actions";

//reducer initial state

const initialState = {
    editClientAdminData: null
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case actions.EditClientAdminAction.REQUEST_EDITCLIENTADMINDATA:
            return {
                ...state
            };
        case actions.EditClientAdminAction.EDITCLIENTADMINDATA_RECEIVED:
            return {
                ...state,
                editClientAdminData: action.data
            };

        default:
            return state;
    }
};

export default reducer;