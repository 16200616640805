import { components } from 'react-select';

export default function ListMenu(props) {
	const { selectProps , children } = props;
  return (
    <components.Menu {...props}>
      {selectProps.fetchingData ? (
        <span className="fetching">Fetching data...</span>
      ) : (
        <div>{children}</div>
      )}
    </components.Menu>
  );
}
