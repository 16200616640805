import actions from "../../actions";


const initialState = {
    clientAdminAccountData: null,
    // clientData: null,
    // usersData: null,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actions.SuperAdminDashboardAction.REQUEST_SUPERADMINDASHBOARD_DATA:
            return {
                ...state,
            }
        case actions.SuperAdminDashboardAction.SUPERADMINDASHBOARD_DATA_RECEIVED:
            return {
                ...state,
                clientAdminAccountData: action?.data,
                // superAdminProjectData: action?.data?.superAdminProjectStatus,
                // clientData: action?.data?.clientData,
                // usersData: action?.data?.usersData,
            }

        default:
            return state;
    }
};

export default reducer;