import styled from '@emotion/styled';

const Button = styled.button`
    background: transparent;
    border: 0;
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: var(--textColor);
    padding: ${props => props.padding || '0'};

    .material-icons {
        font-size: 1.2rem;
        color: var(--textColor);
        margin-right: 5px;
    }
`

export default function ChoiceButton({ children, className="", style={}, onClick, padding }) {
    return <Button className={className} style={style} onClick={onClick} padding={padding}>{ children }</Button>
}