import React, { useRef, useState, useEffect } from "react";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Resizer from "../common/Panel/components/Resizer";

import { Direction } from "../common/Panel/components/Resizer/constants";
import "../common/Panel/styles.css";
const moment = extendMoment(Moment);
const ResizeBox = ({
	className,
	children,
	RightComponent,
	LeftComponent,
	onClick,
	startTime,
	endTime,
	setStartClipTime,
	setEndClipTime,
	nextTileAvailable,
	prevTileAvailable,
	nextClipData,
	prevClipData,
	isCreateClip,
	currentPlayedSecond,
	startClipTime,
	singlePointDifferenceInTermsOfCoordinates,
	passReference,
	passStartTime,
	passEndTime,
	passPrevClipData,
	passNextClipData
}) => {
	const panelRef = useRef(null);
	passReference(panelRef.current)
	passStartTime(startTime)
	passEndTime(endTime)
	if(typeof prevClipData === 'undefined') prevClipData = null ;
	if(typeof nextClipData === 'undefined') nextClipData = null ;
	passPrevClipData(prevClipData);
	passNextClipData(nextClipData);

	const [left, setLeft] = useState(0);
	const [clicked, setClicked] = useState(false);
	// this is written now
	// useEffect(()=>{
	// 	const updateChildRef = {...childRef.current,panelRef};
	// 	childRef.current.panelRef = panelRef;  // this is written now
		
	// },[childRef,panelRef]);

	const handleResize = (direction, movementX, movementY, handle, event) => {
		const panel = panelRef.current;

		if (!panel) return;
		if((panel.offsetWidth) - Math.abs(movementX) > panel.parentElement.parentElement.offsetWidth && !nextTileAvailable && !prevTileAvailable) {
				
				panel.style.width = `${panel.parentElement.parentElement.offsetWidth}px`;
				
				return;
		} else {
			if(nextTileAvailable || prevTileAvailable) {
				let timeDifferenceOfPrevClip = (moment(prevClipData.endTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment(prevClipData.startTime, [moment.ISO_8601, 'HH:mm:ss']))/1000);
				let timeDifferenceOfNextClip = (moment(nextClipData.endTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment(nextClipData.startTime, [moment.ISO_8601, 'HH:mm:ss']))/1000);
				if(nextTileAvailable && !prevTileAvailable) {
					if((panel.offsetWidth + left) >= ((timeDifferenceOfNextClip * singlePointDifferenceInTermsOfCoordinates) + (panel.parentElement.parentElement.offsetWidth))) {
						panel.style.left = `${left - movementX}px`;
						panel.style.width = `${((timeDifferenceOfNextClip * singlePointDifferenceInTermsOfCoordinates) + panel.parentElement.parentElement.offsetWidth - left)}px`
						return;
					}
				} else if(nextTileAvailable && prevTileAvailable) {
					if((left + movementX) <= ((timeDifferenceOfPrevClip * singlePointDifferenceInTermsOfCoordinates) * -1)) {
						panel.style.left = `${left}px`;
						// panel.style.width = `${((timeDifferenceOfPrevClip * singlePointDifferenceInTermsOfCoordinates) + panel.parentElement.parentElement.offsetWidth - left)}px`
						return;
					}
					if((left) >=0 && (panel.offsetWidth + left) >= ((timeDifferenceOfNextClip * singlePointDifferenceInTermsOfCoordinates) + (panel.parentElement.parentElement.offsetWidth))) {
						panel.style.left = `${left - movementX}px`;
						panel.style.width = `${((timeDifferenceOfNextClip * singlePointDifferenceInTermsOfCoordinates) + panel.parentElement.parentElement.offsetWidth - left)}px`
						return;
					}
					if((left + movementX) <0 ) {
						if(panel.offsetWidth >= ((timeDifferenceOfNextClip * singlePointDifferenceInTermsOfCoordinates) + (timeDifferenceOfPrevClip * singlePointDifferenceInTermsOfCoordinates) + (panel.parentElement.parentElement.offsetWidth)))
						// panel.style.left = `${left + movementX}px`;

						// panel.style.width = `${((timeDifferenceOfNextClip * singlePointDifferenceInTermsOfCoordinates) + (timeDifferenceOfPrevClip * singlePointDifferenceInTermsOfCoordinates) + (panel.parentElement.parentElement.offsetWidth))}px`
						return;
					}
				} else if(prevTileAvailable && !nextTileAvailable) {				
					if((left + movementX) <= ((timeDifferenceOfPrevClip * singlePointDifferenceInTermsOfCoordinates) * -1)) {
						panel.style.left = `${left}px`;
						// panel.style.width = `${((timeDifferenceOfPrevClip * singlePointDifferenceInTermsOfCoordinates) + panel.parentElement.parentElement.offsetWidth - left)}px`
						return;
					}
				}
				panel.style.left = `${left - movementX}px`;
			}			
		}
		const { width, height, x, y } = panel.getBoundingClientRect();

		const resizeRight = () => {
			if(!nextTileAvailable) {
				if(width + movementX >= panel.parentElement.parentElement.offsetWidth + 1 || (width + left + movementX - 1) >= panel.parentElement.parentElement.offsetWidth) {
					return;
				}
			}			
			panel.style.width = `${width + movementX}px`;

			if (handle === "left") {
				setLeft(left - movementX);
				panel.style.left = `${left - movementX}px`;
			}
			
			calculateTimedelayedAndToBeDeducted(panel, width, left, movementX)
		};

		const resizeLeft = () => {
			if(!prevTileAvailable) {
				if(left < 0 && movementX < 0) {
					panel.style.left = '0px';
					return;
				}
			}
			
			if(left + movementX > panel.parentElement.parentElement.offsetWidth || width - Math.abs(movementX) > panel.parentElement.parentElement.offsetWidth && !prevTileAvailable) {
				return;
			}
			panel.style.width = `${width - movementX}px`;
			if (handle === "left") {
				setLeft(left + movementX);
				panel.style.left = `${left + movementX}px`;
				if(left + movementX < 0 && !prevTileAvailable) {
					setLeft(0);
					panel.style.left = '0px';
				}
				calculateTimedelayedAndToBeDeducted(panel, width,( left + movementX), movementX)
			}
		};

		switch (direction) {
			case Direction.Right:
				resizeRight();
				break;

			case Direction.Left:
				resizeLeft();
				break;

			default:
				break;
		}

		// setClicked((prev) => !prev);  //mycode
	};

	const calculateTimedelayedAndToBeDeducted = function(panel, width, left, movementX) {
		let secondsDelayed = Math.ceil((left) / singlePointDifferenceInTermsOfCoordinates);
		secondsDelayed = (secondsDelayed > 0? secondsDelayed * 1000: 0);
		const delaySecondsInMili = moment(startTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss']))
		let addingDelayToStart = delaySecondsInMili + secondsDelayed;
		if(addingDelayToStart > moment(endTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss']))) {
			addingDelayToStart = moment(endTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss']))
		}
		
		const milliSecondsToTimeStart = moment.utc(addingDelayToStart).format('HH:mm:ss');
		setStartClipTime(milliSecondsToTimeStart);

		let secondsToDeduct = Math.floor((panel.parentElement.parentElement.offsetWidth - (width + movementX) - (left - movementX)) / singlePointDifferenceInTermsOfCoordinates);
		secondsToDeduct = (secondsToDeduct > 0? secondsToDeduct * 1000: 0);
		const deductSecondsInMili = moment(endTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss']))
		const subtractingDelayFromStart = deductSecondsInMili - secondsToDeduct;
		const milliSecondsToTimeEnd = moment.utc(subtractingDelayFromStart).format('HH:mm:ss');
		setEndClipTime(milliSecondsToTimeEnd);

		if(nextTileAvailable && !prevTileAvailable) {
			let draggedEndTime = ((width + left)/singlePointDifferenceInTermsOfCoordinates) * 1000;
			let draggedToEndTime = delaySecondsInMili + draggedEndTime;
			setEndClipTime(moment.utc(draggedToEndTime).format('HH:mm:ss'));
		} else if(!nextTileAvailable && prevTileAvailable) {
			let draggedEndTime = (Math.abs(left)/singlePointDifferenceInTermsOfCoordinates) * 1000;
			let draggedToEndTime = delaySecondsInMili - draggedEndTime;
			setStartClipTime(moment.utc(draggedToEndTime).format('HH:mm:ss'));
		} else if(nextTileAvailable && prevTileAvailable) {
			let draggedPrevEndTime = (Math.abs(left)/singlePointDifferenceInTermsOfCoordinates) * 1000;
			let draggedToPrevEndTime = delaySecondsInMili - draggedPrevEndTime;
			if(left >= 0) {
				draggedToPrevEndTime = delaySecondsInMili + draggedPrevEndTime;
			}
			setStartClipTime(moment.utc(draggedToPrevEndTime).format('HH:mm:ss'));

			let draggedNextEndTime = ((width + left)/singlePointDifferenceInTermsOfCoordinates) * 1000;
			let draggedToNextEndTime = delaySecondsInMili + draggedNextEndTime;
			setEndClipTime(moment.utc(draggedToNextEndTime).format('HH:mm:ss'));
		}
	}

	useEffect(() => {
		const panel = panelRef.current;
		if(!isCreateClip) {
			panel.style.width = `${panel.parentElement.parentElement.offsetWidth}px`;
			// const currWidth = panel.offsetWidth;
			// const  newWidth = '500px';
			// panel.style.width = newWidth;
		} else {
			let currentPlayedSecondInFormat = moment.utc(currentPlayedSecond * 1000).format('HH:mm:ss');
			let timeDifference = (moment(startTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment(currentPlayedSecondInFormat, [moment.ISO_8601, 'HH:mm:ss']))/1000);
			let startClipTimeInMili = (moment(startClipTime, [moment.ISO_8601, 'HH:mm:ss']).diff(moment('00:00:00', [moment.ISO_8601, 'HH:mm:ss'])));
			setLeft(Math.abs(timeDifference) * singlePointDifferenceInTermsOfCoordinates);
			panel.style.left = `${Math.abs(timeDifference) * singlePointDifferenceInTermsOfCoordinates}px`
			panel.style.width = (singlePointDifferenceInTermsOfCoordinates * 10) + 'px';
			setEndClipTime(moment.utc((currentPlayedSecond * 1000) + 10000).format('HH:mm:ss'))
			if(left + panel.offsetWidth > panel.parentElement.parentElement.offsetWidth) {
				panel.style.width = `${panel.parentElement.parentElement.offsetWidth}px`;
				setEndClipTime(endTime)
			}
	    	// handleResize('right', singlePointDifferenceInTermsOfCoordinates * 10, '', 'right');
		}
  	}, [startTime]);

	return (
		<div
			className={`panel ${className} panel-for-time`}
			style={{ 
				position: "relative"
				// position: 'static'
			 }}
			ref={panelRef}
			onClick={onClick}
		>
			<div className="panel__container">
				<Resizer
					onResize={handleResize}
					RightComponent={RightComponent}
					LeftComponent={LeftComponent}
				/>
				{children}
			</div>
		</div>
	);
};

export default ResizeBox;
