import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import {  useDispatch ,useSelector} from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Button,CardButton,Heading,Icon,ProjectCard,Text, } from '../../common';
import PlusWhite from '../../images/plus-white.svg';
import PlusGreen from '../../images/plus-green.svg';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import { Dropdown } from '../../components/form';
import { REQUEST_WEBDASHBOARD_DATA } from '../../constants/dashboard/webDashboardConstants';

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

const ProjectsList = [
	{
		name: 'Vogue',
		progress: 1,
		url: '../../images/relaunch.png',
		stage: [
			{
				name: 'Setup',
				info: 'Overview',
				notification: 'Riya Rathod hasn’t added team members yet',
				progress: 25,
			},
		],
	},
	{
		name: 'Stay safe!',
		progress: 2,
		url: '../../images/diagnostic.png',
		stage: [
			{
				name: 'Recruit',
				info: '10/30 Respondents Recruited',
				notification: null,
				progress: 30,
			},
			{
				name: 'Design',
				info: '1/2 Tasks Designed',
				notification: null,
				progress: 45,
			},
		],
	},
	{
		name: 'Buying online',
		progress: 4,
		url: '../../images/relaunch.png',
		stage: [
			{
				name: 'Field',
				info: 'In Progress',
				notification: 'Task 1 : 20/30',
				progress: 70,
			},
		],
	},
	{
		name: 'Vogue',
		progress: 5,
		progressStatus: true,
		url: '../../images/relaunch.png',
		stage: [
			{
				name: 'Setup',
				info: 'Overview',
				notification: 'Riya Rathod hasn’t added team members yet',
				progress: 25,
			},
		],
	},
];

const Options = [
	{
		value: 'project_name',
		label: 'Project Name',
	},
	{
		value: 'project_status',
		label: 'Project Status',
	},
	{
		value: 'ongoing_first',
		label: 'Ongoing First',
	},
	{
		value: 'complete_first',
		label: 'Complete First',
	},
];

export default function Projects() {
    const [seeMore, setSeeMore] = useState(false);
    const dispatch = useDispatch(); 
    const userId = useSelector(state=>state.LoginReducer.userId);
	let [projectStatus, setprojectStatus] = useState();
	const [seefilterMore, setSeefilterMore] = useState(false);


	useEffect(() => {
		dispatch({
			type: REQUEST_WEBDASHBOARD_DATA,
			payload: {
				"user_ID":userId,
			}
		  });
	  },[]);
    projectStatus = useSelector(state=>state.webDashboardReducer.projectStatus);
	const projectStatus1 = projectStatus;
	

	const handleChange = (evt) => {
		setprojectStatus();
		
		projectStatus.map((stage, index) => {console.log("stage",
		stage.steps_array[stage.steps_array.length - 1].stepStatus
		)})
		if(evt.value == "project_name"){
	
			const sortedArr=  projectStatus.sort((a, b) => a.title.toUpperCase().localeCompare(b.title.toUpperCase(), "de", { sensitivity: "base" }));
			setprojectStatus(sortedArr);
		}else if(evt.value == "ongoing_first"){
		
		const sortedArr=  projectStatus.sort((a, b) => (a.steps_array[a.steps_array.length - 1].Step)-(b.steps_array[b.steps_array.length - 1].Step));
			
		setprojectStatus(sortedArr);
		}else if(evt.value == "complete_first"){
		
			const sortedArr=  projectStatus.sort((a, b) => (b.steps_array[b.steps_array.length - 1].Step)-(a.steps_array[a.steps_array.length - 1].Step));
				
			setprojectStatus(sortedArr);
		}else{
		
			setprojectStatus(projectStatus1);
		}
 
		  setSeefilterMore(!seefilterMore);
	
	};

	useEffect(() => {
	  },[projectStatus,projectStatus1]);

	return (
		<>
			<div className='row'>
				<div className='col-md-9'>
					<div className='d-flex align-items-start justify-content-start'>
						<Link to='/' className='mt-1'>
							<Button simple className='pr-0 pl-0'>
								<Icon
									color='var(--primaryColor)'
									style={{
										fontSize: '3rem',
										lineHeight: '1',
									}}
								>
									keyboard_arrow_left
								</Icon>
							</Button>
						</Link>
						<div className='mt-3'>
							<Heading className='d-flex align-items-center'>
								Projects
							</Heading>
							<Text type='3'>
								List of all the projects, ongoing and
								completed, your team has been a part of
							</Text>
						</div>
					</div>
				</div>
				<div className='col-md-3 d-flex justify-content-end'>
					<Link to='/project/create'>
						<CardButton>
							<img
								src={PlusGreen}
								alt='Plus Green'
								className='plus-green'
							/>{' '}
							<img
								src={PlusWhite}
								alt='Plus White'
								className='plus-white'
							/>{' '}
							Create a project
						</CardButton>
					</Link>
				</div>
			</div>

			<div className='projects-list mt-4'>
				<div className='d-flex justify-content-end align-items-center mb-3'>
					<Text className='mr-2' type='3'>
						Sort By
					</Text>
					<Dropdown
						selectedFontSize='0.875rem'
						options={Options}
						defaultValue={{
							value: 'project_status',
							label: 'Project Status',
						}}
						onChange={handleChange}
					/>
				</div>
				{/* {ProjectsList.map(
					
					
					
					(project, index) => (
					<ProjectCard
						key={index}
						className='mb-4'
						project={project}
					/>
				))} */}

{projectStatus?.filter((project,index) =>  seeMore ? true : index <= 1).map((project,index) => {return(<ProjectCard key={index} className="mb-4" project={project} />)})}

<SeeButton simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMore(!seeMore)}>{seeMore ? SeeLess() : SeeMore() }</SeeButton>
			</div>
		</>
	);
}
