import LoginActions from './login/LoginActions';
import ResetPasswordAction from './reset-password/ResetPasswordAction';
import TagActions from './tag/TagActions';
import QuestionActions from './question/QuestionActions';
import ProjectsetupAction from './invite-recruit/ProjectsetupAction';
import RecruitsGroupAction from './invite-recruit/RecruitsGroupAction';
import SendInviteAction from './invite-recruit/SendInviteAction';
import AddVideoTagAction from './field/AddVideoTagAction';
import AddVideoNoteAction from './field/AddVideoNoteAction';
import GetVideoTagAction from './field/GetVideoTagAction';
import GetVideoNoteAction from './field/GetVideoNoteAction';
import AddVideoTrimAction from './field/AddVideoTrimAction';
import GetAllTrimClipsAction from './field/GetAllTrimClipsAction';
import GetVideoAction from './field/GetVideoAction';
import RecruitScreener from './invite-recruit/RecruitScreener';
import SendRejectionAction from './invite-recruit/SendRejectionAction';
import GetInviteeAction from './invite-recruit/GetInviteeAction';
import ConfirmAndAssignAction from './invite-recruit/ConfirmAndAssignAction';
import ReassignProjectGroupsAction from './invite-recruit/ReassignProjectGroupsAction';
import ConfirmRespondentAction from './invite-recruit/ConfirmRespondentAction';
import ProjectActions from './project/ProjectActions';
import GetFieldTaskAction from './field/GetFieldTaskAction';
import GetTaskAction from './field/GetTaskAction';
import GetTaskStatusAction from './field/GetTaskStatusAction';
import GetVideoTaskStatusAction from './field/GetVideoTaskStatusAction';
import PublishAction from './publish/PublishAction';
import webDashboardAction from './dashboard/webDashboardAction';
import GetFieldOverviewAction from './field/GetFieldOverviewAction';
import GetCaptureGuideAction from './field/GetCaptureGuideAction';
import AddNewNoteAction from './field/AddNewNoteAction';
import GetRecordTaskVideoAction from './field/GetRecordTaskVideoAction';
import AddTimeVideoQuestionsAction from './field/AddTimeVideoQuestionsAction';
import FieldPreEditorAction from './field/FieldPreEditorAction';
import StartInterviewAction from './field/StartInterviewAction';
import EndInterviewAction from './field/EndInterviewAction';
import DeleteVideoNoteAction from './field/DeleteVideoNoteAction';
import DeleteVideoTagAction from './field/DeleteVideoTagAction';
import EditVideoNoteAction from './field/EditVideoNoteAction';
import EditButtonNameAction from './field/EditButtonNameAction';
import GetProfileAction from './profile/GetProfileAction';
import EditProfileAction from './profile/EditProfileAction';
import analysisAction from './analysis/analysisDataActions';
import PublishProjectAction from './publish/PublishProjectAction';
import graphDataAction from './analysis/graphDataActions';
import DeleteVideoClipAction from './field/DeleteVideoClipAction';
import ChangePasswordWebAction from './Security/ChangePasswordWebAction';
import EditClipAction from './field/EditClipAction';
import AddReschduleSlotAction from './field/AddReschduleSlotAction';
import endProjectAction from './dashboard/endProjectAction';
import AddRespondentAnswerAction from './field/AddRespondentAnswerAction';
import clientDashboardAction from './clientAdmin/clientDashboardAction';
import EditTeamdataAction from './clientAdmin/EditTeamdataAction';
import AddNewUserAction from './clientAdmin/AddNewUserAction';
import EditUserdataAction from './clientAdmin/EditUserdataAction';
import AddNewClientAdminAction from './superAdmin/AddNewClientAdminAction';
import SuperAdminDashboardAction from './superAdmin/SuperAdminDashboardAction';
import EditClientAdminAction from './superAdmin/EditClientAdminAction';
import ViewUserInGeneralPoolAction from './superAdmin/ViewUserInGeneralPoolAction';
import AddNewUserGenPoolAction from './superAdmin/AddNewUserGenPoolAction';
import EditResearcherDataGenPoolAction from './superAdmin/EditResearcherDataGenPoolAction';
import EditRespondantDataGenPoolAction from './superAdmin/EditRespondantDataGenPoolAction';
import ViewProjectSuperAdminDashboardAction from './superAdmin/ViewProjectSuperAdminDashboardAction';
import DeleteProjectAction from './clientAdmin/DeleteProjectAction';
import otpAction from './OTP/otpAction';
import otpVerifyAction from './otpVerify/otpVerifyAction';
import UpdatePasswordAction from './superAdmin/UpdatePasswordAction';
import ResetPasswordEmailNotificationAction from './superAdmin/ResetPasswordEmailNotificationAction';
import calenderDataAction from './dashboard/calenderDataAction';
import GetSummaryDataAction from './field/GetSummaryDataAction';
import generateSummaryActions from './analysis/generateSummaryActions';
import checkSummaryActions from './analysis/checkSummaryActions';
import updateLayoutActions from './field/StartInterviewAction';

export default {
  LoginActions,
  ResetPasswordAction,
  TagActions,
  QuestionActions,
  ProjectsetupAction,
  RecruitsGroupAction,
  SendInviteAction,
  AddVideoTagAction,
  AddVideoNoteAction,
  GetVideoTagAction,
  GetVideoNoteAction,
  GetVideoAction,
  AddVideoTrimAction,
  GetAllTrimClipsAction,
  RecruitScreener,
  SendRejectionAction,
  GetInviteeAction,
  ConfirmAndAssignAction,
  ReassignProjectGroupsAction,
  ConfirmRespondentAction,
  ProjectActions,
  GetFieldTaskAction,
  GetTaskAction,
  GetTaskStatusAction,
  GetVideoTaskStatusAction,
  PublishAction,
  webDashboardAction,
  GetFieldOverviewAction,
  GetCaptureGuideAction,
  AddNewNoteAction,
  GetRecordTaskVideoAction,
  AddTimeVideoQuestionsAction,
  FieldPreEditorAction,
  StartInterviewAction,
  EndInterviewAction,
  DeleteVideoNoteAction,
  DeleteVideoTagAction,
  EditVideoNoteAction,
  EditButtonNameAction,
  GetProfileAction,
  EditProfileAction,
  analysisAction,
  PublishProjectAction,
  analysisAction,
  graphDataAction,
  DeleteVideoClipAction,
  ChangePasswordWebAction,
  EditClipAction,
  AddReschduleSlotAction,
  endProjectAction,
  AddRespondentAnswerAction,
  clientDashboardAction,
  EditTeamdataAction,
  AddNewUserAction,
  EditUserdataAction,
  AddNewClientAdminAction,
  SuperAdminDashboardAction,
  EditClientAdminAction,
  ViewUserInGeneralPoolAction,
  AddNewUserGenPoolAction,
  EditResearcherDataGenPoolAction,
  EditRespondantDataGenPoolAction,
  ViewProjectSuperAdminDashboardAction,
  DeleteProjectAction,
  otpAction,
  otpVerifyAction,
  UpdatePasswordAction,
  ResetPasswordEmailNotificationAction,
  calenderDataAction,
  GetSummaryDataAction,
  generateSummaryActions,
  checkSummaryActions,
  updateLayoutActions
};
