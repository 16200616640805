import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as analysisMethods from '../../utils/api/analysis/analysisData';

function* analysisData(payload) {
  const promise = analysisMethods.analysisData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.analysisAction.ANALYSIS_DATA_RECEIVED, data: res.Response });
}
function* analysisCsv(payload) {
  const promise = analysisMethods.analysisCsv(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.analysisAction.ANALYSIS_CSV_RECEIVED, data: res.Response });
}
function* analysisCsvWithCode(payload) {
  const promise = analysisMethods.analysisCsvWithCode(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.analysisAction.ANALYSIS_CSV_RECEIVED_WITH_CODE, data: res.Response });
}
export  { analysisData ,analysisCsv, analysisCsvWithCode}