import React from 'react';
import { useState } from 'react';
import { Button, Text, Avatar, Heading } from '../../';
import styled from '@emotion/styled';
import {
    InputText,
    TextArea,
    DropFile,
    Dropdown,
    ButtonRadio,
    TaskTypeOption,
    TaskMenu,
} from '../../../components/form';
import Divider from '../../Divider';
import Icon from '../../Icon'
import cross from '../../../assets/images/cross.svg';
import GreenDropdown from '../../../assets/images/green-dropdown.svg';
import profile_pic from '../../../assets/images/profile_pic.png';
import PlusGreyBold from '../../../assets/images/plus-grey-bold.svg';
import AddProfilePic from '../../../assets/images/addProfilePic.svg'
import ChangeProfilePicModal from '../ChangeProfilePicModal';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_ADDNEWCLIENT, ADDNEWCLIENT_RECEIVED } from '../../../constants/SuperAdmin/AddNewClientAdminConstants';



const field = {
    marginLeft: "0px"
}

const InputTextContainer = styled.div`
	.iconContainer {
		position: absolute;
		top: 30%;
		right: 15px;
	}
	span {
		font-size: 18px;
		color: var(--greyBlue);
	}
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;
const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	float: right;
    margin-right: 5px;
`;

const Pill = styled.span`
	font-size: 9px;
	font-family: "Readex Pro", sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
`;

const gender_list = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' },
];

const city_list = [
    { value: 1, label: 'Ahmedabad' },
    { value: 2, label: 'Bangalore' },
    { value: 3, label: 'Mumbai' },
    { value: 4, label: 'Kolkata' },
    { value: 5, label: 'Delhi' },
];

const qualification_list = [
    { value: 1, label: 'Illiterate' },
    { value: 2, label: 'Literate but no formal schooling' },
    { value: 3, label: 'School (5-9 yrs)' },
    { value: 4, label: 'SSC/HSC' },
    { value: 5, label: 'Some College(Including a diploma but not graduate)' },
    { value: 6, label: 'Graduate/Post-Graduate: General' },
    { value: 7, label: 'Graduate/Post-Graduate: Professional' },
];

const subscription_list = [
    { value: 1, label: 'A30' },
    { value: 2, label: 'A50' },
    { value: 3, label: 'A100' },
    { value: 4, label: 'A400' },
];

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function AddNewClientAdminModal() {

    const [seeMoreProfile, setSeeMoreProfile] = useState(true);
    const [seeMoreLicense, setSeeMoreLicense] = useState(false);
    const [seeMoreBilling, setSeeMoreBilling] = useState(false);
    const [clientAdminData, setClientAdminData] = useState([]);
    // const [userType, setUserType] = useState('');

    const dispatch = useDispatch();

    const handleChange = (evt) => {
        const value = evt.target.value;

        setClientAdminData({
            ...clientAdminData,
            [evt.target.name]: value
        })
    }
    // console.warn(clientAdminData);

    const addClientAdmin = () => {
        const formData = {
            name: clientAdminData.client_name,
            CompanyName: clientAdminData.company_name,
            email: clientAdminData.email,
            mobile: clientAdminData.phone_no,
            password: "1234",
            city: clientAdminData.city,
            pincode: clientAdminData.pincode,
            address: clientAdminData.company_address,
            subscription_plan: clientAdminData.subscription_plan,
            validFrom: clientAdminData.valid_from,
            validTo: clientAdminData.valid_till,
            HoursAllocated: '4',
            accountName: clientAdminData.accountName,
            accountNumber: clientAdminData.accountNumber,
            branchName: clientAdminData.branchName,
            swiftCode: clientAdminData.swiftCode,
            sortCode: clientAdminData.sortCode,
            IFSCCode: clientAdminData.IFSCCode,
            license: [
                {
                    FullName: clientAdminData.fullname1,
                    TeamName: clientAdminData.team_name_1,
                    email: clientAdminData.team_email_1,
                    phone: clientAdminData.team_mobile_1,
                    password: "license1",
                    desigination: clientAdminData.designation_1,
                    identity_id: "1",
                    division: clientAdminData.division_name_1
                },
                {
                    FullName: clientAdminData.fullname2,
                    TeamName: clientAdminData.team_name_2,
                    email: clientAdminData.team_email_2,
                    phone: clientAdminData.team_mobile_2,
                    password: "license2",
                    desigination: clientAdminData.designation_2,
                    identity_id: "2",
                    division: clientAdminData.division_name_2
                },
                {
                    FullName: clientAdminData.fullname3,
                    TeamName: clientAdminData.team_name_3,
                    email: clientAdminData.team_email_3,
                    phone: clientAdminData.team_mobile_3,
                    password: "license3",
                    desigination: clientAdminData.designation_3,
                    identity_id: "3",
                    division: clientAdminData.division_name_3
                }
            ]

        }

        // console.warn(formData);
        dispatch({
            type: REQUEST_ADDNEWCLIENT,
            payload: formData
        });
    }

    return (
        <div>
            <>

                {/* <Button
                    className='ml-4'
                    size='small'
                    variant='outlined'
                    width='50px'
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                >
                    edit
                </Button> */}

                <div className="modal fade" id="addNewClientAdminModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" >
                                <Heading fontSize='20px' className='mt-2 ml-2'> New Client Admin
                                    {/* <img
                                        src={cross}
                                        className='ml-1 mb-1'
                                        style={{ cursor: 'pointer', borderRadius: '50%' }}
                                    /> */}
                                </Heading>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-1'
                                            data-toggle='modal'
                                            data-target="#changeProfilePicModal">
                                            <img src={AddProfilePic} style={{ position: 'absolute', marginTop: '45px' }} />
                                            <Avatar
                                                src={profile_pic}
                                                className='team-profile-pic'
                                                width='60'
                                                height='60'
                                            />
                                        </div>
                                        {/* <Button
                                            data-toggle='modal'
                                            data-target="#changeProfilePicModal"
                                        >CLick ME</Button> */}

                                        <div className='container w-75'>
                                            <div className='row'>
                                                {/* Profile Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Profile
                                                    </Heading>
                                                    {seeMoreProfile == true ? (
                                                        <>
                                                            <InputTextContainer>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='client_name'
                                                                            placeholder='Name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Rohit Nimbalkar'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='phone_no'
                                                                            placeholder='Phone No.'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='7894563210'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='email'
                                                                            placeholder='Email ID'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='29-10-1990'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='company_name'
                                                                            placeholder='Company name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Rohit Nimbalkar'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-12'>
                                                                        <InputText
                                                                            name='company_address'
                                                                            placeholder='Company Address'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='The Qube, Moral Naka, Andheri East'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <Dropdown
                                                                            name='city'
                                                                            placeholder='City'
                                                                            showCharacters={false}
                                                                            options={city_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setClientAdminData({
                                                                                    ...clientAdminData,
                                                                                    'city': evt.label
                                                                                })
                                                                            }
                                                                        // value='Mumbai'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        {/* <Icon className='iconContainer'>create</Icon> */}
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='pincode'
                                                                            placeholder='Pincode'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='7894563210'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>

                                                                </div>



                                                            </InputTextContainer>
                                                        </>
                                                    ) : null
                                                    }
                                                </div>
                                                {/* END */}
                                            </div>
                                            {/* See more Button */}
                                            <SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreProfile(!seeMoreProfile)}>{seeMoreProfile ? SeeLess() : SeeMore()}</SeeButton>
                                            {/* END */}

                                            <Divider
                                                margin='0'
                                                height='2px'
                                                width='103%'
                                                color='#38454F'
                                                className='mt-2'
                                            />

                                            <div className=''>
                                                {/* License Details */}
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <Heading
                                                                fontSize='14px'
                                                                className='mt-3'
                                                            >
                                                                Licenses & Subscription
                                                            </Heading>
                                                        </div>
                                                        <div className='col-6 d-flex' style={{ justifyContent: 'end' }}>
                                                            <Heading
                                                                fontSize='12px'
                                                                className='mt-3 ml-3'
                                                            >
                                                                <img
                                                                    src={PlusGreyBold}
                                                                    width='20px'
                                                                    height='15px'
                                                                    className='mr-1 mb-1'
                                                                />
                                                                Add License
                                                            </Heading>
                                                        </div>
                                                    </div>
                                                    {seeMoreLicense == true ? (
                                                        <>
                                                            <InputTextContainer>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4 mt-3 mb-3'>
                                                                        <Text type='7'>License 1</Text>
                                                                    </div>
                                                                    <div className='col-4 mt-3 mb-3'>
                                                                        <Text type='7'>License 2</Text>
                                                                    </div>
                                                                    <div className='col-4 mt-3 mb-3'>
                                                                        <Text type='7'>License 3</Text>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_name_1'
                                                                            placeholder='Team name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_name_2'
                                                                            placeholder='Team name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_name_3'
                                                                            placeholder='Team name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='fullname1'
                                                                            placeholder='Full name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='fullname2'
                                                                            placeholder='Full name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='fullname3'
                                                                            placeholder='Full name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='designation_1'
                                                                            placeholder='Designation'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='designation_2'
                                                                            placeholder='Designation'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='designation_3'
                                                                            placeholder='Designation'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_email_1'
                                                                            placeholder='Email ID'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_email_2'
                                                                            placeholder='Email ID'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_email_3'
                                                                            placeholder='Email ID'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_mobile_1'
                                                                            placeholder='Phone no.'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_mobile_2'
                                                                            placeholder='Phone no.'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='team_mobile_3'
                                                                            placeholder='Phone no.'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='division_name_1'
                                                                            placeholder='Division Name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='division_name_2'
                                                                            placeholder='Division Name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='division_name_3'
                                                                            placeholder='Division Name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Bussiness'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-4' style={field}>
                                                                    <div className='col-6'>
                                                                        <Dropdown
                                                                            name='subscription_plan'
                                                                            placeholder='Subscription Plan'
                                                                            showCharacters={false}
                                                                            options={subscription_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setClientAdminData({
                                                                                    ...clientAdminData,
                                                                                    'subscription_plan': evt.label
                                                                                })
                                                                            }
                                                                        // value='Mumbai'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        {/* <Icon className='iconContainer'>create</Icon> */}
                                                                    </div>
                                                                    <div className='col-1'>
                                                                    </div>
                                                                    <div className='col-5 mt-2 d-flex' style={{ justifyContent: "end" }}>
                                                                        <Text type='7'>Subscription Status: Active</Text>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2' style={field}>
                                                                    <div className='col-6 mt-2'>
                                                                        <Text type='7'>Hours used: 0 hrs</Text>
                                                                    </div>
                                                                    <div className='col-1'>
                                                                    </div>
                                                                    <div className='col-5 mt-2 d-flex' style={{ justifyContent: "end" }}>
                                                                        <Text type='7'>Hours Left: 0 hrs</Text>
                                                                    </div>
                                                                </div>

                                                                <div className='row mt-3' style={field}>
                                                                    <div className='col-6 mt-2'>
                                                                        <Text type='7'>Period of Subscription : </Text>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-6'>
                                                                        <text style={{ fontSize: '10px' }}><strong>Valid from : </strong>
                                                                            <input type='date' style={{
                                                                                boxShadow: 'inset 0px 0px 2px 1.5px rgb(0 0 0 / 15%)',
                                                                                width: '120px',
                                                                                borderRadius: "3px",
                                                                                border: 'navajowhite'
                                                                            }}
                                                                                name='valid_from'
                                                                                onChange={handleChange}
                                                                            />
                                                                        </text>
                                                                    </div>
                                                                    <div className='col-1'>
                                                                    </div>
                                                                    <div className='col-5 mt-1 d-flex' style={{ justifyContent: "end" }}>
                                                                        <text style={{ fontSize: '10px' }}><strong>Valid till : </strong>
                                                                            <input type='date' style={{
                                                                                boxShadow: 'inset 0px 0px 2px 1.5px rgb(0 0 0 / 15%)',
                                                                                width: '120px',
                                                                                borderRadius: "3px",
                                                                                border: 'navajowhite'
                                                                            }}
                                                                                name='valid_till'
                                                                                onChange={handleChange}
                                                                            />
                                                                        </text>
                                                                    </div>
                                                                </div>

                                                            </InputTextContainer>
                                                        </>

                                                    ) : null
                                                    }
                                                </div>
                                                {/* END */}
                                            </div>
                                            {/* See more Button */}
                                            <SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreLicense(!seeMoreLicense)}>{seeMoreLicense ? SeeLess() : SeeMore()}</SeeButton>
                                            {/* END */}

                                            <Divider
                                                margin='0'
                                                height='2px'
                                                width='103%'
                                                color='#38454F'
                                                className='mt-2'
                                            />

                                            <div className='row'>
                                                {/* Billing Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Billing & Payments Info
                                                    </Heading>
                                                    {seeMoreBilling == true ? (
                                                        <>
                                                            <InputTextContainer>
                                                                <div className='row' style={field}>
                                                                    <div className='col-6'>
                                                                        <InputText
                                                                            name='accountName'
                                                                            placeholder='Account name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Rohit Nimbalkar'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <InputText
                                                                            name='accountNumber'
                                                                            placeholder='Account Number'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='7894563210'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-6'>
                                                                        <InputText
                                                                            name='bank_name'
                                                                            placeholder='Select Bank'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Rohit Nimbalkar'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <InputText
                                                                            name='branchName'
                                                                            placeholder='Branch Name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='7894563210'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-6'>
                                                                        <InputText
                                                                            name='swiftCode'
                                                                            placeholder='Swift Code'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Rohit Nimbalkar'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <InputText
                                                                            name='sortCode'
                                                                            placeholder='Sort Code'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='7894563210'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-6'>
                                                                        <InputText
                                                                            name='IFSCCode'
                                                                            placeholder='IFSC Code'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // value='Rohit Nimbalkar'
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>

                                                                </div>






                                                            </InputTextContainer>
                                                        </>
                                                    ) : null
                                                    }
                                                </div>
                                                {/* END */}
                                            </div>
                                            {/* See more Button */}
                                            <SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreBilling(!seeMoreBilling)}>{seeMoreBilling ? SeeLess() : SeeMore()}</SeeButton>
                                            {/* END */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer border-0">
                            </div>
                            {/* <div className='container-fluid'> */}
                            <div className='row'>
                                <div className='col-3 modal-footer border-0 ml-4' style={{ justifyContent: "flex-start" }}>
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        // variant='outlined'
                                        width='60px'
                                        // style={{ color: "#798E8F", background: "none" }}
                                        onClick={addClientAdmin}
                                    >
                                        Save
                                    </CustomButton>
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Cancel
                                    </CustomButton>
                                </div>
                                {/* <div className='col-8'>
                                    <CustomButton
                                        className='ml-2 mt-3'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-toggle="modal" data-target="#DeleteModalRespondant" data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Delete
                                    </CustomButton>
                                </div> */}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                {/* New Modal for Delete User  */}
                <div className="modal fade" id="DeleteModalRespondant" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" style={{ paddingBottom: "0px" }}>
                                <Heading fontSize='18px'>Confirm Delete</Heading>
                                <button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                <div className='row'>
                                    <div className='col col-12'>
                                        <Text type='7' color="var(--ternaryColor)" className="mb-0">Are you sure you want to delete this Respondent?</Text>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingTop: '20px' }}>
                                    <div className='col'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            variant='outlined'
                                            width='60px'
                                            // width='5px'
                                            // height='4px'
                                            fontSize='10px'
                                            style={{ float: "left" }}
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </CustomButton>
                                    </div>
                                    <div id='delete' className='col col-4 col-offset-6'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            // variant='outlined'
                                            width='60px'
                                            data-toggle="modal"
                                            data-target="#UserDeletedModal" data-dismiss="modal"
                                        // style={{ color: "#798E8F", background: "none" }}
                                        >
                                            Delete
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer border-0">
                                <div className='row'>
                                    <div className='col col-4 col-offset-6'>
                                        <Button
                                            className='ml-4'
                                            size='small'
                                            variant='outlined'
                                            width='20px'

                                        >
                                            save changes
                                        </Button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* END */}

                {/* New Modal for change Profile picture */}
                <ChangeProfilePicModal />
                {/* END */}
            </>
        </div >
    )
}
