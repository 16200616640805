import actions from '../../actions';

// reducer with initial state
const initialState = {
  fieldActivity_data: [],
  scheduleDates_data: [],
  researcher_data: [],
  duration_data: {},
  minimumSlotRequired: [],
  bookedSlot: [],
  projectTask: [],
  ProjectDetails: [],
  add_slot_status: {},
  add_status: {},
  addSection: null,
  slotDeleteStatus: {}

  
};

const projectReducer = (state = initialState, action = {}) => {

  switch (action.type) {
    case actions.ProjectActions.REQUEST_SCHEDULE_DATES_RECEIVED:
      return {
        ...state,
        fieldActivity_data: action.data.fieldActivity_data,
        scheduleDates_data: action.data.scheduleDates_data,
        researcher_data: action.data.researcher_data,
        duration_data: action.data.duration_data[0] ? action.data.duration_data[0] : {},
        minimumSlotRequired: action.data.minimumSlotRequired,
        bookedSlot: action.data.bookedSlot,
        projectTask: action.data.projectTask,
        ProjectDetails: action.data.ProjectDetails[0] ? action.data.ProjectDetails[0] : {}
      };

    case actions.ProjectActions.ADD_SCHEDULE_DATE_RECEIVED:
      return {
        ...state,
        // fieldActivity_data: action.data.fieldActivity_data,
        // scheduleDates_data: action.data.scheduleDates_data,
        // researcher_data: action.data.researcher_data,
        add_slot_status: action.data
      };
    case actions.ProjectActions.SAMPLE_AND_BUDGET_RECEIVED:
      return {
        ...state,
        add_status: action.data
      };
    case actions.ProjectActions.RESET_ADD_SLOT_STATUS:
      return {
        ...state,
        add_slot_status: ''
      };

    case actions.ProjectActions.RESET_DELETE_SLOT_STATUS:
      return {
        ...state,
        slotDeleteStatus: ''
      };

    case actions.ProjectActions.EDIT_SCHEDULE_DATE_RECEIVED:
      return {
        ...state,
        fieldActivity_data: action.data.fieldActivity_data,
        scheduleDates_data: action.data.scheduleDates_data,
        researcher_data: action.data.researcher_data
      };

    case actions.ProjectActions.DELETE_SCHEDULE_DATE_RECEIVED:
      return {
        ...state,
        fieldActivity_data: action.data.fieldActivity_data,
        scheduleDates_data: action.data.scheduleDates_data,
        researcher_data: action.data.researcher_data,
        slotDeleteStatus: action.data.Status
      };
    case actions.ProjectActions.RECIEVED_UPDATE_DURATION:
      return {
        ...state
      };

    case actions.ProjectActions.REQUEST_EDIT_SCREENER_ANSWER:
      return {
        ...state
      };

    case actions.ProjectActions.EDIT_SCREENER_ANSWER_RECEIVED:
      return {
        ...state
      };

    case actions.ProjectActions.EDIT_AUTOMATED_SCREENER_RECEIVED:
      return {
        ...state
      };
 

    default:
      return state;
  }
};

export default projectReducer;
