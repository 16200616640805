import React, { useState } from 'react';

const Dropdown = ({ iconSrc, items, onChange, cssClass, selected = '', defaultState = false , whiteSpace = 'pre'}) => {
  const [isOpen, setIsOpen] = useState(defaultState);
  const [selectedItem, setSelectedItem] = useState(selected ? selected : null);
  const [isActive, setIsActive] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item, index) => {
    setSelectedItem(item);
    setIsOpen(false);
    if (onChange) {
      onChange(item);
    }
  };

  return (
    <div className={cssClass == undefined || cssClass == '' ? 'dropdownIcon' : `dropdownIcon ${cssClass}`}>
      <div className="dropdownIcon-button" onClick={toggleDropdown}>
        {cssClass == undefined || cssClass == '' ? (
          ''
        ) : selectedItem ? (
          <button style={{ color: '#FFFFFF', background: 'transparent', border: 'none', fontSize: '12px' }}>
            {selectedItem?.label.split('✔')[0]}
          </button>
        ) : (
          <button style={{ color: '#FFFFFF', background: 'transparent', border: 'none', fontSize: '12px' }}>
            Set Language
          </button>
        )}

        {iconSrc && (
          <img
            src={iconSrc}
            width={cssClass == undefined || cssClass == '' ? '38px' : '60px'}
            height={cssClass == undefined || cssClass == '' ? '28px' : '36px'}
            alt="Dropdown Icon"
          />
        )}
      </div>
      {isOpen && (
        <div className={cssClass == undefined || cssClass =='' ? 'dropdownIcon-menu': `dropdownIcon-menu-index`} >
          {items.map((item, index) => (
            <div
              key={index}
              className={(index >0 ? 'dropdownIcon-item border-top': 'dropdownIcon-item defaultItem') + (selectedItem && selectedItem.label === item.label ? ' selectItemDropdown':'')}
              onClick={index > 0 ? () => handleItemClick(item, index) : null}
              style={{whiteSpace: 'pre'}}
              // style={selectedItem && selectedItem.label === item.label ? 'color:"red"': ''}
            >
              {item.label}
              
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
