import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddNewUserMethods from '../../utils/api/clientAdmin/AddNewUserData';

function* AddNewUserData(payload) {
  const promise = AddNewUserMethods.AddNewUserData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddNewUserAction.ADDNEWUSER_RECEIVED, data: res.Status });
}

export  { AddNewUserData }