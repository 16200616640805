const colors = {
	Basic: '#2361A1',
	Travel: '#E55555',
	Professional: '#A6A6A6',
};

const data = [
	{
		name: 'After Every Wash',
		Basic: 5,

		Travel: 6,

		Professional: 2,
	},
	{
		name: 'Daily',
		Basic: 4,

		Travel: 3,

		Professional: 2,
	},
	{
		name: 'Special Occasions',
		Basic: 2,

		Travel: 4,

		Professional: 2,
	},
];

const keys = ['Basic', 'Travel', 'Professional'];

const groupedData1 = [
	{
		'After Every Wash': [
			{
				x: 'After Every Wash',
				y: 5,
			},
			{
				x: 'Daily',
				y: 2,
			},
			{
				x: 'Special Occasions',
				y: 1,
			},
		],
	},
	{
		Daily: [
			{
				x: 'After Every Wash',
				y: 5,
			},
			{
				x: 'Daily',
				y: 2,
			},
			{
				x: 'Special Occasions',
				y: 1,
			},
		],
	},
	{
		'Special Occasions': [
			{
				x: 'After Every Wash',
				y: 5,
			},
			{
				x: 'Daily',
				y: 2,
			},
			{
				x: 'Special Occasions',
				y: 3,
			},
		],
	},
];

const groupedData2 = [
	[
		{
			x: 'After Every Wash',
			y: 5,
		},
		{
			x: 'Daily',
			y: 2,
		},
		{
			x: 'Special Occasions',
			y: 1,
		},
	],
	[
		{
			x: 'After Every Wash',
			y: 1,
		},
		{
			x: 'Daily',
			y: 4,
		},
		{
			x: 'Special Occasions',
			y: 2,
		},
	],
	[
		{
			x: 'After Every Wash',
			y: 5,
		},
		{
			x: 'Daily',
			y: 2,
		},
		{
			x: 'Special Occasions',
			y: 3,
		},
	],
];
const words = [
	{
		text: 'Parting',
		value: 60,
	},
	{
		text: 'Attachment',
		value: 36,
	},
	{
		text: 'Section',
		value: 60,
	},
	{
		text: 'Clip',
		value: 60,
	},

	{
		text: 'Lift',
		value: 48,
	},
	{
		text: 'Viewing',
		value: 24,
	},
	{
		text: 'Fast-Dry',
		value: 36,
	},
	{
		text: 'Heat',
		value: 48,
	},
	{
		text: 'Pull',
		value: 36,
	},
	{
		text: 'Angle',
		value: 36,
	},
	{
		text: 'Tie',
		value: 48,
	},
	{
		text: 'Back Mirror',
		value: 24,
	},
];

const stackedBarChartData = [
	[
		{ x: 'After Every Wash', y: 5 },
		{ x: 'Regular Use', y: 4 },
		{ x: 'Special Occasions Only', y: 2 },
	],
	[
		{ x: 'After Every Wash', y: 6 },
		{ x: 'Regular Use', y: 3 },
		{ x: 'Special Occasions Only', y: 4 },
	],
	[
		{ x: 'After Every Wash', y: 2 },
		{ x: 'Regular Use', y: 2 },
		{ x: 'Special Occasions Only', y: 2 },
	],
];

const colorData = ['#2361A1', '#E55555', '#A6A6A6'];

export {
	data,
	keys,
	colors,
	groupedData1,
	groupedData2,
	words,
	stackedBarChartData,
	colorData,
};
