import styled from '@emotion/styled';

const Progress = styled.div`
    border-radius: 1rem;
    height: 0.7rem;
    background-color: #e2e2e2;
    margin-bottom: 5px;

    .progress-bar {
        width: ${props => props.width+'%' || '0%'};
        background-color: var(--progressColor);
        border-radius: 1rem;
    }
`

export default function ProgressBar({ width = 0, className="" }) {
    return (
        <Progress className={`progress ${className}`} width={width}>
            <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </Progress>
    )
}