import styled from '@emotion/styled';

const StyledMenu = styled.span`

background: ${props =>  props.background || "var(--white)"};
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: space-between;
border: 1px solid #EFEFEF;
padding: 0.25rem 0.75rem;

hr {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--quadColor);
    padding: 0.25rem;
    text-align: center;
}
`

export default function TaskMenu({ children, className="", width="", background="" }) {
    return <StyledMenu className={className} background={background} style={{width:width}}>{children}</StyledMenu>
}