import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as SendRejectionMethods from '../../utils/api/invite-recruit/SendRejectionData';

function* SendRejectionData(data) {
  const promise = SendRejectionMethods.SendRejectionData(data.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.SendRejectionAction.REJECTION_RESPONSE_RECEIVED, data: res.Status });
}

export  { SendRejectionData }