import React from 'react';
import styled from '@emotion/styled';
import { Text, Divider, Icon } from './';

const StyleDiv = styled.div`
	.divider {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
`

export default function Overview({
	title = '',
	type = 'Form',
	taskLength = '',
	respondents = '0',
	voucher = '',
	budget = '',
    icon = 'description',
	rewardDescription
}) {
	return (
		<StyleDiv className="row mt-2">
			<div className="col-md-3 d-flex align-items-center justify-content-start">
				<Text type="2">{title}</Text>
				<Divider margin="0" orientation="vertical" height="20px" width="1px" />
			</div>
			<div className="col-md-1  ">
				<div className="d-flex align-items-center justify-content-around">
					<div className="d-flex align-items-center">
                     <Icon className="mr-1">{icon}</Icon>
                     <Text type="5">{type}</Text>
					</div>
					<Divider margin="0" orientation="vertical" height="20px" width="1px" />
				</div>
			</div>
			<div className="col-md-2 d-flex align-items-center justify-content-around">
				<Text type="5">Task Length - <Text bold span fontWeight="600">{taskLength}</Text></Text>
				<Divider margin="0" orientation="vertical" height="20px" width="1px" />
			</div>
			<div className="col-md-2 d-flex align-items-center justify-content-around">
				<Text type="5">{respondents} Respondents</Text>
				<Divider margin="0" orientation="vertical" height="20px" width="1px" />
			</div>
			<div className="col-md-2 d-flex align-items-center justify-content-around">
				<Text type="5">{rewardDescription} -<Text bold span fontWeight="600">{voucher}</Text></Text>
				<Divider margin="0" orientation="vertical" height="20px" width="1px" />
			</div>
			<div className="col-md-2 ">
            <div className="d-flex ">
				<Text type="5">Total Budget -  <Text bold span fontWeight="600">{budget}</Text></Text>
            </div>
			</div>
		</StyleDiv>
	);
}
