import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddNewUserGenPoolMethods from '../../utils/api/superAdmin/AddNewUserGenPoolData';

function* AddNewUserGenPoolData(payload) {
  const promise = AddNewUserGenPoolMethods.AddNewUserGenPoolData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddNewUserGenPoolAction.ADDNEWUSERGENPOOL_RECEIVED, data: res.Response });
}

export  { AddNewUserGenPoolData }