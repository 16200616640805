import maverickPortalhttp from '../../webService/webService';
import maverickPortalApiEndPoint from '../../../config/apiEndPoint';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ forceRefresh: true });
const _data = {
  user_ID: 18,
  project_ID: '76',
  respondentUser_ID: '156'
};

const createProject = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.createProject.url,
        maverickPortalApiEndPoint.createProject.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const editProject = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(maverickPortalApiEndPoint.editProject.url, maverickPortalApiEndPoint.editProject.methodType, data)
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const deleteReferral = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteReferral.url,
        maverickPortalApiEndPoint.deleteReferral.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const preProjectData = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.ProjectSetupData.url + '/' + localStorage.userId,
        maverickPortalApiEndPoint.ProjectSetupData.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const deleteProjectImage = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteProjectImage.url,
        maverickPortalApiEndPoint.deleteProjectImage.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const addProjectNewTask = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.addProjectTask.url,
        maverickPortalApiEndPoint.addProjectTask.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const deleteProjectTask = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteProjectTask.url,
        maverickPortalApiEndPoint.deleteProjectTask.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const updateProjectTaskData = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.updateProjectTask.url,
        maverickPortalApiEndPoint.updateProjectTask.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const addProjectLead = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.createProjectLead.url,
        maverickPortalApiEndPoint.createProjectLead.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const addProjectResearcher = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.addProjectResercher.url,
        maverickPortalApiEndPoint.addProjectResercher.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const addProjectObserver = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.addProjectObserver.url,
        maverickPortalApiEndPoint.addProjectObserver.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const getCriteriaAndBudget = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.getCriteriaAndBudget.url + '/' + data.user_ID + '/' + data.project_ID,
          maverickPortalApiEndPoint.getCriteriaAndBudget.methodType
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            // history.push('criteria-and-budget')
            // window.location.reload();
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const updateProjectStage = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.updateProjectStage.url,
        maverickPortalApiEndPoint.updateProjectStage.methodType,
        data
      )
      .then(response => {
        if (response.data != null && response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};

const getResolution = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.getResolution.url,
          maverickPortalApiEndPoint.getResolution.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            // history.push('criteria-and-budget')
            // window.location.reload();
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const getParticipant = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.getParticipant.url,
          maverickPortalApiEndPoint.getParticipant.methodType,
          data
        )
        .then(response => {
          if (response.data?.Status?.StatusDesc == 'Not Allowed. We have reach Maximum number Participant.') {
            console.log('Not Allowed. We have reach Maximum number Participant.');
          } else if (response.data?.Response !== undefined) {
          } else if (response.data?.Status && response.data?.Status?.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status?.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status?.StatusDesc ? response.data?.Status?.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const SendParticpantListOnEnd = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.sendParticipanstListonEnd.url,
          maverickPortalApiEndPoint.sendParticipanstListonEnd.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};
const removeVideoCall = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.removeVideoCall.url,
          maverickPortalApiEndPoint.removeVideoCall.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            // history.push('criteria-and-budget')
            // window.location.reload();
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const GetEditSection = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.GetEditSection.url,
          maverickPortalApiEndPoint.GetEditSection.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            //  history.push(`/project/${data.project_ID}/criteria-and-budget`);
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const updateDuration = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.updateDuration.url,
          maverickPortalApiEndPoint.updateDuration.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            //  history.push(`/project/${data.project_ID}/criteria-and-budget`);
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const GetAddSection = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.GetAddSection.url,
          maverickPortalApiEndPoint.GetAddSection.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            //  history.push(`/project/${data.project_ID}/criteria-and-budget`);
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const GetDragQuestion = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.GetDragQuestion.url,
          maverickPortalApiEndPoint.GetDragQuestion.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            //  history.push(`/project/${data.project_ID}/criteria-and-budget`);
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const GetDragQuestionAcrossSection = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.GetDragQuestionAcrossSections.url,
          maverickPortalApiEndPoint.GetDragQuestionAcrossSections.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            //  history.push(`/project/${data.project_ID}/criteria-and-budget`);
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const EditScreenerAnswer = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.editScreenerAnswer.url,
        maverickPortalApiEndPoint.editScreenerAnswer.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const CountNoParticipant = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.CountNoParticipant.url,
          maverickPortalApiEndPoint.CountNoParticipant.methodType,
          data
        )
        .then(response => {
          console.log('response', response.data.Status.StatusDesc);
          if (response.data.Status.StatusDesc == 'Not Allowed. We have reach Maximum number Participant.') {
            console.log('Not Allowed. We have reach Maximum number Participant.');
          } else if (response.data.Response !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const VideoRecording = (data, token) => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.VideoRecording.url,
          maverickPortalApiEndPoint.VideoRecording.methodType,
          data,
          token
        )
        .then(response => {
          window.$resourceId = response.data.resourceId; //global variable
          const resourceid = window.$resourceId;

          if (response.data.resourceId) {
            const resource = {
              cname: data.cname,
              uid: data.uid,
              clientRequest: {
                // "token": "<token if any>",
                // "extensionServiceConfig": {
                //   "errorHandlePolicy": "error_abort",
                //   "extensionServices": [{
                //     "serviceName": "web_recorder_service",
                //     "errorHandlePolicy": "error_abort",
                //     "serviceParam": {
                //       "url": "https://aws.amazon.com",
                //       "audioProfile": 0,
                //       "videoWidth": 1280,
                //       "videoHeight": 720,
                //       "maxRecordingHour": 72
                //     }
                //   }]
                // },
                // "maxIdleTime": 30,
                // "transcodingConfig": {
                //   "height": 480,
                //   "width": 640,
                //   "bitrate": 500,
                //   "fps": 15,
                //    "mixedVideoLayout": 1,
                //   "backgroundColor": "#FF0000",
                //    },
                recordingConfig: {
                  maxIdleTime: 1800,
                  streamTypes: 2,
                  audioProfile: 1,
                  channelType: 0,
                  videoStreamType: 0,
                  transcodingConfig: {
                    height: 480,
                    width: 640,
                    bitrate: 600,
                    fps: 15,
                    mixedVideoLayout: 3,
                    backgroundColor: '#000000',
                    layoutConfig: [
                      {
                        uid: '1000',
                        x_axis: 0.0,
                        y_axis: 0.0,
                        width: 1.0,
                        height: 1.0,
                        alpha: 1.0,
                        render_mode: 1
                      },
                      {
                        uid: '1500',
                        x_axis: 0.0,
                        y_axis: 0.0,
                        width: 1.0,
                        height: 1.0,
                        alpha: 1.0,
                        render_mode: 1
                      },
                      {
                        uid: '123456789',
                        x_axis: 0.0,
                        y_axis: 0.0,
                        width: 1.0,
                        height: 1.0,
                        alpha: 1.0,
                        render_mode: 1
                      },
                      {
                        uid: '12345678', // for secondary researcher screen sharing
                        x_axis: 0.0,
                        y_axis: 0.0,
                        width: 1.0,
                        height: 1.0,
                        alpha: 1.0,
                        render_mode: 1
                      },
                      {
                        uid: '1234567', // for Respondent on web screen sharing
                        x_axis: 0.0,
                        y_axis: 0.0,
                        width: 1.0,
                        height: 1.0,
                        alpha: 1.0,
                        render_mode: 1
                      }
                    ]
                  },
                  subscribeVideoUids: ['#allstream#'],
                  subscribeAudioUids: ['#allstream#']
                  // "subscribeUidGroup": 5
                },
                recordingFileConfig: {
                  avFileType: ['hls', 'mp4']
                },
                storageConfig: {
                  vendor: 1,
                  region: 14,
                  bucket: process.env.REACT_APP_S3_BUCKET,
                  accessKey: process.env.REACT_APP_S3_ACCESSKEY,
                  secretKey: process.env.REACT_APP_S3_SECRETKEY,
                  fileNamePrefix: ['video']
                }
              }
            };

            const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
            // Customer secret
            const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
            // Concatenate customer key and customer secret and use base64 to encode the concatenated string
            const plainCredential = customerKey + ':' + customerSecret;
            // Encode with base64
            const encodedCredential = Buffer.from(plainCredential).toString('base64');
            const authorizationField = 'Basic ' + encodedCredential;
            VideoRecordingResource(resource, authorizationField);
          }
          if (response.data.resourceId !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            // console.log('Error');
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('startError', e.message);
  }
};

const VideoRecordingResource = (data, token) => {
  const resourceid = window.$resourceId;
  const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
  // Customer secret
  const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
  // Concatenate customer key and customer secret and use base64 to encode the concatenated string
  const plainCredential = customerKey + ':' + customerSecret;
  // Encode with base64
  const encodedCredential = Buffer.from(plainCredential).toString('base64');
  const authorizationField = 'Basic ' + encodedCredential;
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.VideoRecordingResource.url + '/' + resourceid + '/mode/mix/start',
          maverickPortalApiEndPoint.VideoRecordingResource.methodType,
          data,
          token
        )
        .then(response => {
         
          if (response?.originalError?.response?.data?.code === 53) {
           
            // recording already running
            const resourceId = localStorage.getItem('resourceId');
            const sid = localStorage.getItem('sid');
            if (resourceId && sid) {
              window.$sid = sid;
              window.$resourceId = resourceId;
              setTimeout(function() {
                QueryVideoRecording(data.uid, authorizationField);
              }, 15000);
            }
            maverickPortalhttp.makeRequest(
              maverickPortalApiEndPoint.addStartApiLogsAgora.url,
              maverickPortalApiEndPoint.addStartApiLogsAgora.methodType,
              {
                cname: response?.originalError?.response?.data?.cname,
                uid: response?.originalError?.response?.data?.uid,
                resourceId: response?.originalError?.response?.data?.resourceId,
                sid: response?.originalError?.response?.data?.sid,
                code: response?.originalError?.response?.data?.code,
                error: response?.originalError?.response?.data?.reason
              }
            );
          } else {
            window.$sid = response.data.sid; //global variable
            localStorage.setItem('sid', response.data.sid);
            localStorage.setItem('resourceId', response.data.resourceId);
            const sid = window.$sid;
        
            if (response.data.resourceId) {
              setTimeout(function() {
                QueryVideoRecording(data.uid, authorizationField);
              }, 15000);
            }
            if (response.data.resourceId !== undefined) {
            } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
              localStorage.removeItem('token');
              history.push('/#/sign-in');
              console.log(response.data?.Status.StatusDesc);
              resolve({ res });
            } else {
              // console.log('Error');
            }
            const res = response.data;
            resolve({ res });
            maverickPortalhttp.makeRequest(
              maverickPortalApiEndPoint.addStartApiLogsAgora.url,
              maverickPortalApiEndPoint.addStartApiLogsAgora.methodType,
              {
                cname: response.data.cname,
                uid: response.data.uid,
                resourceId: response.data.resourceId,
                sid: sid,
                code: response.data.code,
                error: response.data.reason
              }
            );
          }
        });
    });
  } catch (e) {
    
    maverickPortalhttp.makeRequest(
      maverickPortalApiEndPoint.addStartApiLogsAgora.url,
      maverickPortalApiEndPoint.addStartApiLogsAgora.methodType,
      {
        cname: response.data.cname,
        uid: response.data.uid,
        resourceId: response.data.resourceId,
        sid: sid,
        code: response.data.code,
        error: response.data.reason
      }
    );
  }
};

const QueryVideoRecording = (data, token) => {
  const resourceid = window.$resourceId;
  const sid = window.$sid;
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.QueryVideoRecording.url + '/' + resourceid + '/sid/' + sid + '/mode/mix/query',
          maverickPortalApiEndPoint.QueryVideoRecording.methodType,
          '',
          token
        )
        .then(response => {
         
          if (response.data.resourceId) {
            var today = new Date();

            var time =
              today.getFullYear().toString() +
              '-' +
              (today.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              today
                .getDate()
                .toString()
                .padStart(2, '0') +
              ' ' +
              today
                .getHours()
                .toString()
                .padStart(2, '0') +
              ':' +
              today
                .getMinutes()
                .toString()
                .padStart(2, '0') +
              ':' +
              today
                .getSeconds()
                .toString()
                .padStart(2, '0');
            const endinterviewdata = {
              user_ID: localStorage.userId,
              projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
              // videoName: response.data.serverResponse.fileList[0].fileName,
              endTime: time,
              sid: window.$sid
            };
            // endInterview(endinterviewdata)
            const resource = {
              cname: data.cname,
              uid: data.uid,
              clientRequest: {
                mixedVideoLayout: 3,
                //"backgroundColor": "#FF0000",
                layoutConfig: [
                  {
                    uid: '1',
                    x_axis: 0.1,
                    y_axis: 0.1,
                    width: 0.1,
                    height: 0.1,
                    alpha: 1.0,
                    render_mode: 1
                  },
                  {
                    uid: '2',
                    x_axis: 0.2,
                    y_axis: 0.2,
                    width: 0.1,
                    height: 0.1,
                    alpha: 1.0,
                    render_mode: 1
                  },
                  {
                    uid: '3',
                    x_axis: 0.2,
                    y_axis: 0.2,
                    width: 0.1,
                    height: 0.1,
                    alpha: 1.0,
                    render_mode: 1
                  }
                ]
              }
            };
            const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
            // Customer secret
            const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
            // Concatenate customer key and customer secret and use base64 to encode the concatenated string
            const plainCredential = customerKey + ':' + customerSecret;
            // Encode with base64
            const encodedCredential = Buffer.from(plainCredential).toString('base64');
            const authorizationField = 'Basic ' + encodedCredential;
            // updateLayoutVideoRecording(resource, authorizationField);
          }
          if (response.data.resourceId !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            // console.log('Error');
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const updateLayoutVideoRecording = (data, token) => {

  const resourceid = window.$resourceId;
  const sid = window.$sid;
  // const resourceid = localStorage.getItem('resourceId');
  // const sid = localStorage.getItem('sid');


  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.updateLayoutVideoRecording.url +
            '/' +
            resourceid +
            '/sid/' +
            sid +
            '/mode/mix/updateLayout',
          maverickPortalApiEndPoint.updateLayoutVideoRecording.methodType,
          data,
          token
        )
        .then(response => {
          
          if (response.data.resourceId !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            // console.log('Errorr');
          }
          const res = response.data;
          console.log('update layout api called', res);
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const stopVideoRecording = (data, token) => {
 
  const resourceid = window.$resourceId;
  const sid = window.$sid;

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.stopVideoRecording.url + '/' + resourceid + '/sid/' + sid + '/mode/mix/stop',
          maverickPortalApiEndPoint.stopVideoRecording.methodType,
          data,
          token
        )
        .then(response => {
          
          // if (response.status !== 400) {
          //   if (response.status == 200 || response.data.serverResponse.uploadingStatus == 'uploaded') {
          //     var today = new Date();

          //     var time =
          //       today.getFullYear().toString() +
          //       '-' +
          //       (today.getMonth() + 1).toString().padStart(2, '0') +
          //       '-' +
          //       today
          //         .getDate()
          //         .toString()
          //         .padStart(2, '0') +
          //       ' ' +
          //       today
          //         .getHours()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getMinutes()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getSeconds()
          //         .toString()
          //         .padStart(2, '0');

          //     const endinterviewdata = {
          //       user_ID: localStorage.userId,
          //       projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
          //       videoName: response.data.serverResponse.fileList[0].fileName,
          //       endTime: time,
          //       sid: window.$sid
          //     };
          //     endInterview(endinterviewdata);
          //     maverickPortalhttp
          //       .makeRequest(maverickPortalApiEndPoint.addAgoraStopApiLogs.url, maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType, { "resourceId": response.data.resourceId, "sid": response.data.sid, "serverResponse": response.data.serverResponse.fileList[0], "uploadingStatus": response.data.serverResponse.uploadingStatus, "status": response.status, "projectRepondentVideo_ID": localStorage.projectRepondentVideo_ID, 'error_message': response?.originalError?.response?.data?.reason })
          //   } else if (response.data.resourceId && response.data.serverResponse.uploadingStatus == 'uploaded') {
          //     var today = new Date();

          //     var time =
          //       today.getFullYear().toString() +
          //       '-' +
          //       (today.getMonth() + 1).toString().padStart(2, '0') +
          //       '-' +
          //       today
          //         .getDate()
          //         .toString()
          //         .padStart(2, '0') +
          //       ' ' +
          //       today
          //         .getHours()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getMinutes()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getSeconds()
          //         .toString()
          //         .padStart(2, '0');

          //     const endinterviewdata = {
          //       user_ID: localStorage.userId,
          //       projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
          //       videoName: response.data.serverResponse.fileList[0].fileName,
          //       endTime: time,
          //       sid: window.$sid
          //     };
          //     endInterview(endinterviewdata);
          //     maverickPortalhttp
          //       .makeRequest(maverickPortalApiEndPoint.addAgoraStopApiLogs.url, maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType, { "resourceId": response.data.resourceId, "sid": response.data.sid, "serverResponse": response.data.serverResponse.fileList[0], "uploadingStatus": response.data.serverResponse.uploadingStatus, "status": response.status, "projectRepondentVideo_ID": localStorage.projectRepondentVideo_ID, 'error_message': response?.originalError?.response?.data?.reason })
          //   } else if (response.data.resourceId && response.data.serverResponse.uploadingStatus == 'backuped') {

          //     var today = new Date();

          //     var time =
          //       today.getFullYear().toString() +
          //       '-' +
          //       (today.getMonth() + 1).toString().padStart(2, '0') +
          //       '-' +
          //       today
          //         .getDate()
          //         .toString()
          //         .padStart(2, '0') +
          //       ' ' +
          //       today
          //         .getHours()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getMinutes()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getSeconds()
          //         .toString()
          //         .padStart(2, '0');

          //     const endinterviewdata = {
          //       user_ID: localStorage.userId,
          //       projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
          //       videoName: response.data.serverResponse.fileList[0].fileName,
          //       endTime: time,
          //       sid: window.$sid
          //     };
          //     endInterview(endinterviewdata);
          //     maverickPortalhttp
          //       .makeRequest(maverickPortalApiEndPoint.addAgoraStopApiLogs.url, maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType, { "resourceId": response.data.resourceId, "sid": response.data.sid, "serverResponse": response.data.serverResponse.fileList[0], "uploadingStatus": response.data.serverResponse.uploadingStatus, "status": response.status, "projectRepondentVideo_ID": localStorage.projectRepondentVideo_ID, 'error_message': response?.originalError?.response?.data?.reason })
          //   } else if (response.status == 206 || "serverResponse" in response.data == false || response.data.serverResponse.uploadingStatus == undefined || response.data.serverResponse.uploadingStatus == null) {
          //     var today = new Date();

          //     var time =
          //       today.getFullYear().toString() +
          //       '-' +
          //       (today.getMonth() + 1).toString().padStart(2, '0') +
          //       '-' +
          //       today
          //         .getDate()
          //         .toString()
          //         .padStart(2, '0') +
          //       ' ' +
          //       today
          //         .getHours()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getMinutes()
          //         .toString()
          //         .padStart(2, '0') +
          //       ':' +
          //       today
          //         .getSeconds()
          //         .toString()
          //         .padStart(2, '0');

          //     const endinterviewdata = {
          //       user_ID: localStorage.userId,
          //       projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
          //       // videoName: response.data.serverResponse.fileList[0].fileName,
          //       videoName: "video/" + window.$sid + '_' + localStorage.getItem('channel') + '_' + '0' + ".mp4",
          //       endTime: time,
          //       sid: window.$sid
          //     };
          //     endInterview(endinterviewdata);
          //     maverickPortalhttp
          //       .makeRequest(maverickPortalApiEndPoint.addAgoraStopApiLogs.url, maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType, { "resourceId": response.data.resourceId, "sid": response.data.sid, "serverResponse": response.data.serverResponse.fileList[0], "uploadingStatus": response.data.serverResponse.uploadingStatus, "status": response.status, "projectRepondentVideo_ID": localStorage.projectRepondentVideo_ID, 'error_message': response?.originalError?.response?.data?.reason })
          //   } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          //     localStorage.removeItem('token');
          //     history.push('/#/sign-in');
          //     console.log(response.data?.Status.StatusDesc);
          //     resolve({ res });
          //     maverickPortalhttp
          //       .makeRequest(maverickPortalApiEndPoint.addAgoraStopApiLogs.url, maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType, { "resourceId": response.data?.resourceId, "sid": response.data?.sid, "serverResponse": response.data?.serverResponse.fileList[0], "uploadingStatus": response.data?.serverResponse.uploadingStatus, "status": response.status + response.data?.Status.StatusDesc, "projectRepondentVideo_ID": localStorage.projectRepondentVideo_ID, 'error_message': response?.originalError?.response?.data?.reason })
          //   } else {
          //     //console.log('Errorr');
          //     maverickPortalhttp
          //       .makeRequest(maverickPortalApiEndPoint.addAgoraStopApiLogs.url, maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType, { "resourceId": response.data?.resourceId, "sid": response.data?.sid, "serverResponse": response.data?.serverResponse.fileList[0], "uploadingStatus": response.data?.serverResponse.uploadingStatus, "status": response.status + response.data?.Status.StatusDesc, "projectRepondentVideo_ID": localStorage.projectRepondentVideo_ID, 'error_message': response?.originalError?.response?.data?.reason })
          //   }
          // } else {
          //   maverickPortalhttp
          //     .makeRequest(maverickPortalApiEndPoint.addAgoraStopApiLogs.url, maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType, { "resourceId": response?.originalError?.response?.data?.resourceId, "sid": response?.originalError?.response?.data?.sid, "serverResponse": '', "uploadingStatus": '', "status": response?.originalError?.response?.data?.code, "projectRepondentVideo_ID": '', 'error_message': response?.originalError?.response?.data?.reason })
          // }

          //LOGS start
          if (response.status !== 400) {
            maverickPortalhttp.makeRequest(
              maverickPortalApiEndPoint.addAgoraStopApiLogs.url,
              maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType,
              {
                resourceId: response.data.resourceId,
                sid: response.data.sid,
                serverResponse: response.data.serverResponse.fileList[0],
                uploadingStatus: response.data.serverResponse.uploadingStatus,
                status: response.status,
                projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
                error_message: response?.originalError?.response?.data?.reason
              }
            );
          } else {
            maverickPortalhttp.makeRequest(
              maverickPortalApiEndPoint.addAgoraStopApiLogs.url,
              maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType,
              {
                resourceId: response?.originalError?.response?.data?.resourceId,
                sid: response?.originalError?.response?.data?.sid,
                serverResponse: '',
                uploadingStatus: '',
                status: response?.originalError?.response?.data?.code,
                projectRepondentVideo_ID: '',
                error_message: response?.originalError?.response?.data?.reason
              }
            );
          }
          // LOGS END

          // New Flow
          // var today = new Date();

          // var time =
          //   today.getFullYear().toString() +
          //   '-' +
          //   (today.getMonth() + 1).toString().padStart(2, '0') +
          //   '-' +
          //   today
          //     .getDate()
          //     .toString()
          //     .padStart(2, '0') +
          //   ' ' +
          //   today
          //     .getHours()
          //     .toString()
          //     .padStart(2, '0') +
          //   ':' +
          //   today
          //     .getMinutes()
          //     .toString()
          //     .padStart(2, '0') +
          //   ':' +
          //   today
          //     .getSeconds()
          //     .toString()
          //     .padStart(2, '0');
          // const endinterviewdata = {
          //   user_ID: localStorage.userId,
          //   projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
          //   // videoName: response.data.serverResponse.fileList[0].fileName,
          //   videoName: "video/" + window.$sid + '_' + localStorage.getItem('channel') + '_' + '0' + ".mp4",
          //   endTime: time,
          //   sid: window.$sid
          // };
          // endInterview(endinterviewdata);
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
    maverickPortalhttp.makeRequest(
      maverickPortalApiEndPoint.addAgoraStopApiLogs.url,
      maverickPortalApiEndPoint.addAgoraStopApiLogs.methodType,
      {
        resourceId: '',
        sid: '',
        serverResponse: '',
        uploadingStatus: '',
        status: '',
        projectRepondentVideo_ID: '',
        error_message: response?.originalError?.response?.data?.reason
      }
    );
  }
};

const Resume = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.Resume.url + '/' + data.user_ID + '/' + data.project_ID,
          maverickPortalApiEndPoint.Resume.methodType
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            //  history.push(`/project/${data.project_ID}/criteria-and-budget`);
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const Schedulecall = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.Schedulecall.url + '/' + data.user_ID + '/' + data.project_ID,
          maverickPortalApiEndPoint.Schedulecall.methodType
        )
        .then(response => {
          if (response.data.Response !== undefined) {
            //  history.push(`/project/${data.project_ID}/criteria-and-budget`);
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const getScheduleDateData = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getScheduleDate.url}/${data.user_ID}/${data.project_ID}`,
        maverickPortalApiEndPoint.getScheduleDate.methodType
      )
      .then(
        response => {
          const res = response.data;
          if (response.data.Response !== undefined) {
            resolve({ res });
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            // resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
        },
        err => {
          console.log('API Error:', err);
        }
      );
  });
};

const addScheduleDate = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.addScheduleDate.url,
        maverickPortalApiEndPoint.addScheduleDate.methodType,
        data
      )
      .then(
        response => {
          const res = response.data;
          if (response.data.Response !== undefined) {
            resolve({ res });
            // window.location.reload();
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            // resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
        },
        err => {
          console.log('API Error:', err);
        }
      );
  });
};

const editScheduleDate = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.editScheduleDate.url,
        maverickPortalApiEndPoint.editScheduleDate.methodType,
        data
      )
      .then(
        response => {
          const res = response.data;
          if (response.data.Response !== undefined) {
            // window.location.reload();
            resolve({ res });
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            // resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
        },
        err => {
          console.log('API Error:', err);
        }
      );
  });
};

const deleteScheduleDate = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteScheduleDate.url,
        maverickPortalApiEndPoint.deleteScheduleDate.methodType,
        data
      )
      .then(
        response => {
          const res = response.data;
          if (response.data.Response !== undefined) {
            // window.location.reload();

            resolve({ res });
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            // resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
        },
        err => {
          console.log('API Error:', err);
        }
      );
  });
};

const addProjectGroup = data => {

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.addProjectGroup.url,
          maverickPortalApiEndPoint.addProjectGroup.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          if (response.data.Response !== undefined) {
            resolve({ res });
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }

          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const editProjectGroup = data => {

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.editProjectGroup.url,
          maverickPortalApiEndPoint.editProjectGroup.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          if (response.data.Response !== undefined) {
            resolve({ res });
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }

          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const deleteProjectGroup = data => {

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.deleteProjectGroup.url,
          maverickPortalApiEndPoint.deleteProjectGroup.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          if (response.data.Response !== undefined) {
            resolve({ res });
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }

          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const createBudget = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.createBudget.url,
          maverickPortalApiEndPoint.createBudget.methodType,
          data
        )
        .then(response => {
          if (response.data.Response !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const assignSampleAndBudget = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.assignSampleAndBudget.url,
          maverickPortalApiEndPoint.assignSampleAndBudget.methodType,
          data
        )
        .then(response => {
          console.log({ response });
          if (response.data.Response !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};
const deassignSampleAndBudget = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.deassignSampleAndBudget.url,
          maverickPortalApiEndPoint.deassignSampleAndBudget.methodType,
          data
        )
        .then(response => {
          console.log({ response });
          if (response.data.Response !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const endInterview = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(maverickPortalApiEndPoint.endInterview.url, maverickPortalApiEndPoint.endInterview.methodType, data)
      .then(response => {
        // if (response.data.Response !== undefined && response.data.Response?.token_data.access_token.length) {
        //    AuthService.login(response.data.Response.token_data.access_token);
        // }
        // else {
        //   console.log(response.data?.Status.StatusDesc)
        // }
        const res = response.data;
        resolve({ res });
      });
  });
};

const VideoRecordingUserList = (data, token) => {
  const resourceid = window.$resourceId;
  const channelname = data.cname;

  try {
    return new Promise(function request(resolve) {
   
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.VideoRecordingUserList.url + '/' + channelname,
          maverickPortalApiEndPoint.VideoRecordingUserList.methodType,
          '',
          token,
          'Access-Control-Allow-Origin',
          '*'
        )
        .then(response => {
         
          // window.$sid = response.data.sid; //global variable
          // const sid = window.$sid;
          // if (response.data.resourceId) {

          //   const customerKey = "bbacfe47cc664939a505c41798e466b1"
          //   // Customer secret
          //   const customerSecret = "aa27f49394cd442c9091a44bd24f0222"
          //   // Concatenate customer key and customer secret and use base64 to encode the concatenated string
          //   const plainCredential = customerKey + ":" + customerSecret
          //   // Encode with base64
          //   const encodedCredential = Buffer.from(plainCredential).toString('base64')
          //   const authorizationField = "Basic " + encodedCredential
          // }

          // if (response.data.resourceId !== undefined) {

          // } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          //   localStorage.removeItem('token');
          //   history.push('/#/sign-in');
          //   console.log(response.data?.Status.StatusDesc);
          //   resolve({ res });
          // } else {
          //   // console.log('Error');
          // }
          // const res = response.data;
          // resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const getUIDDetails = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.getUIDDetails.url + '/' + data.user_ID + '/' + data.interviewSchedule_ID,
          maverickPortalApiEndPoint.getUIDDetails.methodType
        )
        .then(response => {
          if (response.data.Response !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
           
            resolve({ res });
          } else {
            console.log(
              response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
            );
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const VideoRecordingUpdate = (data, token) => {
  const resourceid = window.$resourceId;
  const sid = window.$sid;

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.VideoRecordingUpdate.url + '/' + resourceid + '/sid/' + sid + '/mode/mix/update',
          maverickPortalApiEndPoint.VideoRecordingUpdate.methodType,
          data,
          token
        )
        .then(response => {
          window.$sid = response.data.sid; //global variable
          const sid = window.$sid;
          if (response.data.resourceId) {
            console.log(response.data);
          }

          if (response.data.resourceId !== undefined) {
          } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
            localStorage.removeItem('token');
            history.push('/#/sign-in');
            console.log(response.data?.Status.StatusDesc);
            resolve({ res });
          } else {
            // console.log('Error');
          }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

const RtcTokenBuilder = data => {
  console.log('data', data);
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.RtcTokenBuilder.url,
          maverickPortalApiEndPoint.RtcTokenBuilder.methodType,
          data
        )
        .then(response => {
          // if (response.data.Response !== undefined && response.data.Response?.token_data.access_token.length) {
          //    AuthService.login(response.data.Response.token_data.access_token);
          // }
          // else {
          //   console.log(response.data?.Status.StatusDesc)
          // }
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log(e.message);
  }
};

// const RtcTokenBuilder = (data) => {
//   console.log("window.$rtcTokenBuilder",data);
//   const resourceid = window.$resourceId;
//   const sid = window.$sid;
//    try {
//      return new Promise(function request(resolve) {
//        maverickPortalhttp
//          .makeRequestWithoutBase(
//            maverickPortalApiEndPoint.RtcTokenBuilder.url,
//            maverickPortalApiEndPoint.RtcTokenBuilder.methodType,
//            data,
//          )
//          .then(response => {
//           //  window.$sid = response.data.sid; //global variable
//           //  const sid = window.$sid;
//           //  if (response.data.resourceId) {
//           //    console.log(response.data)
//           //  }

//           //  if (response.data.resourceId !== undefined) {

//           //  } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
//           //    localStorage.removeItem('token');
//           //    history.push('/#/sign-in');
//           //    console.log(response.data?.Status.StatusDesc);
//           //    resolve({ res });
//           //  } else {
//           //    // console.log('Error');
//           //  }
//            const res = response.data;
//            resolve({ res });
//          });
//      });
//    } catch (e) {
//      console.log(e.message);
//    }
// };

const deleteTeamMember = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteTeamMember.url,
        maverickPortalApiEndPoint.deleteTeamMember.methodType,
        data
      )
      .then(response => {
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        const res = response.data;
        resolve({ res });
      });
  });
};
const saveRtmTranscriptData = data => {
  console.log('saveRtmTranscriptData data', data);
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.saveRtmTranscriptData.url,
          maverickPortalApiEndPoint.saveRtmTranscriptData.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('saveRtmTranscriptData error', e.message);
  }
};

const updateSidData = data => {
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.updateSidData.url,
          maverickPortalApiEndPoint.updateSidData.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('insertSid error', e.message);
  }
};

const storeTranscriptLogs = data => {
 
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.storeTranscriptLogs.url,
          maverickPortalApiEndPoint.storeTranscriptLogs.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('storeTranscriptLogs error', e.message);
  }
};

const getRtmTranscriptData = data => {

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.getRtmTranscriptData.url,
          maverickPortalApiEndPoint.getRtmTranscriptData.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('getRtmTranscriptData error', e.message);
  }
};

const updateRtmTranscriptData = data => {

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.updateRtmTranscriptData.url,
          maverickPortalApiEndPoint.updateRtmTranscriptData.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('getRtmTranscriptData error', e.message);
  }
};

const updateGuestData = data => {
 
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequestWithoutBase(
          maverickPortalApiEndPoint.updateGuestData.url,
          maverickPortalApiEndPoint.updateGuestData.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('getRtmTranscriptData error', e.message);
  }
};

const storeTranscriptMessageLogs = data => {

  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.storeTranscriptMessageLogs.url,
          maverickPortalApiEndPoint.storeTranscriptMessageLogs.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('storeTranscriptMessageLogs error', e.message);
  }
};

const editAutomatedScreener = data => {
 
  try {
    return new Promise(function request(resolve) {
      maverickPortalhttp
        .makeRequest(
          maverickPortalApiEndPoint.editAutomatedScreener.url,
          maverickPortalApiEndPoint.editAutomatedScreener.methodType,
          data
        )
        .then(response => {
          const res = response.data;
          resolve({ res });
        });
    });
  } catch (e) {
    console.log('editAutomatedScreener error', e.message);
  }
};

export {
  createProject,
  editProject,
  deleteReferral,
  preProjectData,
  deleteProjectImage,
  deleteProjectTask,
  updateProjectTaskData,
  addProjectLead,
  addProjectResearcher,
  addProjectObserver,
  getCriteriaAndBudget,
  updateProjectStage,
  getResolution,
  getParticipant,
  SendParticpantListOnEnd,
  removeVideoCall,
  GetEditSection,
  GetAddSection,
  GetDragQuestion,
  GetDragQuestionAcrossSection,
  EditScreenerAnswer,
  editAutomatedScreener,
  CountNoParticipant,
  VideoRecording,
  VideoRecordingResource,
  QueryVideoRecording,
  updateLayoutVideoRecording,
  stopVideoRecording,
  Resume,
  Schedulecall,
  updateDuration,
  addProjectNewTask,
  getScheduleDateData,
  addScheduleDate,
  editScheduleDate,
  deleteScheduleDate,
  addProjectGroup,
  editProjectGroup,
  deleteProjectGroup,
  createBudget,
  assignSampleAndBudget,
  //deleteBudget,
  deassignSampleAndBudget,
  endInterview,
  VideoRecordingUserList,
  getUIDDetails,
  VideoRecordingUpdate,
  RtcTokenBuilder,
  deleteTeamMember,
  saveRtmTranscriptData,
  storeTranscriptLogs,
  getRtmTranscriptData,
  updateRtmTranscriptData,
  updateGuestData,
  storeTranscriptMessageLogs,
  updateSidData
};
