import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetFieldTaskMethods from '../../utils/api/field/GetFieldTaskData';

function* GetFieldTaskData(payload) {
  const promise = GetFieldTaskMethods.GetFieldTaskData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetFieldTaskAction.FIELD_TASK_COMPLETE_RESPONSE, data: res.Response });
}

export  { GetFieldTaskData }

