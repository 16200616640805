import styled from '@emotion/styled';

const Question = styled.div`
	padding: ${(props) => (props.padding ? props.padding : '0 0 0 1.5rem')};
	position: relative;
	min-height: 100px;
	
	.q-no {
		position: absolute;
		top: 7px;
		left: -0.5rem;
	}

	.question-action {
		position: absolute;
		// bottom: -1rem;
		right: 0;
	}

	table {
		color: var(--quadColor);
		text-align: center;
		table-layout: fixed;
		width: 90%;
	}

	td,
	th {
		font-size: ${(props) => props.tableFontSize || '0.875rem'};
		padding: 0.5rem 1rem;
		border: 1px solid var(--grey20);
	}
`;

export default function QuestionWrapper({
	children,
	className = '',
	tableFontSize
}) {
	return (
		<Question className={className} tableFontSize={tableFontSize}>
			{children}
		</Question>
	);
}
