import React from 'react';
import Overview from '../../assets/images/invite-recruit2.png';
import { Text, Divider, ProgressBar, CustomContentProgressbar, Icon } from '../../common';
import styled from '@emotion/styled';

const ProgressStatus = styled(ProgressBar)`
   height:8px;
`;

const HorizontalLine = styled.hr`width: 144px;`;

export function OverviewCardSmall({
	title,
	icon = 'videocam',
	width = '90px',
	height = '90px',
	percentage = '0',
	fontSize = '34px',
	titlePosition = 'bottom',
	stats
}) {
	return (
		<div className="d-flex flex-column text-center px-1 justify-content-center align-items-center">
			{titlePosition == 'top' && (
				<div className="mb-3">
					<Text type="4" fontSize="14px">
						{title}
					</Text>
				</div>
			)}
			<div
				style={{
					width: width,
					height: height
				}}
			>
				<CustomContentProgressbar percentage={percentage}>
					<div>
						<Icon
							style={{
								fontSize: fontSize,
								margin: '0px',
								padding: '0px'
							}}
						>
							{icon}
						</Icon>
					</div>
					<div>
						<Text fontSize="14px" margin="-8px">
							{stats}
						</Text>
					</div>
				</CustomContentProgressbar>
			</div>

			{titlePosition == 'bottom' && (
				<div className="mt-2">
					<Text type="4" fontSize="14px">
						{title}
					</Text>
				</div>
			)}
		</div>
	);
}

export function OverviewCardBig({ title, width, height, icon, percentage ,data, stats1, stats1_bar, stats2, stats2_bar ,invites, application}) {
	return (
		<div className="row align-items-center justify-content-center">
			<div className="col-md-3 d-flex justify-content-around">
				<div className="pl-3">
					<OverviewCardSmall title={title} width={width} height={width} icon={icon} percentage={percentage}  />
				</div>
				<Divider margin="0" orientation="vertical" height="auto" width="1px" />
			</div>
			<div className="col-md-5 d-flex justify-content-around p-2">
				<div className="d-flex flex-column pl-0 justify-content-center">
				{data}
				</div>
				<Divider margin="0" orientation="vertical" height="auto" width="1px" />
			</div>
			<div className="col-md-4  d-flex flex-column">
				<div className="d-flex flex-column align-items-center">
					<Text type="4" fontSize="12px" fontWeight="400">
						Invites Sent
					</Text>
					<Text type="4" fontSize="18px">
						{invites}
					</Text>
				</div>
				<div>
					<HorizontalLine />
				</div>
				<div className="d-flex flex-column align-items-center">
					<Text type="4" fontSize="12px" fontWeight="400">
						Applications
					</Text>
					<Text type="4" fontSize="18px">
						{application}
					</Text>
				</div>
			</div>
		</div>
	);
}
