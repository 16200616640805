import styled from '@emotion/styled';
import { Button } from '.';
// Link but on hover underline
export const CustomButton = styled(Button)`
	border: none;
	display: flex;
	align-items: center;
	color: ${(props) => props.buttonColor};
	border-bottom: 1px solid transparent
	border-radius: 0;
	padding: 0;
	padding-bottom: 5px;
	margin: 1rem 0;
	span {
		font-size: 14px;
		color: var(--sapGreen);
		padding-right: 3px;
	}
	&:hover {
        background: ${(props) => (!props.disabled ? 'transparent' : 'transparent')};
        border-bottom: ${(props) =>
          !props.disabled ? `1px solid ${props.buttonColor}` : '1px solid transparent'};
        color: ${(props) => (!props.disabled ? props.buttonColor : 'var(--lightestGrey)')};
        cursor: ${(props) => (!props.disabled ? 'pointer' : 'not-allowed')};
        box-shadow: none;
	}

    :disabled {
        border-bottom: 1px solid transparent;
        background: transparent;
        color: ${(props) => (props.disabled ? 'var(--lightestGrey)' : props.buttonColor)};
        box-shadow: none;
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

        /* Remove hover effect when disabled */
        &:hover {
            background: transparent;
            border-bottom: 1px solid transparent;
            color: var(--lightestGrey);
            cursor: not-allowed;
        }
    }
        
`;
