import constants from '../../constants';

const { REQUEST_USER_LOGIN, USER_LOGIN_RECEIVED, REQUEST_USER_LOGOUT, USER_LOGOUT_RECEIVED } = constants.LoginConstants;
export default {
  REQUEST_USER_LOGIN,
  USER_LOGIN_RECEIVED,
  REQUEST_USER_LOGOUT,
  USER_LOGOUT_RECEIVED,

  requestLogin: payload => ({
    type: REQUEST_USER_LOGIN,
    payload
  }),
  userLoginReceived: () => ({
    type: USER_LOGIN_RECEIVED
  }),
  requestLogout: () => ({
    type: REQUEST_USER_LOGOUT
  }),
  logoutReceived: () => ({
    type: USER_LOGOUT_RECEIVED
  })
};

// export const setLoggedInStatus = ()