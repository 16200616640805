import React from 'react';
import { Card, Text } from '../../common';
import styled from '@emotion/styled';

const DiscussionContainer = styled.div`
  max-height: 363px;
  transition: 3s;
  .scrollable {
    overflow-y: auto;
    max-height: 358px;
    scrollbar-width: thin;
  }

  li {
    margin-top: 0.25em;
  }
`;

export function DiscussionGuide({ videoValue }) {
  return (
    <React.Fragment>
      <Text type="5" fontSize="18px" fontWeight="600" color="var(--seaBlue)">
        Discussion Guide
      </Text>
      <Text type="3" fontSize="14px" color="var(--seaBlue)">
        Task Analysis - My Movie Binge
      </Text>

      <Card className="mt-3 p-3">
        <DiscussionContainer>
          <div className="scrollable">
            {videoValue &&
              videoValue.map((obj, index) => {
                return (
                  <div>
                    <div className="d-flex">
                      <Text type="5" fontSize="14px" color="var(--seaBlue)">
                        Instruction
                      </Text>
                      <Text type="4" fontSize="14px" color="var(--greyBlue)" className="ml-3">
                        {obj.instruction}
                      </Text>
                    </div>
                    <div className="mt-1">
                      <Text type="4" fontSize="10px" fontWeight="400">
                        {obj.instructionDescription}
                      </Text>
                    </div>
                    <div>
                      <Card className="mt-3 p-2 card-border">
                        <div>
                          <Text type="4" fontSize="12px" color="var(--greyBlue)">
                            {obj.instructionDetail}
                          </Text>
                        </div>
                      </Card>
                    </div>
                    <br />
                  </div>
                );
              })}
          </div>
        </DiscussionContainer>
      </Card>
    </React.Fragment>
  );
}
