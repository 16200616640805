import styled from '@emotion/styled';
import progress_circle_1 from '../assets/images/progress-circle-1.svg';
import progress_circle_2_3 from '../assets/images/progress-circle-2-3.svg';
import progress_circle_4 from '../assets/images/progress-circle-4.svg';
import progress_circle_5 from '../assets/images/progress-circle-5.svg';
import complete from '../assets/images/complete.svg';
import Text from './Text';

const ProgressMapper = {
    1: progress_circle_1,
    2: progress_circle_2_3,
    3: progress_circle_2_3,
    4: progress_circle_4,
    5: progress_circle_5
};

const Container = styled.div`
    position: relative;
    width: ${props => props.width || '30%'};
    text-align: center;

    .step-number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-right: 0;

        span {
            display: block;
            font-size: 1rem;
        }
    }
`

const Img = styled.img`
    max-height: 90px;
    max-width: 100%;
`

export default function ProgressCircle({ progress, progressStatus, width }) {
    return <Container width={width}>
        <div className="step-number">
            {!progressStatus ? 
                (progress === 2 || progress === 3 
                    ? <><Text type="5" fontWeight="400">Step</Text> <Text type="4" fontSize="0.875rem">2-3</Text></> 
                    : <><Text type="5" fontWeight="400">Step</Text> <Text type="4" fontSize="0.875rem">{progress}</Text></> 
                ) : (<Text type="6" >Complete</Text>)
            }
        </div>
        <Img src={!progressStatus ? ProgressMapper[progress || 1] : complete} />
    </Container>
}