import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditUserMethods from '../../utils/api/clientAdmin/EditUserData';

function* EditUserData(payload) {
  const promise = EditUserMethods.EditUserData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditUserdataAction.EDITUSERDATA_RECEIVED, data: res.Response });
}

export { EditUserData };
