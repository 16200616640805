import React, { useState, useEffect, useRef } from "react";
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  event
} from "d3";

export default function Likert(props){
    //const [data, setData] = useState(datasets);
    const {datasets, valueType} = props
    const svgRef = useRef();
    const wrapperRef = useRef();
    const canvasRef = useRef(null)

    const colors = [
      'rgba(22, 62, 102, 1.0)',
      'rgba(35, 97, 161, 1.0)',
      'rgba(80, 131, 204, 1.0)',
      'rgba(166, 166, 166, 1.0)',
      'rgba(237, 125, 125, 1.0)',
      'rgba(229,85,85,1.0)',
      'rgba(201, 4, 3, 1.0)'
    ]

    useEffect(() => {
      const svg = select(svgRef.current);
      const { width, height } = wrapperRef.current.getBoundingClientRect();

      const yScale = scaleLinear().domain(width).range([0, width]);
      let dk = Object.keys(datasets)
      var data = []
      for(let i=0; i < dk.length;i++)
      {
          if(dk[i] != 'id' && dk[i] != 'choiceName' && dk[i] != 'mean')
          {
              data.push(parseInt(datasets[dk[i]]))
          }
      }
      let dataTemp = [];
      if(valueType == '%')
      {
        svg.selectAll("*").remove();
        for(let i=0; i < data.length; i++)
        {
          let sum = data.reduce((a, b) => a + b, 0);
          dataTemp[i] = Math.floor(data[i]/sum * 100);
        }
      }
      else
      {
        dataTemp = data;
      }

      data = dataTemp?.reverse();

      const total = data.reduce((f,i)=>{
          f = f+i
          return f
      },0)


      svg
      .append("g")
      
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("fill",(d,i)=> colors[i])
      .attr("class", "barreact")
      .attr(
        "x",
        (sequence,i) => {
            if(i == 0){
                return 0
            }else{
                let t = 0
                for(let j = i-1; j >=0; j--){
                    t = data[j]+t
                }
                return (width*t)/total
            }
        }
      )
      .attr("width", (d,i)=>{
        return (width*d)/total
      })
      .attr("y", 0)
      .attr("height", 30)

    let gr = svg.selectAll('rect')
        let ngroup = svg.append("g")
        gr.each(function (p, j) {
            ngroup
            .append("text")
            .text(function(d) {   
                        if(p != 0){
                            return p;
                        }
                        return ''
                        
            })
            .attr("x",function(d){
                let x = 0
                if(j != 0){
                    let t = 0
                    for(let k = j-1; k >=0; k--){
                        t = data[k]+t
                    }
                    x = (width*t)/total
                }
                return (x + ((width*p)/total/2))
            })
            .attr("y", 18)
            .attr("text-anchor", "middle")
            .attr("font-family","Noto Sans")
            .attr("font-size", "11px")
            .attr("fill", "white");
        })

        let doctype = '<?xml version="1.0" standalone="no"?>'
        + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';

        // serialize our SVG XML to a string.
        let source = (new XMLSerializer()).serializeToString(window.document.getElementById('svg_'+props.choice));


    // create a file blob of our SVG.
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        let img = new Image({width: width, height: height})
        img.src = 'data:image/svg+xml;base64,'+window.btoa(doctype + source)
        canvas.width = width
        canvas.height = height
        context.drawImage(img,0,0,width,height)
    
        img.onload = function(){
        // Now that the image has loaded, put the image into a canvas element.
            context.drawImage(img, 0, 0,width,height);
        
            if(props.onReady != undefined){
                props.onReady(props.index, canvas.toDataURL("image/png"), props.choice, props.datasets.mean)
            }
        }


    },[datasets])

    return (
        <>
          <div
            ref={wrapperRef}
            style={{ width: "100%", height: "30px", marginBottom: "1rem" }}
          >
            <svg ref={svgRef} style={{ width: "100%", height: "110%" }} id={"svg_"+props.choice}>
              <g className="x-axis" />
              <g className="y-axis" />
              <g className="content" />
            </svg>
            <canvas ref={canvasRef} style={{display:'none'}}/>
          </div>
        </>
      );
}