import styled from '@emotion/styled';

const P = styled.p`
    margin-bottom: 0;
    color: var(--greyBlue);
    font-size: 0.87rem;
    text-align: right;
    width: ${props => props.width || '28px'};
    font-weight: ${props => props.bold ? '700' : '400'};
`

export default function Time({ children, className="", bold, width }) {
    return <P className={className} bold={bold} width={width}>{children}</P>
}