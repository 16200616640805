import styled from '@emotion/styled';
import Divider from './Divider';

const StyledTemplateForm = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        flex: none;
    }

    .divider {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`

export default function TemplateForm({ children, className }){
    return <StyledTemplateForm className={className} >{children}</StyledTemplateForm>
}