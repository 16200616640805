import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetRecordTaskVideoMethods from '../../utils/api/field/GetRecordTaskVideoData';

function* GetRecordedVideoData(payload) {
  const promise = GetRecordTaskVideoMethods.GetRecordTaskVideo(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetRecordTaskVideoAction.RECORD_TASK_VIDEO_RESPONSE, data: res.Response });
}

export { GetRecordedVideoData };
