import React, { useState } from 'react';
import ProjectWrapper from './ProjectWrapper';
import {
	Heading,
	HelperText,
	Card,
	Text,
	ProgressBar,
	Button,
	Divider,
	Tabs,
	Tab,
	TabsContainer,
    EmptyState
} from '../../common';
import {
	OverviewCardSmall,
	OverviewCardBig,
	RecruitWrapper,
	RecruitStatus,
	Notification,
	ProfileBuilder
} from '../../containers';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import EmptyRecruit from '../../assets/images/empty-recruit.png';

const TemplateCard = styled(Card)`
    padding: 0;
    height: 100%;
    position: relative;
    box-shadow:${(props) => (props.boxShadow ? props.boxShadow : `0px 2px 8px 1px rgba(0, 0, 0, 0.2)`)};
    width:${(props) => (props.width ? props.width : 'auto')};
    height:${(props) => (props.height ? props.height : '191px')};
    position:relative;
    
    img{
        width: 90px;
        height:90px;
        margin-bottom:1rem;
    }

    .hovered {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;
        
    }

    &.active {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
    }
`;

const ProgressStatus = styled(ProgressBar)`
   height:8px;
`;
const ButtonInvite = styled(Button)`
    width: 65px;
    height: 24px;
    font-size: 10px;
    padding:0;
`;

const CardContainer = styled(Card)`
  height: 204.73px;
`;

export default function InviteRecruit() {
	const [ projectTitle, setProjectTitle ] = useState('My Movie Binge');
	const [ isShown, setIsShown ] = useState(false);
	const [ isActiveOverview, setIsActiveOverview ] = useState(false);
	const [ isActiveTask, setIsActiveTask ] = useState(false);
	const [ stage, setStage ] = useState(2);
	const [ appliedStage, setAppliedStage ] = useState(1);

	const handleClickStage = (stage) => {
		if(stage === 1 ){
			setAppliedStage(0)
		}else if(stage === 2){
			setAppliedStage(1)
		}

		setStage(stage)
	}

	return (
		<ProjectWrapper projectTitle={projectTitle} step={2}>
			<Heading type="2" className="mt-4" fontWeight="600" color="var(--seaBlue)">
				In this section...
			</Heading>
			<HelperText fontSize="0.99rem">
				First select a task to choose and invite respondents to participate in
			</HelperText>

			<CardContainer padding="0.5rem 2rem" height="">
				<div className="row ">
					{!isActiveOverview ? (
						<div className="col-md-3 col-md-22 p-0">
							<div className="d-flex justify-content-around">
								<TemplateCard
									className="card-border  pt-4 pb-4 "
									onMouseEnter={() => setIsShown('1')}
									onMouseLeave={() => setIsShown(false)}
									width="172px"
								>
									{isShown !== '1' ? (
										<OverviewCardSmall
											title="Usage Overview"
											width="90px"
											height="90px"
											icon="description"
											percentage={50}
										/>
									) : (
										<React.Fragment>
											<div>
												<Text type="4" fontSize="12px" className="textalign">
													Respondent Data
												</Text>
											</div>
											<div>
												<div className="p-2">
													<Text type="5" fontSize="10px" className="lineheight-18 textalign">
														Group 1-5/20
													</Text>
													<ProgressStatus width={30} className="m-1" />
												</div>
												<div className="p-1">
													<Text type="5" fontSize="10px" className="lineheight-18 textalign">
														Group 2-5/20
													</Text>
													<ProgressStatus width={60} className="m-1" />
												</div>
												<div className="p-2 d-flex justify-content-around">
													<ButtonInvite
														primary
														className="pt-1 pb-1"
														fontSize="0.75rem"
														fill={false}
													>
														Invite
													</ButtonInvite>
													<ButtonInvite
														primary
														className="pt-1 pb-1"
														fontSize="0.75rem"
														onClick={() => {
															setIsActiveOverview(true);
															setIsActiveTask(false);
														}}
													>
														Review
													</ButtonInvite>
												</div>
											</div>
										</React.Fragment>
									)}
								</TemplateCard>
								<Divider margin="0" orientation="vertical" height="auto" width="1px" />
							</div>
						</div>
					) : (
						<div className="col-md-9">
							<TemplateCard
								className="card-border  pt-4 pb-4 "
								boxShadow="0px 12px 24px rgba(0, 0, 0, 0.3)"
							>
								<OverviewCardBig
									title="Usage Overview"
									width="90px"
									height="90px"
									icon="description"
									percentage={50}
								/>
							</TemplateCard>
						</div>
					)}
					{!isActiveTask ? (
						<div className="col-md-3 col-md-22 p-0">
							<div className="d-flex justify-content-around">
								<TemplateCard
									className="card-border  pt-4 pb-4 "
									onMouseEnter={() => setIsShown('2')}
									onMouseLeave={() => setIsShown(false)}
									width="172px"
								>
									{isShown !== '2' ? (
										<OverviewCardSmall
											title="Task Analysis"
											width="90px"
											height="90px"
											icon="videocam"
											percentage={45}
										/>
									) : (
										<React.Fragment>
											<div>
												<Text type="4" fontSize="12px" className="textalign">
													Task Analysis - 20/30
												</Text>
											</div>
											<div>
												<div className="p-2">
													<Text type="5" fontSize="10px" className="lineheight-18 textalign">
														Group 1-10/20
													</Text>
													<ProgressStatus width={60} className="m-1" />
												</div>
												<div className="p-1">
													<Text type="5" fontSize="10px" className="lineheight-18 textalign">
														Group 2-10/10
													</Text>
													<ProgressStatus width={100} className="m-1" />
												</div>
												<div className="p-2 d-flex justify-content-around">
													<ButtonInvite
														primary
														className="pt-1 pb-1"
														fontSize="0.75rem"
														fill={false}
													>
														Invite
													</ButtonInvite>
													<ButtonInvite
														primary
														className="pt-1 pb-1"
														fontSize="0.75rem"
														onClick={() => {
															setIsActiveOverview(false);
															setIsActiveTask(true);
														}}
													>
														Review
													</ButtonInvite>
												</div>
											</div>
										</React.Fragment>
									)}
								</TemplateCard>
								<Divider margin="0" orientation="vertical" height="auto" width="1px" />
							</div>
						</div>
					) : (
						<div className="col-md-9">
							<TemplateCard
								className="card-border  pt-4 pb-4 "
								boxShadow="0px 12px 24px rgba(0, 0, 0, 0.3)"
							>
								<OverviewCardBig
									title="Task Analysis"
									width="90px"
									height="90px"
									icon="videocam"
									percentage={45}
								/>
							</TemplateCard>
						</div>
					)}
				</div>
			</CardContainer>
			<Heading type="5" breakHeading={false} color="var(--headingColor)" className="mt-5">
				Pick the right{' '}
				<Text fontWeight="800" color="var(--headingColor)" span>
					Respondents
				</Text>{' '}
			</Heading>
			<Text type="3" className="mb-4">
				Select and invite potential respondents to participate in the Usage Overview task for {projectTitle}.
			</Text>
			{/* Action Component */}
			<div className="row mt-4">
				<div className="col-md-12 p-0">
					<RecruitWrapper stage={stage} handleClick={handleClickStage}>
						{stage === 1 && (
							<div>
								<RecruitStatus />
								<div className="row mt-4">
									<div className="col-md-8 col-md-70 pl-0 ">
										<Card padding="0">
											<ProfileBuilder stage={appliedStage} />
										</Card>
										<div className="mt-4 d-flex justify-content-center">
											<Link to="/project/1/design">
												<Button primary className="mt-3" size="mid" fontSize="13px">
													Start Designing Tasks
												</Button>
											</Link>
										</div>
									</div>
									<div className="col-md-4 p-0">
										<Notification />
									</div>
								</div>
							</div>
						)}
						{stage === 2 && (
							<div>
								<div className="row">
									<div className="col-md-8 offset-1">
										<TabsContainer>
											<div className="row">
												<div className="col-md-8">
													<Tabs>
														<Tab
															active={appliedStage === 1 && true}
															fontSize="14px"
															onClick={() => setAppliedStage(1)}
														>
															All
														</Tab>
														<Tab
															active={appliedStage === 2 && true}
															fontSize="14px"
															onClick={() => setAppliedStage(2)}
														>
															Invited
														</Tab>
														<Tab
															active={appliedStage === 3 && true}
															fontSize="14px"
															onClick={() => setAppliedStage(3)}
														>
															Directly
														</Tab>
													</Tabs>
												</div>
											</div>
										</TabsContainer>
									</div>
								</div>
								<RecruitStatus />
								<div className="row mt-4">
									<div className="col-md-8 col-md-70 pl-0 pr-0">
										<Card padding="0.45rem">
                                            <EmptyState imgSrc={EmptyRecruit} className="">
                                                <Heading type="2" fontWeight="700" fontSize="22px">Oops, nobody’s here yet</Heading>
                                                <Text type="3">Inviting more respondents to join will help your recruiting process</Text>

                                                <div className="d-flex justify-content-start align-items-center mt-4">
                                                    <Link to="/project/create" className="mr-4" >
                                                        <Button color="var(--primaryColor)" size="mid">Go to Invite</Button>
                                                    </Link>

                                                    <Link to="/project/create">
                                                        <Button fill={false} color="var(--primaryColor)" size="mid">Get Help ?</Button>
                                                    </Link>
                                                </div>
                                                
                                            </EmptyState>
										</Card>
										<div className="mt-4 d-flex justify-content-center">
											<Link to="/project/1/design">
												<Button primary className="mt-3" size="mid" fontSize="13px">
													Start Designing Tasks
												</Button>
											</Link>
										</div>
									</div>
									<div className="col-md-4 pr-0">
										<Notification />
									</div>
								</div>
							</div>
						)}
						{stage === 3 && (
							<div>
								<RecruitStatus />
								<div className="row mt-4">
									<div className="col-md-8 col-md-70 pl-0 pr-0">
										<Card padding="0">
											<ProfileBuilder stage={stage} appliedStage={appliedStage} />
										</Card>
										<div className="mt-4 d-flex justify-content-center">
											<Link to="/project/1/design">
												<Button primary className="mt-3" size="mid" fontSize="13px">
													Start Designing Tasks
												</Button>
											</Link>
										</div>
									</div>
									<div className="col-md-4 pr-0">
										<Notification />
									</div>
								</div>
							</div>
						)}
						{stage === 4 && (
							<div>
								<RecruitStatus />
								<div className="row mt-4">
									<div className="col-md-8 col-md-70 pl-0 pr-0">
										<Card padding="0">
											<ProfileBuilder stage={stage} appliedStage={appliedStage} />
										</Card>
										<div className="mt-4 d-flex justify-content-center">
											<Link to="/project/1/design">
												<Button primary className="mt-3" size="mid" fontSize="13px">
													Start Designing Tasks
												</Button>
											</Link>
										</div>
									</div>
									<div className="col-md-4 pr-0">
										<Notification />
									</div>
								</div>
							</div>
						)}
					</RecruitWrapper>
				</div>
			</div>
		</ProjectWrapper>
	);
}
