import React, { useState, useRef } from 'react';
import Chevron from '../images/downIcon.svg';

import './Accordion.css';

function Accordion(props) {
	const [setActive, setActiveState] = useState('active');
	const [setHeight, setHeightState] = useState('auto');
	const [setRotate, setRotateState] = useState('accordion__icon');

	const content = useRef(null);

	function toggleAccordion() {
		setActiveState(setActive === '' ? 'active' : '');
		setHeightState(
			setActive === 'active'
				? '0px'
				: `${content.current.scrollHeight}px`
		);
		setRotateState(
			setActive === 'active'
				? 'accordion__icon rotate'
				: 'accordion__icon '
		);
	}

	return (
		<div className={`accordion__section ${props.className}`}>
			<div
				className={`accordion ${setActive}`}
				onClick={toggleAccordion}
			>
				<p className='accordion__title'>
					{props.title}
					{props.isRequired === 1 && (
						<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
					)}
				</p>
				<img
					src={Chevron}
					className={`${setRotate}`}
					width={10}
					fill={'#777'}
				/>
			</div>
			<div
				ref={content}
				style={{ maxHeight: `${setHeight}` }}
				className='accordion__content'
			>
				<div className='accordion__text'>{props.children}</div>
			</div>
		</div>
	);
}

export default Accordion;
