import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditButtonNameMethods from '../../utils/api/field/EditButtonNameData';

function* EditButtonNameData(payload) {
  const promise = EditButtonNameMethods.EditButtonNameData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditButtonNameAction.EDITBUTTONNAME_RECEIVED, data: res.Response });
}

export  { EditButtonNameData }