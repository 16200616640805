import styled from '@emotion/styled';
import Card from './Card';
import Heading from './Heading';
import HelperText from './HelperText';
import ProgressCircle from './ProgressCircle';
import ProgressBar from './ProgressBar';
import { Button, Text } from '.';
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from 'react-redux';
import { getCriteriaAndBudget } from '../actions/project/index';
import { extendWith } from 'lodash';

const history = createBrowserHistory({ forceRefresh: true });

const addQuote = (text) => {
    return "'" + text + "'";
}

const ImgContainer = styled.div`
    background: url(${props => addQuote(props.url) || 'https://dummyimage.com/600x400/f5f5f5/f5f5f5.png'}) no-repeat center center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 120px;
    width: 180px;
    background-size: cover;
`

const ProjectInfo = styled.div`
    padding: 1rem 0.8rem 1rem 1.5rem;
    width: 16%;
    position: relative;
    margin-right: 10px;

    :after{
        content: '';
        height: 78px;
        border-right: 1px solid var(--darkBorderColor);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`

const ProgressStat = styled.div`
    width: 60%;
`

const StatusContainer = styled.div`
    width: 70%;
`

const StatusInfo = styled.div`

`

const StatusName = styled(Heading)`
    display: inline-block;
`

const StatusText = styled(HelperText)`
    display: inline-block;
    margin-bottom: 8px;
`

const Action = styled.div`
    width: 15%;
    text-align: center;
    //display: flex;
    align-items: center;
    justify-content: center;
`


export default function ProjectCard({ className = "", project }) {
    const loggedUser = useSelector(state => state.LoginReducer);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let startdate, startmonth, enddate, endmonth;
    if (project.startDuration != null) 
    {
        startdate = new Date(project?.startDuration).getDate();
        startmonth = months[new Date(project?.startDuration).getMonth()];
        enddate = new Date(project?.endDuration).getDate();
        endmonth = months[new Date(project?.endDuration).getMonth()];
    } 
    else 
    {
        startdate = "";
        startmonth = "";
        enddate = "";
        endmonth = "";
    }
    let progress = 0;
    let progressStatus = 0;
    let projectImages = [];
    if(project.projectImages_array?.length > 0) 
    {
        progress = project.steps_array[project.steps_array.length - 1].Step;
        progressStatus = (project.steps_array[project.steps_array.length - 1].Step == 5 && project.steps_array[project.steps_array.length - 1].stepStatus == 'completed') && 1;
        projectImages = Object.values(project.projectImages_array);
    }
    let projectId = project.project_ID;
    
    const dispatch = useDispatch();

    const sample = (data,projectId,title) => {
        data = data[data.length - 1];
        if(data.ModuleDescription == "Project Setup")
        {
		    dispatch({ type: 'RESUME_DATA', payload: {user_ID: loggedUser.userId, project_ID: projectId } });
        }
        else
        {
            dispatch({ type: 'GET_CRITERIA_AND_BUDGET', payload: { user_ID: loggedUser.userId, project_ID: projectId, projectIdentity: title } });
        }
        setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
            () => getData(data), 
            2000
          );
      };

      const endproject = (projectId) => {
		dispatch({ type: 'REQUEST_ENDPROJECT', payload: {user_ID: loggedUser.userId, project_ID: projectId } });
      };

      const getData = (data) => {
		if (data.ModuleDescription == 'Design') 
        {
            history.push('/project/'+projectId+'/design');
        }
        else if (data.ModuleDescription == 'Project Setup') 
        {
            if(data.SubStepData.length == 0)
            {
                history.push('/project/'+projectId+'/edit');
            }
            if(data.SubStepData.length == 1)
            {
                history.push('/project/'+projectId+'/criteria-and-budget');
            }
            if(data.SubStepData.length == 2)
            {
                history.push('/project/'+projectId+'/schedule');
            }
            if(data.SubStepData.length == 3)
            {
                history.push('/project/'+projectId+'/publish');
            }
            if(data.SubStepData.length == 4)
            {
                history.push('/project/'+projectId+'/invite-recruit');
            }
        }
        else if (data.ModuleDescription == 'Recruit') 
        {
            history.push('/project/'+projectId+'/invite-recruit');
        }
        else if (data.ModuleDescription == 'Field') 
        {
            history.push('/project/'+projectId+'/field');
        }
        else if(data.ModuleDescription == "Analysis")
        {
            history.push('/project/'+projectId+'/analysis');
        }
	};

    return <Card className={`d-flex align-items-center ${className}`} padding="0">
        <ImgContainer url={projectImages.length > 0 && projectImages[projectImages.length - 1] != null ? projectImages[projectImages.length - 1] : `https://thumbs.dreamstime.com/b/projects-concept-black-chalkboard-d-rendering-handwritten-top-view-office-desk-lot-business-office-supplies-79906734.jpg`} />
        <ProjectInfo className="d-flex flex-column justify-content-center">
            <Heading type="5" color="var(--quadColor)" fontWeight="700">{project?.title || 'Project Name'}</Heading>
            {/* <Text type="6">{project?.templateName}</Text> */}
            <Text type="6">{startdate + " " + startmonth} - {enddate + " " + endmonth}</Text>
        </ProjectInfo>
        <ProgressStat className="d-flex align-items-center">
            <ProgressCircle progress={progress} progressStatus={progressStatus} />
            <StatusContainer>
                {
                    project.steps_array.map((stage, index) => {
                        let temp = project.steps_array.map(function(v,i) { return v["ModuleDescription"]})
                        if(project.steps_array.length == index +1 || (stage.ModuleDescription == "Recruit" && !$.inArray("Field", temp)))
                        {
                            let progressStep1 = 0;
                            let progressStep2 = null;
                            return <StatusInfo key={index}>
                                {(() => {
                                    switch(stage.Step) {
                                        case 1:
                                            progressStep1 = (stage?.SubStepData && stage?.SubStepData?.length) * 25;
                                            return <Heading type="4" color="var(--quadColor)" fontWeight="700" breakHeading={false}>
                                                {stage?.ModuleDescription || 'Stage Name '} {(stage?.SubStepData && stage?.SubStepData.length > 0) && <Text span fontSize="0.75rem" fontWeight="300"> - {stage?.SubStepData[stage?.SubStepData?.length -1].ModuleDescription} </Text>}
                                            </Heading>
                                        case 2:
                                        case 3:
                                            progressStep1 = stage.stepStatus == "completed" ? 100 : ((stage.recruitedRespondent/stage.totalRespondent)*100);
                                            progressStep2 = (stage.Step == 3 && stage.stepStatus == "completed") ? 100 : ((stage.visitedTasks/stage.totalTasks)*100);
                                            return <>
                                                <Heading type="4" color="var(--quadColor)" fontWeight="700" breakHeading={false}>
                                                    Recruit <Text span fontSize="0.75rem" fontWeight="300"> - {stage.recruitedRespondent}/{stage.totalRespondent} Respondents Recruited</Text>
                                                </Heading>
                                                <ProgressBar width={progressStep1} className="mb-2" />
                                                <Heading type="4" color="var(--quadColor)" fontWeight="700" breakHeading={false}>
                                                    Design <Text span fontSize="0.75rem" fontWeight="300"> - {stage.visitedTasks}/{stage.totalTasks} Tasks Designed</Text>
                                                </Heading>
                                            </>
                                        case 4:
                                            let completedRespondents = 0;
                                            let totalRespondents = 0;
                                            stage?.taskData?.forEach((task) => {
                                                completedRespondents += task.completedRespondents;
                                                totalRespondents += task.totalRespondents;
                                            });
                                            progressStep1 = stage.stepStatus == "completed" ? 100 : ((completedRespondents/totalRespondents) * 100);
                                            return <Heading type="4" color="var(--quadColor)" fontWeight="700" breakHeading={false}>
                                                    {stage?.ModuleDescription || 'Stage Name '} {stage?.stepStatus && <Text span fontSize="0.75rem" fontWeight="300"> - {stage.stepStatus} </Text>}
                                                </Heading>
                                        case 5:
                                            progressStep1 = stage.stepStatus == "completed" ? 100 : 50;
                                            return (stage.stepStatus == 'In Progress') ? (
                                                <Heading type="4" color="var(--quadColor)" fontWeight="700" breakHeading={false}>
                                                    {stage?.ModuleDescription || 'Stage Name '} {stage?.stepStatus && <Text span fontSize="0.75rem" fontWeight="300"> - {stage.stepStatus} </Text>}
                                                </Heading>
                                            ) : (
                                                <Heading type="4" color="var(--quadColor)" fontWeight="700" breakHeading={false}>
                                                    Complete
                                                </Heading>
                                            )
                                    }
                                })()}
                                
                                {(!progressStatus) && <ProgressBar width={progressStep2 == null ? progressStep1 : progressStep2} className="mb-2" />}

                                {stage?.Step == 4 ? (
                                    <Text type="6" className="d-flex mb-0">
                                        {stage?.taskData?.map((task, index) => 
                                            <span style={{flex:1}}>Task {(index+1)} : {task.completedRespondents}/{task.totalRespondents} </span>
                                        )}
                                    </Text>
                                ) :(
                                    (stage?.notification && stage.Step != 5) && <Text type="6" className="mb-0">{stage?.notification}</Text>
                                )}
                                {
                                    (stage.Step == 5 &&  stage.stepStatus != "completed") && <Text type="6" className="mb-0">{stage?.notification}</Text>
                                }
                            </StatusInfo>
                        }
                    })
                }
            </StatusContainer>
        </ProgressStat>
        <Action>
            {
                (project.steps_array[project.steps_array.length - 1].ModuleDescription == "Analysis" && project.steps_array[project.steps_array.length - 1].stepStatus == "completed") ? (
                    <Button size="mid" onClick={() => sample(project.steps_array,projectId,project?.title)}>View Analysis</Button>
                ) : (
                    <Button size="mid" style={{ width: "128px" }} onClick={() => sample(project.steps_array,projectId,project?.title)}>Resume</Button>  
                )
            }
            {
                (project.steps_array[project.steps_array.length - 1].ModuleDescription == "Analysis" && project.steps_array[project.steps_array.length - 1].stepStatus == "In Progress" && new Date(project.endDuration) < new Date() && project.projectLead_ID == loggedUser.userId ) &&
                        <Button size="mid" style={{ marginTop: '15px' }} onClick={() => endproject(projectId)}>End Project</Button>
            }
        </Action>

    </Card>
}
