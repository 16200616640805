export default function FeedbackMessage({children}){
    return (
        <div 
					style={{ 
						color: '#798e8f',
						fontWeight: '250',
						fontSize: '12px',
						padding: '0.7rem 1.4rem', 
						height: 'auto',
						margin:'10px 5px', 
						borderRadius:'5px', 
						backgroundColor: '#F2F4F4',
						textAlign: 'center',
						fontFamily: 'Noto Sans',
					}}
					>
						{children || "No feedback"}
				</div>
    )
}