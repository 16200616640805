import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';

import { Icon } from '.';
import editGreen from '../images/edit-green.svg';
const TextInput = styled.input`
	border: 0;
	background: transparent;
	resize: none;
	font-size: 11px;
	font-weight: 500;
	color: red;
	width: 100%;
	margin: 0;
	padding: 0;

	&:focus-visible {
		border: 0;

		outline: none;
	}
`;

const IconCustom = styled(Icon)`
	font-size: 0.75rem;
	margin-top: 5px;
`;

const FormContainer = styled.div`
	display: flex;
	width: ${(props) => props.FormContainerWidth};
	padding: 0;
	margin: 0;
	border-bottom: ${(props) =>
		props.disabled
			? '1px solid transparent'
			: `1px solid ${props.color}`};

	form {
		width: 100%;
	}
	input {
		width: ${(props) => props.containerWidth || '100%'};
		min-width: ${(props) => props.minWidth || '60px'};
		font-weight: bold;
		font-size: 12px;
		color: var(--deepBlue);
	}
	span {
		align-self: flex-end;
		cursor: pointer;
		margin-bottom: 0.5rem;
	}
`;

function EditableInput({
	className,
	submit,
	width,
	editnow = false,
	minWidth,
	value = '',
	color = 'var(--sapGreen)',
	inputStyle,
	autoFocus = false,
	FormContainerWidth = '100%',
}) {
	const [text, setText] = useState(value);
	const [edit, setEdit] = useState(editnow);
	const onChangeText = (e) => {
		setText(e.target.value);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		submit(text);
		setEdit(false);
	};
	useEffect(() => {
	    setText(value)
  	}, [value]);
	return (
		<FormContainer
			FormContainerWidth={FormContainerWidth}
			containerWidth={width}
			minWidth={minWidth}
			className={className}
			disabled={!edit}
			color={color}
		>
			<form onSubmit={handleSubmit}>
				<TextInput
					autoFocus={autoFocus}
					disabled={!edit}
					value={text}
					onChange={onChangeText}
					style={inputStyle}
				/>
			</form>
			{!edit ? (
				<img
					src={editGreen}
					onClick={() => {
						setEdit((prev) => !prev);
					}}
					style={{cursor: 'pointer'}}
				/>
			) : (
				
				<div role='button' type='Submit' style={{ height: '12px' }} onClick={handleSubmit}>
					<IconCustom className='my-0' color={color}>
						subdirectory_arrow_left
					</IconCustom>
				</div>
			)}
		</FormContainer>
	);
}

export default EditableInput;
