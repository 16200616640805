import constants from "../../constants";


const {REQUEST_EDITCLIENTADMINDATA, EDITCLIENTADMINDATA_RECEIVED} = constants.EditClientAdminConstants;


export default{
    REQUEST_EDITCLIENTADMINDATA,
    EDITCLIENTADMINDATA_RECEIVED,

    requestEditClientAdminData: payload => ({
        type: REQUEST_EDITCLIENTADMINDATA,
        payload
    }),
    editClientAdminDataReceived: () => ({
        type: EDITCLIENTADMINDATA_RECEIVED
    })
}