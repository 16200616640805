import styled from '@emotion/styled';

const StyledSwitch = styled.label`
	// display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: -35px;
	.outer {
		height: 16px;
		width: 28px;
		border-radius: 10px;
		border: ${(props) => `1px solid ${props.inactiveColor}`};
		position: relative;
		margin-bottom: 1px;
		display: inline-block;
	}

	.inner {
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: transparent;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(2px, -50%);
		z-index: 1;
		background: ${(props) => props.inactiveColor};
	}

	.text {
		font-size: ${(props) => props.fontSize || '12px'};
		font-weight: 500;
		color: var(--quadColor);
		margin-right: 5px;
	}

	input {
		display: none;

		// code for using isDisable
		:not(:disabled){
			:checked ~ .outer {
				border: ${(props) => `1px solid ${props.activeColor}`};
			}
		}
		

		:checked ~ .outer .inner {
			background: ${(props) => props.activeColor};
			transform: translate(12px, -50%);
		}

		:checked ~ .text {
			color: ${(props) => props.activeColor};

			font-weight: 800;
		}
	}
`;

export default function Switch({
	children,
	name,
	inactiveColor = 'var(--quadColor)',
	activeColor = 'var(--primaryColor)',
	value,
	checked,
	isDisabled = false, //default is not disabled
	className = '',
	fontSize,
	onChange,
}) {
	return (
		<StyledSwitch
			activeColor={activeColor}
			inactiveColor={inactiveColor}
			className={className}
			fontSize={fontSize}
		>
			<input
				type='checkbox'
				name={name}
				value={value}
				checked={checked}
				onChange={onChange}
				disabled={isDisabled}
			/>

			<span className='text'>{children}</span>
			<span className='outer'>
				<span className='inner'></span>
			</span>
		</StyledSwitch>
	);
}
