import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetVideoMethods from '../../utils/api/field/GetVideoData';

function* GetVideoData() {
  const promise = GetVideoMethods.GetVideoData();
  const { res } = yield promise;
  yield putResolve({ type: actions.GetVideoAction.GETVIDEO_RECEIVED, data: res.Response });
}

export { GetVideoData };
