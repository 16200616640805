import styled from '@emotion/styled';

const ProgressMultiple = styled.div`
	border-radius: 1rem;
	height: 0.7rem;
	background-color: #e2e2e2;
	margin-bottom: 5px;

	.progress-bar {
		width: ${(props) => props.width + '%' || '0%'};
		background-color: var(--progressColor);
		border-radius: 1rem;
	}

	.progress-bar1 {
		width: ${(props) => props.width + '%' || '0%'};
		background-color: var(--progressColor);
		border-radius: 1rem;
        opacity: 0.5;
        margin-left:-5px;
	}
`;

export default function ProgressMultipleBar({ width, className = '', progress1 = '0', progress2 = '0' }) {
	return (
		<ProgressMultiple className={`progress ${className}`}>
			<div
				className="progress-bar"
				role="progressbar"
				aria-valuenow="50"
				aria-valuemin="0"
				aria-valuemax="100"
				style={{ width: progress1 }}
			/>
			<div
				class="progress-bar1"
				role="progressbar"
				aria-valuenow="30"
				aria-valuemin="0"
				aria-valuemax="100"
				style={{ width: progress2 }}
			/>
		</ProgressMultiple>
	);
}
