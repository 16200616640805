import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Icon, Text } from '../../common';
import Cropper from 'react-easy-crop';
import profile_pic from '../../images/profile_pic.png';
import getCroppedImg from '../../views/Profile/cropImage';

const Image = styled.img`
	height: 200px;
	width: 200px;
	border-radius: 100px;
`;
const CustomLabel = styled.label`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	/* identical to box height */

	/* Colour/Sap Green/Text */

	color: var(--sapGreen);
	padding: 0;
	margin-bottom: 0;
	padding-bottom: 0;

	border-bottom: 1px solid var(--sapGreen);
	&:hover {
		color: var(--sapGreen);
	}
`;

const StyledButton = styled(Button)`
	width: 140px;
`;

function ChangeProfilePicModal() {

    const [image_crop, setImage_crop] = useState(profile_pic);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1.5);
	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [GetProfileData,setGetProfileData] = useState([]);

    const onCropComplete = useCallback(
		async (croppedArea, croppedAreaPixels) => {
			try {
				const croppedImage = await getCroppedImg(
					image_crop,
					croppedAreaPixels
				);

				setCroppedImage(croppedImage);
			} catch (e) {
				console.error(e);
			}
		},
		[croppedImage]
	);

    const handleConfim = async (evt) => {
		let imageName = evt.target.name;
		let file = await fetch(croppedImage).then(r => r.blob()).then(blobFile => new File([blobFile], imageName, { type: "image/png" }));
		setGetProfileData({
			...GetProfileData,
			[imageName]: croppedImage,
			"file": file
		});
	};

  return (
   <>
        {/* New Modal for change Profile picture */}
        <div
                    className='modal fade '
                    id='changeProfilePicModal'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='changeProfilePicLabel'
                    aria-hidden='false'
                >
                    <div
                        className='modal-dialog modal-lg modal-dialog-centered'
                        role='document'
                        style={{
                            width: '620px',
                        }}
                    >
                        <div className='modal-content' style={{ height: '300px' }}>
                            <div className='modal-body'>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <Icon>close</Icon>
                                </button>

                                <div
                                    className='d-flex align-items-center px-3 py-4'
                                    style={{ position: 'relative' }}
                                >
                                    <div
                                        style={{
                                            width: '200px',
                                            height: '230px',
                                            // background: 'red',
                                            position: 'relative',
                                        }}
                                        className='mr-5'
                                    >
                                        <div
                                            style={{
                                                width: '200px',
                                                height: '230px',
                                            }}
                                        >
                                            <Cropper
                                                cropShape='round'
                                                showGrid={false}
                                                image={image_crop}
                                                crop={crop}
                                                minZoom={0}
                                                zoom={zoom}
                                                aspect={1}
                                                onCropChange={setCrop}
                                                onCropComplete={
                                                    onCropComplete
                                                }
                                                onZoomChange={setZoom}
                                                restrictPosition={false}
                                                cropSize={{
                                                    width: 170,
                                                    height: 170,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Text
                                            fontSize='18px'
                                            fontWeight='500'
                                            color='var(--deepBlue)'
                                            className='mb-4'
                                        >
                                            Crop Profile Picture
                                        </Text>
                                        <div className='d-flex'>
                                            <StyledButton
                                                fontWeight='700'
                                                fontSize='13px'
                                                size='small'
                                                buttonColor='var(--sapGreen)'
                                                name='profileImagePath'
                                                onClick={handleConfim}
                                                data-dismiss='modal'
                                            >
                                                Confirm
                                            </StyledButton>
                                            <StyledButton
                                                variant='outlined'
                                                fontWeight='700'
                                                fontSize='13px'
                                                size='small'
                                                buttonColor='var(--sapGreen)'
                                                className='ml-2'
                                                data-dismiss='modal'
                                            >
                                                Cancel
                                            </StyledButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END */}
   </>
  )
}

export default ChangeProfilePicModal
