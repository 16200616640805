import styled from '@emotion/styled';
import React from 'react';

const OL = styled.ol`
	background: transparent;
	font-size: 0.75rem;
	margin: 0;
	padding: 0;
	font-weight: 500;
	.breadcrumb-item.active {
		color: var(--deepBlue);
	}
	li:not(.active) {
		a {
			color: var(--snapGreen);
		}
	}
`;
function Breadcurb({ children }) {
	return <OL className='breadcrumb'>{children}</OL>;
}

export default Breadcurb;
