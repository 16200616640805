import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetTaskStatusMethods from '../../utils/api/field/GetTaskStatusData';

function* GetTaskStatusData(payload) {
  const promise = GetTaskStatusMethods.GetTaskStatusData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetTaskStatusAction.TASK_COMPLETION_STATUS_RESPONSE, data: res.Response });
}

export  { GetTaskStatusData }

