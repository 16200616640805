import React, { useState,useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Button, Heading, Card, Text } from '../../common';
import { FormGroup, Label, InputText, ButtonRadio } from '../../components/form';
import sign_in_background from '../../assets/images/sign_in_background.jpg';
import { createBrowserHistory } from 'history';
import {REQUEST_TOKEN_VALIDATE} from '../../constants/SuperAdmin/TokenValidateConstant';
import {REQUEST_UPDATE_PASSWORD}  from '../../constants/SuperAdmin/UpdatedPasswordConstant';


const ResetPasswordSection = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 1rem;

  .container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const SignInCard = styled(Card)`
  width: 580px;
  border: 1px solid var(--borderColor);
  padding: 1.8rem 2.75rem;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #898989;
`;

const SmallLinkButton = styled(Button)`
  font-size: 13px;
  padding-right: 0;
  font-weight: 500;
  color: var(--labelColor);
`;

export default function ResetPasswordEmailNotification() {
  const [usertype, setUsertype] = useState('researcher');

  // const [email, setEmail] = useState('');

  const [newPassword, setNewPassword] = useState('');

  const [password, setPassword] = useState('');

  const [resetPasswordStatus, setResetPasswordStatus] = useState('');
  const [GetTokenValidated,setTokenValidate] = useState([]);

  const history = createBrowserHistory({ forceRefresh: true });

  const token = useParams();
 
  useEffect(() => {
		dispatch({
		  type: REQUEST_TOKEN_VALIDATE,
      payload: {
        token: token.token
        }
		});
	  }, []);

	const TokenValidateResult = useSelector(state => state.ResetPasswordEmailNotificationReducer.TokenValidate);
  
	useEffect(() => {
		setTokenValidate(TokenValidateResult);
	},[TokenValidateResult])
  

  const ResendToken = () => {
    dispatch({
        type: REQUEST_UPDATE_PASSWORD,
        payload: GetTokenValidated.user_id
    });
}

const handleUpdate = () => {
  if (newPassword !== password) {
    console.warn('password not matched');
  } else {
    const email = GetTokenValidated.email;
    dispatch({ type: 'RESET_PASSWORD', payload: {email,password} });
    console.warn('Password matched');

      
  }
}


  const dispatch = useDispatch();
  return (

    <ResetPasswordSection className="sign-in-section">
      <BackgroundImage
        src={sign_in_background}
        alt="Maverick running on Different Mobiles"
        className="sign-in-section-img"
      />

      
      <div className="container-fluid">
        <SignInCard className="card sign-in-card mt-5">
        
     
          <form className="sign-in-form">
          {   GetTokenValidated.validation_status ==true ?
            <Heading color="var(--seaBlue)">Reset Password</Heading> :""}
            {   GetTokenValidated.validation_status ==true ?
            <FormGroup className="form-group mb-5">
              <div className="d-flex justify-content-between">
                <StyledLabel>New Password</StyledLabel>
              </div>

              <InputText
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                showCharacters={false}
                className="form-control"
                id="newpassword"
                placeholder="*******"
              />
            </FormGroup>
          : 
           <Heading color="var(--seaBlue)">Reset Password Link Expired</Heading> }

        {   GetTokenValidated.validation_status ==true ?     
            <FormGroup className="form-group mb-5">
              <div className="d-flex justify-content-between">
                <StyledLabel>Confirm Password</StyledLabel>
              </div>

              <InputText
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                showCharacters={false}
                className="form-control"
                id="password"
                placeholder="*******"
              />
              {newPassword !== password ? <p className='notmatch' style={{ color: 'red' }}>Password doesn't match.Try again.</p> : null}
            </FormGroup>
            :""}
            <div className="form-group mt-4">
              {   GetTokenValidated.validation_status ==true ? 
              <Button
                fontSize="1rem"
                size="small"
                onClick={handleUpdate}
              >
                Update Password
              </Button> : ""}
              {/* <Link to="/" className="underline" tabIndex="-1" style={{ float: "right" }}>
                <Text type="2">Not getting the code?</Text> */}
             <Button
        
                fontSize="1rem"
                size="smaill"
                onClick={ResendToken}
                className="underline" tabIndex="-1" style={{ float: "right" }}
              >
             Resend Fresh Link
              </Button>
              {/* </Link> */}
            </div>
         
          </form>
        </SignInCard>
      </div>
    </ResetPasswordSection>
  );
}
