import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetVideoTaskStatusMethods from '../../utils/api/field/GetVideoTaskStatusData';

function* GetVideoTaskStatusData(payload) {
  const promise = GetVideoTaskStatusMethods.GetVideoTaskStatusData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetVideoTaskStatusAction.VIDEO_TASK_COMPLETION_STATUS_RESPONSE, data: res.Response });
}

export  { GetVideoTaskStatusData }

