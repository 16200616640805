import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as EndInterviewMethods from '../../utils/api/project/project';

function* endInterview(payload) {
  const promise = EndInterviewMethods.endInterview(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EndInterviewAction.ENDINTERVIEW_RECEIVED, data: res.Response });
}



export  { endInterview 
}