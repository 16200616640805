import styled from '@emotion/styled';

const StyledTag = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    border: 1px solid var(--lightGrey);
    color: var(--textColor);
    font-size: 12px;
    margin: ${props => props.margin || '0 0.875rem 0.875rem 0'};
`

export default function Tags({ children, margin }) {
    return <StyledTag margin={margin}>{children}</StyledTag>
}