import React from 'react';
import { Text, Card, Button, Divider } from '../../common';
import { InputText } from '../../components/form';
import { Notes } from '../Notes/Notes';
import styled from '@emotion/styled';
import {  useDispatch, useSelector } from 'react-redux';
import { REQUEST_GET_TAGS_LIST } from '../../constants/tag/TagConstants';
import { useState, useEffect } from 'react';

const ButtonBase = styled(Button)`
   width:${(props) => props.width || 'auto'};
   height:${(props) => props.height || 'auto'};
   color:${(props) => props.color};
`;

// const tags = [
// 	'Style Preference',
// 	'Design Consistency',
// 	'Readability',
// 	'Tone of Voice',
// 	'Sense of Place',
// 	'Content Discoverability',
// 	'Content Discoverability',
// 	'CTA Discoverability',
// 	'Broken Action',
// 	'Affordability',
// 	'Choice Overload',
// 	'CTA Cognition',
// 	'Paused Action',
// 	'Path Discoverability',
// 	'Number of clicks',
// 	'Information Overload',
// 	'Intuitive',
// 	'Microcopy',
// 	'Understandability',
// 	'Number of scrolls',
// 	'Choice Theory',
// 	'Difficult Ergonomics',
// 	'Drop off',
// 	'Information Hierarchy',
// 	'Escape/resume route'
// ];

export function AddEditTags() {
	const loggedUser = useSelector(state => state.LoginReducer);
  	const project_ID = useSelector(state => state.ProjectReducer.project_ID);
	const dispatch = useDispatch(); 
	const data = {user_ID : loggedUser.userId, project_ID: 76}
	useEffect(() => {
		dispatch({ type: 'REQUEST_GET_TAGS_LIST', payload: data });
	},[]);
	
	const tagList = useSelector(state => state.TagReducer.tagList);

	const handleChange = (e) => {
		if (e.key === 'Enter') {
			const data1 = {user_ID:loggedUser.userId, projectRepondentVideo_ID:"1", projectTagName:e.target.value, project_ID:project_ID}
			dispatch({ type: 'REQUEST_ADDVIDEOTAG', payload: data1 });
			e.target.value = "";
			dispatch({ type: 'REQUEST_GET_TAGS_LIST', payload: data });
		}
				
	  };
  
	return (
		<div className="mt-4">
			<Text type="5" fontSize="18px" fontWeight="600" color="var(--seaBlue)">
				Tags
			</Text>
			<Text type="3" fontSize="12px" fontWeight="300" color="var(--seaBlue)">
				Add & Edit Tags at specific moments in the video
			</Text>
			<Card className="mt-3 p-3">
				<div className="row">
					<div className="col-md-4 d-flex justify-content-between">
						<div className="pr-0 col-md-80">
							<Notes
								height="162px"
								maxHeight="160px"
								displayTitle={false}
								className="box-shadow-none p-0 m-0"
								type="tag"
							/>
							<div className="mt-0 ">
								<InputText
									placeholder="Start typing to see tags or add new tags"
									showCharacters={false}
									color="var(--grey20)"
									placeholderSize="8.5px"
									fontWeight="500"
									fontSize="0.7rem"
									onKeyDown={(e) => handleChange(e)}
									id = "addtagvideo"
								/>
							</div>
						</div>
						<Divider margin="0" orientation="vertical" height="auto" width="1px" />
					</div>
					<div className="col-md-8 pl-0">
						{tagList.map((data, index) => (
							<ButtonBase
								key={`tagskey-${index}`}
								size="mid"
								variant="outlined"
								buttonColor="var(--lightGrey)"
								fontSize="10px"
								fontWeight="500"
								height="24px"
								padding="0.25rem"
								color="var(--greyBlue)"
								className="m-1"
								value={data.projectTag_ID}
							>
								{data.projectTagName}
							</ButtonBase>
						))}
					</div>
				</div>
			</Card>
		</div>
	);
}
