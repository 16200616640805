import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ErrorText from './ErrorText';
import { Icon } from '../../common';

// const customStyles = {
// 	container: (provided, state) => ({
// 		...provided,
// 		border: 0,
// 		borderRadius: 0,
// 	}),

// 	valueContainer: (provided, state) => ({
// 		...provided,
// 		...state.selectProps.valueContainerStyle,
// 	}),

// 	control: (provided, state) => ({
// 		...provided,
// 		outline: 0,
// 		borderRadius: 0,
// 		border: 0,
// 		background: 'transparent',
// 		// border: '1px solid var(--white)',
// 		// borderRadius: '20px',
// 		borderColor: state.isFocused
// 			? 'var(--inputColor)'
// 			: 'var(--borderColor)',
// 		boxShadow: 'none',
// 		minHeight: '30px',
// 		minWidth: '18px',
// 		'&:hover': {
// 			borderColor: state.isFocused
// 				? 'var(--inputColor)'
// 				: 'var(--borderColor)',
// 		},
// 		...state.selectProps.controlStyle,
// 	}),

// 	menuList: (provided, state) => ({
// 		...provided,

// 		borderRadius: '5px',
// 		width: 'auto',
// 		padding: '0 10px',
// 		border: '1px solid #EFEFEF',
// 		position: 'absolute',
// 		// background: '#8A8A8A',
// 		background: '#FFFFFF',
// 		width: '180px',
// 		zIndex: 5,
// 		boxShadow: 'none',
// 		textAlign: 'left',
// 		'&::-webkit-scrollbar':{
// 			width:'4px',
// 			borderRadius:'100px',
// 		},
// 		'&::-webkit-scrollbar-track':{
// 			background:'#f0f0f0'
// 		},
// 		'&::-webkit-scrollbar-thumb':{
// 			background:'var(--scrollbarColor)'
// 		},
// 		'&::-webkit-scrollbar-thumb:hover':{
// 			background:'var(--scrollbarColor)'
// 		},

// 	}),

// 	option: (provided, state) => ({
// 		fontSize: '0.75rem',
// 		fontWeight: '500',
// 		// color: 'var(--white)',
// 		color: 'var(--inputColor)',
// 		padding: '0px 3px',
// 		paddingTop: '3px',
// 		textAlign: 'left',
// 		cursor: 'pointer',
// 		// borderBottom: '1px solid var(--white)',
// 		borderBottom: '1px solid #E2E2E2',
// 		display: 'flex',
// 		justifyContent: 'space-between',
// 		alignItems: 'center',
// 		// '&:last-child': {
// 		// 	borderBottom: 0,
// 		// },
// 	}),

// 	input: (provided, state) => ({
// 		...provided,
// 		margin: '1px',
// 		fontSize: '11px',
// 		fontWeight: '500',
// 		color: 'var(--white)',
// 		...state.selectProps.inputStyle,
// 	}),

// 	singleValue: (provided, state) => ({
// 		fontSize: state.selectProps.selectedFontSize
// 			? state.selectProps.selectedFontSize
// 			: '11px',
// 		fontWeight: state.selectProps.selectedFontWeight
// 			? state.selectProps.selectedFontWeight
// 			: '500',
// 		color: state.selectProps.selectedColor
// 			? state.selectProps.selectedColor
// 			: 'var(--white)',

// 		...state.selectProps.singleValueStyle,
// 	}),

// 	indicatorSeparator: () => ({
// 		display: 'none',
// 	}),
// 	IndicatorsContainer: () => ({
// 		display: 'none',
// 	}),
// 	placeholder: () => ({
// 		fontSize: '0.75rem',
// 		color: 'var(--mediumGrey)',
// 	}),

// 	// menu: (provided, state) => ({
// 	//   ...provided,
// 	//   width: state.selectProps.width,
// 	//   borderBottom: '1px dotted pink',
// 	//   color: state.selectProps.menuColor,
// 	//   padding: 20,
// 	// }),
// };

const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  margin-left: 5px;
`;

const DownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon style={{ fontSize: '0.75rem' }} color="var(--greyBorder)"></Icon>
    </components.DropdownIndicator>
  );
};

export default function SearchableDropdown({
  onChange,
  options = [],
  value,
  width,
  controlWidth,
  defaultValue,
  placeholder = '',
  className = '',
  Menu,
  SingleValue,
  isClearable = false,
  isSearchable = false,
  name,
  error = false,
  selectedFontSize,
  selectedFontWeight,
  selectedColor,
  DropdownIndicator = DownIndicator,
  selectedBorderBottom,
  singleValueStyle = {},
  valueContainerStyle = {},
  controlStyle = {},
  inputStyle,
  crossOption,
  masterTagList,
  setMasterTagList,
  menuIsOpen,
  setMenuIsOpen,
  passHandleDeleteDispatch,
  passClickedonDelete = null,
  passMtaglist,
  customStylesForDropdown
}) {
  const customStyles = customStylesForDropdown;

  const clickedOnDelete = useRef(false);
  const selectedValue = useRef('');
  const [renderValue, setRenderValue] = useState();
  // const [menuIsOpen, setMenuIsOpen] = useState(false);
  const mtaglist = useRef();

  const handleDeleteOption = id => {
    let index = masterTagList.findIndex(tag => tag.tag_ID === id);
    if (index !== -1) {
      let tempTagsList = [...masterTagList];
      tempTagsList.splice(index, 1);
      mtaglist.current = tempTagsList;
      setMasterTagList(tempTagsList);
    } else {
      console.log('Tag Not Found');
    }
    clickedOnDelete.current = true;
    passClickedonDelete(clickedOnDelete.current);
    passHandleDeleteDispatch(id);
    passMtaglist(mtaglist.current);
  };

  const handleClickOption = () => {
    clickedOnDelete.current = false;
    mtaglist.current = masterTagList;
    if (crossOption) passClickedonDelete(clickedOnDelete.current);
  };

  const handleChange = opt => {
    // console.log("clickedOnDelete.current",clickedOnDelete.current);
    // if(clickedOnDelete.current == false) selectedValue.current = opt.label ;
    onChange(opt);
    if (crossOption) setMenuIsOpen(true);
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  // const handleOutsideClick = (event) => {
  // 	if(event.target.closest('.react-select-container') === null){
  // 		setMenuIsOpen(false);
  // 	}
  // }

  const Option = ({ ...props }) => {
    return (
      <div>
        <components.Option {...props}>
          <span onClick={() => handleClickOption()}>{props.label}</span>
          {crossOption && (
            <IconCustom
              color="var(--inputColor)"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleDeleteOption(props.value);
              }}
            >
              close
            </IconCustom>
          )}
        </components.Option>
      </div>
    );
  };

  let Components = {
    DropdownIndicator,
    Option
  };

  if (Menu) {
    Components = { ...Components, Menu };
  }
  if (SingleValue) {
    Components = { ...Components, SingleValue };
  }

  return (
    <div>
      <CreatableSelect
        key={defaultValue.label}
        styles={customStyles}
        components={Components}
        options={masterTagList}
        value={value}
        defaultValue={defaultValue}
        width={width}
        controlWidth={controlWidth}
        placeholder={placeholder}
        className={className + `${error ? ' is-invalid' : ''}`}
        onChange={handleChange}
        // controlShouldRenderValue={clickedOnDelete ? false : true}
        controlShouldRenderValue={renderValue}
        menuIsOpen={menuIsOpen}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isClearable={isClearable}
        isSearchable={isSearchable}
        // name={name}
        error={error}
        selectedFontSize={selectedFontSize}
        selectedFontWeight={selectedFontWeight}
        selectedColor={selectedColor}
        selectedBorderBottom={selectedBorderBottom}
        singleValueStyle={singleValueStyle}
        valueContainerStyle={valueContainerStyle}
        controlStyle={controlStyle}
        inputStyle={inputStyle}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
}
