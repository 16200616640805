import constants from "../../constants";


const {REQUEST_UPDATE_PASSWORD, REQUEST_UPDATE_PASSWORD_RECEIVED} = constants.UpdatedPasswordConstant;


export default{
    REQUEST_UPDATE_PASSWORD,
    REQUEST_UPDATE_PASSWORD_RECEIVED,

    requestEditPassword: payload => ({
        type: REQUEST_UPDATE_PASSWORD,
        payload
    }),
    requestEditPasswordReceived: () => ({
        type: REQUEST_UPDATE_PASSWORD_RECEIVED
    })
}