import { Card, Divider, Heading, Icon } from "../../common";
import styled from "@emotion/styled";
import respondent_background from "../../assets/images/respondent_background.jpg";
import be_a_respondent1 from "../../assets/images/be_a_respondent1.png";
import voice_your_opinion from "../../assets/images/voice_your_opinion.png";
import get_paid from "../../assets/images/get_paid.png";
import get_invited from "../../assets/images/get_invited.png";
import engage_with_us from "../../assets/images/engage.png";
import google_play_dark from "../../assets/images/google_play_dark.png";
import app_store_dark from "../../assets/images/app_store_dark.png";
import {
	GreenButton,
	GreyButton,
	H1,
	H3,
	Section,
	Step,
	StyledLabel,
	SubContent,
	SubHeading,
} from "./common";
import { useState, useEffect } from "react";
import { Checkbox, FormGroup, InputText } from "../../components/form";
const CustomCard = styled(Card)`
	background: #ffffff;
	/* Colour/5% Grey */

	border: 1px solid #f0f0f0;
	box-sizing: border-box;
	/* Content card drop shadow 21.1 */

	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.05);
	border-radius: 20px;
	padding: 3rem;

	@media (max-width: 720px) {
		.checkbox-container {
			flex-direction: column;

			label {
				width: 100%;
			}
		}
	}
`;

const StyledInput = styled(InputText)`
	border-bottom: 1.5px solid #c2c2c2;
	color: var(--seaBlue);
`;
const HelpForm = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [organisation, setOrganisation] = useState("");
	return (
		<CustomCard>
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<FormGroup className="mb-5">
							<StyledLabel>First Name</StyledLabel>
							<StyledInput
								type="text"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								showCharacters={false}
								className="form-control"
							/>
						</FormGroup>
					</div>
					<div className="col-md-6">
						<FormGroup className="mb-5">
							<StyledLabel>Last Name</StyledLabel>
							<StyledInput
								type="text"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								showCharacters={false}
								className="form-control"
							/>
						</FormGroup>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<FormGroup className="mb-5">
							<StyledLabel>Work Email</StyledLabel>
							<StyledInput
								type="text"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								showCharacters={false}
								className="form-control"
							/>
						</FormGroup>
					</div>
					<div className="col-md-6">
						<FormGroup className="mb-5">
							<StyledLabel>Organisation</StyledLabel>
							<StyledInput
								type="text"
								value={organisation}
								onChange={(e) =>
									setOrganisation(e.target.value)
								}
								showCharacters={false}
								className="form-control"
							/>
						</FormGroup>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-md-12">
						<p
							style={{
								fontSize: "14px",
							}}
						>
							What can we help you with?
						</p>
						<div className="d-flex checkbox-container mt-2">
							<Checkbox
								fontSize="14px"
								fontWeight="400"
								color="var(--seaBlue)"
								className="mr-5"
							>
								{"   "} I want to know more on the platform
							</Checkbox>
							<Checkbox
								fontSize="14px"
								fontWeight="400"
								color="var(--seaBlue)"
								className="mr-5"
							>
								{"   "}How do I become a respondent?
							</Checkbox>
							<Checkbox
								fontSize="14px"
								fontWeight="400"
								color="var(--seaBlue)"
							>
								{"   "} Tell me about the pricing
							</Checkbox>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<GreenButton
							fontSize="18px"
							fontWeight="700"
							color="var(--sapGreen)"
						>
							Submit
						</GreenButton>
					</div>
				</div>
			</div>
		</CustomCard>
	);
};
export default function BeARespondent() {
	const [active, setActive] = useState("1");

	const [scrollTo, setScrollTO] = useState(1)

	useEffect(() => {
		if(scrollTo === 1){
			window.scrollTo(0,0)
		}
	})

	return (
		<>
			<Section className="landing-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<H1 className="mb-3">
									Get paid for what{" "}
									<span className="break">you think </span>
								</H1>
								<SubHeading
									color="var(--seaBlue)"
									className="mb-3"
								>
									Participate in research interviews, and
									advise leading brands on their upcoming
									launches.
								</SubHeading>
								<div>
									<div className="smallScreen mb-2">
										<div className="my-4">
											<img
												style={{
													width: "150px",
												}}
												src={app_store_dark}
											/>
										</div>
										<div className="my-4">
											<img
												style={{
													width: "150px",
												}}
												src={google_play_dark}
											/>
										</div>
									</div>
									<div className="bigScreen">
										<div className="mt-5 d-flex align-items-center ">
											<img
												style={{
													width: "150px",
													marginRight: "20px",
												}}
												src={app_store_dark}
											/>
											<Divider
												height="50px"
												width="1px"
												background="#3A3A3A"
												margin="0 3rem"
											/>
											<img
												style={{
													width: "150px",
													marginLeft: "20px",
												}}
												src={google_play_dark}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<img
									src={be_a_respondent1}
									alt="Landing Image"
								/>
							</div>
						</div>
					</div>
				</div>
			</Section>

			<Section className="steps-section  landing-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<Step
									className={`${
										active === "1" ? "active" : ""
									} mb-5`}
								>
									<h2
										className="mb-0"
										onClick={() => {setActive("1"),setScrollTO(0)}}
									>
										Voice your opinion
									</h2>
									<SubHeading className="mb-2 sub-heading">
										Share your experiences
									</SubHeading>
									<SubContent className="sub-content">
										Get interviewed and tell brands what you
										think of their products. At your
										convenience. On your smartphone.
									</SubContent>
									{active === "1" && (
										<img
											src={voice_your_opinion}
											alt="voice_your_opinion"
											className="smallScreen"
										/>
									)}
								</Step>

								<Step
									className={`${
										active === "2" ? "active" : ""
									} mb-5`}
								>
									<h2
										className="mb-0"
										onClick={() => {setActive("2"),setScrollTO(0)}}
									>
										Get Paid
									</h2>
									<SubHeading className="mb-2 sub-heading">
										Instant payments for a bit of your time
									</SubHeading>
									<SubContent className="sub-content">
										Get paid instantly. All you have to do
										is answer a few questions, try a new
										product or share a recent experience
									</SubContent>
									{active === "2" && (
										<img
											src={get_paid}
											alt="get_paid"
											className="smallScreen"
										/>
									)}
								</Step>

								<Step
									className={`${
										active === "3" ? "active" : ""
									} mb-5`}
								>
									<h2
										className="mb-0"
										onClick={() => {setActive("3"),setScrollTO(0)}}
									>
										Get Invited
									</h2>
									<SubHeading className="mb-2 sub-heading">
										Join an elite group for exclusive sneak
										peeks
									</SubHeading>
									<SubContent className="sub-content">
										Get a behind the scenes preview into
										what the world’s best brands are
										launching. Free samples and priority
										testing make you the first to know
										what’s coming up.
									</SubContent>
									{active === "3" && (
										<img
											src={get_invited}
											alt="get_invited"
											className="smallScreen"
										/>
									)}
								</Step>
							</div>
							<div className="col-md-6">
								{active === "1" && (
									<img
										src={voice_your_opinion}
										alt="voice_your_opinion"
										className="bigScreen"
									/>
								)}
								{active === "2" && (
									<img
										src={get_paid}
										alt="get_paid"
										className="bigScreen"
									/>
								)}
								{active === "3" && (
									<img
										src={get_invited}
										alt="get_invited"
										className="bigScreen"
									/>
								)}
							</div>
						</div>
					</div>

					{/* <StyledLandingImage src={desktop_mobile} alt="Maverick running on Desktop and Mobile" className="landing-section-img"/> */}
				</div>
			</Section>

			<Section className="landing-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<H1 className="mb-3">Engage with us.</H1>
								<SubHeading
									color="var(--seaBlue)"
									className="mb-3"
								>
									Start a free trial without a credit card, no
									strings attached! Or take a tour and learn
									more.
								</SubHeading>

								<div className="mt-5">
									<a href="#contact-form"><GreenButton className="mr-3">
										Join Waitlist
									</GreenButton></a>
									{/* <GreyButton>Take a Tour</GreyButton> */}
								</div>
							</div>
							<div className="col-md-6">
								<img
									src={engage_with_us}
									alt="engage_with_us"
								/>
							</div>
						</div>
					</div>
				</div>
			</Section>

			<Section className="landing-section" id="contact-form">
				<div className="container">
					<div className="content mb-5">
						<div className="row align-items-center">
							<div className="col-md-6">
								<H1 className="mb-3">Still have questions?</H1>
								<SubHeading
									color="var(--seaBlue)"
									className="mb-3"
								>
									You can choose to fill the form below or
									talk to one of our representatives. We’ll
									get back to you at the earliest
								</SubHeading>
							</div>
							<div className="col-md-5 offset-md-1">
								<div className="d-flex align-items-center mb-3">
									<Icon color="var(--seaBlue)">email</Icon>
									<p
										className="mb-0 pb-0 ml-2"
										style={{
											fontSize: "14px",
											fontWeight: "700",
											color: "var(--seaBlue)",
										}}
									>
										hello@explorastory.in
									</p>
								</div>
								<div className="d-flex align-items-center">
									<Icon color="var(--seaBlue)">call</Icon>
									<p
										className="mb-0 pb-0 ml-2"
										style={{
											fontSize: "14px",
											fontWeight: "700",
											color: "var(--seaBlue)",
										}}
									>
										+91 22 49559797/ +91 22 28596358/ +91 22
										28594394
									</p>
								</div>
							</div>
						</div>
					</div>
					<Card className="mt-5" withBorder={true}>
						<div className="row ">
							<div className="col-md-6 mb-4">
								<StyledLabel>First name</StyledLabel>
								<InputText showCharacters={false} />
							</div>
							<div className="col-md-5 offset-md-1 mb-4">
								<StyledLabel>Last name</StyledLabel>
								<InputText showCharacters={false} />
							</div>
						</div>

						<div className="row ">
							<div className="col-md-6 mb-4">
								<StyledLabel>Work email</StyledLabel>
								<InputText showCharacters={false} />
							</div>
							<div className="col-md-5 offset-md-1 mb-4">
								<StyledLabel>Organisation</StyledLabel>
								<InputText showCharacters={false} />
							</div>
						</div>

						{/* <div className="row mb-4">
							<div className="col-md-12">
								<p
									style={{
										fontSize: "14px",
									}}
								>
									What can we help you with?
								</p>
								<div className="d-flex checkbox-container mt-2">
									<Checkbox
										fontSize="14px"
										fontWeight="400"
										color="var(--seaBlue)"
										className="mr-5"
									>
										{"   "} I want to know more on the
										platform
									</Checkbox>
									<Checkbox
										fontSize="14px"
										fontWeight="400"
										color="var(--seaBlue)"
										className="mr-5"
									>
										{"   "}How do I become a respondent?
									</Checkbox>
									<Checkbox
										fontSize="14px"
										fontWeight="400"
										color="var(--seaBlue)"
									>
										{"   "} Tell me about the pricing
									</Checkbox>
								</div>
							</div>
						</div> */}

						<GreenButton className="mt-5">Submit</GreenButton>
					</Card>
				</div>
			</Section>
		</>
	);
}
