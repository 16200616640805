import ajax from './ajaxHelper';
import { maverickPortalconfig } from '../../config/apiConfig';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';
import AuthService from "../authService/AuthService";

const host = maverickPortalconfig.env[0].DEV.hostname;
export const postRequest = async (url, data, callback) => {
	const token = AuthService.getToken();
	await ajax
    .post(host+url, data, {
      networkParams: { showLoader: false },
      headers: {
      	'Content-Type': 'application/json',
        'Authorization': token
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err?.response?.status == '401') {
        window.location = '/';
      }
    });
}