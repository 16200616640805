import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddNewNoteMethods from '../../utils/api/field/AddNewNoteData';

function* AddNewNoteData(payload) {
  const promise = AddNewNoteMethods.AddNewNoteData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddNewNoteAction.ADDNEWNOTE_RECEIVED, data: res.Response });
}

export  { AddNewNoteData }