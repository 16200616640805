import maverickPortalhttp from '../../webService/webService';
import maverickPortalApiEndPoint from '../../../config/apiEndPoint';
import AuthService from '../../authService/AuthService';

const loginUser = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(maverickPortalApiEndPoint.login.url, maverickPortalApiEndPoint.login.methodType, data)
      .then(response => {
        if (response.data.Response !== undefined && response.data.Response?.token_data.access_token.length) {
          AuthService.login(response.data.Response);
        } else if (response.data?.Status?.StatusCode == -2) {
          localStorage.removeItem('token');
          localStorage.setItem(response.data?.Status.StatusDesc);
          history.push('/#/sign-in');
          resolve({ res });
        } else {
          console.log(response.data?.Status?.StatusDesc);
        }
        const res = response.data;
        if (res.Response) {
          resolve({ res });
        }
      });
  });
};

const logoutUser = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(maverickPortalApiEndPoint.logout.url, maverickPortalApiEndPoint.logout.methodType, data)
      .then(response => {
        if (response?.data?.Response?.token_data.message === 'Successfully logged out') {
          AuthService.logout();
          location.href = '/#/sign-in';
          //history.push('/#/sign-in');
          //localStorage.removeItem('token')
        } else if (response.data?.Status?.StatusCode == -2) {
          localStorage.removeItem('token');
          location.href = '/#/sign-in';
          resolve({ res });
        } else {
          localStorage.removeItem('token');
          location.href = '/#/sign-in';
        }
        const res = response.data;
        resolve({ res });
      })
      .catch(e => {
        localStorage.removeItem('token');
        location.href = '/#/sign-in';
        resolve(true);
      });
  });
  // return new Promise(function request(resolve) {

  // resolve(true);
  // });
};

export { loginUser, logoutUser };
