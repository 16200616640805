import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Text } from './';
export default class RadioButton extends Component {
	state = {};

	render() {
		const { selected , onChange, text, value } = this.props;
		return (
			<div className="modern-radio-container">
				<div className={`radio-outer-circle ${!selected && 'unselected'}`}>
					<div className={`radio-inner-circle ${!selected && 'unselected-circle'}`} />
				</div>
				<Text
					type={ selected ? '8' : '5'}
					fontSize="14px"
					className={selected ? 'helper-text-selected' : 'helper-text'}
				>
					{text}
				</Text>
			</div>
		);
	}
}

// RadioButton.propTypes = {
//   text: PropTypes.node.isRequired,
//   onChange: PropTypes.func.isRequired,
//   selected: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired
// };
