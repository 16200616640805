import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as viewUserInGeneralpoolMethods from '../../utils/api/superAdmin/ViewUserInGeneralPoolData';

function* viewUserInGeneralPoolData(payload) {
  const promise = viewUserInGeneralpoolMethods.viewUserInGeneralPool(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ViewUserInGeneralPoolAction.USERINGENERALPOOL_DATA_RECEIVED, data: res.Response });
}

export  { viewUserInGeneralPoolData }