import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Button, Heading, Card, Text } from '../../common';
import { FormGroup, Label, InputText, ButtonRadio } from '../../components/form';
import sign_in_background from '../../assets/images/sign_in_background.jpg';
import { createBrowserHistory } from 'history';

const ResetPasswordSection = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 1rem;

  .container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const SignInCard = styled(Card)`
  width: 580px;
  border: 1px solid var(--borderColor);
  padding: 1.8rem 2.75rem;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #898989;
`;

const SmallLinkButton = styled(Button)`
  font-size: 13px;
  padding-right: 0;
  font-weight: 500;
  color: var(--labelColor);
`;

export default function ResetPassword() {
  const [usertype, setUsertype] = useState('researcher');

  // const [email, setEmail] = useState('');

  const [newPassword, setNewPassword] = useState('');

  const [password, setPassword] = useState('');

  const [resetPasswordStatus, setResetPasswordStatus] = useState('');

  const history = createBrowserHistory({ forceRefresh: true });

  const emailEntered = useParams();
  // console.warn(emailEntered.email);
  const email = emailEntered.email;
  // console.warn(email);

  // const value = useSelector(state => state);
  // console.warn(value)

  const handleUpdate = () => {
    if (newPassword !== password) {
      console.warn('password not matched');
    } else {

      dispatch({ type: 'RESET_PASSWORD', payload: {email,password} });
       console.warn('Password matched');

        
    }
  }



  const dispatch = useDispatch();

  return (
    <ResetPasswordSection className="sign-in-section">
      <BackgroundImage
        src={sign_in_background}
        alt="Maverick running on Different Mobiles"
        className="sign-in-section-img"
      />

      <div className="container-fluid">
        <SignInCard className="card sign-in-card mt-5">
          <Link to={`/verify-code/${email}`} className="underline" tabIndex="-1" style={{ float: "right" }}>
            <Text type="2">{'<'}Back</Text>
          </Link>
          <form className="sign-in-form">
            <Heading color="var(--seaBlue)">Reset Password</Heading>
            <p>{emailEntered.email}</p>
            <div className="mt-4 mb-4">
              {/* <ButtonRadio
                text="Researcher"
                className="mr-3"
                name="user-type"
                value="researcher"
                checked={usertype === 'researcher'}
                onClick={() => setUsertype('researcher')}
              /> */}

              {/* <ButtonRadio
                text="Observer"
                className="mr-3"
                name="user-type"
                value="observer"
                checked={usertype === 'observer'}
                onClick={() => setUsertype('observer')}
              /> */}
              {/* <ButtonRadio
                text="Admin"
                className="mr-3"
                name="user-type"
                value="admin"
                checked={usertype === 'admin'}
                onClick={() => setUsertype('admin')}
              /> */}
            </div>

            {/* <FormGroup className="mb-5">
              <StyledLabel>Email</StyledLabel>
              <InputText
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
                showCharacters={false}
                className="form-control"
                id="email"
                placeholder="you@example.com"
              />
            </FormGroup> */}

            <FormGroup className="form-group mb-5">
              <div className="d-flex justify-content-between">
                <StyledLabel>New Password</StyledLabel>
              </div>

              <InputText
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                showCharacters={false}
                className="form-control"
                id="newpassword"
                placeholder="*******"
              />
            </FormGroup>


            <FormGroup className="form-group mb-5">
              <div className="d-flex justify-content-between">
                <StyledLabel>Confirm Password</StyledLabel>
              </div>

              <InputText
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                showCharacters={false}
                className="form-control"
                id="password"
                placeholder="*******"
              />
              {newPassword !== password ? <p className='notmatch' style={{ color: 'red' }}>Password doesn't match.Try again.</p> : null}
            </FormGroup>
            <div className="form-group mt-4">
              {/* <Button type="submit" loading={false}>Sign In</Button> */}

              <Button
                disabled={!email || !newPassword || !password || newPassword !== password}
                fontSize="1.125rem"
                size="mid"
                // onClick={() => {
                //   localStorage.setItem('user', usertype);

                //   dispatch({ type: 'REQUEST_USER_LOGIN', payload: {email,password} });
                // }}
                onClick={handleUpdate}
              >
                Update Password
              </Button>
              {/* <Link to="/" className="underline" tabIndex="-1" style={{ float: "right" }}>
                <Text type="2">Not getting the code?</Text>
              </Link> */}
               <div tabIndex="-1" style={{ float: "right", textAlign: "right"}}>
                <Text type="2">Problem Signing in? Contact us at</Text>
                <Text className="underline">hello@explorastory.in</Text>
              </div>
            </div>
            {/* {(resetPasswordStatus == 'Success') 
            ? <p style={{color:'green'}}>Password Changed Status = {resetPasswordStatus}</p>
          : null }
           {(resetPasswordStatus == 'Unsuccess') 
            ? <p style={{color:'Red'}}>Password Changed Status = UnSuccessful</p>
          : null} */}
          </form>
        </SignInCard>
      </div>
    </ResetPasswordSection>
  );
}
