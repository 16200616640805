import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as StartInterviewMethods from '../../utils/api/field/StartInterviewData';

function* StartInterviewData(payload) {
  const promise = StartInterviewMethods.StartInterviewData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.StartInterviewAction.STARTINTERVIEW_RECEIVED, data: res.Response });
}

function* StartInterviewParticipantsData(payload) {
  const promise = StartInterviewMethods.StartInterviewParticipantsData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.StartInterviewAction.SEND_PARTICIPANTS_LIST_RECIEVED_ONSTART, data: res.Response });
}

export { StartInterviewData, StartInterviewParticipantsData };
