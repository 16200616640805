import actions from "../../actions";


const initialState = {
    generalPoolUsersData: null,
    // clientData: null,
    // usersData: null,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actions.ViewUserInGeneralPoolAction.REQUEST_USERINGENERALPOOL_DATA:
            return {
                ...state,
            }
        case actions.ViewUserInGeneralPoolAction.USERINGENERALPOOL_DATA_RECEIVED:
            return {
                ...state,
                generalPoolUsersData: action?.data,
                // superAdminProjectData: action?.data?.superAdminProjectStatus,
                // clientData: action?.data?.clientData,
                // usersData: action?.data?.usersData,
            }

        default:
            return state;
    }
};

export default reducer;