import { useState, useRef, useEffect, useCallback } from 'react'
import WordCloud from 'react-d3-cloud';

export default function BarChart(props) 
{
    let d = props.dataset != undefined ? props.dataset.reduce((f,d)=>{           
        let obj = {
            text: d['word'],
            value: d['count']
        }
        f.push(obj)
        
        return f
    },[]): [];
    
    const [data, setData] = useState(d);
    const wrapperRef = useRef();

    useEffect(() => {
        if(props.dataset != undefined && props.dataset.length > 0)
        {
            let d = props.dataset.reduce((f,d)=>{
                let obj = {
                    text: d['word'],
                    value: d['count']
                }
            
                f.push(obj)
                
                return f
            },[])
            
            setData([...d])
        }
        else
        {
            setData([])
        }
        
    }, [props.dataset])

    const fontSize = useCallback((word) => Math.max(Math.log2(word.value) * 5,5), []);
    
    const onWordClick = useCallback((ev,word) => {
        props.onWordClick(word.text)
      }, []);

    const rotate = useCallback((word) => word.value % 360, []);

    return (
        <>
            <div
                ref={wrapperRef}
                style={{ width: "70vw", height: "200px", margin: "2rem" }}
            >
                <WordCloud
                data={data}
                width={500}
                height={80}
                font="Noto Sans"
                fontStyle="normal"
                fontWeight="bold"
                fontSize={fontSize}
                rotate={rotate}
                padding={5}
                random={Math.random}
                fill={'#664178'}
                onWordClick={onWordClick}
            />
            </div>
        </>
    );
}