import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Text, Avatar, Heading, Icon } from '.';
import styled from '@emotion/styled';
import cross from '../assets/images/cross.svg';
import { InputText } from '../components/form';
import { TemplateCard } from '.';
import './Modal.css';
import profile_pic from '../assets/images/profile_pic.png';
import { REQUEST_GENERATE_SUMMARY_DATA } from '../constants/analysis/generateSummaryConstants';
import {  useDispatch ,useSelector} from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { style } from 'd3';

const Pill = styled.span`
	font-size: 8px;
	font-family: "Readex Pro",
	 sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
`;
const modal1 = {
    width: '300px'
}

const InputTextContainer = styled.div`

    .iconContainer {
        position: absolute;
        top: 30%;
        right: 15px;

    }
    span {
        font-size: 18px;
        color: var(--greyBlue);
    }

`;


export default function GenerateSumaryModal(props) {
    const [isShown, setIsShown] = useState('d-block');
    const {id} = useParams();
    const dispatch = useDispatch();

    const handleClose = () => {
        setIsShown('')
    }
   
    
    const handleSummaryGeneration = () =>{
        dispatch({
            type: REQUEST_GENERATE_SUMMARY_DATA,
            payload: {
                project_ID:id
               
            }
        });
        props.setGenerated(true)

        setIsShown('')
    }
    return (
        
                       

                <div className={`modal ${isShown}`} style={{backgroundColor: 'rgba(64,64,64,0.8)'}}>
                   
                    <div className="modal-dialog  modal-dialog-centered"  role="document" tabIndex="-1" style={{ maxWidth: "500px",padding: '1%'}}>
                        <div class="modal-content" style={{borderRadius: "10px"}} >
                            <div class="modal-body" style={{fontFamily: 'Noto Sans',fontStyle: 'normal'}} >
                                <h3 style={{color: "#38454F",fontWeight: "600"}}>Generate Summaries</h3>

                                <p style={{color: "#38454F",fontWeight: "500"}}>Your plan is eligible to generate AI summaries for video questions. Would you like to proceed?</p>

                                <div className='row' style={{ paddingTop: '20px',display:'flex',justifyContent: 'space-between' }}>
                              
                                        <Button
                                            className='ml-3'
                                            size='small'
                                            width='20px'
                                            variant='outlined'
                                            data-dismiss='modal'
                                            buttonColor="white"
                                            onClick={handleClose}
                                            style={{border: "1px solid rgba(56, 69, 79, 0.67)", color: 'rgba(56, 69, 79, 0.69)'}}
                                            
                                        >
                                            Cancel
                                        </Button>
                                   
                                    
                                        <Button
                                            
                                            size='small'
                                            width='20px'
                                            data-dismiss="modal"

                                            onClick={handleSummaryGeneration}
                                            style={{marginLeft: 0,marginRight:'3%'}}
                                        >
                                           Yes, Generate Summaries
                                        </Button>


                                    
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                
            
        
    )
}
