import { Link } from 'react-router-dom';
import { Text } from '.';
import Card from './Card';
import Heading from './Heading';

export default function NotificationCard({ children, heading="Notifications" }) {
    return (
        <Card padding="0">
            <div className="d-flex justify-content-between align-items-center p-4">
                <Heading fontWeight="700" type="6" color="var(--quadColor)" className="mb-0">{heading}</Heading>
                <Link to='/notifications' className="underline">
                    <Text type="6">View All</Text>
                </Link>
            </div>
            {children}
        </Card>
    )
}