import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Text, Button, Icon } from '../../common';
import Previous from '../../images/previous.png';
import Next from '../../images/next.png';
import { Handle, Track } from './RangeSlider';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

const VideoTime = styled.div`
  width: 82px;
  height: 51px;
  background: var(--darkGrey);
  opacity: 0.95;
  border-radius: 0px 0px 0px 10px;
`;

const ButtonBase = styled(Button)`
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  color: ${props => props.color};
`;

export function VideoClipTime(duration) {
  return (
    <VideoTime className="d-flex justify-content-center align-items-center">
      <Text type="5" fontSize="10px" color="#F3F3F3">
        <Duration seconds={duration.played} /> / <Duration seconds={duration.duration} />
      </Text>
    </VideoTime>
  );
}

const leftcurl = '{';
const rightCurl = '}';

export function Seperator({ children, type = 4, size = '19px', color = 'var(--white)' }) {
  return (
    <Text type={type} fontSize={size} color={color}>
      {children}
    </Text>
  );
}

export function AutoCreateClips() {
  return (
    <div className="d-flex">
      <ButtonBase width="120.15px" height="23.52px" fontSize="10px" className="p-0 ml-3">
        Auto-create Clips
      </ButtonBase>
      <div className="d-flex w-25 justify-content-between ml-3">
        <Seperator type="4" size="19px" color="var(--white)">
          {leftcurl}
        </Seperator>
        <Seperator type="4" size="19px" color="var(--white)">
          {rightCurl}
        </Seperator>
      </div>
    </div>
  );
}

export function ControlButton({ icon, size = '14.13px', img = null, clickFunction }) {
  if (img) {
    return (
      <ButtonBase simple fontSize={size} className="p-0 d-flex" onClick={clickFunction}>
        <img src={img} />
      </ButtonBase>
    );
  }
  return (
    <ButtonBase simple fontSize={size} className="p-0 d-flex">
      <Icon style={{ color: 'var(--white)', fontSize: size }} onClick={clickFunction}>
        {icon}
      </Icon>
    </ButtonBase>
  );
}

export function Duration({ className, seconds }) {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  );
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(string) {
  return ('0' + string).slice(-2);
}
