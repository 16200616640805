import * as LoginConstants from './login/LoginConstants';
import * as TagsConstants from './tag/TagConstants';
import * as ProjectConstants from './projects/ProjectConstants';
import * as Projectsetdata from './invite-recruit/Projectsetdata';
import * as RecruitsGroupConstant from './invite-recruit/RecruitsGroupConstant';
import * as SendInviteConstant from './invite-recruit/SendInviteConstant';
import * as AddVideoTagConstant from './field/AddVideoTagConstant';
import * as AddVideoNoteConstant from './field/AddVideoNoteConstant';
import * as AddVideoTrimConstants from './field/AddVideoTrimConstants';
import * as GetAllTrimClipsConstants from './field/GetAllTrimClipsConstants';
import * as GetVideoTagConstant from './field/GetVideoTagConstant';
import * as GetVideoNoteConstant from './field/GetVideoNoteConstant';
import * as GetVideoConstants from './field/GetVideoConstants';
import * as SendRejectionConstant from './invite-recruit/SendRejectionConstant';
import * as RecruitScreener from './invite-recruit/RecruitScreener';
import * as GetInviteeConstants from './invite-recruit/GetInviteeConstants';
import * as ConfirmAndAssignConstants from './invite-recruit/ConfirmAndAssignConstants';
import * as ReassignProjectGroupsConstants from './invite-recruit/ReassignProjectGroupsConstants';
import * as ConfirmRespondentConstants from './invite-recruit/ConfirmRespondentConstants';
import * as GetTaskStatusConstants from './field/GetTaskStatusConstants';
import * as GetFieldTaskConstants from './field/GetFieldTaskConstants';
import * as GetTaskConstants from './field/GetTaskConstants';
import * as GetVideoTaskStatusConstants from './field/GetVideoTaskStatusConstants';
import * as PublishConstants from './publish/PublishConstants';
import * as webDashboardConstants from './dashboard/webDashboardConstants';
import * as FieldOverviewConstants from './field/GetFieldOverviewConstants';
import * as GetCaptureGuideConstants from './field/GetCaptureGuideConstants';
import * as AddNewNoteConstant from './field/AddNewNoteConstant';
import * as GetRecordTaskVideoConstants from './field/GetRecordTaskVideoConstants';
import * as AddTimeVideoQuestionsConstant from './field/AddTimeVideoQuestionsConstant';
import * as FieldPreEditorConstant from './field/FieldPreEditorConstant';
import * as StartInterviewConstant from './field/StartInterviewConstant';
import * as EndInterviewConstant from './field/EndInterviewConstant';
import * as DeleteVideoNoteConstant from './field/DeleteVideoNoteConstant';
import * as DeleteVideoTagConstant from './field/DeleteVideoTagConstant';
import * as EditVideoNoteConstant from './field/EditVideoNoteConstant';
import * as EditButtonNameConstant from './field/EditButtonNameConstant';
import * as GetProfileData from './profile/GetProfileData';
import * as EditProfileData from './profile/EditProfileData';
import * as analysisConstants from './analysis/analysisConstants';
import * as publishProjectConstants from './publish/publishProjectConstants';
import * as DeleteVideoClipConstant from './field/DeleteVideoClipConstant';
import * as ChangePasswordWeb from './Security/ChangePasswordWeb';
import * as EditClipConstant from './field/EditClipConstant';
import * as AddRescheduleSlot from './field/AddRescheduleSlot';
import * as endProjectConstants from './dashboard/endProjectConstants';
import * as AddRespondentAnswerConstant from './field/AddRespondentAnswerConstant';
import * as clientDashboardConstants from './clientAdmin/clientDashboardConstants';
import * as EditTeamdataConstants from './clientAdmin/EditTeamdataConstants';
import * as AddNewUserConstants from './clientAdmin/AddNewUserConstants';
import * as EditUserdataConstants from './clientAdmin/EditUserdataConstants';
import * as AddNewClientAdminConstants from './SuperAdmin/AddNewClientAdminConstants';
import * as SuperAdminDashboardConstants from './SuperAdmin/SuperAdminDashboardConstants';
import * as EditClientAdminConstants from './SuperAdmin/EditClientAdminConstants';
import * as ViewUserInGeneralPool from './SuperAdmin/ViewUserInGeneralPoolConstants';
import * as AddNewUserGenPoolConstants from './SuperAdmin/AddNewUserGenPoolConstants';
import * as EditResearcherDataGenPoolConstants from './SuperAdmin/EditResearcherDataGenPoolConstants';
import * as EditRespondantsDataGenPoolConstants from './SuperAdmin/EditRespondantDataGenPoolConstants';
import * as ViewProjectSuperAdminDashboardConstants from './SuperAdmin/ViewProjectSuperAdminDashboardConstants';
import * as DeleteProjectConstants from './clientAdmin/DeleteProjectConstants';
import * as OtpConstants from './otp/OtpConstants';
import * as otpVerification from './otpVerify/otpVerifyconstants';
import * as calenderDataConstants from './dashboard/calenderDataConstants'
import * as summaryDataConstants from './field/GetSummaryDataConstant';
import * as generateSummaryConstants from './analysis/generateSummaryConstants';
import * as checkSummaryConstants from './analysis/checkSummaryConstants';

import * as UpdatedPasswordConstant from './SuperAdmin/UpdatedPasswordConstant';
import * as TokenValidateForEmailPassword from './SuperAdmin/TokenValidateConstant';
export default {
  LoginConstants,
  TagsConstants,
  ProjectConstants,
  Projectsetdata,
  RecruitsGroupConstant,
  SendInviteConstant,
  AddVideoTagConstant,
  AddVideoNoteConstant,
  AddVideoTrimConstants,
  GetAllTrimClipsConstants,
  GetVideoTagConstant,
  GetVideoNoteConstant,
  GetVideoConstants,
  RecruitScreener,
  SendRejectionConstant,
  GetInviteeConstants,
  ConfirmAndAssignConstants,
  ReassignProjectGroupsConstants,
  ConfirmRespondentConstants,
  GetTaskStatusConstants,
  GetFieldTaskConstants,
  GetTaskConstants,
  GetVideoTaskStatusConstants,
  PublishConstants,
  webDashboardConstants,
  FieldOverviewConstants,
  GetCaptureGuideConstants,
  AddNewNoteConstant,
  GetRecordTaskVideoConstants,
  AddTimeVideoQuestionsConstant,
  FieldPreEditorConstant,
  StartInterviewConstant,
  EndInterviewConstant,
  DeleteVideoNoteConstant,
  DeleteVideoTagConstant,
  EditVideoNoteConstant,
  EditButtonNameConstant,
  GetProfileData,
  EditProfileData,
  analysisConstants,
  publishProjectConstants,
  DeleteVideoClipConstant,
  ChangePasswordWeb,
  EditClipConstant,
  AddRescheduleSlot,
  endProjectConstants,
  AddRespondentAnswerConstant,
  clientDashboardConstants,
  EditTeamdataConstants,
  AddNewUserConstants,
  EditUserdataConstants,
  AddNewClientAdminConstants,
  SuperAdminDashboardConstants,
  EditClientAdminConstants,
  ViewUserInGeneralPool,
  AddNewUserGenPoolConstants,
  EditResearcherDataGenPoolConstants,
  EditRespondantsDataGenPoolConstants,
  ViewProjectSuperAdminDashboardConstants,
  DeleteProjectConstants,
  UpdatedPasswordConstant,
  TokenValidateForEmailPassword,
  OtpConstants,
  otpVerification,
  calenderDataConstants,
  summaryDataConstants,
  generateSummaryConstants,
  checkSummaryConstants
};
