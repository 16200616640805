// String.prototype.getInitials = function(glue){
//     if (typeof glue == "undefined") {
//         var glue = true;
//     }

//     var initials = this.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
    
//     if (glue) {
//         return initials.join('');
//     }

//     return  initials;
// };

// String.prototype.capitalize = function(){
//     return this.toLowerCase().replace( /\b\w/g, function (m) {
//         return m.toUpperCase();
//     });
// };

const getInitials = (text) => {
    return text.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g).join('').toUpperCase();
}

const randomColor = () => '#'+Math.floor(Math.random()*16777215).toString(16);

export {
    getInitials,
    randomColor
}