import React, { useEffect } from 'react';
import { useState } from 'react';

import { Button, Text, Avatar, Heading } from '../..';
import styled from '@emotion/styled';
import {
    InputText,
    TextArea,
    DropFile,
    Dropdown,
    ButtonRadio,
    TaskTypeOption,
    TaskMenu,
} from '../../../components/form';
import { Divider } from '../..';
import Icon from '../../Icon'
import cross from '../../../assets/images/cross.svg';
import GreenDropdown from '../../../assets/images/green-dropdown.svg';
import profile_pic from '../../../assets/images/profile_pic.png';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_ADDNEWUSERGENPOOL } from '../../../constants/SuperAdmin/AddNewUserGenPoolConstants';

const field = {
    marginLeft: "0px"
}

const InputTextContainer = styled.div`
	.iconContainer {
		position: absolute;
		top: 30%;
		right: 15px;
	}
	span {
		font-size: 18px;
		color: var(--greyBlue);
	}
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;
    .reverse {
        transform: rotate(-180deg);
    }
`;
const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	float: right;
    margin-right: 5px;
`;

const gender_list = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' },
];

const city_list = [
    { value: 1, label: 'Ahmedabad' },
    { value: 2, label: 'Bangalore' },
    { value: 3, label: 'Mumbai' },
    { value: 4, label: 'Kolkata' },
    { value: 5, label: 'Delhi' },
];

const qualification_list = [
    { value: 1, label: 'Not Educated' },
    { value: 2, label: 'Literate but no formal schooling' },
    { value: 3, label: 'School (5-9 yrs)' },
    { value: 4, label: 'SSC/HSC' },
    { value: 5, label: 'Some College(Including a diploma but not graduate)' },
    { value: 6, label: 'Graduate/Post-Graduate: General' },
    { value: 7, label: 'Graduate/Post-Graduate: Professional' },
];

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function AddNewUserModal_SA() {

    const [seeMoreProfile, setSeeMoreProfile] = useState(true);
    const [seeMorePrimary, setSeeMorePrimary] = useState(false);
    const [userType, setUserType] = useState('Researcher');
    const [GetUserData, setGetUserData] = useState([]);
    const [productUsed, setProductUsed] = useState({languages: []});
    const [isMarried, setisMarried] = useState('');
    const dispatch = useDispatch();

    const handleChange = (evt) => {
        const value = evt.target.value;

        if (evt.target.name == "marital_status") {
            if (evt.target.value == "married" || evt.target.value == "Married") {
                setisMarried('yes');
            } else {
                setisMarried('no');
            }
        }

        setGetUserData({
            ...GetUserData,
            [evt.target.name]: value,
        });

    };

    const handleChangeCheckbox = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { languages } = productUsed;
          
         
        // Case 1 : The user checks the box
        if (checked) {
          setProductUsed({
            languages: [...languages, value]
          });
        }
      
        // Case 2  : The user unchecks the box
        else {
          setProductUsed({
            languages: languages.filter((e) => e !== value),
            
          });
        }
        };

        useEffect(()=>{
            setGetUserData({
                ...GetUserData,
                'product_used': productUsed.languages,
            });
        },[productUsed])

    // console.warn('Product Used',productUsed.languages);

    // console.warn(isMarried);

    // console.warn('User Data',GetUserData);

    const addUser = () => {

        if (userType == "Researcher") {
            const formData = {
                userRole: "Researcher",
                role_ID: 1,
                full_name: GetUserData.name,
                email: GetUserData.email,
                phone_no: GetUserData.phone_no
            }
            dispatch({
                type: REQUEST_ADDNEWUSERGENPOOL,
                payload: formData
            });
        } else if (userType == "Respondant") {
            const formData = {
                userRole: "Respondant",
                role_ID: 4,
                full_name: GetUserData.name,
                // email: 'q@gmail.com',
                phone_no: GetUserData.phone_no,
                gender_ID: GetUserData.gender_ID,
                profileImage: "",
                dateOfBirth: GetUserData.dateOfBirth,
                isMarried: isMarried,
                noOfKids: GetUserData.noOfKids,
                address: GetUserData.address,
                center_ID: GetUserData.city_ID,
                noOfRooms: GetUserData.noOfRooms,
                HomeType: [GetUserData.house_type],
                ownProfession: GetUserData.occupation,
                ownannualIncome: GetUserData.annualIncome,
                ownEducation_ID: GetUserData.qualification,
                parentProfession: GetUserData.pOccupation,
                parentannualIncome: GetUserData.pAnnualIncome,
                parentEducation_ID: GetUserData.pQualification,
                HomeProduct: GetUserData.product_used
            }
            dispatch({
                type: REQUEST_ADDNEWUSERGENPOOL,
                payload: formData
            });
        }

        // setGetUserData([]);
        // setIsFlag(1);
    }

    const cancelChanges = () => {
        setGetUserData([]);
        // setIsFlag(1);

    }

    return (
        <div>
            <>

                {/* <Button
                    className='ml-4'
                    size='small'
                    variant='outlined'
                    width='50px'
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                >
                    edit
                </Button> */}

                <div className="modal fade" id="addNewUserModal_SA" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" >
                                <Text className='mt-2 ml-2'>New User</Text>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <Avatar
                                                src={profile_pic}
                                                className='team-profile-pic'
                                                width='60'
                                                height='60'
                                            />
                                        </div>

                                        <div className='col ml-4'>
                                            <Heading
                                                fontSize='14px'
                                                className='ml-2'
                                            >
                                                User Role
                                            </Heading>
                                            <div className='col mt-2' style={{ paddingLeft: "5px" }}>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <Text><input type='radio' name='user_type' value="Reseacher" defaultChecked style={{ accentColor: "#728F01" }} onClick={() => setUserType('Researcher')} />    Researcher</Text>
                                                    </div>
                                                    <div className='col'>
                                                        <Text><input type='radio' className='ml-3' name='user_type' value="Respondant" style={{ accentColor: "#728F01" }} onClick={() => setUserType('Respondant')} />    Respondant</Text>
                                                    </div>
                                                    <div className='col'>
                                                        {/* <Text><input type='radio' className='ml-3' name='user_type' value='Observer' style={{ accentColor: "#728F01" }} onClick={() => setUserType('Observer')} />    Observer</Text> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {(userType == 'Researcher') ? (
                                    <div className='container w-75'>
                                        <div className='row'>
                                            {/* PRofile Details */}
                                            <div>
                                                <Heading
                                                    fontSize='14px'
                                                    className='mt-3 ml-3'
                                                >
                                                    Profile
                                                </Heading>

                                                <>
                                                    <InputTextContainer>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='name'
                                                                    placeholder='Full Name'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='phone_no'
                                                                    placeholder='Phone No.'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='email'
                                                                    placeholder='Email ID'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='teamName'
                                                                    placeholder='Team Name'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='designation'
                                                                    placeholder='Designation'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                    </InputTextContainer>
                                                </>
                                            </div>
                                            {/* END */}
                                        </div>
                                    </div>
                                ) : (userType == 'Observer') ? (
                                    <div className='container w-75'>
                                        <div className='row'>
                                            {/* PRofile Details */}
                                            <div>
                                                <Heading
                                                    fontSize='14px'
                                                    className='mt-3 ml-3'
                                                >
                                                    Profile
                                                </Heading>

                                                <>
                                                    <InputTextContainer>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='name'
                                                                    placeholder='Full Name'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='phone_no'
                                                                    placeholder='Phone No.'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={field}>
                                                            <div className='col-8'>
                                                                <InputText
                                                                    name='email'
                                                                    placeholder='Email ID'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                            <div className='col-4'>
                                                                <InputText
                                                                    name='designation'
                                                                    placeholder='Designation'
                                                                    showCharacters={false}
                                                                    onChange={handleChange}
                                                                // showIcon={true}
                                                                // iconName="create"
                                                                // error='Hey, looks like you’ve missed this'
                                                                />
                                                                <Icon className='iconContainer'>create</Icon>
                                                            </div>
                                                        </div>
                                                    </InputTextContainer>
                                                </>
                                            </div>
                                            {/* END */}
                                        </div>
                                    </div>
                                ) :
                                    (
                                        <div className='container w-75'>
                                            <div className='row'>
                                                {/* PRofile Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Profile
                                                    </Heading>
                                                    {/* {seeMoreProfile == true ? ( */}
                                                        <>
                                                            <InputTextContainer>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='name'
                                                                            placeholder='Full Name'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='phone_no'
                                                                            placeholder='Phone No.'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='dateOfBirth'
                                                                            placeholder='Date-of-birth'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <Dropdown
                                                                            name='gender_ID'
                                                                            placeholder='Gender'
                                                                            showCharacters={false}
                                                                            options={gender_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setGetUserData({
                                                                                    ...GetUserData,
                                                                                    'gender': evt.value
                                                                                })
                                                                            }
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        {/* <Icon className='iconContainer'>create</Icon> */}
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='marital_status'
                                                                            placeholder='Marital Status'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    {/* <div className='col-4'>
                                                                        <InputText
                                                                            name='noOfKids'
                                                                            placeholder='No. of Kids'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div> */}
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-12'>
                                                                        <InputText
                                                                            name='address'
                                                                            placeholder='Address'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <Dropdown
                                                                            name='city_ID'
                                                                            placeholder='City'
                                                                            showCharacters={false}
                                                                            options={city_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setGetUserData({
                                                                                    ...GetUserData,
                                                                                    'city_ID': evt.value
                                                                                })
                                                                            }
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        {/* <Icon className='iconContainer'>create</Icon> */}
                                                                    </div>
                                                                    {/* <div className='col-4'>
                                                                        <Text><input type='radio' className='ml-3 mt-3' name='house_type' value='1' onChange={handleChange} style={{ accentColor: "#728F01" }} />    Flat</Text>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <Text><input type='radio' className='ml-3 mt-3' name='house_type' value='2' onChange={handleChange} style={{ accentColor: "#728F01" }} defaultChecked />    Bunglow</Text>
                                                                    </div> */}
                                                                </div>
                                                                {/* <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='noOfRooms'
                                                                            placeholder='No. of rooms'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                            name='occupation'
                                                                            placeholder='Occupation'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                            name='annualIncome'
                                                                            placeholder='Annual Income'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={field}>
                                                                    <div className='col-4'>
                                                                        <Dropdown
                                                                            name='qualification'
                                                                            placeholder='Qualifications'
                                                                            showCharacters={false}
                                                                            options={qualification_list}
                                                                            onChange={(evt) =>
                                                                                //  console.warn(evt.label)
                                                                                setGetUserData({
                                                                                    ...GetUserData,
                                                                                    'qualification': evt.value
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                            </InputTextContainer>
                                                        </>
                                                    {/* ) : null
                                                    } */}
                                                </div>
                                                {/* END */}
                                            </div>
                                            {/* See more Button */}
                                            {/* <SeeButton className='mt-2' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreProfile(!seeMoreProfile)}>{seeMoreProfile ? SeeLess() : SeeMore()}</SeeButton> */}
                                            {/* END */}

                                            {/* <Divider
                                                margin='0'
                                                height='2px'
                                                width='100%'
                                                color='#38454F'
                                                className='mt-2'
                                            /> */}

                                            <div className='row mt-3'>
                                                {/* Products Used at home */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Products Used at home -
                                                    </Heading>
                                                    {/* {seeMorePrimary == true ? ( */}
                                                    <>
                                                        <InputTextContainer>
                                                            <div className='row' style={{width: "133%"}}>
                                                                {/* <div className='col-12'> */}
                                                                    <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='electricity_connection' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Electricity connection</Text>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='LPG_stove' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    LPG Stove</Text>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='refrigerator' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Refrigerator</Text>
                                                                    </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <div className='row' style={{width: "133%"}}>
                                                                {/* <div className='col-12'> */}
                                                                    <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='colour_television' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Colour Television</Text>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='ceiling_fan' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Ceiling Fan</Text>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='washing_machine' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Washing Machine</Text>
                                                                    </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <div className='row' style={{width: "133%"}}>
                                                                {/* <div className='col-12'> */}
                                                                    <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='computer/laptop' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Computer/Laptop</Text>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='four_wheeler' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Four Wheeler</Text>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='air_conditioner' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }}  />    Air Conditioner</Text>
                                                                    </div>
                                                                {/* </div> */}
                                                            </div>
                                                            <div className='row' style={{width: "133%"}}>
                                                                {/* <div className='col-12'> */}
                                                                    <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='two_wheeler' onChange={handleChangeCheckbox} style={{ accentColor: "#728F01" }} />    Two-Wheeler</Text>
                                                                    </div>
                                                                    {/* <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='2' onChange={handleChange} style={{ accentColor: "#728F01" }}  />    Bunglow</Text>
                                                                    </div> */}
                                                                    {/* <div className='col-4'>
                                                                        <Text><input type='checkbox' className='ml-3 mt-3' name='product_used' value='3' onChange={handleChange} style={{ accentColor: "#728F01" }}  />    Text</Text>
                                                                    </div> */}
                                                                {/* </div> */}
                                                            </div>
                                                        </InputTextContainer>
                                                    </>

                                                    {/* ) : null
                                                    } */}
                                                </div>
                                                {/* END */}
                                            </div>

                                            <div className='row mt-3'>
                                                {/* Primary Earning Member Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3'
                                                    >
                                                        Primary Earning Member Details
                                                    </Heading>
                                                    {/* {seeMorePrimary == true ? ( */}
                                                    <>
                                                        <InputTextContainer>
                                                            {/* <div className='row' style={field}>
                                                                    <div className='col-8'>
                                                                        <InputText
                                                                        name='pOccupation'
                                                                            placeholder='Occupation'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <InputText
                                                                        name='pAnnualIncome'
                                                                            placeholder='Annual Income'
                                                                            showCharacters={false}
                                                                            onChange={handleChange}
                                                                        // showIcon={true}
                                                                        // iconName="create"
                                                                        // error='Hey, looks like you’ve missed this'
                                                                        />
                                                                        <Icon className='iconContainer'>create</Icon>
                                                                    </div>
                                                                </div> */}
                                                            <div className='row' style={field}>
                                                                <div className='col-8'>
                                                                    <Dropdown
                                                                        name='pQualification'
                                                                        placeholder='Qualifications'
                                                                        showCharacters={false}
                                                                        options={qualification_list}
                                                                        onChange={(evt) =>
                                                                            //  console.warn(evt.label)
                                                                            setGetUserData({
                                                                                ...GetUserData,
                                                                                'pQualification': evt.value
                                                                            })
                                                                        }
                                                                        selectedFontWeight="700"
                                                                        selectedFontSize= 'small !important'
                                                                        selectedColor= '#757c7cd9 !important'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </InputTextContainer>
                                                    </>

                                                    {/* ) : null
                                                    } */}
                                                </div>
                                                {/* END */}
                                            </div>
                                            {/* See more Button */}
                                            {/* <SeeButton className='mt-3' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMorePrimary(!seeMorePrimary)}>{seeMorePrimary ? SeeLess() : SeeMore()}</SeeButton> */}
                                            {/* END */}
                                        </div>
                                    )
                                }


                            </div>
                            <div className="modal-footer border-0 mt-2" style={{ justifyContent: "flex-start" }}>
                                <CustomButton
                                    className='ml-2 mt-1'
                                    size='small'
                                    // variant='outlined'
                                    width='60px'
                                    // style={{ color: "#798E8F", background: "none" }}
                                    onClick={addUser}
                                >
                                    Save
                                </CustomButton>
                                <CustomButton
                                    className='ml-2 mt-1'
                                    size='small'
                                    variant='outlined'
                                    width='60px'
                                    data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    onClick={cancelChanges}
                                >
                                    Cancel
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}