export const data = [
  {
    title: 'Intending Buyer - Affluent Class',
    groupTag: 'G1',
    sampleSize: 20,
    classification: 'NCCS - A',
    centre: 'Maharashtra',
    gender: 'Female',
    agegroup: '20-40',
    userStatus: 'Intending Buyer',
    recencyOfPurchase: 'New Buyer',
    brandOwner: 'Sony Liv',
    purchaser: 'Purchaser',
    device: 'Mobile',
    network: 'Network'
  },
  {
    title: 'Intending Buyer - Consumer Class',
    groupTag: 'G2',
    sampleSize: 25,
    classification: 'NCCS - B',
    centre: 'Andra Pradesh',
    gender: 'Male',
    agegroup: '20-40',
    userStatus: 'Intending Buyer',
    recencyOfPurchase: 'New Buyer',
    brandOwner: 'Yes Bank',
    purchaser: 'Purchaser',
    device: 'Mobile',
    network: 'Network'
  }
];

export const sections = [
  {
    heading: 'Section 1',
    title: 'Ownership / Purchase',
    description: 'Knowing more about your subscription and purchase history will help us get set to begin the study',
    questions: [
      {
        title: 'Are you a current user or do you intend to buy this product/service?',
        type: 'radio',

        options: [
          {
            key: 'currentuser',
            value: 'Current User',
            selected: true
          },
          {
            key: 'intendingbuyer',
            value: 'Intending Buyer',
            selected: false
          }
        ]
      },
      {
        title: 'When did you subscribe to this product?',
        type: 'checkbox',

        options: [
          {
            key: '0-6',
            value: '< 6 months',
            selected: false
          },
          {
            key: '6-12',
            value: '6-12 months',
            selected: true
          },
          {
            key: '>1',
            value: '> 1 year',
            selected: false
          }
        ]
      },
      {
        title: 'What brand did you purchase/subscribe to?',
        type: 'checkbox',

        options: [
          {
            key: 'Amazon Prime Video',
            value: 'Amazon Prime Video',
            selected: false
          },
          {
            key: 'Netflix',
            value: 'Netflix',
            selected: true
          },
          {
            key: 'Hotstar',
            value: 'Hotstar',
            selected: false
          },
          {
            key: 'Sony LIV',
            value: 'Sony LIV',
            selected: false
          },
          {
            key: 'Other',
            value: 'Other',
            selected: false
          }
        ]
      },
      {
        title: 'Who purchased this subscription?',
        type: 'checkbox',

        options: [
          {
            key: 'Self',
            value: 'Self',
            selected: false
          },
          {
            key: 'Parent',
            value: 'Parent',
            selected: true
          },
          {
            key: 'Other family member',
            value: 'Other family member',
            selected: false
          },
          {
            key: 'Friend',
            value: 'Friend',
            selected: false
          }
        ]
      }
    ]
  },
  {
    heading: 'Section 2',
    title: 'Platform',
    description: 'We’d love to hear about the platforms you use when watching content',
    questions: [
      {
        title: 'What device/OS do you use for this service?',
        type: 'checkbox',

        options: [
          {
            key: 'iOS',
            value: 'iOS',
            selected: true
          },
          {
            key: 'Android',
            value: 'Android',
            selected: false
          },
          {
            key: 'Mac',
            value: 'Mac',
            selected: false
          },
          {
            key: 'Windows',
            value: 'Windows',
            selected: false
          },
          {
            key: 'Other',
            value: 'Other',
            selected: false
          }
        ]
      },
      {
        title: 'What is your name?',
        type: 'checkbox',

        options: [
          {
            key: 'swapnil',
            value: 'swapnil',
            selected: true
          },
          {
            key: 'prawajal',
            value: 'prawjal',
            selected: false
          },
          {
            key: 'param',
            value: 'param',
            selected: false
          },
          {
            key: 'hrushi',
            value: 'hrushi',
            selected: false
          },
          {
            key: 'Other',
            value: 'Other',
            selected: false
          }
        ]
      },
      {
        title: 'What network connection do you use?',
        type: 'checkbox',

        options: [
          {
            key: '2G',
            value: '2G',
            selected: false
          },
          {
            key: '3G',
            value: '3G',
            selected: true
          },
          {
            key: '4G',
            value: '4G',
            selected: false
          },
          {
            key: 'Wired Internet/WiFi',
            value: 'Wired Internet/WiFi',
            selected: false
          },
          {
            key: 'Other',
            value: 'Other',
            selected: false
          }
        ]
      },
      {
        title: 'What network connection do you use?',
        type: 'checkbox',

        options: [
          {
            key: '2G',
            value: '2G',
            selected: false
          },
          {
            key: '3G',
            value: '3G',
            selected: true
          },
          {
            key: '4G',
            value: '4G',
            selected: false
          },
          {
            key: 'Wired Internet/WiFi',
            value: 'Wired Internet/WiFi',
            selected: false
          },
          {
            key: 'Other',
            value: 'Other',
            selected: false
          }
        ]
      }
    ]
  }
];

export const UsageReview = [
  {
    title: 'What is the genre that you watch the most?',
    type: 'radio',

    options: [
      {
        key: 'currentuser',
        value: 'English movies/soaps',
        selected: false
      },
      {
        key: 'intendingbuyer',
        value: 'Hindi movies/soaps',
        selected: false
      },
      {
        key: 'documentries',
        value: 'Documentaries',
        selected: true
      },
      {
        key: 'childrens',
        value: 'Childrens entertainment',
        selected: false
      }
    ]
  },
  {
    title: 'What influences you to watch certain shows?',
    type: 'radio',

    options: [
      {
        key: 'pr',
        value: 'Platform recommendations',
        selected: true
      },
      {
        key: 'fam',
        value: 'Family & friends',
        selected: false
      },
      {
        key: 'reviews',
        value: 'Online reviews',
        selected: false
      }
    ]
  }
];

export const form_status = {
  0: 'Not initiated',
  1: 'Complete',
  2: 'In Progress',
  3: 'Overdue'
};
export const interview_status = {
  0: 'Not Scheduled',
  1: 'Interview Complete',
  2: 'Scheduled',
  3: 'Lapsed'
};

export const edit_status = {
  0: 'Yet to begin',
  1: 'Complete',
  2: 'In-Progress',
  3: 'Video Not Found',
  4: 'In-Queue'
};

//Interview status  :
// 0: Not Scheduled
// 1: complete
// 2: scheduled
// 3: Lapsed

//edit status  :
// 0:Yet to begin
// 1: complete
// 2: in_progress

//can_start
// 0 :no
// 1: yes
export const fieldsData = [
  {
    name: 'R1. Sonia P.',
    group: 'G1',
    description: 'Begins in 15 Mins',
    form_status: 1,
    interview_status: 2,
    can_start: 1,
    edit_status: 0
  },
  {
    name: 'R1. Jason P.',
    description: 'Begins in 2 Hours',
    group: 'G2',
    form_status: 1,
    interview_status: 2,
    can_start: 0,
    edit_status: 0
  },
  {
    name: 'R1. Arushi P.',
    group: 'G2',
    form_status: 1,
    interview_status: 1,
    can_start: 0,
    edit_status: 2
  },
  {
    name: 'R1. Aaron G.',
    group: 'G2',
    form_status: 0,
    interview_status: 0,
    can_start: 0,
    edit_status: 0
  },
  {
    name: 'R1. Sam J.',
    group: 'G3',
    form_status: 1,
    interview_status: 1,
    can_start: 0,
    edit_status: 1
  },
  {
    name: 'R1. Soham J.',
    group: 'G3',
    form_status: 2,
    interview_status: 2,
    can_start: 0,
    edit_status: 0
  },
  {
    name: 'R12. Partiv J.',
    group: 'G3',
    form_status: 3,
    interview_status: 3,
    can_start: 0,
    edit_status: 0
  }
];
