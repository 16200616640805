import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetPublishProjectMethods from '../../utils/api/publish/GetPublishProjectData';

function* GetPublishProjectData(payload) {
  const promise = GetPublishProjectMethods.GetPublishProjectData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.PublishProjectAction.PUBLISH_PROJECT_RECEIVED, data: res.Response });
}

export  { GetPublishProjectData }