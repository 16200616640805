import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Heading, Button, Text, CardButton, EmptyState, Avatar, Divider, ActionIcon } from "../../../common";
import AddUserModal from "../../../common/modal/AddUserModal";
import { InputText } from '../../../components/form';
import { Link } from 'react-router-dom';
import PlusWhite from '../../../assets/images/plus-white.svg';
import PlusGreen from '../../../assets/images/plus-green.svg';
import PlusGrey from '../../../assets/images/plus-grey.svg';
import GreenDropdown from '../../../assets/images/green-dropdown.svg';
import Grey1 from '../../../assets/images/709.svg';
import Grey2 from '../../../assets/images/710.svg';
import Grey3 from '../../../assets/images/711.svg';
import Grey4 from '../../../assets/images/712.svg';
import Grey5 from '../../../assets/images/713.svg';
import profile_pic from '../../../images/profile_pic.png';
import relaunch from '../../../images/relaunch.png';
import diagnostic from '../../../images/diagnostic.png';
import discovery from '../../../images/discovery.png';
import digital from '../../../images/digital.png';
import search from '../../../images/search.svg';
import filter from '../../../images/filter.svg';
import uparrow from '../../../images/uparrow.svg';
import downArrow from '../../../images/downArrow.svg';
import cross from '../../../assets/images/cross.svg';
import delete_icon from '../../../assets/images/delete_icon.svg';
import complete from '../../../assets/images/complete_group.svg';
import setup from '../../../assets/images/setup.svg';
import { REQUEST_CLIENTDASHBOARD_DATA } from '../../../constants/clientAdmin/clientDashboardConstants';
import { useDispatch, useSelector } from 'react-redux';
import UserDeletedModal from '../../../common/modal/UserDeletedModal';
import ProjectDeletedModal from '../../../common/modal/ProjectDeletedModal';
import EditRespondantModal from '../../../common/modal/EditRespondantModal';
import EditResearcherModal from '../../../common/modal/EditResearcherModal';
import EditObserverModal from '../../../common/modal/EditObserverModal';
import EditTeamModal from '../../../common/modal/EditTeamModal';
import analysis from '../../../assets/images/analysis.svg';
import design from '../../../assets/images/design.svg';
import field from '../../../assets/images/field.svg';
import recruit from '../../../assets/images/recruit.svg';
import recruitdesign from '../../../assets/images/recruit-design.svg';
import avatar from '../../../images/researcher.png';
import { REQUEST_DELETEPROJECT } from '../../../constants/clientAdmin/DeleteProjectConstants';
import { color, style } from 'd3';


const grey_circle = {
	marginLeft: "-10px"
}

const emailFont = {
	fontSize: "12px"
}

const datefont = {
	fontSize: "10px"
}

const ClientAdmin = styled.div`
	// position: absolute;
	width: 185px;
 
	right: 0px;
	// left: 0;
	top: 0px;

	/* Colour/White */

	background: #ffffff;
	/* Colour/Light Grey */

	border: 1px solid #e2e2e2;
	box-sizing: border-box;
	/*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;*/
	height: 120px;
	/*left: 208px;
	top: 222px;*/

	background: #FFFFFF;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 14px;
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;


const Project = styled.div`
// position: absolute;
// width: 276px;

right: 0px;
// left: 0;
top: 0px;
background: #ffffff;
/* Colour/Light Grey */

/*border: 1px solid #e2e2e2;
box-sizing: border-box;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
border-radius: 5px;*/
border: 0.934737px solid #E2E2E2;
box-shadow: 0px 1.86947px 3.73895px 0.934737px rgba(0, 0, 0, 0.05);
border-radius: 9.34737px;
`;

const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	//float: right;
    //margin-right: 5px;
`;

const Pill = styled.span`
	font-size: 10px;
	// font-family: "Readex Pro", sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
	display: inline-block;
`;

const TableContainer = styled.table`
	table-layout: fixed;
	border-collapse: collapse;
	border-radius: 10px;
	border-style: hidden; /* hide standard table (collapsed) border */
	box-shadow: 0 0 0 0.1px var(--greyBlue);
	.search {
		padding-left: 10px;
	}

	th:first-of-type {
		border: none;
		border-top-left-radius: 10px;
		padding-left: 2rem;
	}
	th:last-of-type {
		border: none;
		border-top-right-radius: 10px;
		border-left: 1px solid var(--grey20);
	}
	tr:last-of-type td:first-of-type {
		border-bottom-left-radius: 10px;
	}
	tr:last-of-type td:last-of-type {
		border-bottom-right-radius: 10px;
	}
	tr,
	td,
	th {
		border: 0;
		border-bottom: 0 !important;
	}
	thead {
		background: var(--greyBlue);
		color: var(--white);
	}

	th {
		font-weight: 600;
		font-size: 0.75rem;
		padding: 1rem 0;
		padding-left: 0.625rem;
		border: 0;
		border-left: 1px solid var(--grey20);
	}

	td:first-of-type {
		padding-left: 1rem;
	}

	td {
		padding: 0.675rem 0;
		padding-left: 0.625rem;
		vertical-align: middle;
	}

	button {
		font-size: 0.625rem;
		font-weight: 700;
		// padding: 5px 10px;

		:disabled,
		[disabled] {
			background: transparent;
			border-color: var(--middleGrey);
			color: var(--middleGrey);

			:hover {
				box-shadow: none;
				background: #c2c2c2;
				color: #ffffff;
				cursor: not-allowed;
			}
		}
	}

	.icon {
		font-size: 1rem;
		font-weight: 100;
	}
	& > tbody > tr:nth-child(even) {
		background: var(--greyBlueLight);
	}
	& > tbody > trtr:nth-child(odd) {
		background: var(--white);
	}
`;

const Container = styled.div`
	position: absolute;
	width: 240px;

	// right: 0px;
	// left: 0;
	// top: 0px;

	/* Colour/White */

	background: #ffffff;
	/* Colour/Light Grey */

	border: 1px solid #e2e2e2;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
`;



const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function DashboardScreen_A() {

	const [seeMore, setSeeMore] = useState(false);
	const [seeMoreUser, setSeeMoreUser] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const dispatch = useDispatch();
	const userId = useSelector(state => state.LoginReducer.userId);
	const clientAdminProjectData = useSelector(state => state.clientDashboardReducer.clientAdminProjectData);
	useEffect(() => {
		dispatch({
			type: REQUEST_CLIENTDASHBOARD_DATA,
			payload: {
				"user_ID": userId,
			}
		});
	}, []);

	// console.warn(clientAdminProjectData.project_ID);
	const usersData = useSelector(state => state.clientDashboardReducer.usersData);
	const Addproject=useSelector(state=>state.clientDashboardReducer.Addproject);
	const teamsData = useSelector(state => state.clientDashboardReducer.teamsData);
	const city = useSelector(state => state.clientDashboardReducer.city);
	const gender = useSelector(state => state.clientDashboardReducer.gender);
	const qualification = useSelector(state => state.clientDashboardReducer.qualification);
	const projectDeleteStatus = useSelector(state => state.DeleteProjectReducer.DeleteProject)

	const [isEditVisible1, setIsEditVisible1] = useState(false);
	const [isEditVisible2, setIsEditVisible2] = useState(false);
	const [isEditVisible3, setIsEditVisible3] = useState(false);
	const [teamModaldata, setTeamModaldata] = useState();
	const [getINdexOfClientInfo, setGetINdexOfClientInfo] = useState(null);
	const [IsFlag, setIsFlag] = useState(0);
	const [teamData, setTeamData] = useState([])
	const [userModaldata, setuserModaldata] = useState();
	const [projectID, setProjectID] = useState();
	const [more,setMore]=useState(true)

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	let startdate, startmonth, enddate, endmonth;

	// useEffect(() => {
	// 	setTeamData(teamsData);
	// },[teamData])

	const handelModal = (team) => {
		setTeamModaldata(team);
		setIsFlag(1);
	}

	const handelUserModal = (team) => {
		setTeamData(teamsData);
		setIsFlag(1);
	}

	const handleOnMouseGetIndex = (index) => {
		setGetINdexOfClientInfo(index)
	}

	const handleOnMouseLeaveGetIndex = (index) => {
		setGetINdexOfClientInfo(null);
	}

	const editUserModal = (user) => {
		setuserModaldata(user);
		setIsFlag(1);
	}

	

	return (
		<>


			{/* Heading */}
			<div className="row">

				<div className="col-sm-8">
					<Heading >Your Admin Dashboard</Heading>
					<Text type="3" color="var(--ternaryColor)" className="mb-0">View your team, permissions, projects and respondents in one place</Text>
				</div>
				{/* <div className="col-sm-4 ">
                    <div className="d-flex justify-content-end">
                        <Link to="/project/create">
                            <CardButton><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white" /> New Client Admin</CardButton>
                        </Link>
                        
                    </div>
                    
                </div> */}

			</div>

			<div className='row mt-4'>

				<div className="col-sm-8 mt-2">
					<Heading >
						Teams
						<img src={Grey1} alt="grey circle" className="ml-2 grey-circle" />
						<img src={Grey2} alt="grey circle" className="grey-circle" style={grey_circle} />
						<img src={Grey3} alt="grey circle" className="grey-circle" style={{ marginLeft: '-10px', paddingRight: "10px" }} />
						{/* <img src={Grey4} alt="grey circle" className="grey-circle" style={grey_circle}/>
                        <img src={Grey5} alt="grey circle" className="grey-circle" style={{marginLeft: '-15px', paddingRight: '15px'}}/> */}
						{teamsData?.length == 0 ? " " : teamsData?.length + " Accounts"}
					</Heading>
					{/* <Text type="3" color="var(--ternaryColor)" className="mb-0">View your live projects, calendar and recent notifications in one place</Text> */}
				</div>



			</div>


			<div className='row mt-4'>

				{/* Client admin list */}
				{teamsData?.map((team, index) =>

					<ClientAdmin className='px-3 ml-3 team' onMouseEnter={() => handleOnMouseGetIndex(index)} onMouseLeave={() => handleOnMouseLeaveGetIndex()} style={{ width: '257px' }}>
						<div className='d-flex align-items-center ml-3 py-2 card1' style={{ opacity: getINdexOfClientInfo == index ? "0.2" : "1", minWidth: '225px', width: '217px', height: '111px' }}>
							<Avatar
								src={team?.presignedUrl != null ? team?.presignedUrl : avatar}
								className='profile-pic'
								width='60'
								height='60'
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='14px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3'
									>
										{team.teamName}
									</Heading>
								</div>
								<div className='col'>
									<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>{(team.email)/*.slice(0,10)+('...')*/}</Text>
								</div>
							</div>
						</div>

						{getINdexOfClientInfo == index ?
							<div className='cardOverlay'>
								<CustomButton
									style={{ float: 'right' }}
									className='mr-2 mt-1'
									size='small'
									// variant='outlined'
									width='100px'
									// style={{ color: "#798E8F", background: "none" }}
									data-toggle="modal"
									data-target="#editTeamModal"
									onClick={() => { handelModal(team) }}
								>
									Select & Edit
								</CustomButton>
							</div>
							: null}


						{/* <Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/> */}

					</ClientAdmin>
				)}


				{/* <ClientAdmin className='px-3 ml-4 team' onMouseEnter={() => setIsEditVisible2(true)} onMouseLeave={() => setIsEditVisible2(false)}>
					<div className='d-flex align-items-center ml-3 py-2 card1' style={{ opacity: isEditVisible2 == true ? "0.2" : "1" }}>
						<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
						<div className='row'>
							<div className='col'>
								<Heading
									fontSize='14px'
									fontWeight='700'
									color='var(--deepBlue)'
									className='mb-0 pb-0 ml-3'
								>
									Rohit
								</Heading>
							</div>
							<div className='col'>
								<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>rohit@xyz.in</Text>
							</div>
						</div>
					</div>

					{isEditVisible2 == true ? <div className='cardOverlay'>
						<CustomButton
							className='ml-2 mt-1'
							size='small'
							// variant='outlined'
							width='100px'
							// style={{ color: "#798E8F", background: "none" }}
							data-toggle="modal"
							data-target="#editTeamModal"
						>
							Select & Edit
						</CustomButton>
					</div> : null}


					<Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/>

				</ClientAdmin>


				<ClientAdmin className='px-3 ml-4 team' onMouseEnter={() => setIsEditVisible3(true)} onMouseLeave={() => setIsEditVisible3(false)}>
					<div className='d-flex align-items-center ml-3 py-2 card1' style={{ opacity: isEditVisible3 == true ? "0.2" : "1" }}>
						<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
						<div className='row'>
							<div className='col'>
								<Heading
									fontSize='14px'
									fontWeight='700'
									color='var(--deepBlue)'
									className='mb-0 pb-0 ml-3'
								>
									Gazala
								</Heading>
							</div>
							<div className='col'>
								<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>gazala@xyz.in</Text>
							</div>
						</div>
					</div>

					{isEditVisible3 == true ? <div className='cardOverlay'>
						<CustomButton
							className='ml-2 mt-1'
							size='small'
							// variant='outlined'
							width='100px'
							// style={{ color: "#798E8F", background: "none" }}
							data-toggle="modal"
							data-target="#editTeamModal"
						>
							Select & Edit
						</CustomButton>
					</div> : null}


					<Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/>

				</ClientAdmin> */}

				{/* ********END****** */}
			</div>

			{/* **********PROJECTS SECTION*************** */}
			<div className='row mt-4'>
				<div className="col-sm-8 mt-4">
					<Heading >Projects</Heading>
				</div>
			</div>
			<div className='row mt-3'>
				{/* <div className='col-3'>
					<Project className='project'>
						<div className='d-flex'>
							<img
								src={relaunch}
								className='profile-pic'
								width='125'
								height='125'
								style={{ borderRadius: "5px 0 0 5px" }}
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='12px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3 mt-1'
										style={{ width: "100px" }}
									>
										My Movie Binge
									</Heading>
									<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
									<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>31 Oct - 15 Nov</Text>
									<CustomButton
										className='ml-2 mt-1'
										size='small'
										variant='outlined'
										width='60px'
										style={{ color: "#798E8F", background: "none" }}
										disabled
									>
										View
									</CustomButton>
									<CustomButton
										className='ml-2 mt-1'
										size='small'
										variant='outlined'
										width='60px'
										style={{ color: "#798E8F", background: "none" }}
										disabled
									>
										Download
									</CustomButton>
									<img
										src={setup}
										className='pt-2'
										style={{ marginLeft: "12px" }}
									// style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</div>
								<div className='col'>
								</div>
							</div>
						</div>

						<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>

					</Project>
				</div>

				<div className='col-3'>
					<Project className='project'>
						<div className='d-flex'>
							<img
								src={digital}
								className='profile-pic'
								width='125'
								height='125'
								style={{ borderRadius: "5px 0 0 5px" }}
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='12px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3 mt-1'
										style={{ width: "100px" }}
									>
										Weekend Plan
									</Heading>
									<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
									<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>25 Oct - 10 Nov</Text>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										variant='outlined'
										width='60px'
										style={{ color: "#798E8F", background: "none" }}
										disabled
									>
										View
									</CustomButton>
									<CustomButton
										className='ml-2 mt-1'
										// disabled
										size='small'
										variant='outlined'
										width='60px'
										style={{ color: "#798E8F", background: "none" }}
										disabled
									>
										Download
									</CustomButton>
									<img
										src={setup}
										className='pt-2'
										style={{ marginLeft: "12px" }}
									// style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</div>
								<div className='col'>
								</div>
							</div>
						</div>

						<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>

					</Project>
				</div>

				<div className='col-3'>
					<Project className='project'>
						<div className='d-flex'>
							<img
								src={discovery}
								className='profile-pic'
								width='125'
								height='125'
								style={{ borderRadius: "5px 0 0 5px" }}
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='12px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3 mt-1'
										style={{ width: "100px" }}
									>
										My Movie Binge
									</Heading>
									<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
									<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>01 Oct - 01 Nov</Text>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										variant='outlined'
										width='60px'
									>
										View
									</CustomButton>
									<CustomButton
										className='ml-2 mt-1'
										// disabled
										size='small'
										variant='outlined'
										width='60px'
									>
										Download
									</CustomButton>
									<img
										src={complete}
										className='ml-1 pt-2'
									// style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</div>
								<div className='col'>
								</div>
							</div>
						</div>

						<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>

					</Project>
				</div> */}

				{clientAdminProjectData?.filter((project, index) => seeMore ? true : index <= 3).map((project, index) => {
					const projectsteps = project?.steps_array;
					return (<div className='col-3 mt-4'>
						<Project className='project'>
							<div className='d-flex'>
								<img
									src={project.projectImages_array.length > 0 && project.projectImages_array[project.projectImages_array.length - 1] != null ? project.projectImages_array[project.projectImages_array.length - 1] : `https://thumbs.dreamstime.com/b/projects-concept-black-chalkboard-d-rendering-handwritten-top-view-office-desk-lot-business-office-supplies-79906734.jpg`}
									className='project_image'
									width='100'
									max-width='100%'
									height='125'
									style={{
										borderRadius: "5px 0 0 5px",
										objectFit: "cover"
									}}
								/>
								<div className='row'>
									<div className='col mt-1'>
										<Heading
											fontSize='12px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3 mt-1'
										//style={{ width: "100px" }}
										>
											{(project?.title).slice(0, 10) + ('...')}
											<img
												src={delete_icon}
												// src={projectData.projectImages_array[0]}
												className='mr-2 delete_button'
												// width='125'
												// height='125'
												style={{ float: "right", cursor: 'pointer' }}
												data-toggle="modal"
												data-target="#DeleteModalProject"
												data-dismiss="modal"

												onClick={() => {
													setProjectID(project.project_ID);
													console.warn(project.project_ID);
												}}
											/>
										</Heading>
										<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>{project?.templateName}</Text>
										{project.startDuration != null ? <Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>
											{new Date(project?.startDuration).getDate() + " " + months[new Date(project?.startDuration).getMonth()]} - {new Date(project?.endDuration).getDate() + " " + months[new Date(project?.endDuration).getMonth()]}
										</Text> : ""}
										<div className='mrg'>
											{projectsteps[projectsteps.length - 1].ModuleDescription == 'Project Setup' ?
												<img src={setup} className='ml-1 pt-2' />
												: projectsteps[projectsteps.length - 1].ModuleDescription == 'Recruit' ? <img src={recruit} className='pt-2' />
													: projectsteps[projectsteps.length - 1].ModuleDescription == 'Design' ? <img src={recruitdesign} className='pt-2' />
														: projectsteps[projectsteps.length - 1].ModuleDescription == 'Field' ? <img src={field} className='pt-2' />
															: projectsteps[projectsteps.length - 1].ModuleDescription == 'Analysis' ? <img src={analysis} className='pt-2' /> : <img src={complete} className='ml-1 pt-2' />}
											<div className='view_download_button'>
												<CustomButton
													className='ml-2 mt-1'
													size='small'
													variant='outlined'
													width='60px'
													style={{ color: "#728f01", background: "none" }}
												// disabled
												>
													View
												</CustomButton>
												<CustomButton
													className='ml-2 mt-1'
													size='small'
													variant='outlined'
													width='60px'
													style={{ color: "#728f01", background: "none" }}
												// disabled
												>
													Download
												</CustomButton>
											</div>
										</div>
										{/* <CustomButton
											className='ml-2 mt-1'
											size='small'
											variant='outlined'
											width='60px'
											style={{ color: "#798E8F", background: "none" }}
											disabled
										>
											View
										</CustomButton>
										<CustomButton
											className='ml-2 mt-1'
											size='small'
											variant='outlined'
											width='60px'
											style={{ color: "#798E8F", background: "none" }}
											disabled
										>
											Download
										</CustomButton>
										{ projectsteps[projectsteps.length-1].ModuleDescription == 'Project Setup' ?
											<img src={setup} className='ml-1 pt-2'/> 
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Recruit' ? <img src={recruit} className='pt-2'/> 
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Design' ? <img src={recruitdesign} className='pt-2'/> 
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Field' ? <img src={field} className='pt-2'/>
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Analysis' ? <img src={analysis} className='pt-2'/> : <img src={complete} className='ml-1 pt-2'/>} */}
									</div>
									{/* <div className='col'>
									</div> */}
								</div>
							</div>
						</Project>
					</div>)
				}
				)}
				{/* 
					<div className='col-3'>
						<Project className='project'>
							<div className='d-flex'>
								<img
									src={relaunch}
									className='profile-pic'
									width='125'
									height='125'
									style={{ borderRadius: "5px 0 0 5px" }}
								/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='12px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3 mt-1'
											style={{ width: "100px" }}
										>
											My Movie Binge
										</Heading>
										<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
										<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>31 Oct - 15 Nov</Text>
										<CustomButton
											className='ml-2 mt-1'
											size='small'
											variant='outlined'
											width='60px'
											style={{ color: "#798E8F", background: "none" }}
											disabled
										>
											View
										</CustomButton>
										<CustomButton
											className='ml-2 mt-1'
											size='small'
											variant='outlined'
											width='60px'
											style={{ color: "#798E8F", background: "none" }}
											disabled
										>
											Download
										</CustomButton>
										<img
											src={setup}
											className='ml-1 pt-2'
										// style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</div>
									<div className='col'>
									</div>
								</div>
							</div> */}

				{/* <Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/> */}
				{/* 
						</Project>
					</div>

				{seeMore == true ? (
					<>
						<div className='col-3 mt-4'>
							<Project className='project'>
								<div className='d-flex'>
									<img
										src={diagnostic}
										className='profile-pic'
										width='125'
										height='125'
										style={{ borderRadius: "5px 0 0 5px" }}
									/>
									<div className='row'>
										<div className='col'>
											<Heading
												fontSize='12px'
												fontWeight='700'
												color='var(--deepBlue)'
												className='mb-0 pb-0 ml-3 mt-1'
												style={{ width: "100px" }}
											>
												Eating Out
											</Heading>
											<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
											<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>06 Nov - 20 Nov</Text>
											<CustomButton
												className='ml-2 mt-2'
												size='small'
												variant='outlined'
												width='60px'
											>
												View
											</CustomButton>
											<CustomButton
												className='ml-2 mt-1'
												// disabled
												size='small'
												variant='outlined'
												width='60px'
											>
												Download
											</CustomButton>
											<img
												src={complete}
												className='ml-1 pt-2'
											// style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</div>
										<div className='col'>
										</div>
									</div>
								</div>

								<Divider
									margin='0'
									height='1px'
									width='100%'
									color='var(--lightGrey)'
								/>

							{/*</Project>
						</div>

						<div className='col-3 mt-4'>
							<Project className='project'>
								<div className='d-flex'>
									<img
										src={relaunch}
										className='profile-pic'
										width='125'
										height='125'
										style={{ borderRadius: "5px 0 0 5px" }}
									/>
									<div className='row'>
										<div className='col'>
											<Heading
												fontSize='12px'
												fontWeight='700'
												color='var(--deepBlue)'
												className='mb-0 pb-0 ml-3 mt-1'
												style={{ width: "100px" }}
											>
												Let's Drive
											</Heading>
											<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
											<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>02 Nov - 15 Nov</Text>
											<CustomButton
												className='ml-2 mt-1'
												size='small'
												variant='outlined'
												width='60px'
												style={{ color: "#798E8F", background: "none" }}
												disabled
											>
												View
											</CustomButton>
											<CustomButton
												className='ml-2 mt-1'
												size='small'
												variant='outlined'
												width='60px'
												style={{ color: "#798E8F", background: "none" }}
												disabled
											>
												Download
											</CustomButton>
											<img
												src={setup}
												className='pt-2'
												style={{ marginLeft: "12px" }}
											// style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</div>
										<div className='col'>
										</div>
									</div>
								</div>
							</div> */}

				{/* <Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>  */}
				{/* 
							</Project>
						</div>
					</>

				) : null} */}
			</div>




			<SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMore(!seeMore)}>{seeMore ? SeeLess() : SeeMore()}</SeeButton>

			{/* ********END****** */}



			{/* **********USER SECTION*************** */}


			<div className='row mt-4'>
				<div className="col-sm-8 mt-3">
					<Heading>Users</Heading>
				</div>
				<div className="col-sm-4 ">
					<div className="d-flex justify-content-end">
						<div data-toggle="modal" data-target="#addUserModal">
							<CardButton onClick={() => { handelUserModal() }}><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white" /> New User</CardButton>
						</div>

					</div>

					{/* Add User Modal Start */}
					<AddUserModal IsFlag={IsFlag} setIsFlag={setIsFlag} teamData={teamsData} city={city} gender={gender} qualification={qualification} />
					{/* Add User Modal End */}

					{/* Edit User Modal Start */}
					< EditRespondantModal userModaldata={userModaldata} IsFlag={IsFlag} setIsFlag={setIsFlag} city={city} gender={gender} qualification={qualification} />
					{/* Edit User Modal End */}

					{/* Edit Researcher Modal Start */}
					< EditResearcherModal userModaldata={userModaldata} IsFlag={IsFlag} setIsFlag={setIsFlag} teamData={teamsData} />
					{/* Edit User Modal End */}

					{/* Edit Observer Modal Start */}
					< EditObserverModal userModaldata={userModaldata} IsFlag={IsFlag} setIsFlag={setIsFlag} />
					{/* Edit User Modal End */}

					{/* Confirm User deleted Modal Start */}
					<UserDeletedModal />
					{/* Confirm User deleted Modal End */}

					{/* Confirm User deleted Modal Start */}
					<EditTeamModal teamModaldata={teamModaldata} IsFlag={IsFlag} setIsFlag={setIsFlag} />
					{/* Confirm User deleted Modal End */}

					{/* New Modal for Delete Project  */}
					<div className="modal fade" id="DeleteModalProject" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
							<div className="modal-content" style={{ borderRadius: "10px" }}>
								<div className="modal-header border-0" style={{ paddingBottom: "0px" }}>
									<Heading fontSize='18px'>Confirm Delete</Heading>
									<button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
									<div className='row'>
										<div className='col col-12'>
											<Text type='7' color="var(--ternaryColor)" className="mb-0">Deleting your project permanently removes it from your account</Text>
										</div>
									</div>
									<div className='row' style={{ paddingTop: '20px' }}>
										<div className='col'>
											<CustomButton
												className='ml-2 mt-1'
												size='small'
												variant='outlined'
												width='60px'
												// width='5px'
												// height='4px'
												fontSize='10px'
												style={{ float: "left" }}
												data-dismiss="modal"
											>
												Cancel
											</CustomButton>
										</div>
										<div id='delete' className='col col-4 col-offset-6'>
											<CustomButton
												className='ml-2 mt-1'
												size='small'
												// variant='outlined'
												width='60px'
												data-toggle="modal"
												data-target="#ProjectDeletedModal" data-dismiss="modal"
												onClick={() => {
													dispatch({
														type: REQUEST_DELETEPROJECT,
														payload: {
															project_id: projectID
														}
													})
												}}
											// style={{ color: "#798E8F", background: "none" }}
											>
												Delete
											</CustomButton>
										</div>
									</div>
								</div>
								{/* <div className="modal-footer border-0">
                                <div className='row'>
                                    <div className='col col-4 col-offset-6'>
                                        <Button
                                            className='ml-4'
                                            size='small'
                                            variant='outlined'
                                            width='20px'

                                        >
                                            save changes
                                        </Button>
                                    </div>
                                </div>
                            </div> */}
							</div>
						</div>
					</div>
					{/* END */}

					{/* Confirm Project deleted Modal Start */}
					<ProjectDeletedModal />
					{/* Confirm Project deleted Modal End */}



				</div>
			</div>

			<div className='row mt-2'>
				<div className='col-2'>
					<Text>Client Pool :</Text>
				</div>
				<div className='col'>
					<InputText
						style={{ width: '50%', height: "20px" }}
						placeholder='Search'
						showCharacters={false}
					// showIcon={true}
					// iconName="create"
					/>
				</div>
				{/* <div className='col-2'>
					<Text style={emailFont}>General Pool</Text>
				</div>
				<div className='col-2'>
					<Text style={{marginLeft: "-5%"}}>General Pool</Text>
				</div> */}
			</div>




			<TableContainer className='table mt-3'>
			<thead>
					<tr>
						<th style={{ width: '20%' }}>
							<div className='name d-flex align-items-center'>
								Name{' '}
								<div className='d-flex px-3'>
									<img
										className='icon'
										src={uparrow}
									/>
									<img
										className='icon'
										src={downArrow}
									/>
								</div>
								{/* <img
										className='icon'
										src={search}
									/> */}
								<img
									className='icon pl-3'
									src={filter}
								/>
							</div>
						</th>
						<th style={{ width: '16%' }}>
							<div className=' d-flex align-items-center '>
								Roles{' '}
								<img
									className='icon pl-3'
									src={filter}
								/>
							</div>
						</th>
						<th style={{ width: '25%' }}>
							<div className=' d-flex align-items-center '>
								OnGoing Projects{' '}
								<img
									className='icon pl-3'
									src={filter}
								/>
							</div>
						</th>
						<th style={{ width: '42%' }}>
							<div className='d-flex align-items-center'>
								Permission
							</div>
						</th>
					</tr>
				</thead>

				<tbody>
					{Addproject?.filter((userValue, index) => seeMoreUser ? true : index <= 2).map((userValue, index) =>
						<tr>
							<th scope="row">
								<div className='d-flex align-items-center py-2 mt-4'>
									<Avatar
										src={userValue.presignedUrl}
										className='profile-pic'
										width='30'
										height='30'
									/>
									<div className='row '>
										<div className='col'>
											<Heading
												fontSize='14px'
												fontWeight='700'
												color='var(--deepBlue)'
												className='mb-0 pb-0 ml-3'
											>
												{(userValue.name)?.slice(0, 7) + ('...')}
											</Heading>
										</div>
										<div className='col'>
											<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>{(userValue.email)?.slice(0, 10) + ('...')}</Text>
										</div>
									</div>
									{
										userValue.Roles == "Respondent" ? <div data-toggle="modal" data-target="#editRespondantModal" onClick={() => { editUserModal(userValue) }}><ActionIcon>create</ActionIcon></div>
											: userValue.Roles == "Researcher" ? <div data-toggle="modal" data-target="#editResearcherModal" onClick={() => { editUserModal(userValue) }}><ActionIcon>create</ActionIcon></div>
												: userValue.Roles == "Observer" ? <div data-toggle="modal" data-target="#editObserverModal" onClick={() => { editUserModal(userValue) }}><ActionIcon>create</ActionIcon></div>
													: ""
									}
								</div>
							</th>
							<td className='mt-4'><Text className='mt-4'><Pill className="pill">
								{/* {userValue.role_ID} */}Researcher
								<img
									src={cross}
									className='ml-1'
									style={{ cursor: 'pointer', borderRadius: '50%' }}
								/>
							</Pill>
								<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" style={{ width: '20px', height: 'auto' }} /></Text>
							</td>
							<td ><Text className='mt-4'type='2' >{userValue.project_Title.split(',',3).join(" | ")}<button  style={{display:'inline',border:'none',background:'none'}}><Text>{userValue.project_Title.split(',').length-3 <= 0?"":"+".concat(userValue.project_Title.split(',').length-3)}</Text></button></Text></td>
							<td>
								{index == 0 ?
									<div className='row' style={{ marginBottom: "15px" }}>
										<Text type='8' className='ml-3'>Setup</Text>
										<Text type='8' className='ml-3'>Recruit</Text>
										<Text type='8' className='ml-2'>Design</Text>
										<Text type='8' className='ml-2'>Field</Text>
										<Text type='8' className='ml-2'>Analysis</Text>
										<Text type='8' className='ml-2'>Reports</Text>
										<div style={{ marginBottom: "-15px" }}>
											<Text type='8' className='ml-2'>Approve</Text>
											<Text type='8' className='ml-2'>budget</Text>
										</div>
										<div style={{ marginBottom: "-15px" }}>
											<Text type='8' className='ml-2'>Close</Text>
											<Text type='8' className='ml-2'>Project</Text>
										</div>
										{/* <div className='col-xs col-sm col-lg ml-3'>
											<Text type='8' className=''>Setup1</Text>
										</div>
										<div className='col-xs col-sm col-lg ml-2'>
											<Text type='8' className=''>Setup2</Text>
										</div>
										<div className='col-xs col-sm col-lg ml-2'>
											<Text type='8' className=''>Setup3</Text>
										</div>
										<div className='col-xs col-sm col-lg ml-2'>
											<Text type='8' className=''>Setup4</Text>
										</div>
										<div className='col-xs col-sm col-lg ml-2'>
											<Text type='8' className=''>Setup5</Text>
										</div>
										<div className='col-xs col-sm col-lg ml-2'>
											<Text type='8' className=''>Setup6</Text>
										</div>
										<div className='col-xs col-sm col-lg ml-2'>
											<Text type='8' className=''>Setup7</Text>
										</div>
										<div className='col-xs col-sm col-lg ml-2'>
											<Text type='8' className=''>Setup8</Text>
										</div> */}
									</div>
									: null}
								<div className='row ml-1 mt-2'>
									<label className="switch">
										<input type="checkbox" name='setup' defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" name='recruit' defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" name='design' defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" name='field' defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" name='analysis' defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" name='reports' defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" name='approve_budget' defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" name='close_project' defaultChecked />
										<span className="slider round"></span>
									</label>

									<CustomButton
										className='ml-4'
										size='small'
										variant='outlined'
										width='50px'
									>
										Save
									</CustomButton>
								</div>


					</td>
				</tr>
					)}
					{/* <tr>
						<th scope="row">
							<div className='d-flex align-items-center py-2'>
								<Avatar
									src={profile_pic}
									className='profile-pic'
									width='30'
									height='30'
								/>
								<div className='row' style={{ display: 'inline-block' }}>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3'
										>
											Sonia P
										</Heading>
									</div>
									<div className='col'>
										<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>sonia@xyz.in</Text>
									</div>
								</div>
								<div data-toggle="modal" data-target="#editRespondantModal">
									<ActionIcon>create</ActionIcon>
								</div>
							</div>
						</th>
						<td>
							<Text><Pill className="pill">Lead Researcher
								<img
									src={cross}
									className='ml-1'
									style={{ cursor: 'pointer', borderRadius: '50%' }}
								/>
							</Pill>
								<Pill className="pill">Researcher
									<img
										src={cross}
										className='ml-1'
										style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</Pill></Text>
						</td>
						<td><Text>Book A Show</Text></td>
						<td>
							<label className="switch ml-2">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<CustomButton
								className='ml-5 mb-4'
								size='small'
								variant='outlined'
								width='50px'
							>
								Save
							</CustomButton>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<div className='d-flex align-items-center py-2'>
								<Avatar
									src={profile_pic}
									className='profile-pic'
									width='30'
									height='30'
								/>
								<div className='row' style={{ display: 'inline-block' }}>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3'
										>
											Aarushi P
										</Heading>
									</div>
									<div className='col'>
										<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>aarushi@xyz.in</Text>
									</div>
								</div>
								<div data-toggle="modal" data-target="#editObserverModal">
									<ActionIcon>create</ActionIcon>
								</div>
							</div>
						</th>
						<td>
							<Text><Pill className="pill">Researcher
								<img
									src={cross}
									className='ml-1'
									style={{ cursor: 'pointer', borderRadius: '50%' }}
								/>
							</Pill>
								<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" style={{ width: '20px', height: 'auto' }} /></Text>
						</td>
						<td><Text>Style Your Hair | Book A Show | My Movie Binge | +3</Text></td>
						<td>
							<label className="switch ml-2">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-4">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<CustomButton
								className='ml-5 mb-4'
								size='small'
								variant='outlined'
								width='50px'
							>
								Save
							</CustomButton>
						</td>
					</tr> */}
					{/* <tr>
						<th scope="row">
							<div className='d-flex align-items-center py-2'>
								<Avatar
									src={profile_pic}
									className='profile-pic'
									width='30'
									height='30'
								/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3'
										>
											Sam j
										</Heading>
									</div>
									<div className='col'>
										<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>sam@xyz.in</Text>
									</div>
								</div>
								<ActionIcon>create</ActionIcon>
							</div>
						</th>
						<td>
							<Text><Pill className="pill">Researcher
								<img
									src={cross}
									className='ml-1'
									style={{ cursor: 'pointer', borderRadius: '50%' }}
								/>
							</Pill>
								<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" /></Text>
						</td>
						<td><Text>My Movie Binge</Text></td>
						<td>
							<label className="switch">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<CustomButton
								className='ml-2'
								size='small'
								variant='outlined'
								width='50px'
							>
								Save
							</CustomButton>
						</td>
					</tr> */}

					{/*seeMoreUser == true ? (
						<>
							<tr>
								<th scope="row">
									<div className='d-flex align-items-center py-2'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='30'
											height='30'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='14px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3'
												>
													Gazala
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>gazala@xyz.in</Text>
											</div>
										</div>
										<ActionIcon>create</ActionIcon>
									</div>
								</th>
								<td>
									<Text><Pill className="pill">Lead Researcher
										<img
											src={cross}
											className='ml-1'
											style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</Pill>
										<Pill className="pill">Researcher
											<img
												src={cross}
												className='ml-1'
												style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</Pill></Text>
								</td>
								<td><Text>Book A Show</Text></td>
								<td>
									<label className="switch">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<CustomButton
										className='ml-2'
										size='small'
										variant='outlined'
										width='50px'
									>
										Save
									</CustomButton>
								</td>
							</tr>
							<tr>
								<th scope="row">
									<div className='d-flex align-items-center py-2'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='30'
											height='30'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='14px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3'
												>
													Rohit
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>rohit@xyz.in</Text>
											</div>
										</div>
										<ActionIcon>create</ActionIcon>
									</div>
								</th>
								<td>
									<Text><Pill className="pill">Researcher
										<img
											src={cross}
											className='ml-1'
											style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</Pill>
										<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" /></Text>
								</td>
								<td><Text>Style Your Hair | Book A Show | My Movie Binge | +3</Text></td>
								<td>
									<label className="switch">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" />
										<span className="slider round"></span>
									</label>
									<CustomButton
										className='ml-2'
										size='small'
										variant='outlined'
										width='50px'
									>
										Save
									</CustomButton>
								</td>
							</tr>
							<tr>
								<th scope="row">
									<div className='d-flex align-items-center py-2'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='30'
											height='30'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='14px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3'
												>
													Sia
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>sia@xyz.in</Text>
											</div>
										</div>
										<ActionIcon>create</ActionIcon>
									</div>
								</th>
								<td>
									<Text><Pill className="pill">Lead Researcher
										<img
											src={cross}
											className='ml-1'
											style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</Pill>
										<Pill className="pill">Researcher
											<img
												src={cross}
												className='ml-1'
												style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</Pill></Text>
								</td>
								<td><Text>Book A Show</Text></td>
								<td>
									<label className="switch">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<CustomButton
										className='ml-2'
										size='small'
										variant='outlined'
										width='50px'
									>
										Save
									</CustomButton>
								</td>
							</tr>
						</>
					) : null*/}


				</tbody>
			</TableContainer>
			<SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreUser(!seeMoreUser)}>{seeMoreUser ? SeeLess() : SeeMore()}</SeeButton>


			{/* ********END****** */}
		</>
	)
}