import actions from '../../actions';

// reducer with initial state
const initialState = {
   TokenValidate:null
    
  };
  // const getUsername = data => {
  //   return data.email;
  

  const reducer = (state = initialState, action = {}) => {
    
    switch (action.type) {
      
      case actions.ResetPasswordEmailNotificationAction.REQUEST_TOKEN_VALIDATE:
     
        return {
          ...state
        };
      case actions.ResetPasswordEmailNotificationAction.REQUEST_TOKEN_VALIDATE_RECEIVED:
      return {
          ...state,
        
          TokenValidate: action.data
        };
  
      default:
        return state;
    }
  };
  export default reducer;
  