import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddRespondentAnswerMethods from '../../utils/api/field/AddRespondentAnswerData';

function * AddRespondentAnswerData(payload) {
  const promise = AddRespondentAnswerMethods.AddRespondentAnswerData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddRespondentAnswerAction.ADDRESPONDENTANSWER_RECEIVED, data: res.Response });
}

export  { AddRespondentAnswerData }