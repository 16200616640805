import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Button, Heading } from '../../common';
import ProjectWrapper from '../Project/ProjectWrapper';
import logo from '../../images/logo.jpg';

import useWindowDimensions from '../../common/useWindowDimensions';
import BarChart from './charts/report/barchart';
import WordCloud from './charts/report/wordcloud';
import VerticalStakedBarChart from './charts/report/verticalstack';
import GroupedStackedBarChart from './charts/report/stackedchart';
import barChart from '../../images/bar_chart_2.svg';
import table from '../../images/table.svg';
import Likert from './charts/report/likert';
import AnalysisLikerTable from './AnalysisLikerTable';
import { Player, BigPlayButton } from 'video-react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { UPDATE_REPORT, REQUEST_ANALYSIS_DATA } from '../../constants/analysis/analysisConstants';

const Header = styled.div`
  background: #f2f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1.5rem;

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.15em;
    text-transform: uppercase;

    /* Colour/DeepBlue */

    color: #38454f;
    padding: 0;
    margin: 0;
  }
`;

const ReportCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1rem 3rem;
  margin-bottom: 2.5rem;
`;

const ReportCard = ({ question, information, pageinfo, cardHeight, cardWidth, children }) => {
  return (
    <ReportCardContainer
      style={{
        minWidth: cardWidth,
        minHeight: cardHeight,
        maxWidth: cardWidth,
        maxHeight: cardHeight,
        position: 'relative',
        display: 'block'
      }}
    >
      <Heading fontSize="12px" fontWeight="400" color="var(--mediumGrey)" className="mb-4">
        {information}
      </Heading>
      <Heading fontSize="20px" fontWeight="700" color="var(--deepBlue)">
        {question}
      </Heading>
      <div style={{ height: '90%' }} className="d-flex align-items-center justify-content-center py-3">
        {children}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '3rem'
        }}
      >
        <img src={logo} style={{ width: '70px' }} />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '15px',
          right: '3rem'
        }}
      >
        <Heading fontSize="12px" fontWeight="400" color="var(--mediumGrey)" className="mb-0 pb-0">
          {pageinfo}
        </Heading>
      </div>
    </ReportCardContainer>
  );
};

const ScrollContainer = styled.div`
  // padding: 2rem 5rem;
  // background: red;
  height: 900px;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    // margin-right: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #a6a6a6;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a6a6a6;
  }
`;

const ResponseContainer = styled.div`
  /* Colour/White */

  border: 1px solid rgba(121, 142, 143, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;

  &:last-child {
    margin-right: 0rem;
  }
  p {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;

    /* Colour/DeepBlue */

    color: var(--deepBlue);
    span {
      font-weight: 700;
    }

    margin: 0;
    padding: 0;
  }
`;

const ImgCardContainer = styled.div`
  background: #f2f4f4;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  flex-basis: 25%;
  text-align: center;
`;

const Mp4CardContainer = styled.div`
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  flex-basis: 25%;
  text-align: center;
`;

const VideoWrapper = styled.div`
  width: 200px; 
  height: 200px; 
  overflow: hidden;
  background-color: black; 
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;



const ImgCard = ({ name, information, src, style }) => {
  return (
    <ImgCardContainer>
      <img src={src} style={style} />
      <div className="p-2">
        <Heading fontSize="10px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0">
          {name}
        </Heading>
        <Heading fontSize="10px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 mt-1">
          {information}
        </Heading>
      </div>
    </ImgCardContainer>
  );
};

const VidCard = ({ name, information, src, thumb, style }) => {
  return (
    <div style={{ height: '400px', width: '400px' }}>
      <Player src={src} poster={thumb} preload="none">
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};


const MP4Card = ({ src , name}) => {
  return (
    <Mp4CardContainer>
      <VideoWrapper>
        <video controls preload="none">
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </VideoWrapper>
      <div className="p-2" >
          <Heading fontSize="10px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0 ">
            {name}
          </Heading>
        </div>
    </Mp4CardContainer>
  );
};

function AnalysisReport() {
  const { height, width } = useWindowDimensions();
  const barWidth = width > 1500 ? width / 1.4 : width / 1.5;
  const barHeight = height / 2.4;

  const cardHeight = (height * 16) / 9 / 2;
  const cardWidth = (width * 16) / 9 / 2.3;
  const imgStyle = { width: cardWidth / 4.5, height: cardHeight / 3 };
  //const imgStyle = { width: cardWidth / 4.5,maxHeight:"160px" };
  const report = useSelector(state => state.analysisReducer.report);

  let { id } = useParams();
  const dispatch = useDispatch();
  const [squestion, setSquestion] = useState([]);
  const projectTitle = useSelector(state => state.ProjectReducer.projectIdentity);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    if (id != null && id !== undefined && report !== undefined && report !== null) {
      dispatch({
        type: REQUEST_ANALYSIS_DATA,
        payload: {
          project_ID: id
        }
      });

      let qid = [];
      report.map((item, index) => {
        qid.push(item.qid);
      });
      setSquestion(qid);

      dispatch({
        type: UPDATE_REPORT,
        data: {}
      });
    }
    setTimeout(() => {
      setShow(true);
    }, 5000);
  }, [report, id]);

  const rdata = useSelector(state => state.analysisReducer.reportdata);

  const AudioCard = ({ src, name, information }) => {
    return (
      <div style={{ width: '250px' }}>
        <audio controls style={{ width: '100%', maxHeight: '250px' }}>
          <source src={src} type="audio/mpeg" />
        </audio>
        <div className="p-2">
          <Heading fontSize="10px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0">
            {name}
          </Heading>
          <Heading fontSize="10px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 mt-1">
            {information}
          </Heading>
        </div>
      </div>
    );
  };

  const updateData = (i, qtype, data, question) => {
    let d = rdata;
    if (typeof d != 'object') {
      d = {};
    }
    d[i] = {
      report: data,
      qtype: qtype,
      question: question
    };
    if (qtype == 5 && report[i].config.hasOwnProperty('word')) {
      d[i]['extra'] = report[i].config;
    }
    dispatch({
      type: UPDATE_REPORT,
      data: d
    });
  };

  const onLikertChartReady = (index, data, choice, mean) => {
    let d = rdata;
    if (typeof d != 'object') {
      d = [];
    }
    let t = [];
    if (d[index] != null) {
      if (d[index].hasOwnProperty('report')) {
        t = d[index].report;
      }
    } else {
      d[index] = {
        report: [],
        qtype: 4,
        question: report[index].question,
        chartType: report[index].config.chartType
      };
    }

    let element = {
      report: data,
      choice: choice,
      mean: mean
    };
    let isPresent = t?.some(
      obj => obj.choice == element.choice && obj.report == element.report && obj.mean == element.mean
    );

    if (!isPresent) {
      t.push({
        report: data,
        choice: choice,
        mean: mean
      });
    }

    d[index] = {
      report: t,
      qtype: 4,
      question: report[index].question,
      chartType: report[index].config.chartType
    };

    dispatch({
      type: UPDATE_REPORT,
      data: d
    });
  };

  var counter = 0;
  return (
    <ProjectWrapper projectTitle={projectTitle} step={5}>
      <Header className="mt-1">
        <p>Report deck</p>
        {show ? (
          <Link to="/project/export">
            <Button
              fontWeight="700"
              fontSize="12px"
              buttonColor="var(--sapGreen)"
              variant="outlined"
              size="small"
              className="px-2 py-1"
            >
              Export All Slides
            </Button>
          </Link>
        ) : (
          ''
        )}
      </Header>
      <ScrollContainer
        className=" py-4 d-flex align-items-center"
        // className='mx-auto'
        style={{ flexDirection: 'column' }}
      >
        {report?.map((questionReport, reportIndex) => {
          if (
            questionReport.questionObject.questionType_ID == 1 ||
            questionReport.questionObject.questionType_ID == 2 ||
            questionReport.questionObject.questionType_ID == 7 ||
            questionReport.questionObject.questionType_ID == 11
          ) {
            return (
              <ReportCard
                question={questionReport.question}
                information="Bar Graph"
                pageinfo={'Page ' + ++counter + ' of ' + squestion.length}
                cardHeight={cardHeight}
                cardWidth={cardWidth}
              >
                <div
                  className=""
                  style={{
                    height: cardHeight / 2,
                    width: cardWidth / 1.5,
                    position: 'relative'
                  }}
                >
                  {questionReport.questionObject.answerCnt.length > 0 && (
                    <>
                      {questionReport.config['contribution'] == null && questionReport.config['graphItem'] == null && (
                        <BarChart
                          qid={questionReport.qid}
                          index={reportIndex}
                          datasets={questionReport.config.data}
                          width={barWidth}
                          height={barHeight}
                          top={20}
                          bottom={30}
                          left={30}
                          right={0}
                          onReady={updateData}
                          question={questionReport.question}
                        />
                      )}

                      {questionReport.config['contribution'] != null && questionReport.config['graphItem'] == null && (
                        <VerticalStakedBarChart
                          qid={questionReport.qid}
                          index={reportIndex}
                          data={questionReport.config.data}
                          primary={'cn1'}
                          secondary={'cn2'}
                          top={20}
                          bottom={30}
                          left={30}
                          right={0}
                          onReady={updateData}
                          question={questionReport.question}
                        />
                      )}
                      {questionReport.config['contribution'] == null && questionReport.config['graphItem'] != null && (
                        <VerticalStakedBarChart
                          qid={questionReport.qid}
                          index={reportIndex}
                          data={questionReport.config.data}
                          primary={'cn1'}
                          secondary={'cn3'}
                          top={20}
                          bottom={30}
                          left={30}
                          right={0}
                          onReady={updateData}
                          question={questionReport.question}
                        />
                      )}
                      {questionReport.config['contribution'] != null && questionReport.config['graphItem'] != null && (
                        <GroupedStackedBarChart
                          qid={questionReport.qid}
                          index={reportIndex}
                          data={questionReport.config.data}
                          width={barWidth}
                          height={barHeight}
                          primary={'cn1'}
                          secondary={'cn2'}
                          tertiary={'cn3'}
                          top={20}
                          bottom={30}
                          left={30}
                          right={0}
                          onReady={updateData}
                          question={questionReport.question}
                        />
                      )}
                    </>
                  )}
                </div>
              </ReportCard>
            );
          }

          if (
            questionReport.questionObject.questionType_ID == 5 ||
            questionReport.questionObject.questionType_ID == 6
          ) {
            return (
              <ReportCard
                question={questionReport.question}
                information="Word Cloud"
                pageinfo={'Page ' + ++counter + ' of ' + squestion.length}
                cardHeight={cardHeight}
                cardWidth={cardWidth}
              >
                <div
                  className=""
                  style={{
                    height: cardHeight,
                    marginTop: '90px',
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  {/* <Legend list={legendList} /> */}

                  <WordCloud
                    dataset={questionReport.questionObject['questionAns']}
                    index={reportIndex}
                    qid={questionReport.qid}
                    onReady={updateData}
                    question={questionReport.question}
                  />
                  {questionReport.config.hasOwnProperty('word') && (
                    <>
                      <div className="d-flex">
                        <Heading
                          fontSize="12px"
                          fontWeight="500"
                          color="var(--deepBlue)"
                          className="mb-0 pb-0"
                          breakHeading={false}
                        >
                          See Verbatims:
                        </Heading>
                        <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0 ml-2">
                          {questionReport.config['word']} ({questionReport.config['answers'].length})
                        </Heading>
                      </div>
                      <div className="d-flex justify-content-between my-3 align-items-center">
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                            gap: '5px',
                            gridAutoRows: 'minmax(100px, auto)',
                            justifyContent: 'space-between'
                          }}
                        >
                          {questionReport.config['answers'].map((d, i) => {
                            // {responses && responses.filter((q)=> questionReport.qid == questionID).map((d,i)=>{
                            return (
                              <ResponseContainer>
                                <div>
                                  <Heading
                                    fontSize="10px"
                                    fontWeight="700"
                                    color="var(--deepBlue)"
                                    className="mb-0 pb-0"
                                  >
                                    {d.name}
                                  </Heading>
                                  <p className="mb-0 pb-0 mt-1">{d.text}</p>
                                </div>
                              </ResponseContainer>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </ReportCard>
            );
          }

          if (questionReport.questionObject.questionType_ID == 8) {
            {
              updateData(reportIndex, 8, questionReport.config, questionReport.question);
            }
            let html = '';
            return (
              <ReportCard
                question={questionReport.question}
                information="Photos"
                pageinfo={'Page ' + ++counter + ' of ' + squestion.length}
                cardHeight={cardHeight}
                cardWidth={cardWidth}
              >
                {Object.keys(questionReport.config).length > 0 && Object.keys(questionReport.config).length <= 2
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 2 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <ImgCard
                          style={{ width: cardWidth / 4.5, height: cardHeight / 2 }}
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 2 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).length > 2 && Object.keys(questionReport.config).length <= 4
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 2 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <ImgCard
                          style={{ width: cardWidth / 4.5, height: cardHeight / 3 }}
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 2 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).length > 4 && Object.keys(questionReport.config).length <= 6
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 3 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <ImgCard
                          style={{ width: cardWidth / 4.5, height: cardHeight / 3 }}
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 3 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 4 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <ImgCard
                          style={{ width: cardWidth / 4.5, height: cardHeight / 3 }}
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 4 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })}
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
              </ReportCard>
            );
          }

          if (questionReport.questionObject.questionType_ID == 10) {
            {
              updateData(reportIndex, 10, questionReport.config, questionReport.question);
            }
          
            return (
              <ReportCard
                question={questionReport.question}
                information="Video"
                pageinfo={'Page ' + ++counter + ' of ' + squestion.length}
                cardHeight={cardHeight}
                cardWidth={cardWidth}
              >
                {Object.keys(questionReport.config).length > 0 && (
                  <div className="d-flex" style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                    <VidCard
                      style={{ width: '400px', height: '400px', objectFit: 'cover' }}
                      src={questionReport.config[questionReport.qid].path}
                      thumb={questionReport.config[questionReport.qid].thumb}
                      information=""
                    />
                  </div>
                )}
              </ReportCard>
            );
          }

          if (questionReport.questionObject.questionType_ID == 12) {
            {
              updateData(reportIndex, 12, questionReport.config, questionReport.question);
            }
            let html = '';
            return (
              <ReportCard
                question={questionReport.question}
                information="Audios"
                pageinfo={'Page ' + ++counter + ' of ' + squestion.length}
                cardHeight={cardHeight}
                cardWidth={cardWidth}
              >
                {Object.keys(questionReport.config).length > 0 && Object.keys(questionReport.config).length <= 2
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 2 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <AudioCard
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 2 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).length > 2 && Object.keys(questionReport.config).length <= 4
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 2 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <AudioCard
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 2 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).length > 4 && Object.keys(questionReport.config).length <= 6
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 3 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <AudioCard
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 3 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 4 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <AudioCard
                          src={questionReport.config[a].path}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 4 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })}
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
              </ReportCard>
            );
          }

          if (questionReport.questionObject.questionType_ID == 13) {
            {
              updateData(reportIndex, 13, questionReport.config, questionReport.question);
            }
            let html = '';
            return (
              <ReportCard
                question={questionReport.question}
                information="Videos"
                pageinfo={'Page ' + ++counter + ' of ' + squestion.length}
                cardHeight={cardHeight}
                cardWidth={cardWidth}
              >
                {Object.keys(questionReport.config).length > 0 && Object.keys(questionReport.config).length <= 2
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 2 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <MP4Card
                          style={{ width: cardWidth / 4.5, height: cardHeight / 2 }}
                          src={questionReport.config[a].path}
                          thumb={questionReport.config[a].thumb}
                          information=""
                          name={questionReport.config[a].name}
                        />
                      );
                      if ((i != 0 && (i + 1) % 2 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).length > 2 && Object.keys(questionReport.config).length <= 4
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 2 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <MP4Card
                          style={{ width: cardWidth / 4.5, height: cardHeight / 3 }}
                          src={questionReport.config[a].path}
                          thumb={questionReport.config[a].thumb}
                          name={questionReport.config[a].name}
                          information=""
                        />
                      );
                      if ((i != 0 && (i + 1) % 2 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).length > 4 && Object.keys(questionReport.config).length <= 6
                  ? Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 3 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <MP4Card
                          style={{ width: cardWidth / 4.5, height: cardHeight / 3 }}
                          src={questionReport.config[a].path}
                          thumb={questionReport.config[a].thumb}
                          information=""
                          name={questionReport.config[a].name}
                        />
                      );
                      if ((i != 0 && (i + 1) % 3 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })
                  : Object.keys(questionReport.config).map((a, i) => {
                      if (i == 0 || i % 4 == 0) {
                        html += "<div class='d-flex'>";
                      }
                      html += ReactDOMServer.renderToString(
                        <MP4Card
                          style={{ width: cardWidth / 4.5, height: cardHeight / 3 }}
                          src={questionReport.config[a].path}
                          thumb={questionReport.config[a].thumb}
                          information=""
                          name={questionReport.config[a].name}
                        />
                      );
                      if ((i != 0 && (i + 1) % 4 == 0) || Object.keys(questionReport.config).length == i + 1) {
                        html += '</div>';
                      }
                    })}
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
              </ReportCard>
            );
          }

          if (questionReport.questionObject.questionType_ID == 4) {
            //updateData(reportIndex, 8, questionReport.config)
            return (
              <ReportCard
                question={questionReport.question}
                information="Likert"
                pageinfo={'Page ' + ++counter + ' of ' + squestion.length}
                cardHeight={cardHeight}
                cardWidth={cardWidth}
              >
                <div
                  className=""
                  style={{
                    height: '500px',
                    height: cardHeight / 1.3,
                    width: cardWidth / 1.5,
                    position: 'relative'
                  }}
                >
                  {questionReport.config.chartType == barChart && (
                    <>
                      {questionReport.config.data.chart != undefined && (
                        <div className="row">
                          {questionReport.config.data.chart.map((d, i) => {
                            return (
                              <div className="col-md-12">
                                <div style={{ fontSize: '12px', marginBottom: '8px' }}>
                                  <strong>{d.choiceName}</strong>&nbsp;<i>&mu;{d.mean}</i>
                                </div>
                                <Likert
                                  datasets={d}
                                  valueType={questionReport.config.valueType}
                                  onReady={onLikertChartReady}
                                  choice={d.choiceName}
                                  index={reportIndex}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                  {questionReport.config.chartType == table && (
                    <>
                      {updateData(reportIndex, 4, questionReport.config, questionReport.question)}
                      <div className="mt-3">
                        <AnalysisLikerTable
                          High={questionReport.config.data.table.high}
                          HighList={questionReport.config.data.table.highList}
                          Neutral={questionReport.config.data.table.neutral}
                          Low={questionReport.config.data.table.low}
                          LowList={questionReport.config.data.table.lowList}
                          MeanScore={questionReport.config.data.table.mean}
                          Header={questionReport.config.data.table.header}
                          Sum={questionReport.config.data.table.sum}
                        />
                      </div>
                    </>
                  )}
                </div>
              </ReportCard>
            );
          }
        })}
      </ScrollContainer>
    </ProjectWrapper>
  );
}

export default AnalysisReport;
