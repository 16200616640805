import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddVideoTrimMethods from '../../utils/api/field/AddVideoTrimData';

function* AddVideoTrimData(payload) {
  const promise = AddVideoTrimMethods.AddVideoTrimData(payload.payload);
  const { res } = yield promise;
  const jsonPart = res.substring(res.indexOf('{'), res.lastIndexOf('}') + 1);
  const parsedResponse = JSON.parse(jsonPart);
  const statusCode = parsedResponse?.Status?.StatusCode;
  yield putResolve({ type: actions.AddVideoTrimAction.ADDVIDEOTRIM_RECEIVED,  data: {
    statusCode,
    response: res.Response
  }});
}

export { AddVideoTrimData };
