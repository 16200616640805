import React, { useState } from 'react';
import User1 from '../../images/user1.png';
import { Text, Icon, UserTag, Button } from '../../common';

export function VideoClips({
  img = User1,
  alt = 'Clip Image',
  name,
  type,
  context,
  duration,
  width = '100%',
  height = '122px',
  className,
  handleSelect,
  radioIcon = false,
  closeIcon = true,
  tag
}) {
  return (
    <div className={`${className} relative-position`}>
      <img src={img} class="rounded  d-block" alt={alt} width={width} height={height} />
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div className="d-flex ">
          <Text type="5" fontSize="10px">
            {type}
          </Text>
          <Text type="4" fontSize="10px">
            - {name}
          </Text>
          <Text type="5" fontSize="10px">
            {context}
          </Text>
        </div>
        <Text type="5" fontSize="10px">
          {duration}
        </Text>
      </div>
      {tag && (
        <div className="tags-container">
          <UserTag fontSize="9px" className="mt-2 mr-2">
            Content Discoverability
          </UserTag>
        </div>
      )}
      <Icon
        className="absolute-position cursor"
        style={{
          top: 0,
          right: 0,
          fontSize: '20px'
        }}
      >
        {closeIcon && 'close'}
        {radioIcon && <CheckIcon />}
      </Icon>
    </div>
  );
}
// radio_button_unchecked, check_circle

function CheckIcon() {
  const [select, setSelect] = useState(false);

  if (select) {
    return (
      <Button simple onClick={() => setSelect(false)}>
        <Icon
          className="absolute-position cursor"
          style={{
            top: 0,
            right: 0,
            fontSize: '20px',
            color: 'white'
          }}
        >
          {' '}
          check_circle
        </Icon>
      </Button>
    );
  }

  return (
    <Button simple onClick={() => setSelect(true)}>
      <Icon
        className="absolute-position cursor"
        style={{
          top: 0,
          right: 0,
          fontSize: '20px'
        }}
      >
        {' '}
        radio_button_unchecked
      </Icon>
    </Button>
  );
}
