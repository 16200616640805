import { Button, Card, Icon } from "../../common";
import styled from "@emotion/styled";
import desktop_mobile from "../../assets/images/desktop_mobile.jpg";
import { Link } from "react-router-dom";
import landingImage from "../../assets/images/landing-image.png";
import fabulousImage from "../../assets/images/fabulous.png";
import agileImage from "../../assets/images/agile.png";
import collaborativeImage from "../../assets/images/collaborative.png";
import step1Image from "../../assets/images/step-1.png";
import step2Image from "../../assets/images/step-2.png";
import step3Image from "../../assets/images/step-3.png";
import engageImage from "../../assets/images/engage.png";
import mail from "../../assets/images/mail.svg";
import call from "../../assets/images/call.svg";
import { useState,useEffect } from "react";
import { css } from "@emotion/react";
import { Checkbox, InputText } from "../../components/form";

const Section = styled.div`
	padding: 6.5rem 1rem;
	overflow: hidden;

	&.landing-section {
		padding-top: 13rem;
	}

	.container-fluid {
		height: 100%;
	}

	.content {
		height: 100%;
		display: flex;
		align-items: center;
	}

	img {
		max-width: 100%;
	}

	.icon {
		/* width: 27px; */
	}

	.dark-content,
	.dark-content a {
		font-size: 14px;
		font-weight: 700;
		color: var(--seaBlue);
	}
	.smallScreen {
		display: none;
	}
	@media (max-width: 720px) {
		padding: 3.25rem 1rem;
		&.landing-section {
			padding-top: 7rem;
		}
		.bigScreen {
			display: none;
		}
		.smallScreen {
			display: block;
		}

		.checkbox-container {
			flex-direction: column;

			label {
				width: 100%;
			}
		}
	}
`;

const GreenButton = styled.button`
	font-size: 14px;
	background: var(--primaryColor);
	border-radius: 5px;
	border: 0;
	padding: 0.5rem 1rem;
	text-transform: uppercase;
	color: var(--white);
	font-weight: 700;
`;

const GreyButton = styled.button`
	font-size: 14px;
	background: transparent;
	border-radius: 5px;
	border: 1px solid var(--seaBlue);
	color: var(--seaBlue);
	font-weight: 700;
	text-transform: uppercase;
	padding: 0.5rem 1rem;
`;

const HeadingStyling = (props) => css`
	font-size: 48px;
	font-weight: 700;
	color: var(--deepBlue);

	.light {
		font-weight: 400;
	}

	.break {
		display: block;
	}
`;

const H1 = styled.h1`
	${HeadingStyling}
`;

const H2 = styled.h2`
	${HeadingStyling}
`;

const H3 = styled.h3`
	font-size: 36px;
	font-weight: 700;
	color: var(--deepBlue);
`;

const Step = styled.div`
	h2 {
		font-size: 48px;
		font-weight: 700;
		color: #dddddd;

		:hover {
			color: var(--primaryColor);
			font-weight: 800;
			cursor: pointer;
		}
	}

	.sub-heading,
	.sub-content {
		display: none;
	}

	&.active h2 {
		color: var(--primaryColor);
		font-weight: 800;
	}

	&.active .sub-heading,
	&.active .sub-content {
		display: block;
	}
`;

const SubHeading = styled.p`
	font-size: 20px;
	font-weight: 700;
	color: ${(props) => props.color || "var(--deepBlue)"};
`;

const SubContent = styled.p`
	font-size: 18px;
	font-weight: 400;
	color: var(--seaBlue);
`;

const StyledLabel = styled.label`
	font-size: 0.875rem;
	font-weight: 500;
	color: #898989;
	margin-bottom: 0;
`;

export default function LandingScreen() {
	const [active, setActive] = useState("1");

	const [scrollTo, setScrollTO] = useState(1)

	useEffect(() => {
		if(scrollTo === 1){
			window.scrollTo(0,0)
		}
	})

	return (
		<>
			<Section className="landing-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<H1 className="mb-3">
									Run user studies{" "}
									<span className="break">
										like a Pro.{" "}
										<span className="light">
											In a click.
										</span>
									</span>
								</H1>
								<SubContent
								>
								Humanify Technologies creates products that help make a more human centred world. Our remote research platform Explorastory, 
								helps business directly connect with their users. Helping marketing and product teams build fabulous experiences.
								</SubContent>

								<div className="mt-5">
								<a href="#contact-form"><GreenButton className="mr-3">
										Join Waitlist
									</GreenButton></a>
									{/* <GreyButton>Take a Tour</GreyButton> */}
								</div>
							</div>
							<div className="col-md-6">
								<img src={landingImage} alt="Landing Image" />
							</div>
						</div>
					</div>
				</div>
			</Section>
			<Section className="fabulous-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<H1 className="mb-1">Be fabulous</H1>
								<SubHeading className="mb-5">
									The best experiences are built with users.
								</SubHeading>

								<SubContent>
									Meet your respondents in the moments that
									matter. Watch them use your product, share
									their experiences. And build remarkable
									products.
								</SubContent>
							</div>
							<div className="col-md-6">
								<img src={fabulousImage} alt="Fabulous Image" />
							</div>
						</div>
					</div>

					{/* <StyledLandingImage src={desktop_mobile} alt="Maverick running on Desktop and Mobile" className="landing-section-img"/> */}
				</div>
			</Section>

			<Section className="agile-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="bigScreen col-md-6 ">
								<img src={agileImage} alt="Agile Image" />
							</div>
							<div className="col-md-5 offset-md-1">
								<H1 className="mb-1">Be agile</H1>
								<SubHeading className="mb-5">
									Get results in real-time.
								</SubHeading>

								<SubContent>
									Conduct studies on the go, and get quick
									results in just a few days. At literally
									half the cost.
								</SubContent>
							</div>
							<div className="smallScreen col-md-6 ">
								<img src={agileImage} alt="Agile Image" />
							</div>
						</div>
					</div>

					{/* <StyledLandingImage src={desktop_mobile} alt="Maverick running on Desktop and Mobile" className="landing-section-img"/> */}
				</div>
			</Section>

			<Section className="fabulous-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<H1 className="mb-1">Be collaborative</H1>
								<SubHeading className="mb-5">
									Join from your desk. Watch anytime.
								</SubHeading>

								<SubContent>
									Meet users in their homes right from your
									desk. Watch pre-recorded interviews if you
									missed them, without waiting for results.
									Stay updated, always.{" "}
								</SubContent>
							</div>
							<div className="col-md-6">
								<img
									src={collaborativeImage}
									alt="Collaborative Image"
								/>
							</div>
						</div>
					</div>

					{/* <StyledLandingImage src={desktop_mobile} alt="Maverick running on Desktop and Mobile" className="landing-section-img"/> */}
				</div>
			</Section>

			<Section className="steps-section">
				<div className="container">
					<H3 className="mb-3">Get started in 3 easy steps:</H3>

					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<Step
									className={`${
										active === "1" ? "active" : ""
									} mb-5`}
								>
									<h2
										className="mb-0"
										onClick={() => {setActive("1"),setScrollTO(0)}}
									>
										Recruit
									</h2>
									<SubHeading className="mb-2 sub-heading">
										Get the right respondent.
									</SubHeading>
									<SubContent className="sub-content">
										Meet power users, new users,
										influencers. Find NCCS/SEC classified
										users. Across geographies. Pick from our
										curated panel or recruit your own.{" "}
									</SubContent>
									{active === "1" && (
										<img
											src={step1Image}
											className="smallScreen"
											alt="Recruit Image"
										/>
									)}
								</Step>

								<Step
									className={`${
										active === "2" ? "active" : ""
									} mb-5`}
								>
									<h2
										className="mb-0"
										onClick={() => {setActive("2"),setScrollTO(0)}}
									>
										Design
									</h2>
									<SubHeading className="mb-2 sub-heading">
										Run specialised studies.
									</SubHeading>
									<SubContent className="sub-content">
										Run Discovery Studies to understand
										users, Journey Maps to design products,
										Concept Tests to check preferences, and
										more.{" "}
									</SubContent>
									{active === "2" && (
										<img
											src={step2Image}
											alt="Design Image"
											className="smallScreen"
										/>
									)}
								</Step>

								<Step
									className={`${
										active === "3" ? "active" : ""
									} mb-5`}
								>
									<h2
										className="mb-0"
										onClick={() => {setActive("3"),setScrollTO(0)}}
									>
										Analyse
									</h2>
									<SubHeading className="mb-2 sub-heading">
										Run advanced analysis, curated for you.
									</SubHeading>
									<SubContent className="sub-content">
										Run analysis and find patterns in
										unstructured, data. Use behavioural,
										qualitative, and statistical modelling.
										At the click of a button.{" "}
									</SubContent>

									{active === "3" && (
										<img
											src={step3Image}
											alt="Analyse Image"
											className="smallScreen"
										/>
									)}
								</Step>
							</div>
							<div className="col-md-6  sidebar-imgs">
								{active === "1" && (
									<img
										src={step1Image}
										alt="Recruit Image"
										className="bigScreen"
									/>
								)}
								{active === "2" && (
									<img
										src={step2Image}
										alt="Design Image"
										className="bigScreen"
									/>
								)}
								{active === "3" && (
									<img
										src={step3Image}
										alt="Analyse Image"
										className="bigScreen"
									/>
								)}
							</div>
						</div>
					</div>

					{/* <StyledLandingImage src={desktop_mobile} alt="Maverick running on Desktop and Mobile" className="landing-section-img"/> */}
				</div>
			</Section>
			<Section className="engage-section">
				<div className="container">
					<div className="content">
						<div className="row align-items-center">
							<div className="col-md-6">
								<H1 className="mb-3">Engage with us.</H1>
								<SubHeading className="mb-3">
									Start a free trial without a credit card, no
									strings attached! Or take a tour and learn
									more.
								</SubHeading>

								<div className="mt-5">
								<a href="#contact-form"><GreenButton className="mr-3">
										Join Waitlist
									</GreenButton></a>
									{/* <GreyButton>Take a Tour</GreyButton> */}
								</div>
							</div>
							<div className="col-md-6">
								<img src={engageImage} alt="Engage Image" />
							</div>
						</div>
					</div>
				</div>
			</Section>

			<Section className="footer-section" id="contact-form">
				<div className="container">
					<div className="content">
						<div className="row align-items-end">
							<div className="col-md-6">
								<H2>Still have questions?</H2>
								<SubHeading>
									You can choose to fill the form below or
									talk to one of our representatives. We’ll
									get back to you at the earliest
								</SubHeading>
							</div>
							<div className="col-md-5 offset-md-1">
								<div className="d-flex align-items-center mb-3">
									<Icon color="var(--seaBlue)">email</Icon>
									<p
										className="mb-0 pb-0 ml-2"
										style={{
											fontSize: "14px",
											fontWeight: "700",
											color: "var(--seaBlue)",
										}}
									>
										hello@explorastory.in
									</p>
								</div>
								<div className="d-flex align-items-center">
									<Icon color="var(--seaBlue)">call</Icon>
									<p
										className="mb-0 pb-0 ml-2"
										style={{
											fontSize: "14px",
											fontWeight: "700",
											color: "var(--seaBlue)",
										}}
									>
										+91 22 49559797/ +91 22 28596358/ +91 22
										28594394
									</p>
								</div>
							</div>
						</div>
					</div>

					<Card className="mt-5" withBorder={true}>
						<div className="row ">
							<div className="col-md-6 mb-4">
								<StyledLabel>First name</StyledLabel>
								<InputText showCharacters={false} />
							</div>
							<div className="col-md-5 offset-md-1 mb-4">
								<StyledLabel>Last name</StyledLabel>
								<InputText showCharacters={false} />
							</div>
						</div>

						<div className="row ">
							<div className="col-md-6 mb-4">
								<StyledLabel>Work email</StyledLabel>
								<InputText showCharacters={false} />
							</div>
							<div className="col-md-5 offset-md-1 mb-4">
								<StyledLabel>Organisation</StyledLabel>
								<InputText showCharacters={false} />
							</div>
						</div>

						{/* <div className="row mb-4">
							<div className="col-md-12">
								<p
									style={{
										fontSize: "14px",
									}}
								>
									What can we help you with?
								</p>
								<div className="d-flex checkbox-container mt-2">
									<Checkbox
										fontSize="14px"
										fontWeight="400"
										color="var(--seaBlue)"
										className="mr-5"
									>
										{"   "} I want to know more on the
										platform
									</Checkbox>
									<Checkbox
										fontSize="14px"
										fontWeight="400"
										color="var(--seaBlue)"
										className="mr-5"
									>
										{"   "}How do I become a respondent?
									</Checkbox>
									<Checkbox
										fontSize="14px"
										fontWeight="400"
										color="var(--seaBlue)"
									>
										{"   "} Tell me about the pricing
									</Checkbox>
								</div>
							</div>
						</div> */}

						<GreenButton className="mt-5">Submit</GreenButton>
					</Card>
				</div>
			</Section>
		</>
	);
}
