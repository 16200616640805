import React, { useEffect } from "react";
import { BrowserRouter as Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { LandingScreen } from "./views/StaticScreen";

// Sageer's code for Redirection


const RenderDashboard = ({ roleId, isloggedIn }) => {
    const value = useSelector(state => state.LoginReducer);
    const history = useHistory();
    useEffect(() =>{
        if (isloggedIn) {
            switch (roleId) {
                case 4:
                    // return (
                    //     <Redirect to='/dashboard-respondent'></Redirect>
                        
                    // );
                    if(history.location.pathname !== '/dashboard-respondent'){
                        history.replace('/dashboard-respondent');
                    }
                    break;
                case 5:
                    // return (
                    //     <Redirect to='/dashboard-super-admin'></Redirect>
                    // );
                    if(history.location.pathname !== '/dashboard-super-admin'){
                        history.replace('/dashboard-super-admin');
                    }
                    break;
                    
                case 3:
                    // return (   
                    //   <Redirect to='/dashboard-admin'></Redirect>
                    // )
                    if(history.location.pathname !== '/dashboard-admin'){
                        history.replace('/dashboard-admin');
                    }
                    break;
                case 1:
                    // return (
                    //     <Redirect to='/dashboard'>
                    //     </Redirect>
                    // )
                    if(history.location.pathname !== '/dashboard'){
                        history.replace('/dashboard');
                    }
                    break;
                default:
                    break;
            }
        }
    },[isloggedIn, roleId, history])

    useEffect(() =>{
        if(isloggedIn){
            sessionStorage.setItem('lastLoggedInPath',history.location.pathname)
        }
    },[isloggedIn, history]);

    useEffect(() =>{
        const lastLoggedInPath = sessionStorage.getItem('lastLoggedInPath');
        if(lastLoggedInPath && isloggedIn){
            history.replace(lastLoggedInPath);
        }
    },[isloggedIn,history])

    if(isloggedIn){
        return null;
    }else{
        return <LandingScreen/>;
    }
}

export default RenderDashboard;