import styled from '@emotion/styled';
import Icon from './Icon';

const TAG = styled.div`
    display: inline-block;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    padding: 6px 26px 6px 6px;
    position: relative;
    font-weight: 800;
    color: var(--greyBlue);
    font-size: ${props => props.fontSize ? props.fontSize : '1rem'};

    .icon-box {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        :hover {
            background: var(--lightestGrey);
            cursor: pointer;
        }
    }

    .material-icons {
        font-size: 1rem;
        color: var(--grey);
    }
`

export default function UserTag({ children, className,onCancel, fontSize }){
    return (
        <TAG className={className} fontSize={fontSize}>{children} <div className='icon-box' onClick={onCancel}><Icon className="material-icons-round">close</Icon></div></TAG>
    )
}