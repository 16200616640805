import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
    Avatar,
    ActionButton,
    Heading,
    HelperText,
    ProjectCard,
    Button,
    NotificationCard,
    WeekCalenderCard,
    TabsContainer,
    Tabs,
    Tab,
    TabsContentContainer,
    NotificationInfo,
    Text,
    Time,
    Icon
} from '../../common';
import { Link } from 'react-router-dom';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import PlusWhite from '../../assets/images/plus-white.svg';
import PlusGreen from '../../assets/images/plus-green.svg';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_WEBDASHBOARD_DATA } from '../../constants/dashboard/webDashboardConstants';
import { REQUEST_FIELD_DATA } from '../../constants/field/GetFieldOverviewConstants';
import maverickPortalhttp from '../../utils/webService/webService';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';
import avatar from '../../images/researcher.png';
import { edit_status, fieldsData, form_status, interview_status } from '../Project/data';
import Countdown from 'react-countdown';
import moment from 'moment';
import filter from '../../images/filter.svg';
import { ButtonRadio, Dropdown, Checkbox } from '../../components/form';
import uparrow from '../../images/uparrow.svg';
import downArrow from '../../images/downArrow.svg';

const addQuote = text => {
    if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(text) == true) {
        return text.indexOf(' ') >= 0 ? "'" + text + "'" : text;
    } else {
        return avatar;
    }
};

const CardButton = styled(Button)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  background: var(--white);
  color: var(--labelColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.65rem 1rem;
  font-weight: 600;
  font-size: 1rem;
  border: 0;
  transition: background-color 250ms, color 250ms;

  img {
    margin-right: 8px;
  }

  .plus-white {
    display: none;
  }

  span {
    background: transparent;
    display: inline-block;
    margin-right: 5px;
    font-size: 2.2rem;
    color: var(--primaryColor);
    transition: color 250ms;
  }

  .active {
    background: var(--grey5);
    color: var(--ternaryColor);
    text-decoration: none;

    span {
      color: var(--ternaryColor);
    }
  }

  :hover,
  :focus {
    background: var(--primaryColor);
    color: var(--white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    transition: background-color 250ms, color 250ms;

    span {
      color: var(--white);
      transition: color 250ms;
    }

    .plus-white {
      display: inline-block;
    }

    .plus-green {
      display: none;
    }
  }
`;

const SeeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;

  .reverse {
    transform: rotate(-180deg);
  }
`;
const Circle = styled.div`
  border: ${props => (props.status ? `` : `1px solid var(--greyBlue)`)};
  background: ${props =>
        props.status == 1
            ? `var(--primaryColor)`
            : props.status == 2
                ? `var(--darkYellow)`
                : props.status == 3
                    ? `var(--red)`
                    : `var(--white)`};
  width: 9px;
  height: 9px;
  border-radius: 4.5px;
  margin-right: 5px;
`;

const PopupFilter = styled.div`
  background: white;
  position: absolute;
  padding: 0;
  margin: 0;
  z-index: 1050;
  left: 0;
  top: 33px;
  border-top: 3px solid var(--highlightGrey);
  padding: 1rem 1.5rem;
  width: ${props => props.width || '450px'};
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
`;

const FilterIconContainer = styled.div`
  padding: 2px;
  margin: 2px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${props => (props.isOpen || props.active ? 'var(--highlightGrey)' : 'transparent')};
  border-radius: 5px;
  .iconContainer {
    height: 26px;
  }
  .icon {
    padding: 7px 5px;
    // height: 35px;
    // width: 100%;
    // object-fit: contain;
  }
  .corner {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const SeeLess = () => (
    <>
        See less <img src={GreenDropdown} className="ml-2" />
    </>
);

const SeeMore = () => (
    <>
        See more <img src={GreenDropdown} className="ml-2 reverse" />
    </>
);

const CustomButton = styled(Button)`
  width: ${props => props.width};
  padding: 3px 3px !important;
`;

const FilterIcon = ({
    src,
    multi_choice = false,
    count = false,
    active = false,
    children,
    isOpen,
    width,
    setIsOpen = () => { }
}) => {
    const [activeIcon, setActiveIcon] = useState(active);

    const handleToggle = () => {
        setIsOpen(prev => !prev);
        setActiveIcon(prev => !prev);
    };
    const openPopup = () => {
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };

    const [lapsedState, setLapsedState] = useState(false);

    return (
        <>
            <FilterIconContainer isOpen={isOpen} active={(count && count > 0) || activeIcon} className="filter_icon">
                <div
                    className="iconContainer"
                    onClick={handleToggle}
                    onMouseEnter={openPopup}
                // onMouseLeave={closePopup}
                >
                    {/* {count && count > 0 && <Badge>{count}</Badge>} */}
                    <img src={src} className="icon " />
                    {/* {multi_choice && !isOpen && (
                          <img src={corner} className='corner' />
                      )} */}
                </div>
                {multi_choice && isOpen && (
                    <PopupFilter width={width} onMouseLeave={closePopup}>
                        {children}
                    </PopupFilter>
                )}
            </FilterIconContainer>
        </>
    );
};
const StartDateFilter = () => {
    const handleClearAll = () => {
        // setSelection([]);
        setUserList([...fieldData.FieldOverview]);
    };
    const [selection, setSelection] = useState([]);
    // console.warn(selection);

    const FilterFormStatus = () => {
        let filterFormList = [];

        for (let i = 0; i < userList.length; i++) {
            for (let j = 0; j < selection.length; j++) {
                if (userList[i].formStatus == selection[j]) {
                    filterFormList.push(userList[i]);
                }
            }
        }

        setUserList(filterFormList);
    };
    // console.warn(userList);
    return (
        <>
            <div className="d-flex justify-content-between">
                <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="pb-2">
                    {/* Groups */}
                </Heading>
                <div onClick={handleClearAll}>
                    <Heading fontSize="10px" fontWeight="400" color="var(--sapGreen)" className="pb-2">
                        Clear All
                    </Heading>
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <div className="">
                    <Heading fontSize="10px" fontWeight="600" color="var(--deepBlue)" className="pb-1">
                        {/* Popstrata */}
                    </Heading>

                    <div className="d-flex ">
                        <div className="pr-3">
                            <div>
                                <Checkbox
                                    color="var(--deepBlue)"
                                    fontSize="10px"
                                    value={1}
                                    // onChange={handlechange}
                                    onChange={e => {
                                        // add to list
                                        if (e.target.checked) {
                                            setSelection([...selection, e.target.value]);
                                        } else {
                                            // remove from list
                                            setSelection(selection.filter(people => people !== e.target.value));
                                        }
                                    }}
                                >
                                    {`Today (Most recent)`}
                                </Checkbox>
                                <Checkbox
                                    color="var(--deepBlue)"
                                    fontSize="10px"
                                    value={0}
                                    // onChange={handlechange}
                                    // checked={selection.indexOf(value.toString()) != -1 || selection.indexOf(value) != -1}
                                    onChange={e => {
                                        // add to list
                                        if (e.target.checked) {
                                            setSelection([...selection, e.target.value]);
                                        } else {
                                            // remove from list
                                            setSelection(selection.filter(people => people !== e.target.value));
                                        }
                                    }}
                                >
                                    {`This Week`}
                                </Checkbox>
                                <Checkbox
                                    color="var(--deepBlue)"
                                    fontSize="10px"
                                    value={0}
                                    // onChange={handlechange}
                                    // checked={selection.indexOf(value.toString()) != -1 || selection.indexOf(value) != -1}
                                    onChange={e => {
                                        // add to list
                                        if (e.target.checked) {
                                            setSelection([...selection, e.target.value]);
                                        } else {
                                            // remove from list
                                            setSelection(selection.filter(people => people !== e.target.value));
                                        }
                                    }}
                                >
                                    {`Next Week`}
                                </Checkbox>
                                <Checkbox
                                    color="var(--deepBlue)"
                                    fontSize="10px"
                                    value={0}
                                    // onChange={handlechange}
                                    // checked={selection.indexOf(value.toString()) != -1 || selection.indexOf(value) != -1}
                                    onChange={e => {
                                        // add to list
                                        if (e.target.checked) {
                                            setSelection([...selection, e.target.value]);
                                        } else {
                                            // remove from list
                                            setSelection(selection.filter(people => people !== e.target.value));
                                        }
                                    }}
                                >
                                    {`Next Month`}
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Button size="small" onClick={FilterFormStatus}>
                    Apply
                </Button>
            </div>
        </>
    );
};

export default function RespondentDashboardScreen() {
    const [seeMore, setSeeMore] = useState(false);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.LoginReducer.userId);
    const [formStatus, setFromStatus] = useState(false);
    const [userList, setUserList] = useState();
    const [sort, setSort] = useState(false);
    // useEffect(() => {
    //     dispatch({
    //         type: REQUEST_WEBDASHBOARD_DATA,
    //         payload: {
    //             "user_ID": userId,
    //         }
    //     });
    // }, []);
    // let { id } = '2383';
    // useEffect(() => {
    //     dispatch({
    //         type: REQUEST_FIELD_DATA,
    //         payload: {
    //             user_ID: 1675,
    //             // project_ID: 1395,
    //             project_ID: 2383
    //         }
    //     });
    // }, []);
    // const projectStatus = useSelector(state => state.webDashboardReducer.projectStatus);
    // const notificationContent = useSelector(state => state.webDashboardReducer.notificationContent);
    // const fieldData = useSelector(state => state.GetFieldOverviewReducer.fieldData);
    // useEffect(() => {
    //     if (userList != null) {
    //           setUserList(
    //             [...projectData.FieldOverview].sort(function (a, b) {
    //               let x = a.projectTitle.toUpperCase();
    //               let y = b.projectTitle.toUpperCase();
    //               if (x < y) {
    //                 return -1;
    //               }
    //               if (x > y) {
    //                 return 1;
    //               }
    //               return 0;
    //             })
    //           );
    //         }
    //     }, []);

    function sortList() {
        // const numDescending = [...fieldData.FieldOverview].sort((a, b) => b.Respondent_ID - a.Respondent_ID);
       
        // const userList = numDescending;
        if (sort === true) {
            const numAscending = [...userList.FieldOverview].sort(function (a, b) {
                let x = a.projectTitle.toUpperCase();
                let y = b.projectTitle.toUpperCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
            setSort(false);
            
            setUserList(numAscending);
        } else if (sort === false) {
            const numDescending = [...userList.FieldOverview].sort(function (a, b) {
                let x = a.projectTitle.toUpperCase();
                let y = b.projectTitle.toUpperCase();
                if (x > y) {
                    return -1;
                }
                if (x < y) {
                    return 1;
                }
                return 0;
            });

            setSort(true);
            setUserList(numDescending);
        }
    }



    const FormStatus = () => {
        const handleClearAll = () => {
            // setSelection([]);
            setUserList([...fieldData.FieldOverview]);
        };
        const [selection, setSelection] = useState([]);
        // console.warn(selection);

        const FilterFormStatus = () => {
            let filterFormList = [];

            for (let i = 0; i < userList.length; i++) {
                for (let j = 0; j < selection.length; j++) {
                    if (userList[i].formStatus == selection[j]) {
                        filterFormList.push(userList[i]);
                    }
                }
            }

            setUserList(filterFormList);
        };
        return (
            <>
                <div className="d-flex justify-content-between">
                    <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="pb-2">
                        {/* Groups */}
                    </Heading>
                    <div onClick={handleClearAll}>
                        <Heading fontSize="10px" fontWeight="400" color="var(--sapGreen)" className="pb-2">
                            Clear All
                        </Heading>
                    </div>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="">
                        <Heading fontSize="10px" fontWeight="600" color="var(--deepBlue)" className="pb-1">
                            {/* Popstrata */}
                        </Heading>

                        <div className="d-flex ">
                            <div className="pr-3">
                                <div>
                                    <Checkbox
                                        color="var(--deepBlue)"
                                        fontSize="10px"
                                        value={1}
                                        // onChange={handlechange}
                                        onChange={e => {
                                            // add to list
                                            if (e.target.checked) {
                                                setSelection([...selection, e.target.value]);
                                            } else {
                                                // remove from list
                                                setSelection(selection.filter(people => people !== e.target.value));
                                            }
                                        }}
                                    >
                                        {`Complete`}{' '}
                                    </Checkbox>
                                    <Checkbox
                                        color="var(--deepBlue)"
                                        fontSize="10px"
                                        value={0}
                                        // onChange={handlechange}
                                        // checked={selection.indexOf(value.toString()) != -1 || selection.indexOf(value) != -1}
                                        onChange={e => {
                                            // add to list
                                            if (e.target.checked) {
                                                setSelection([...selection, e.target.value]);
                                            } else {
                                                // remove from list
                                                setSelection(selection.filter(people => people !== e.target.value));
                                            }
                                        }}
                                    >
                                        {`Not initiated`}{' '}
                                    </Checkbox>
                                    {/* <Checkbox
                                        color='var(--deepBlue)'
                                        fontSize='10px'
                                    >
                                        {`G3`}{' '}
                                    </Checkbox> */}
                                    {/* <Checkbox
                                        color='var(--deepBlue)'
                                        fontSize='10px'
                                    >
                                        {`G4`}{' '}
                                    </Checkbox>
                                    <Checkbox
                                        color='var(--deepBlue)'
                                        fontSize='10px'
                                    >
                                        {`G5`}{' '}
                                    </Checkbox> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button size="small" onClick={FilterFormStatus}>
                        Apply
                    </Button>
                </div>
            </>
        );
    };

    // const fieldData = useSelector(state => state.GetFieldOverviewReducer.fieldData);
    // console.log('data', fieldData);
    //const fieldData = props.fieldData != undefined ? props.fieldData : "";
    // useEffect(() => {
    //     if (fieldData != null) {
    //         setUserList(
    //             [...fieldData.FieldOverview].sort(function (a, b) {
    //                 let x = a.RespondentName.toUpperCase();
    //                 let y = b.RespondentName.toUpperCase();
    //                 if (x < y) {
    //                     return -1;
    //                 }
    //                 if (x > y) {
    //                     return 1;
    //                 }
    //                 return 0;
    //             })
    //         );
    //     }
    // }, []);

    // useEffect(() => {
    //     if (fieldData != null) {
    //         setUserList(
    //             [...fieldData.FieldOverview].sort(function (a, b) {
    //                 let x = a.RespondentName.toUpperCase();
    //                 let y = b.RespondentName.toUpperCase();
    //                 if (x < y) {
    //                     return -1;
    //                 }
    //                 if (x > y) {
    //                     return 1;
    //                 }
    //                 return 0;
    //             })
    //         );
    //     }
    // }, [fieldData]);
    const Arrow = ({ icon_name = 'keyboard_arrow_left', style, active = true, onClick }) => {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: '10px',
                    top: '45%',
                    ...style
                }}
            >
                <div
                    style={{
                        background: active ? 'var(--sapGreen)' : 'var(--grey20)',
                        width: '22px',
                        height: '22px',
                        borderRadius: '11px',
                        alignItems: 'center',
                        paddingTop: '3px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Button simple className="pr-0 pl-0" onClick={onClick}>
                        <Icon
                            color="var(--white)"
                            style={{
                                fontSize: '1.2rem',
                                lineHeight: '1'
                            }}
                        >
                            {icon_name}
                        </Icon>
                    </Button>
                </div>
            </div>
        );
    };

    const InterviewContainer = styled.div`
    border-left: 1px solid var(--greyBlue30);
    border-right: 1px solid var(--greyBlue30);
    box-sizing: border-box;
    padding: 0.5rem;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  `;

    const InterviewCardContainer = styled.div`
    border: 1px solid var(--greyBlue30);
    padding: 1rem;
    box-sizing: border-box;
    width: 200px;
    border-radius: 10px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  `;

    const InterviewCard = props => {
        const { index, image, round, name, startTime, endTime, active = true, item, id, usersList } = props;
        const current_date_time = new Date();
        const converted_start_time = new Date(startTime);
        let start_time_in_seconds = converted_start_time - current_date_time;
        
        return (
            <InterviewCardContainer>
                <div className="d-flex  mb-3 align-items-center">
                    <img
                        src={item?.preAssingedImageUrl ? item?.preAssingedImageUrl : avatar}
                        className="img-fluid "
                        style={{ width: 40, height: 40, borderRadius: 20 }}
                    />
                    <div className="pl-2">
                        <Heading
                            type="3"
                            className="mb-0"
                            fontSize="0.875rem"
                            fontWeight="700"
                            color="var(--secondaryColor)"
                            breakHeading={false}
                        >
                            <span>
                                <Heading
                                    type="3"
                                    className="mb-0"
                                    fontWeight="500"
                                    fontSize="0.875rem"
                                    color="var(--secondaryColor)"
                                    breakHeading={false}
                                >
                                    {round}
                                </Heading>
                            </span>

                            {/* {'R' + (index + 1) + ' - ' + name.slice(0, 10) + '...'} */}
                            {name.slice(0, 10) + '...'}
                        </Heading>
                        <Heading type="5" className="mb-0" fontWeight="400" fontSize="0.75rem" color="var(--secondaryColor)">
                            <Countdown date={Date.now() + start_time_in_seconds} renderer={data => renderer({ ...data, endTime })} />
                        </Heading>
                    </div>
                </div>
                {/* {id != undefined ? (
                    item.isInterviewStart == 1 ? (
                        <Link
                            style={{ color: 'var(--white)' }}
                            to={{ pathname: '/project/' + id + '/pre-call', state: { data: item, usersList: usersList } }}
                        >
                            <Button variant={active ? 'fill' : 'outlined'} buttonColor="var(--sapGreen)" round={true} size="small">
                                Start
                            </Button>
                        </Link>
                    ) : (
                        <Link
                            style={{ color: 'var(--white)' }}
                            to={{ pathname: '/project/' + id + '/pre-call', state: { data: item, usersList: usersList } }}
                        >
                            <Button variant={active ? 'fill' : 'outlined'} buttonColor="var(--sapGreen)" round={true} size="small">
                                Join
                            </Button>
                        </Link>
                    )
                ) : (
                    ''
                )} */}
                {formattedDate2 < item.interviewEndTime && item.interviewStatus != 1 ? (
                    <CustomButton className="ml-2" size="small" buttonColor="var(--sapGreen)">
                        <Link
                            to={{
                                pathname: '/project/' + id + '/pre-call-resp',
                                state: { data: item, usersList: userList.FieldOverview }
                            }}
                            style={{ color: 'white' }}
                        >
                            Join
                        </Link>
                    </CustomButton>
                ) : (
                    <CustomButton disabled className="ml-2" size="small">
                        Join
                    </CustomButton>
                )}
            </InterviewCardContainer>
        );
    };

    const InterviewEmptyContainer = styled.div`
    width: 270px;
    height: 120px;
    // background: red;
    white-space: pre-line;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem;
    h1 {
      word-wrap: break-word;
      text-align: center;
    }
  `;
    const InterviewEmptyScreen = props => {
        return (
            <InterviewEmptyContainer>
                <Heading fontSize="14px" fontWeight="normal" color="var(--deepBlue)">
                    No Interviews for todays.
                </Heading>
                {/* <Link to={'/project/' + props.id + '/analysis'}>
                    <Button
                        variant={'fill'}
                        buttonColor="var(--sapGreen)"
                        round={true}
                        size="small"
                        fontSize="12px"
                        fontWeight="bold"
                    >
                        Go to Analysis
                    </Button>
                </Link> */}
            </InterviewEmptyContainer>
        );
    };

    const TableContainer = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 0.1px var(--greyBlue);
    .search {
      padding-left: 10px;
    }
    th:first-of-type {
      border: none;
      border-top-left-radius: 10px;
      padding-left: 2rem;
    }
    th:last-of-type {
      border: none;
      border-top-right-radius: 10px;
      border-left: 1px solid var(--grey20);
    }
    tr:last-of-type td:first-of-type {
      border-bottom-left-radius: 10px;
    }
    tr:last-of-type td:last-of-type {
      border-bottom-right-radius: 10px;
    }
    tr,
    td,
    th {
      border: 0;
      border-bottom: 0 !important;
    }
    thead {
      background: var(--greyBlue);
      color: var(--white);
    }
    th {
      font-weight: 600;
      font-size: 0.75rem;
      padding: 1rem 0;
      padding-left: 0.625rem;
      border: 0;
      border-left: 1px solid var(--grey20);
    }
    td:first-of-type {
      padding-left: 2rem;
    }
    td {
      padding: 0.675rem 0;
      padding-left: 0.625rem;
      vertical-align: middle;
    }
    button {
      font-size: 0.625rem;
      font-weight: 700;
      padding: 5px 10px;
      :disabled,
      [disabled] {
        background: transparent;
        border-color: var(--middleGrey);
        color: var(--middleGrey);
        :hover {
          box-shadow: none;
          background: #c2c2c2;
          color: #ffffff;
          cursor: not-allowed;
        }
      }
    }
    .icon {
      font-size: 1rem;
      font-weight: 100;
    }
    & > tbody > tr:nth-child(even) {
      background: var(--greyBlueLight);
    }
    & > tbody > trtr:nth-child(odd) {
      background: var(--white);
    }
  `;

    const Pill = styled.span`
    width: 70px;
    font-size: 9px;
    font-family: 'Noto Sans';
    padding: 2px 3px 2px 3px;
    // margin: 0.25em;
    // border-radius: 1em;
    // border: none;
    // outline: none;
    // background: #dddddd;
    border-radius: 20px;
    // cursor: pointer;
    border: 1px solid #38454f;
    // font-weight: bold;
    color: #38454f;
    text-align: center;
  `;

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    const checkTime = endTime => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        if (formattedDate > endTime.endTime) {
            return 3;
        } else {
            return 1;
        }
    };

    const renderer = ({ days, hours, minutes, seconds, completed, endTime }) => {
        if (completed) {
            // Render a completed state
            var date = moment(endTime);
            var now = moment();
            // now.set({ h: 16 });
            // console.log(now);
            if (now < date) {
                return <span>Live Now</span>;
            } else {
                return <span>Lapsed</span>;
            }
        } else {
            // Render a countdown
            return (
                <span>
                    <span>Scheduled</span>
                    Starts in <br />
                    {/* {days !=0 && pad(days,2) + ":"} */}
                    {hours != 0 && pad(hours, 2) + (hours > 1 ? ' hours ' : ' hour ')}
                    {minutes != 0 && pad(minutes, 2) + ' mins'}
                    {/* {seconds !=0 && pad(seconds,2)} */}
                </span>
            );
        }
    };

    const renderer2 = ({ days, hours, minutes, seconds, completed, endTime, count }) => {
        if (completed) {
            // Render a completed state
            var date = moment(endTime);
            var now = moment();
            // now.set({ h: 16 });
            // console.log(now);
            if (now < date) {
                return <span>Live Now</span>;
            } else {
                return <span>Lapsed</span>;
            }
        } else {
            // Render a countdown
            return (
                <span style={{ position: 'relative' }}>
                    <span style={{ display: 'flex' }}>
                        Scheduled
                        <Pill className="ml-2">Rescheduled</Pill>
                    </span>
                    Starts in <br />
                    {/* {days !=0 && pad(days,2) + ":"} */}
                    {hours != 0 && pad(hours, 2) + (hours > 1 ? ' hours ' : ' hour ')}
                    {minutes != 0 && pad(minutes, 2) + ' mins'}
                    {/* {seconds !=0 && pad(seconds,2)} */}
                    <span
                        style={{
                            position: 'absolute',
                            top: -10,
                            right: -5,
                            background: '#EB5757',
                            color: 'white',
                            borderRadius: '50%',
                            padding: '1px 4px'
                            // fontSize: '12px',
                        }}
                    >
                        {count}
                    </span>
                </span>
            );
        }
    };

    const currentDate2 = new Date();
    const year2 = currentDate2.getFullYear();
    const month2 = String(currentDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(currentDate2.getDate()).padStart(2, '0');
    const hours2 = String(currentDate2.getHours()).padStart(2, '0');
    const minutes2 = String(currentDate2.getMinutes()).padStart(2, '0');
    const seconds2 = String(currentDate2.getSeconds()).padStart(2, '0');

    const formattedDate2 = `${year2}-${month2}-${day2} ${hours2}:${minutes2}:${seconds2}`;
    useEffect(() => {
        getRespondentDashboardata();
    }, []);

    const getRespondentDashboardata = () => {
        const user_Id = localStorage.getItem('userId');
        maverickPortalhttp
            .makeRequest(
                `${maverickPortalApiEndPoint.respondentDaashboardData.url}/${user_Id}`,
                maverickPortalApiEndPoint.respondentDaashboardData.methodType
            )
            .then(response => {
                // if (response.data.Response !== undefined && response.data.Response?.token_data.access_token.length) {
                //    AuthService.login(response.data.Response.token_data.access_token);
                // }
                // else {
                //   console.log(response.data?.Status.StatusDesc)
                // }
                const res = response.data?.Response;
                setUserList(res);
            });
    };

    const getEndTime = endDateTime => {
        let today = new Date(endDateTime);

        let ampm = today.getHours() >= 12 ? 'pm' : 'am';
        let time =
            today
                .getHours()
                .toString()
                .padStart(2, '0') +
            ':' +
            today
                .getMinutes()
                .toString()
                .padStart(2, '0');

        return time + ' ' + ampm;
    };
    const getStartDateTime = startDateTime => {
        let today = new Date(startDateTime);
        let ampm = today.getHours() >= 12 ? 'pm' : 'am';
        let convertedDate = today
            .getDate()
            .toString()
            .padStart(2, '0');
        let time =
            today
                .getDate()
                .toString()
                .padStart(2, '0') +
            getDateFormats(convertedDate) +
            today.toLocaleString('default', { month: 'short' }) +
            ' ' +
            today
                .getFullYear()
                .toString()
                .substr(-2) +
            '|' +
            today
                .getHours()
                .toString()
                .padStart(2, '0') +
            ':' +
            today
                .getMinutes()
                .toString()
                .padStart(2, '0');

        return time + ' ' + ampm;
    };

    const getDateFormats = convertedDate => {
        if (convertedDate == '1') {
            return 'st ';
        } else if (convertedDate == '2') {
            return 'nd ';
        } else if (convertedDate == '3') {
            return 'rd ';
        } else {
            return 'th ';
        }
    };

    return (
        <>
            {/* Heading */}
            <div className="row">
                <div className="col-sm-8">
                    <Heading>My Projects</Heading>
                    <Text type="3" color="var(--ternaryColor)" className="mb-0">
                        View your ongoing projects, view your interview status and join calls
                    </Text>
                </div>
            </div>

            {/* Project Data start */}

            {/* Interviews for today */}
            <div className="mt-4">
                <Heading type="3" fontSize="0.75rem" color="var(--secondaryColor)">
                    Interviews for Today
                </Heading>
            </div>
            <div className="mt-3">
                {/* <Arrow
                    active={scrollValue == 0 || typeof scrollValue === 'undefined' ? false : true}
                    onClick={() => handleScroll(-170)}
                /> */}
                <div className="px-4">
                    <Heading type="3" fontSize="0.75rem" color="var(--secondaryColor)">
                        {/* Interviews for Today */}
                    </Heading>

                    <InterviewContainer>
                        {userList != undefined && userList.FieldOverviewToday?.length > 0 ? (
                            userList.FieldOverviewToday?.map((item, index) =>
                                item.interviewStatus != 1 && formattedDate2 < item.interviewEndTime ? (
                                    <InterviewCard
                                        index={index}
                                        image={item.preAssingedImageUrl ? item.preAssingedImageUrl : avatar}
                                        round={item.round}
                                        name={item.projectTitle}
                                        startTime={item.interviewStartTime}
                                        endTime={item.interviewEndTime}
                                        active={item.active}
                                        item={item}
                                        usersList={userList.FieldOverview}
                                        id={item.project_ID}
                                    />
                                ) : (
                                    ''
                                )
                            )
                        ) : (
                            <InterviewEmptyScreen />
                        )}
                    </InterviewContainer>
                </div>
                {/* <Arrow
                    active={
                        (fieldData != undefined && fieldData.InterviewsForToday.length == 0) || scrollValue >= 350 ? false : true
                    }
                    icon_name="keyboard_arrow_right"
                    style={{ left: 'none', right: '10px' }}
                    onClick={() => handleScroll(170)}
                /> */}
            </div>

            {/* table Data */}
            <div className="mt-3">
                <Heading type="3" fontSize="0.75rem" color="var(--secondaryColor)">
                    Projects
                </Heading>
                <TableContainer className="table mt-3 position-relative">
                    <thead>
                        <tr>
                            <th style={{ width: '25%' }}>
                                <div className=" d-flex align-items-center">
                                    Interview Date & Time
                                </div>
                            </th>
                            <th style={{ width: '22%' }}>
                                <div className="name d-flex align-items-center">
                                    Name{' '}
                                    {/* <div className="d-flex px-3" onClick={sortList}>
                                        <img className="icon" src={uparrow} />
                                        <img className="icon" src={downArrow} />
                                    </div> */}
                                    <div>
                                        {/* <img className="icon" src={search} /> */}
                                        {/* <FilterIcon src={search} multi_choice isOpen={searchName} setIsOpen={setsearchName}>
                                            <Search />
                                        </FilterIcon> */}
                                    </div>
                                </div>
                            </th>
                            {/* <th style={{ width: '8%' }}>
                                <div className=" d-flex align-items-center">
                                    Group
                                    <img className="icon pl-3" src={filter} onClick={GeographyFilter} />
                                    <FilterIcon src={filter} multi_choice isOpen={Group} setIsOpen={setGroup}>
                                        <Groups />
                                    </FilterIcon>
                                </div>
                            </th> */}
                            <th style={{ width: '23%' }}>
                                <div className=" d-flex align-items-center ">
                                    Form Status
                                    {/* <img className="icon pl-3" src={filter} /> */}
                                    {/* <FilterIcon
                                        className="icon pl-3"
                                        src={filter}
                                        multi_choice
                                        isOpen={formStatus}
                                        setIsOpen={setFromStatus}
                                    >
                                        <FormStatus />
                                    </FilterIcon> */}
                                </div>
                            </th>
                            <th style={{ width: '30%' }}>
                                <div className=" d-flex align-items-center">
                                    Interview Status
                                    {/* <img className="icon pl-3" src={filter} /> */}
                                    {/* <FilterIcon src={filter} multi_choice isOpen={interviewStatus} setIsOpen={setInterviewStatus}>
                                        <InterviewStatus />
                                    </FilterIcon> */}
                                </div>
                            </th>
                            {/* <th style={{ width: '20%' }}>
                                <div className=" d-flex align-items-center  ">
                                    Edit Status
                                    <img className="icon pl-3" src={filter} /> 
                                    <FilterIcon src={filter} multi_choice width={'180px'} isOpen={editStatus} setIsOpen={setEditStatus}>
                                        <EditStatus />
                                    </FilterIcon>
                                </div>
                            </th>  */}
                        </tr>
                    </thead>
                    <tbody className="position-relative">
                        {userList &&
                            userList.FieldOverview &&
                            userList.FieldOverview.map((item, index) => {
                                return (
                                    <>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {(item?.interviewStartTime && item?.interviewEndTime !== "0")
                                                        ?
                                                        <Heading
                                                            type="3"
                                                            className="mb-0"
                                                            fontSize="0.75rem"
                                                            fontWeight="700"
                                                            color="var(--sapGreen)"
                                                            breakHeading={false}
                                                        >
                                                            {/* <span>
                                                         <Heading
                                                             type="3"
                                                             className="mb-0"
                                                             fontWeight="500"
                                                             fontSize="0.75rem"
                                                             color="var(--sapGreen)"
                                                             breakHeading={false}
                                                         >
                                                             R{index + 1}
                                                         </Heading>
                                                     </span> */}

                                                            {/* {' - '} */}
                                                            <>
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            color: '#38454F',
                                                                            fontSize: 12,
                                                                            fontFamily: 'Noto Sans',
                                                                            fontWeight: '500',
                                                                            wordWrap: 'break-word'
                                                                        }}
                                                                    >
                                                                        {getStartDateTime(item?.interviewStartTime)} -
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: '#38454F',
                                                                            fontSize: 12,
                                                                            fontFamily: 'Noto Sans',
                                                                            fontWeight: '400',
                                                                            wordWrap: 'break-word'
                                                                        }}
                                                                    >
                                                                        {getEndTime(item?.interviewEndTime)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        </Heading>
                                                        : <Heading
                                                            type="3"
                                                            className="mb-0"
                                                            fontSize="0.75rem"
                                                            fontWeight="700"
                                                            // color="var(--sapGreen)"
                                                            breakHeading={false}
                                                        >To be scheduled
                                                        </Heading>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {/* <img
                                                        src={item.preAssingedImageUrl ? item.preAssingedImageUrl : avatar}
                                                        className="img-fluid "
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            borderRadius: 10,
                                                            marginRight: 10
                                                        }}
                                                    /> */}
                                                    <Heading
                                                        type="3"
                                                        className="mb-0"
                                                        fontSize="0.75rem"
                                                        fontWeight="700"
                                                        color="var(--sapGreen)"
                                                        breakHeading={false}
                                                    >
                                                        {/* <span>
                                                            <Heading
                                                                type="3"
                                                                className="mb-0"
                                                                fontWeight="500"
                                                                fontSize="0.75rem"
                                                                color="var(--sapGreen)"
                                                                breakHeading={false}
                                                            >
                                                                R{index + 1}
                                                            </Heading>
                                                        </span> */}

                                                        {/* {' - '} */}
                                                        {item.projectTitle}
                                                    </Heading>
                                                </div>
                                            </td>
                                            {/* <td>
                                                    <Heading
                                                        type="3"
                                                        className="mb-0"
                                                        fontWeight="700"
                                                        fontSize="0.625rem"
                                                        color="var(--deepBlue)"
                                                    >
                                                        {item.ProjectGroup}
                                                    </Heading>
                                                </td> */}
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        style={{
                                                            width: '80px'
                                                        }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Circle status={item.formStatus} />
                                                        <Heading
                                                            type="3"
                                                            className="mb-0"
                                                            fontWeight="500"
                                                            fontSize="0.625rem"
                                                            color="var(--deepBlue)"
                                                        >
                                                            {form_status[item.formStatus]}
                                                        </Heading>
                                                    </div>

                                                    {/* {item.formStatus != 0 ? (
                                                        <CustomButton
                                                            className="ml-2"
                                                            size="small"
                                                            variant="outlined"
                                                            buttonColor="var(--sapGreen)"
                                                        >
                                                            {item.formStatus == 1 ? 'View' : 'Remind '}
                                                        </CustomButton>
                                                    ) : (
                                                        <CustomButton className="ml-2" disabled size="small" variant="outlined">
                                                            View
                                                        </CustomButton>
                                                    )} */}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        style={{
                                                            width: '180px'
                                                        }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                paddingRight: 10
                                                            }}
                                                        >
                                                            {/* <Circle
                                  status={
                                    interview_status[item.interviewStatus] == 'Scheduled Starts in' && item.isInterviewStart != 0
                                      ? checkStatusBox({ ...item, endTime: item.interviewEndTime })
                                      : item.interviewStatus && item.interviewStatus == 2
                                        ? 1
                                        : item.interviewStatus
                                  }
                                /> */}
                                                            <Circle
                                                                status={
                                                                    item.interviewStatus == 0
                                                                        ? 0
                                                                        : item.interviewStatus == 1
                                                                            ? 1
                                                                            : item.interviewStatus == 2 || item.interviewStatus == 3
                                                                                ? checkTime({ endTime: item.interviewEndTime })
                                                                                : item.interviewStatus
                                                                }
                                                            />
                                                            <Circle
                                                                status={
                                                                    item.interviewStatus && item.interviewStatus == 2
                                                                        ? 0
                                                                        : item.interviewStatus == 3
                                                                            ? 0
                                                                            : item.interviewStatus
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <Heading
                                                                type="3"
                                                                className="mb-0"
                                                                fontWeight="500"
                                                                fontSize="0.625rem"
                                                                color="var(--deepBlue)"
                                                            >
                                                                {interview_status[item.interviewStatus] == 'Scheduled' ? (
                                                                    <>
                                                                        <br />
                                                                        <Countdown
                                                                            date={Date.now() + (new Date(item.interviewStartTime) - new Date())}
                                                                            renderer={
                                                                                item.updated_count !== null
                                                                                    ? data =>
                                                                                        renderer2({
                                                                                            ...data,
                                                                                            endTime: item.interviewEndTime,
                                                                                            count: item.updated_count
                                                                                        })
                                                                                    : data => renderer({ ...data, endTime: item.interviewEndTime })
                                                                            }
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    interview_status[item.interviewStatus]
                                                                )}
                                                            </Heading>
                                                            <Heading
                                                                type="3"
                                                                className="mb-0 mt-1"
                                                                fontWeight="400"
                                                                fontSize="0.625rem"
                                                                color="var(--deepBlue)"
                                                            >
                                                                {item.description}
                                                            </Heading>
                                                        </div>
                                                    </div>
                                                    {/* {item.interviewStatus == 0 || item.interviewStatus == 1 ? (
                                                        <CustomButton disabled className="ml-2" size="small" variant="outlined">
                                                            Reschedule
                                                        </CustomButton>
                                                    ) : (
                                                        <CustomButton
                                                            className="ml-2"
                                                            size="small"
                                                            variant="outlined"
                                                            buttonColor="var(--sapGreen)"
                                                            onClick={() => {
                                                                handleReschdule(item.InterviewSchedule_ID, index);
                                                            }}
                                                        >
                                                            Reschedule
                                                        </CustomButton>
                                                    )} */}
                                                    {/* {interview_status[item.interviewStatus] != 'Scheduled' ? (item.isInterviewStart == 1 ? (
                                                        <CustomButton disabled className="ml-2" size="small">
                                                            Start
                                                        </CustomButton>
                                                    ) : (
                                                        <CustomButton disabled className="ml-2" size="small">
                                                            Join
                                                        </CustomButton>
                                                    )
                                                        // ) : hours[index] < 3 ? (
                                                    ) : (formattedDate2 < item.interviewEndTime) ? (
                                                        item.isInterviewStart == 1 ? (
                                                            <CustomButton className="ml-2" size="small" buttonColor="var(--sapGreen)">
                                                                <Link
                                                                    to={{
                                                                        pathname: '/project/' + id + '/pre-call',
                                                                        state: { data: item, usersList: fieldData.FieldOverview }
                                                                    }}
                                                                    style={{ color: 'white' }}
                                                                >
                                                                    Start
                                                                </Link>
                                                            </CustomButton>
                                                        ) : (
                                                            <CustomButton className="ml-2" size="small" buttonColor="var(--sapGreen)">
                                                                <Link
                                                                    to={{
                                                                        pathname: '/project/' + id + '/pre-call',
                                                                        state: { data: item, usersList: fieldData.FieldOverview }
                                                                    }}
                                                                    style={{ color: 'white' }}
                                                                >
                                                                    Join
                                                                </Link>
                                                            </CustomButton>
                                                        )
                                                    ) : (item.isInterviewStart == 1 ? (
                                                        <CustomButton disabled className="ml-2" size="small">
                                                            Start
                                                        </CustomButton>
                                                    ) : (
                                                        <CustomButton disabled className="ml-2" size="small">
                                                            Join
                                                        </CustomButton>
                                                    )
                                                    )} */}
                                                    {formattedDate2 < item.interviewEndTime && item.interviewStatus != 1 ? (
                                                        <CustomButton className="ml-2" size="small" buttonColor="var(--sapGreen)">
                                                            <Link
                                                                to={{
                                                                    pathname: '/project/' + item.project_ID + '/pre-call-resp',
                                                                    state: { data: item, usersList: userList.FieldOverview }
                                                                }}
                                                                style={{ color: 'white' }}
                                                            >
                                                                Join
                                                            </Link>
                                                        </CustomButton>
                                                    ) : (
                                                        <CustomButton disabled className="ml-2" size="small">
                                                            Join
                                                        </CustomButton>
                                                    )}
                                                </div>
                                            </td>
                                            {/* <td>
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        style={{
                                                            width: '80px'
                                                        }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Circle status={item.editStatus} />
                                                        <Heading
                                                            type="3"
                                                            className="mb-0"
                                                            fontWeight="500"
                                                            fontSize="0.625rem"
                                                            color="var(--deepBlue)"
                                                        >
                                                            {edit_status[item.editStatus] ? edit_status[item.editStatus] : 'In Progress'}
                                                        </Heading>
                                                    </div>
                                                    {item.editStatus == 0 ? (
                                                        <CustomButton disabled className="ml-2" size="small" variant="outlined">
                                                            Edit
                                                        </CustomButton>
                                                    ) : item.editStatus == 3 ? (
                                                        <CustomButton disabled className="ml-2" size="small" variant="outlined">
                                                            Edit
                                                        </CustomButton>
                                                    ) : item.editStatus == 2 ? (
                                                        <div>
                                                            <CustomButton disabled className="ml-2" size="small" variant="outlined">
                                                                Edit
                                                            </CustomButton>
                                                            <CustomButton
                                                                className="ml-2"
                                                                size="small"
                                                                variant="outlined"
                                                                buttonColor="var(--sapGreen)"
                                                                onClick={() => handleClipping(item, id)}
                                                            >
                                                                Clipping
                                                            </CustomButton>
                                                        </div>
                                                    ) : (
                                                        <CustomButton
                                                            className="ml-2"
                                                            size="small"
                                                            variant="outlined"
                                                            buttonColor="var(--sapGreen)"
                                                            onClick={() => handleEdit(item, id)}
                                                        >
                                                            Edit
                                                        </CustomButton>
                                                    )}
                                                </div>
                                            </td> */}
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </TableContainer>
            </div>
        </>
    );
}
