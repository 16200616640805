// import styled from '@emotion/styled';
// import { Link } from 'react-router-dom';
// import { Avatar, Button } from '.';
// import logo from '../assets/images/logo.svg';
// import user_photo from '../assets/images/user.jpg';
// import { Store } from '../config/sagaConfig';

// const StyledNav = styled.nav`
// 	border-radius: 0;
// 	background: #efefef;
// 	.nav-item:last-child {
// 		margin-left: 1rem;
// 	}
// `;

// export default function AppNav() {
//   const { userName } = Store.getState().LoginReducer;
// 	return (
// 		<StyledNav className='navbar navbar-expand-lg fixed-top  p-1 mb-5'>
// 			<div className='container-fluid'>
// 				<Link className='navbar-brand' to='/'>
// 					<img src={logo} alt='Explorastory Logo' />
// 				</Link>
// 				<button
// 					className='navbar-toggler'
// 					type='button'
// 					data-toggle='collapse'
// 					data-target='#navbarSupportedContent'
// 					aria-controls='navbarSupportedContent'
// 					aria-expanded='false'
// 					aria-label='Toggle navigation'
// 				>
// 					<span className='navbar-toggler-icon'></span>
// 				</button>

// 				<div
// 					className='collapse navbar-collapse'
// 					id='navbarSupportedContent'
// 				>
// 					<ul className='navbar-nav ml-auto'>
// 						<li className='nav-item'>
// 							<Button simple fontSize='1rem' color='var(--secondaryColor)' className='p-3'>
// 								Hello, {userName} <Avatar src={user_photo} className='ml-2' />{' '}
// 							</Button>
// 						</li>
// 					</ul>
// 				</div>
// 			</div>
// 		</StyledNav>
// 	);
// }
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Divider, Heading, Icon } from '.';
import logo from '../assets/images/logo.svg';
import user_photo from '../assets/images/user.jpg';
import profile_pic from '../assets/images/profile_pic.png';
import cross from '../assets/images/cross.svg';
import { Store } from '../config/sagaConfig';
import { REQUEST_GETPROFILEDATA } from '../constants/profile/GetProfileData';
import { useDispatch, useSelector } from 'react-redux';
import avatar from '../images/researcher.png';
import KebabMenu from '../assets/images/KebabMenu.svg';

const StyledNav = styled.nav`
	border-radius: 0;
	background: #efefef;
	.nav-item:last-child {
		margin-left: 1rem;
	}
`;

const Container = styled.div`
	position: absolute;
	width: 240px;

	right: 0px;
	// left: 0;
	top: 0px;

	/* Colour/White */

	background: #ffffff;
	/* Colour/Light Grey */

	border: 1px solid #e2e2e2;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
`;

const ProfileTab = ({ title, LinkTo, handleCLose }) => {
	return (
		<Link style={{ display: 'block' }} to={LinkTo} onClick={handleCLose}>
			<div className='d-flex align-items-center justify-content-between pt-3 '>
				<Heading
					fontSize='14px'
					fontWeight='800'
					color='var(--deepBlue)'
					className='mb-0 pb-0 '
				>
					{title}
				</Heading>
				<Icon
					style={{ fontSize: '18px' }}
					color='var(--middleGreen)'
				>
					arrow_forward_ios
				</Icon>
			</div>
		</Link>
	);
};
// const ProfilePopUp = ({ handleCLose ,GetProfileData}) => {
// 	const userId = useSelector(state => state.LoginReducer.userId);
// 	const dispatch = useDispatch();
// 	const data = {user_ID: userId};
// 	return (
// 		<Container className='px-3 login-info'>
// 			<div className='d-flex align-items-center py-2'>
// 				<img
// 					src={GetProfileData.profileImagePath}
// 					className='ml-2'
// 					width='60'
// 					height='60'
// 					onClick={() => handleCLose={handleCLose}}
// 					style={{cursor:'pointer', borderRadius: '50%'}}
// 				/>
// 				<Heading
// 					fontSize='14px'
// 					fontWeight='700'
// 					color='var(--deepBlue)'
// 					className='mb-0 pb-0 ml-3'
// 				>
// 					{GetProfileData.name}
// 				</Heading>
// 			</div>
// 			<Divider
// 				margin='0'
// 				height='1px'
// 				width='100%'
// 				color='var(--lightGrey)'
// 			/>
// 			<div className=' pb-3'>
// 				<ProfileTab
// 					title='Profile'
// 					LinkTo='/profile'
// 					handleCLose={handleCLose}
// 				/>
// 				<ProfileTab
// 					title='Security'
// 					LinkTo='/security'
// 					handleCLose={handleCLose}
// 				/>
// 			</div>
// 			<Divider
// 				margin='0'
// 				height='1px'
// 				width='100%'
// 				color='var(--lightGrey)'
// 			/>
// 			<Heading
// 				fontSize='14px'
// 				fontWeight='800'
// 				color='#FF8B8B'
// 				style={{cursor:'pointer'}}
// 				className='mb-0 pb-0 py-3 pt-3 '
// 				onClick={() => {  
// 					dispatch({ type: 'REQUEST_USER_LOGOUT', payload: data });
// 				  }}
// 			>
// 				Sign Out
// 			</Heading>
// 		</Container>
// 	);
// };

export default function AppNavSA() {
	const ProfilePopUp = ({ handleCLose ,GetProfileData}) => {
		const userId = useSelector(state => state.LoginReducer.userId);
		const dispatch = useDispatch();
		const data = {user_ID: userId};
		return (
			<Container className='px-3 login-info'>
				<div className='d-flex align-items-center py-2'>
					<Avatar
						src={GetProfileData?.presignedUrl != null ? GetProfileData?.presignedUrl : avatar }
						className='ml-2'
						width='60'
						height='60'
						onClick={() => setProfile(false)}
					/>
					<Heading
						fontSize='14px'
						fontWeight='700'
						color='var(--deepBlue)'
						className='mb-0 pb-0 ml-3'
					>
						{GetProfileData.name}
					</Heading>
					<img
						src={cross}
						className='mb-auto ml-auto mt-1'
						onClick={() => setProfile(false)}
						style={{cursor:'pointer', borderRadius: '50%'}}
					/>
				</div>
				<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>
				{/* <div className=' pb-3'>
					<ProfileTab
						title='Profile'
						LinkTo='/profile'
						handleCLose={handleCLose}
					/>
					<ProfileTab
						title='Security'
						LinkTo='/security'
						handleCLose={handleCLose}
					/>
				</div>
				<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/> */}
				<Heading
					fontSize='14px'
					fontWeight='800'
					color='#FF8B8B'
					style={{cursor:'pointer'}}
					className='mb-0 pb-0 py-3 pt-3 '
					onClick={() => {  
						dispatch({ type: 'REQUEST_USER_LOGOUT', payload: data });
					  }}
				>
					Sign Out
				</Heading>
			</Container>
		);
	};
	const { userName } = Store.getState().LoginReducer;
	const [profile, setProfile] = useState(false);
	const userId = useSelector(state => state.LoginReducer.userId);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({
		  type: REQUEST_GETPROFILEDATA,
		  payload: {
			user_ID: userId
		  }
		});
	  }, []);

	  const GetProfileData = useSelector(state => state.GetProfileReducer.GetProfileData != null ? state.GetProfileReducer.GetProfileData.userProfileData[0]  : "");
	
	return (
		<StyledNav className='navbar navbar-expand-lg fixed-top  p-1 mb-5'>
			<div className='container-fluid'>
				<Link className='navbar-brand' to='/dashboard-super-admin'>
					<img src={logo} alt='Explorastory Logo' />
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					data-toggle='collapse'
					data-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>

				<div
					className='collapse navbar-collapse'
					id='navbarSupportedContent'
				>
					<ul className='navbar-nav ml-auto'>
						<li className='nav-item'>
							<Button
								simple
								fontSize='1rem'
								color='var(--secondaryColor)'
								className='p-3'
								onClick={() => setProfile(true)}
							>
								Hello, {GetProfileData.name}{' '}
								<Avatar
									src={GetProfileData?.presignedUrl != null ? GetProfileData.presignedUrl : avatar}
									className='ml-2'
								/>{' '}
								<img src={KebabMenu}/>
							</Button>
						</li>
					</ul>
					{profile && (
						<ProfilePopUp
							handleCLose={() => setProfile(false)}
							GetProfileData={GetProfileData}
						/>
					)}
				</div>
			</div>
		</StyledNav>
	);
	
}
