import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as sendOtpMethods from '../../utils/api/otp/SendOtpData';


function* Otp(payload) {
  const promise = sendOtpMethods.SendOtp(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.otpAction.OTP_RECEIVED, data: res.Response });
}

export { Otp };
