import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VideoClips } from '../';
import ReviewClips from '../../images/editclips.png';
import { Card } from '../../common';

export function EditClips() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'REQUEST_ALLTRIMCLIPS', payload: { user_ID: '18', projectRepondentVideo_ID: '6' } });
  }, []);

  const clips = useSelector(state => state.GetAllTrimClipsReducer.clips);

  return (
    <React.Fragment>
      <div className="container mt-3">
        <div className="row row-cols-1 row-cols-md-4">
          {clips &&
            clips.map(clip => (
              <div className="col md-2 pl-0">
                <Card className="p-2  card-border">
                  <VideoClips
                    name={clip.trimVideo_ID}
                    type="R4"
                    context="Context of Use"
                    duration="55:34"
                    img={ReviewClips}
                    radioIcon={true}
                    tag={[{ name: 'Content Discoverability' }]}
                    closeIcon={true}
                  />
                </Card>
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}
