import React, { useState, useEffect } from 'react';
import ProjectWrapper from './ProjectWrapper';
import { Heading, HelperText, Card, Text, ListMenu, SingleValue, Button, Icon } from '../../common';
import { Dropdown, InputText } from '../../components/form';
import { VideoClipWrapper, VideoClips } from '../../containers';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import User1 from '../../images/user1.png';
import User2 from '../../images/user2.png';

const ClipContainer = styled.div`
	max-height: 480px;
	transition: 3s;
	.scrollable {
		overflow-y: auto;
		max-height: 470px;
		scrollbar-width: thin;
	}
`;

const UserOptions = [
	{ value: 'G1 Indending Buyer Affluent Class', label: 'Indending Buyer Affluent Class', group: 'G1' },
	{ value: 'G2 Indending Buyer Consumer Class', label: 'Indending Buyer Consumer Class', group: 'G2' },
	{ value: 'G3 Current User Consumer Class', label: 'Current User Consumer Class', group: 'G3' }
];

const updatedOptions = UserOptions.map((item) => {
	item.content = item.label;
	item.label = (
		<div className="d-flex ">
			<Text type="4" fontSize="14px" className="d-flex flex-column">
				{item.group}
				<Text span fontSize="10px" fontWeight="400">
					{item.label}
				</Text>
			</Text>
		</div>
	);
	return item;
});

const ButtonBase = styled(Button)`
    width: 67px;
    height: 33px;
    font-size: 12px;
    padding:0;
`;

export default function ViewClipScreen() {
	const [ projectTitle, setProjectTitle ] = useState('My Movie Binge');
	const [ viewClips, setViewClips ] = useState(1);
	const [ deleteClips, setDelete ] = useState(false);
	const [ fetchingData, setfetchingData ] = useState(false);
	const [ userList, setUserlist ] = useState(UserOptions);

	useEffect(() => {
		setfetchingData(true);
		setTimeout(() => {
			setUserlist(updatedOptions);
			setfetchingData(false);
		}, 1000);
	}, []);

	const handleClickStage = (stage) => {
		setViewClips(stage);
	};

	return (
		<ProjectWrapper projectTitle={projectTitle} step={4}>
			<Heading type="2" className="mt-4" fontWeight="600" color="var(--seaBlue)">
				In this section...
			</Heading>
			<HelperText fontSize="0.99rem">Review and edit Video Interview Clips</HelperText>

			<Heading
				type="2"
				fontSize="18px"
				fontWeight="800"
				breakHeading={false}
				color="var(--headingColor)"
				className="mt-5"
			>
				Video Clip{' '}
				<Text color="var(--headingColor)" span>
					Library
				</Text>{' '}
			</Heading>
			<Text type="3" className="mb-4">
				Full video interviews and clips in one place
			</Text>

			<section className="filter-group-section">
				<Card>
					<div className="row d-flex align-items-center">
						<div className="col-md-4 p-0 d-flex flex-column">
							<Text fontSize="12px" fontWeight="400" color="var(--grey20)">
								User Groups
							</Text>
							<Dropdown
								placeholder="Select Groups"
								options={userList}
								fetchingData={fetchingData}
								defaultValue={userList[0]}
								SingleValue={SingleValue}
								isSearchable={true}
							/>
						</div>
					</div>
				</Card>
			</section>
			<section className="view-clips mt-3">
				<VideoClipWrapper stage={viewClips} handleClick={handleClickStage} justify="flex-start">
					{/* {viewClips == 1 || viewClips ==2 && ( */}
					<Card className="mt-4">
						<div className="row">
							<div className="col-md-4">
								<InputText
									type="text"
									id="note"
									placeholder="Search For Respondent By Tag or Name"
									showCharacters={false}
									showIcon={true}
									iconName="search"
									iconStyle="p-1 icon-input-style-left cursor"
									color="var(--grey20)"
									placeholderSize="12px"
									positionIcon="left"
									fontWeight="500"
								/>
							</div>
							<div className="col-md-8 d-flex justify-content-end">
								{deleteClips && (
									<Button simple>
										<Icon>delete_outline</Icon>
									</Button>
								)}
							</div>
						</div>
						<ClipContainer>
							<div className="scrollable">
								<div className="container">
									<div className="row row-cols-1 row-cols-md-4 mt-3">
										<div className="col md-2  mb-2 p-1">
											<Card className="p-2  card-border">
												<VideoClips
													name="Sahil R."
													type="R4"
													context="Context of Use"
													duration="55:34"
													img={User1}
													radioIcon={true}
													tag={[ { name: 'Content Discoverability' } ]}
													closeIcon={false}
												/>
											</Card>
										</div>
										<div className="col md-2 mb-2 p-1">
											<Card className="p-2  card-border">
												<VideoClips
													name="Sahil R."
													type="R4"
													context="Context of Use"
													duration="55:34"
													img={User2}
													radioIcon={true}
													tag={[ { name: 'Content Discoverability' } ]}
													closeIcon={false}
												/>
											</Card>
										</div>
										<div className="col md-2 mb-2 p-1">
											<Card className="p-2  card-border">
												<VideoClips
													name="Sahil R."
													type="R4"
													context="Context of Use"
													duration="55:34"
													img={User1}
													radioIcon={true}
													tag={[ { name: 'Content Discoverability' } ]}
													closeIcon={false}
												/>
											</Card>
										</div>
										<div className="col md-2 mb-2 p-1">
											<Card className="p-2  card-border">
												<VideoClips
													name="Sahil R."
													type="R4"
													context="Context of Use"
													duration="55:34"
													img={User2}
													radioIcon={true}
													tag={[ { name: 'Content Discoverability' } ]}
													closeIcon={false}
												/>
											</Card>
										</div>
										<div className="col md-2 mb-2 p-1">
											<Card className="p-2  card-border">
												<VideoClips
													name="Sahil R."
													type="R4"
													context="Context of Use"
													duration="55:34"
													img={User1}
													radioIcon={true}
													tag={[ { name: 'Content Discoverability' } ]}
													closeIcon={false}
												/>
											</Card>
										</div>
										<div className="col md-2 mb-2 p-1">
											<Card className="p-2  card-border">
												<VideoClips
													name="Sahil R."
													type="R4"
													context="Context of Use"
													duration="55:34"
													img={User1}
													radioIcon={true}
													tag={[ { name: 'Content Discoverability' } ]}
													closeIcon={false}
												/>
											</Card>
										</div>
									</div>
								</div>
							</div>
						</ClipContainer>
					</Card>
					{/* )} */}
					{/* {viewClips == 2 && <Card className="mt-4">View Raw clips</Card>} */}
				</VideoClipWrapper>

				<div className="d-flex justify-content-center w-100">
					<Link to="/project/1/field">
						<Button className="mt-3 mr-4" fontSize="13px" size="mid">
							Back
						</Button>
					</Link>
				</div>
			</section>
		</ProjectWrapper>
	);
}
