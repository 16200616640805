import React, {useCallback} from 'react'
import styled from '@emotion/styled';
import {useDropzone} from 'react-dropzone'
import { Icon, Text } from '../../common';
import Upload from '../../assets/images/upload.svg';
import ErrorText from './ErrorText';

const Drop = styled.div`
    padding: 1rem;
    border: ${props => props.error ? '1px solid var(--errorColor)' : '1px solid var(--darkBorderColor)'};
    border-radius: 7px;
    min-height: 135px;
    position: relative;

    &.active {
        border: 1px solid var(--primaryColor);
    }

    .material-icons {
        font-size: 3rem;
        color: var(--primaryColor);
    }

    .divider {
        margin-right: 10px;
        margin-left: 10px;
        height: 30px;
        width: 1px;
        background: var(--darkBorderColor);
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: rgba(255,255,255,0.9);
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primaryColor);
    }
`

export default function MyDropzone({ updateFiles, error }) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files

    const images = [];

    for (let i = 0; i < acceptedFiles.length; i++) {
        images.push({file: acceptedFiles[i], img: URL.createObjectURL(acceptedFiles[i])})
    }

    updateFiles(images);

    // acceptedFiles.forEach((file) => {
    //     const reader = new FileReader()
  
    //     reader.onabort = () => console.log('file reading was aborted')
    //     reader.onerror = () => console.log('file reading has failed')
    //     reader.onload = () => {
    //     // Do whatever you want with the file contents
    //       const binaryStr = reader.result;
    //       updateFiles(binaryStr);
    //     //   console.log(1);
    //     }
    //     // reader.readAsArrayBuffer(file);
    //     reader.readAsDataURL(file);
    // })
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <>
    <Drop {...getRootProps()} error={error} className={`${isDragActive ? 'active': ''} ${error ? 'is-invalid': ''}`}>
        <input {...getInputProps()} />
        {isDragActive && <Text bold className="overlay">Drop the files here!</Text>}
        <div className="d-flex justify-content-center flex-column align-items-center">
        
            <img src={Upload} alt="Upload Images" className="mb-2"/>
            <Text type="5">Drop Files here or click to upload</Text>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="d-flex justify-content-center flex-column align-items-center">
                <Text type="6"fontSize="0.625rem">Dimensions</Text>
                <Text type="6"fontSize="0.625rem">1500 x 1500 px</Text>
            </div>
            <div className="divider"></div>
            <div className="d-flex justify-content-center flex-column align-items-center">
                <Text type="6"fontSize="0.625rem">Max File Size</Text>
                <Text type="6"fontSize="0.625rem">5mb per image</Text>
            </div>
        </div>
    </Drop>
    {error && <ErrorText>{error}</ErrorText>}
    </>
  )
}