import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetAllTrimClipsMethods from '../../utils/api/field/GetAllTrimClipsData';

function* GetAllTrimClips(payload) {
  const promise = GetAllTrimClipsMethods.GetAllTrimClips(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetAllTrimClipsAction.ALLTRIMCLIPS_RECEIVED, data: res.Response });
}

export { GetAllTrimClips };
