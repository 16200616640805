import React from 'react';
import { merge } from 'lodash';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { withRouter } from 'react-router';
import { VideoRecording } from '../../actions/project/index';
import { CountNoParticipant } from '../../actions/project/index';
import { StopVideoRecording } from '../../actions/project/index';
import { removeVideoCall } from '../../actions/project/index';
import { connect } from 'react-redux';
import mixPanel from '../../utils/helpers/mixPanel';
import './canvas.css';
import '../../assets/fonts/css/icons.css';
import { VideoRecordingUserList } from '../../actions/project/index';
import { getUIDDetails } from '../../actions/project/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { VideoRecordingUpdate } from '../../actions/project/index';
import { UpdateLayoutVideoRecording } from '../../actions/project/index';
import { RtcTokenBuilder } from '../../actions/project/index';
import maverickPortalhttp from '../../utils/webService/webService';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';
import videoOffNew from '../../assets/images/videoOffNew.svg';
import videoOnNew from '../../assets/images/videoOnNew.svg';
import micOffNew from '../../assets/images/micOffNew.svg';
import micOnNew from '../../assets/images/micOnNew.svg';
import PulsatingDot from '../../common/PulsatingDot';
// import spearkerOffNew from '../../assets/images/spearkerOffNew.svg';
// import spearkerOnNew from '../../assets/images/spearkerOnNew.svg';
import screenShareNew from '../../assets/images/screenShareNew.svg';
import endCallNew from '../../assets/images/endCallNew.svg';
import { createChannel, createClient, RtmMessage } from 'agora-rtm-react';
import { Dropdown } from '../../components/form';
import DownIndicator from '../../common/DownIndicator';
import captionIcon from '../../assets/images/captionIcon.svg';
import captionOffIcon from '../../assets/images/Caption-closed.svg';
import translationIcon from '../../assets/images/translationIcon.svg';
import DropdownIcon from '../../components/form/DropdownIcon';
import { Divider } from '../../common';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { sendTranscriptData } from '../../actions/transcript/SendTranscriptData';
import {
  saveRtmTranscriptData,
  storeTranscriptLogs,
  storeTranscriptMessageLogs,
  updateSid,
  getParticipant,
  SendParticpantsListOnEnd
} from '../../actions/project/index';
import { requestUpdateVideoLayout } from '../../actions/field/StartInterviewAction';

import moment from 'moment';
import GenerateSumaryModal from '../../common/GenerateSummaryModal';
import '../../common/ReactTooltip.css';
import avatar from '../../images/researcher.png';
import participants from '../../images/participants.png';
import participantsoff from '../../images/participantsoff.png';
import styled from '@emotion/styled';
import { GiNetworkBars } from 'react-icons/gi';
import { ToastContainer, toast } from 'react-toastify';
import './Toast.css';
import 'react-toastify/dist/ReactToastify.css';

import yellowbar from '../../images/yellowbar.png';
import redbar from '../../images/redbar.png';
import ParticipantsPopup from '../AgoraVideoCall/ParticipantsPopup';
import profile_pic from '../../assets/images/profile_pic.png';
import { color } from 'd3';
import EndInterviewPopup from './EndInterviewPopup';
import { Button } from '../../common';



const tile_canvas = {
  '1': ['span 12/span 24'],
  '2': ['span 12/span 12/13/25', 'span 12/span 12/13/13'],
  '3': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/19'],
  '4': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/13'],
  '5': [
    'span 3/span 4/13/9',
    'span 3/span 4/13/13',
    'span 3/span 4/13/17',
    'span 3/span 4/13/21',
    'span 9/span 16/10/21'
  ],
  '6': [
    'span 3/span 4/13/7',
    'span 3/span 4/13/11',
    'span 3/span 4/13/15',
    'span 3/span 4/13/19',
    'span 3/span 4/13/23',
    'span 9/span 16/10/21'
  ],
  '7': [
    'span 3/span 4/13/5',
    'span 3/span 4/13/9',
    'span 3/span 4/13/13',
    'span 3/span 4/13/17',
    'span 3/span 4/13/21',
    'span 3/span 4/13/25',
    'span 9/span 16/10/21'
  ]
};

const CustomButton = styled(Button)`
  padding: 2px 1rem;
  color: var(--white);
  border: 1px solid var(--deepBlue);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
  width: 65px;
  height: 32px;
  font-size: 16px;
  margin-right: 10px;
`;

const CustomButtont = styled(Button)`
  padding: 3px 1rem;
  color: var(--white);
 
  cursor: pointer;
  fontSize="15px"
                fontWeight="600"
                color="black"
                size="large"
                buttonColor= var(--sapGreen);
`;

const Badge = styled.div`
  position: absolute;
  top: 2px;
  left: 55%;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  border-radius: 7px;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--greyBlue);
  color: var(--white);
  font-size: 8px;
  font-weight: 500;
  z-index: 1;
`;

var selectedLanguage;

// list of spoken languages
var languages = [
  { label: 'Select Spoken language', value: 'NA' },
  {
    label: selectedLanguage?.value == 'en-IN' ? 'English ' + '                           ✔' : 'English',
    value: 'en-IN'
  },
  {
    label: selectedLanguage?.value == 'hi-IN' ? 'Hindi/हिंदी' + '                        ✔' : 'Hindi/हिंदी',
    value: 'hi-IN'
  },
  // {label: 'Telugu/తెలుగు', value: 'te-IN'},
  { label: selectedLanguage?.value == 'ta-IN' ? 'Tamil/தமிழ்' + '                  ✔' : 'Tamil/தமிழ்', value: 'ta-IN' },
  // {label: 'Marathi/मराठी', value: 'mr-IN'},
  // {label: 'Malayalam/മലയാളം', value: 'ml-IN'},
  { label: selectedLanguage?.value == 'kn-IN' ? 'Kannada/ಕನ್ನಡ' + '            ✔' : 'Kannada/ಕನ್ನಡ', value: 'kn-IN' }
  // {label: 'Gujarati/ગુજરાતી', value: 'gu-IN'},
];

const setLanguage = lang => {
  selectedLanguage = lang;
  // console.log(selectedLanguage?.value);
  languages = [
    { label: 'Select Spoken language', value: 'NA' },
    {
      label: selectedLanguage?.value == 'en-IN' ? 'English ' + '                           ✔' : 'English',
      value: 'en-IN'
    },
    {
      label: selectedLanguage?.value == 'hi-IN' ? 'Hindi/हिंदी' + '                        ✔' : 'Hindi/हिंदी',
      value: 'hi-IN'
    },
    // {label: 'Telugu/తెలుగు', value: 'te-IN'},
    {
      label: selectedLanguage?.value == 'ta-IN' ? 'Tamil/தமிழ்' + '                  ✔' : 'Tamil/தமிழ்',
      value: 'ta-IN'
    },
    // {label: 'Marathi/मराठी', value: 'mr-IN'},
    // {label: 'Malayalam/മലയാളം', value: 'ml-IN'},
    { label: selectedLanguage?.value == 'kn-IN' ? 'Kannada/ಕನ್ನಡ' + '            ✔' : 'Kannada/ಕನ್ನಡ', value: 'kn-IN' }
    // {label: 'Gujarati/ગુજરાતી', value: 'gu-IN'},
  ];
};

const SPEECH_KEY = '896ae8a6c1c84cff9956584ce61c7646';
const SPEECH_REGION = 'eastasia';
const messageBoxColor = ['#EB4D4D', '#FAD702', '#798E8F'];

const DownIndicatorCustom = ({ ...props }) => {
  return <DownIndicator color="var(--sapGreen)" iconStyle={{ fontSize: 16 }} {...props} />;
};

var fieldData = '';
var resumeData = '';
const getFieldData = data => {
  fieldData = data;
};

const getResumeData = data => {
  // console.log(data);
  resumeData = data;
};

/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode
 */

function mapStateToProps(state) {
  const userId = state.LoginReducer.userId;
  const email = state.LoginReducer.emailAddress;

  window.$emailId = email; //global variable
  window.$user = userId; //global variable

  const screenResolution =
    state.ProjectReducer.getResolution != null && state.ProjectReducer.getResolution[1] != undefined
      ? state.ProjectReducer.getResolution[1]
      : '480p_1';
  const videoResolution =
    state.ProjectReducer.getResolution != null && state.ProjectReducer.getResolution[0] != undefined
      ? state.ProjectReducer.getResolution[0]
      : '640 X 480';
  window.$shareScreenResolution = screenResolution; //global variable
  window.$videoScreenResolution = videoResolution; //global variable
  const getUIDData = state.ProjectReducer.getUIDData;
  window.$getUIDData = getUIDData;
  const rtcTokenBuilder = state.ProjectReducer.rtcTokenBuilder;
  window.$rtcTokenBuilder = rtcTokenBuilder;

  const fieldData = state.GetFieldOverviewReducer.fieldData;
  const resumeData = state.ProjectReducer.resumeData;

  const Guest = state.ProjectReducer.getParticipant;
  window.$Guest = Guest;

 

  getFieldData(fieldData);
  getResumeData(resumeData);
}

class AgoraCanvas extends React.Component {
  translationRecognizer = sdk.TranslationRecognizer;
  USER_ID = Math.floor(Math.random() * 1000000001).toString();
  resetCount = 0;

  constructor(props) {
    super(props);
    this.client = {};
    this.localStream = {};
    this.shareClient = {};
    this.shareStream = {};
    this.state = {
      displayMode: 'pip',
      streamList: [],
      readyState: true,
      stateSharing: false,
      userName: '',
      userid: window.$user,
      selectedLanguage: this.props?.selectedLanguage,

      isSpeaker: this.props.micinit,
      transcriptData: [],
      TranslationLanguage: false,
      startTranslation: false,
      currentLanguage: 'Select',
      myLanguage: '',
      senderId: '',
      transcriptionResults: '',
      languageData: [],
      displayData: [],
      participantsName: [],
      translationStop: true,
      rtmStop: false,
      errorMsgTime: 0,
      networkQuality: 100,
      showModal: false,
      isMicMuted: !this.props.micinit,
      isreconnecting: false,
      showPopup: false,
      // active: !this.props.micinit
      active: false,
      previousNetworkQuality: null,
      networkIconPath: {
        redbar: redbar,
        yellowbar: yellowbar
      },
      showParticipantsBefore: false
    };

    this.uid = '';
    this.localTracks = {
      videoTrack: null,
      audioTrack: null,
      screenVideoTrack: null,
      screenAudioTrack: null
    };

    this.timerId = null;
    this.remoteUsers = {};
    this.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
  
    this.localTrackState = {
      videoTrackMuted: !this.props.videoinit,
      audioTrackMuted: !this.props.micinit
    };
    this.uidarray = [];
    this.screenshareId;
    this.transcriptionTimeoutId = null;

    //create rtc client
    this.rtmClient = null;
    this.rtmChannel = null;

    this.handleTogglePopup = this.handleTogglePopup.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.handleToggleParticipants = this.handleToggleParticipants.bind(this);
    this.handleToggleParticipantsImage = this.handleToggleParticipantsImage.bind(this);
  }

  handleToggleParticipantsImage = () => {
    this.setState(prevState => ({
      showParticipantsBefore: !prevState.showParticipantsBefore
    }));
  };
  handleToggleParticipants = () => {
    this.setState({ showAllParticipants: !this.state.showAllParticipants });
  };

  handleTogglePopup = async () => {
    const { showPopup } = this.state;
    if (!showPopup) {
      await this.fetchParticipantsData();
      this.setState({ showPopup: true });
    }
  };

  handleClosePopup() {
    this.setState({ showPopup: false, showParticipantsBefore: false });
  }

  fetchParticipantsData = async () => {
    try {
      const data = {
        channelName: this.props.channel,
        user_ID: this.state.userid
      };
      this.props.getParticipant(data);
      const Guest = window.$Guest;

      this.setState({
        ParticipantsData: Guest?.fetchResearcherName
      });
    } catch (error) {
      console.error('Failed to fetch participants data:', error);
    }
  };

  async componentWillMount(props) {
    //  const user = window.$user;

  
    mixPanel.init();
    mixPanel.identify(`${this.props.channel}-${this.state.userid}`);
    AgoraRTC.enableLogUpload(true);
    // AgoraRTC.setLogLevel(AgoraRTC.DEBUG);

    // reset timer for rtm
    this.resetTimer();

    this.setState({
      currentLanguage: this.props.selectedLanguage.label,
      myLanguage: this.props.selectedLanguage.value,
      networkQuality: this.props.networkQuality
    });

    if (this.state.isMicMuted) {
      this.setState({ active: true });
    }

    const user = localStorage.getItem('user');
    let $ = this.props;
    let appid = $.appId;
    let channel = $.channel;
    let certificate = '566f52717c70402b8e2232f6d8be24b7';


    this.client.on('user-published', this.handleUserPublished);
    this.client.on('user-unpublished', this.handleUserUnpublished);
    this.client.on('exception', e => console.log('exception_listener123---->', e));
    // this.client.on('error', this.handleError);

    // get user name
    const userData = localStorage.getItem('userData');
    if (userData) {
      let userName = JSON.parse(userData).user_name;
      this.setState({
        userName: userName
      });
    }

    // start RTM client and translation
    setTimeout(() => {
      this.initRtm(this.props.channel);
      this.startTranslation('Initialized Transcription');
      this.sendTurnOn();
      this.startSendMessage();
    }, 1000);

    try {
     
      if (this.props.respondantInfo.isInterviewStart == 0) {
        this.uid = Math.floor(100000 + Math.random() * 900000);
        this.uidarray['other'] = this.uid;
      } else if (this.props.respondantInfo.isRead == 1) {
        this.uid = '1500';
        this.uidarray['RespondentOnWeb'] = this.uid;
      } else {
        this.uid = '240000';
        this.uidarray['PrimaryResearcher'] = this.uid;
      }
      localStorage.setItem('UID', this.uid);

      [this.uid, this.localTracks.audioTrack, this.localTracks.videoTrack] = await Promise.all([
        // Join the channel.
        this.client.join(appid, channel, null, Number(this.uid)),
        // Create tracks to the local microphone and camera.
        AgoraRTC.createMicrophoneAudioTrack(),
        AgoraRTC.createCameraVideoTrack({
          encoderConfig: {
            width: 640,
            // Specify a value range and an ideal value
            height: 480,
            frameRate: 15,
            bitrateMin: 600,
            bitrateMax: 1000
          }
        })
      ])
        .then(values => {
          console.log('values camera', values); // [3, 1337, "foo"]
          return values;
        })
        .catch(e => {
          console.log('values camera error', e); // [3, 1337, "foo"]
          this.sendToMixPanel('RTC | Join Channel failed', 419, `${e}`);
          toast.error('Oops! Something went wrong. Please try joining again.');
        });

     
      if (this.uid) {
        this.state.uid = this.uid;
        console.log('User ' + this.uid + ' join channel successfully');
        this.sendToMixPanel('RTC | Join Channel', 418, 'RTC channel joined');

        //   // this.handleCamera();

        const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
        // Customer secret
        const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
        // Concatenate customer key and customer secret and use base64 to encode the concatenated string
        const plainCredential = customerKey + ':' + customerSecret;
        // Encode with base64
        const encodedCredential = Buffer.from(plainCredential).toString('base64');
        const authorizationField = 'Basic ' + encodedCredential;
        const newuid = String(this.uid);
        const channelName = this.props.channel;
     
        const data = {
          cname: this.props.channel,
          newuid: String(this.uid),
          uid: '3613483512',
          clientRequest: {
            resourceExpiredHour: 24,
            scene: 0
          }
        };

        const InterviewSchedule_ID = localStorage.getItem('InterviewSchedule_ID');

        const payload = {
          user_ID: window.$user,
          emailID: window.$emailId,
          uid: newuid,
          interviewSchedule_ID: InterviewSchedule_ID
        };
    

        const { CountNoParticipant } = this.props;
        CountNoParticipant(payload);

        const { VideoRecording } = this.props;
        const { updateSid } = this.props;
      
        if (this.uid == '240000') {
          // VideoRecording(data, authorizationField, newuid);
        

          var today = new Date(),
            time =
              today.getFullYear().toString() +
              '-' +
              (today.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              today
                .getDate()
                .toString()
                .padStart(2, '0') +
              ' ' +
              today
                .getHours()
                .toString()
                .padStart(2, '0') +
              ':' +
              today
                .getMinutes()
                .toString()
                .padStart(2, '0') +
              ':' +
              today
                .getSeconds()
                .toString()
                .padStart(2, '0');

          const data2 = {
            user_ID: this.state.userid,
            startTime: time,
            videoTask_ID: this.props.respondantInfo?.videoTask_ID,
            projectRespondent_ID: this.props.respondantInfo?.projectRespondent_ID,
            projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID
          };

          
        }

        const payload1 = {
          user_ID: window.$user,
          interviewSchedule_ID: InterviewSchedule_ID
        };

        let _this = this;
        const { getUIDDetails } = this.props;
        this.client.on('connection-state-change', async (state, prevState, reason) => {
          this.sendToMixPanel(
            'RTC | Connection State Changed',
            417,
            `Connection state changed: ${state}, reason: ${reason}`
          );

          if (state === 'DISCONNECTING') {
            this.sendToMixPanel('RTC | Left Channel', 420, `User left, reason: ${reason}`);
          }
        });

        // check network quality
        this.client.on('network-quality', quality => {
          this.setState({ networkQuality: quality });
          this.networkQuality = quality;
          if (quality.uplinkNetworkQuality == 1) {
            console.log('network-quality: Excellent');
          } else if (quality.uplinkNetworkQuality == 2) {
            console.log('network-quality: Good');
          } else if (this.networkQuality != quality && quality.uplinkNetworkQuality >= 3) {
            console.log('network-quality: Poor');
            this.sendToMixPanel('Network Change', 417, 'Network Quality changed to poor');
          }
        });
        // var myTimer = setInterval(function() {
        //   getUIDDetails(payload1);

        //   const getUIDData = window.$getUIDData;
        //   console.log('will', getUIDData);

        //   getUIDData != undefined && getUIDData?.length > 0
        //     ? getUIDData?.map(item =>
        //         item.roleName == 'Respondent' ? ((window.$respondantuid = item.uid), clearInterval(myTimer)) : ''
        //       )
        //     : ' ';

        //   const { VideoRecordingUpdate } = _this.props;

        //   setTimeout(function() {
        //     if (window.$respondantuid != null && window.$respondantuid != undefined) {
        //       const streamId = window.$respondantuid;
        //       const data = {
        //         cname: channelName,
        //         uid: '3613483512',
        //         clientRequest: {
        //           streamSubscribe: {
        //             audioUidList: {
        //               subscribeAudioUids: ['#allstream#']
        //             },
        //             videoUidList: {
        //               subscribeVideoUids: [streamId]
        //             }
        //           }
        //         }
        //       };

        //       VideoRecordingUpdate(data, authorizationField);
        //       clearInterval(myTimer);
        //     } else {
        //       const data = {
        //         cname: channelName,
        //         uid: '3613483512',
        //         clientRequest: {
        //           streamSubscribe: {
        //             audioUidList: {
        //               subscribeAudioUids: ['#allstream#']
        //             },
        //             videoUidList: {
        //               subscribeVideoUids: [newuid]
        //             }
        //           }
        //         }
        //       };

        //       VideoRecordingUpdate(data, authorizationField);
        //     }
        //   }, 10000);
        // }, 5000);

        //Update video recording start
        // const resource = {
        //   cname: this.props.channel,
        //   uid: '3613483512',
        //   clientRequest: {
        //     mixedVideoLayout: 3,
        //     // "backgroundColor": "#FF0000",
        //     layoutConfig: [
        //       {
        //         uid: `${this.uid}` == '1500' ? '1500' : '1000',
        //         x_axis: 0.0,
        //         y_axis: 0.0,
        //         width: 1.0,
        //         height: 1.0,
        //         alpha: 1.0,
        //         render_mode: 1
        //       }
        //     ]
        //   }
        // };
        // const { UpdateLayoutVideoRecording } = this.props;
        // UpdateLayoutVideoRecording(resource, authorizationField);
        //END
      }
      let canvas = document.querySelector('#ag-canvas');
      let dom = document.querySelector('#ag-item-' + this.uid);
      // console.log("dom", dom);
      if (!dom) {
    
        dom = document.createElement('section1');
        dom.setAttribute('id', 'ag-item-' + this.uid);
        dom.setAttribute('class', 'ag-item');
        // dom.onClick=hello();

        if (localStorage.getItem('user') == 'respondent') {
          // dom.setAttribute(
          //   'style',
          //   'grid-area: span 3/span 4/4/5;z-index:3;width:calc(100% - 12px);height:calc(100% - 20px);margin-top: 45px;'
          // );
          dom.setAttribute('class', `researcherBox 1`);
        }
        canvas.appendChild(dom);
        //this.localTracks.videoTrack.play("ag-item-" + uid , {fit: "contain"});
      }
      if (this.uid == '240000') {
        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null) 
        this.uidarray['Respondant'];
        let dom3 = document.querySelector('#ag-item-' + this.uidarray['Respondant']);

        // added by shweta - if respondent joins call first then researcher
        if (localStorage.getItem('user') == 'researcher' && dom3 != null) {
          dom2.setAttribute('class', `researcherBox 2`);
        } else {
          dom2.setAttribute(
            'style',
            `grid-area: span 3/span 4/4/2;
                    z-index:1;
                width:calc(100% - 12px);height:calc(100% - 20px);margin-top: 45px;`
          );
        }
      } else if (this.uid == '1500') {
        let dom1 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
        if (dom1 != null) console.log('33');
        // dom1.setAttribute(
        //   'style',
        //   `grid-area: span 3/span 4/4/5;
        //           z-index:1
        //           width:calc(100% - 20px);height:calc(100% - 20px)`
        // );
        //added by shweta
        dom1.setAttribute('class', `researcherBox 3`);
      } else {
        let dom1 = document.querySelector('#ag-item-' + this.uidarray['other']);
        if (dom1 != null) console.log('2');
        dom1.setAttribute(
          'style',
          `grid-area: span 3/span 4/4/2;
                  z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
        );
      }
      let videoPosition = 'absolute';
      if (this.uid == '1500') {
        videoPosition = 'relative';
      }
      this.localTracks.videoTrack.play('ag-item-' + this.uid, { fit: 'cover', position: videoPosition });

      if (this.uid == '240000') {
        this.localTracks.videoTrack.setEnabled(this.props.videoinit);
        this.localTracks.audioTrack.setEnabled(this.props.micinit);
        await this.client.publish([this.localTracks.videoTrack, this.localTracks.audioTrack]);
      } else {
    
        this.localTracks.audioTrack.setEnabled(this.props.micinit);
        this.localTracks.videoTrack.setEnabled(false);
        // await this.client.publish([this.localTracks.videoTrack, this.localTracks.audioTrack]);
        // changed
        await this.client.publish([this.localTracks.audioTrack]);
      }
    
      if (document.querySelector('#ag-item-' + this.uidarray['other'])) {
      
        let dom = document.querySelector('#ag-item-' + this.uidarray['other']);
        dom.setAttribute('style', `display: none`);
      }
    } catch (e) {
     
      this.sendToMixPanel('RTC | Publish Failed', 422, `RTC channel publish failed - ${e}`);
      toast.error('Oops! Something went wrong. Please try joining again.');
    }
    //});
  }

  startTranslation = async reason => {
    if (this.state.myLanguage != '') {
      //   const transcriptObj = {
      //     text: 'Please turn on CC..!!',
      //     name: this.state.Username,
      //     language: this.state.myLanguage.split('-')[0],
      //   };
      //  this.sendMsg(JSON.stringify(transcriptObj));
      this.getTranslationRecognizer(this.state.myLanguage, reason);
    } else {
      this.getTranslationRecognizer('hi-IN', reason);
      this.setState({ currentLanguage: 'Hindi', myLanguage: 'hi-IN' });
    }
  };

  startSendMessage = async () => {
    const currentUTCTimestampString = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    const parsedMsg = {
      end: '' + currentUTCTimestampString,
      duration: '0',
      text: '',
      recognized: true,
      language: 'en',
      name: this.state.userName
    };
    const { saveRtmTranscriptData } = this.props;
    const InterviewSchedule_ID = localStorage.getItem('InterviewSchedule_ID');
    const transcriptPayload = {
      user_ID: window.$user,
      emailID: window.$emailId,
      uid: String(this.uid),
      InterviewSchedule_ID: InterviewSchedule_ID,
      transcript_data: parsedMsg,
      channel_name: this.props.channel
    };
    await saveRtmTranscriptData(transcriptPayload);
  };

  // // send rtm message
  // sendMsg = async text => {
  //   if (this.rtmClient?.connectionState == 'CONNECTED') {
  //     let message = this.rtmClient.createMessage({ text, messageType: 'TEXT' });
  //     await this.rtmChannel
  //       .sendMessage(message)
  //       .then(async () => {
  //         console.log('RTC message send successfully', text);
  //         const parsedMsg = JSON.parse(text);
  //         let hasKey = parsedMsg.hasOwnProperty('recognized');
  //         if (hasKey && parsedMsg.recognized) {
  //           const { saveRtmTranscriptData } = this.props;
  //           const InterviewSchedule_ID = localStorage.getItem('InterviewSchedule_ID');
  //           const transcriptPayload = {
  //             user_ID: window.$user,
  //             emailID: window.$emailId,
  //             uid: String(this.uid),
  //             InterviewSchedule_ID: InterviewSchedule_ID,
  //             transcript_data: parsedMsg,
  //             channel_name: this.props.channel
  //           };
  //           console.log('RTC message failed', e);
  //           const logData = {
  //             respondentId: this.state.userid,
  //             respondentName: this.state.userName,
  //             // researcherId: responseObj.researcherId,
  //             // researcherName: responseObj.researcherName,
  //             channelId: this.props.channel,
  //             reason: "Working",
  //             createdAt: new Date().toISOString(),
  //           };
  //           console.log("Testing log in positive" , logData)
  //           await saveRtmTranscriptData(transcriptPayload);
  //         }
  //       })
  //       .catch(e => {
  //         console.log('RTC message failed', e);
  //         const logData = {
  //           respondentId: this.state.userid,
  //           respondentName: this.state.userName,
  //           // researcherId: responseObj.researcherId,
  //           // researcherName: responseObj.researcherName,
  //           channelId: this.props.channel,
  //           reason: "Recognition session canceled and message is not send",
  //           createdAt: new Date().toISOString(),
  //         };
  //         console.log("Testing log if break" , logData)
  //         const msg = JSON.parse(text);
  //         console.log("In break " ,msg)
  //         if (msg.text == 'Please turn on CC..!!') {
  //           console.log("in if " ,msg)
  //           this.sendMsg(text);
  //         }
  //       });
  //   }
  // };

  sendMsg = async text => {
    try {
      //without if condition sendMSG not working
      if (this.rtmClient?.connectionState === 'CONNECTED') {
        let message = this.rtmClient.createMessage({ text, messageType: 'TEXT' });
       
        await this.rtmChannel.sendMessage(message);

        // console.log('RTC message send successfully', text);
        if (this.resetCount > 8) {
          this.resetCount = 0;
        }
        const parsedMsg = JSON.parse(text);
        let hasKey = parsedMsg.hasOwnProperty('recognized');
        // send message only in case of primary user
        if (hasKey && parsedMsg.recognized && this.uid == '240000') {
          const { saveRtmTranscriptData } = this.props;
          const InterviewSchedule_ID = localStorage.getItem('InterviewSchedule_ID');

          const transcriptPayload = {
            user_ID: window.$user,
            emailID: window.$emailId,
            uid: String(this.uid),
            InterviewSchedule_ID: InterviewSchedule_ID,
            transcript_data: parsedMsg,
            channel_name: this.props.channel
          };
         

          // const logData = {
          //   respondentId: this.props.respondantInfo.Respondent_ID,
          //   respondentName: this.props.respondantInfo.RespondentName,
          //   researcherId: this.state.userid,
          //   researcherName: this.state.userName,
          //   channelId: this.props.channel,
          //   reason: "Message are sent!",
          // };
          // console.log("In sendMsg  messsage send to respondent" , logData)
          // this.sendMessageDataAPi(logData);
          await saveRtmTranscriptData(transcriptPayload);
         
        }
      } else {
        const currentTimestamp = new Date().getTime();
        if (this.state.errorMsgTime > currentTimestamp + 60000) {
          this.setState({ errorMsgTime: currentTimestamp });
          this.sendToMixPanel(
            'RTM | Send Message Error',
            201,
            'SendMsg - Message are not send with error ' + this.rtmClient?.connectionState
          );
        }
      }
    } catch (e) {
    
      const logData = {
        respondentId: this.props.respondantInfo.Respondent_ID,
        respondentName: this.props.respondantInfo.RespondentName,
        researcherId: this.state.userid,
        researcherName: this.state.userName,
        channelId: this.props.channel,
        reason: 'Message are not send with error ' + e
      };
    
      // this.sendMessageDataAPi(logData);
      const currentTimestamp = new Date().getTime();
      if (this.state.errorMsgTime > currentTimestamp + 60000) {
        this.setState({ errorMsgTime: currentTimestamp });
        this.sendToMixPanel('RTM | Send Message Error', 201, 'SendMsg - Message are not send with error ' + e);
      }
      const msg = JSON.parse(text);
      if (msg.text === 'Please turn on CC..!!') {
   
        await this.sendMsg(text); // Retry the message
      }
    }
  };

  generateTranscriptData = result => {
    const transcriptObj = {
      start: result.start,
      end: result.end,
      text: result.text,
      recognized: result.recognized,
      language: result.language,
      name: result.name
    };

    this.setState({
      transcriptData: [...this.state.transcriptData, transcriptObj]
    });
    // this.props.sendTranscriptData( sendMsgData.text)
  };

  getTranslationRecognizer = (sourceLang, reason) => {
    this.translationRecognizer = null;
    if (this.translationRecognizer != null) {
      this.translationRecognizer?.stopContinuousRecognitionAsync(() => {
        this.translationRecognizer.close();
      });
    }
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

    const speechTranslationConfig = sdk.SpeechTranslationConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);

    speechTranslationConfig.speechRecognitionLanguage = sourceLang;
    speechTranslationConfig.enableDictation();

    for (const languageObj of this.state.languageData) {
     
      if (languageObj.language != '') {
        speechTranslationConfig.addTargetLanguage(languageObj.language);
      }
    }
    const existingIndex = this.state.languageData.findIndex(item => item.language === 'en');
    if (existingIndex == -1) {
      speechTranslationConfig.addTargetLanguage('en');
    }

    this.translationRecognizer = new sdk.TranslationRecognizer(speechTranslationConfig, audioConfig);

    // this.setState({transcriptionResults: ''});
    this.translationRecognizer.recognizing = (s, e) => {
      if (this.state.isSpeaker) {
        const result = e.result;
        const transcript = result.privText;
        this.setState({
          transcriptionResults: transcript
        });
        // console.log('translation', transcript);
        for (const languageObj of this.state.languageData) {
          const existingIndex = result.privTranslations.privMap.privKeys.findIndex(
            item => item === languageObj.language
          );
          if (existingIndex != -1) {
            let translation = `${result.privTranslations.privMap.privValues[existingIndex]}`;

            const transcriptObj = {
              end: `${result.privOffset}`,
              duration: `${result.privDuration}`,
              text: translation,
              recognized: false,
              name: this.state.userName,
              language: languageObj.language
            };

            //this.generateTranscriptData(transcriptObj)
            if (this.rtmClient.connectionState == 'CONNECTED') {
              this.sendMsg(JSON.stringify(transcriptObj));
            }
          }
        }
      }
    };

    this.translationRecognizer.recognized = (s, e) => {
      // if (e.result.reason) {
      const existingIndex = e.result.privTranslations.privMap.privKeys.findIndex(item => item === 'en');
      if (existingIndex != -1) {
        if (this.state.isSpeaker) {
          const result = e.result;
          let translation = `${result.privTranslations.privMap.privValues[existingIndex]}`;
          const currentUTCTimestampString = moment.utc().format('YYYY-MM-DD HH:mm:ss');
          let sendMsgData = {
            end: '' + currentUTCTimestampString,
            duration: `${result.privDuration}`,
            text: translation,
            recognized: true,
            language: 'en',
            name: this.state.userName
          };
       
          // this.generateTranscriptData(sendMsgData)
          if (this.rtmClient.connectionState == 'CONNECTED') {
            this.sendMsg(JSON.stringify(sendMsgData));
          }
          this.setState({
            transcriptData: [...this.state.transcriptData, sendMsgData]
          });
          this.props.sendTranscriptData(this.state.transcriptData);
        }
      }
    };

    this.translationRecognizer.canceled = (s, e) => {
   
      this.sendToMixPanel('Azure| Recognition Cancel', 202, 'Recognition session canceled ' + e);
      setTimeout(() => {
        if (!this.state.translationStop) {
          this.stopTranslationRecognition();
          this.startTranslation(e);
        }
      }, 5000);
    };

    this.translationRecognizer.sessionStopped = (s, e) => {

      this.sendToMixPanel('Azure | Recognition Stop', 203, 'Recognition session stopped.');
    };

    this.translationRecognizer.startContinuousRecognitionAsync(
      e => {
  
        this.sendToMixPanel('Azure | Recognition Start', 204, 'Recognition session started.' + reason);
      },
      err => {
        console.log(`ERRORss: ${err}`);
      }
    );
  };

  stopTranslationRecognition = () => {
    if (this.translationRecognizer != null) {
      this.translationRecognizer?.stopContinuousRecognitionAsync(
        () => {
          // this.translationRecognizer.close()
          console.log('Translation stopped!!');
        },
        e => {
          console.log('Error while stopping translation services!!');
        }
      );
    }
    this.setState({ TranslationLanguage: false });
  };

  sendDataAPi = data => {
    this.props.storeTranscriptLogs(data);
  };

  sendMessageDataAPi = data => {
    this.props.storeTranscriptMessageLogs(data);
  };

  startTranslationCC = () => {
  
    if (this.state.TranslationLanguage) {
      this.setState({ startTranslation: false, TranslationLanguage: false });
    } else {
      this.sendTurnOn();

      this.setState({ startTranslation: true, TranslationLanguage: true });
    }
  };

  subscribe = async (user, mediaType) => {
    const uid = user.uid;
 
    if (uid == '240000') {
      this.uidarray['PrimaryResearcher'] = uid;
    } else if (uid == '1000') {
      this.uidarray['Respondant'] = uid;
    } else if (uid == '1500') {
      this.uidarray['RespondentOnWeb'] = uid;
    } else if (uid == '123456789' || uid == '12345678' || uid == '1234567') {
      this.uidarray['ScreenShare'] = uid;
    }
    // TOASK
    // changed
    // else {
    //   console.log("inside else of subscribe", this.uidarray['other'], uid)
    //   this.uidarray['other'] = uid;
    // }

    // subscribe to a remote user
    await this.client.subscribe(user, mediaType);

    if (mediaType === 'video') {
      // this.localTracks.videoTrack = user.videoTrack;
      let canvas = document.querySelector('#ag-canvas');
      //Object.values(this.uidarray).map((value, index) => {
      // const id = value;
      let dom = document.querySelector('#ag-item-' + uid);
   
      if (!dom) {
        dom = document.createElement('section2');
        dom.setAttribute('id', 'ag-item-' + uid);
        dom.setAttribute('class', 'ag-item');
        dom.setAttribute('value', uid);
        let videoPosition = 'absolute';

        //dom.setAttribute('style', "position:'" + videoPosition + "'");
        // dom.onClick=hello();

        if (localStorage.getItem('user') == 'respondent') {
          // dom.setAttribute('style', 'grid-area: span 12/span 24/13/25');
        }
        canvas.appendChild(dom);
      
        setTimeout(function() {
          user?.videoTrack?.play('ag-item-' + uid, { fit: 'contain' });
        }, 1000);

        //this.localTracks.videoTrack.play("ag-item-" + uid , {fit: "contain"});
      }
      if (document.querySelector('#ag-item-' + this.uidarray['other'])) {
       
        let dom = document.querySelector('#ag-item-' + this.uidarray['other']);
        dom.setAttribute('style', `display: none`);
      }

      //screen share by researcher
      if (this.uidarray['ScreenShare']) {
        let dom4 = document.querySelector('#ag-item-' + this.uidarray['ScreenShare']);
        if (dom4 != null)
          if (dom4.getAttribute('id') == 'ag-item-' + this.uidarray['ScreenShare']) {
            console.log('scrrenshre---1');
            dom4.setAttribute(
              'style',
              `grid-area: span 12/span 24/13/25;z-index:1;color:red; width: calc(100% - 12px);
            height: calc(100% - 20px);`
            );
          }
        if (this.uidarray['Respondant']) {
          console.log('screen share by researcher');
          let dom1 = document.querySelector('#ag-item-' + this.uidarray['Respondant']);
          if (dom1 != null)
            if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['Respondant']) {
              console.log('scrrenshre---2');
              // dom1.setAttribute(
              //   'style',
              //   `grid-area: span 3/span 4/4/5;
              //       z-index:1;width:calc(100% - 20px);height:calc(100% - 20px);color:1;`
              // );
              dom1.setAttribute('class', `researcherBox 4`);
            }
        }
        // Respondent on Web start
        if (this.uidarray['RespondentOnWeb']) {
          console.log('screen share by RespondentOnWeb');
          let dom5 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
          if (dom5 != null)
            if (dom5.getAttribute('id') == 'ag-item-' + this.uidarray['RespondentOnWeb']) {
              console.log('scrrenshre---RespondentOnWeb');
              dom5.setAttribute(
                'style',
                `grid-area: span 3/span 4/4/5;
                    z-index:2;width:calc(100% - 20px);height:calc(100% - 20px);color:2;`
              );
              if (localStorage.getItem('user') == 'respondent') {
                let dom1 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
                dom1.setAttribute('style', `grid-area: span 12/span 24/13/25;color:8;`);
                let dom6 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
                dom6.setAttribute(
                  'style',
                  `grid-area:span 3/span 4/4/5;
                    z-index:2;width:calc(100% - 20px);height:calc(100% - 20px);color:7;`
                );
              }
              if (localStorage.getItem('user') == 'researcher') {
                let dom6 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
                // dom6.setAttribute('style', `grid-area: span 12/span 24/13/25;z-index:1`);
                //added by shweta
                dom6.setAttribute(
                  'style',
                  `grid-area: span 3/span 4/4/2;
                          z-index:1;
                      width:calc(100% - 12px);height:calc(100% - 20px);margin-top: 45px;`
                );
              }
            }
        }
        //END
        if (this.uidarray['other']) {
          let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
          if (dom3 != null)
            if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
              console.log('scrrenshre---3');
              let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
              if (dom2 != null) {
                dom2.removeAttribute('class');
              }
              // dom3.setAttribute(
              //   "style",
              //   `grid-area: span 3/span 4/7/5;
              //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
              // );
            }
        }
        // if(this.uidarray["PrimaryResearcher"]){
        // let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        // if (dom2 != null)
        //   if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
        //     console.log('scrrenshre---4');
        //     dom2.setAttribute(
        //       'style',
        //       `grid-area: span 3/span 4/10/5;
        //             z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
        //     );
        //   }
        // }
      } else if (this.uidarray['Respondant']) {
        let dom1 = document.querySelector('#ag-item-' + this.uidarray['Respondant']);
        if (dom1 != null)
          if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['Respondant']) {
            console.log('Respondant');
            // added by shweta
            // dom1.setAttribute('style', `grid-area: span 3/span 4/7/5;z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`);
          }

        let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
        if (dom3 != null)
          if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
            console.log('Respondant1');
            // dom3.setAttribute(
            //   "style",
            //   `grid-area: span 3/span 4/7/5;
            //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
            // );
          }

        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null) {
          if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
            console.log('Respondant2');
            dom2.removeAttribute('style');
            //added by shweta
            dom2.setAttribute('class', `researcherBox 5`);
            //its for researcher
            if (localStorage.getItem('user') == 'respondent') {
              // dom2.setAttribute('style', `grid-area: span 12/span 24/13/25;border:2px;`);
              dom2.setAttribute('class', `researcherBox 6`);
            }
          }
        }
      } else if (this.uidarray['RespondentOnWeb']) {
        let dom1 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
        if (dom1 != null)
          if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['RespondentOnWeb']) {
            console.log('RespondentOnWeb');
            dom1.setAttribute('style', `grid-area: span 12/span 24/13/25;color:yellow`);

            // if (localStorage.getItem('user') == 'respondent') {
            //   dom1.setAttribute(
            //     'style',
            //     `grid-area: span 3/span 4/4/5;z-index:3;width:calc(100% - 20px);height:calc(100% - 20px)`
            //   );
            // }

            if (localStorage.getItem('user') == 'researcher') {
              // dom1.setAttribute(
              //   'style',
              //   `grid-area: span 3/span 4/4/5;z-index:3;width:calc(100% - 20px);height:calc(100% - 20px)`
              // );
              // let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
              // dom2.setAttribute('style', `grid-area: span 12/span 24/13/25;color:11`);
            }
          }

        let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
        if (dom3 != null)
          if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
            console.log('RespondentOnWeb1');
            // dom3.setAttribute(
            //   "style",
            //   `grid-area: span 3/span 4/7/5;
            //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
            // );
          }

        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null)
          if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
            console.log('RespondentOnWeb2');
            // dom2.setAttribute(
            //   'style',
            //   `grid-area: span 3/span 4/4/5;
            //           z-index:3;width:calc(100% - 20px);height:calc(100% - 20px);2`
            // );
            //its for researcher section 2
            if (localStorage.getItem('user') == 'respondent') {
              dom2.setAttribute('style', `grid-area: span 12/span 24/13/25;border:3px;`);
            }
          }
      } else if (this.uidarray['other']) {
        let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
        if (dom3 != null)
          if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
            console.log('other---1');
            //dom3.setAttribute("style", `grid-area: span 12/span 24/13/25`);
          }

        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null)
          if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
            console.log('other---2');
            // dom2.setAttribute(
            //   'style',
            //   `grid-area: span 3/span 4/4/5;
            //         z-index:1;width:calc(100% - 20px);height:calc(100% - 20px);color:3;`
            // );
          }
      } else {
        console.log('-------1');
        dom.setAttribute('style', `grid-area: span 12/span 24/13/25;color:grey`);
      }
    }
    if (mediaType === 'audio') {
      console.log('Inside mediaTypeAudio');
      // if(this.localTracks.audioTrack != null){
      console.log('Inside audiotrack mediaTypeAudio');
      // if (!this.uidarray['ScreenShare']) {
      console.log('audio going', user, user.audioTrack);
      user.audioTrack?.play();
      // }
      //}
    }
  };

  componentDidMount() {
    let canvas = document.querySelector('#ag-canvas');
   
    let btnGroup = document.querySelector('.ag-btn-group');
    this.preloadImages();
    // let captionBtn = document.querySelector('ag-caption')
    if (this.state.isMicMuted) {
 
      this.timerId = setTimeout(() => {
        this.setState({ active: false });
      }, 3000);
    }

    canvas.addEventListener('mousemove', () => {
      if (global._toolbarToggle) {
        clearTimeout(global._toolbarToggle);
      }
      btnGroup.classList.add('active');
      global._toolbarToggle = setTimeout(function() {
        btnGroup.classList.remove('active');
        // captionBtn.classList.remo('active')
      }, 2000);
    });
  }

  preloadImages = () => {
    const { networkIconPath } = this.state;
    Object.keys(networkIconPath).forEach(key => {
      const img = new Image();
      img.src = networkIconPath[key];
      //console.log("images", img.src)
    });
  };

  showNetworkQualityToast = () => {
    const { networkQuality } = this.state;

    let message = '';
    let submessage = '';
    let iconPath = '';
    let toastClassName = 'custom-toast-body';
    let closeButton = true;

    if (networkQuality?.uplinkNetworkQuality === 0 || networkQuality?.uplinkNetworkQuality === 1) {
      this.preloadImages();
      toastClassName += ' transparent-toast-body';
      closeButton = false;
    } else if (networkQuality?.uplinkNetworkQuality >= 3 && networkQuality?.uplinkNetworkQuality <= 5) {
      this.preloadImages();
      iconPath = this.state.networkIconPath.yellowbar;
      message = 'Poor connection';
      submessage = 'Change network or wait while we reconnect';
    } else if (networkQuality?.uplinkNetworkQuality === 6) {
      this.preloadImages();
      iconPath = this.state.networkIconPath.redbar;
      message = 'No connection';
      submessage = 'Check your network connection';
    } else {
      toastClassName += ' transparent-toast-body';
      closeButton = false;
    }

    if (this.toastId) {
      toast.dismiss(this.toastId);
    }
    this.toastId = toast(
      <div style={{ display: 'flex', alignItems: 'center', justifycontent: 'space-around' }}>
        {iconPath && (
          <img src={iconPath} alt={message} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
        )}

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div style={{ fontWeight: 'bold' }}>{message}</div>
          <div style={{ textAlign: 'left', width: '100%' }}>{submessage}</div>
        </div>
      </div>,

      {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: toastClassName,
        closeButton: closeButton ? true : false
      }
    );
  };
  closeToast = () => {
    // Close the toast
    if (this.toastId) {
      toast.dismiss(this.toastId);
    }
  };
  componentDidUpdate() {
    // rerendering
    // console.log('componentDidUpdate');
    let canvas = document.querySelector('#ag-canvas');

    // console.log('canvas', this.state.displayMode);
    // pip mode (can only use when less than 4 people in channel)
    const currentUplinkQuality = this.state.networkQuality?.uplinkNetworkQuality;
    if (currentUplinkQuality !== this.previousUplinkQuality) {
      this.previousUplinkQuality = currentUplinkQuality;
      this.showNetworkQualityToast();
      if (this.toastTimeoutId) {
        clearTimeout(this.toastTimeoutId);
      }
      this.toastTimeoutId = setTimeout(() => {
        this.closeToast();
      }, 5000); // Close toast after 5 seconds
    }
    if (this.state.displayMode === 'pip') {
      let no = this.state.streamList.length;
      if (no > 4) {
        this.setState({ displayMode: 'tile' });
        return;
      }
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector('#ag-item-' + id);
       
        if (!dom) {
          dom = document.createElement('section3');
          dom.setAttribute('id', 'ag-item-' + id);
          dom.setAttribute('class', 'ag-item');
          // dom.onClick=hello();
          canvas.appendChild(dom);
          item.play('ag-item-' + id, { fit: 'cover' });
        }
    

        if (id == '240000') {
          dom.setAttribute(
            'style',
            `grid-area: span 3/span 4/4/25;
                    z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
          );
        } else {
          dom.setAttribute('style', `grid-area: span 12/span 24/13/25;color:green`);
        }
      });
    }
    // tile mode
    else if (this.state.displayMode === 'tile') {
      let no = this.state.streamList.length;
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector('#ag-item-' + id);
        if (!dom) {
          dom = document.createElement('section4');
          dom.setAttribute('id', 'ag-item-' + id);
          dom.setAttribute('class', 'ag-item');
          canvas.appendChild(dom);
          item.play('ag-item-' + id);
        }
        dom.setAttribute('style', `grid-area: ${tile_canvas[no][index]}`);
        item.player.resize && item.player.resize();
      });
    }
    // screen share mode (tbd)
    else if (this.state.displayMode === 'share') {
      // console.log('Screen Share', 'Test');
    }
    // console.log('Screen Share', 'Test');
  }
  eventHandler = () => {
    this.rtmClient.logout();
    this.rtmChannel.leave();
    this.client.leave();
    this.sendToMixPanel('RTM | Left Channel', 207, `RTM member left the channel`);
    
  };

  componentWillUnmount() {

    this.sendTurnOff();
    // code to remove rtm client
    // code to remove rtm client
    const stopRtc = async () => {
      this.rtmChannel.removeAllListeners();
      this.rtmClient.removeAllListeners();
      // await this.rtmChannel.leave();
      window.removeEventListener('beforeunload', this.eventHandler);
      await this.rtmClient.logout();
      console.log('RTM client released!!!');
    };
    // rtm client release
    if (this.rtmClient && this.rtmClient != null && this.rtmChannel) {
      stopRtc();
    }
    clearTimeout(this.timerId);

    if (this.translationRecognizer != null) {
      this.translationRecognizer?.stopContinuousRecognitionAsync(async () => {
        await this.translationRecognizer.close();
        console.log('translation stopped!!!', this.translationRecognizer);
      });
    }

    //this.client && this.client.unpublish();
    this.client &&
      this.client.leave(
        () => {
          console.log('Client succeed to leave.');
        },
        () => {
          console.log('Client failed to leave.');
        }
      );

    //destroying interval
    clearInterval(this.intervalReset);
  }

  RTMConnectionLost = () => {
    setTimeout(() => {
      if (this.state.rtmStop) {
        console.log('RTM stopped!!! Restarting...');
        this.initRtm(this.props.channel);
        this.startTranslation('Called from RTMConnectionLost');
        this.sendTurnOn();
      }
    }, 5000);
  };

  resetTimer = () => {
    this.intervalReset = setInterval(() => {
      if (this.resetCount > 60) {
        this.resetCount = 0;
        this.rtmChannel.removeAllListeners();
        this.rtmClient.removeAllListeners();
        this.initRtm(this.props.channel);
        this.sendToMixPanel('RTM | Restart RTM', 205, `Restarting RTM due to no message exchange in last 1 min`);
      } else {
        if (this.state.isSpeaker) {
          this.resetCount += 10;
        }
      }
    }, 10000);
  };

  initRtm = async channel => {
    try {
      //rtm client initialize

      //create rtm client and channel
      const useClient = createClient(this.props.appId, { enableLogUpload: true });
      // const useClient = createClient(this.props.appId);
      const useChannel = createChannel(channel);

      this.rtmClient = useClient();
      this.rtmChannel = useChannel(this.rtmClient);
      let receiverId = '';

      // RTM client login
      console.log(this.USER_ID, '*******countmember this.USER_ID');
      await this.rtmClient.login({
        uid: this.USER_ID
      });
      // uid: this.state.userid.toString()
      console.log('**********RTM CLIENT LOGGED IN**********');

      // create RTM channel
      await this.rtmChannel.join();
      this.sendToMixPanel('RTM | Join Channel', 206, 'RTM member joined the channel.');
      const response = await this.rtmClient.getChannelMemberCount([channel]);
      const memberCount = response[channel];
      console.log(memberCount, '************countmember');
      this.setState({
        participants: memberCount
      });
      await this.fetchParticipantsData();

      this.rtmChannel.on('MemberCountUpdated', async count => {
        console.log(`countmember user joined ${channel}: ${count}`);
        this.setState({
          participants: count
        });
        this.fetchParticipantsData();
        //console.log('count change 2', this.state.ParticipantsData);
      });

      window.addEventListener('beforeunload', this.eventHandler);
      // this.rtmClient.on('ConnectionStateChanged', async (state, reason) => {
      //   console.log('ConnectionStateChanged...', state, reason);
      //   var errorCod = 300
      //   if (state == 'DISCONNECTED') {
      //     errorCod = 302
      //   } else if (state == 'CONNECTING') {
      //     errorCod = 303
      //   } else if (state == 'ABORTED') {
      //     errorCod = 304
      //   } else if (state == 'RECONNECTING') {
      //     errorCod = 301
      //   }
      //   //if(this.rtmClient?.connectionState == 'CONNECTED')
      //   // if(state == 'RECONNECTING'){
      //   //   this.setState({
      //   //     transcriptionResults: "Resuming translation... "
      //   //   });
      //   //   console.log("Resuming translation...")
      //   // }
      //   // // if(state == 'CONNECTED'){
      //   // //   this.startTranslation();
      //   // //   this.sendTurnOn();
      //   // // }
      //   // if (state == 'DISCONNECTED' || state == 'ABORTED') {
      //   //   this.setState({
      //   //     transcriptionResults: "Connection disconnected, retrying... "
      //   //   });
      //   //   console.log("Connection disconnected, retrying...")
      //   //   this.setState({rtmStop: true});
      //   //   this.RTMConnectionLost();
      //   // } else {
      //   //   // this.setState({rtmStop: false});
      //   // }
      //   const currentTimestamp = new Date().getTime();
      //   const responseObj = {
      //     statement: {
      //     timeStamp: currentTimestamp,
      //     Platform: 'web',
      //     role: 'researcher',
      //     channelId: this.props.channel,
      //     name: this.state.userName,
      //     event: 'Agora RTM',
      //     userId: this.state.userid,
      //     RTMUserId: this.USER_ID,
      //     errorCode: errorCod,
      //     reson: `Connection state changed: ${state}, reason: ${reason}`
      //     }
      //   };
      //   this.sendDataAPi(responseObj);
      // });

      console.log('**********RTM CHANNEL JOINED**********');
      this.rtmClient.on('ConnectionStateChanged', async (state, reason) => {
        console.log('RTM Connection State Changed...', state, reason);
        //if(this.rtmClient?.connectionState == 'CONNECTED')
        if (state == 'RECONNECTING') {
          this.setState({ isreconnecting: true });
          this.setState({
            transcriptionResults: 'Please wait. Trying to resume captions...'
          });
          this.transcriptionTimeoutId = setTimeout(() => {
            this.setState({
              transcriptionResults: 'Failed to load. Contact support'
            });
          }, 15000);
          console.log('Connection state is reconnecting then resuming translation...', state);
        }
        if (state == 'CONNECTED') {
          // this.startTranslation();
          // this.sendTurnOn();
          this.setState({
            // transcriptionResults: '',
            isreconnecting: false
          });
          if (this.transcriptionTimeoutId) {
            clearTimeout(this.transcriptionTimeoutId);
            this.transcriptionTimeoutId = null;
          }
        }
        if (state == 'DISCONNECTED' || state == 'ABORTED') {
          this.setState({
            transcriptionResults: 'Failed to load. Contact support'
          });
          console.log('Connection disconnected, retrying...', state);
          this.setState({ rtmStop: true });
          this.RTMConnectionLost();
        } else {
          // this.setState({rtmStop: false});
        }
        var errorCod = 300;
        if (state == 'DISCONNECTED') {
          errorCod = 302;
        } else if (state == 'CONNECTING') {
          errorCod = 303;
        } else if (state == 'ABORTED') {
          errorCod = 304;
        } else if (state == 'RECONNECTING') {
          errorCod = 301;
        }
        this.sendToMixPanel(
          'RTM | Connection State Changed',
          errorCod,
          `Connection state changed: ${state}, reason: ${reason}`
        );
        if (state === 'DISCONNECTED') {
          this.sendToMixPanel('RTM | Left Channel', 207, `RTM member left the channel, reason: ${reason}`);
        }
      });

      this.rtmChannel.on('ChannelMessage', async (msg, uid) => {
        const parsedMsg = JSON.parse(msg.text);
        if (parsedMsg) {
          this.setState({ participantsName: parsedMsg });
        }

        console.log(`Message received ${parsedMsg} from ${uid}`);
        if (parsedMsg.text == 'Please turn on CC..!!') {
          const lang = parsedMsg.language;
          this.updateData(parsedMsg.name, lang);
        } else if (parsedMsg && parsedMsg.text == 'Please turn off CC..!!') {
          const data = this.state.displayData;
          const existingIndex = data.findIndex(item => item.name === parsedMsg.name);
          if (existingIndex != -1) {
            data[existingIndex].text = '';
            this.setState({ displayData: data });
          }
        } else {
          if (parsedMsg && parsedMsg.recognized) {
            // this.setState({ participantsName : parsedMsg});
            // console.log("Message received ${parsedMsg} from ${uid} ", this.state.participantsName)
            if (this.resetCount > 8) {
              this.resetCount = 0;
            }
          }
          if (this.state.myLanguage.split('-')[0] == parsedMsg.language) {
            const data = this.state.displayData;

            const existingIndex = data.findIndex(item => item.name === parsedMsg.name);
            if (existingIndex != -1) {
              data[existingIndex].text = parsedMsg.text;
            } else {
              data.push({ name: parsedMsg.name, text: parsedMsg.text });
            }
            this.setState({ displayData: data });
            //this.setState({ participantsName: data });
          }
          if (parsedMsg.recognized) {
            //const parsedMsg = JSON.parse(text);
            let hasKey = parsedMsg.hasOwnProperty('recognized');

            // receive message only for primary user
            if (hasKey && parsedMsg.recognized && this.uid == '240000') {
              const { saveRtmTranscriptData } = this.props;
              const InterviewSchedule_ID = localStorage.getItem('InterviewSchedule_ID');
              const transcriptPayload = {
                user_ID: window.$user,
                emailID: window.$emailId,
                uid: String(this.uid),
                InterviewSchedule_ID: InterviewSchedule_ID,
                transcript_data: parsedMsg,
                channel_name: this.props.channel
              };
              saveRtmTranscriptData(transcriptPayload);
            }

            // const logData = {
            //   respondentId: this.props.respondantInfo.Respondent_ID,
            //   respondentName: this.props.respondantInfo.RespondentName,
            //   researcherId: this.state.userid,
            //   researcherName: this.state.userName,
            //   channelId: this.props.channel,
            //   reason: "Message is received from respondent!",
            // };
            // console.log("in rtm channel message recxived", logData)
            // this.sendMessageDataAPi(logData);
      

            this.setState({
              transcriptData: [...this.state.transcriptData, parsedMsg]
            });
            this.props.sendTranscriptData(this.state.transcriptData);
          }
        }
      });

      //Listening to user joining the channel event.
      this.rtmChannel.on('MemberJoined', memberId => {
        console.log('-------------Member joined: ', memberId);
        setTimeout(this.sendTurnOn, 1500);
        const currentTimestamp = new Date().getTime();
        const responseObj = {
          statment: {
            timeStamp: currentTimestamp,
            Platform: 'web',
            role: 'researcher',
            channelId: this.props.channel,
            name: this.state.userName,
            event: 'Agora RTM',
            userId: this.state.userid,
            RTMUserId: this.USER_ID,
            errorCode: 206,
            reson: `RTM member joined the channel, member id: ${memberId}`
          }
        };
      });

      //Listening to member leaving the channel event
      this.rtmChannel.on('MemberLeft', memberId => {
        console.log('--------------Member left: ', memberId);
        const currentTimestamp = new Date().getTime();
        const responseObj = {
          statement: {
            timeStamp: currentTimestamp,
            Platform: 'web',
            role: 'researcher',
            channelId: this.props.channel,
            name: this.state.userName,
            event: 'Agora RTM',
            userId: this.state.userid,
            RTMUserId: this.USER_ID,
            errorCode: 207,
            reson: `RTM member left the channel, member id: ${memberId}`
          }
        };
      });
    } catch (e) {
      // console.log('In rtm channel message not recieved', logData);
      this.sendToMixPanel('RTM | Join Failed', 208, 'initRtm - RTM not initialized with error ' + e);
    }
  };

  updateData = (name, newTarget) => {
    const data = this.state.languageData;
    const existingIndex = data.findIndex(item => item.name === name);
    if (existingIndex != -1) {
      data[existingIndex].language = newTarget;
    } else {
      data.push({ name, language: newTarget });
    }
    this.setState({ languageData: data }, () => {
      this.stopTranslationRecognition();
      this.startTranslation('User changed the Language');
    });
  };

  sendTurnOn = () => {
    if (this.state.myLanguage && this.state.myLanguage != '') {
      const transcriptObj = {
        text: 'Please turn on CC..!!',
        name: this.state.userName,
        language: this.state.myLanguage?.split('-')[0]
      };
      this.sendMsg(JSON.stringify(transcriptObj));
    } else {
      const transcriptObj = {
        text: 'Please turn on CC..!!',
        name: this.state.userName,
        language: 'en'
      };
      this.sendMsg(JSON.stringify(transcriptObj));
    }
  };

  sendTurnOff = () => {
    console.log('Member has left and turned off captions...');
    const transcriptObj = {
      text: 'Please turn off CC..!!',
      name: this.state.userName,
      language: ''
    };
    this.sendMsg(JSON.stringify(transcriptObj));
  };

  handleCamera = async e => {
    this.localTracks.videoTrack.enabled
      ? e.currentTarget.classList.add('off')
      : e.currentTarget.classList.remove('off');
    if (this.localTracks.videoTrack.enabled) {
      this.sendToMixPanel('RTC | Video off', 201, 'User video turned off.');
    } else {
      this.sendToMixPanel('RTC | Video on', 201, 'User video turned on.');
    }

    await this.localTracks.videoTrack.setEnabled(!this.localTracks.videoTrack.enabled);
    // e.currentTarget.classList.toggle('off');

    // if (!this.localTrackState.videoTrackMuted) {
    //   this.muteVideo();
    // } else {
    //   this.unmuteVideo();
    // }
  };
  sendToMixPanel = (eventName, errCode, reason) => {
    const currentTimestamp = new Date().getTime();
    const responseObj = {
      statement: {
        timeStamp: currentTimestamp,
        Platform: 'web',
        role: 'researcher',
        channelId: this.props.channel,
        name: this.state.userName,
        event: eventName,
        userId: this.state.userid,
        RTMUserId: this.USER_ID,
        errorCode: errCode,
        reson: reason
      }
    };
    
  
    mixPanel.track(responseObj.statement.event, { ...responseObj.statement, memberId: this.uid });
  };

  handleMic = async e => {
    // console.log('MIC', e.currentTarget.classList);
    this.localTracks.audioTrack.enabled
      ? e.currentTarget.classList.add('off')
      : e.currentTarget.classList.remove('off');
    if (this.localTracks.audioTrack.enabled) {
      this.sendToMixPanel('RTC | Mute Mic', 201, 'User mic turned off.');
    } else {
      this.sendToMixPanel('RTC | Unmute Mic', 201, 'User mic turned on.');
    }
    // await this.localTracks.audioTrack.setEnabled(!this.localTracks.audioTrack.enabled)
    // this.setState({ isMicMuted: this.localTracks.audioTrack.enabled, active: this.localTracks.audioTrack, isSpeaker: this.localTracks.audioTrack.enabled });
    // e.currentTarget.classList.toggle('off');
    this.unmuteAudio();
    if (!this.state.isMicMuted) {
      this.setState({ isMicMuted: true, active: true });

      this.timerId = setTimeout(() => {
        this.setState({ active: false });
      }, 3000);
    } else {
      this.setState({ isMicMuted: false });
      this.unmuteAudio();
    }
    window.$mic = e.currentTarget.classList[2];
    if (!this.localTrackState.audioTrackMuted) {
    
      this.muteAudio();
    } else {
    
      this.unmuteAudio();
    }
    this.setState({ isSpeaker: !this.state.isSpeaker });
  };

  muteAudio = async () => {
    console.log('muteVideo muteAudio***********', this.localTracks.audioTrack.enabled);
    if (!this.localTracks.audioTrack) return;
    /**
     * After calling setMuted to mute an audio or video track, the SDK stops sending the audio or video stream. Users whose tracks are muted are not counted as users sending streams.
     * Calling setEnabled to disable a track, the SDK stops audio or video capture
     */
    await this.localTracks.audioTrack.setEnabled(false);
    this.localTrackState.audioTrackMuted = true;
    // this.setState({ startTranslation: false, TranslationLanguage: false });
    $('#mute-audio').text('Unmute Audio');
  };
  muteVideo = async () => {
    console.log('muteVideo***********', this.localTracks.videoTrack.enabled);
    if (!this.localTracks.videoTrack) return;
    await this.localTracks.videoTrack.setEnabled(false);
    this.localTrackState.videoTrackMuted = true;
    $('#mute-video').text('Unmute Video');
  };
  unmuteAudio = async () => {
    console.log('muteVideo unmuteAudio***********', this.localTracks.audioTrack.enabled);
   
    if (!this.localTracks.audioTrack) return;
    await this.localTracks.audioTrack.setEnabled(true);
    this.localTrackState.audioTrackMuted = false;
    // this.setState({ startTranslation: true, TranslationLanguage: true });
    $('#mute-audio').text('Mute Audio');
  };
  unmuteVideo = async () => {
    console.log('muteVideo unmuteVideo***********', this.localTracks.videoTrack.enabled);
    if (!this.localTracks.videoTrack) return;
    await this.localTracks.videoTrack.setEnabled(true);
    this.localTrackState.videoTrackMuted = false;
    $('#mute-video').text('Mute Video');
  };

  switchDisplay = e => {
    if (e.currentTarget.classList.contains('disabled') || this.state.streamList.length <= 1) {
      return;
    }
    if (this.state.displayMode === 'pip') {
      this.setState({ displayMode: 'tile' });
    } else if (this.state.displayMode === 'tile') {
      this.setState({ displayMode: 'pip' });
    } else if (this.state.displayMode === 'share') {
      // do nothing or alert, tbd
    } else {
      console.error('Display Mode can only be tile/pip/share');
    }
  };

  hideRemote = e => {
    if (e.currentTarget.classList.contains('disabled') || this.state.streamList.length <= 1) {
      return;
    }
    let list;
    let id = this.state.streamList[this.state.streamList.length - 1].getId();
    list = Array.from(document.querySelectorAll(`.ag-item:not(#ag-item-${id})`));
    list.map(item => {
      if (item.style.display !== 'none') {
        item.style.display = 'none';
      } else {
        item.style.display = 'block';
      }
    });
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  // handleConfirmExit = async () => {
  //   this.setState({ showModal: false });
  //   await this.handleExit();
  // };

  handleExit = async e => {
    const payload = {
      channelName: this.props.channel,
      user_ID: this.state.userid
    };
    this.props.SendParticpantsListOnEnd(payload);

    var lastCall = false;
    this.sendToMixPanel('RTM | Left Channel', 207, `RTM member left the channel`);
    // stop showing message on ui for the member left
    this.sendTurnOff();
    //disable translation
    if (this.translationRecognizer != null) {
      this.translationRecognizer?.stopContinuousRecognitionAsync(() => {
        this.translationRecognizer.close();
      });
    }

    // code to remove rtm client
    const stopRtc = async () => {
      this.rtmChannel.removeAllListeners();
      this.rtmClient.removeAllListeners();
      // await this.rtmChannel.leave();
      await this.rtmClient.logout();
    };
    // rtm client release
    if (this.rtmClient) {
      stopRtc();
    }

    console.log('RTC client released!!!');

    this.props.setCallOn(2);
    this.props.setRespondantActive(false);
  
    if (e.currentTarget.classList.contains('disabled')) {
      return;
    }
    var trackName = '';
    try {
      var localTracks = this.localTracks;
      for (trackName in localTracks) {
      
        var track = localTracks[trackName];
        if (track) {
          track.stop();
          track.close();
          localTracks[trackName] = undefined;
        }
      }
      //this.client && this.client.unpublish();
      // if (this.state.stateSharing) {
      //   this.shareClient && this.shareClient.unpublish(this.shareStream);
      //   this.shareStream && this.shareStream.close();
      // }
      (await this.client) &&
        this.client.leave(
          () => {
            console.log('Client succeed to leave.');
          },
          () => {
            console.log('Client failed to leave.');
          }
        );
    } finally {
      this.setState({ readyState: false });
      this.client = null;
      const currentUid = localStorage.getItem('UID');
      // redirect to index
      if (this.uid == '1500') {
        this.props.history.push('pre-call-resp');
      } else {
        if (currentUid == '240000') {
          if (fieldData.InterviewComplete + 1 == resumeData.respondentCount) {
            lastCall = true;
          }
        }

        this.props.history.push('pre-call', { LastCall: lastCall });
      }
    }
    const data = {
      cname: this.props.channel,
      "uid": String(this.state.uid),
      // uid: '3613483512',
      clientRequest: {}
    };

    const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
    // Customer secret
    const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
    // Concatenate customer key and customer secret and use base64 to encode the concatenated string
    const plainCredential = customerKey + ':' + customerSecret;
    // Encode with base64
    const encodedCredential = Buffer.from(plainCredential).toString('base64');
    const authorizationField = 'Basic ' + encodedCredential;



    const email = window.$emailId;
    const userid = window.$user; //global variable

    const payload3 = {
      user_ID: userid,
      emailID: email
    };

    const { removeVideoCall } = this.props;
    removeVideoCall(payload3);

    const { StopVideoRecording } = this.props;

    //Endinterview code//

    const endInterview = data => {
      return new Promise(function request(resolve) {
        maverickPortalhttp
          .makeRequest(
            maverickPortalApiEndPoint.endInterview.url,
            maverickPortalApiEndPoint.endInterview.methodType,
            data
          )
          .then(response => {
            const res = response.data;
            resolve({ res });
          });
      });
    };

    //END//

    if (this.uid == '240000') {
      // StopVideoRecording(data, authorizationField);
      var today = new Date();

      var time =
        today.getFullYear().toString() +
        '-' +
        (today.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        today
          .getDate()
          .toString()
          .padStart(2, '0') +
        ' ' +
        today
          .getHours()
          .toString()
          .padStart(2, '0') +
        ':' +
        today
          .getMinutes()
          .toString()
          .padStart(2, '0') +
        ':' +
        today
          .getSeconds()
          .toString()
          .padStart(2, '0');
      const endinterviewdata = {
        user_ID: localStorage.userId,
        projectRepondentVideo_ID: localStorage.projectRepondentVideo_ID,
        // videoName: response.data.serverResponse.fileList[0].fileName,
        // videoName: 'video/' + window.$sid + '_' + localStorage.getItem('channel') + '_' + '0' + '.mp4',
        videoName: null,
        endTime: time,
        sid: window.$sid,
        channelName: this.props.channel,
        //uid: String(this.uid), // can't use same UID as a user, this affects audio in recording. This UID is cloud recording  
        uid: '3613483512',
        clientRequest: {}
      };
      endInterview(endinterviewdata);
    }
  };

  handleExitClick = e => {
    const { uid } = this.state;
    if (uid === 240000) {
      this.handleOpenModal();
    } else {
      this.handleExit(e);
    }
  };

  handleScreenShareEnd = () => {
 
    const storeuid = '1500';
    const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
    // Customer secret
    const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
    // Concatenate customer key and customer secret and use base64 to encode the concatenated string
    const plainCredential = customerKey + ':' + customerSecret;
    // Encode with base64
    const encodedCredential = Buffer.from(plainCredential).toString('base64');
    const authorizationField = 'Basic ' + encodedCredential;
    console.log(`Screen-share track ended, stop sharing screen ` + this.localTracks?.screenVideoTrack?.getTrackId());
    this.localTracks.screenVideoTrack && this.localTracks.screenVideoTrack.close();
    this.localTracks.screenAudioTrack && this.localTracks.screenAudioTrack.close();
    // this.localTracks.audioTrack && this.localTracks.audioTrack.close();
    this.shareClient.leave();
    this.sendToMixPanel('RTC | Screen sharing ended', 201, 'User stopped screen share.');
    this.state.stateSharing = false;
    let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
   
    if (dom2 != null) {
      dom2.setAttribute('style', 'visibility: visible;');
    }
    // const data1 = {
    //   cname: this.props.channel,
    //   uid: '3613483512',
    //   clientRequest: {
    //     streamSubscribe: {
    //       audioUidList: {
    //         subscribeAudioUids: ['#allstream#']
    //       },
    //       videoUidList: {
    //         subscribeVideoUids: [storeuid]
    //       }
    //     }
    //   }
    // };
    // const { VideoRecordingUpdate } = this.props;
    // VideoRecordingUpdate(data1, authorizationField);

    //Update video recording start
    // const resource = {
    //   cname: this.props.channel,
    //   uid: '3613483512',
    //   clientRequest: {
    //     mixedVideoLayout: 3,
    //     // "backgroundColor": "#FF0000",
    //     layoutConfig: [
    //       {
    //         uid: `${this.uidarray.RespondentOnWeb}` == '1500' ? '1500' : '1000',
    //         x_axis: 0.0,
    //         y_axis: 0.0,
    //         width: 1.0,
    //         height: 1.0,
    //         alpha: 1.0,
    //         render_mode: 1
    //       }
    //     ]
    //   }
    // };
    // const { UpdateLayoutVideoRecording } = this.props;
    // UpdateLayoutVideoRecording(resource, authorizationField);
    //END
  };

  sharingScreen = async e => {
   
    try {
      

      const storeuid = '1000';
      let screenTrack;
      const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
      // Customer secret
      const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
      // Concatenate customer key and customer secret and use base64 to encode the concatenated string
      const plainCredential = customerKey + ':' + customerSecret;
      // Encode with base64
      const encodedCredential = Buffer.from(plainCredential).toString('base64');
      const authorizationField = 'Basic ' + encodedCredential;
      
      let uid;
      if (this.uid == '240000') {
        uid = '123456789';
      } else if (this.uid == '1500') {
        uid = '1234567';
      } else {
        uid = '12345678';
      }
      this.screenshareId = uid;
      this.uidarray['ScreenShare'] = uid;
      if (this.state.stateSharing) {
        this.handleScreenShareEnd();
      } else {
        let $ = this.props;
        const screenClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
        this.shareClient = screenClient;
        console.log('AgoraRTC client initialized');
        let dynamicUid = new Date().getHours().toString() + new Date().getMinutes() + new Date().getSeconds();
        [uid, screenTrack] = await Promise.all([
          // Join the channel.
          screenClient.join(this.props.appId, this.props.channel, null, Number(uid)),
          // Create tracks to the local microphone and camera.
          // AgoraRTC.createMicrophoneAudioTrack(),
          // this.localTracks.audioTrack,
          AgoraRTC.createScreenVideoTrack(
            {
              encoderConfig: {
                framerate: 15,
                height: 720,
                width: 960
                // height: 480,
                // width: 640
              },
              systemAudio: 'include'
            },
            'auto'
          )
        ])
          .then(values => {
            console.log('values screen sharing 123', values); // [3, 1337, "foo"]
            this.sendToMixPanel('RTC | Screen sharing started ', 201, 'User started screen share.');
            return values;
          })
          .catch(e => {
            this.sendToMixPanel(
              'RTC | Screen sharing error ',
              201,
              `Got error when started screen share. Error - ${e}`
            );
            console.log('values screen sharing error', e); // [3, 1337, "foo"]
            screenClient.leave(this.props.appId, this.props.channel, null, Number(uid));
          });
     
        this.state.stateSharing = true;

        if (screenTrack && screenTrack instanceof Array) {
      
          this.localTracks.screenVideoTrack = screenTrack[0];
          this.localTracks.screenAudioTrack = screenTrack[1];
        } else {
        
          this.localTracks.screenVideoTrack = screenTrack;
        }

        const streamId = String(uid);

        let canvas = document.querySelector('#ag-canvas');
        let dom = document.querySelector('#ag-item-' + this.screenshareId);
        if (!dom) {
          dom = document.createElement('section5');
          dom.setAttribute('id', 'ag-item-' + this.screenshareId);
          dom.setAttribute('class', 'ag-item');
          canvas.appendChild(dom);
          this.localTracks.screenVideoTrack.play('ag-item-' + this.screenshareId, { fit: 'contain' });
        }

        if (this.uidarray['ScreenShare']) {
          let dom4 = document.querySelector('#ag-item-' + this.uidarray['ScreenShare']);
         
          if (dom4 != null)
            if (dom4.getAttribute('id') == 'ag-item-' + this.uidarray['ScreenShare']) {
            
              // added by shweta
              dom4.setAttribute(
                'style',
                `grid-area: span 12/span 24/13/25;color:orange;z-index: 1;
              width: calc(100% - 12px);
              height: calc(100% - 20px);
              margin-top: 45px;`
              );
            }
          // if (this.uidarray['Respondant']) {
          //   let dom1 = document.querySelector('#ag-item-' + this.uidarray['Respondant']);
          //   if (dom1 != null)
          //     if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['Respondant']) {
          //       console.log('scrrenshre---2');
          //       // dom1.setAttribute(
          //       //   'style',
          //       //   `grid-area: span 3/span 4/4/5;
          //       //       z-index:3;width:calc(100% - 20px);height:calc(100% - 20px);3`
          //       // );
          //       dom1.setAttribute(
          //         'class',
          //         `researcherBox 7`
          //       );
          //       //its for researcher section 2
          //       if (localStorage.getItem('user') == 'respondent') {
          //         dom1.setAttribute('style', `grid-area: span 12/span 24/13/25;border:4px;`);
          //       }
          //     }
          // }
          //RespondentOnWeb whenscreen sharing is going on
          if (this.uidarray['RespondentOnWeb']) {
            let dom1 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
            if (dom1 != null)
              if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['RespondentOnWeb']) {
                console.log('scrrenshre---RespondentOnWeb11', this.uidarray);
                // dom1.setAttribute(
                //   'style',
                //   `grid-area: span 3/span 4/10/5;
                //       z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
                // );
                if (localStorage.getItem('user') == 'respondent') {
                  dom1.setAttribute('style', `grid-area: span 12/span 24/13/25;border:4px;`);
                }
              }
          }
          //END
          if (this.uidarray['other']) {
            let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
            if (dom3 != null)
              if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
                console.log('scrrenshre---3');

                // dom3.setAttribute(
                //   "style",
                //   `grid-area: span 3/span 4/7/5;
                //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
                // );
              }
          }
          if (this.uidarray['PrimaryResearcher']) {
            let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
            // dom2.setAttribute('style', 'display: none;')

            let dom3 = document.querySelector('#ag-item-' + this.uidarray['Respondant']);
            if (dom3 != null) {
              dom2.setAttribute('style', 'visibility: hidden;');
            }

            if (dom2 != null && dom3 != null) {
              console.log('respondent joined and screen shared');
              dom3.setAttribute('class', `researcherBox 8`);
              //dom2.removeAttribute('style');
              //dom2.setAttribute('style', 'z-index:500; !important');
            } else if (dom2 != null && dom3 == null) {
              dom2.setAttribute('style', 'display: none;');
              if (localStorage.getItem('user') == 'researcher') {
                if (dom3 != null) {
                  dom3.setAttribute('class', `researcherBox 9`);
                }
                console.log('this.uidarray', this.uidarray);
                let hasKey = this.uidarray.hasOwnProperty('RespondentOnWeb');
                if (hasKey) {
                  let dom1 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
                  dom1.setAttribute(
                    'style',
                    `grid-area: span 3/span 4/4/5;z-index:3;width:calc(100% - 20px);height:calc(100% - 20px);border:14px;`
                  );
                }
              } else {
                console.log('only researcher joined and screen shared');

                //dom2.setAttribute('class', `researcherBox 10`);
                //dom2.removeAttribute('style');
              }
            }
          }
        }

        // const data = {
        //   cname: this.props.channel,
        //   uid: '3613483512',
        //   clientRequest: {
        //     streamSubscribe: {
        //       audioUidList: {
        //         subscribeAudioUids: ['#allstream#']
        //       },
        //       videoUidList: {
        //         subscribeVideoUids: [streamId]
        //       }
        //     }
        //   }
        // };

        // const { VideoRecordingUpdate } = this.props;
        // VideoRecordingUpdate(data, authorizationField);

        //Code for updating recording on screen share
        // if (this.uid == '240000') {
        //   // Update video recording start
        //   const resource = {
        //     cname: this.props.channel,
        //     uid: '3613483512',
        //     clientRequest: {
        //       mixedVideoLayout: 3,
        //       // "backgroundColor": "#FF0000",
        //       layoutConfig: [
        //         {
        //           uid:`${uid}`,
        //           x_axis: 0.0,
        //           y_axis: 0.0,
        //           width: 1.0,
        //           height: 1.0,
        //           alpha: 1.0,
        //           render_mode: 1
        //         }
        //       ]
        //     }
        //   };
        //   const { UpdateLayoutVideoRecording } = this.props;
        //   UpdateLayoutVideoRecording(resource, authorizationField);
        //   // END
        // }
        //END

        // play local video track
        //this.localTracks.screenVideoTrack.play("ag-canvas");
        //bind "track-ended" event, and when screensharing is stopped, there is an alert to notify the end user.
        this.localTracks.screenVideoTrack.on('track-ended', this.handleScreenShareEnd);

        // publish local tracks to channel
        if (this.localTracks.screenAudioTrack == null) {
          // await this.localTracks.audioTrack.setEnabled(false);
          await screenClient.publish([this.localTracks.screenVideoTrack]);
        } else {
          // await this.localTracks.screenAudioTrack.setEnabled(false);
          await screenClient.publish([
            this.localTracks.screenVideoTrack,
            // this.localTracks.audioTrack
            this.localTracks.screenAudioTrack
          ]);
        }

        console.log('publish success screen share ---------');
      }
    } catch (error) {
      console.log('this.shareClient localTracks', this.localTracks);
      console.log('this.shareClient', this.shareClient);
      console.log(error);
    }
  };

  streamInitSharing = (uid, attendeeMode, videoProfile, config) => {
   
    const mic = window.$mic;
 
    let defaultConfig;
    if (mic === 'off') {
      defaultConfig = {
        streamID: uid,
        audio: false,
        video: false,
        screen: true
      };
    } else {
      defaultConfig = {
        streamID: uid,
        audio: true,
        video: false,
        screen: true
      };
    }

    switch (attendeeMode) {
      case 'audio-only':
        defaultConfig.video = false;
        break;
      case 'audience':
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case 'video':
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    // const Reso = this.props.screenResolution;
    const shareScreenResolution = window.$shareScreenResolution;
    stream.setScreenProfile(shareScreenResolution.resolution);
    return stream;
  };

  handleUserPublished = (user, mediaType) => {
    console.log(this.props, 'props in index');

    //print in the console log for debugging
    console.log('"user-published" event for remote users is triggered.', user, mediaType);
    const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
    // Customer secret
    const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
    // Concatenate customer key and customer secret and use base64 to encode the concatenated string
    const plainCredential = customerKey + ':' + customerSecret;
    // Encode with base64
    const encodedCredential = Buffer.from(plainCredential).toString('base64');
    const authorizationField = 'Basic ' + encodedCredential;
    const id = user.uid;
    this.remoteUsers[id] = user;
    console.log(this.uid, id, this.uidarray.other, '***********this.uid published');
    if (this.uid == '240000') {
      if (id != '123456789' && id != '12345678' && id != '1234567') {
        this.subscribe(user, mediaType);
      } else if (id == '1234567') {
        this.subscribe(user, mediaType);
      } else if (id == '12345678') {
        this.subscribe(user, mediaType);
      }
    } else if (this.uid == '1500') {
      if (id != '123456789' && id != '12345678' && id != '1234567') {
        this.subscribe(user, mediaType);
      } else if (id == '123456789') {
        this.subscribe(user, mediaType);
      } else if (id == '12345678') {
        this.subscribe(user, mediaType);
      }
    } else if (this.uid == this.uidarray.other) {
      if (id != '12345678' && id != '123456789' && id != '1234567') {
        this.subscribe(user, mediaType);
      } else if (id == '123456789') {
        this.subscribe(user, mediaType);
      } else if (id == '1234567') {
        this.subscribe(user, mediaType);
        // TOASK
      } else if (id == '12345678') {
        //changed added condition
        this.subscribe(user, mediaType);
      }
    }

    if (this.uid == '240000') {
      //If loop for calling when secondary researcher is there
      if (`${id}` === '123456789' || `${id}` === '12345678' || `${id}` === '1234567') {
        // Update video recording start
        const resource = {
          projectRespondent_ID: this.props.respondantInfo?.projectRespondent_ID,
          cname: this.props.channel,
          //uid: String(this.uid), // can't use same UID as a user, this affects audio in recording. This UID is cloud recording  
          uid: '3613483512',
          user_ID: localStorage.userId,
          clientRequest: {
            mixedVideoLayout: 3,
            // "backgroundColor": "#FF0000",
            layoutConfig: [
              {
                uid: `${id}`,
                x_axis: 0.0,
                y_axis: 0.0,
                width: 1.0,
                height: 1.0,
                alpha: 1.0,
                render_mode: 1
              }
            ]
          },
          projectRepondentVideo_ID: this.respondantInfo?.projectRepondentVideo_ID
        };
        const { requestUpdateVideoLayout } = this.props;
        // UpdateLayourequestUpdateVideoLayouttVideoRecording(resource, authorizationField);
        requestUpdateVideoLayout(resource, authorizationField);
        // END
      }
    }
  };

  handleUserUnpublished = (user, mediaType) => {
    const customerKey = process.env.REACT_APP_AGORA_CUSTOMERKEY;
    // Customer secret
    const customerSecret = process.env.REACT_APP_AGORA_CUSTOMERSECRET;
    // Concatenate customer key and customer secret and use base64 to encode the concatenated string
    const plainCredential = customerKey + ':' + customerSecret;
    // Encode with base64
    const encodedCredential = Buffer.from(plainCredential).toString('base64');
    const authorizationField = 'Basic ' + encodedCredential;
    //print in the console log for debugging
    console.log('"user-unpublished" event for remote users is triggered.');

    if (mediaType === 'video') {
      const id = user.uid;
      console.log('remove user', id);
      delete this.remoteUsers[id];
      $(`#ag-item-${id}`).remove();

      if (id == '240000') {
        delete this.uidarray.PrimaryResearcher;
      } else if (id == '1000') {
        delete this.uidarray.Respondant;
      } else if (id == '1500') {
        delete this.uidarray.RespondentOnWeb;
      } else if (id == '123456789' || id == '12345678' || id == '1234567') {
        delete this.uidarray.ScreenShare;
      } else {
        delete this.uidarray.other;
      }
      console.log('this.uidarray', this.uidarray);

      //delete this.uidarray.ScreenShare;

      if (this.uidarray['ScreenShare']) {
        let dom4 = document.querySelector('#ag-item-' + this.uidarray['ScreenShare']);
        if (dom4 != null)
          if (dom4.getAttribute('id') == 'ag-item-' + this.uidarray['ScreenShare']) {
            console.log('scrrenshre---1');
            dom4.setAttribute('style', `grid-area: span 12/span 24/13/25;z-index:2;border:1px`);
          }
        if (this.uidarray['Respondant']) {
          let dom1 = document.querySelector('#ag-item-' + this.uidarray['Respondant']);
          if (dom1 != null)
            if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['Respondant']) {
              console.log('scrrenshre---2');
              // dom1.setAttribute(
              //   'style',
              //   `grid-area: span 3/span 4/4/5;
              //       z-index:1;width:calc(100% - 20px);height:calc(100% - 20px);color:4;`
              // );
              dom1.setAttribute('class', `respondentBox`);
            }
        }
        if (this.uidarray['RespondentOnWeb']) {
          let dom5 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
          if (dom5 != null)
            if (dom5.getAttribute('id') == 'ag-item-' + this.uidarray['RespondentOnWeb']) {
              console.log('scrrenshre---RespondentOnWeb');
              dom5.setAttribute(
                'style',
                `grid-area: span 3/span 4/4/5;
                    z-index:1;width:calc(100% - 20px);height:calc(100% - 20px);color:5;`
              );
            }
        }
        if (this.uidarray['other']) {
          let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
          if (dom3 != null)
            if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
              console.log('scrrenshre---3');
              // dom3.setAttribute(
              //   "style",
              //   `grid-area: span 3/span 4/7/5;
              //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
              // );
            }
        }
        // else {
        //   let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        //   if (dom2 != null)
        //     if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
        //       console.log('scrrenshre---4');
        //       dom2.setAttribute(
        //         'style',
        //         `grid-area: span 3/span 4/7/5;
        //             z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
        //       );
        //     }
        // }
      } else if (this.uidarray['Respondant']) {
        let dom1 = document.querySelector('#ag-item-' + this.uidarray['Respondant']);
        if (dom1 != null)
          if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['Respondant']) {
            console.log('Respondant');
            dom1.setAttribute('style', `grid-area: span 12/span 24/13/25; color:#000`);
            dom1.removeAttribute('class', 'researcherBox 11');
            dom1.setAttribute('class', 'ag-item');
          }

        let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
        if (dom3 != null)
          if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
            console.log('Respondant1');
            // dom3.setAttribute(
            //   "style",
            //   `grid-area: span 3/span 4/7/5;
            //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
            // );
          }

        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null) {
          if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
            console.log('Respondant2');
            // dom2.setAttribute(
            //   'style',
            //   `grid-area: span 3/span 4/4/5;
            //           z-index:3;width:calc(100% - 20px);height:calc(100% - 20px);5`
            // );
            //its for researcher section 2
            if (localStorage.getItem('user') == 'respondent') {
              // dom2.setAttribute('style', `grid-area: span 12/span 24/13/25;border:4px;`);
              dom2.setAttribute('class', `researcherBox 12`);
            }
            dom2.setAttribute('class', `researcherBox 13`);
          }
        }
      } else if (this.uidarray['RespondentOnWeb']) {
        let dom1 = document.querySelector('#ag-item-' + this.uidarray['RespondentOnWeb']);
        if (dom1 != null)
          if (dom1.getAttribute('id') == 'ag-item-' + this.uidarray['RespondentOnWeb']) {
            console.log('RespondentOnWeb');
            dom1.setAttribute('style', `grid-area: span 12/span 24/13/25;color:#FFF`);
            if (localStorage.getItem('user') == 'respondent') {
              dom1.setAttribute(
                'style',
                `grid-area: span 3/span 4/4/5;z-index:3;width:calc(100% - 20px);height:calc(100% - 20px)`
              );
            }

            if (localStorage.getItem('user') == 'researcher') {
              dom1.setAttribute('style', `grid-area: span 12/span 24/13/25;11`);
            }
          }

        let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
        if (dom3 != null)
          if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
        
            // dom3.setAttribute(
            //   "style",
            //   `grid-area: span 3/span 4/7/5;
            //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
            // );
          }

        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null)
          if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
         
            // dom2.setAttribute(
            //   'style',
            //   `grid-area: span 3/span 4/4/5;
            //           z-index:3;width:calc(100% - 20px);height:calc(100% - 20px);6`
            // );
            //its for researcher section 2
            if (localStorage.getItem('user') == 'respondent') {
              dom2.setAttribute('style', `grid-area: span 12/span 24/13/25;border:4px;`);
            }
          }
      } else if (this.uidarray['other']) {
        let dom3 = document.querySelector('#ag-item-' + this.uidarray['other']);
        if (dom3 != null)
          if (dom3.getAttribute('id') == 'ag-item-' + this.uidarray['other']) {
            console.log('other---1');
            //dom3.setAttribute("style", `grid-area: span 12/span 24/13/25`);
          }

        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null)
          if (dom2.getAttribute('id') == 'ag-item-' + this.uidarray['PrimaryResearcher']) {
    
            dom2.setAttribute(
              'style',
              `grid-area: span 3/span 4/4/2;
              z-index:1;
              width:calc(100% - 12px);height:calc(100% - 20px);margin-top: 45px;color:6;`
            );
            dom2.removeAttribute('class');
          }
      } else if (this.uidarray['PrimaryResearcher']) {
        let dom2 = document.querySelector('#ag-item-' + this.uidarray['PrimaryResearcher']);
        if (dom2 != null) {
          dom2.removeAttribute('class');
          dom2.setAttribute(
            'style',
            `grid-area: span 3/span 4/4/2;
                    z-index:1;
                width:calc(100% - 12px);height:calc(100% - 20px);margin-top: 45px;`
          );
        } else {
          // dom2.setAttribute('class', `researcherBox 14`);
        }
      } else {
        let dom = document.querySelector('#ag-item-' + id);
   
        if (dom != null) dom.setAttribute('style', `grid-area: span 12/span 24/13/25;color:#CCC`);
      }

      if (this.uid == '240000') {
     
        //If loop for calling when secondary researcher is there
        if (`${id}` === '123456789' || `${id}` === '12345678' || `${id}` === '1234567') {
          //Update video recording start
          const resource = {
            projectRespondent_ID: this.props.respondantInfo?.projectRespondent_ID,
            cname: this.props.channel,
            //uid: String(this.uid), // can't use same UID as a user, this affects audio in recording. This UID is cloud recording  
            uid: '3613483512',
            user_ID: localStorage.userId,
            clientRequest: {
              mixedVideoLayout: 3,
              // "backgroundColor": "#FF0000",
              layoutConfig: [
                {
                  uid: `${this.uidarray.RespondentOnWeb}` == '1500' ? '1500' : '1000',
                  x_axis: 0.0,
                  y_axis: 0.0,
                  width: 1.0,
                  height: 1.0,
                  alpha: 1.0,
                  render_mode: 1
                }
              ]
            }
          };
          const { UpdateLayoutVideoRecording } = this.props;
          // UpdateLayoutVideoRecording(resource, authorizationField);
          const { requestUpdateVideoLayout } = this.props;
          // UpdateLayourequestUpdateVideoLayouttVideoRecording(resource, authorizationField);
          requestUpdateVideoLayout(resource, authorizationField);

          //END
        }
      }
      // this.uidarray = this.uidarray.filter(function(item) {
      //   return console.log("item",item);
      //     //return item !== id
      // })
    }
  };

  handleSelectChange = e => {
    if (e.value == 'NA') {
      return;
    }

    setLanguage(e);
    localStorage.setItem('language', e.label);
    localStorage.setItem('languageValue', e.value);

    let lang = e.value;
    this.setState({ currentLanguage: e.label, myLanguage: lang });
    const transcriptObj = {
      text: 'Please turn on CC..!!',
      name: this.state.userName,
      language: lang.split('-')[0]
    };
    this.sendMsg(JSON.stringify(transcriptObj));
    this.sendToMixPanel('Lang Change', 101, 'Language changed to ' + lang);
    // this.sendDataAPi(responseObj);
    if (this.translationRecognizer != null) {
      this.translationRecognizer?.stopContinuousRecognitionAsync(async () => {
        await this.translationRecognizer.close();
        this.getTranslationRecognizer(lang, 'Language changed to ' + lang);
      });
    } else {
      if (this.translationRecognizer == null) {
        this.getTranslationRecognizer(lang, 'Language changed to ' + lang);
      }
    }
  };

  // transcriptionDesign = (name,value,color) => {
  //   const baseStyle = {
  //     padding: '0.5rem', // equivalent to Bootstrap's p-2
  //     width: '25%'
  //   };

  //   const reconnectingStyle = {
  //     paddingRight: '1.5rem' // equivalent to Bootstrap's pr-4
  //   };
  //   return (
  //     <>
  //       <div
  //         className={this.state.isreconnecting ? "p-2 pr-4 w-25" : "p-2 w-25"}
  //         style={{ color, borderRight: '1px solid rgb(164, 201, 22)', width: '45px'}}
  //       >
  //         {name}
  //       </div>
  //       {((this.state.isreconnecting && (name == this.state.userName)) && (value == 'Please wait. Trying to resume captions...')&& (<div className="p-2"><PulsatingDot color='white'/></div>))}
  //       {((this.state.isreconnecting && (name == this.state.userName)) && (value == 'Failed to load. Contact support')&& (<div className="p-2"><PulsatingDot color='red'/></div>))}
  //       {
  //         (this.state.isreconnecting && (name == this.state.userName) &&(value == 'Please wait. Trying to resume captions...') ) && (
  //           <div className="text-warning p-2 w-100">{value}</div>
  //         )
  //       }
  //       {
  //         (this.state.isreconnecting && (name == this.state.userName) &&(value == 'Failed to load. Contact support') ) && (
  //           <div className="text-danger p-2 w-100">{value}</div>
  //         )
  //       }
  //       {
  //         (!this.state.isreconnecting) && (
  //           <div className="text-white p-2 w-100">{(value != 'Failed to load. Contact support' && value != 'Please wait. Trying to resume captions...') ? value : ''}</div>
  //         )
  //       }
  //       {/* {this.state.transcriptionResults == 'Resuming translation... ' ?
  //         <div className="text-white p-2 w-100"><PulsatingDot /></div>
  //         : <div className="text-white p-2 w-100">{this.state.transcriptionResults}</div>
  //       } */}
  //     </>
  //   );
  // };

  render() {
   
    const style = {
      display: 'flex',
      // gridGap: "25px",
      alignItems: 'center',
      justifyItems: 'center',
      gridTemplateRows: 'repeat(0, auto)',
      gridTemplateColumns: 'repeat(0, auto)',
      // width:"588px",
      flexDirection: 'columnReverse'
    };

    const videoControlBtn =
      this.props.attendeeMode === 'video' ? (
        this.props.respondantInfo?.isInterviewStart == 1 || this.props.respondantInfo?.isRead == 1 ? (
          <span
            onClick={
              this.props.respondantInfo?.isInterviewStart == 1 || this.props.respondantInfo?.isRead == 1
                ? this.handleCamera
                : ''
            }
            className={this.props.videoinit ? 'ag-btn videoControlBtn' : 'ag-btn videoControlBtn off'}
            // className = "ag-btn videoControlBtn off"
            title="Enable/Disable Video"
            style={{ marginRight: '6%' }}
          >
            {/* <i className="ag-icon ag-icon-camera"></i>
            <i className="ag-icon ag-icon-camera"></i> */}
            <img src={videoOnNew} className="ag-icon ag-icon-camera" width="20px" height="20px" />
            <img src={videoOffNew} className="ag-icon ag-icon-camera-off" width="20px" height="20px" />
          </span>
        ) : (
          <span className="ag-btn videoControlBtn1" title="Enable/Disable Video" style={{ marginRight: '6%' }}>
            {/* <i className="ag-icon ag-icon-camera-off"></i> */}
            <img src={videoOffNew} className="ag-icon ag-icon-camera-off" width="20px" height="20px" />
          </span>
        )
      ) : (
        ''
      );

    // const [isMicMuted, setIsMicMuted] = useState(true);

    const Tooltip = ({ text }) => {
      const tooltipStyle = {
        position: 'absolute',
        bottom: '-68%',
        left: '75%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        color: 'var(--headingColor);',
        fontWeight: 'bold',
        padding: '5px 10px',
        border: '1px solid white',
        borderRadius: '4px',
        fontSize: '14px',
        whiteSpace: 'nowrap'
      };

      return <div style={tooltipStyle}>{text}</div>;
    };

    // const tooltipWrapperStyle = {
    //   position: 'relative',
    //   display: 'inline-block'
    // };

    const styles = {
      userInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '10px',
        paddingBottom: '8px',
        borderBottom: '1px solid #ccc'
      },
      avatar: {
        width: '25px',
        height: '25px',
        zindex: 1,
        transform: 'translateY(-5px)'
      },

      displayDataContainer: {
        // marginTop: '5px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
      },
      displayAvatar: {
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        //  marginRight: '5px',
        marginRight: '10px'
      },
      displayName: {
        color: 'var(--greyBlue)',
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'left',
        lineHeight: '1.2',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      seeMore: {
        color: '#2361A0',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '1.2',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
      }
    };

    const AvatarImage = styled.img`
      margin-top: 13px;
    `;

    const AvatarContainer = styled.div`
      position: relative;
      display: flex;
      align-items: center;
    `;
    const PopupContainer = styled.div`
      position: relative;
    `;

    const { isMicMuted, active } = this.state;

    const audioControlBtn =
      this.props.attendeeMode !== 'audience' ? (
        <span
          onClick={this.handleMic}
          className={this.props.micinit ? 'ag-btn audioControlBtn' : 'ag-btn audioControlBtn off'}
          title="Enable/Disable Audio"
          style={{ marginRight: '6%' }}
        >
          {/* <i className="ag-icon ag-icon-mic"></i>
          <i className="ag-icon ag-icon-mic-off"></i> */}
          <img src={micOnNew} className="ag-icon ag-icon-mic" width="20px" height="20px" />
          <img src={micOffNew} className="ag-icon ag-icon-mic-off" width="20px" height="20px" />
        </span>
      ) : (
        ''
      );

    const switchDisplayBtn = (
      <span
        onClick={this.switchDisplay}
        className={this.state.streamList.length > 4 ? 'ag-btn displayModeBtn disabled' : 'ag-btn displayModeBtn'}
        title="Switch Display Mode"
      >
        <i className="ag-icon ag-icon-switch-display"></i>
      </span>
    );
    const hideRemoteBtn = (
      <span
        className={
          this.state.streamList.length > 4 || this.state.displayMode !== 'pip'
            ? 'ag-btn disableRemoteBtn disabled'
            : 'ag-btn disableRemoteBtn'
        }
        onClick={this.hideRemote}
        title="Hide Remote Stream"
      >
        <i className="ag-icon ag-icon-remove-pip"></i>
      </span>
    );

    const { showModal } = this.state;
    const exitBtn = (
      <span
        // onClick={this.handleExit}
        onClick={this.handleExitClick}
        className={this.state.readyState ? 'ag-btn exitBtn' : 'ag-btn exitBtn disabled'}
        title="Exit"
      >
        {/* <i className="ag-icon ag-icon-leave"></i> */}
        <img src={endCallNew} width="60px" height="55px" className="ag-icon ag-icon-leave" />
      </span>
    );

    //   const renderWifiButton1 = () => {
    //     let color;
    //     const { networkQuality } = this.state;
    //  //   console.log("check" , this.state.networkQuality)
    //    if (networkQuality?.uplinkNetworkQuality === 1) {
    //       color = 'green';
    //     } else if (networkQuality?.uplinkNetworkQuality === 2) {
    //       color = 'yellow';
    //     } else if (networkQuality?.uplinkNetworkQuality >= 3) {
    //       color = 'red';
    //     }else
    //     {
    //       color = 'white'
    //     }

    //   // console.log("color", networkQuality)
    //     return (
    //       <span title="Network-Quality">
    //         <IoIosWifi size={30} className="ag-icon ag-icon-wifi mr-4" color={color} />
    //       </span>
    //     );

    //   }

    // const renderWifiButton1 = () => {
    //   const { iconColor } = this.state;

    //   return (
    //     <span title="Network-Quality">
    //       <GiNetworkBars size={25} className="ag-icon ag-icon-wifi mr-4" color={iconColor} />
    //     </span>
    //   );
    // };

    // translation button code
    const translationBtn =
      this.props.attendeeMode !== 'audience' ? (
        <span onClick={this.startTranslationCC} className="ag-btn" title="Enable/Disable Translation">
          <img
            src={this.state.startTranslation ? captionIcon : captionOffIcon}
            width="30px"
            height="30px"
            className="ag-icon ag-icon-caption"
          />
        </span>
      ) : (
        ''
      );

    const lang = { label: localStorage.getItem('language'), value: localStorage.getItem('languageValue') };
    setLanguage(lang);

    // language selection
    const selectLanguageBtn =
      this.props.attendeeMode !== 'audience' ? (
        <span style={{ marginLeft: '3%', marginRight: '3%' }} className="ag-btn">
          <DropdownIcon
            cssClass="dropdownIcon-button p-1"
            color="var(--snapGreen)"
            iconSrc={translationIcon}
            items={languages}
            onChange={this.handleSelectChange}
            selected={lang}
            style={{ marginTop: '3%' }}
          />
          {setLanguage(lang)}
        </span>
      ) : (
        ''
      );

    const { ParticipantsData, showPopup, showAllParticipants } = this.state;

    return (
      <>
        <div id="ag-canvas" style={style} className="d-lg-flex flex-column-reverse">
          <div className="ag-btn-group">
            <ToastContainer
              className="custom-toast-container"
              toastClassName="custom-toast-body"
              bodyClassName="custom-toast-body"
            />
            {translationBtn}
            {selectLanguageBtn}
            <div className="ag-btn">
              <div style={styles.tooltipWrapper} onClick={this.handleTogglePopup}>
                <div>
                  <PopupContainer>
                    <AvatarContainer>
                      <Badge class="badge">{this.state.participants}</Badge>

                      <AvatarImage
                        src={this.state.showParticipantsBefore ? participants : participantsoff}
                        className="mr-4"
                        style={styles.avatar}
                        alt="User Avatar"
                        onClick={this.handleToggleParticipantsImage}
                      />
                      <ParticipantsPopup
                        showed={showPopup}
                        onClose={this.handleClosePopup}
                        heading="Participants"
                        showCross={true}
                        showCancel={false}
                        showAllParticipants={showAllParticipants}
                        handleToggleParticipants={this.handleToggleParticipants}
                        ParticipantsData={ParticipantsData}
                      ></ParticipantsPopup>
                    </AvatarContainer>
                  </PopupContainer>
                </div>
              </div>
            </div>

            <Divider
              className="ag-btn"
              background="var(--greyBlue)"
              style={{ marginRight: '3%' }}
              orientation="vertical"
              height="30px"
              width="2px"
            />

            {videoControlBtn}
            {audioControlBtn}
          
            {active && isMicMuted && <Tooltip text="You are on mute" />}
            {
              <span
                onClick={this.sharingScreen}
                className="ag-btn shareScreenBtn"
                title="Share/unShare Screen"
                style={{ marginRight: '6%' }}
              >
                {/* <i className="ag-icon ag-icon-screen-share"></i> */}
                <img src={screenShareNew} width="18px" height="20px" />
              </span>
            }

            {exitBtn}
            <EndInterviewPopup
              onClose={this.handleCloseModal}
              showed={showModal}
              heading="Terminate the interview?"
              secondheading="You will not be able to rejoin this call, Recording will be terminated."
              // secondheading2="Once deleted, it cant be retrieved."
              showCancel={true}
              showCross={false}
            >
              <CustomButtont
                fontSize="14px"
                fontWeight="600"
                size="medium"
                style={{
                  justifyContent: 'space-between',
                  marginRight: '20px'
                }}
                onClick={this.handleExit}
              >
                End Interview
              </CustomButtont>
            </EndInterviewPopup>
          </div>
          <div className="d-flex flex-md-column w-100 captionBox mt-2">
            {this.state.startTranslation ? (
              <>
                <div className="d-flex mt-2 w-100" style={{ fontSize: '14px' }}>
                  {this.state.transcriptionResults != '' ? (
                    <>
                      {/* {
                      this.transcriptionDesign(
                        this.state.userName,
                        this.state.transcriptionResults,
                        '#A4C916',
                      )
                    }   */}
                      <div
                        className={!this.state.isreconnecting ? 'p-2 w-25' : 'p-2 w-25'}
                        style={{ color: '#A4C916', borderRight: '1px solid rgb(164, 201, 22)', width: '15%' }}
                      >
                        {this.state.userName}
                      </div>
                      {/* {((this.state.isreconnecting) && (this.state.transcriptionResults == 'Please wait. Trying to resume captions...')&& (<div className="p-2" style={{position: 'relative', left: '5px'}}><PulsatingDot color='white'/></div>))} */}
                      {/* {((this.state.isreconnecting) && (this.state.transcriptionResults == 'Failed to load. Contact support')&& (<div className="p-2"><PulsatingDot color='red'/></div>))}  */}
                      {this.state.isreconnecting &&
                        this.state.transcriptionResults == 'Please wait. Trying to resume captions...' && (
                          <div className="d-flex w-100">
                            <div className="p-2">
                              <PulsatingDot color="white" />
                            </div>
                            <div className="text-warning p-2 w-80">{this.state.transcriptionResults}</div>
                          </div>
                        )}
                      {this.state.isreconnecting &&
                        this.state.transcriptionResults == 'Failed to load. Contact support' && (
                          <div className="d-flex w-100">
                            <div className="p-2">
                              <PulsatingDot color="red" />
                            </div>
                            <div className="text-danger p-2 w-80">{this.state.transcriptionResults}</div>
                          </div>
                        )}
                      {this.state.isreconnecting &&
                        this.state.transcriptionResults != 'Failed to load. Contact support' &&
                        this.state.transcriptionResults != 'Please wait. Trying to resume captions...' && (
                          <div className="text-white p-2 w-100">{this.state.transcriptionResults}</div>
                        )}
                      {!this.state.isreconnecting && (
                        <div className="text-white p-2 w-100">
                          {this.state.transcriptionResults != 'Failed to load. Contact support' &&
                          this.state.transcriptionResults != 'Please wait. Trying to resume captions...'
                            ? this.state.transcriptionResults
                            : ''}
                        </div>
                      )}
                      {/* {
                      (!this.state.isreconnecting) && (
                        <div className="text-white p-2 w-100">{(this.state.transcriptionResults != 'Failed to load. Contact support' && this.state.transcriptionResults != 'Please wait. Trying to resume captions...') ? this.state.transcriptionResults : ''}</div>
                      )
                    } */}
                    </>
                  ) : (
                    ''
                  )}
                </div>
               
                {this.state.displayData.map((item, index) => (
                  <div className="d-flex mt-2 w-100" style={{ fontSize: '14px' }}>
                    {item && item.text != '' && (
                      <>
                        <div
                          className="p-2 w-25"
                          style={{
                            color: `${messageBoxColor[index]}`,
                            borderRight: `1px solid ${messageBoxColor[index]}`,
                            width: '15%'
                          }}
                        >
                          {item.name}
                        </div>
                        <div className="text-white p-2 w-100">{item.text}</div>
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, {
  VideoRecording,
  CountNoParticipant,
  StopVideoRecording,
  removeVideoCall,
  VideoRecordingUserList,
  getUIDDetails,
  VideoRecordingUpdate,
  UpdateLayoutVideoRecording,
  requestUpdateVideoLayout,
  RtcTokenBuilder,
  sendTranscriptData,
  storeTranscriptLogs,
  getParticipant,
  saveRtmTranscriptData,
  storeTranscriptMessageLogs,
  updateSid,
  SendParticpantsListOnEnd
})(withRouter(AgoraCanvas));
