import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as SendInviteMethods from '../../utils/api/invite-recruit/SendInviteData';

function* SendInviteData(payload) {
  const promise = SendInviteMethods.SendInviteData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.SendInviteAction.SENDINVITATION_RECEIVED, data: res.Status });
}

export  { SendInviteData }