import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as clientDashboardMethods from '../../utils/api/clientAdmin/clientDashboardData';

function* clientDashboardData(payload) {
  const promise = clientDashboardMethods.clientDashboardData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.clientDashboardAction.CLIENTDASHBOARD_DATA_RECEIVED, data: res.Response });
}

export  { clientDashboardData }