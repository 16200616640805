import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as graphMethods from '../../utils/api/analysis/graphData';

function* graphData(payload) {
  const promise = graphMethods.graphData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.graphDataAction.GRAPH_DATA_RECEIVED, data: res.Response });
}

export  { graphData }