import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as RecruitScreenerMethod from '../../utils/api/invite-recruit/RecruitScreenerData';

function* RecruitScreenerData(payload) {
  const promise = RecruitScreenerMethod.RecruitScreenerData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.RecruitScreener.RECRUITS_SCREENER_DATA_RECEIVED, data: res.Response });
}

export  { RecruitScreenerData }