import React from 'react';
import { NotificationCard, NotificationInfo, Avatar, Text, Tab, Tabs, TabsContainer, Heading, Button, TabsContentContainer} from '../../common';
import { Link } from 'react-router-dom';

export function Notification() {
	return (
		<NotificationCard>
			{/* <TabsContainer>
				<Tabs>
					<Tab active={true}>All</Tab>
					<Tab>Todo</Tab>
					<Tab>Updates</Tab>
					<Tab>Reminders</Tab>
				</Tabs>
			</TabsContainer>
			<TabsContentContainer>
				<Heading small={true}>Today</Heading>
				<NotificationInfo>
					<Avatar width="25" height="25" fontSize="0.65rem" name="Aaron G." className="mr-2" />
					<Text className="notification-text" type="5">
						Interview with R4 -{' '}
						<Text fontWeight="800" span>
							Aaron G.
						</Text>{' '}
						-{' '}
						<Link to="/">
							<Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">
								Join Now
							</Button>
						</Link>
					</Text>
					<Text type="6">1m</Text>
				</NotificationInfo>

				<NotificationInfo>
					<Avatar width="25" height="25" fontSize="0.65rem" name="Sam J." className="mr-2" />
					<Text className="notification-text" type="5">
						<Text fontWeight="800" span>
							Sam J.
						</Text>{' '}
						has accepted the invitation
					</Text>
					<Text type="6">4m</Text>
				</NotificationInfo>

				<NotificationInfo>
					<Avatar width="25" height="25" fontSize="0.65rem" name="Sally H." className="mr-2" />
					<Text className="notification-text" type="5">
						<Text fontWeight="800" span>
							R6 - Sally H.
						</Text>{' '}
						has submitted Task - 1 Form -{' '}
						<Link to="/">
							<Button simple fontSize="0.75rem" fontWeight="500" decoration="underline" className="p-0">
								Review Form
							</Button>
						</Link>
					</Text>
					<Text type="6">21m</Text>
				</NotificationInfo>

				<Heading small={true} className="mt-4">
					Yesterday
				</Heading>

				<NotificationInfo>
					<Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
					<Text className="notification-text" type="5">
						<Text fontWeight="800" span>
							Sonia S.
						</Text>{' '}
						has scheduled her interview -{' '}
						<Text fontWeight="800" span>
							11:45 - 12:45
						</Text>{' '}
						on{' '}
						<Text fontWeight="800" span>
							2.11.21
						</Text>{' '}
						has applied.
					</Text>
					<Text type="6">25hr</Text>
				</NotificationInfo>

				<NotificationInfo>
					<Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
					<Text className="notification-text" type="5">
						<Text fontWeight="800" span>
							Sonia S.
						</Text>{' '}
						has scheduled her interview -{' '}
						<Text fontWeight="800" span>
							11:45 - 12:45
						</Text>{' '}
						on{' '}
						<Text fontWeight="800" span>
							2.11.21
						</Text>{' '}
						has applied.
					</Text>
					<Text type="6">25hr</Text>
				</NotificationInfo>

				<NotificationInfo>
					<Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
					<Text className="notification-text" type="5">
						<Text fontWeight="800" span>
							Sonia S.
						</Text>{' '}
						has scheduled her interview -{' '}
						<Text fontWeight="800" span>
							11:45 - 12:45
						</Text>{' '}
						on{' '}
						<Text fontWeight="800" span>
							2.11.21
						</Text>{' '}
						has applied.
					</Text>
					<Text type="6">25hr</Text>
				</NotificationInfo>

				<NotificationInfo>
					<Avatar width="25" height="25" fontSize="0.65rem" name="Kelly S" className="mr-2" />
					<Text className="notification-text" type="5">
						<Text fontWeight="800" span>
							Sonia S.
						</Text>{' '}
						has scheduled her interview -{' '}
						<Text fontWeight="800" span>
							11:45 - 12:45
						</Text>{' '}
						on{' '}
						<Text fontWeight="800" span>
							2.11.21
						</Text>{' '}
						has applied.
					</Text>
					<Text type="6">25hr</Text>
				</NotificationInfo>
			</TabsContentContainer> */}
		</NotificationCard>
	);
}
