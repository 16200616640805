import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetFieldMethods from '../../utils/api/field/GetFieldOverviewData';

function* GetFieldData(payload) {
  const promise = GetFieldMethods.GetFieldData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetFieldOverviewAction.FIELD_DATA_RECEIVED, data: res.Response });
}

export { GetFieldData };
