// import React from 'react'
// import loaderImg from '../assets/images/Spinner-2.svg';

// const PageLoader = () => {
//   return (
    
//       <div className="fp-container">
//           <img src={loaderImg} className = "fp-loader" alt="loading" />
//         </div>
    
//   )
// }

// export default PageLoader

//Rutujas Code for dynamic size of loader
import React from 'react';
import PropTypes from 'prop-types';
import loaderImg from '../assets/images/Spinner-2.svg';

const PageLoader = ({ size }) => {
  // Define styles based on props
  const containerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const imageStyle = {
    width: size,
    height: size,
  };

  return (
    <div style={containerStyle}>
      <img src={loaderImg} style={imageStyle} alt="loading" />
    </div>
  );
};

PageLoader.propTypes = {
  size: PropTypes.string, 
};

export default PageLoader;

