/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { useState, useSelector } from 'react';
import PlusGreen from '../assets/images/plus-green.svg';
import Text from './Text';
import { Button } from '.';
import { useEffect } from 'react';

const StyledButton = styled.button`
    background: transparent;
    border: 0;
    padding: 0.5rem;

    .task-menu {
        display: none;
        position: absolute;
        z-index: 9999;
        transition-delay: 1s !important;
    }

    /* &:hover .task-menu, */
    &.isOpen .task-menu{
        display: block;
        
    }
`

const NormalBtn = styled.div`
    background: transparent;
    border: 0;

    .task-menu {
        display: none;
        position: absolute;
        z-index: 9999;
        transition-delay: 1s !important;
        left: ${props => props.left || 'auto'};
        bottom: ${props => props.bottom || 'auto'};
    }

    /* &:hover .task-menu, */
    &.isOpen .task-menu{
        display: block;
    }
`



export default function ActionButton({ children, link, onClick, normalBtn, disable, left, bottom, isOpenpopup, onActionButtonClick, ...rest }) {

    const [isOpen, setIsOpen] = useState(isOpenpopup);
    const [isHover, setIsHover] = useState(false)
    useEffect(() => {
        setIsOpen(isOpenpopup);
    }, [isOpenpopup]);

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
        onActionButtonClick && onActionButtonClick();
    };

    const handleMouseEnter = () => {
        setIsHover(true);
     };
    
     const handleMouseLeave = () => {
        setIsHover(false);
     };
    
     const boxStyle = {
        backgroundColor: isHover ? '#3a3a3a' : 'transparent',
     };
    
    if (normalBtn) {
        return (<>
            <NormalBtn left={left} top={top}>
                <Button {...rest}
                 style={boxStyle}
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
								 disabled={disable}
                    // onBlur={() => setTimeout(() => { setIsOpen(false) }, 2000)}
                    onClick={() => {
                        // onClick && onClick(); 
                        // setIsOpen(isOpen => !isOpen);
                        handleButtonClick();
                    }
                    } >
                    {children[0]}{children[1]}
                </Button>
            </NormalBtn>
            <NormalBtn className={isOpen ? 'isOpen' : ''} left={left} bottom={bottom}>
                {children[2]}
            </NormalBtn>
        </>)
    }

    return <StyledButton
        onBlur={() => setTimeout(() => { setIsOpen(false) }, 350)}
        onClick={() => {
            onClick && onClick();
            setIsOpen(isOpen => !isOpen);
        }
        } className={isOpen ? 'isOpen' : ''}>
        <img src={PlusGreen} alt="Add Circle" style={{ height: '55px' }} />
        <Text type="2">{children || `Add new`}</Text>
    </StyledButton>
}