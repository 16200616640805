import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as tagMethods from '../../utils/api/tag/tag';

function* addTag(payload) {
  const promise = tagMethods.addTag(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.TagActions.ADD_TAG_REQUEST_RECEIVED, data: res.Response });
  //yield putResolve({ type: actions.TagActions.REQUEST_GET_TAGS_LIST });  
}
function* getTags(payload) {
  const promise = tagMethods.getTags(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.TagActions.TAGS_LIST_RECEIVED, data: res.Response });
}
export  { addTag, getTags }