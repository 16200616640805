import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as endProjectMethods from '../../utils/api/dashboard/endProjectData';

function* endProjectData(payload) {
  const promise = endProjectMethods.endProjectData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.endProjectAction.ENDPROJECT_RECEIVED, data: res.Response });
}

export { endProjectData };
