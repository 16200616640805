import React from 'react';
import { components } from 'react-select';
import { Text } from './';

export default function SingleValue({ children, ...props }) {
	const { getValue, hasValue } = props;
	const nbValues = getValue();

	if (!hasValue) {
		return <components.SingleValue {...props}>{children}</components.SingleValue>;
	}
	return (
		<components.SingleValue {...props}>
			<div className="d-flex justify-content-left align-items-center">
				<Text className="flex-fill" fontSize="14px" fontWeight="800">
					{nbValues[0].group}
				</Text>
				<Text className="pl-2 flex-fill" fontSize="12px" fontWeight="500" color="var(--seaBlue)">
					{nbValues[0].content}
				</Text>
			</div>
		</components.SingleValue>
	);
}
