import styled from '@emotion/styled';
import {css} from '@emotion/react'

const SPAN = styled.span`
    font-size: ${props => props.fontSize || 'inherit' };
    font-weight: ${props => props.fontWeight ? props.fontWeight : '400'};
    color: ${props => props.color || 'var(--textColor)'};
`

const TextStyles = props => css`
    color: ${props.color || 'var(--textColor)'};
    margin: ${props.margin || '0'};
    padding: ${props.padding || '0'};
`

const P1 = styled.p`
    ${TextStyles};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '800'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.875rem'};
`

const P2 = styled.p`
    ${TextStyles};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '500'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.875rem'};
`

const P3 = styled.p`
    ${TextStyles};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '300'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.875rem'};
`

const P4 = styled.p`
    ${TextStyles};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '700'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.75rem'};
`

const P5 = styled.p`
    ${TextStyles};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '500'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.75rem'};
`

const P6 = styled.p`
    ${TextStyles};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '300'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.75rem'};
`

export default function Text({ 
    children, 
    className="",
    span,
    fontSize,
    fontWeight,
    color,
    padding,
    margin,
    type="1",
}) {

    if(type==='6') {
        return <P6 
            className={className} 
            fontSize={fontSize} 
            color={color} 
            fontWeight={fontWeight} 
            padding={padding}
            margin={margin}
        >
            {children}
        </P6>
    }

    if(type==='5') {
        return <P5 
            className={className} 
            fontSize={fontSize} 
            color={color} 
            fontWeight={fontWeight} 
            padding={padding}
            margin={margin}
        >
            {children}
        </P5>
    }

    if(type==='4') {
        return <P4 
            className={className} 
            fontSize={fontSize} 
            color={color} 
            fontWeight={fontWeight} 
            padding={padding}
            margin={margin}
        >
            {children}
        </P4>
    }

    if(type==='3') {
        return <P3 
            className={className} 
            fontSize={fontSize} 
            color={color} 
            fontWeight={fontWeight} 
            padding={padding}
            margin={margin}
        >
            {children}
        </P3>
    }

    if(type==='2') {
        return <P2
            className={className} 
            fontSize={fontSize} 
            color={color} 
            fontWeight={fontWeight} 
            padding={padding}
            margin={margin}
        >
            {children}
        </P2>
    }

    if(span) {
        return <SPAN fontSize={fontSize} color={color} fontWeight={fontWeight} padding={padding}>{children}</SPAN>
    }

    return <P1 
        className={className} 
        fontSize={fontSize} 
        color={color} 
        fontWeight={fontWeight} 
        padding={padding}
        margin={margin}
    >
        {children}
    </P1>
}