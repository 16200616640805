import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as DeleteVideoTagMethods from '../../utils/api/field/DeleteVideoTagData';

function* DeleteVideoTagData(payload) {
  const promise = DeleteVideoTagMethods.DeleteVideoTagData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.DeleteVideoTagAction.DELETEVIDEOTAG_RECEIVED, data: res.Response });
}

export  { DeleteVideoTagData }