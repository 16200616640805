import { isTSEnumMember } from '@babel/types';
import styled from '@emotion/styled';
import React from 'react';
import { Heading } from '../../../common';

const Item = styled.div`
	width: 18px;
	height: 12px;
	background: ${(props) => props.color};
	margin-right: 4px;
`;
const Title = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	color: #000;
`;
const Container = styled.div`
	display: flex;
	align-items: center;

	position: absolute;
	right: 0;
	top: -10px;
`;

function Legend({ list, style, title = null }) {
	return (
		<Container style={style}>
			<div style={{ width: '100px', textAlign: 'right' }}>
				{title && (
					<Heading
						fontSize='10px'
						fontWeight='600'
						color='var(--deepBlue)'
						className='mb-0 pb-0 mr-4'
					>
						{title}
					</Heading>
				)}
			</div>

			<div className='d-flex' style={{ width: '200px' }}>
				{list.map((item) => (
					<div className='d-flex align-items-center mr-3'>
						{item.color && <Item color={item.color}></Item>}
						{item.number && (
							<Title className='mr-1'>
								{`${item.number}.`}{' '}
							</Title>
						)}
						<Title>{` ${item.label}`}</Title>
					</div>
				))}
			</div>
		</Container>
	);
}

export default Legend;
