import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetTaskMethods from '../../utils/api/field/GetTaskData';

function* GetTaskData(payload) {
  const promise = GetTaskMethods.GetTaskData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetTaskAction.TASK_RESPONSE, data: res.Response });
}

export  { GetTaskData }

