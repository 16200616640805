import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as projectsetdataMethods from '../../utils/api/invite-recruit/projectsetupdata';

function* projectsetupdata(payload) {
  const promise = projectsetdataMethods.projectsetupdata(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectsetupAction.PROJECTSETUPDATA_RECEIVED, data: res.Response });
}

export  { projectsetupdata }