import styled from '@emotion/styled';
import Card from './Card';

const StyledCard = styled(Card)`
    padding: 0;
    height: 100%;
    position: relative;
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || '100%'};

    img{
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    h6 {
        color: var(--quadColor);
        font-weight: 700;
        font-size: 1rem;
    }

    p {
        color: var(--quadColor);
        font-size: 0.75rem;
        font-weight: 400;
    }

    .hovered {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background: rgba(255,255,255,0.9);
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;

        h6 {
            color: var(--ternaryColor);
            font-size: 1.125rem;
        }

        p {
            color: var(--ternaryColor);
            font-size: 0.875rem;
        }
        
        .top-content {
            margin-top: 1rem;
        }
    }

    &.active {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);

        h6 {
            color: var(--ternaryColor);
            font-size: 1.125rem;
        }
    }
`

export default function TemplateCard({children, onMouseEnter, onMouseLeave, padding, height, width}){
    return <StyledCard onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} padding={padding} height={height} width={width}>{children}</StyledCard>
}