import actions from '../../actions';

// reducer with initial state
const initialState = {
  taskList: [],
  videoTaskList: [],
  questionTypes: [],
  screenTypes: [],
  masterTags: [],
  addQuestion: null,
  showScreenerFilterOnAnalysis: null,
  editQuestion: null,
  deleteQues: null,

};

const reformList = data => {
  return data;
};

const reformVideoList = data => {
  return data;
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.ProjectActions.QUESTION_EDIT_RECEIVED:
      return { ...state, editQuestion: action.payload.Status };

    case actions.ProjectActions.CLEAR_EDIT_QUESTION:
      return {
        ...state,
        editQuestion: 0
      };

    case actions.ProjectActions.REQUEST_DELETEFORMTAG:
      return {
        ...state
      };

    case actions.ProjectActions.DELETEFORMTAG_RECEIVED:
      return {
        ...state
      };

    case actions.ProjectActions.REQUEST_VIDEO_QUESTION_RECEIVED:
      return { ...state };

    case actions.ProjectActions.ADD_QUESITON_RECEIVED:
     
      return {
        ...state,
        addQuestion: action.payload.Status
      };
      case actions.ProjectActions.SHOW_ScreenerValidation_Recieved:
        return {
          ...state,
          showScreenerFilterOnAnalysis: action.payload.Status
        };
    case actions.ProjectActions.CLEAR_ADD_QUESTION:
      return {
        ...state,
        addQuestion: 0
      };

    case actions.ProjectActions.DELETE_QUESITON_RECEIVED:
      return { ...state ,
        deleteQues: action.payload
      };

      case actions.ProjectActions.CLEAR_DELETE_QUESTION:
        return {
          ...state,
          deleteQues: 0
        };
        
    case actions.ProjectActions.PROJECT_TASK_RECEIVED:
      return {
        ...state,
        taskList: reformList(action.payload.GetProjectTaskQuestions),
        questionTypes: reformList(action.payload.questionType)
      };
    case actions.ProjectActions.VIDEO_TASK_RECEIVED:
      return {
        ...state,
        videoTaskList: reformVideoList(action.payload.GetProjectTaskQuestions),
        screenTypes: reformVideoList(action.payload.dropDownForScreenerTypeQ),
        masterTags: reformVideoList(action.payload.masterTag)
      };

    default:
      return state;
  }
};

export default reducer;
