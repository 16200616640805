import styled from '@emotion/styled';

const StyledDivider = styled.div`
    width: ${props => props.width ? props.width : '75px'};
    height: ${props => props.height ? props.height : '2px'};
    background: ${props => props.background ? props.background : 'var(--darkBorderColor)'};
`

export default function Divider({ orientation, width, height, background, margin, className='', style={} }){
    return <StyledDivider 
        orientation={orientation}
        width={width}
        height={height}
        background={background}
        margin={margin}
        className={`divider ${className}`}
        style={style}
    ></StyledDivider>
}