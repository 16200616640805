import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as GetVideoNoteMethods from '../../utils/api/field/GetVideoNoteData';

function* GetVideoNoteData(payload) {
  const promise = GetVideoNoteMethods.GetVideoNoteData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.GetVideoNoteAction.GETVIDEONOTE_RECEIVED, data: res.Response });
}

export  { GetVideoNoteData }