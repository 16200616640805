import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as stitchMethods from '../../utils/api/analysis/stitchVideo';

function* stitchVideo(payload) {
  const promise = stitchMethods.stitchVideo(payload.data.payload);
  const { res } = yield promise;
  
  yield putResolve({ type: actions.analysisAction.STICH_VIDEO_RESPONSE, data: res.Response });
}

export  { stitchVideo }