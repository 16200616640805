import styled from '@emotion/styled';

const StyledCard = styled.div`
	background: var(--white);
	border: ${(props) =>
		props.error
			? '1px solid var(--navActive)'
			: props.withBorder
			? '1px solid rgba(121, 142, 143, 0.3)'
			: '0'};
	padding: ${(props) => props.padding || '1.8rem 2.75rem'};
	border-radius: ${(props) => props.borderRadius || '10px'};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
	width: 100%;
	position: relative;
	height: ${(props) => props.height || 'auto'};

	&.focused {
		border-left: 3px solid var(--greyBlue);
	}
`;

export default function Card({
	children,
	withBorder = true,
	padding = null,
	borderRadius = null,
	className = '',
	onMouseEnter,
	onMouseLeave,
	onClick,
	height,
	style,
	error,
}) {
	return (
		<StyledCard
			withBorder={withBorder}
			padding={padding}
			height={height}
			borderRadius={borderRadius}
			onClick={onClick}
			className={className}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={style}
			error={error}
		>
			{children}
		</StyledCard>
	);
}
