import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditRespondantGenPoolMethods from '../../utils/api/superAdmin/EditRespondantDataGenPoolData';

function* EditRespondantGenPoolData(payload) {
  const promise = EditRespondantGenPoolMethods.EditRespondantGenPoolData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditRespondantDataGenPoolAction.EDITRESPONDANTDATAGENPOOL_RECEIVED, data: res.Response });
}

export { EditRespondantGenPoolData };
