import {useState, useRef,useEffect} from 'react'
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  event,
  map,
  InternSet,
  range,
  schemeTableau10,
  scaleOrdinal
} from "d3";

export default function VerticalStack(props){

    const svgRef = useRef();
    const wrapperRef = useRef();
    
    const colors = schemeTableau10
    const [dkeys,setDkeys] = useState([])
    const [dcolors,setDcolors] = useState({})

    useEffect(()=>{
        const svg = select(svgRef.current);
        const { width, height } = wrapperRef.current.getBoundingClientRect();
        let tdata ={}
        for(let i =0; i < props?.data?.length; i++){
            if(!tdata.hasOwnProperty(props.data[i].cn1)){
                tdata[props.data[i].cn1] = {}
            }

            tdata[props.data[i].cn1][props.data[i][props.secondary]] = props.data[i].anscnt
            
        }
        let tkeys = Object.keys(tdata)
        let fdata = []
        for(let i=0;i < tkeys.length;i++){
            let tobj = {
                name: tkeys[i]
            }
            tobj = {...tobj,...tdata[tkeys[i]]}
            fdata.push(tobj)
        }
        let keys = props?.data?.reduce((f,d)=>{
            if(f.indexOf(d[props.secondary]) == -1){
                f.push(d[props.secondary])
            }
            return f
        },[])

        setDkeys(keys)

        const stackGenerator = stack().keys(keys);
        const layers = stackGenerator(fdata);

        const extent = [
            0,
            max(layers, (layer) => max(layer, (sequence) => sequence[1]))
          ];
          
        const yScale = scaleLinear().domain(extent).range([height, 20]);
    
        const xScale = scaleBand()
        .domain(fdata.map((d) => d.name))
        .range([0, width])
        .padding(0.1);

        const xAix = axisBottom(xScale);
        const yAix = axisLeft(yScale);

        const color = scaleOrdinal(keys, colors);
        
        let cd = {}
        for(let i=0; i < keys.length;i++){
          cd[keys[i]] = color(i)
        }

        setDcolors(cd)
        

        svg.selectAll('.content > *').remove()

        svg
        .select(".x-axis")
        .attr("transform", `translate(0, ${height})`)
        .call(xAix);
        svg
        .select(".y-axis")
        .attr("transform", `translate(${0 + 25}, 0 )`)
        .call(yAix);

        svg
        .selectAll('.content')
        .selectAll(".layer")
        .data(layers)
        .join("g")
        .attr("fill", (d,i)=>{
            return color(i)
        })
        .selectAll("rect")
        .data(d => d)
        .join("rect")
        .attr("x", (d)=> (Math.max(xScale(d.data.name)+(xScale.bandwidth()/2)-50,xScale(d.data.name)+xScale.bandwidth()/3)))
        .attr("y", (sequence) => yScale(sequence[1]))
        .attr("height", (sequence) => yScale(sequence[0]) - yScale(sequence[1]))
        .attr("width", Math.min(100,xScale.bandwidth()/3))

        let ar = svg
        .selectAll('.content')
        .selectAll('rect')

        let agroup = svg.selectAll('.content').append("g")
    ar.each(function (p, j) {
      
      agroup
      .append("text")
         .text(function(d) {   
               
             if( (p[1] - p[0])  != 0){
              return p[1] - p[0];
             }else{
               return ''
             }
                  
         })
         .attr("x",function(d){
             return (xScale(p.data.name)+xScale.bandwidth()/3) + xScale.bandwidth()/6
         })
         .attr("y", function(d){
             
             return yScale(p[1]) + ((yScale(p[0]) - yScale(p[1]))/2)
             
         })
         .attr("text-anchor", "middle")
         .attr("font-family","Noto Sans")
         .attr("font-size", "11px")
         .attr("fill", "white");
  });

  
    },[props])

    return (
        <>
          <div
            ref={wrapperRef}
            style={{ width: "100%", height: "200px", marginBottom: "2rem" }}
          >
            <svg ref={svgRef} style={{ width: "100%", height: "110%" }}>
              <g className="x-axis" />
              <g className="y-axis" />
              <g className="content" />
            </svg>
            <div style={{ width: "100%", height: "50px", marginTop:'20px', display:'flex' }}>
              {dkeys.map((d,i)=>{
                return (
                  <div style={{marginRight: '8px', display:'flex'}} key={'color_'+d} >
                    <div style={{width:'16px',height:'16px',backgroundColor:dcolors[d]}}>&nbsp;</div>
                    <div style={{marginLeft:'4px', fontSize:'12px'}}>{d}</div>
                  </div>
                )
              })

              }
              
            </div>
          </div>
        </>
      );
}