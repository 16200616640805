import React from 'react';
import { Heading } from '.';

function Question({
	question,
	children,
	isRequired = 0, 
	border = '1px solid var(--greyBorder)',
	width = '200px',
}) {
	return (
		<div
			style={{
				borderBottom: border ? border : '',
				padding: '0.75rem 0',
			}}
			className='align-items-center'
		>
				<div style={{ display: 'flex', alignItems: 'center' }}>
				<Heading
					type={3}
					fontSize='0.75rem'
					fontWeight='500'
					color='var(--deepBlue)'
				>
					{question}
				</Heading>
				{isRequired === 1 && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
			</div>


			{children}
		</div>
	);
}

export default Question;
