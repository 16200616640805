import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as EditClientAdminMethods from '../../utils/api/superAdmin/EditClientAdminData';

function* EditClientAdminData(payload) {
  const promise = EditClientAdminMethods.EditClientAdminData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.EditClientAdminAction.EDITCLIENTADMINDATA_RECEIVED, data: res.Response });
}

export { EditClientAdminData };
